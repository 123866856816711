import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
  },
  modal: {
    width: "100%",
  },
  chipOpen: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  chipClose: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  drawer: {
    overflow: "hidden",
  },
  drawerContent: {
    padding: 10,
    height: 400,
  },
  drawerPaper: {
    height: 400,
    top: 80,
    width: "21.7%",
    minWidth: 200,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  success: {
    backgroundColor: green[500],
  }
}));
