import { VisibilityOff } from "@mui/icons-material";
import ThingDetail from "features/thingDetail";
import Things from "features/things";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function ShareImageStandardTutorial(props) {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const myThingsRef = useRef(null);
  const thingDetailRef = useRef(null);

  let history = useHistory();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [thingId, setThingId] = useState(null);

  useEffect(() => {
    ThingService.getSystemThing(
      localStorage.accountId,
      (data) => {
        setThingId(data?.id);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    if (thingId)
      setSteps([
        {
          element: "#menuThings",
          intro: (
            <TutorialElement icon="click">
              <b>{t("Etkileşim Noktalarım")}</b> {t(" menüsüne tıklayın")}.
            </TutorialElement>
          ),
        },
        {
          element: "#menuThingList",
          intro: (
            <TutorialElement icon="click">
              <b>{t("Etkileşim Noktalarını ")}</b>{" "}
              {t("görüntülemek için Listeye tıklayın.")}
            </TutorialElement>
          ),
          onBeforeChange: (index) => {
            var element = document.querySelector("#menuThings");
            if (element?.childElementCount > 1 == false)
              $("#menuThings")[0].firstElementChild.click();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="info">
              {t("Etkileşim noktalarınız burada görüntülenmektedir.")}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "div.MuiTableContainer-root > table > tbody > tr",
          intro: (
            <TutorialElement icon="click">
              {"Görsel eklemek istediğiniz"} <b>{t("Nokta")}</b>{" "}
              {"'ya tıklayın. "}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-button='PHOTOS_ADD']",
          intro: (
            <TutorialElement icon="click">
              {t("Görsel eklemek için tıklayın.")}
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        // 4
        {
          intro: (
            <TutorialElement>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/tutorial/klasor_image.png"
                  }
                  width="225"
                  height="150"
                ></img>
              </div>
              Açılan klasörden Etkileşim noktanızın Görselini seçin.
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        // 5
        {
          element: "[data-fa-section='imageArea']",
          intro: (
            <TutorialElement icon="info">
              {t("Etkileşim Noktası görseli ")} <b>“Herkese Kapalı”</b>{" "}
              {t("olarak eklenir.")}
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: () => {
            stepsRef.current.updateStepElement(5);
            stepsRef.current.updateStepElement(6);
          },
        },
        // 6
        {
          intro: (
            <TutorialElement>
              <div style={{ textAlign: "center" }}>
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/tutorial/thingImages.png"
                  }
                  width="404"
                  height="101"
                ></img>
              </div>
              Görselin sağ alt köşesindeki <VisibilityOff /> simgesine tıklayın.
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="info">
              {t(
                "Artık Kare Kod’u okutan müşterinize Görselinizi paylaşmaya hazırsınız."
              )}
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
      ]);
  }, [thingId]);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Things" && <Things />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "ThingDetail" &&
        thingId && <ThingDetail ref={thingDetailRef} thingId={thingId} />}
      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuThings")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
