import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function UserAccountsHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Hesaplarınıza</b> ait bilgilerin yer aldığı alandır.
            <p>
              Bu alanda;
              <ul>
                <li>
                  Hesaplarınızla ilgili bilgileri <b>izleyebilirsiniz.</b>
                </li>
                <li>
                  Hesaplarınız arasında <b>geçiş*</b> yapabilirsiniz.
                </li>
                <li>
                  Hesaplarınızı <b>silebilirsiniz.</b>
                </li>
                <li>
                  <b>Yeni Hesap oluşturabilirsiniz.</b>
                </li>
              </ul>
            </p>
            <b>*</b>: Birden fazla hesabınız olmalıdır.
          </TutorialElement>
        ),
      },
      {
        element: "div.MuiTableContainer-root > table > thead > tr",
        intro: (
          <TutorialElement icon="click">
            Hesaplarınızı <b>Hesap, Rol</b> ve <b>İşlem</b>’e göre listelemek
            için kolon başlıklarına tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='SWITCH_ACCOUNT']",
        intro: (
          <TutorialElement icon="click">
            Hesaplarınız arasında <b>geçiş*</b> yapmak için tıklayın.
            <br />
            <br />
            <b>*</b>: Birden fazla hesabınız olmalıdır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='DELETE_ACCOUNT']",
        intro: (
          <TutorialElement icon="click">
            Hesabınızı <b>silmek</b> için tıklayın ve açılan pop-up’da{" "}
            <b>Evet</b> butonuna basın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='CREATE_ACCOUNT']",
        intro: (
          <TutorialElement icon="click">
            <b>Yeni Hesap Oluşturmak</b> için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/hesapOlustur.png"
                }
                width="816"
                height="295"
              ></img>
              <br />
              <b>Hesap Adı</b> girin.
              <br />
              <b>Ülke, Veri Merkezi ve Dil</b> seçin.
              <br />
              <b>Lisans sözleşmesini okudum ve kabul ediyorum </b>kutucuğunu
              işaretleyin ve <b>Hesap Oluştur</b> butonuna basın.
            </div>
          </TutorialElement>
        ),
        tooltipClass: classes.hesapOlustur,
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

UserAccountsHelp.propTypes = {
  value: PropTypes.string,
};
