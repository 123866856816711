import { faChartBar, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsDataTable from "components/thingsDataTable";
import ThingsScanCountStatHelp from "features/help/ThingsScanCountStatHelp";
import {
  forwardRef,
  default as React,
  Suspense,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaBarChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { CHART_COLORS } from "services/utils/ChartConstants";
import { gridStyles } from "styles/ThingsGridStyle";
import LoadingBar from "../../../components/loadingBar";
import i18n from "../../../i18n";
import MenuDate from "./MenuDate";
import { default as MenuCumulative, default as MenuLimit } from "./MenuLimit";
import MenuPeriod from "./MenuPeriod";

const GenericBarChart = forwardRef((props, ref) => {
  const {
    title,
    getData,
    bAxisLabel,
    lAxisLabel,
    menuDate,
    menuLimit,
    menuPeriod,
    menuCumulative,
    menuList,
    settings,
    ...other
  } = props;

  const [statSettings, setStatSettings] = useState({ ...settings });
  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const [mode, setMode] = useState("CHART");
  const gridClasses = gridStyles();

  const { t } = useTranslation("statistic", { i18n });

  useEffect(() => {
    getData(statSettings, getDataOnSuccess, getDataOnError);
  }, [statSettings]);

  useEffect(() => {
    setStatSettings({ ...settings });
  }, [settings]);

  const getDataOnSuccess = (data) => {
    setChartData(data.chartData);
    setChartKeys(data.chartKeys);
  };

  const getDataOnError = (error) => {};

  const changePeriod = (newPeriod) => {
    setStatSettings({ ...statSettings, period: newPeriod });
  };

  const changeDate = (newDate) => {
    setStatSettings({
      ...statSettings,
      start: newDate.start,
      end: newDate.end,
      dateRangeType: newDate.dateRangeType,
    });
  };

  const changeLimit = (newLimit) => {
    setStatSettings({ ...statSettings, limit: newLimit });
  };

  const changeCumulative = (newCumulative) => {
    setStatSettings({ ...statSettings, cumulative: newCumulative });
  };

  const chartClicked = (p1, p2) => {
    console.log("p1", p1);
    console.log("p2", p2);
  };

  useImperativeHandle(ref, () => ({
    refreshChart(newSettings) {
      setStatSettings({ ...statSettings, ...newSettings });
    },
  }));

  const columns = [
    {
      field: "label",
      label: lAxisLabel,
      align: "center",
      width: "60%",
      valueFormatter: (value, row) => {
        return row.label ?? row.category;
      },
    },
    {
      field: "SAYI",
      label: bAxisLabel,
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        return row.SAYI ?? row.value;
      },
    },
  ];

  const renderList = () => {
    return (
      <ThingsDataTable
        data={chartData.filter((item) => item.SAYI !== 0)}
        columns={columns}
        //ref={tableRef}
        showDeleteButton={false}
        showHeaderText={false}
        showPaging={true}
        queryParams={{
          page: 0,
          size: 10,
          sort: "label",
          isAsc: false,
        }}
        columnClassName={gridClasses.GenericColumn}
      />
    );
  };

  const renderChart = () => {
    return (
      <div>
        <Card>
          <CardHeader
            action={
              <Grid container row>
                {menuList && mode === "CHART" && (
                  <Button
                    data-fa-button="LIST_OR_GRAPHIC_BUTTON"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      setMode("LIST");
                    }}
                    startIcon={
                      <FontAwesomeIcon
                        icon={faChartBar}
                        style={{ color: "#6F7782" }}
                      />
                    }
                  >
                    {t("CHART")}
                  </Button>
                )}
                {menuList && mode === "LIST" && (
                  <Button
                    data-fa-button="LIST_OR_GRAPHIC_BUTTON"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={() => {
                      setMode("CHART");
                    }}
                    startIcon={
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ color: "#6F7782" }}
                      />
                    }
                  >
                    {t("LIST")}
                  </Button>
                )}

                {menuDate && (
                  <MenuDate
                    value={statSettings.dateRangeType}
                    onChange={changeDate}
                    start={statSettings.start}
                    end={statSettings.end}
                  />
                )}
                {menuPeriod && (
                  <MenuPeriod
                    value={statSettings.period}
                    onChange={changePeriod}
                  />
                )}
                {menuLimit && (
                  <MenuLimit
                    value={statSettings.limit}
                    onChange={changeLimit}
                  />
                )}

                {menuCumulative && (
                  <MenuCumulative
                    value={statSettings.cumulative}
                    onChange={changeCumulative}
                  />
                )}
                {props.helpCode === "thingDetail.thingScanCountStat" ? (
                  <ThingsScanCountStatHelp value={props.helpCode} />
                ) : (
                  <IconButton aria-label="help" size="large">
                    <ThingsHelpIcon />
                  </IconButton>
                )}
              </Grid>
            }
            title={title}
          />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12}>
                <Grid item>
                  {mode === "CHART" && (
                    <FaBarChart
                      data={chartData}
                      keys={chartKeys}
                      indexBy={"label"}
                      height="300px"
                      bAxisLabel={bAxisLabel}
                      lAxisLabel={lAxisLabel}
                      stacked
                      onClick={chartClicked}
                      colors={CHART_COLORS}
                      {...other}
                    />
                  )}
                  {mode === "LIST" && renderList()}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
    </>
  );
});
export default GenericBarChart;
