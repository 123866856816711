import {
  faExclamationCircle,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import IssueClassAutoComplete from "components/autocomplete/IssueClassAutoComplete";
import ServiceOperationClassAutoComplete from "components/autocomplete/ServiceOperationClassAutoComplete";
import ControlItemTypeSelect from "components/select/ControlItemTypeSelect";
import DocumentAttachmentTypeSelect from "components/select/DocumentAttachmentTypeSelect";
import React, { forwardRef, useEffect, useState } from "react";
import { FaButton, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ControlItemService } from "services/controlItem/controlItemService";
import i18n from "../../i18n";
import { useStyles } from "./style";

const ControlItemEdit = forwardRef((props, ref) => {
  const {
    onChange,
    onClose,
    value,
    open,
    thingClass,
    patrolDef,
    patrolDefThing,
  } = props;

  const classes = useStyles();

  const { t } = useTranslation("thingClass", { i18n });
  const faDialog = useDialog();
  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  const [sectionIssueClass, setSectionIssueClass] = useState(false);
  const [sectionServiceOperationClass, setSectionServiceOperationClass] =
    useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const newControlItem = () => {
    return {
      id: 0,
      orderNo: 1,
      question: "",
      itemType: "YES_NO",
      documentAttachmentType: "NOT_REQUIRED",
      yesLabel: t("YES"),
      noLabel: t("NO"),
      maxRating: 5,
      thingClass: thingClass,
      patrolDefThing: patrolDefThing,
      patrolDef: patrolDef,
      account: { id: accountId },
      controlItemTargets: [],
      controlItemOverride: null,
    };
  };

  const [controlItem, setControlItem] = useState(newControlItem());

  useEffect(() => {
    console.log({ open });
    if (open) {
      setControlItem(value == null ? newControlItem() : { ...value });
      setIsDialogOpen(true);
    } else {
      setIsDialogOpen(false);
    }
  }, [open]);

  const handleChange = (prop, val) => {
    setControlItem({ ...controlItem, [prop]: val });
  };

  const handleChipDelete = (index) => () => {
    var temp = [...controlItem.controlItemTargets];
    temp.splice(index, 1);
    setControlItem({ ...controlItem, controlItemTargets: temp });
  };

  const addTargetIssueClass = (value) => {
    let dto = {
      issueClass: { id: value.id },
      serviceOperationClass: null,
      label: value.className,
      key: "ic_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetServiceOperationClass = (value) => {
    let dto = {
      issueClass: null,
      serviceOperationClass: { id: value.id },
      label: value.className,
      key: "soc_" + value.id,
    };

    addTarget(dto);
  };

  const addTarget = (newValue) => {
    setControlItem({
      ...controlItem,
      controlItemTargets: [...controlItem.controlItemTargets, newValue],
    });
  };

  const saveControlItem = () => {
    ControlItemService.save(controlItem, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    setIsDialogOpen(false);
    setControlItem(newControlItem());
    setLoading(false);
    onChange(data);
  };

  const saveOnError = (data) => {};

  const deleteControlItem = () => {
    faDialog({
      description: t("Kontrol sorusunu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ControlItemService.delete(controlItem, onChange, (e) => {
          showAlert("Bağlı kayıtlar mevcut.", "error");
        });
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDialog = () => {
    return (
      <FaDialog
        loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("ADD_QUESTION")}
        faOnSubmit={saveControlItem}
        faHandleClose={() => {
          setIsDialogOpen(false);
          onClose();
        }}
      >
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <FaInput
              required
              faType="description"
              label={t("QUESTION")}
              value={controlItem.question}
              onChange={(event) => {
                handleChange("question", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlItemTypeSelect
              value={controlItem.itemType}
              onChange={(data) => handleChange("itemType", data)}
            />
          </Grid>
          {controlItem.itemType === "YES_NO" && (
            <Grid item xs={12}>
              <FaInput
                required
                label={t("YES_LABEL")}
                value={controlItem.yesLabel}
                onChange={(event) => {
                  handleChange("yesLabel", event.target.value);
                }}
              />
            </Grid>
          )}

          {controlItem.itemType === "YES_NO" && (
            <Grid item xs={12}>
              <FaInput
                required
                label={t("NO_LABEL")}
                value={controlItem.noLabel}
                onChange={(event) => {
                  handleChange("noLabel", event.target.value);
                }}
              />
            </Grid>
          )}

          {controlItem.itemType === "RATING_SCALE" && (
            <Grid item xs={12}>
              <FaInput
                required
                label={t("MAX_RATING")}
                value={controlItem.maxRating}
                onChange={(event) => {
                  handleChange("maxRating", event.target.value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <DocumentAttachmentTypeSelect
              value={controlItem.documentAttachmentType}
              onChange={(data) => handleChange("documentAttachmentType", data)}
            />
          </Grid>
          {thingClass && (
            <Grid item xs={12}>
              <Typography variant="caption">
                {t("CONTROL_ITEM_TARGETS")}
              </Typography>

              <Paper component="ul" className={classes.chipRoot}>
                {controlItem.controlItemTargets.map((data, index) => {
                  let icon;

                  if (data.issueClass) {
                    icon = <FontAwesomeIcon icon={faExclamationCircle} />;
                  } else if (data.serviceOperationClass) {
                    icon = <FontAwesomeIcon icon={faWrench} />;
                  }

                  return (
                    <li key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.label}
                        onDelete={handleChipDelete(index)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </Paper>
              <div className={classes.buttonPanel}>
                <FaButton
                  faClick={() => {
                    setSectionIssueClass(true);
                  }}
                  fullWidth={false}
                  startIcon={<AddIcon />}
                >
                  {t("ADD_ISSUE_CLASS")}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionServiceOperationClass(true);
                  }}
                  fullWidth={false}
                  startIcon={<AddIcon />}
                >
                  {t("ADD_SERVICE_OPERATION_CLASS")}
                </FaButton>
              </div>

              {sectionIssueClass ? (
                <div className={classes.hboxBlock}>
                  <IssueClassAutoComplete
                    accountId={accountId}
                    thingClassId={thingClass.id}
                    onChange={(data) => {
                      addTargetIssueClass(data);
                      setSectionIssueClass(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionIssueClass(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}

              {sectionServiceOperationClass ? (
                <div className={classes.hboxBlock}>
                  <ServiceOperationClassAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetServiceOperationClass(data);
                      setSectionServiceOperationClass(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionServiceOperationClass(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
            </Grid>
          )}
        </Grid>
        {controlItem?.id ? (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deleteControlItem} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        ) : null}
      </FaDialog>
    );
  };

  return renderDialog();
});

export default ControlItemEdit;
