import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SlaContractLevelRadio from "components/radio/SlaContractLevelRadio";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { TaskService } from "services/TaskService";
import { IssueService } from "services/issue/issueService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function SlaDetail(props) {
  const { issue, task, editable, onChange, slaContracts } = props;
  const { t } = useTranslation("issueDetail", { i18n });
  const labelClasses = labelStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [newSlaContractLevel, setNewSlaContractLevel] = useState(
    issue ? issue?.slaContractLevel : task?.slaContractLevel
  );

  const saveSlaLevel = () => {
    if (issue) {
      IssueService.saveSlaLevel(
        issue.id,
        newSlaContractLevel?.id,
        (data) => {
          setDialogOpen(false);
          onChange();
        },
        (error) => {}
      );
    } else if (task) {
      TaskService.saveSlaLevel(
        task.id,
        newSlaContractLevel?.id,
        (data) => {
          setDialogOpen(false);
          onChange();
        },
        (error) => {}
      );
    }
  };

  const modalDialogRender = () => {
    return (
      <>
        <FaDialog
          title={t("SLA Seviye Belirle")}
          faOpen={dialogOpen}
          faOnSubmit={saveSlaLevel}
          showSaveButton
          faHandleClose={(data) => {
            setDialogOpen(false);
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              {slaContracts && slaContracts.length === 1
                ? slaContracts[0].name
                : ""}
            </Grid>
            <Grid item xs={12}>
              <SlaContractLevelRadio
                value={newSlaContractLevel}
                thingId={issue ? issue?.thing?.id : task?.thing?.id}
                onChange={(data) => {
                  setNewSlaContractLevel(data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  const renderSlaString = () => {
    if (issue && issue.slaContractLevel)
      return (
        issue.slaContractLevel.slaContract.name +
        "-" +
        issue.slaContractLevel?.name +
        (issue.slaContractLevel?.cause
          ? "-" + issue?.slaContractLevel?.cause
          : "")
      );
    else if (task && task.slaContractLevel)
      return (
        task.slaContractLevel.slaContract.name +
        "-" +
        task.slaContractLevel?.name +
        (task.slaContractLevel?.cause
          ? "-" + task?.slaContractLevel?.cause
          : "")
      );
  };

  return (
    <>
      {dialogOpen && modalDialogRender()}
      <Grid item xs={12} className={labelClasses.LabelLastRow}>
        <Typography className={labelClasses.LabelHeader}>
          {t("SLA_CONTRACT_LEVEL")}
        </Typography>
        <Typography className={labelClasses.LabelValue}>
          <div style={{ display: "flex" }}>
            <div style={{ flex: 5 }}>{renderSlaString()}</div>
            {editable &&
              ((task &&
                (task.status === "ACTIVE" || task.status === "MISSED")) ||
                (issue && issue.status !== "CLOSE")) && (
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Tooltip title={"Değiştir"}>
                    <IconButton
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                      size="small"
                      color="primary"
                      aria-label={t("EDIT")}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
          </div>
        </Typography>
      </Grid>
    </>
  );
}

SlaDetail.propTypes = {
  issue: PropTypes.any,
  task: PropTypes.any,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  slaContracts: PropTypes.any,
};
