import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ISSUECLASS } from "../../services/faApi";

export default function IssueClassByThingClassAutoComplete(props) {
  const { value, accountId, thingClassId, onChange, required, disabled } =
    props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="className"
      faFilterProp="className"
      getOptionLabel={(option) => (option.className ? option.className : "")}
      requestUrl={
        ISSUECLASS.findByThingClass +
        "?disable=false&accountId=" +
        accountId +
        "&thingClassId=" +
        (thingClassId ?? "-1")
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={t("ISSUE_CLASS")}
      required={required}
      disabled={disabled}
    />
  );
}
