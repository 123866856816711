import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Add } from "@mui/icons-material";
import SlaMeasurementSelect from "components/select/SlaMeasurementSelect";
import SlaResponseDefinitionSelect from "components/select/SlaResponseDefinitionSelect";
import SlaTimePeriodSelect from "components/select/SlaTimePeriodSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { SlaContractLevelService } from "../../services/SlaContractLevelService";
import { useStyles } from "./style";

export default function SlaContractLevels(props) {
  const { slaContract } = props;
  const faDialog = useDialog();

  const classes = useStyles();

  const { t } = useTranslation("slaContract", { i18n });
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const newLevel = () => {
    return {
      id: 0,
      slaContract: slaContract,
      name: "",
      cause: "",
      description: "",
      todo: "",
      factor: 0.0,
      responseTime: 0,
      responsePeriod: "HOUR",
      solutionTime: 0,
      solutionPeriod: "HOUR",
      measurementMethod: null,
      responseDefinition: null,
      orderNo: null,
    };
  };

  const [level, setLevel] = useState(newLevel());
  const [levelList, setLevelList] = useState([]);

  useEffect(() => {
    if (slaContract && accountId && slaContract.id > 0) {
      refresh();
    }
  }, [slaContract, accountId]);

  const refresh = () => {
    SlaContractLevelService.findBySlaContract(
      slaContract.id,
      findBySlaContractOnSuccess,
      findBySlaContractOnError
    );
  };

  const findBySlaContractOnSuccess = (data) => {
    setLevelList(data.list);
  };

  const findBySlaContractOnError = (data) => {};

  const addLevel = () => {
    SlaContractLevelService.save(level, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsDialogOpen(false);
  };

  const saveOnError = (data) => {};

  const deleteLevel = (data) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SlaContractLevelService.delete(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const handleChange = (prop, val) => {
    setLevel({ ...level, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setLevel(row);
    setIsDialogOpen(true);
  };

  const renderDialog = () => {
    return (
      <FaDialog
        loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("EDIT_LEVEL")}
        faOnSubmit={addLevel}
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <FaInput
              required
              label={t("LEVEL_NAME")}
              value={level.name}
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              label={t("LEVEL_CAUSE")}
              value={level.cause}
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                handleChange("cause", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("LEVEL_DESCRIPTION")}
              value={level.description}
              inputProps={{ maxLength: 4000 }}
              onChange={(event) => {
                handleChange("description", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("TODO")}
              value={level.todo}
              inputProps={{ maxLength: 4000 }}
              onChange={(event) => {
                handleChange("todo", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              awnum="decimal-6"
              label={t("FACTOR")}
              value={level.factor}
              onChange={(event) => {
                handleChange("factor", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              faType="number"
              label={t("RESPONSE_TIME")}
              value={level.responseTime}
              onChange={(event) => {
                handleChange("responseTime", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SlaTimePeriodSelect
              value={level.responsePeriod}
              onChange={(data) => handleChange("responsePeriod", data)}
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              faType="number"
              label={t("SOLUTION_TIME")}
              value={level.solutionTime}
              onChange={(event) => {
                handleChange("solutionTime", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SlaTimePeriodSelect
              value={level.solutionPeriod}
              onChange={(data) => handleChange("solutionPeriod", data)}
            />
          </Grid>
          <Grid item xs={12}>
            <SlaMeasurementSelect
              value={level.measurementMethod}
              onChange={(data) => handleChange("measurementMethod", data)}
            />
          </Grid>
          <Grid item xs={12}>
            <SlaResponseDefinitionSelect
              value={level.responseDefinition}
              onChange={(data) => handleChange("responseDefinition", data)}
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              faType="number"
              label={t("ORDER_NO")}
              value={level.orderNo}
              onChange={(event) => {
                handleChange("orderNo", event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const columns = [
    {
      field: "orderNo",
      label: t("ORDER_NO"),
      align: "center",
      width: "5%",
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "15%",
    },
    {
      field: "cause",
      label: t("LEVEL_CAUSE"),
      align: "center",
      width: "10%",
    },
    {
      field: "description",
      label: t("LEVEL_DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "todo",
      label: t("TODO"),
      align: "center",
      width: "20%",
    },
    {
      field: "factor",
      label: t("FACTOR"),
      align: "center",
      width: "10%",
    },
    {
      field: "measurementMethod",
      label: t("SLA_MEASUREMENT_METHOD"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => t(value),
    },
    {
      field: "responseDefinition",
      label: t("SLA_RESPONSE_DEFINITION"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => t(value),
    },
    {
      field: "responseTime",
      label: t("RESPONSE_TIME"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.responseTime)
          return row.responseTime + " " + t("" + row.responsePeriod);
        else return null;
      },
    },
    {
      field: "solutionTime",
      label: t("SOLUTION_TIME"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.solutionTime)
          return row.solutionTime + " " + t("" + row.solutionPeriod);
        else return null;
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteLevel(row)}
            size="small"
            color="primary"
            aria-label={t("SIL")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  const levelAddButtonClicked = () => {
    setLevel(newLevel());
    setIsDialogOpen(true);
  };

  return <>
    {renderDialog()}
    <Card>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={levelAddButtonClicked} size="large">
            <Add />
          </IconButton>
        }
        title={t("SLA_LEVELS")}
        data-fa-section="SLA_LEVELS"
      />
    </Card>
    <ThingsDataTable
      data={levelList}
      columns={columns}
      ref={tableRef}
      showDeleteButton={false}
      showHeaderText={false}
      headerText={t("SLA_LEVELS")}
      showPaging={false}
      faOnDoubleClick={onDoubleClick}
    />
  </>;
}
