import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IssueClassByThingClassAutoComplete from "components/autocomplete/IssueClassByThingClassAutoComplete";
import DynamicFieldsEdit from "components/dynamicFieldsEdit";
import ThingSelector from "components/selector/ThingSelector";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { DynamicFormService } from "services/dynamicForm/dynamicFormService";
import { IssueService } from "services/issue/issueService";
import { ThingService } from "services/thing/thingService";
import { isTNF } from "services/utils";
import i18n from "../../i18n";

const IssueCreate = forwardRef((props, ref) => {
  const { onCreate, thing } = props;
  const { t } = useTranslation(["issue", "issueCloseType"], { i18n });
  const accountId = localStorage.accountId;

  const [files, setFiles] = useState([]);
  const [isNewIssueDialog, setIsNewIssueDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportIssueDefaultNoThing, setReportIssueDefaultNoThing] =
    useState(false);
  const userInfo = useSelector(getUserInfo);

  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);

  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsNewIssueDialog(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const createNewIssue = () => {
    return {
      thing: thing ?? null,
      description: "",
      user: {},
      notificationDate: "",
      status: "",
      notifier: userInfo?.fullname,
      hashtag: "",
      issueClass: {},
      noThing: reportIssueDefaultNoThing,
    };
  };

  const [issueDTO, setIssueDTO] = useState(createNewIssue());

  useEffect(() => {
    if (thing) setIssueDTO({ ...issueDTO, thing: thing });
  }, [thing]);

  const saveIssue = () => {
    setLoading(true);
    let data = {
      ...issueDTO,
      status: 0,
      user: userInfo,
      account: { id: accountId },
      notificationDate: new Date(),
      dynamicValues: dynamicValues,
    };
    IssueService.save(data, files, onSaveSuccess, onSaveError);
  };

  const onSaveSuccess = (data) => {
    showAlert(!issueDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsNewIssueDialog(false);
    setLoading(false);
    onCreate(data);

    setFiles([]);
    const temp = createNewIssue();
    setIssueDTO(temp);
    setDynamicFormList([]);
    setDynamicValues([]);
  };

  const onSaveError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const handleIssueChange = (prop, val) => {
    setIssueDTO({ ...issueDTO, [prop]: val });
  };

  const noThingOnCheck = (checked) => {
    if (checked) {
      ThingService.getSystemThing(
        accountId,
        (data) => {
          setIssueDTO((prev) => {
            return { ...prev, thing: data };
          });
        },
        (error) => {}
      );
    } else {
      setIssueDTO((prev) => {
        return { ...prev, thing: null };
      });
    }
  };

  useEffect(() => {
    AccPreferencesService.findByAccount(accountId, (data) => {
      setReportIssueDefaultNoThing(data?.reportIssueDefaultNoThing);
      if (data?.reportIssueDefaultNoThing === true) {
        noThingOnCheck(data?.reportIssueDefaultNoThing);
        handleIssueChange("noThing", data?.reportIssueDefaultNoThing);
      }
    });
  }, []);

  const findDynamicFields = (newIssueClass) => {
    if (newIssueClass && newIssueClass.id) {
      DynamicFormService.findFormByName(
        "ISSUE_CLASS",
        accountId,
        newIssueClass.id,
        setDynamicFormList,
        (error) => {
          setDynamicFormList(null);
        }
      );
    } else {
      setDynamicFormList(null);
    }
  };

  return (
    <FaDialog
      title={t("REPORT_ISSUE")}
      data-fa-section="REPORT_ISSUE_CREATE"
      isDocumentUploadOn
      faFiles={files}
      faSetFiles={setFiles}
      faOpen={isNewIssueDialog}
      faOnSubmit={saveIssue}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsNewIssueDialog(false);
        setFiles([]);
        const temp = createNewIssue();
        setIssueDTO(temp);
        if (temp.noThing) noThingOnCheck(temp.noThing);
        setDynamicFormList([]);
        setDynamicValues([]);
      }}
      documentUploadText={t("GORSEL_YUKLE")}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NOTIFIER")}
            required
            onChange={(event) =>
              handleIssueChange("notifier", event.target.value)
            }
            value={issueDTO.notifier || ""}
          />
        </Grid>
        {!issueDTO.noThing && (
          <Grid item xs={12}>
            <ThingSelector
              required={true}
              value={issueDTO.thing}
              accountId={accountId}
              onChange={(data) => {
                setIssueDTO({
                  ...issueDTO,
                  thing: data,
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={issueDTO.noThing}
                onChange={(event) => {
                  setIssueDTO((prev) => {
                    return { ...prev, noThing: event.target.checked };
                  });
                  noThingOnCheck(event.target.checked);
                }}
                color="primary"
              />
            }
            label={isTNF(t("NO_THING_ISSUE"), t("NO_THING_ISSUE_T2C"))}
          />
        </Grid>
        <Grid item xs={12}>
          <IssueClassByThingClassAutoComplete
            value={issueDTO.issueClass}
            required={true}
            accountId={accountId}
            thingClassId={issueDTO.thing?.thingClass?.id}
            onChange={(data) => {
              setIssueDTO({
                ...issueDTO,
                issueClass: data,
              });
              findDynamicFields(data);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              handleIssueChange("description", event.target.value)
            }
            value={issueDTO.description || ""}
          />
        </Grid>
        {dynamicFormList && dynamicFormList.length > 0 && (
          <Grid item xs={12}>
            <DynamicFieldsEdit
              selectedDetails={dynamicFormList}
              details={dynamicFormList}
              data={dynamicValues}
              onValuesChange={onDynamicValuesChange}
            />
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default IssueCreate;
