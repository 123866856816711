import { Grid } from "@mui/material";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";
import { useStyles } from "./style";

const CampaignTeamEdit = forwardRef((props, ref) => {
  const { campaign, onSave } = props;
  const { t } = useTranslation("campaign", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const newCampaignUser = () => {
    return {
      campaign: campaign,
      user: null,
    };
  };

  const [campaignUser, setCampaignUser] = useState(newCampaignUser());

  useEffect(() => {}, []);
  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));
  const saveUser = () => {
    setLoading(true);
    CampaignService.saveUser(
      { ...campaignUser },
      (data) => {
        onSave();
        setIsDialogOpen(false);
        setCampaignUser(newCampaignUser());
        setLoading(false);
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };
  const handleChangeV = (prop, val) => {
    setCampaignUser({ ...campaignUser, [prop]: val });
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("CAMPAIGN_TEAM")}
          faOpen={isDialogOpen}
          faOnSubmit={saveUser}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsDialogOpen(false);
            setCampaignUser(newCampaignUser());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <UserAutoComplete
                value={campaignUser?.user}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeV("user", data);
                }}
                label={t("USER")}
                required={true}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
});

export default CampaignTeamEdit;
