import React, { useState } from "react";

const HashtagInput = ({ existingHashtags = [], onChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [hashtags, setHashtags] = useState(existingHashtags);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const addHashtag = (value) => {
    if (value.trim() && !hashtags.includes(value.trim())) {
      const newHashtags = [...hashtags, value.trim()];
      setHashtags(newHashtags);
      onChange(newHashtags); // Güncellenen hashtagleri parent'a ilet
    }
    setInputValue(""); // Giriş alanını temizle
  };

  const removeHashtag = (tag) => {
    const filteredHashtags = hashtags.filter((t) => t !== tag);
    setHashtags(filteredHashtags);
    onChange(filteredHashtags); // Güncellenen hashtagleri parent'a ilet
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addHashtag(`#${inputValue.replace(/^#/, "")}`); // '#' ile başlamazsa ekle
    }
  };

  return (
    <div
      style={{ border: "1px solid #ccc", padding: "10px", borderRadius: "5px" }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          marginBottom: "10px",
        }}
      >
        {hashtags.map((tag, index) => (
          <div
            key={index}
            style={{
              display: "inline-flex",
              alignItems: "center",
              backgroundColor: "#f0f0f0",
              borderRadius: "3px",
              padding: "5px 10px",
            }}
          >
            {tag}
            <button
              onClick={() => removeHashtag(tag)}
              style={{
                marginLeft: "5px",
                backgroundColor: "red",
                color: "white",
                border: "none",
                borderRadius: "50%",
                cursor: "pointer",
                width: "20px",
                height: "20px",
                lineHeight: "20px",
                textAlign: "center",
                padding: 0,
              }}
            >
              ×
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Add a hashtag"
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "3px",
          border: "1px solid #ccc",
        }}
      />
    </div>
  );
};

// Kullanım Örneği
const App = () => {
  const handleHashtagChange = (newHashtags) => {
    console.log("Updated hashtags:", newHashtags);
  };

  return (
    <div>
      <h1>Hashtag Component</h1>
      <HashtagInput
        existingHashtags={["#thing", "#ankara", "#service"]}
        onChange={handleHashtagChange}
      />
    </div>
  );
};

export default App;
