import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";

export default function UserGroupAutoComplete(props) {
  const { value, accountId, onChange, required, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="name"
      faFilterProp="name"
      getOptionLabel={(option) => (option.name ? option.name : "")}
      requestUrl={ACCOUNT.findAccountUserGroups + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label == null ? t("USER_GROUP") : label}
      required={required}
      renderOption={(props, option, { inputValue }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <div>
              <FontAwesomeIcon icon={faUsers} style={{ paddingRight: 4 }} />
              {option.name}
            </div>
          </li>
        );
      }}
    />
  );
}
