import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function LegalTextSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("legalText", { i18n });

  return (
    <FormControl className={classes.w100} required={required}>
      <InputLabel>{t("TEXT_TYPE")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        label={t("TEXT_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"KVKK"}>{t("KVKK")}</MenuItem>
        <MenuItem value={"KULLANICI_SOZLESMESI"}>
          {t("KULLANICI_SOZLESMESI")}
        </MenuItem>
        <MenuItem value={"LISANS_SOZLESMESI"}>
          {t("LISANS_SOZLESMESI")}
        </MenuItem>
        <MenuItem value={"TICARI_ELEKTRONIK_ILETI_BILGILENDIRME"}>
          {t("TICARI_ELEKTRONIK_ILETI_BILGILENDIRME")}
        </MenuItem>
        <MenuItem value={"TICARI_ELEKTRONIK_ILETI_RIZA"}>
          {t("TICARI_ELEKTRONIK_ILETI_RIZA")}
        </MenuItem>
        <MenuItem value={"FAYDALANICI_AYDINLATMA_METNI"}>
          {t("FAYDALANICI_AYDINLATMA_METNI")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
