import {
  Card,
  CardContent,
  CardHeader,
  Link,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import React, { useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getAccountId } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ThingChangeService } from "services/ThingChangeService";
import { ThingService } from "services/thing/thingService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingChangeDetail() {
  const { t } = useTranslation("thingChange", { i18n });
  const { id } = useParams();
  const accountId = useSelector(getAccountId); //localStorage.accountId;
  const labelClasses = labelStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [thingChange, setThingChange] = useState({});

  useEffect(() => {
    if (accountId)
      ThingChangeService.findById(
        id,
        (data) => {
          setThingChange(data);
        },
        (error) => {}
      );
  }, [accountId]);

  const downloadDocument = () => {
    ThingService.downloadDocument(
      thingChange.thing.id,
      thingChange.fileObjectId,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  return <>
    <Grid item container spacing={1}>
      <Grid item xs={12} lg={12}>
        <Card>
          <CardHeader
            title={t("THING_CHANGE_TITLE")}
            data-fa-section="THING_CHANGE_TITLE"
          />
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {isTNF(t("THING"), t("THING_T2C"))}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <ThingDetail value={thingChange.thing} />
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("CHANGE_DATE")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {getDateTimeWFormat(thingChange.changeDate)}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DESCRIPTION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {thingChange.description}
                </Typography>
              </Grid>

              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("OLD_OWNER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={thingChange.oldOwnerUser} type="USER" />
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail
                    value={thingChange.oldOwnerUserGroup}
                    type="USER_GROUP"
                  />
                </Typography>
                <Typography
                  className={labelClasses.LabelValue}
                  style={{ fontSize: 11 }}
                >
                  {thingChange.oldOwnerUserText}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NEW_OWNER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={thingChange.newOwnerUser} type="USER" />
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail
                    value={thingChange.newOwnerUserGroup}
                    type="USER_GROUP"
                  />
                </Typography>
                <Typography
                  className={labelClasses.LabelValue}
                  style={{ fontSize: 11 }}
                >
                  {thingChange.newOwnerUserText}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("OLD_MTNC")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={thingChange.oldMtncUser} type="USER" />
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail
                    value={thingChange.oldMtncUserGroup}
                    type="USER_GROUP"
                  />
                </Typography>
                <Typography
                  className={labelClasses.LabelValue}
                  style={{ fontSize: 11 }}
                >
                  {thingChange.oldMtncUserText}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NEW_MTNC")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={thingChange.newMtncUser} type="USER" />
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail
                    value={thingChange.newMtncUserGroup}
                    type="USER_GROUP"
                  />
                </Typography>
                <Typography
                  className={labelClasses.LabelValue}
                  style={{ fontSize: 11 }}
                >
                  {thingChange.newMtncUserText}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("OLD_USER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={thingChange.oldUser} type="USER" />
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail
                    value={thingChange.oldUserGroup}
                    type="USER_GROUP"
                  />
                </Typography>
                <Typography
                  className={labelClasses.LabelValue}
                  style={{ fontSize: 11 }}
                >
                  {thingChange.oldUserText}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NEW_USER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={thingChange.newUser} type="USER" />
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail
                    value={thingChange.newUserGroup}
                    type="USER_GROUP"
                  />
                </Typography>
                <Typography
                  className={labelClasses.LabelValue}
                  style={{ fontSize: 11 }}
                >
                  {thingChange.newUserText}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("OLD_ORGANIZATION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {thingChange.oldOrganization?.name}
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NEW_ORGANIZATION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {thingChange.newOrganization?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DOCUMENT")}
                </Typography>
                {thingChange.fileObjectId && (
                  <Typography className={labelClasses.LabelValue}>
                    <Link
                      onClick={() => {
                        downloadDocument();
                      }}
                      title={t("DOWNLOAD")}
                      className={labelClasses.SimpleLink}
                      underline="hover">
                      <Typography className={labelClasses.LabelValue}>
                        {t("ZIMMET_DOKUMANI")}
                      </Typography>
                    </Link>
                  </Typography>
                )}
                {!thingChange.fileObjectId && (
                  <Typography className={labelClasses.LabelValue}>
                    {t("ZIMMET_DOKUMANI_YOK")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}></Grid>
    </Grid>
  </>;
}
