import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingsEventsHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim noktanızla ilgili <b>Hatırlatıcı</b> oluşturmak için
            kullanılan alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='EVENT_EDIT_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            <b>Hatırlatıcı </b> oluşturmak için tıklayın.
          </TutorialElement>
        ),
      },

      {
        element: "[data-fa-section='EVENT_DIALOG']",
        intro: (
          <TutorialElement>
            <b>Tanım</b> ve <b>Açıklama</b> bilgilerini girin ve{" "}
            <b>Kullanıcı</b> girin.
            <br />
            <br />
            Sabit tarihli hatırlatıcı oluşturmak için <b>Sabit</b>’e tıklayın ve{" "}
            <b>Tarih</b> seçin. <b>Göreli</b> hatırlatıcı için Göreli’ye
            tıklayın ve <b>Ek Alan</b> seçin.
            <br />
            <br />
            Hatırlatıcı <b>Bildirim Türü</b> olarak{" "}
            <b>“Mobil Bildirim”, “E-Posta” ve “SMS”</b> seçeneklerinden birini
            seçin. <b>Zaman Birimi</b> seçin ve <b>Süre</b> girin. <b>“Önce”</b>{" "}
            ve <b>“Sonra”</b> seçeneklerinden birini seçin ve <b>Kaydet</b>’e
            basın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='EVENT_EDIT_BUTTON']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingsEventsHelp.propTypes = {
  value: PropTypes.string,
};
