import { Chip, Typography } from "@mui/material";
import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { THINGCLASS } from "../../services/faApi";

export default function ThingClassAutoComplete(props) {
  const {
    value,
    accountId,
    onChange,
    required,
    filterExists,
    multiple,
    label,
    product,
  } = props;

  const { t } = useTranslation("components", { i18n });
  const accountName = localStorage.accountName;
  const labelClasses = labelStyles();

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="className"
      faInfinite
      pageSize={100}
      faFilterProp="className"
      getOptionLabel={(option) => (option.className ? option.className : "")}
      requestUrl={
        THINGCLASS.findClassName +
        "?accountId=" +
        accountId +
        (filterExists === true ? "&exist=true" : "") +
        (product === true ? "&domain=product" : "")
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ?? isTNF(t("THING_CLASS"), t("THING_CLASS_T2C"))}
      required={required}
      multiple={multiple}
      renderOption={(props, option, { inputValue }) => {
        const { key, ...optionProps } = props;

        return (
          <li key={key} {...optionProps}>
            <span
              style={{
                fontSize: "14px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {option.className}
              <Chip
                label={option.domain}
                size="small"
                style={{ textAlign: "right", marginLeft: 10 }}
              />
              <Typography className={labelClasses.LabelHeader}>
                {option?.system ? t("SYSTEM") : accountName}
              </Typography>
            </span>
          </li>
        );
      }}
    />
  );
}
