import { Grid } from "@mui/material";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import React, { forwardRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IssueService } from "services/issue/issueService";
import i18n from "../../i18n";

const ChangeOwner = forwardRef((props, ref) => {
  const { onChange, onClose, issueId } = props;

  const accountId = localStorage.accountId;

  const { t } = useTranslation("issue", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);

  const newOwner = () => {
    return {
      user: null,
      userGroup: null,
      issueId: null,
    };
  };
  const [owner, setOwner] = useState(newOwner());

  const saveSuccess = (data) => {
    showAlert(t("Sahip değiştirildi"), "success");
    setOwner(newOwner());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveOwner = () => {
    setLoading(true);

    IssueService.changeOwner(
      issueId,
      owner.user?.id,
      owner.userGroup?.id,
      saveSuccess,
      saveError
    );
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("Sahip Değiştir")}
          faOpen={true}
          faOnSubmit={saveOwner}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setOwner(newOwner());
          }}
          faMaxWidth="sm"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <UserAndGroupAutoComplete
                value={{
                  user: owner.user,
                  userGroup: owner.userGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setOwner({
                    ...owner,
                    user: data ? (data.user ? data.user : null) : null,
                    userGroup: data
                      ? data.userGroup
                        ? data.userGroup
                        : null
                      : null,
                  });
                }}
                label={t("USER")}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return <>{newFormRender()}</>;
});

export default ChangeOwner;
