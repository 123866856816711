import React from "react";
import LanguageSelectButton from "./LanguageSelectButton";

export default function ThingsUnAuthPages(props) {
  const { children } = props;

  return (
    <div>
      <LanguageSelectButton />
      {children}
    </div>
  );
}
