import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { PUBLIC } from "../../services/faApi";

export default function CountryAutoComplete(props) {
  const { value, onChange, required, disabled } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="nameTr"
      faFilterProp="name"
      getOptionLabel={(option) => (option.nameTr ? option.nameTr : "")}
      requestUrl={PUBLIC.findCountry + "?page=0&size=20"}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={t("COUNTRY")}
      required={required}
      disabled={disabled}
    />
  );
}
