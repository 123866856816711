import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import i18n from "i18n";
import React, { useEffect, useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReportService } from "services/report/reportService";

export default function PublicDocument(props) {
  const { value, publicThing, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [isOpenDocumentDialog, setIsOpenDocumentDialog] = useState(false);

  useEffect(() => {
    if (value != null) {
      setIsOpenDocumentDialog(true);
      downloadDocument();
    } else {
      setIsOpenDocumentDialog(false);
    }
  }, [value]);

  const downloadDocument = () => {
    setIsOpenDocumentDialog(true);
    ReportService.downloadDocument(
      publicThing.id,
      value.object,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const iframe = document.createElement("iframe");
    iframe.setAttribute("title", "document");
    iframe.setAttribute("style", "width: 100%; height: 100vh;");
    iframe.src = "data:" + data.mimeType + ";base64," + data.content;
    removeAllChildNodes(document.getElementById("documentDiv"));
    document.getElementById("documentDiv").appendChild(iframe);
  };

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  const downloadDocuemntOnError = (data) => {};

  const renderDocumentDialog = () => {
    return (
      <Dialog
        open={isOpenDocumentDialog}
        onClose={() => {
          setIsOpenDocumentDialog(false);
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            style={{ position: "absolute", right: 0, top: 0 }}
            onClick={() => {
              setIsOpenDocumentDialog(false);
              onClose();
            }}
            size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div id="documentDiv" style={{ width: "100%", height: "100%" }}></div>
        </DialogContent>
      </Dialog>
    );
  };

  return <>{renderDocumentDialog()}</>;
}
