import { Chip, Grid, Paper, Typography } from "@mui/material";
import { default as React, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CampaignNumbers(props) {
  const { campaign } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  const faDialog = useDialog();

  let history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedAward, setSelectedAward] = useState(false);
  const [numbers, setNumbers] = useState();

  useEffect(() => {
    if (campaign) refresh();
  }, [campaign]);

  const refresh = () => {
    setLoading(true);
    CampaignService.getCampaignNumbers(
      campaign.id,
      campaign.account.id,
      setNumbers,
      () => {}
    );
  };

  const navigateAll = () => {
    const tmpTo = {
      pathname: `/campaign/customers/${campaign.id}`,
      breadCrumbKey: `/campaign/customers/:id`,
    };
    history.push(tmpTo);
  };

  const navigateVerified = () => {
    const tmpTo = {
      pathname: `/campaign/customers/${campaign.id}`,
      breadCrumbKey: `/campaign/customers/:id`,
      state: {
        status: "VERIFIED",
      },
    };
    history.push(tmpTo);
  };

  const navigateRegistered = () => {
    const tmpTo = {
      pathname: `/campaign/customers/${campaign.id}`,
      breadCrumbKey: `/campaign/customers/:id`,
      state: {
        status: "REGISTERED",
      },
    };
    history.push(tmpTo);
  };

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item xs={4}>
        <Paper className={classes.NumberPanel} onClick={navigateAll}>
          <Typography
            component="p"
            variant="h6"
            style={{ textAlign: "center" }}
          >
            Toplam Katılımcı
          </Typography>
          <Chip size="small" label={numbers?.total} />
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.NumberPanel} onClick={navigateVerified}>
          <Typography
            component="p"
            variant="h6"
            style={{ textAlign: "center" }}
          >
            Doğrulanmış
          </Typography>
          <Chip
            style={{ backgroundColor: "#38B64A", color: "white" }}
            size="small"
            label={numbers?.verified}
          />
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper className={classes.NumberPanel} onClick={navigateRegistered}>
          <Typography
            component="p"
            variant="h6"
            style={{ textAlign: "center" }}
          >
            Bekleyen
          </Typography>
          <Chip
            style={{ backgroundColor: "#F67B2E", color: "white" }}
            size="small"
            label={numbers?.registered}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
