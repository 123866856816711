import { Edit } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import ThingStatusSelect from "components/select/ThingStatusSelect";
import ThingAdditionalFields from "features/thingDetail/ThingAdditionalFields";
import update from "immutability-helper";
import React, { Suspense, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import {
  getDateTimeWFormat,
  getDateWFormat,
  getThingsApp,
  getTimeWFormat,
} from "services/utils";
import { APP } from "services/utils/Constants";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function MissingData() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation(["missingData", "additionalFields"], { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [thingClass, setThingClass] = useState(false);
  const [missingList, setMissingList] = useState([]);
  const [dynamicFormList, setDynamicFormList] = useState([]);

  const [selectedThing, setSelectedThing] = useState(null);
  const [isEditRow, setIsEditRow] = useState(false);
  const [thingClassList, setThingClassList] = useState([]);

  const getEksikBilgi = (row) => {
    const ekAlanList = dynamicFormList.filter(
      (item) => item.id2 === row.thingClassId
    );
    let eksikEkAlanList = [];

    ekAlanList.forEach((item) => {
      var deger = getDetailValue(row, item);
      if (deger && String(deger).length > 0) {
        //dolu
      } else eksikEkAlanList.push(item.label);
    });

    return eksikEkAlanList.map((item) => {
      return <Chip label={item} />;
    });
  };

  const getDetailValue = (row, dynamicForm) => {
    if (row.detailData) {
      if (
        dynamicForm.dataType === "STRING" ||
        dynamicForm.dataType === "TEXT" ||
        dynamicForm.dataType === "FILE"
      ) {
        return row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
          ?.dataString;
      } else if (
        dynamicForm.dataType === "NUMERIC" ||
        dynamicForm.dataType === "MONEY"
      ) {
        return row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
          ?.dataNumeric;
      } else if (dynamicForm.dataType === "DATE") {
        return getDateWFormat(
          row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
            ?.dataDate
        );
      } else if (dynamicForm.dataType === "DATE_TIME") {
        return getDateTimeWFormat(
          row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
            ?.dataDate
        );
      } else if (dynamicForm.dataType === "TIME") {
        return getTimeWFormat(
          row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
            ?.dataDate
        );
      } else if (dynamicForm.dataType === "BOOLEAN") {
        return !!row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
          ?.dataBool;
      } else if (dynamicForm.dataType === "YES_NO") {
        const dataBool = row.detailData.find(
          (x) => x.dynamicFormId === dynamicForm.id
        )?.dataBool;
        return dataBool == null
          ? ""
          : !!dataBool
          ? dynamicForm.yesLabel
          : dynamicForm.noLabel;
      } else if (dynamicForm.dataType === "ENUMS") {
        return row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
          ?.dataString;
      } else if (dynamicForm.dataType === "JSON") {
        return row.detailData.find((x) => x.dynamicFormId === dynamicForm.id)
          ?.dataString;
      }
    }
  };

  const editRow = (row) => {
    setSelectedThing({
      id: row.id,
      account: { id: accountId },
      thingClass: { id: row.thingClassId },
    });
    setIsEditRow(true);
  };

  const dynamicFormDataChanged = (dynamicValues) => {
    let index = -1;
    missingList.find(function (item, i) {
      if (item.id === selectedThing.id) {
        index = i;
        return i;
      }
    });

    var updatedItem = update(missingList[index], {
      detailData: { $set: dynamicValues },
    });
    var newData = update(missingList, {
      $splice: [[index, 1, updatedItem]],
    });
    setMissingList(newData);

    setIsEditRow(false);
    setSelectedThing(null);
  };

  const newSearchForm = () => {
    let result = {
      thingClassList: [],
      thingClassIdList: [],
      vendorList: [],
      vendorIdList: [],
      summary: null,
      accountId: accountId,
      status: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    ThingService.searchMissingData(
      searchForm,
      paging,
      (data) => {
        setMissingList(data.missingList);
        setDynamicFormList(data.dynamicFormList);
      },
      (error) => {}
    );
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    ThingService.searchMissingData(
      { ...searchForm, summary: summary },
      paging,
      (data) => {
        setMissingList(data.missingList);
        setDynamicFormList(data.dynamicFormList);
      },
      (error) => {}
    );
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      thingClassList: [],
      thingClassIdList: [],
      vendorList: [],
      vendorIdList: [],
      summary: null,
      accountId: accountId,
      status: null,
    });
  };

  const renderThumbnail = (thing) => {
    if (thing.thumbnail) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="Etkileşim Noktası"
            src={`data:image/png;base64, ${thing.thumbnail}`}
            style={{
              width: "48px",
              height: "40px",
              marginRight: "10px",
            }}
          />
          {" " + thing.identifier}
        </div>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            variant="square"
            style={{ width: "48px", marginRight: "10px" }}
          >
            {" "}
            {thing.identifier.charAt(0)}
          </Avatar>{" "}
          {thing.identifier}
        </div>
      );
    }
  };

  return (
    <>
      {selectedThing && isEditRow && (
        <ThingAdditionalFields
          thing={selectedThing}
          editMode={true}
          onChange={dynamicFormDataChanged}
        />
      )}
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={6}>
          <div>{searchInfo}</div>
        </Grid>
        <Grid item xs={6}>
          <FaSearch
            onSearch={searchSubmit}
            onClear={searchClear}
            onSummarySearch={searchSummary}
            setSearchInfo={setSearchInfo}
            faSummary={searchForm?.summary}
            faClassName="appSearchBar"
          >
            <ThingClassAutoComplete
              accountId={accountId}
              value={searchForm.thingClassList}
              filterExists={true}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  thingClassList: data,
                  thingClassIdList: data?.map((item) => {
                    return item.id;
                  }),
                });
              }}
              multiple
            />

            <VendorAutoComplete
              accountId={accountId}
              value={searchForm.vendorList}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  vendorList: data,
                  vendorIdList: data?.map((item) => {
                    return item.id;
                  }),
                });
              }}
              multiple
            />
            <ThingStatusSelect
              value={searchForm.status}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  status: data,
                });
              }}
            />
          </FaSearch>
        </Grid>
      </Grid>

      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Card style={{ minHeight: 100 }} className="fai-additionalFields">
              <CardHeader
                title={
                  (getThingsApp() === APP.T2C
                    ? t("THING_LIST_T2C")
                    : t("THING_LIST")) +
                  " (" +
                  missingList.length +
                  ")"
                }
                data-fa-section={t("INTERACTION_POINT_LIST")}
              />
              <CardContent>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {getThingsApp() === APP.T2C
                              ? t("THING_T2C")
                              : t("THING")}
                          </TableCell>
                          <TableCell>{t("MISSING_INFO")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {missingList.map((value, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{renderThumbnail(value)}</TableCell>
                              <TableCell>{getEksikBilgi(value)}</TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={() => {
                                    editRow(value);
                                  }}
                                  size="large"
                                >
                                  <Edit />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
