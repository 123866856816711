import { FormHelperText } from "@mui/material";
import React, { useState } from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { ConcatHashtagStr, ParseHashtagStr } from "services/utils";
import i18n from "../../i18n";
import { CUSTOMER } from "../../services/faApi";

export default function CustomerHashtag(props) {
  const { value, accountId, onChange, required, label } = props;
  const { t } = useTranslation("components", { i18n });

  const [inputValue, setInputValue] = useState(""); // Giriş değerini takip etmek için state

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Form submit işlemini engelle
      if (
        inputValue.trim() &&
        !ParseHashtagStr(value).includes(inputValue.trim())
      ) {
        const updatedHashtags = [...ParseHashtagStr(value), inputValue.trim()];
        onChange(ConcatHashtagStr(updatedHashtags));
        setInputValue(""); // Giriş alanını temizle
      }
    }
  };

  const handleBlur = () => {
    if (
      inputValue.trim() &&
      !ParseHashtagStr(value).includes(inputValue.trim())
    ) {
      const updatedHashtags = [...ParseHashtagStr(value), inputValue.trim()];
      onChange(ConcatHashtagStr(updatedHashtags));
      setInputValue(""); // Giriş alanını temizle
    }
  };

  return (
    <div>
      <FaAutoComplete
        faType="async"
        value={ParseHashtagStr(value)}
        required={required}
        faMultiple={true}
        limitTags={20}
        isOptionEqualToValue={(option, value) => {
          return option === value;
        }}
        sort="filter"
        faFilterProp="filter"
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option;
        }}
        requestUrl={CUSTOMER.findHashtag + "?accountId=" + accountId}
        onChange={(event, newValue) => {
          onChange(ConcatHashtagStr(newValue));
        }}
        onInputChange={(event, newValue) => {
          setInputValue(newValue); // Kullanıcı girdisini state ile takip et
        }}
        onKeyDown={handleKeyDown} // Enter tuşu işleme
        onBlur={handleBlur} // Odaktan çıkma işleme
        freeSolo // Kullanıcının serbest giriş yapmasına izin ver
        inputValue={inputValue} // Giriş değerini kontrol et
        label={label == null ? t("Hashtag") : label}
      />
      <FormHelperText>{t("HASHTAG.HELPER_TEXT")}</FormHelperText>
    </div>
  );
}
