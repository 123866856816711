import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import AuthCode from "react-auth-code-input";
import { FaButton } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { THINGS_USER, USER } from "services/faApi";
import { useStyles } from "./style";

const EmailVerificationCode = forwardRef((props, ref) => {
  const { t } = useTranslation("report", { i18n });
  const { email, onSuccess } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  let timer2 = 0;
  let seconds2 = 180;

  useEffect(() => {
    emailConfirmation();
  }, []);

  const [confirmationHash, setConfirmationHash] = useState("");
  const [confirmationToken, setConfirmationToken] = useState("");
  const [isCountDownOn2, setIsCountDownOn2] = useState(false);
  const [countDownTime2, setCountDownTime2] = useState();

  const [isActivated, setIsActivated] = useState(false);

  useImperativeHandle(ref, () => ({
    start() {
      emailConfirmation();
    },
  }));

  async function emailConfirmation() {
    startTimer2();
    getMailConfirmationToken();
  }

  async function getMailConfirmationToken() {
    let req = requests.getSimpleGetRequest(
      THINGS_USER.verificationTokenViaEmail,
      {
        email: email,
      }
    );
    FaAxios(req)
      .then((response) => {
        setConfirmationHash(response.data.HASH);
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }

  function startTimer2() {
    if (timer2 == 0 && seconds2 > 0) {
      setIsCountDownOn2(true);
      timer2 = setInterval(countDown2, 1000);
    }
  }

  function countDown2() {
    let tempSeconds = seconds2 - 1;
    seconds2 = tempSeconds;
    setCountDownTime2(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer2);
      setCountDownTime2(null);
      setIsCountDownOn2(false);
    }
  }

  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  const handleChangeConfirmation = (value) => {
    setConfirmationToken(value);
  };

  async function sendEmailToken() {
    let req = requests.getSimplePostRequest(
      USER.emailVerifyOnly + "/" + confirmationToken + "/" + confirmationHash
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          email: true,
        });
        onSuccess();
      })
      .catch((err) => {
        showAlert(t("EMAIL_CONFIRMATION_ERROR_MSG"), "error");
      });
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      justifyContent="center"
      spacing={2}
      lg={12}
      xs={12}
    >
      <Grid item lg={12} xs={12}>
        <Typography variant="subtitle1">{email}</Typography>
      </Grid>
      <Grid item lg={10} xs={12} style={{ height: 70 }}>
        <AuthCode
          onChange={handleChangeConfirmation}
          containerClassName={classes.container}
          inputClassName={
            !isCountDownOn2 ? classes.inputDisabled : classes.input
          }
          allowedCharacters="numeric"
          disabled={!countDownTime2}
        />
      </Grid>
      <Grid item lg={2} xs={12} style={{ height: 70 }}>
        <Button
          disabled={isCountDownOn2 || isActivated.email}
          variant="contained"
          fullWidth
          component="span"
          color="primary"
          style={{ height: 60 }}
          disableElevation
          onClick={emailConfirmation}
        >
          {countDownTime2
            ? countDownTime2.h * 3600 + countDownTime2.m * 60 + countDownTime2.s
            : t("EPOSTA_GONDER")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <FaButton
          faType="button"
          color="primary"
          size="large"
          onClick={sendEmailToken}
          disabled={confirmationToken === ""}
          style={{ height: "60px" }}
          label={t("Doğrula")}
        ></FaButton>
      </Grid>
    </Grid>
  );
});
export default EmailVerificationCode;

EmailVerificationCode.propTypes = {
  appLogo: PropTypes.any,
};
