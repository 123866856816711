import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { CUSTOMER_LIST } from "../../services/faApi";
import CustomerListEdit from "../customerList/CustomerListEdit";

export default function CustomerList() {
  const tableRef = useRef();
  const { t } = useTranslation(["customerList", "components"], { i18n });

  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    {
      field: "name",
      label: t("Liste Adı"),
      align: "center",
      width: "35%",
    },
    {
      field: "description",
      label: t("Liste Açıklaması"),
      align: "center",
      width: "35%",
    },
    {
      field: "createDate",
      label: t("Liste Oluşturulma Tarihi"),
      align: "center",
      type: "smartDateTime",
      width: "30%",
    },
  ];

  const newSearchForm = () => {
    return {
      name: null,
      description: null,
      account_id: null,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `customerListDetail/${row.id}`,
      breadCrumbKey: `/customerListDetail/:id`,
    };
    history.push(tmpTo);
  };

  const customerListOnChange = (data) => {
    setIsEditFormOpen(false);
    tableRef.current.refreshTable();
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <CustomerListEdit
          onChange={customerListOnChange}
          open={isEditFormOpen}
          onClose={setIsEditFormOpen}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
            >
              {t("Ekle")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
            >
              <FaInput
                label={t("İsim")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("Açıklama")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={CUSTOMER_LIST.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
      </Suspense>
    </>
  );
}
