import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function BooleanDecorator(props) {
  const { value } = props;

  const { t } = useTranslation("components", { i18n });

  const render = () => {
    if (value) return <CheckIcon />;
  };

  return render();
}
