import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    activeColor: {
        color: "#28a745"
    },
    passiveColor: {
        color: "#dc3545"
    },
    boldFont: {
        fontWeight: "bold"
    }
}));