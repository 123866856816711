import {
  Avatar,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const TopThingInteractions = forwardRef((props, ref) => {
  const { showThings } = props;
  const { t } = useTranslation(["customer", "components"], { i18n });

  const labelClasses = labelStyles();

  const [topList, setTopList] = useState(null);

  const [masterSearchForm, setMasterSearchForm] = useState(null);

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        ThingStatisticService.getTopThingCounts(
          searchForm,
          getTopCountsOnSuccess,
          (error) => {}
        );
      }
    },
  }));

  const getTopCountsOnSuccess = (data) => {
    setTopList(data);
  };

  const renderThing = (row) => {
    if (row.thumbnail != null)
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="thing"
            src={`data:image/png;base64, ${row.thumbnail}`}
            style={{
              width: "16px",
              height: "16px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          />{" "}
          <Typography className={labelClasses.LabelValue}>
            {row.identifier}
          </Typography>
        </div>
      );
    else
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            variant="square"
            style={{
              width: "16px",
              height: "16px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          >
            {" "}
            {row.identifier.charAt(0)}
          </Avatar>{" "}
          <Typography className={labelClasses.LabelValue}>
            {row.identifier}
          </Typography>
        </div>
      );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Paper style={{ height: "10rem" }}>
              {topList && (
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="70%">
                          <Typography className={labelClasses.LabelHeader}>
                            Popüler Etkileşim Noktaları
                          </Typography>
                        </TableCell>
                        <TableCell width="30%">
                          <Typography className={labelClasses.LabelHeader}>
                            #
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topList.map((item, index) => (
                        <TableRow key={index}>
                          {showThings && (
                            <TableCell component="th" scope="row">
                              {renderThing(item)}
                            </TableCell>
                          )}
                          <TableCell>
                            <Typography className={labelClasses.LabelValue}>
                              {item.count}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
export default TopThingInteractions;
