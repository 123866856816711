import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import UserDetail from "components/detail/UserDetail";
import CampaignStatusDecorator from "components/ui/CampaignStatusDecorator";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import { getDateTimeWFormat, GetFieldByLanguage } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CampaignAwards from "./CampaignAwards";
import CampaignCalendar from "./CampaignCalendar";
import CampaignCheckList from "./CampaignCheckList";
import CampaignCustomersExport from "./CampaignCustomersExport";
import CampaignDocuments from "./CampaignDocuments";
import CampaignEdit from "./CampaignEdit";
import CampaignNumbers from "./CampaignNumbers";
import CampaignPage from "./CampaignPage";
import CampaignPhoto from "./CampaignPhoto";
import CampaignTarget from "./CampaignTarget";
import CampaignTeam from "./CampaignTeam";

export default function CampaignDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();
  let history = useHistory();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [campaign, setCampaign] = useState({});
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [editStatus, setEditStatus] = useState();
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [selectedLang, setSelectedLang] = useState("tr");

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    CampaignService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setCampaign({
      ...data,
    });
    setEditStatus(data?.status);
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const changeStatus = (status) => {
    if (
      status === "STARTED" &&
      (!campaign.registerStartDate || !campaign.registerEndDate)
    ) {
      showAlert("Başlangıç ve bitiş tarihleri girilmeli", "error");
    } else
      faDialog({
        description: t(
          "Kampanya durumunu değiştirmek istediğinize emin misiniz?"
        ),
        type: "confirm",
      })
        .then((confirmation) => {
          CampaignService.changeStatus(
            id,
            status,
            changeStatusOnSuccess,
            changeStatusOnError
          );
        })
        .catch((err) => console.log("error:", err));
  };

  const changeStatusOnSuccess = (data) => {
    setCampaign({
      ...data,
    });
    refresh();
    setStatusDialogOpen(false);
    setLoading(false);
  };

  const changeStatusOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const navigateToCustomers = () => {
    const tmpTo = {
      pathname: `/campaign/customers/${id}`,
      breadCrumbKey: `/campaign/customers/:id`,
    };
    history.push(tmpTo);
  };
  const editDialogRender = () => {
    return (
      <CampaignEdit
        campaign={campaign}
        ref={dialogRef}
        onSave={onSave}
        currentLang={selectedLang}
      ></CampaignEdit>
    );
  };
  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const onMenuItemClick = (event, index) => {
    setAnchor(null);
    setSelectedLang(index);
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      {editDialogRender()}
      <CampaignCustomersExport
        campaign={campaign}
        open={isOpenExport}
        onClose={() => {
          setIsOpenExport(false);
        }}
      />
      <Grid container spacing={1}>
        <Grid item lg={6}>
          <Grid item container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  title={
                    campaign.type === "CAMPAIGN"
                      ? t("CAMPAIGN_INFO")
                      : t("EVENT_INFO")
                  }
                  data-fa-section="CAMPAIGN_INFO"
                  action={
                    <div style={{ marginRight: "10px" }}>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                        size="small"
                        style={{
                          color: "#532363",
                          marginRight: "10px",
                          fontSize: "22px",
                        }}
                        title={selectedLang.toUpperCase()}
                      >
                        <ReactCountryFlag
                          countryCode={
                            selectedLang === "en"
                              ? "US"
                              : selectedLang.toUpperCase()
                          }
                          svg
                        />
                      </IconButton>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchor}
                        keepMounted
                        open={Boolean(anchor)}
                        onClose={handleClose}
                      >
                        {campaign &&
                          campaign.languages &&
                          campaign.languages.map((lang) => (
                            <MenuItem
                              onClick={(event) =>
                                onMenuItemClick(event, lang.toLowerCase())
                              }
                            >
                              <ReactCountryFlag
                                countryCode={
                                  lang === "EN" ? "US" : lang.toUpperCase()
                                }
                                svg
                              />
                            </MenuItem>
                          ))}
                      </Menu>
                      <IconButton
                        disabled={campaign.status !== "DRAFT"}
                        aria-label="settings"
                        onClick={() => openEditDialog()}
                        size="large">
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          navigateToCustomers();
                        }}
                        size="small"
                        style={{ color: "#532363", fontSize: "20px" }}
                        title={
                          campaign.type === "CAMPAIGN"
                            ? t("CAMPAIGN_PARTICIPANTS")
                            : t("EVENT_PARTICIPANTS")
                        }
                      >
                        <FontAwesomeIcon icon={faUsers} />
                      </IconButton>
                    </div>
                  }
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("NAME")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {GetFieldByLanguage(
                              campaign.multiLangName,
                              selectedLang
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DESCRIPTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {GetFieldByLanguage(
                              campaign.multiLangDescription,
                              selectedLang
                            )}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("REGISTER_START_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(campaign.registerStartDate)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("REGISTER_END_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(campaign.registerEndDate)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("MANAGER_USER")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            <UserDetail
                              value={campaign?.managerUser}
                              type="USER"
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("USER_VERIFICATION_TYPE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t(campaign.userVerificationType)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("Katılım Geri Bildirim Mesajı")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t(campaign.messageTemplate?.description)}
                          </Typography>
                        </Grid>
                        {campaign.drawRate && (
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DRAW_RATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              Her {t(campaign.drawRate)} {campaign.currency}{" "}
                              alışveriş için 1 çekiliş hakkı
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("STATUS")}
                          </Typography>
                          <Typography
                            component={"span"}
                            variant={"body2"}
                            className={labelClasses.LabelValue}
                          >
                            <CampaignStatusDecorator
                              value={campaign.status}
                            />
                          </Typography>
                        </Grid>

                        {campaign.type === "EVENT" && (
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("QUOTA")}
                            </Typography>
                            <Typography
                              component={"span"}
                              variant={"body2"}
                              className={labelClasses.LabelValue}
                            >
                              {campaign.totalParticipants} /{" "}
                              {campaign.quota ?? "?"}
                            </Typography>
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          {campaign.status === "DRAFT" && (
                            <FaButton
                              style={{
                                width: "25%",
                                marginRight: 5,
                                marginBottom: 5,
                              }}
                              variant="contained"
                              size="small"
                              color="primary"
                              faClick={() => {
                                changeStatus("STARTED");
                              }}
                              //startIcon={<Refresh />}
                              data-fa-button="START"
                            >
                              {t("START")}
                            </FaButton>
                          )}
                          {(campaign.status === "STARTED" ||
                            campaign.status === "CANCELED") && (
                            <>
                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                                faClick={() => {
                                  changeStatus("DRAFT");
                                }}
                                //startIcon={<Refresh />}
                                data-fa-button="DRAFT"
                              >
                                {t("TURN_TO_DRAFT")}
                              </FaButton>
                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                                faClick={() => {
                                  changeStatus("REGISTER_CLOSED");
                                }}
                                //startIcon={<Refresh />}
                                data-fa-button="GET_REGISTER_CLOSED"
                              >
                                {t("GET_REGISTER_CLOSED")}
                              </FaButton>
                            </>
                          )}
                          {campaign.status === "REGISTER_CLOSED" && (
                            <>
                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                                faClick={() => {
                                  changeStatus("STARTED");
                                }}
                                //startIcon={<Refresh />}
                                data-fa-button="TURN_TO_STARTED"
                              >
                                {t("TURN_TO_STARTED")}
                              </FaButton>

                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                                faClick={() => {
                                  changeStatus("FINISHED");
                                }}
                                //startIcon={<Refresh />}
                                data-fa-button="FINISH"
                              >
                                {t("FINISH")}
                              </FaButton>
                            </>
                          )}

                          {campaign.status !== "DRAFT" &&
                            campaign.status !== "FINISHED" &&
                            campaign.status !== "CANCELED" && (
                              <FaButton
                                style={{
                                  width: "25%",
                                  marginRight: 5,
                                  marginBottom: 5,
                                }}
                                variant="contained"
                                size="small"
                                color="primary"
                                faClick={() => {
                                  changeStatus("CANCELED");
                                }}
                                //startIcon={<Refresh />}
                                data-fa-button="CANCEL"
                              >
                                {t("CANCEL")}
                              </FaButton>
                            )}

                          <FaButton
                            style={{
                              width: "25%",
                              marginRight: 5,
                              marginBottom: 5,
                            }}
                            variant="contained"
                            size="small"
                            color="primary"
                            faClick={() => {
                              setIsOpenExport(true);
                            }}
                            data-fa-button="EXPORT_CUSTOMERS"
                          >
                            {t("EXPORT_CUSTOMERS")}
                          </FaButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={12}>
              {campaign && campaign.id > 0 && (
                <CampaignCalendar campaign={campaign}></CampaignCalendar>
              )}
            </Grid>
            <Grid item xs={12}>
              {campaign && campaign.id > 0 && (
                <CampaignPage
                  campaign={campaign}
                  currentLang={selectedLang}
                ></CampaignPage>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6}>
          <Grid item container spacing={1}>
            {campaign.type === "CAMPAIGN" && (
              <Grid item xs={12}>
                {campaign && campaign.id > 0 && (
                  <CampaignNumbers campaign={campaign} />
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              {campaign && campaign.id > 0 && (
                <CampaignPhoto
                  campaign={campaign}
                  onChange={refresh}
                ></CampaignPhoto>
              )}
            </Grid>

            {campaign.type === "CAMPAIGN" && (
              <Grid item xs={12}>
                {campaign && campaign.id > 0 && (
                  <CampaignAwards campaign={campaign}></CampaignAwards>
                )}
              </Grid>
            )}

            {campaign.type === "CAMPAIGN" && (
              <Grid item xs={12}>
                {campaign && campaign.id > 0 && (
                  <CampaignCheckList campaign={campaign}></CampaignCheckList>
                )}
              </Grid>
            )}

            <Grid item xs={12}>
              {campaign && campaign.id > 0 && (
                <CampaignTarget campaign={campaign}></CampaignTarget>
              )}
            </Grid>

            <Grid item xs={12}>
              {campaign && campaign.id > 0 && (
                <CampaignDocuments campaign={campaign}></CampaignDocuments>
              )}
            </Grid>

            <Grid item xs={12}>
              {campaign && campaign.id > 0 && (
                <CampaignTeam campaign={campaign}></CampaignTeam>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Suspense>
  </>;
}
