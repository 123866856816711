import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ActivePassiveButtonGroup from "components/buttonGroup/ActivePassiveButtonGroup";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { QueueService } from "services/QueueService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { QUEUE } from "../../services/faApi";
import QueueEdit from "./QueueEdit";

export default function Queue() {
  const tableRef = useRef();
  const { t } = useTranslation(["queue", "components"], { i18n });
  const dispatch = useDispatch();
  const labelClasses = labelStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [orderDilaogOpen, setOrderDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [newOrder, setNewOrder] = useState();

  const columns = [
    {
      field: "orderNo",
      label: "#",
      align: "center",
      width: "5%",
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "20%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "managerUser",
      label: t("MANAGER_USER"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => <UserDetail value={val} type="USER" />,
    },
    {
      field: "schedulePlan",
      label: t("SCHEDULE_PLAN"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => val?.name,
    },
    {
      field: "dispatchMethod",
      label: t("DISPATCH_METHOD"),
      align: "center",
      width: "15%",
      valueFormatter: (val) =>
        t("QUEUE_DISPATCH_METHOD_" + val, { ns: "components" }),
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        if (row.status === "ACTIVE")
          return (
            <>
              {renderMoveButton(row, true)}
              {renderMoveButton(row, false)}
              {renderOrderNoButton(row)}
            </>
          );
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      name: null,
      description: null,
      status: "ACTIVE",
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `queue/detail/${row.id}`,
      breadCrumbKey: `/queue/detail/:id`,
    };
    history.push(tmpTo);
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onCreate = (data) => {
    const tmpTo = {
      pathname: `/queue/detail/${data.id}`,
      breadCrumbKey: `/queue/detail/:id`,
    };
    history.push(tmpTo);
  };

  const addNewQueueRender = () => {
    return <QueueEdit ref={dialogRef} onSave={onCreate}></QueueEdit>;
  };

  const renderOrderNoButton = (row) => {
    return (
      <IconButton
        onClick={() => {
          setSelectedRow(row);
          setOrderDialogOpen(true);
        }}
        size="small"
        color="primary"
        aria-label={t("ENTER_ORDER_NO")}
      >
        <FormatListNumberedIcon />
      </IconButton>
    );
  };

  const renderOrderDialog = () => {
    return (
      <FaDialog
        title={"Yeni sıra numarası giriniz"}
        faOpen={orderDilaogOpen}
        faOnSubmit={changeOrder}
        showSaveButton
        faHandleClose={(data) => {
          setOrderDialogOpen(false);
          setNewOrder(null);
        }}
      >
        <Grid container spacing={1}>
          <Grid item lg={12} xs={12}>
            <FaInput
              required
              label={t("ORDER_NO")}
              value={newOrder}
              onChange={(event) => setNewOrder(event.target.value)}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const changeOrder = () => {
    QueueService.changeOrder(
      accountId,
      selectedRow.id,
      newOrder,
      () => {
        setOrderDialogOpen(false);
        tableRef.current.refreshTable();
        setNewOrder(null);
        setSelectedRow(null);
      },
      moveOnError
    );
  };

  const renderMoveButton = (row, up) => {
    return (
      <IconButton
        onClick={() => (up === true ? moveUp(row) : moveDown(row))}
        size="small"
        color="primary"
        aria-label={up === true ? t("MOVE_UP") : t("MOVE_DOWN")}
      >
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };
  const moveUp = (row) => {
    QueueService.moveUp(accountId, row.id, moveOnSuccess, moveOnError);
  };

  const moveDown = (row) => {
    QueueService.moveDown(accountId, row.id, moveOnSuccess, moveOnError);
  };

  const moveOnSuccess = (data) => {
    tableRef.current.refreshTable();
  };
  const moveOnError = (data) => {};

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addNewQueueRender()}
        {renderOrderDialog()}
        <Grid item container spacing={0} style={{ marginBottom: 5 }}>
          <Grid item xs={3}>
            <Grid item xs={12}>
              <ActivePassiveButtonGroup
                value={searchForm.status}
                onChange={(data) => {
                  const temp = { ...searchForm, status: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={QUEUE.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD_QUEUE"
            >
              {t("ADD_QUEUE")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                value={searchForm.descripton}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
