import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import AccountPeriodicTaskHelp from "features/help/AccountPeriodicTaskHelp";
import React, { useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { AccountPreferencesService } from "services/AccountPreferencesService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountPreferencesPeriodic(props) {
  const { accountPreferences, onSave } = props;
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const labelClasses = labelStyles();
  const classes = useStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editValue, setEditValue] = useState({});

  const editButtonClicked = () => {
    setEditValue({ ...accountPreferences });
    setIsEditOpen(true);
  };

  const handleChange = (prop) => (event) => {
    setEditValue({ ...editValue, [prop]: event.target.value });
  };

  const savePeriodic = () => {
    AccountPreferencesService.savePeriodicPreferences(
      editValue,
      () => {
        onSave();
        setIsEditOpen(false);
      },
      () => {}
    );
  };

  const renderDialog = () => {
    return (
      isEditOpen && (
        <FaDialog
          title={t("PERIODIC_TASK_PREFERENCES")}
          faOpen={isEditOpen}
          faOnSubmit={savePeriodic}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditOpen(false);
          }}
          faMaxWidth="sm"
        >
          <Grid
            container
            spacing={1}
            data-fa-section="PERIODIC_TASK_PREFERENCES_EDIT"
          >
            <Grid item lg={12} xs={12}>
              Periyodik Görev Kapatma Limitleri (Dakika)
            </Grid>

            <Grid item xs={4}>
              Saatlik
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Önce")}
                faType="number"
                onChange={handleChange("taskBeforeHour")}
                value={editValue.taskBeforeHour}
              />
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Sonra")}
                faType="number"
                onChange={handleChange("taskAfterHour")}
                value={editValue.taskAfterHour}
              />
            </Grid>

            <Grid item xs={4}>
              Günlük
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Önce")}
                faType="number"
                onChange={handleChange("taskBeforeDay")}
                value={editValue.taskBeforeDay}
              />
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Sonra")}
                faType="number"
                onChange={handleChange("taskAfterDay")}
                value={editValue.taskAfterDay}
              />
            </Grid>

            <Grid item xs={4}>
              Haftalık
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Önce")}
                faType="number"
                onChange={handleChange("taskBeforeWeek")}
                value={editValue.taskBeforeWeek}
              />
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Sonra")}
                faType="number"
                onChange={handleChange("taskAfterWeek")}
                value={editValue.taskAfterWeek}
              />
            </Grid>

            <Grid item xs={4}>
              Aylık
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Önce")}
                faType="number"
                onChange={handleChange("taskBeforeMonth")}
                value={editValue.taskBeforeMonth}
              />
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Sonra")}
                faType="number"
                onChange={handleChange("taskAfterMonth")}
                value={editValue.taskAfterMonth}
              />
            </Grid>

            <Grid item xs={4}>
              Yıllık
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Önce")}
                faType="number"
                onChange={handleChange("taskBeforeYear")}
                value={editValue.taskBeforeYear}
              />
            </Grid>
            <Grid item xs={4}>
              <FaInput
                label={t("Sonra")}
                faType="number"
                onChange={handleChange("taskAfterYear")}
                value={editValue.taskAfterYear}
              />
            </Grid>
          </Grid>
        </FaDialog>
      )
    );
  };

  return <>
    {renderDialog()}
    <Card style={{ marginTop: "16px" }}>
      <CardHeader
        action={
          <>
            <AccountPeriodicTaskHelp value="accountDetail.periodicTaskPreferences" />
            <IconButton
              aria-label="settings"
              onClick={editButtonClicked}
              data-fa-button="PERIODIC_TASK_PREFERENCES"
              size="large">
              <EditIcon />
            </IconButton>
          </>
        }
        title={t("PERIODIC_TASK_PREFERENCES")}
        data-fa-section="PERIODIC_TASK_PREFERENCES"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid
              item
              container
              spacing={1}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Görev Kapatma Limitleri")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Önce")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Sonra (Dakika)")}
                </Typography>
              </Grid>

              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Saatlik")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskBeforeHour}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskAfterHour}
                </Typography>
              </Grid>

              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Günlük")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskBeforeDay}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskAfterDay}
                </Typography>
              </Grid>

              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Haftalık")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskBeforeWeek}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskAfterWeek}
                </Typography>
              </Grid>

              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Aylık")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskBeforeMonth}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskAfterMonth}
                </Typography>
              </Grid>

              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Yıllık")}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskBeforeYear}
                </Typography>
              </Grid>
              <Grid item xs={4} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.taskAfterYear}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
