export const ROLES = {
  ACCOUNTOWNER: "ACCOUNTOWNER",
  BENEFICIARY: "BENEFICIARY",
  ACCOUNTUSER: "ACCOUNTUSER",
  THINGOWNER: "THINGOWNER",
  OPERATOR: "OPERATOR",
  FACEBOOK_USER: "FACEBOOK_USER",
  GOOGLE_USER: "GOOGLE_USER",
  ADMIN: "ADMIN",
  THINGMANAGER: "THINGMANAGER",
  POWER_USER: "POWER_USER",
  SLA: "SLA",
  PATROL: "PATROL",
  QUEUE: "QUEUE",
  POLICY: "POLICY",
  CUSTOMER: "CUSTOMER",
  SURVEY: "SURVEY",
  CAMPAIGN: "CAMPAIGN",
  PUBLIC_BENEFICIARY: "PUBLIC_BENEFICIARY",
  THINGS_2_CARE: "THINGS_2_CARE",
  FREE: "FREE",
  TASK_MANAGEMENT: "TASK_MANAGEMENT",
  SERVICE_OPERATION: "SERVICE_OPERATION",
  STOCK_TAKING: "STOCK_TAKING",
  RESPONSIBILITY: "RESPONSIBILITY",
  CUSTOM_LEGAL: "CUSTOM_LEGAL",
  JOURNAL: "JOURNAL",
  RESERVATION: "RESERVATION",
  MY_THING: "MY_THING",
  MY_THINGS: "MY_THINGS",
  USER_CONTENT: "USER_CONTENT",
  COUPON: "COUPON",
};
