import { Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ActivePassiveDecorator(props) {
  const { value } = props;

  const { t } = useTranslation("components", { i18n });

  const render = () => {
    if (value === "ACTIVE")
      return (
        <Chip
          color="primary"
          size="small"
          label={t("ACTIVE_PASSIVE_" + value)}
        />
      );
    else if (value === "PASSIVE") {
      return <Chip size="small" label={t("ACTIVE_PASSIVE_" + value)} />;
    } else {
      return <div>{value}</div>;
    }
  };

  return render();
}
