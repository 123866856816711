import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import { useStyles } from "features/accountDetails/AccountDetailsCardStyle";
import ThingsPublicReportPageHelp from "features/help/ThingsPublicReportPageHelp";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import { default as React, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import FaButton from "react-base-fa/dist/fa/faButton";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { CustomInteractionPageService } from "services/CustomInteractionPageService";
import { ThingService } from "services/thing/thingService";
import { ThingClassService } from "services/thingClass/thingClassService";
import { PUBLIC_REPORT_PAGE_TEMPLATES } from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";

export default function PublicReportTemplate(props) {
  const { thing, thingClass, account, onSave, onUpdate } = props;
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const classes = useStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editValue, setEditValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [customPage, setCustomPage] = useState({});
  const [mainThing, setMainThing] = useState();
  const [isHtmlEditOpen, setIsHtmlEditOpen] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);
  const role = useSelector(getRole);

  useEffect(() => {
    if (thing) setMainThing(thing);
    if (thingClass) setMainThing(thingClass);
    if (account) setMainThing(account);
  }, [thing, thingClass, account]);

  const editButtonClicked = () => {
    setIsEditOpen(true);
  };
  const handleChange = (prop, data) => {
    setEditValue({ ...editValue, [prop]: data });
  };

  const handleCustomChange = (prop, data) => {
    setCustomPage({ ...customPage, [prop]: data });
  };
  const newCustomInteractionPage = () => {
    return {
      account: null,
      thing: null,
      thingClass: null,
      customInteractionPageHtml: null,
    };
  };
  const saveForm = (data) => {
    if (thing)
      ThingService.updatePublicReportPageTemplate(
        thing.id,
        data.publicReportPageTemplate,
        saveFormSuccess,
        saveFormError
      );
    if (thingClass)
      ThingClassService.updatePublicReportPageTemplate(
        thingClass.id,
        data.publicReportPageTemplate,
        saveFormSuccess,
        saveFormError
      );
    if (account)
      AccPreferencesService.updatePublicReportPageTemplate(
        account.id,
        data.publicReportPageTemplate,
        saveFormSuccess,
        saveFormError
      );
  };

  const saveCustomInteractionPage = (data) => {
    let tmp = newCustomInteractionPage();
    tmp.customInteractionPageHtml = data.customInteractionPageHtml;
    if (thing) tmp.thing = { id: thing.id };
    if (thingClass) tmp.thingClass = { id: thingClass.id };
    if (account) tmp.account = { id: account.account.id };
    CustomInteractionPageService.createCustomInteractionPage(
      tmp,
      saveFormSuccess,
      saveFormError
    );
  };

  const getCustomInteractionPage = () => {
    if (thing)
      CustomInteractionPageService.getCustomInteractionPageByThingId(
        thing.id,
        getPageSuccess,
        getPageError
      );
    if (thingClass)
      CustomInteractionPageService.getCustomInteractionPageByThingClassId(
        thingClass.id,
        getPageSuccess,
        getPageError
      );
    if (account)
      CustomInteractionPageService.getCustomInteractionPageByAccountId(
        account.account.id,
        getPageSuccess,
        getPageError
      );
  };

  const getPageSuccess = (data) => {
    //showAlert("success");
    setCustomPage(data);
    setLoading(false);
  };
  const getPageError = (data) => {
    //showAlert(data.error, "error");
    setLoading(false);
  };
  const saveFormSuccess = (data) => {
    //showAlert("success");
    onUpdate();
    setLoading(false);
  };
  const saveFormError = (data) => {
    //showAlert(data.error, "error");
    setLoading(false);
  };

  const isFreeRole = checkRole([ROLES.FREE], role);

  const renderTemplate = (template) => {
    return (
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CardActionArea
          style={{
            display: "flex",
          }}
          onClick={() => {
            handleChange("publicReportPageTemplate", template);
          }}
        >
          <img
            alt="Şablon"
            src={
              process.env.PUBLIC_URL +
              "/assets/pageTemplate/" +
              template +
              ".jpg"
            }
            style={{
              width: 200,
              border:
                template === editValue.publicReportPageTemplate
                  ? "3px solid grey"
                  : "",
            }}
          ></img>
        </CardActionArea>
      </Card>
    );
  };

  const renderDialog = () => {
    return (
      isEditOpen && (
        <FaDialog
          title={t("THING_INTERACTION_PAGE")}
          faOpen={isEditOpen}
          faOnSubmit={() => {
            if (isFreeRole) {
              setSubscriptionError(true);
            } else {
              onSave(editValue);
              setIsEditOpen(false);
            }
          }}
          faHandleClose={(data) => {
            setIsEditOpen(false);
          }}
          faMaxWidth="md"
        >
          <Grid container spacing={1} data-fa-section="INTERACTION_PAGE_DIALOG">
            <Grid item lg={12} xs={12}>
              <Grid container spacing={5}>
                {PUBLIC_REPORT_PAGE_TEMPLATES.map((template) => (
                  <Grid item md={3} key={template}>
                    {renderTemplate(template)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} xs={12}>
            <FaButton
              style={{
                width: "49%",
                marginTop: 5,
                marginRight: 5,
                marginBottom: 5,
              }}
              size="small"
              color="secondary"
              onClick={() => {
                if (isFreeRole) {
                  setSubscriptionError(true);
                } else {
                  const data = { ...editValue, publicReportPageTemplate: "" };
                  setEditValue(data);
                  saveForm(data);
                  setIsEditOpen(false);
                }
              }}
            >
              Temizle
            </FaButton>
            <FaButton
              style={{
                width: "49%",
                marginTop: 5,

                marginLeft: 5,
                marginBottom: 5,
              }}
              size="small"
              color="primary"
              onClick={() => {
                if (isFreeRole) {
                  setSubscriptionError(true);
                } else {
                  saveForm(editValue);
                  setIsEditOpen(false);
                }
              }}
            >
              Kaydet
            </FaButton>
          </Grid>
        </FaDialog>
      )
    );
  };
  const renderHtmlDialog = () => {
    return (
      isHtmlEditOpen && (
        <FaDialog
          title={t("Özelleştirilmiş HTML")}
          faOpen={isHtmlEditOpen}
          faOnSubmit={() => {
            saveCustomInteractionPage(customPage);
            setIsHtmlEditOpen(false);
          }}
          showSaveButton
          faHandleClose={(data) => {
            setIsHtmlEditOpen(false);
          }}
          faMaxWidth="lg"
        >
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <Grid container spacing={5}>
                <FaInput
                  faType="description"
                  rows={20}
                  inputProps={{ maxLength: 100000 }}
                  label={t("Özelleştirilmiş HTML Kodu")}
                  value={customPage?.customInteractionPageHtml}
                  onChange={(event) => {
                    handleCustomChange(
                      "customInteractionPageHtml",
                      event.target.value
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </FaDialog>
      )
    );
  };
  const renderPublicReportPageTemplate = () => {
    return (
      <img
        className={classes.publicReportPageTemplateImage}
        alt="Sayfa şablonu"
        src={
          process.env.PUBLIC_URL +
          "/assets/pageTemplate/" +
          mainThing?.publicReportPageTemplate +
          ".jpg"
        }
      />
    );
  };

  return <>
    {renderDialog()}
    {renderHtmlDialog()}
    {subscriptionError && (
      <FeatureNotAllowed
        open={subscriptionError}
        onClose={() => {
          setSubscriptionError(false);
        }}
      />
    )}
    <Card style={{ overflowY: "auto" }}>
      <CardHeader
        action={
          <>
            <ThingsPublicReportPageHelp
              value="thingDetail.publicReportPage"
              thing={thing}
              thingClass={thingClass}
            />
            <IconButton
              aria-label="settings"
              onClick={editButtonClicked}
              data-fa-button="INTERACTION_PAGE_BUTTON"
              size="large">
              <ThingsEditIcon />
            </IconButton>
          </>
        }
        title={t("THING_INTERACTION_PAGE")}
        data-fa-section="THING_INTERACTION_PAGE"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <Typography>
                  {mainThing?.publicReportPageTemplate
                    ? renderPublicReportPageTemplate()
                    : t("NO_INTERACTION_PAGE", { ns: "components" })}
                </Typography>

                {mainThing?.publicReportPageTemplate ===
                  "TEMPLATE_CUSTOM" && (
                  <FaButton
                    style={{ width: "40%" }}
                    size="small"
                    color="primary"
                    faClick={() => {
                      getCustomInteractionPage();
                      setIsHtmlEditOpen(true);
                    }}
                  >
                    {t("Özelleştirilmiş HTML")}
                  </FaButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
