import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Adsense from "components/adsense/Adsense";
import LabelPaperTemplateAutoComplete from "components/autocomplete/LabelPaperTemplateAutoComplete";
import React, { Suspense, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";
import { QRTemplateService } from "../../services/qrTemplate/qrTemplateService";
import QrLabelTemplateItem from "./QrLabelTemplateItem";
import { useStyles } from "./style";

const DUMMY_TEMPLATES = [
  {
    id: 1,
    name: "QR_1",
    desc: "Standart şablon-1",
    src: process.env.PUBLIC_URL + "/assets/label/QR_1.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 2,
    name: "QR_2",
    desc: "Standart şablon-2",
    src: process.env.PUBLIC_URL + "/assets/label/QR_2.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 3,
    name: "QR_3",
    desc: "QR ve açıklama",
    src: process.env.PUBLIC_URL + "/assets/label/QR_3.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 4,
    name: "QR_4",
    desc: "QR görsel",
    src: process.env.PUBLIC_URL + "/assets/label/QR_4.png",
    width: 595,
    height: 842,
  },
  {
    id: 5,
    name: "QR_5",
    desc: "Açıklama, QR, Firma ve Logo",
    src: process.env.PUBLIC_URL + "/assets/label/QR_5.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 6,
    name: "QR_6",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_6.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 7,
    name: "QR_7",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_7.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 8,
    name: "QR_8",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_8.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 9,
    name: "QR_9",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_9.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 10,
    name: "QR_10",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_10.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 11,
    name: "QR_11",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_11.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 12,
    name: "QR_12",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_12.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 13,
    name: "QR_13",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_13.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 14,
    name: "QR_14",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_14.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 15,
    name: "QR_15",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_15.jpg",
    width: 595,
    height: 842,
  },
  {
    id: 16,
    name: "QR_16",
    desc: "",
    src: process.env.PUBLIC_URL + "/assets/label/QR_16.jpg",
    width: 595,
    height: 842,
  },
];

const DUMMY_BOYUT_LIST = [
  { id: 1, name: "A3" },
  { id: 2, name: "A4" },
  { id: 3, name: "A5" },
];

const DUMMY_KALITE_LIST = [
  { id: 1, name: "Kalite1" },
  { id: 2, name: "Kalite2" },
  { id: 3, name: "Kalite3" },
];

const DUMMY_RESOLUTION_LIST = [
  { value: "120" },
  { value: "300" },
  { value: "400" },
];

const DUMMY_LABEL_PER_PAGE_LIST = [
  { value: 1 },
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
  { value: 12 },
  { value: 14 },
];

export default function PrintQR() {
  const { t } = useTranslation("createQr", { i18n });
  const accountId = localStorage.accountId;
  const classes = useStyles();
  const location = useLocation();

  // varlık detayından qr oluşturulmak istendiğinde
  const [selectedQr, setSelectedQr] = useState(location?.state?.selectedQr);
  const [selectedThid, setSelectedThid] = useState(
    location?.state?.selectedThid
  );

  // liste sayfasından çoklu seçim yapıldığında
  const [selectedIds, setSelectedIds] = useState(location?.state?.selectedIds);

  const role = useSelector(getRole);
  const isFree = checkRole([ROLES.FREE], role);

  const [printValues, setPrintValues] = useState({
    amount: 1,
    pageNum: 1,
    orderNoStart: 1,
    extension: 1,
    size: 2,
    quality: 1,
    templateName: "",
    resolution: 120,
    width: 0,
    height: 0,
    labelPerPage: 1,
    templateId: null,
    satirSayi: 8,
    sutunSayi: 3,
    bottomMargin: 0,
    columnSpacing: 3,
    rowSpacing: 0,
    leftMargin: 6,
    rightMargin: 0,
    topMargin: 13,
    labelWidth: 64,
    labelHeight: 34,
    labelPaperTemplate: null,
  });

  useEffect(() => {
    const paging = { page: 0, size: 1000, isAsc: false, sort: "id" };

    QRTemplateService.findQRTemplate(
      accountId,
      paging,
      findQRTemplateOnSuccess,
      findQRTemplateOnError
    );
  }, []);

  useEffect(() => {
    if (printValues.extension == 2) {
      validatePrintLayout();
    }
  }, [printValues]);

  const findQRTemplateOnSuccess = (data) => {
    //setLabelList([...data, ...DUMMY_TEMPLATES]);
    setLabelList([...data]);
  };

  const findQRTemplateOnError = (data) => {};

  const [labelList, setLabelList] = useState([]);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [qrImages, setQrImages] = useState([]);

  const handleChangeV = (prop, val) => {
    setPrintValues({ ...printValues, [prop]: val });
  };

  const [isPrintLabelDialogOpen, setIsPrintLabelDialogOpen] = useState(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

  const templateSelected = (val) => {
    setPrintValues({
      ...printValues,
      templateName: val.name,
      width: val.width,
      height: val.height,
      templateId: val.src == null ? val.id : null,
    });
    setSelectedTemplate(val);
    setIsPrintLabelDialogOpen(true);
  };

  const autoSizeCalculate = (width, height) => {
    if (width != null) {
      const newHeight = Math.floor(
        (width * selectedTemplate.height) / selectedTemplate.width
      );
      setPrintValues({
        ...printValues,
        height: newHeight,
        width: width,
      });
    } else if (height != null) {
      const newWidth = Math.floor(
        (height * selectedTemplate.width) / selectedTemplate.height
      );
      setPrintValues({
        ...printValues,
        width: newWidth,
        height: height,
      });
    }
  };

  const refreshQrImages = () => {
    setLoading(true);
    let req = requests.getSimpleGetRequest(THING.printQRThumbnail, {
      accountId,
      amount: printValues.amount,
      pageNum: printValues.pageNum,
      orderNoStart: printValues.orderNoStart,
      templateName: printValues.templateName,
      extension: DUMMY_CIKTI_ORTAM_LIST[printValues.extension - 1].extension,
      size: DUMMY_BOYUT_LIST[printValues.size - 1].name,
      templateId: printValues.templateId,
      selectedQr: selectedQr,
      selectedIds:
        selectedIds && selectedIds.length > 0 ? selectedIds.toString() : null,
    });
    FaAxios(req)
      .then((response) => {
        setQrImages(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const DUMMY_CIKTI_ORTAM_LIST = [
    { id: 1, name: t("CIKTI_ORTAM_Resim"), extension: "jpg" },
    { id: 2, name: t("CIKTI_ORTAM_PDF"), extension: "pdf" },
    { id: 3, name: t("CIKTI_ORTAM_Liste"), extension: "xlsx" },
  ];

  const ciktiOrtamList = DUMMY_CIKTI_ORTAM_LIST.map((item) => (
    <MenuItem value={item.id}>{item.name}</MenuItem>
  ));

  const boyutList = DUMMY_BOYUT_LIST.map((item) => (
    <MenuItem value={item.id}>{item.name}</MenuItem>
  ));

  const kaliteList = DUMMY_KALITE_LIST.map((item) => (
    <MenuItem value={item.id}>{item.name}</MenuItem>
  ));

  const resolutionList = DUMMY_RESOLUTION_LIST.map((item) => (
    <MenuItem value={item.value}>{item.value + " DPI"}</MenuItem>
  ));

  const labelPerPageList = DUMMY_LABEL_PER_PAGE_LIST.map((item) => (
    <MenuItem value={item.value}>{item.value}</MenuItem>
  ));

  //const classes = useStyles();

  const printLabel = () => {
    setLoading(true);
    if (printValues.extension === 1) {
      setIsPrintLabelDialogOpen(false);
      refreshQrImages();
      setIsPreviewDialogOpen(true);
    } else {
      const req = requests.getSimpleGetRequest(THING.printQR, {
        accountId,
        amount: printValues.amount,
        pageNum: printValues.pageNum,
        orderNoStart: printValues.orderNoStart,
        templateName: printValues.templateName,
        extension: DUMMY_CIKTI_ORTAM_LIST[printValues.extension - 1].extension,
        size: DUMMY_BOYUT_LIST[printValues.size - 1].name,
        templateId: printValues.templateId,
        satirSayi: printValues.satirSayi,
        sutunSayi: printValues.sutunSayi,
        bottomMargin: printValues.bottomMargin,
        columnSpacing: printValues.columnSpacing,
        rowSpacing: printValues.rowSpacing,
        leftMargin: printValues.leftMargin,
        rightMargin: printValues.rightMargin,
        topMargin: printValues.topMargin,
        labelWidth: printValues.labelWidth,
        labelHeight: printValues.labelHeight,
        selectedQr: selectedQr, // TODO:
        selectedIds:
          selectedIds && selectedIds.length > 0 ? selectedIds.toString() : null,
      });
      req.responseType = "blob";
      FaAxios(req)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          if (printValues.extension == 2)
            link.setAttribute("download", "download.pdf");
          else if (printValues.extension == 3)
            link.setAttribute("download", "download.xlsx");
          //or any other extension
          else link.setAttribute("download", "download.zip"); //or any other extension
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const downloadPrintedLabel = () => {
    const req = requests.getSimpleGetRequest(THING.printQR, {
      accountId,
      amount: printValues.amount,
      pageNum: printValues.pageNum,
      orderNoStart: printValues.orderNoStart,
      templateName: printValues.templateName,
      extension: DUMMY_CIKTI_ORTAM_LIST[printValues.extension - 1].extension,
      size: DUMMY_BOYUT_LIST[printValues.size - 1].name,
      templateId: printValues.templateId,
      selectedQr: selectedQr,
      selectedIds:
        selectedIds && selectedIds.length > 0 ? selectedIds.toString() : null,
    });
    req.responseType = "blob";
    FaAxios(req)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
      });
    setIsPreviewDialogOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);

  const parseN = (val) => {
    return parseFloat(val ?? 0);
  };

  const validatePrintLayout = () => {
    let pageHeight = 297;
    let pageWidth = 210;
    if (printValues.size == 1) {
      pageHeight = 420;
      pageWidth = 297;
    } else if (printValues.size == 2) {
      pageHeight = 297;
      pageWidth = 210;
    } else if (printValues.size == 3) {
      pageHeight = 210;
      pageWidth = 148;
    }

    const calculatedWidth =
      parseN(printValues.leftMargin) +
      parseN(printValues.sutunSayi) * parseN(printValues.labelWidth) +
      (parseN(printValues.sutunSayi) - 1) * parseN(printValues.columnSpacing);
    const calculatedHeight =
      parseN(printValues.topMargin) +
      parseN(printValues.satirSayi) * parseN(printValues.labelHeight) +
      (parseN(printValues.satirSayi) - 1) * parseN(printValues.rowSpacing);

    setWidthError(calculatedWidth > pageWidth);
    setHeightError(calculatedHeight > pageHeight);
  };

  const labelPaperTemplateSelected = (data) => {
    if (data != null) {
      const size_ = DUMMY_BOYUT_LIST.find((item) => {
        if (item.name === data.paperSize) return item;
      });
      setPrintValues({
        ...printValues,
        satirSayi: data.rows ?? 0,
        sutunSayi: data.cols ?? 0,
        labelWidth: data.labelWidth ?? 0,
        labelHeight: data.labelHeight ?? 0,
        leftMargin: data.leftMargin ?? 0,
        rightMargin: data.rightMargin ?? 0,
        topMargin: data.topMargin ?? 0,
        bottomMargin: data.bottomMargin ?? 0,
        columnSpacing: data.columnSpacing ?? 0,
        rowSpacing: data.rowSpacing ?? 0,
        labelPaperTemplate: data,
        size: size_.id,
      });
    } else {
      setPrintValues({ ...printValues, labelPaperTemplate: data });
    }
  };

  const printDialogRender = () => {
    return (
      <>
        <FaDialog
          //title={"Etiket Bas - " + selectedTemplate?.desc}
          title={t("ETIKET_BAS")}
          faOpen={isPrintLabelDialogOpen}
          faOnSubmit={printLabel}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsPrintLabelDialogOpen(false);
          }}
          saveButtonTitle={t("YAZDIR")}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid container xs={12} alignItems="center" justifyContent="center">
              {selectedTemplate && selectedTemplate.src && (
                <img src={selectedTemplate.src} style={{ height: 200 }} />
              )}
              {selectedTemplate && selectedTemplate.thumbnail && (
                <img
                  src={`data:image/png;base64, ${selectedTemplate.thumbnail}`}
                  style={{ height: 200 }}
                />
              )}
            </Grid>
            {(printValues.extension == 1 || printValues.extension == 3) && (
              <Grid item xs={12}>
                <FaInput
                  label={t("ETIKET_SAYISI")}
                  faType="number"
                  required
                  onChange={(event) =>
                    handleChangeV("amount", event.target.value)
                  }
                  value={printValues.amount}
                />
              </Grid>
            )}
            {printValues.extension == 2 && (
              <Grid item xs={12}>
                <FaInput
                  label={t("SAYFA_SAYISI")}
                  faType="number"
                  required
                  onChange={(event) =>
                    handleChangeV("pageNum", event.target.value)
                  }
                  value={printValues.pageNum}
                />
              </Grid>
            )}
            {selectedTemplate &&
              selectedTemplate.items &&
              selectedTemplate.items.find((x) => x.type === "ORDER_NO") && (
                <Grid item xs={12}>
                  <FaInput
                    label={t("ETIKET_NO_BASLANGIC")}
                    faType="number"
                    required
                    onChange={(event) =>
                      handleChangeV("orderNoStart", event.target.value)
                    }
                    value={printValues.orderNoStart}
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <FormControl variant="standard" component="fieldset">
                <FormLabel component="legend">{t("CIKTI_ORTAMI")}</FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  value={"" + printValues.extension}
                  onChange={(event) => {
                    handleChangeV("extension", event.target.value);
                  }}
                >
                  {DUMMY_CIKTI_ORTAM_LIST.map((item) => {
                    return (
                      <FormControlLabel
                        value={"" + item.id}
                        control={<Radio color="primary" />}
                        label={item.name}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
            {printValues.extension == 1 ? (
              <Grid item xs={12}>
                <FormControl className={classes.w100}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("COZUNURLUK")}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    classes={{
                      outlined: classes.padding10,
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={printValues.resolution}
                    onChange={(event) =>
                      handleChangeV("resolution", event.target.value)
                    }
                    label={t("COZUNURLUK")}
                  >
                    {resolutionList}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {printValues.extension == 1 ? (
              <>
                <Grid item xs={6}>
                  <FaInput
                    label={t("EN")}
                    faType="number"
                    required
                    // onChange={(event) =>
                    //   autoSizeCalculate(event.target.value, null)
                    // }
                    onKeyUp={(event) => {
                      autoSizeCalculate(event.target.value, null);
                    }}
                    value={printValues.width}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FaInput
                    label={t("BOY")}
                    faType="number"
                    required
                    // onChange={(event) =>
                    //   autoSizeCalculate(null, event.target.value)
                    // }
                    onKeyUp={(event) => {
                      autoSizeCalculate(null, event.target.value);
                    }}
                    value={printValues.height}
                  />
                </Grid>
              </>
            ) : null}
            {printValues.extension == 2 ? (
              <Grid item xs={12}>
                <FormControl className={classes.w100}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("KAGIT_BOYUTU")}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    classes={{
                      outlined: classes.padding10,
                    }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={printValues.size}
                    onChange={(event) =>
                      handleChangeV("size", event.target.value)
                    }
                    label={t("BOYUT")}
                  >
                    {boyutList}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {printValues.extension == 2 ? (
              <>
                <Grid item xs={12}>
                  <LabelPaperTemplateAutoComplete
                    value={printValues.labelPaperTemplate}
                    accountId={accountId}
                    onChange={labelPaperTemplateSelected}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FaInput
                    label={t("SATIR_SAYISI")}
                    faType="number"
                    required
                    onChange={(event) =>
                      handleChangeV("satirSayi", event.target.value)
                    }
                    value={printValues.satirSayi}
                    faError={heightError ? t("SAYFA_ASIMI") : ""}
                    helperText={heightError ? t("SAYFA_ASIMI") : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FaInput
                    label={t("SUTUN_SAYISI")}
                    faType="number"
                    required
                    onChange={(event) =>
                      handleChangeV("sutunSayi", event.target.value)
                    }
                    value={printValues.sutunSayi}
                    faError={widthError ? t("SAYFA_ASIMI") : ""}
                    helperText={widthError ? t("SAYFA_ASIMI") : ""}
                  />
                </Grid>
              </>
            ) : null}

            {printValues.extension == 2 && renderMargins()}
          </Grid>
        </FaDialog>
      </>
    );
  };

  const renderMargins = () => {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            {t("PAGE_SETTINGS")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={6}>
              <FaInput
                label={t("ETIKET_GENISLIGI")}
                faType="number"
                awnum="para"
                required
                onChange={(event) =>
                  handleChangeV("labelWidth", event.target.value)
                }
                value={printValues.labelWidth}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("ETIKET_YUKSEKLIGI")}
                faType="number"
                awnum="para"
                required
                onChange={(event) =>
                  handleChangeV("labelHeight", event.target.value)
                }
                value={printValues.labelHeight}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("UST_KENAR_BOSLUGU")}
                faType="number"
                awnum="para"
                required
                onChange={(event) =>
                  handleChangeV("topMargin", event.target.value)
                }
                value={printValues.topMargin}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("SOL_KENAR_BOSLUGU")}
                faType="number"
                awnum="para"
                required
                onChange={(event) =>
                  handleChangeV("leftMargin", event.target.value)
                }
                value={printValues.leftMargin}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("ETIKETLER_ARASI_BOSLUK_YATAY")}
                faType="number"
                awnum="para"
                required
                onChange={(event) =>
                  handleChangeV("columnSpacing", event.target.value)
                }
                value={printValues.columnSpacing}
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                label={t("ETIKETLER_ARASI_BOSLUK_DIKEY")}
                faType="number"
                awnum="para"
                required
                onChange={(event) =>
                  handleChangeV("rowSpacing", event.target.value)
                }
                value={printValues.rowSpacing}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const createCarouselItemImage = (item, index) => (
    <div key={index} style={{ width: 400 }}>
      <img src={`data:image/png;base64, ${item.content}`} />
    </div>
  );

  const baseChildren = (
    <div>{qrImages == null ? null : qrImages.map(createCarouselItemImage)}</div>
  );

  const previewDialogRender = () => {
    return (
      <>
        <FaDialog
          title={t("ETIKET_ONIZLEME")}
          faOpen={isPreviewDialogOpen}
          faOnSubmit={downloadPrintedLabel}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsPreviewDialogOpen(false);
          }}
          saveButtonTitle={t("INDIR")}
        >
          {qrImages && qrImages.length > 0 && (
            <Carousel showArrows={true}>{baseChildren.props.children}</Carousel>
          )}
        </FaDialog>
      </>
    );
  };

  const renderSelectedQr = () => (
    <div>
      {selectedQr && (
        <Chip
          icon={<FontAwesomeIcon icon={faQrcode} />}
          label={t("BASILACAK_QR") + ":" + selectedThid}
          onClick={() => {
            setSelectedQr(null);
            setSelectedThid(null);
          }}
          onDelete={() => {
            setSelectedQr(null);
            setSelectedThid(null);
          }}
          variant="outlined"
        />
      )}
      {selectedIds && (
        <Chip
          icon={<FontAwesomeIcon icon={faQrcode} />}
          label={selectedIds.length + " varlık seçildi."}
          variant="outlined"
        />
      )}
    </div>
  );

  const renderElements = () => {
    return (
      <Grid container spacing={5}>
        {labelList.map((template) => (
          <Grid item md={3}>
            <QrLabelTemplateItem
              key={template.id}
              value={template}
              clickAction={templateSelected}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {printDialogRender()}
        {previewDialogRender()}
        {renderSelectedQr()}

        {isFree && (
          <Grid container spacing={1}>
            <Grid item md={10}>
              {renderElements()}
            </Grid>
            <Grid item md={2}>
              <Adsense value="RklmSag" />
            </Grid>
          </Grid>
        )}

        {!isFree && renderElements()}
      </Suspense>
    </>
  );
}
