import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Button, Typography } from "@mui/material";
import React, { Suspense, useEffect } from "react";
import { getUserInfo, setRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccUserService } from "services/AccUserService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function IyzicoCallback() {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const accountId = localStorage.accountId;
  const { t } = useTranslation("subscriptions", { i18n });

  useEffect(() => {
    AccUserService.findAccUserRole(
      accountId,
      userInfo.id,
      findAccUserRoleOnSuccess,
      () => {}
    );
  }, []);

  const findAccUserRoleOnSuccess = (data) => {
    dispatch(setRole(data));
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100vh - 80px)",
            backgroundColor: "#f1f8e9",
            padding: "32px",
            textAlign: "center",
            color: "#4caf50",
          }}
        >
          <CheckCircleOutlineIcon
            style={{ fontSize: "80px", marginBottom: "16px" }}
          />
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            {t("paymentSuccess")}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: "8px", color: "#666" }}
          >
            {t("subscriptionActivated")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "24px", padding: "10px 20px" }}
            onClick={() => (window.location.href = "/")}
          >
            {t("backToHome")}
          </Button>
        </div>
      </Suspense>
    </>
  );
}
