import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material";
import BaseMessageTemplateAutoComplete from "components/autocomplete/BaseMessageTemplateAutoComplete";
import ActionAllTypeSelect from "components/select/ActionAllTypeSelect";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MessageTemplateService } from "services/MessageTemplateService";
import i18n from "../../i18n";

const MessageTemplateEdit = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, value, open } = props;
  const tableRef = useRef();
  const { t } = useTranslation("messageTemplate", { i18n });

  const accountId = localStorage.accountId;

  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setMessageTemplate(value == null ? newMessageTemplate() : { ...value });
    }
  }, [open]);

  const newMessageTemplate = () => {
    return {
      id: "",
      actionType: null,
      description: "",
      emailTitle: "",
      emailTemplate: "",
      smsTemplate: "",
      pushTitle: "",
      pushTemplate: "",
      template: false,
      baseTemplate: null,
    };
  };
  const [messageTemplate, setMessageTemplate] = useState(newMessageTemplate());

  const handleChange = (prop) => (event) => {
    setMessageTemplate({ ...messageTemplate, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setMessageTemplate({ ...messageTemplate, [prop]: value });
  };

  const saveSuccess = (data) => {
    showAlert(!messageTemplate.id ? t("SAVED") : t("EDITED"), "success");
    setMessageTemplate(newMessageTemplate());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveMessageTemplate = () => {
    setLoading(true);
    MessageTemplateService.save(
      { ...messageTemplate, account: { id: accountId } },
      saveSuccess,
      saveError
    );
  };

  const messageTemplateDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        MessageTemplateService.delete(
          messageTemplate,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            onDelete();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Mesaj Şablonu Tanımlama"}
          faOpen={open}
          faOnSubmit={saveMessageTemplate}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setMessageTemplate(newMessageTemplate());
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={messageTemplate.template}
                      onChange={(event) => {
                        handleChangeV("template", event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("Ana Şablon")}
                />
              </FormControl>
            </Grid>
            {!!!messageTemplate.template && (
              <Grid item xs={12}>
                <ActionAllTypeSelect
                  required
                  readOnly={messageTemplate?.id}
                  label="İşlem Tipi"
                  value={messageTemplate?.actionType}
                  onChange={(data) => handleChangeV("actionType", data)}
                />
              </Grid>
            )}
            {!!!messageTemplate.template && (
              <Grid item xs={12}>
                <BaseMessageTemplateAutoComplete
                  value={messageTemplate.baseTemplate}
                  accountId={accountId}
                  onChange={(data) => handleChangeV("baseTemplate", data)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FaInput
                faType="description"
                label={t("Açıklama")}
                value={messageTemplate.description}
                inputProps={{ maxLength: 100 }}
                onChange={handleChange("description")}
              />
            </Grid>
            {!!!messageTemplate.template && (
              <Grid item xs={12}>
                <FaInput
                  label={t("Mesaj Konusu")}
                  value={messageTemplate.title}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange("title")}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FaInput
                faType="description"
                label={t("Mesaj İçeriği")}
                inputProps={{ maxLength: 4000 }}
                value={messageTemplate.content}
                onChange={handleChange("content")}
              />
            </Grid>
            {!!!messageTemplate.template && (
              <Grid item xs={4}>
                <FormControl variant="outlined" size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={messageTemplate.email}
                        onChange={(event) => {
                          handleChangeV("email", event.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label={t("E-Posta")}
                  />
                </FormControl>
              </Grid>
            )}
            {!!!messageTemplate.template && (
              <Grid item xs={4}>
                <FormControl variant="outlined" size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={messageTemplate.push}
                        onChange={(event) => {
                          handleChangeV("push", event.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label={t("Mobil Push")}
                  />
                </FormControl>
              </Grid>
            )}
            {!!!messageTemplate.template && (
              <Grid item xs={4}>
                <FormControl variant="outlined" size="small">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={messageTemplate.sms}
                        onChange={(event) => {
                          handleChangeV("sms", event.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label={t("SMS")}
                  />
                </FormControl>
              </Grid>
            )}

            {messageTemplate.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  disabled={!!!messageTemplate.account}
                  onClick={messageTemplateDelete}
                  color="secondary"
                >
                  Sil
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default MessageTemplateEdit;
