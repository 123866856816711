import {
  Button,
  Card,
  CardHeader,
  Chip,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import InvitationHelp from "features/help/InvitationHelp";
import { default as React, Suspense, useEffect, useRef } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { InvitationService } from "services/InvitationService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { INVITATION } from "../../services/faApi";
import { useStyles } from "./style";

export default function Invitations(props) {
  const { onChange } = props;
  const tableRef = useRef();
  const { t } = useTranslation(["accountUserInvitation", "errorMessages"], {
    i18n,
  });
  const classes = useStyles();
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const acceptInvitation = (row) => {
    faDialog({
      description: t("Davetiyeyi onaylamak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        InvitationService.acception(row, acceptOnSuccess, acceptOnError);
      })
      .catch((error) => {
        showAlert(
          t("errorMessages:" + error?.message ?? "UNEXPECTED_ERROR"),
          "error"
        );
      });
  };

  const acceptOnSuccess = () => {
    showAlert(t("INVITATION_ACCEPT_SUCCESS"), "success");
    tableRef.current.refreshTable();
    if (onChange) onChange();
  };

  const acceptOnError = (error) => {
    showAlert(
      t("errorMessages:" + error?.message ?? "UNEXPECTED_ERROR"),
      "error"
    );
  };

  const rejectInvitation = (row) => {
    faDialog({
      description: t("Davetiyeyi reddetmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        InvitationService.rejection(row, acceptOnSuccess, acceptOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const columns = [
    {
      field: "invitationDate",
      label: t("INVITATION_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value ? getDateTimeWFormat(value) : null;
      },
    },
    {
      field: "account",
      label: t("ACCOUNT"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value.fullname;
      },
    },
    {
      field: "invitationMsg",
      label: t("INVITATION_MESSAGE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value;
      },
    },
    {
      field: "statusDate",
      label: t("ACCEPT_REJECT_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value ? getDateTimeWFormat(value) : null;
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        //console.log("my value", value);
        let text = row.status
          ? t("STATUS_" + row.status)
          : t("STATUS_NOT_SENDED");
        let className = null;
        if (row.status === "WAITING") className = classes.waiting;
        else if (row.status === "ACCEPT") className = classes.accept;
        else if (row.status === "REJECT") className = classes.reject;
        return <Chip className={className} label={text} />;
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.status === "WAITING") {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => acceptInvitation(row)}
                style={{ marginRight: 5 }}
              >
                {t("ACCEPT")}
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => rejectInvitation(row)}
              >
                {t("REJECT")}
              </Button>
            </>
          );
        }
      },
    },
  ];

  const [filter, setFilter] = React.useState("WAITING");

  useEffect(() => {
    tableRef.current.refreshTable();
  }, [filter]);

  const handleToggleFilter = (event) => {
    setFilter(event.target.checked ? null : "WAITING");
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Card>
          <CardHeader
            title={t("INVITATIONS")}
            action={
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!filter}
                      onChange={handleToggleFilter}
                      color="primary"
                    />
                  }
                  label={
                    filter
                      ? t("SHOW_ALL_INVITATIONS") // Başlangıçta "WAITING" gösteriliyorsa, etiket "Tüm Davetleri Göster"
                      : t("SHOW_WAITING_INVITATIONS") // Filtre tüm davetlere geçtiğinde, etiket "Sadece Bekleyenleri Göster"
                  }
                />
                <InvitationHelp value="profile.invitations" />
              </>
            }
          />
          <Grid container data-fa-section="INVITATIONS">
            <ThingsDataTable
              dataUrl={INVITATION.findByUser}
              columns={columns}
              ref={tableRef}
              showDeleteButton={false}
              showHeaderText={false}
              queryParams={{
                page: 0,
                size: 50,
                sort: "invitationDate",
                isAsc: false,
                ...(filter ? { status: filter } : {}),
              }}
              noDataMessage={t("NO_INVITATIONS")}
            />
          </Grid>
        </Card>
      </Suspense>
    </>
  );
}
