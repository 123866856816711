import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";
import CountryAutoComplete from "components/autocomplete/CountryAutoComplete";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaButton, FaInput } from "react-base-fa/dist/fa";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AccountService } from "services/AccountService";
import { priceToString } from "services/utils";
import {
  GetNameFromFullName,
  GetSurnameFromFullName,
} from "services/utils/StringUtils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ChooseDeposite(props) {
  const { subscription, onSelect, open, onClose, loading } = props;
  const userInfo = useSelector(getUserInfo);
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("subscriptions", { i18n });
  const accountId = localStorage.accountId;
  const [accept, setAccept] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [subscribe, setSubscribe] = useState({
    name: GetNameFromFullName(userInfo?.fullname),
    surname: GetSurnameFromFullName(userInfo?.fullname),
    identityNumber: null,
    city: null,
    country: null,
    email: userInfo?.email,
    address: null,
    paymentMethod: "iyzico",
    subscription: subscription,
  });

  const iyzicoLogoSrc =
    i18n.language === "tr"
      ? "/assets/payment/iyzico_ile_ode_colored.png"
      : "/assets/payment/pay_with_iyzico_colored.png";

  useEffect(() => {
    setAccept(false);

    AccountService.findById(
      accountId,
      (data) => {
        setSubscribe((prev) => {
          return { ...prev, country: data.country, address: data.adress };
        });
      },
      (error) => {}
    );

    console.log({ subscription });
  }, [subscription]);

  useEffect(() => {
    let result = true;
    if (!subscribe.name || subscribe.name.length <= 0) result = false;
    if (!subscribe.surname || subscribe.surname.length <= 0) result = false;
    if (!subscribe.identityNumber || subscribe.identityNumber.length <= 0)
      result = false;
    if (!subscribe.city || subscribe.city.length <= 0) result = false;
    if (!subscribe.country || subscribe.country.length <= 0) result = false;
    if (!subscribe.email || subscribe.email.length <= 0) result = false;
    if (!subscribe.address || subscribe.address.length <= 0) result = false;

    setIsFormValid(result);
  }, [subscribe]);

  const submitForm = () => {
    onSelect(subscribe);
  };

  const handleChange = (prop) => (event) => {
    setSubscribe({ ...subscribe, [prop]: event.target.value });
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("paymentTitle", {
              price: priceToString(subscription.price, subscription.currency),
            })}
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                backgroundColor: "#f7f7f9",
                padding: "16px",
                border: "1px solid #cfdae0",
                borderRadius: "4px",
              }}
            >
              {t("monthlySubscription", {
                price: priceToString(subscription.price, subscription.currency),
                campaign: subscription.campaign.name,
              })}
            </div>
            <DialogContentText id="alert-dialog-description">
              {t("addressPrompt")}
            </DialogContentText>

            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("NAME")}
                  onChange={handleChange("name")}
                  value={subscribe.name}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("SURNAME")}
                  onChange={handleChange("surname")}
                  value={subscribe.surname}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FaInput
                  required
                  faType="email"
                  maxLength={100}
                  label={t("EMAIL")}
                  onChange={handleChange("email")}
                  value={subscribe.email}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("IDENTITY_NUMBER")}
                  onChange={handleChange("identityNumber")}
                  value={subscribe.identityNumber}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("CITY")}
                  onChange={handleChange("city")}
                  value={subscribe.city}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("ADDRESS")}
                  onChange={handleChange("address")}
                  value={subscribe.address}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <CountryAutoComplete
                  required
                  value={subscribe.country}
                  disabled={subscribe.country ? true : false}
                  onChange={handleChange("country")}
                />
              </Grid>
              <Grid item lg={12} xs={12}></Grid>
              <Grid item lg={1} xs={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={accept}
                      onChange={(event) => {
                        setAccept(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                />
              </Grid>
              <Grid item lg={11} xs={11}>
                <a href="#">{t("termsOfUse")}</a> {t("acceptanceText")}{" "}
                <a href="#">{t("recurrentPaymentPolicy")}</a> {t("and")}{" "}
                <a href="#">{t("refundPolicy")}</a>
              </Grid>
              <Grid item xs={12}>
                <FaButton
                  async
                  //type="submit"
                  disabled={!accept || !isFormValid || loading}
                  //formRef={formRef}
                  variant="contained"
                  startIcon={
                    <img
                      alt="Iyzico"
                      style={{ height: 40 }}
                      src={`${process.env.PUBLIC_URL}${iyzicoLogoSrc}`}
                    ></img>
                  }
                  className={classes.submit}
                  onClick={submitForm}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Suspense>
    </>
  );
}
