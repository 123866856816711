import { Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function CustomerMessageTemplateStatusDecorator(props) {
  const { value } = props;

  const { t } = useTranslation("components", { i18n });

  const render = () => {
    if (value === "ACTIVE")
      return (
        <Chip
          color="primary"
          size="small"
          label={t("CUSTOMER_MESSAGE_TEMPLATE_STATUS_" + value)}
        />
      );
    else if (value === "DRAFT")
      return (
        <Chip
          size="small"
          label={t("CUSTOMER_MESSAGE_TEMPLATE_STATUS_" + value)}
        />
      );
    else if (value === "SENT")
      return (
        <Chip
          style={{ backgroundColor: "green", color: "white" }}
          size="small"
          label={t("CUSTOMER_MESSAGE_TEMPLATE_STATUS_" + value)}
        />
      );
  };

  return render();
}
