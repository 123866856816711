import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, FormControlLabel, IconButton, Switch, Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { green, grey } from "@mui/material/colors";
import { Check, Edit, Replay } from "@mui/icons-material";
import SlaDetail from "components/detail/SlaDetail";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import MiniMapButton from "components/map/MiniMapButton";
import SlaContractLevelRadio from "components/radio/SlaContractLevelRadio";
import IssueCloseHelp from "features/help/IssueCloseHelp";
import IssueClose from "features/issues/IssueClose";
import React, { useEffect, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import {
  getGroups,
  getOrganizations,
  getRole,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SlaContractService } from "services/SlaContractService";
import { IssueService } from "services/issue/issueService";
import { isTNF } from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole, checkRoleByIssue } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import ChangeOwner from "./ChangeOwner";
import IssueConfirmReject from "./IssueConfirmReject";
import IssueReopen from "./IssueReopen";
import ThingAssign from "./ThingAssign";

export default function IssueDetailCard(props) {
  const { issue, onChange, accPreferences } = props;
  const { t } = useTranslation("issueDetail", { i18n });
  const labelClasses = labelStyles();
  const faDialog = useDialog();

  const [isSorunKapatDialog, setIsSorunKapatDialog] = useState(false);
  const [isSingleEditFormOpen, setIsSingleEditFormOpen] = useState(false);
  const [isThingAssignOpen, setIsThingAssignOpen] = useState(false);
  const [isConfirmRejectOpen, setIsConfirmRejectOpen] = useState(false);
  const [isIssueReopenOpen, setIsIssueReopenOpen] = useState(false);

  const [isOwnerChangeOpen, setIsOwnerChangeOpen] = useState(false);

  const role = useSelector(getRole);
  const isSLA = checkRole([ROLES.SLA], role);

  const userRole = useSelector(getRole);
  const userGroups = useSelector(getGroups);
  const userOrganizations = useSelector(getOrganizations);
  const userInfo = useSelector(getUserInfo);

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [slaContracts, setSlaContracts] = useState(null);
  const [newSlaContractLevel, setNewSlaContractLevel] = useState(
    issue?.slaContractLevel
  );

  let isIssueClosable = checkRoleByIssue(
    [ROLES.OPERATOR, ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    userRole,
    userInfo,
    userGroups,
    userOrganizations,
    issue
  );

  let issueReopenRole = checkRole(
    [ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    userRole
  );

  useEffect(() => {
    if (issue && issue.account && issue.thing) {
      SlaContractService.findContractsByThing(
        issue.account.id,
        issue.thing.id,
        (data) => {
          setSlaContracts(data);
        },
        (error) => {
          setSlaContracts(null);
        }
      );
    }
  }, [issue]);

  const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      "&$checked": {
        color: grey[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const saveSlaLevel = () => {
    IssueService.saveSlaLevel(
      issue.id,
      newSlaContractLevel?.id,
      (data) => {
        setIsSingleEditFormOpen(false);
        onChange();
      },
      (error) => {}
    );
  };

  const approveConfirmation = () => {
    faDialog({
      description: t(
        "Sorunum giderildi. Bildirimin kapatılmasını onaylıyor musunuz?"
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        IssueService.approveConfirmation(
          issue.id,
          () => {
            showAlert("Bildiriminiz kapatılmıştır.", "success");
            onChange();
          },
          () => {
            showAlert("Bir hata ile karşılaşıldı.", "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const singleSlaAssingRender = () => {
    return (
      <>
        <FaDialog
          title={t("SLA Seviye Belirle")}
          faOpen={isSingleEditFormOpen}
          faOnSubmit={saveSlaLevel}
          showSaveButton
          faHandleClose={(data) => {
            setIsSingleEditFormOpen(data);
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              {slaContracts && slaContracts.length === 1
                ? slaContracts[0].name
                : ""}
            </Grid>
            <Grid item xs={12}>
              <SlaContractLevelRadio
                value={newSlaContractLevel}
                thingId={issue?.thing?.id}
                onChange={(data) => {
                  setNewSlaContractLevel(data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader
        action={<MiniMapButton value={issue?.gpsLocation} />}
        title={t("ISSUE_INFO")}
        data-fa-section="ISSUE_INFO"
      />
      <CardContent>
        {isConfirmRejectOpen && (
          <IssueConfirmReject
            issueId={issue.id}
            onClose={() => {
              setIsConfirmRejectOpen(false);
            }}
            onChange={() => {
              onChange();
              setIsConfirmRejectOpen(false);
            }}
          />
        )}
        <IssueClose
          onChange={() => {
            setIsSorunKapatDialog(false);
            onChange();
          }}
          open={isSorunKapatDialog}
          onClose={() => {
            setIsSorunKapatDialog(false);
          }}
          accPreferences={accPreferences}
          issue={issue}
        />

        {singleSlaAssingRender()}
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {isTNF(t("THING"), t("THING_T2C"))}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <ThingDetail value={issue?.thing} />
                </Typography>
              </Grid>
              {issue &&
                issue.thing &&
                issue.thing.system === true &&
                isIssueClosable && (
                  <Grid item xs={12} className={labelClasses.LabelRow}>
                    <FaButton
                      style={{ width: "25%" }}
                      variant="contained"
                      size="small"
                      color="primary"
                      faClick={() => {
                        setIsThingAssignOpen(true);
                      }}
                      startIcon={<Edit />}
                      data-fa-button="ASSIGN_THING"
                    >
                      {t("ASSIGN_THING")}
                    </FaButton>
                  </Grid>
                )}
              {issue &&
                issue.user &&
                issue.status === "CONFIRMATION" &&
                issue.user.id === userInfo.id && (
                  <Grid item xs={12} className={labelClasses.LabelRow}>
                    <FaButton
                      style={{ width: "25%", marginRight: 10 }}
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => {
                        approveConfirmation();
                      }}
                      startIcon={<Check />}
                      data-fa-button="APPROVE_CONFIRMATION"
                    >
                      {t("APPROVE_CONFIRMATION")}
                    </FaButton>
                    <FaButton
                      style={{ width: "25%" }}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      faClick={() => {
                        setIsConfirmRejectOpen(true);
                      }}
                      data-fa-button="REJECT_CONFIRMATION"
                    >
                      {t("REJECT_CONFIRMATION")}
                    </FaButton>
                  </Grid>
                )}
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("BILDIRIM_TARIH")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {getDateTimeWFormat(issue.notificationDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NOTIFIER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {issue.notifier}
                  {issue?.user?.exPhone &&
                    " / Dahili Tel:" + issue?.user?.exPhone}
                  {issue?.user?.exFloor && " / Kat:" + issue?.user?.exFloor}
                  {issue?.user?.exRoom && " / Oda No:" + issue?.user?.exRoom}
                  {issue?.user?.email && " " + issue?.user?.email}
                </Typography>
              </Grid>

              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DESCRIPTION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {issue?.description}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("CLASS_NAME")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {issue?.issueClass?.className}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("HASHTAG")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {issue?.hashtag}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("IP_ADDRESS")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {issue?.clientInfo?.ipAddress}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("OWNER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <div style={{ display: "flex" }}>
                    <UserDetail
                      user={issue.ownerUser}
                      userGroup={issue.ownerUserGroup}
                      style={{ flex: 5 }}
                    />

                    {isIssueClosable && (
                      <div style={{ flex: 1, textAlign: "right" }}>
                        <Tooltip title={"Sahibi değiştir"}>
                          <IconButton
                            onClick={() => {
                              setIsOwnerChangeOpen(true);
                            }}
                            size="small"
                            color="primary"
                            aria-label={t("EDIT")}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className={labelClasses.LabelRow}
                style={{ overflowY: "auto" }}
              >
                <Grid container alignItems="center">
                  <Grid item xs={11}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("STATUS")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {isIssueClosable &&
                        (issue.status === "OPEN" ||
                          issue.status === "REJECT") && (
                          <FormControlLabel
                            id="statusSwitch"
                            control={
                              <GreenSwitch
                                onChange={() => {
                                  setIsSorunKapatDialog(true);
                                }}
                              />
                            }
                            label={t(issue.status)}
                          />
                        )}
                      {!isIssueClosable &&
                        (issue.status === "OPEN" ||
                          issue.status === "REJECT") && (
                          <FormControlLabel
                            id="statusSwitch"
                            control={<GreenSwitch disabled />}
                            label={t(issue.status)}
                          />
                        )}
                      {issue.status === "CONFIRMATION" && (
                        <FormControlLabel
                          id="statusSwitch"
                          disabled
                          control={<Switch checked />}
                          label={t(issue.status)}
                        />
                      )}
                      {issue.status === "CLOSE" && (
                        <>
                          <FormControlLabel
                            id="statusSwitch"
                            disabled
                            control={<Switch checked />}
                            label={t(issue.status)}
                          />
                          {issueReopenRole && (
                            <FaButton
                              style={{ width: "30%", float: "right" }}
                              variant="outlined"
                              color="primary"
                              size="small"
                              faClick={() => setIsIssueReopenOpen(true)}
                              startIcon={<Replay />}
                              data-fa-button="REOPEN"
                            >
                              {t("REOPEN")}
                            </FaButton>
                          )}
                        </>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "right" }}>
                    <IssueCloseHelp value="issueDetail.issueClose" />
                  </Grid>
                </Grid>
              </Grid>
              {issue.status !== "OPEN" && (
                <>
                  <Grid item xs={12} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("CLOSE_NOTE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {issue?.closeNote}
                    </Typography>
                  </Grid>
                </>
              )}
              {issue.confirmationNote && (
                <>
                  <Grid item xs={12} className={labelClasses.LabelLastRow}>
                    <Typography className={labelClasses.LabelHeader}>
                      {t("CONFIRMATION_NOTE")}
                    </Typography>
                    <Typography className={labelClasses.LabelValue}>
                      {issue?.confirmationNote}
                    </Typography>
                  </Grid>
                </>
              )}
              {isSLA && slaContracts && slaContracts.length > 0 && (
                <SlaDetail
                  issue={issue}
                  slaContracts={slaContracts}
                  onChange={onChange}
                  editable={isIssueClosable && issue.status !== "CLOSE"}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <ThingAssign
          onChange={onChange}
          onClose={() => {
            setIsThingAssignOpen(false);
          }}
          open={isThingAssignOpen}
          issue={issue}
        />

        {issue.status === "CLOSE" && (
          <IssueReopen
            onChange={onChange}
            onClose={() => {
              setIsIssueReopenOpen(false);
            }}
            open={isIssueReopenOpen}
            issue={issue}
          />
        )}

        {isOwnerChangeOpen && (
          <ChangeOwner
            issueId={issue?.id}
            onClose={() => {
              setIsOwnerChangeOpen(false);
            }}
            onChange={() => {
              setIsOwnerChangeOpen(false);
              onChange();
            }}
          />
        )}
      </CardContent>
    </Card>
  );
}
