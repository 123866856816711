import { Button, Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Archive, Delete, Unarchive } from "@mui/icons-material";
import Adsense from "components/adsense/Adsense";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingProductTypeSelect from "components/select/ThingProductTypeSelect";
import PublicReportTemplate from "features/publicReportTemplate/PublicReportTemplate";
import ThingScanCountStat from "features/statistic/thing/ThingScanCountStat";
import React, {
  Suspense,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  getGroups,
  getOrganizations,
  getRole,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ROLES } from "services/utils/Roles";
import { checkRole, checkRoleByThing } from "services/utils/SecurityUtils";
import LoadingBar from "../../components/loadingBar";
import Note from "../../components/noteCard";
import i18n from "../../i18n";
import { STATISTIC, THING } from "../../services/faApi";
import { ThingService } from "../../services/thing/thingService";
import ThingAdditionalFields from "./ThingAdditionalFields";
import ThingDetailCard from "./ThingDetailCard";
import ThingDocuments from "./ThingDocuments";
import ThingEvents from "./ThingEvents";
import ThingHistory from "./ThingHistory";
import ThingInteractions from "./ThingInteractions";
import ThingIssues from "./ThingIssues";
import ThingJournals from "./ThingJournals";
import ThingLinks from "./ThingLinks";
import ThingMap from "./ThingMap";
import ThingOwner from "./ThingOwner";
import ThingPhotos from "./ThingPhotos";
import ThingRecurrences from "./ThingRecurrences";
import ThingRelationsChart from "./ThingRelationsChart";
import ThingReservation from "./ThingReservation";
import { useStyles } from "./style";

const ThingDetail = forwardRef((props, ref) => {
  const { t } = useTranslation(["thingDetail", "errorMessages"], { i18n });
  const { id: thingParamId } = useParams();
  const id = props.thingId ? props.thingId : thingParamId;

  const thingPhotosRef = useRef();

  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const classes = useStyles();
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [thing, setThing] = useState({
    thingOrganization: { name: "" },
    hashtag: "",
    thingCode: "",
    thingClass: { className: "" },
    gpsLocation: "",
    id: 0,
  });
  const [images, setImages] = useState([]);
  const role = useSelector(getRole);
  const groups = useSelector(getGroups);
  const organizations = useSelector(getOrganizations);
  const userInfo = useSelector(getUserInfo);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [thingConvert, setThingConvert] = useState({});

  const isEditable = checkRoleByThing(
    [ROLES.OPERATOR, ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    role,
    userInfo,
    groups,
    organizations,
    thing
  );
  const isEditableHigher = checkRole(
    [ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
    role
  );

  const isJournal = checkRole([ROLES.JOURNAL], role);
  const isReservation = checkRole([ROLES.RESERVATION], role);

  useEffect(() => {
    refresh();
  }, [id]);

  useImperativeHandle(ref, () => ({
    openPhotoMenu() {
      thingPhotosRef.current.openPhotoMenu();
    },
  }));

  const refresh = () => {
    setLoading(true);
    let req = requests.getObjectByIdRequest(THING.findById, id);
    FaAxios(req)
      .then((response) => {
        setThing(response.data);
        loadChartData(response.data.id);
        refreshThingImages();
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const refreshThingImages = () => {
    ThingService.downloadThumbnails(
      id,
      (data) => {
        setImages(data);
        if (data?.length > 0) {
          setThumbnail("data:image/jpeg;base64," + data[0].content);
        }
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
      }
    );
  };

  const loadChartData = (thingId) => {
    let req = requests.getSimpleGetRequest(
      STATISTIC.chartThingIssueClassCount,
      { thingId }
    );
    FaAxios(req)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const gpsLocationChange = (data) => {
    ThingService.changeGpsLocation(
      thing.id,
      JSON.stringify({ lng: data.lng, lat: data.lat }),
      data.location,
      gpsLocationChanged,
      (error) => {
        console.log("error");
      }
    );
  };

  const indoorLocationChange = (data) => {
    ThingService.changeIndoorLocation(
      thing.id,
      JSON.stringify({ x: data.x, y: data.y }),
      data.locationDetailId,
      data.location,
      data.floor,
      data.roomNumber,
      indoorLocationChanged,
      (error) => {
        console.log("error");
      }
    );
  };

  const indoorLocationChanged = (data) => {
    setThing(data);
  };

  const gpsLocationChanged = (data) => {
    setThing(data);
  };

  const onThingInfoUpdate = (newData) => {
    setThing(newData);
    showAlert("Etkileşim noktası bilgileri güncellendi", "success");
  };

  const setDefaultImage = (objectId) => {
    ThingService.setDefaultImage(
      thing.id,
      objectId,
      setDefaultImageSuccess,
      setDefaultImageError
    );
  };

  const setDefaultImageSuccess = (data) => {
    refresh();
  };
  const setDefaultImageError = (data) => {
    showAlert("Varsayılan resim ayarlanamadı", "error");
  };

  const deleteThing = () => {
    faDialog({
      description: t(t("DELETE_CONFIRMATION")),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.delete(
          thing,
          (data) => {
            showAlert(t("DELETE_SUCCESS"), "success");
            const tmpTo = {
              pathname: `/things`,
              breadCrumbKey: `/things`,
              search: "?page=0&size=10&isAsc=false&sort=createDate",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const archiveThing = () => {
    faDialog({
      description: t("Arşivlemek istediğinize emin misiniz"),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.archive(
          thing.id,
          (data) => {
            showAlert(t("ARCHIVE_SUCCESS"), "success");
            const tmpTo = {
              pathname: `/things`,
              breadCrumbKey: `/things`,
              search: "?page=0&size=10&isAsc=false&sort=createDate",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const unarchiveThing = () => {
    faDialog({
      description: t("Arşivden çıkarmak istediğinize emin misiniz"),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingService.unarchive(
          thing.id,
          (data) => {
            showAlert(t("UNARCHIVE_SUCCESS"), "success");
            const tmpTo = {
              pathname: `/things`,
              breadCrumbKey: `/things`,
              search: "?page=0&size=10&isAsc=false&sort=createDate",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const saveAsProduct = () => {
    ThingService.convertToProduct(
      thing.id,
      thingConvert?.thingClass?.id,
      thingConvert?.productType,
      (data) => {
        showAlert(t("SUCCESS"), "success");
        setIsDialogOpen(false);
        refresh();
      },
      (error) => {
        console.log(error);
      }
    );
  };
  const saveAsInteractionPoint = () => {
    ThingService.convertToInteractionPoint(
      thing.id,
      thingConvert?.thingClass?.id,
      (data) => {
        showAlert(t("SUCCESS"), "success");
        setIsDialogOpen(false);
        refresh();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const convertToFromProduct = () => {
    setThingConvert({
      thingClass: thing.thingClass,
      thingClassId: thing.thingClass?.id,
      productType: thing.productType,
    });
    setIsDialogOpen(true);
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={t("Etkileşim Noktası Dönüştür")}
        faOpen={isDialogOpen}
        faOnSubmit={!thing.productType ? saveAsProduct : saveAsInteractionPoint}
        showSaveButton
        faHandleClose={(data) => {
          setIsDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          {!!!thing.productType && (
            <Grid item xs={12}>
              <ThingProductTypeSelect
                required={true}
                value={thingConvert.productType}
                onChange={(data) => {
                  setThingConvert({
                    ...thingConvert,
                    productType: data,
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ThingClassAutoComplete
              accountId={accountId}
              value={thingConvert.thingClass}
              filterExists={true}
              onChange={(data) => {
                setThingConvert({
                  ...thingConvert,
                  thingClass: data,
                  thingClassId: data?.id,
                });
              }}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />} data-fa-page="THING_DETAIL_PAGE">
        {renderDialog()}
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1} style={{ overflowY: "auto" }}>
              <Grid item xs={12}>
                <ThingDetailCard
                  thing={thing}
                  thumbnail={thumbnail}
                  onUpdate={onThingInfoUpdate}
                  isEditable={isEditable}
                />
              </Grid>

              <Grid item xs={12}>
                <ThingAdditionalFields thing={thing} isEditable={isEditable} />
              </Grid>

              <Grid item xs={12}>
                <ThingDocuments thing={thing} isEditable={isEditable} />
              </Grid>

              <Grid item xs={12}>
                <ThingLinks thing={thing} isEditable={isEditable} />
              </Grid>

              <Grid item xs={12}>
                <ThingRecurrences thing={thing} isEditable={isEditable} />
              </Grid>

              <Grid item xs={12}>
                <ThingEvents thing={thing} isEditable={isEditableHigher} />
              </Grid>

              <Grid item xs={12}>
                {thing && thing.id > 0 && (
                  <ThingMap
                    thing={thing}
                    onChange={gpsLocationChange}
                    onIndoorLocationChange={indoorLocationChange}
                    isEditable={isEditable}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                {thing && thing.id > 0 && <Note thing={thing} />}
              </Grid>

              <Grid item xs={12}>
                <PublicReportTemplate thing={thing} onUpdate={refresh} />
              </Grid>

              {isJournal && (
                <Grid item xs={12}>
                  <ThingJournals thing={thing} isEditable={isEditable} />
                </Grid>
              )}

              {isReservation && (
                <Grid item xs={12}>
                  <ThingReservation thing={thing} onUpdate={refresh} />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <ThingPhotos
                  ref={thingPhotosRef}
                  images={images}
                  setImages={setImages}
                  setDefaultImage={setDefaultImage}
                  refresh={refreshThingImages}
                  thingId={thing.id}
                  thing={thing}
                  isEditable={isEditable}
                />
              </Grid>

              <Adsense value="RklmKare" />

              <Grid item xs={12}>
                <ThingInteractions thingId={thing.id} />
              </Grid>
              <Grid item xs={12}>
                <ThingOwner
                  thing={thing}
                  onUpdate={onThingInfoUpdate}
                  isEditable={isEditableHigher}
                />
              </Grid>

              <Grid item xs={12}>
                <ThingRelationsChart thing={thing} isEditable={isEditable} />
              </Grid>
              <Grid item xs={12}>
                <ThingIssues thing={thing} isEditable={isEditable} />
              </Grid>
              <Grid item xs={12}>
                <ThingHistory thing={thing} isEditable={isEditable} />
              </Grid>
              <Grid item xs={12}>
                <ThingScanCountStat thingId={thing?.id} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} lg={6}></Grid>

              {/* <Grid item xs={6}>
              <ThingStatistics type="pie" title="Sorun Sınıflarına Göre Sayılar" />
            </Grid>
            <Grid item xs={6}>
              <ThingStatistics type="pie" title="Etkileşim Noktası Tipi için Sorun Sınıflarına Göre Sayılar" />
            </Grid>
            <Grid item xs={12}>
              <ThingStatistics type="bar" title="Sorun Tarihlerine Göre İstatistikler" />
            </Grid>
            <Grid item xs={6}>
              <ThingStatistics type="line" title="Etkileşim Noktası için Ortalama Sorun Çözülme Süresi" />
            </Grid>
            <Grid item xs={6}>
              <ThingStatistics type="line" title="Etkileşim Noktası Tipi için Ortalama Sorun Çözülme Süresi" />
            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
        {isEditable && !!!thing.system && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid item container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      startIcon={<Delete />}
                      onClick={deleteThing}
                    >
                      {t("Sil")}
                    </Button>
                    {thing?.archiveDate ? (
                      <Button
                        color="primary"
                        startIcon={<Archive />}
                        onClick={unarchiveThing}
                      >
                        {t("Arşivden Çıkar")}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        startIcon={<Unarchive />}
                        onClick={archiveThing}
                      >
                        {t("Arşivle")}
                      </Button>
                    )}
                    {thing?.productType ? (
                      <Button
                        color="primary"
                        // startIcon={<Delete />}
                        onClick={convertToFromProduct}
                      >
                        {t("Etkileşim Noktasına Dönüştür")}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        // startIcon={<Delete />}
                        onClick={convertToFromProduct}
                      >
                        {t("Ürün/Hizmete Dönüştür")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Suspense>
    </>
  );
});
export default ThingDetail;
