import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import { Check, Close } from "@mui/icons-material";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CUSTOMER_MESSAGE } from "services/faApi";
import { getDateTimeWFormat } from "services/utils";
import i18n from "../../i18n";

export default function CustomerMessageSendDetail(props) {
  const { t } = useTranslation("customerMessage", { i18n });
  const { message } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const accountId = localStorage.accountId;
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);

  useEffect(() => {
    if (!!tableRef && !!tableRef.current && !!tableRef.current.refreshTable) {
      tableRef.current.refreshTable();
    }
  }, [message]);

  const newSearchForm = () => {
    return {
      customerMessageTemplateId: message.id,
      name: null,
      surname: null,
      email: null,
      phone: null,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm);
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const columns = [
    {
      field: "customer",
      label: t("CUSTOMER_NAME"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "customer",
      label: t("CUSTOMER_SURNAME"),
      align: "center",
      width: "20%",
      inner: "surname",
      valueFormatter: (value) => {
        return value?.surname;
      },
    },
    {
      field: "customer",
      label: t("PHONE"),
      align: "center",
      width: "15%",
      inner: "phone",
      valueFormatter: (value) => {
        return value?.phone;
      },
    },
    {
      field: "customer",
      label: t("EMAIL"),
      align: "center",
      width: "15%",
      inner: "email",
      valueFormatter: (value) => {
        return value?.email;
      },
    },
    {
      field: "sendDate",
      label: t("SEND_DATE"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "sendStatus",
      label: t("SEND_STATUS"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        if (value == "YES") return <Check />;
        else return <Close />;
      },
    },
  ];
  const onDoubleClick = (row) => {
    const id = row?.customer.id;
    const tmpTo = {
      pathname: `/customer/detail/${id}`,
      breadCrumbKey: `/customer/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Card>
        <CardHeader
          title={t("CUSTOMER_MESSAGE_SEND_DETAIL")}
          data-fa-section="CUSTOMER_MESSAGE_SEND_DETAIL"
        />
        <ThingsDataTable
          searchUrl={CUSTOMER_MESSAGE.searchDetail}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          queryParams={{
            page: 0,
            size: 50,
            sort: "id",
            isAsc: false,
            accountId: accountId,
          }}
          faOnDoubleClick={onDoubleClick}
        />
      </Card>
    </>
  );
}
