import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";


export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    gridFooter: {
        borderTop: "rgb(224 224 224)",
    },
    table: {},
    cell: {
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    img: {
        height: 406,
    },
    headerButton: {
        backgroundColor: "#343a40",
        marginTop: theme.spacing(1),
        "&:hover": {
            backgroundColor: "#5f5f5f"
        }
    },
    gridList: {
        width: '100%'
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    imgPaper: {
        padding: theme.spacing(1)
    },
    mt: {
        marginTop: theme.spacing(2)
    },
    closeButton: {
        color: theme.palette.grey[500],
        right: 0,
        top: 0,
        position: "absolute"
    },
    drawer: {
        overflow: 'hidden'
    },
    drawerContent: {
        padding: 10,
        height: 400
    },
    drawerPaper: {
        height: 400,
        top: 80,
        width: "21.7%",
        minWidth: 200
    },
    avatar: {
        width: 50,
        height: 50,
        marginRight: 15
    },
    success: {
        backgroundColor: green[500]
    }
}));