import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Alert } from '@mui/material';
import React, { Suspense, useEffect, useState } from "react";
import { getUserInfo, setRole } from "react-base-fa/dist/services/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { AccUserService } from "services/AccUserService";
import LoadingBar from "../../components/loadingBar";

export default function TrialSuccessResult(props) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  const userInfo = useSelector(getUserInfo);

  const accountId = localStorage.accountId;

  useEffect(() => {
    setOpen(true);

    AccUserService.findAccUserRole(
      accountId,
      userInfo.id,
      findAccUserRoleOnSuccess,
      () => {}
    );
  }, []);

  const findAccUserRoleOnSuccess = (data) => {
    dispatch(setRole(data));
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Dialog
          open={open}
          onClose={() => {
            window.location = "/subscriptions";
            //setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deneme süreniz başladı"}
          </DialogTitle>
          <DialogContent>
            <Alert severity="success">
              Ücretsiz deneme kullanımınız başlamıştır!
            </Alert>
          </DialogContent>
        </Dialog>
      </Suspense>
    </>
  );
}
