import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CouponService } from "services/CouponService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CouponCustomers from "./CouponCustomers";
import CouponEdit from "./CouponEdit";
import CouponProducts from "./CouponProducts";
import CouponTarget from "./CouponTarget";
import CouponUsageHeatMap from "./CouponUsageHeatMap";
import CouponUsagePieChart from "./CouponUsagePieChart";

export default function CouponDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("coupon", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();
  let history = useHistory();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState({});
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [editStatus, setEditStatus] = useState();

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    CouponService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setCoupon({
      ...data,
    });
    setEditStatus(data?.status);
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const changeStatus = (status) => {
    faDialog({
      description: t("Kupon durumunu değiştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CouponService.changeStatus(
          id,
          status,
          changeStatusOnSuccess,
          changeStatusOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const changeStatusOnSuccess = (data) => {
    setCoupon({
      ...data,
    });
    refresh();
    setStatusDialogOpen(false);
    setLoading(false);
  };

  const changeStatusOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const editDialogRender = () => {
    return (
      <CouponEdit coupon={coupon} ref={dialogRef} onSave={onSave}></CouponEdit>
    );
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      {editDialogRender()}

      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  title={t("COUPON_INFO")}
                  data-fa-section="COUPON_INFO"
                  action={
                    <div style={{ marginRight: "10px" }}>
                      <IconButton
                        disabled={coupon.status === "ACTIVE"}
                        aria-label="settings"
                        onClick={() => openEditDialog()}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </div>
                  }
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("NAME")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {coupon.name}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DESCRIPTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {coupon.description}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("START_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(coupon.startDate)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("END_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(coupon.endDate)}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("MESSAGE_TEMPLATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t(coupon.messageTemplate?.description)}
                          </Typography>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          className={labelClasses.LabelLastRow}
                        >
                          <Typography className={labelClasses.LabelHeader}>
                            {t("STATUS")}
                          </Typography>
                          <Typography
                            component={"span"}
                            variant={"body2"}
                            className={labelClasses.LabelValue}
                          >
                            <ActivePassiveDecorator value={coupon.status} />
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          {coupon.status === "PASSIVE" && (
                            <FaButton
                              style={{
                                width: "25%",
                                marginRight: 5,
                                marginBottom: 5,
                              }}
                              variant="contained"
                              size="small"
                              color="primary"
                              faClick={() => {
                                changeStatus("ACTIVE");
                              }}
                              //startIcon={<Refresh />}
                              data-fa-button="ACTIVE"
                            >
                              {t("ACTIVE")}
                            </FaButton>
                          )}
                          {coupon.status === "ACTIVE" && (
                            <FaButton
                              style={{
                                width: "25%",
                                marginRight: 5,
                                marginBottom: 5,
                              }}
                              variant="contained"
                              size="small"
                              color="primary"
                              faClick={() => {
                                changeStatus("PASSIVE");
                              }}
                              //startIcon={<Refresh />}
                              data-fa-button="PASSIVE"
                            >
                              {t("PASSIVE")}
                            </FaButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              {coupon && coupon.id > 0 && (
                <CouponProducts coupon={coupon}></CouponProducts>
              )}
            </Grid>
            <Grid item xs={12}>
              {coupon && coupon.id > 0 && (
                <CouponTarget coupon={coupon}></CouponTarget>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {coupon && coupon.id > 0 && (
                <CouponUsagePieChart coupon={coupon}></CouponUsagePieChart>
              )}
            </Grid>
            <Grid item xs={12}>
              {coupon && coupon.id > 0 && (
                <CouponUsageHeatMap coupon={coupon}></CouponUsageHeatMap>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {coupon && coupon.id > 0 && (
                <CouponCustomers coupon={coupon}></CouponCustomers>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Suspense>
  </>;
}
