import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import ThingHashtag from "components/hashtag/ThingHashtag";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FaButton,
  FaDatePicker,
  FaDialog,
  FaInput,
} from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RecurrenceService } from "services/RecurrenceService";
import { ThingDesc, days, isTNF, months } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

const StaticRecurrenceEdit = forwardRef((props, ref) => {
  const { recurrence, onSave, thing } = props;
  const { t } = useTranslation("recurrence", { i18n });
  const accountId = localStorage.accountId;
  const classes = useStyles();

  const userInfo = useSelector(getUserInfo);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sectionHashtag, setSectionHashtag] = useState(false);
  const [sectionThingClass, setSectionThingClass] = useState(false);
  const [sectionThing, setSectionThing] = useState(false);
  const [sectionOrganization, setSectionOrganization] = useState(false);
  const [sectionLocation, setSectionLocation] = useState(false);

  const newRecurrence = () => {
    return {
      id: 0,
      name: "",
      description: "",
      account: {
        id: accountId,
      },
      user: {
        id: userInfo.id,
      },
      status: "PASSIVE",
      dateType: "LITERAL",
      userGroup: null,
      frequency: "hour",
      hourlyInterval: 8,

      dailyMod: "INTERVAL", //WEEKDAY
      dailyInterval: 1,

      weeklyInterval: 1,
      weeklyDays: [false, false, false, false, false, false, false],

      monthlyDay: 1,
      monthlyInterval: 1,

      yearlyInterval: 1,
      yearlyDay: 1,
      yearlyMonth: 0,

      startTime: new Date(2021, 1, 1, 12, 0, 0),
      startDate: new Date(),
      endDate: null,

      mtncRecurrenceTargets: [],
    };
  };

  const [recurrenceDTO, setRecurrenceDTO] = useState(newRecurrence());

  useImperativeHandle(ref, () => ({
    openDialog() {
      if (thing) {
        let dto = {
          thingClass: null,
          thing: { id: thing.id },
          hashtag: null,
          label: ThingDesc(thing),
          key: "t_" + thing.id,
        };

        setRecurrenceDTO({
          ...recurrenceDTO,
          mtncRecurrenceTargets: [dto],
        });
      }
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (recurrence) {
      setRecurrenceDTO(
        recurrence == null ? newRecurrence() : { ...recurrence }
      );
    }
  }, [recurrence]);

  const saveRecurrence = () => {
    setLoading(true);
    RecurrenceService.save({ ...recurrenceDTO }, saveOnSuccess, saveOnError);
  };

  const saveTask = () => {
    const recurrenceReqObject = {
      ...recurrenceDTO,
      hourlyInterval:
        recurrenceDTO.frequency === "hour"
          ? recurrenceDTO.hourlyInterval
          : null,
      dailyMod:
        recurrenceDTO.frequency === "day" ? recurrenceDTO.dailyMod : null,
      dailyInterval:
        recurrenceDTO.frequency === "day" ? recurrenceDTO.dailyInterval : null,

      weeklyInterval:
        recurrenceDTO.frequency === "week"
          ? recurrenceDTO.weeklyInterval
          : null,
      weeklyDays:
        recurrenceDTO.frequency === "week"
          ? recurrenceDTO.weeklyDays
          : [false, false, false, false, false, false, false],

      monthlyDay:
        recurrenceDTO.frequency === "month" ? recurrenceDTO.monthlyDay : null,
      monthlyInterval:
        recurrenceDTO.frequency === "month"
          ? recurrenceDTO.monthlyInterval
          : null,

      yearlyInterval:
        recurrenceDTO.frequency === "year"
          ? recurrenceDTO.yearlyInterval
          : null,
      yearlyDay:
        recurrenceDTO.frequency === "year" ? recurrenceDTO.yearlyDay : null,
      yearlyMonth:
        recurrenceDTO.frequency === "year" ? recurrenceDTO.yearlyMonth : null,
    };

    RecurrenceService.save({ ...recurrenceDTO }, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(t("SAVED"), "success"); // edited silindi
    setIsDialogOpen(false);
    setLoading(false);
    onSave(data);
  };
  const saveOnError = (error) => {
    console.log(error);
    showAlert(error, "error");
    setLoading(false);
  };

  const handleChange = (prop, val) => {
    setRecurrenceDTO({ ...recurrenceDTO, [prop]: val });
  };

  const handleChipDelete = (index) => () => {
    var temp = [...recurrenceDTO.mtncRecurrenceTargets];
    temp.splice(index, 1);
    setRecurrenceDTO({ ...recurrenceDTO, mtncRecurrenceTargets: temp });
  };

  const addTarget = (newValue) => {
    setRecurrenceDTO({
      ...recurrenceDTO,
      mtncRecurrenceTargets: [...recurrenceDTO.mtncRecurrenceTargets, newValue],
    });
  };

  const addTargetHashtag = (value) => {
    let dto = {
      thingClass: null,
      thing: null,
      organization: null,
      location: null,
      locationDetail: null,
      hashtag: value,
      label: value,
      key: "h_" + value,
    };

    addTarget(dto);
  };

  const addTargetThingClass = (value) => {
    let dto = {
      thingClass: { id: value.id },
      thing: null,
      hashtag: null,
      label: value.className,
      key: "c_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetOrganization = (value) => {
    let dto = {
      organization: { id: value.id },
      label: value.name,
      key: "o_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocation = (value) => {
    let dto = {
      location: { id: value.id },
      label: value.name,
      key: "l_" + value.id,
    };

    addTarget(dto);
  };

  const handleTabChange = (event, newValue) => {
    setRecurrenceDTO({ ...recurrenceDTO, frequency: newValue });
  };

  const handleChangeWeeklyDays = (index, checked) => {
    var wd = recurrenceDTO.weeklyDays;
    wd[index] = checked;

    setRecurrenceDTO({ ...recurrenceDTO, weeklyDays: wd });
  };

  return (
    <FaDialog
      title={t("NEW_PERIODIC_TASK")}
      faOpen={isDialogOpen}
      faOnSubmit={() => saveTask()}
      loading={loading}
      faHandleClose={() => {
        setIsDialogOpen(false);
        setRecurrenceDTO(recurrence ? recurrence : newRecurrence());
      }}
      showSaveButton
      faMaxWidth="lg"
    >
      <Grid item container spacing={1}>
        <Grid item lg={6} xs={12}>
          <FaInput
            required
            value={recurrenceDTO.name}
            label={t("PERIODIC_TASK_NAME")}
            onChange={(event) => {
              handleChange("name", event.target.value);
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <FaInput
            value={recurrenceDTO.description}
            label={t("PERIODIC_TASK_DESCRIPTION")}
            onChange={(event) => {
              handleChange("description", event.target.value);
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <UserAndGroupAutoComplete
            value={{
              user: recurrenceDTO.user,
              userGroup: recurrenceDTO.userGroup,
            }}
            accountId={accountId}
            onChange={(data) => {
              setRecurrenceDTO({
                ...recurrenceDTO,
                user: data.user,
                userGroup: data.userGroup,
              });
            }}
            label={t("USER")}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{t("TARGET_GROUP")}</Typography>

          <Paper component="ul" className={classes.chipRoot}>
            {recurrenceDTO.mtncRecurrenceTargets.map((data, index) => {
              let icon;

              if (data.thingClass) {
                icon = <FontAwesomeIcon icon={faSitemap} />;
              } else if (data.thing) {
                icon = <FontAwesomeIcon icon={faCubes} />;
              } else if (data.hashtag) {
                icon = <FontAwesomeIcon icon={faHashtag} />;
              } else if (data.organization) {
                icon = <FontAwesomeIcon icon={faFolder} />;
              } else if (data.location) {
                icon = <FontAwesomeIcon icon={faGlobe} />;
              } else if (data.locationDetail) {
                icon = <FontAwesomeIcon icon={faMap} />;
              }

              return (
                <li key={data.key}>
                  <Chip
                    icon={icon}
                    label={data.label}
                    onDelete={handleChipDelete(index)}
                    className={classes.chip}
                  />
                </li>
              );
            })}
          </Paper>
          <div className={classes.buttonPanel}>
            <FaButton
              faClick={() => {
                setSectionHashtag(true);
              }}
              fullWidth={false}
              startIcon={<AddIcon />}
            >
              {t("ADD_HASHTAG")}
            </FaButton>
            <FaButton
              faClick={() => {
                setSectionThingClass(true);
              }}
              fullWidth={false}
              startIcon={<AddIcon />}
            >
              {isTNF(t("ADD_THINGCLASS"), t("ADD_THINGCLASS_T2C"))}
            </FaButton>
            <FaButton
              faClick={() => {
                setSectionThing(true);
              }}
              fullWidth={false}
              startIcon={<AddIcon />}
            >
              {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
            </FaButton>
            <FaButton
              faClick={() => {
                setSectionOrganization(true);
              }}
              fullWidth={false}
              startIcon={<AddIcon />}
            >
              {t("ADD_ORGANIZATION")}
            </FaButton>
            <FaButton
              faClick={() => {
                setSectionLocation(true);
              }}
              fullWidth={false}
              startIcon={<AddIcon />}
            >
              {t("ADD_LOCATION")}
            </FaButton>
          </div>
          {sectionHashtag ? (
            <div className={classes.hboxBlock}>
              <ThingHashtag
                accountId={accountId}
                onChange={(data) => {
                  addTargetHashtag(data);
                  setSectionHashtag(false);
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                className={classes.cancelButton}
                onClick={() => {
                  setSectionHashtag(false);
                }}
              >
                {t("CANCEL")}
              </Button>
            </div>
          ) : null}

          {sectionThingClass ? (
            <div className={classes.hboxBlock}>
              <ThingClassAutoComplete
                accountId={accountId}
                onChange={(data) => {
                  addTargetThingClass(data);
                  setSectionThingClass(false);
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                className={classes.cancelButton}
                onClick={() => {
                  setSectionThingClass(false);
                }}
              >
                {t("CANCEL")}
              </Button>
            </div>
          ) : null}

          {sectionThing ? (
            <div className={classes.hboxBlock}>
              <ThingAutoComplete
                accountId={accountId}
                onChange={(data) => {
                  addTargetThing(data);
                  setSectionThing(false);
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                className={classes.cancelButton}
                onClick={() => {
                  setSectionThing(false);
                }}
              >
                {t("CANCEL")}
              </Button>
            </div>
          ) : null}
          {sectionOrganization ? (
            <div className={classes.hboxBlock}>
              <OrganizationAutoComplete
                accountId={accountId}
                onChange={(data) => {
                  addTargetOrganization(data);
                  setSectionOrganization(false);
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                className={classes.cancelButton}
                onClick={() => {
                  setSectionOrganization(false);
                }}
              >
                {t("CANCEL")}
              </Button>
            </div>
          ) : null}

          {sectionLocation ? (
            <div className={classes.hboxBlock}>
              <LocationAutoComplete
                accountId={accountId}
                onChange={(data) => {
                  addTargetLocation(data);
                  setSectionLocation(false);
                }}
              />
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                className={classes.cancelButton}
                onClick={() => {
                  setSectionLocation(false);
                }}
              >
                {t("CANCEL")}
              </Button>
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{t("RECURRENCE_PERIOD")}</Typography>

          <TabContext value={recurrenceDTO.frequency}>
            <Box display="flex" color="transparent" flexDirection="column">
              <TabList
                value={recurrenceDTO.frequency}
                onChange={handleTabChange}
                scrollButtons="auto"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
              >
                <Tab label={t("HOURLY")} value="hour" />
                <Tab label={t("DAILY")} value="day" />
                <Tab label={t("WEEKLY")} value="week" />
                <Tab label={t("MONTHLY")} value="month" />
                <Tab label={t("YEARLY")} value="year" />
              </TabList>
              <Divider />
            </Box>
            <TabPanel value="hour">
              <div className={classes.hboxBlock}>
                {t("EVERY")}
                <FaInput
                  faType={"number"}
                  value={recurrenceDTO.hourlyInterval}
                  style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                  onChange={(event) => {
                    handleChange("hourlyInterval", event.target.value);
                  }}
                />
                {t("HOURS")}
              </div>
            </TabPanel>
            <TabPanel value="day">
              <FormControl variant="standard" component="fieldset">
                <RadioGroup
                  value={recurrenceDTO.dailyMod}
                  onChange={(event) => {
                    handleChange("dailyMod", event.target.value);
                  }}
                >
                  <div className={classes.hboxBlock}>
                    <FormControlLabel
                      value="INTERVAL"
                      control={<Radio />}
                      label=" "
                    />
                    {t("EVERY")}
                    <FaInput
                      faType={"number"}
                      value={recurrenceDTO.dailyInterval}
                      style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                      onChange={(event) => {
                        handleChange("dailyInterval", event.target.value);
                      }}
                    />
                    {t("DAYS")}
                  </div>
                  <div className={classes.hboxBlock}>
                    <FormControlLabel
                      value="WEEKDAY"
                      control={<Radio />}
                      label={t("WEEKDAYS")}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </TabPanel>
            <TabPanel value="week">
              <div className={classes.hboxBlock}>
                {t("EVERY")}
                <FaInput
                  faType={"number"}
                  value={recurrenceDTO.weeklyInterval}
                  style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                  onChange={(event) => {
                    handleChange("weeklyInterval", event.target.value);
                  }}
                />
                {t("WEEKS")}
              </div>
              {days.map((day, index) => (
                <FormControlLabel
                  key={index}
                  value={index}
                  control={
                    <Checkbox
                      checked={recurrenceDTO.weeklyDays[index]}
                      onChange={(event) => {
                        handleChangeWeeklyDays(index, event.target.checked);
                      }}
                      name="notEmpty"
                      color="primary"
                    />
                  }
                  label={t(day)}
                />
              ))}
            </TabPanel>
            <TabPanel value="month">
              <div className={classes.hboxBlock}>
                {t("EVERY_MONTH_ON_THE")}
                <FaInput
                  faType={"number"}
                  value={recurrenceDTO.monthlyDay}
                  style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                  onChange={(event) => {
                    handleChange("monthlyDay", event.target.value);
                  }}
                />
                {t("DAY_OF_EVERY")}
                <FaInput
                  faType={"number"}
                  value={recurrenceDTO.monthlyInterval}
                  style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                  onChange={(event) => {
                    handleChange("monthlyInterval", event.target.value);
                  }}
                />
                {t("MONTHS")}
              </div>
            </TabPanel>
            <TabPanel value="year">
              <div className={classes.hboxBlock}>
                {t("EVERY")}
                <FaInput
                  faType={"number"}
                  value={recurrenceDTO.yearlyInterval}
                  style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                  onChange={(event) => {
                    handleChange("yearlyInterval", event.target.value);
                  }}
                />
                {t("YEARS")}
                <Select
                  variant="standard"
                  className={classes.timeSelect}
                  value={recurrenceDTO.yearlyMonth}
                  onChange={(event) => {
                    handleChange("yearlyMonth", event.target.value);
                  }}>
                  {months.map((item, index) => (
                    <MenuItem key={index} value={index}>
                      {t(item)}
                    </MenuItem>
                  ))}
                </Select>
                {t("MONTH_OF_THE")}
                <FaInput
                  faType={"number"}
                  value={recurrenceDTO.yearlyDay}
                  style={{ width: 90, paddingLeft: 10, paddingRight: 10 }}
                  onChange={(event) => {
                    handleChange("yearlyDay", event.target.value);
                  }}
                />
                {t("DAY")}
              </div>
            </TabPanel>
          </TabContext>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">{t("RECURRENCE_INTERVAL")}</Typography>

          <Grid item container spacing={1}>
            <Grid item lg={4} xs={12}>
              <FaDatePicker
                faType="date"
                required
                label={t("RECURRENCE_START_DATE")}
                style={{ minHeight: 65 }}
                value={recurrenceDTO.startDate}
                faOnChange={(val) => {
                  handleChange("startDate", val);
                }}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <FaDatePicker
                faType="time"
                required
                label={t("START_TIME")}
                value={recurrenceDTO.startTime}
                faOnChange={(val) => {
                  handleChange("startTime", val);
                }}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <FaDatePicker
                faType="date"
                label={t("RECURRENCE_END_DATE")}
                style={{ minHeight: 65 }}
                value={recurrenceDTO.endDate}
                faOnChange={(val) => {
                  handleChange("endDate", val);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default StaticRecurrenceEdit;
