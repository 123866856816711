import { Card, CardHeader, Grid, Typography } from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import { convertFromRaw, EditorState } from "draft-js";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ANNOUNCEMENT } from "services/faApi";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../../i18n";

const ebru = {
  id: "10",
  name: "kemal",
};

export default function HomepageAnnouncements(props) {
  const { size, refreshCount } = props;
  const { t } = useTranslation(["components", "announcement"], { i18n });
  const dialogRef = useRef();
  let history = useHistory();
  const accountId = localStorage.accountId;
  const tableRef = useRef();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const labelClasses = labelStyles();

  useEffect(() => {
    if (refreshCount > 0) {
      tableRef.current.refreshTable();
    }
  }, [refreshCount]);

  const newSearchForm = () => {
    return {
      thingId: null,
      subject: null,
      contentRow: null,
      accountId: accountId,
      noThing: true,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const columns = [
    {
      field: "subject",
      label: t("announcement:SUBJECT"),
      align: "center",
      width: "40%",
    },
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      width: "30%",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      width: "30%",
      type: "dateTime",
    },
  ];

  const onDoubleClick = (row) => {
    setSelectedRow(row);
    setEditorState(
      EditorState.createWithContent(convertFromRaw(JSON.parse(row.contentRow)))
    );
    setIsDialogOpen(true);
  };

  const detailDialog = () => {
    return (
      <FaDialog
        title={t("announcement:ANNOUNCEMENT")}
        faOpen={isDialogOpen}
        faHandleClose={setIsDialogOpen}
        faMaxWidth="md"
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12} md={6} className={labelClasses.LabelRow}>
            <Typography className={labelClasses.LabelHeader}>
              {t("announcement:SUBJECT")}
            </Typography>
            <Typography className={labelClasses.LabelValue}>
              {selectedRow?.subject}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={labelClasses.LabelRow}>
            <Typography className={labelClasses.LabelHeader}>
              {t("START_DATE")}
            </Typography>
            <Typography className={labelClasses.LabelValue}>
              {getDateTimeWFormat(selectedRow?.startDate)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={labelClasses.LabelRow}>
            <Typography className={labelClasses.LabelHeader}>
              {t("END_DATE")}
            </Typography>
            <Typography className={labelClasses.LabelValue}>
              {getDateTimeWFormat(selectedRow?.endDate)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} className={labelClasses.LabelLastRow}>
            <Editor editorState={editorState} readOnly toolbarHidden />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      {detailDialog()}
      <Grid item lg={size} xs={12}>
        <Card>
          <CardHeader title={t("HOMEPAGE_ITEM.ANNOUNCEMENTS")} />

          <ThingsDataTable
            searchUrl={ANNOUNCEMENT.search}
            searchForm={searchForm}
            columns={columns}
            queryParams={{ page: 0, size: 10, isAsc: true, sort: "startDate" }}
            ref={tableRef}
            showHeaderText={false}
            faOnDoubleClick={onDoubleClick}
          />
        </Card>
      </Grid>
    </>
  );
}
