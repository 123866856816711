import { Avatar } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ThingsDataTable from "components/thingsDataTable";
import CampaignCustomerStatusDecorator from "components/ui/CampaignCustomerStatusDecorator";
import i18n from "i18n";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat } from "services/utils";
import { useStyles } from "./style";

export default function MyCampaigns(props) {
  const { campaign } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation(["beneficiaryPortal", "report"], { i18n });

  const classes = useStyles();
  const tableRef = useRef();
  let history = useHistory();

  const newSearchForm = () => {
    return {
      accountId: localStorage.beneficiaryAccountId,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "registerDate",
      label: t("Kayıt Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "campaign",
      label: t("Kampanya"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        if (value.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${value.thumbnail}`}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value.name}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.name.charAt(0)}
              </Avatar>{" "}
              {value.name}
            </div>
          );
      },
    },
    {
      field: "draw",
      label: t("Çekiliş Hakkı"),
      align: "center",
      width: "20%",
    },
    {
      field: "status",
      label: t("Durum"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => {
        return <CampaignCustomerStatusDecorator value={val} />;
      },
    },
  ];

  const onDoubleClick = (row) => {
    const accId = row?.campaign?.account?.id;
    const tmpTo = {
      pathname: `/bportal/campaignDetail/${row.id}/${accId}`,
      breadCrumbKey: `/bportal/campaignDetail/:id/:accId`,
    };
    history.push(tmpTo);
  };

  return (
    <Card>
      <CardHeader
        title={t("Kampanya ve Etkinliklerim")}
        data-fa-section="CAMPAIGNS"
      />
      <ThingsDataTable
        searchUrl={BENEFICIARY_PORTAL.campaigns}
        searchForm={searchForm}
        columns={columns}
        ref={tableRef}
        showHeaderText={false}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "registerDate",
        }}
        faOnDoubleClick={onDoubleClick}
      />
    </Card>
  );
}
