import { Grid } from "@mui/material";
// import TimezoneSelect from 'react-timezone-select'
import AddIcon from "@mui/icons-material/Add";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { SLA_CALENDAR } from "../../services/faApi";
import { hourMinuteToDate } from "../../services/utils";
import { useStyles } from "./style";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function SlaCalendar() {
  const classes = useStyles();
  const { t } = useTranslation("slaCalendar", { i18n });
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const tableRef = useRef();
  let history = useHistory();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  // States
  const [loading, setLoading] = useState(false);
  const [newCalendarOpen, setNewCalendarOpen] = useState(false);
  const [newHoursOpen, setNewHoursOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [calendarDTO, setCalendarDTO] = useState({ name: "", timeZoneId: "" });
  const [editCalendarDTO, setEditCalendarDTO] = useState({
    name: "",
    timeZoneId: "",
  });
  const [newCalendarDTO, setNewCalendarDTO] = useState({
    name: "",
    timeZoneId: "",
  });
  const [newHours, setNewHours] = useState({
    start: hourMinuteToDate(0, 0),
    end: hourMinuteToDate(23, 59),
  });
  const [calendarHours, setCalendarHours] = useState([]);
  const [weekDay, setWeekDay] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [newHolidayOpen, setNewHolidayOpen] = useState(false);
  const [holidayDTO, setHolidayDTO] = useState({
    name: "",
    halfWhole: false,
    startTime: hourMinuteToDate(0, 0),
    endTime: hourMinuteToDate(23, 59),
    recurring: false,
    holidaydate: new Date(),
  });

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `SLA-calendar/edit/${row.id}`,
      breadCrumbKey: `/SLA-calendar/edit/:id`,
    };
    history.push(tmpTo);
  };

  const columns = [
    {
      field: "name",
      label: t("CALENDAR_NAME"),
      align: "center",
    },
    {
      field: "timeZoneId",
      label: t("TIMEZONE"),
      align: "center",
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      type: "dateTime",
    },
    {
      field: "lastChangeDate",
      label: t("LAST_CHANGE_DATE"),
      align: "center",
      type: "dateTime",
    },
  ];

  const saveCalendar = () => {
    const req = requests.getSimplePostRequest(SLA_CALENDAR.save, {
      ...newCalendarDTO,
      account: { id: accountId },
    });
    FaAxios(req)
      .then((res) => {
        console.log(res.data);
        let name = newCalendarDTO.name;
        setNewCalendarDTO({ name: "", timeZoneId: "" });
        setNewCalendarOpen(false);

        const tmpTo = {
          pathname: `SLA-calendar/edit/${res.data.id}`,
          breadCrumbKey: `/SLA-calendar/edit/:id`,
        };
        history.push(tmpTo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeNew = (prop, val) => {
    setNewCalendarDTO({ ...newCalendarDTO, [prop]: val });
  };

  const renderNewCalendarDialog = () => {
    return (
      <FaDialog
        title={t("NEW_CALENDAR")}
        faOpen={newCalendarOpen}
        faOnSubmit={() => saveCalendar()}
        loading={loading}
        faHandleClose={() => setNewCalendarOpen(false)}
        showSaveButton
      >
        <Grid item container>
          <Grid item xs={12}>
            <FaInput
              required
              value={newCalendarDTO.name}
              label={t("CALENDAR_NAME")}
              onChange={(event) => {
                handleChangeNew("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <TimezoneSelect
                            required
                            value={newCalendarDTO.timeZoneId || ""}
                            onChange={tz => { handleChangeNew("timeZoneId", tz.value); }}
                            placeholder={t("TIMEZONE")}
                        /> */}
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderNewCalendarDialog()}

        {
          <ThingsDataTable
            dataUrl={
              SLA_CALENDAR.findByAccount +
              "?accountId=" +
              localStorage.accountId +
              "&page=0&size=10&isAsc=false&sort=name"
            }
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            faOnDoubleClick={onDoubleClick}
          />
        }
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setNewCalendarOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Suspense>
    </>
  );
}
