import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function QueueDispatchMethodSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100} required={required}>
      <InputLabel>{t("QUEUE_DISPATCH_METHOD")}</InputLabel>
      <Select
        variant="outlined"
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("QUEUE_DISPATCH_METHOD")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"ROUND_ROBIN"}>
          {t("QUEUE_DISPATCH_METHOD_ROUND_ROBIN")}
        </MenuItem>
        <MenuItem value={"LEAST_CONNECTION"}>
          {t("QUEUE_DISPATCH_METHOD_LEAST_CONNECTION")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
