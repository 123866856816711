import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Grid, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import ThingsDataTable from "components/thingsDataTable";
import NotesHelp from "features/help/NotesHelp";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NoteService } from "services/NoteService";
import { NOTE } from "services/faApi";
import { qsParse } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";

export default function Note(props) {
  const { thing, customer, issue, solution, thingClass } = props;
  const accountId = localStorage.accountId;
  const tableRef = useRef();
  const userInfo = useSelector(getUserInfo);
  const gridClasses = gridStyles();
  const { t } = useTranslation("note", { i18n });
  const faDialog = useDialog();
  let history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const newNote = () => {
    return {
      note: "",
      user: userInfo,
      account: { id: accountId },
    };
  };
  const [note, setNote] = useState(newNote());
  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      noteType: null,
      thingId: null,
      customerId: null,
      issueId: null,
      solutionId: null,
      thingClassId: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState();

  const setObjects = () => {
    if (customer) {
      setSearchForm({
        ...newSearchForm(),
        customerId: customer.id,
        noteType: "CUSTOMER",
      });
      setNote({ ...newNote(), customer: customer, noteType: "CUSTOMER" });
    } else if (issue) {
      setSearchForm({
        ...newSearchForm(),
        issueId: issue.id,
        noteType: "ISSUE",
      });
      setNote({ ...newNote(), issue: issue, noteType: "ISSUE" });
    } else if (thing) {
      setSearchForm({
        ...newSearchForm(),
        thingId: thing.id,
        noteType: "THING",
      });
      setNote({ ...newNote(), thing: thing, noteType: "THING" });
    } else if (solution) {
      setSearchForm({
        ...newSearchForm(),
        solutionId: solution.id,
        noteType: "SOLUTION",
      });
      setNote({ ...newNote(), solution: solution, noteType: "SOLUTION" });
    } else if (thingClass?.id) {
      setSearchForm({
        ...newSearchForm(),
        noteType: "THING_CLASS",
        thingClassId: thingClass.id,
      });
      setNote({
        ...newNote(),
        thingClass: thingClass,
        noteType: "THING_CLASS",
      });
    }
  };

  useEffect(() => {
    setObjects();
  }, [customer, issue, thing, solution, thingClass]);

  const columns = [
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "15%",
      type: "dateTime",
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "20%",
      inner: "fullname",
      valueFormatter: (val) => {
        return val?.fullname;
      },
    },
    {
      field: "note",
      label: t("NOTE"),
      align: "center",
      width: "55%",
    },
    {
      field: "id",
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.user.id !== userInfo.id) {
          return null;
        } else {
          return (
            <>
              <IconButton
                onClick={() => onEdit(row)}
                size="small"
                color="primary"
                aria-label={t("Sil")}
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
              <IconButton
                onClick={() => onDelete(row)}
                size="small"
                color="primary"
                aria-label={t("Sil")}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </>
          );
        }
      },
    },
  ];

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const saveNote = () => {
    setLoading(true);
    NoteService.save(note, saveOnSuccess, saveOnError);
  };
  const saveOnSuccess = () => {
    setObjects();
    setLoading(false);
    setIsDialogOpen(false);
    refresh();
  };
  const saveOnError = () => {
    //
  };
  const handleChange = (prop, val) => {
    setNote({ ...note, [prop]: val });
  };

  const onDelete = (row) => {
    faDialog({
      description: t("Notu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        NoteService.delete(row, deleteSuccess, deleteError);
      })
      .catch((err) => console.log("error:", err));
  };
  const deleteSuccess = () => {
    refresh();
  };
  const deleteError = (error) => {
    console.log(error);
  };

  const onEdit = (row) => {
    setIsDialogOpen(true);
    setNote(row);
  };

  const renderNewNoteDialog = () => {
    return (
      <FaDialog
        title={t("ADD_NOTE")}
        faOpen={isDialogOpen}
        faOnSubmit={() => saveNote()}
        loading={loading}
        faHandleClose={() => setIsDialogOpen(false)}
        showSaveButton
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          data-fa-section="NOTE_DIALOG"
        >
          <Grid item xs={12}>
            <FaInput
              required
              value={note.note}
              label={t("NOTE")}
              faType="description"
              onChange={(event) => {
                handleChange("note", event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      {renderNewNoteDialog()}
      <Card style={{ overflowY: "auto" }}>
        <CardHeader
          title={t("NOTES")}
          data-fa-section="NOTES"
          action={
            <>
              {thing && (
                <NotesHelp value="thingDetail.thingNotes" thing={true} />
              )}
              {issue && (
                <NotesHelp value="issueDetail.issueNotes" issue={true} />
              )}
              {thingClass && (
                <NotesHelp value="thingClass.notes" thingClass={true} />
              )}
              <IconButton
                aria-label="settings"
                onClick={() => {
                  setIsDialogOpen(true);
                }}
                data-fa-button="NOTE_ADD"
                size="large"
              >
                <ThingsEditIcon />
              </IconButton>
            </>
          }
        />
        {searchForm && (
          <ThingsDataTable
            searchUrl={NOTE.search}
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
            headerText={t("NOTES")}
            showHeader={false}
            columnClassName={gridClasses.GenericColumn}
            queryParams={{
              page: 0,
              size: 10,
              isAsc: false,
              sort: "createDate",
            }}
          />
        )}
      </Card>
    </>
  );
}
