import { faFile, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PublicDocument from "./PublicDocument";

export default function PublicThingDetailInfo(props) {
  const { publicThing, onLinkClick } = props;
  const { id, thingId, accountId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [gpsError, setGpsError] = useState(false);
  const lang = getCurrentLang();
  const location = useLocation();

  const [isShowNextButton, setIsShowNextButton] = useState(false);

  const [visitorId, setVisitorId] = useState(null);
  const [blockReport, setBlockReport] = useState(false);

  const [survey, setSurvey] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const dispatch = useDispatch();

  const [documentParam, setDocumentParam] = useState(null);

  const renderDocumentsAndLinks = () => {
    return (
      <Grid container spacing={1}>
        {publicThing?.documentList?.map((item, index) => (
          <Grid
            item
            lg={12}
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
          >
            <Typography
              onClick={() => {
                setDocumentParam(item);
              }}
              style={{
                color: "#512562",
                borderBottom: "1px solid",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faFile} /> {item.name ?? item.fileName}
            </Typography>
          </Grid>
        ))}

        {publicThing?.thingLinkList?.map((item, index) => (
          <Grid
            item
            lg={12}
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
          >
            <Typography
              onClick={() => {
                window.open(item.url, "_blank").focus();
                onLinkClick(item);
              }}
              style={{
                color: "#512562",
                borderBottom: "1px solid",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faLink} /> {item.description ?? item.url}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getDocumentsAndLinksCount = () => {
    let docs =
      publicThing?.documentList && publicThing?.documentList.length > 0
        ? publicThing?.documentList.length
        : 0;
    let links =
      publicThing?.thingLinkList && publicThing?.thingLinkList.length > 0
        ? publicThing?.thingLinkList.length
        : 0;

    return docs + links;
  };

  return (
    <>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <PublicDocument
          value={documentParam}
          publicThing={publicThing}
          onClose={() => {
            setDocumentParam(null);
          }}
        />
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <p>
          <Typography
            style={{ fontWeight: "bold", color: "#6D6D6D", fontSize: 16 }}
          >
            Dokümanlar ve Linkler ({getDocumentsAndLinksCount()})
          </Typography>
        </p>
        {renderDocumentsAndLinks()}
      </Grid>
    </>
  );
}

PublicThingDetailInfo.propTypes = {
  publicThing: PropTypes.any,
  onLinkClick: PropTypes.any,
};
