import { Button, Grid } from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useHistory } from "react-router-dom";

import ThingClassHashtag from "components/hashtag/ThingClassHashtag";
import TimePeriodSelect from "components/select/TimePeriodSelect";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import i18n from "../../i18n";

const ServiceOperationEdit = forwardRef((props, ref) => {
  const { onSave, serviceOperation } = props;
  const { t } = useTranslation("serviceOperationClass", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const newServiceOperation = () => {
    return {
      account: { id: accountId },
      className: null,
      code: null,
      domain: null,
      parent: null,
    };
  };
  const [serviceOperationDTO, setServiceOperationDTO] = useState(
    newServiceOperation()
  );

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsEditFormOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (serviceOperation) setServiceOperationDTO(serviceOperation);
  }, [serviceOperation]);

  const save = () => {
    ServiceOperationClassService.save(
      serviceOperationDTO,
      (data) => {
        onSave(data);
        setIsEditFormOpen(false);
      },
      (error) => {
        showAlert(error, "error");
      }
    );
  };

  const handleChange = (prop, val) => {
    setServiceOperationDTO({ ...serviceOperationDTO, [prop]: val });
  };
  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const handleChangeV = (prop, val) => {
    setServiceOperationDTO({ ...serviceOperationDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Bakım görevini silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ServiceOperationClassService.delete(
          serviceOperationDTO,
          (data) => {
            showAlert(t("Bakım görevi silindi"), "success");
            setIsEditFormOpen(false);
            const tmpTo = {
              pathname: `/serviceOperationClass`,
              breadCrumbKey: `/serviceOperationClass`,
              search: "?page=0&size=10&isAsc=false&sort=className",
            };
            history.push(tmpTo);
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <>
      <FaDialog
        title={"Bakım Görevi Tanımla"}
        faOpen={isEditFormOpen}
        faOnSubmit={save}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsEditFormOpen(false);
          if (!serviceOperation) setServiceOperationDTO(newServiceOperation());
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={t("GENERIC_CLASSNAME")}
              value={serviceOperationDTO.className || ""}
              onChange={(event) =>
                handleChange("className", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <ThingClassHashtag
              label={t("DOMAIN")}
              accountId={accountId}
              value={serviceOperationDTO.domain || ""}
              onChange={(data) => handleChange("domain", data)}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              maxLength={100}
              label={t("CODE")}
              value={serviceOperationDTO.code || ""}
              onChange={(event) => handleChange("code", event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("EQUIPMENT")}
              value={serviceOperationDTO.equipment || ""}
              onChange={(event) =>
                handleChange("equipment", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              label={t("PERSONAL_COUNT")}
              value={serviceOperationDTO.personalCount || ""}
              onChange={(event) =>
                handleChange("personalCount", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              faType="number"
              label={t("DURATION")}
              value={serviceOperationDTO.duration || ""}
              onChange={(event) => handleChange("duration", event.target.value)}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 18 }}>
            <TimePeriodSelect
              value={serviceOperationDTO.timePeriod}
              onChange={(data) => handleChange("timePeriod", data)}
            ></TimePeriodSelect>
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("TRAININGS")}
              value={serviceOperationDTO.trainings || ""}
              onChange={(event) =>
                handleChange("trainings", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("WARNINGS")}
              value={serviceOperationDTO.warnings || ""}
              onChange={(event) => handleChange("warnings", event.target.value)}
            />
          </Grid>
          {serviceOperationDTO.id && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={onDelete} color="secondary">
                {t("Sil")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    </>
  );
});

export default ServiceOperationEdit;
