import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { ProjectService } from "services/ProjectService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function ProjectUsers(props) {
  const { project } = props;
  const { t } = useTranslation("project", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [projectUserList, setProjectUserList] = useState([]);
  const [user, setUser] = useState(null);
  const [userGroup, setUserGroup] = useState(null);

  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "90%",
      valueFormatter: (value, row) => {
        return row.user ? (
          <UserDetail value={row.user} type="USER" />
        ) : (
          <UserDetail value={row?.userGroup} type="USER" />
        );
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => deleteUser(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    refresh();
  }, [project]);

  const refresh = () => {
    if (project && project.id > 0) {
      ProjectService.findUsersByProject(
        project.id,
        findByOnSuccess,
        findByOnError
      );
    }
  };

  const findByOnSuccess = (data) => {
    setProjectUserList(data.list);
  };

  const findByOnError = (error) => {
    console.log(error);
  };

  const addUser = () => {
    const data = { project, user, userGroup };
    ProjectService.saveUser(data, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsEditFormOpen(false);
  };

  const saveOnError = (data) => {};

  const deleteUser = (data) => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ProjectService.deleteUser(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={"Kullanıcı Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={addUser}
          //loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <UserAndGroupAutoComplete
                value={{ user: user, userGroup: userGroup }}
                accountId={accountId}
                onChange={(data) => {
                  if (data != null && data.user) {
                    setUser(data.user);
                    setUserGroup(null);
                  } else if (data != null && data.userGroup) {
                    setUser(null);
                    setUserGroup(data.userGroup);
                  } else {
                    setUser(null);
                    setUserGroup(null);
                  }
                }}
                required={true}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {renderEditForm()}
      <Card style={{ minHeight: 100 }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" size="large">
              <AddIcon
                onClick={() => {
                  setIsEditFormOpen(true);
                  setUser(null);
                  setUserGroup(null);
                }}
              />
            </IconButton>
          }
          title={t("PROJECT_USERS")}
          data-fa-section="PROJECT_USERS"
        />

        <ThingsDataTable
          data={projectUserList}
          columns={columns}
          //ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          headerText={t("PROJECT_USERS")}
          showPaging={false}
          showHeader={false}
        />
      </Card>
    </Suspense>
  </>;
}
