import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SurveyQuestionTypeSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("campaign", { i18n });

  return (
    <FormControl className={classes.w100}>
      <InputLabel>{t("QUESTION_TYPE")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("QUESTION_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"MULTIPLE_CHOICE"}>{t("MULTIPLE_CHOICE")}</MenuItem>
        <MenuItem value={"CHECKBOX"}>{t("CHECKBOX")}</MenuItem>
        <MenuItem value={"STAR_RATING"}>{t("STAR_RATING")}</MenuItem>
        <MenuItem value={"DROPDOWN"}>{t("DROPDOWN")}</MenuItem>
        <MenuItem value={"TEXT"}>{t("TEXT")}</MenuItem>
        <MenuItem value={"COMMENT"}>{t("COMMENT")}</MenuItem>
        <MenuItem value={"INTEGER"}>{t("INTEGER")}</MenuItem>
        <MenuItem value={"DECIMAL"}>{t("DECIMAL")}</MenuItem>
        <MenuItem value={"SHOPPING_AMOUNT"}>{t("SHOPPING_AMOUNT")}</MenuItem>
        <MenuItem value={"DATE"}>{t("DATE")}</MenuItem>
        <MenuItem value={"TIME"}>{t("TIME")}</MenuItem>
        <MenuItem value={"DATE_TIME"}>{t("DATE_TIME")}</MenuItem>
        <MenuItem value={"SLIDER"}>{t("SLIDER")}</MenuItem>
        <MenuItem value={"FILE"}>{t("FILE")}</MenuItem>
        <MenuItem value={"SHOPPING_DOCUMENTS"}>
          {t("SHOPPING_DOCUMENTS")}
        </MenuItem>
        <MenuItem value={"IMAGE"}>{t("IMAGE")}</MenuItem>
      </Select>
    </FormControl>
  );
}
