export const LOCALSTORAGE = {
  APP: "app",
};

export const APP = {
  TF: "TF",
  T2C: "T2C",
  CNF: "CNF",
};

export const RECAPTCHA_CODE = "6Lc9aDgnAAAAAMA8pzZHmrtihhweSGWD9xLspqx5";

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA";

export const CONFIRMATION_TOKEN_COUNTDOWN = 180;
