import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function ThingClassDetailTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialThingClass", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <b>Nokta Tipi</b>’ne ait detay bilgilerin gösterildiği sayfadır.
            <p>
              Bu sayfada;
              <ul>
                <li>
                  <b>Etkileşim Noktası Tipi Bilgileri</b>’ni
                  güncelleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim Noktası Tipi</b>'ni silebilirsiniz.
                </li>
                <li>
                  <b>Türetilmiş Etkileşim Noktası Tipleri</b>’ni liste olarak
                  izleyebilir ve geçiş yapabilirsiniz.
                </li>
                <li>
                  <b>Ek Alan</b> tanımlayabilirsiniz.
                </li>
                <li>
                  <b>
                    Bildirim Tipi, Beğeni Tipi, Öneri Tipi, Kontrol Soruları,
                    Web Bağlantıları, Dosyalar, Notlar ve Etkileşim Sayfası
                  </b>{" "}
                  ekleyebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"thing_class_detail"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
