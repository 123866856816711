// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

export const styles = {
  MuiTablePagination: {
    styleOverrides: {
      toolbar: {
        "@media (max-width:700px)": {
          flexWrap: "wrap-reverse",
          justifyContent: "center",
        },
      },
    },
  },
  MuiTabPanel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none", // 'initial' yerine 'none' kullanılır
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      alignCenter: {
        textAlign: "left",
      },
      root: {
        padding: 4,
      },
      head: {
        fontWeight: 600,
        color: "#9A9A9A",
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        paddingTop: "1px",
        paddingBottom: "1px",
      },
      button: {
        "&:hover": {
          backgroundColor: "#5f5f5f",
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paperAnchorRight: {
        backgroundColor: "white",
      },
      paper: {
        backgroundColor: "#151B26",
        color: "#CBD4DB",
        overflow: "auto",
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: "#6F7782 !important",
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: {
        color: "#CBD4DB",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: 8,
        color: "#532363",
      },
      colorPrimary: {
        color: "#6F7782",
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      colorPrimary: {
        color: "#151B26",
      },
      body1: {
        color: "black",
      },
      colorSecondary: {
        color: "#151B26",
      },
      caption: {
        fontSize: "0.875rem",
        fontWeight: "bold",
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        minWidth: 50,
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
      },
      containedPrimary: {
        backgroundColor: "#151B26",
        "&:hover": {
          backgroundColor: "#dc3545",
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        "&:last-child th, &:last-child td": {
          borderBottom: 0,
        },
      },
    },
  },
  thingsMoru: {
    color: "#532363",
  },
};
