import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function IssueTaskHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Görevlendirmenin yapıldığı alandır.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorevlendir_butonu.png"
                }
                width="543"
                height="453"
              ></img>
            </div>
            <br />
            <b>Görev atamak</b> için tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.gorevlendirButonuClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorev_olustur_kuyruk.png"
                }
                width="649"
                height="360"
              ></img>
            </div>
            <br />
            <b>Otomatik Görev</b> atamak için tıklayın.
            <br />
            <br />
            <b>ÖNEMLİ NOT</b>: <br /> Otomatik Görev Atama yapabilmek için
            Otomasyon menüsünden <b>Kuyruk</b> tanımlanmış olmalıdır.
          </TutorialElement>
        ),
        tooltipClass: classes.gorevOlusturDialogClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorev_olustur_kullanici.png"
                }
                width="649"
                height="360"
              ></img>
            </div>
            <br />
            Görev atamak istediğiniz kişiyi seçin.
          </TutorialElement>
        ),
        tooltipClass: classes.gorevOlusturDialogClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorev_olustur_aciklama.png"
                }
                width="649"
                height="360"
              ></img>
            </div>
            <br />
            İsteğe bağlı Görev açıklaması girin.
          </TutorialElement>
        ),
        tooltipClass: classes.gorevOlusturDialogClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/gorev_olustur_tarih.png"
                }
                width="649"
                height="360"
              ></img>
            </div>
            <br />
            <b>Termin Tarihi</b> seçin ve <b>Kaydet</b>’e basın.
          </TutorialElement>
        ),
        tooltipClass: classes.gorevOlusturDialogClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

IssueTaskHelp.propTypes = {
  value: PropTypes.string,
};
