import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import ThingDetail from "components/detail/ThingDetail";
import CustomerHashtag from "components/hashtag/CustomerHashtag";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { smartTimeDiff } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { CUSTOMER } from "../../services/faApi";
import CustomerEdit from "./CustomerEdit";
import { useStyles } from "./style";

export default function Customers() {
  const tableRef = useRef();
  const { t } = useTranslation("customer", { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "membershipDate",
      label: t("MEMBERSHIP_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "15%",
    },
    {
      field: "surname",
      label: t("SURNAME"),
      align: "center",
      width: "15%",
    },
    {
      field: "email",
      label: t("E-MAIL"),
      align: "center",
      width: "15%",
    },
    {
      field: "phone",
      label: t("PHONE"),
      align: "center",
      width: "10%",
    },
    {
      field: "firstThing",
      label: t("İlk Etkileşim Noktası"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return <ThingDetail value={value} />;
      },
    },
    {
      field: "lastThing",
      label: t("Son Etkileşim Noktası"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return <ThingDetail value={value} />;
      },
    },

    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "5%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
  ];

  const newSearchForm = () => {
    let result = {
      thing: null,
      thingId: null,
      membershipDateStart: null,
      membershipDateEnd: null,
      email: null,
      phone: null,
      accountId: accountId,
      name: null,
      surname: null,
      hashtag: null,
      recordType: null,
      status: null,
      description: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `customer/detail/${row.id}`,
      breadCrumbKey: `/customer/detail/:id`,
    };
    history.push(tmpTo);
  };

  const addNewCustomerRender = () => {
    return <CustomerEdit ref={dialogRef} onSave={onCreate}></CustomerEdit>;
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onCreate = (data) => {
    refresh();
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addNewCustomerRender()}
        <ThingsDataTable
          searchUrl={CUSTOMER.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD_CUSTOMER"
            >
              {t("ADD_CUSTOMER")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("SURNAME")}
                value={searchForm.surname}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "surname",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("E-MAIL")}
                value={searchForm.email}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "email",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("PHONE")}
                value={searchForm.phone}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "phone",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                value={searchForm.descripton}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <CustomerHashtag
                label={t("HASHTAG")}
                accountId={accountId}
                value={searchForm.hashtag}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "hashtag",
                    data
                  );
                }}
                data-fa-search="HASHTAG"
              />

              <FaInput
                label={t("E-MAIL")}
                value={searchForm.email}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "email",
                    event.target.value
                  );
                }}
              />

              <FormControl style={{ width: "100%", minHeight: 65 }}>
                <InputLabel>
                  {searchForm.email ? searchForm.email : t("RECORD_TYPE")}
                </InputLabel>
                <Select
                  variant="outlined"
                  value={searchForm.recordType}
                  onChange={(event) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "recordType",
                      event.target.value
                    );
                  }}
                  label={
                    searchForm.recordType
                      ? searchForm.recordType
                      : t("RECORD_TYPE")
                  }
                >
                  <MenuItem value={null}>...</MenuItem>
                  <MenuItem value="MANUEL">{t("Manuel")}</MenuItem>
                  <MenuItem value="USER">{t("User")}</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ width: "100%", minHeight: 65 }}>
                <InputLabel>
                  {searchForm.status ? searchForm.status : t("STATUS")}
                </InputLabel>
                <Select
                  variant="outlined"
                  value={searchForm.status}
                  onChange={(event) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "status",
                      event.target.value
                    );
                  }}
                  label={searchForm.status ? searchForm.status : t("STATUS")}
                >
                  <MenuItem value={null}>...</MenuItem>
                  <MenuItem value="ACTIVE">{t("Active")}</MenuItem>
                  <MenuItem value="PASSIVE">{t("Passive")}</MenuItem>
                </Select>
              </FormControl>

              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    membershipDateStart: data.startDate,
                    membershipDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
