import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import ThingDetail from "components/detail/ThingDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { CUSTOMER } from "services/faApi";
import { isTNF } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerThings(props) {
  const { customer } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();

  const { t } = useTranslation("customer", { i18n });

  const columns = [
    {
      field: "registerDate",
      label: t("Kayıt Tarihi"),
      align: "center",
      width: "20%",
      type: "smartDateTime",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "80%",
      valueFormatter: (value, row) => {
        return <ThingDetail value={value} />;
      },
    },
  ];

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-customerInteractions">
        <CardHeader
          title={t("İlgilendiği Etkileşim Noktaları")}
          data-fa-section="İlgilendiği Etkileşim Noktaları"
        />
        {customer && customer.id > 0 && (
          <ThingsDataTable
            dataUrl={CUSTOMER.findThings + "?id=" + customer.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "createDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
