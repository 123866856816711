import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function AccountInteractionTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialAccountInteraction", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            <p>
              Müşterilerinizle etkileşime geçmenizi sağlayan{" "}
              <b>Etkileşim Sayfası</b> ile ilgili düzenlemelerin yapıldığı{" "}
              <b>Menü/Sayfadır</b>. <br />
              Bu menüde;
              <ul>
                <li>
                  <b>Etkileşim Sayfası</b> seçebilirsiniz<b>*</b>.
                </li>
                <li>
                  <b>Etkileşim Sayfası Seçenekleri</b>’ni
                  seçebilir/güncelleyebilirsiniz.
                </li>
              </ul>
              <b>*</b>: Başlangıç ve üstü paketlerde.
            </p>
          </TutorialElement>
        ),
        comp: "AccountInteractionPage",
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"account_interaction"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
