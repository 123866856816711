import { SvgIcon } from "@mui/material";
import { default as React } from "react";

export default function ThingsSurveyIcon(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <g>
        <polygon points="13.494,14.516 10.896,17.114 15.87,22.087 24.518,13.437 21.92,10.839 15.87,16.891 	" />
        <rect x="28.731" y="10.301" width="14.584" height="3.675" />
        <rect x="28.731" y="15.813" width="10.909" height="3.675" />
        <polygon points="25.058,4.789 6.685,4.789 6.685,23.163 10.359,23.163 10.359,8.464 25.058,8.464 	" />
        <polygon points="13.494,36.564 10.896,39.162 15.87,44.135 24.518,35.485 21.92,32.888 15.87,38.939 	" />
        <rect x="28.731" y="32.349" width="14.584" height="3.675" />
        <rect x="28.731" y="37.862" width="10.909" height="3.675" />
        <polygon points="6.685,45.211 10.359,45.211 10.359,30.512 25.058,30.512 25.058,26.837 6.685,26.837 	" />
      </g>
    </SvgIcon>
  );
}
