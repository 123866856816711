import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { FaDatePicker } from "react-base-fa/dist/fa";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionDate(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  let minDate = null;
  let maxDate = null;
  if (question.future === true && question.past === true) {
  } else if (question.future === true) {
    minDate = new Date();
  } else if (question.past === true) {
    maxDate = new Date();
  }

  return (
    <Grid item xs={12}>
      <FormControl
        variant="standard"
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}>
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FaDatePicker
            required={question.notEmpty}
            faType={
              question.questionType === "DATE_TIME"
                ? "dateTime"
                : question.questionType === "DATE"
                ? "date"
                : "time"
            }
            value={answer?.dataDate ?? null}
            faOnChange={(val) => {
              onChange(question, val, "dataDate");
            }}
            minDate={minDate == null ? undefined : minDate}
            maxDate={maxDate == null ? undefined : maxDate}
            label={undefined}
            disabled={readOnly}
          />
        </Grid>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionDate.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
