import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { Close } from "@mui/icons-material";
import { Alert } from '@mui/material';
import { unwrapResult } from "@reduxjs/toolkit";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import { FaButton, FaInput } from "react-base-fa/dist/fa";
import { clearArr } from "react-base-fa/dist/fa/faBreadCrumb/breadCrumbSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  loginThunk,
  setRole,
  setUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation } from "react-router-dom";
import { BENEFICIARY_PORTAL } from "services/faApi";
import BeneficiaryLoginLayout from "./BeneficiaryLoginLayout";
import EmailVerificationCode from "./EmailVerificationCode";
import SmsVerificationCode from "./SmsVerificationCode";

export default function BeneficiaryLoginSystem(props) {
  const { appLogo, partial, onSuccess } = props;

  const classes = useStyles();
  const { t } = useTranslation(["beneficiaryPortal", "errorMessages"], {
    i18n,
  });
  const history = useHistory();
  const formRef = createRef();

  const emailVerificationRef = useRef();

  const [loading, setLoading] = useState(null);
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);

  const [visitorId, setVisitorId] = useState(null);

  const VERIFICATION_EMAIL = "EMAIL";
  const VERIFICATION_SMS = "SMS";

  const [verificationType, setVerificationType] = useState(VERIFICATION_EMAIL);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);

  const [step, setStep] = useState(1);

  const onFocusPhone = () => {
    if (!phone || phone.length == 0) setPhone("+90 (5");
  };

  const onBlurPhone = () => {
    if (phone === "+90 (5**) *** ** **") setPhone("");
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const dispatch = useDispatch();

  const isSendActivationCodeButtonActive = () => {
    if (verificationType === VERIFICATION_EMAIL) {
      return email !== "";
    } else if (verificationType === VERIFICATION_SMS) {
      return phone !== "";
    }
    return false;
  };

  const renderStep1 = () => {
    return <>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <FormControl variant="standard" component="fieldset">
          <FormLabel component="legend">
            {t("Faydalanıcı portali için lütfen iletişim bilginizi girin")}
          </FormLabel>
          <RadioGroup
            row
            aria-label="position"
            value={verificationType}
            onChange={(event) => {
              setVerificationType(event.target.value);
            }}
          >
            <FormControlLabel
              value={VERIFICATION_EMAIL}
              control={<Radio color="primary" />}
              label="E-Posta"
            />
            <FormControlLabel
              value={VERIFICATION_SMS}
              control={<Radio color="primary" />}
              label="Cep Telefonu"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {verificationType === VERIFICATION_EMAIL && (
        <Grid item xs={12}>
          <FaInput
            faType="email"
            label={t("EMAIL")}
            autoComplete="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email || ""}
            helperText="Tek kullanımlık doğrulama kodu e-posta adresinize gönderilecektir."
          />
        </Grid>
      )}
      {verificationType === VERIFICATION_SMS && (
        <Grid item xs={12}>
          <FaInput
            label={t("PHONE_NUMBER")}
            onChange={(event) =>
              setPhone(event.target.allValues.formattedValue)
            }
            faMask="+## (###) ### ## ##"
            faMaskChar="*"
            value={phone}
            onFocus={onFocusPhone}
            onBlur={onBlurPhone}
            helperText="Tek kullanımlık doğrulama kodu telefon numaranıza gönderilecektir."
          />
        </Grid>
      )}
      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <FaButton
          faType="button"
          color="primary"
          size="large"
          onClick={() => {
            setStep(2);
            //emailVerificationRef.current.start();
          }}
          disabled={!isSendActivationCodeButtonActive()}
          style={{ height: "60px" }}
          label={t("Doğrulama Kodu Gönder")}
        ></FaButton>
      </Grid>
    </>;
  };

  const renderStep2 = () => {
    return (
      <Grid item xs={12}>
        {verificationType === VERIFICATION_EMAIL && (
          <EmailVerificationCode
            ref={emailVerificationRef}
            email={email}
            onSuccess={verificationCodeOnSuccess}
          />
        )}
        {verificationType === VERIFICATION_SMS && (
          <SmsVerificationCode
            ref={emailVerificationRef}
            phone={phone}
            onSuccess={verificationCodeOnSuccess}
          />
        )}
      </Grid>
    );
  };

  const renderStepError1 = () => {
    return (
      <Grid item xs={12}>
        <Collapse in={showError}>
          <Alert
            variant="outlined"
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowError(false);
                  setStep(1);
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>
      </Grid>
    );
  };

  const renderStepError2 = () => {
    return (
      <Grid item xs={12}>
        Error2
      </Grid>
    );
  };

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const verificationCodeOnSuccess = async () => {
    const data = { email: email, phone: phone };

    //BeneficiaryPortalService.login(data, loginOnSuccess, loginOnError);

    try {
      const res = unwrapResult(
        await dispatch(
          loginThunk(
            requests.getSimplePostRequest(BENEFICIARY_PORTAL.login, {
              email: email,
              phone: phone,
            })
          )
        )
      );
      if (res && res.ERROR) {
        switch (res.ERROR) {
          case "USER_NOT_ENABLED":
            setErrorMessage(t("errorMessages:USER_NOT_ENABLED"));
            setShowError(true);
            break;
          case "USER_NOT_FOUND":
            setErrorMessage(t("errorMessages:USER_NOT_FOUND"));
            setShowError(true);
            break;
          case "USER_ACCOUNT_EXPIRED":
            setErrorMessage(t("errorMessages:USER_ACCOUNT_EXPIRED"));
            setShowError(true);
            break;
          case "USER_ACCOUNT_LOCKED":
            setErrorMessage(t("errorMessages:USER_ACCOUNT_LOCKED"));
            setShowError(true);
            break;
          case "USER_CREDENTIALS_EXPIRED":
            setErrorMessage(t("errorMessages:USER_CREDENTIALS_EXPIRED"));
            setShowError(true);
            break;
          case "WRONG_PASSWORD":
            setErrorMessage(t("errorMessages:WRONG_PASSWORD"));
            setShowError(true);
            break;
          default:
            break;
        }
        setStep(-1);
      } else {
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());

        dispatch(setRole(["PUBLIC_BENEFICIARY"]));

        if (res.ACCOUNT_LIST && res.ACCOUNT_LIST.length === 1) {
          localStorage.setItem("beneficiaryAccountId", res.ACCOUNT_LIST[0].id);
          localStorage.setItem(
            "beneficiaryAccountName",
            res.ACCOUNT_LIST[0].fullname
          );

          if (onSuccess) onSuccess();
          else history.push("/bportal/homepage");
        } else if (res.ACCOUNT_LIST) {
          const tmpTo = {
            pathname: "/bportal/accountSelection",
            state: {
              accountList: [...res.ACCOUNT_LIST],
            },
          };

          if (onSuccess) onSuccess();
          else history.push(tmpTo);
        }
      }
    } catch (err) {
      console.log("error", `Fetch failed: ${err.message}`);
      console.log(err);
      switch (err && err.response && err.response.status) {
        case 400:
          showErrorMessage(err.response);
          break;
        default:
          break;
      }
    }
  };

  const showErrorMessage = (err) => {
    let message;
    if (err.data.ERROR.includes("validationError")) {
      const errorObj = JSON.parse(err.data.ERROR);
      message = errorObj.validationError.map((error) => {
        return (
          <p className={classes.errorMessage}>
            {" "}
            <strong>{error.fieldName}</strong> {error.errorMessage}
          </p>
        );
      });
    } else {
      message = t("errorMessages:" + err.data.ERROR);
    }
    setErrorMessage(message);
    setShowError(true);
  };

  const loginOnSuccess = () => {};

  const loginOnError = () => {};

  const render = () => {
    return (
      !loading && (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item lg={10} xs={12} style={{ textAlign: "center" }}></Grid>
          <Grid
            item
            lg={10}
            xs={12}
            style={{ textAlign: "left", paddingLeft: 20 }}
          >
            <Grid item container spacing={1}>
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}

              {step === -1 && renderStepError1()}
              {step === -2 && renderStepError2()}
            </Grid>
          </Grid>
        </Grid>
      )
    );
  };

  return (
    <>
      {partial && render()}
      {!!!partial && (
        <BeneficiaryLoginLayout appLogo={appLogo}>
          {render()}
        </BeneficiaryLoginLayout>
      )}
    </>
  );
}

BeneficiaryLoginSystem.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
