import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  buttonPanel: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  cancelButton: {
    marginBottom: "20px",
    marginLeft: "5px",
  },
}));
