import MyThing from "features/things/MyThing";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function ShareWebLinkFreeTutorial(props) {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const myThingRef = useRef(null);
  let history = useHistory();

  const { t } = useTranslation("tutorialShareWebLink", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuMyThing",
        intro: (
          <TutorialElement
            icon="click"
            text={
              "İşletmeniz, Ürün ve Hizmetlerinizle ilgili web bağlatı(lar)ı paylaşmak için  tıklayın."
            }
          />
        ),
        comp: "MyThing",
      },
      {
        element: "[data-fa-button='WEB_LINKS_ADD']",
        intro: (
          <TutorialElement header="Menu" icon="click">
            {t("Web bağlantısı eklemek için tıklayın.")}
          </TutorialElement>
        ),
        comp: "MyThing",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='WEB_LINK_DIALOG']",
        intro: (
          <TutorialElement icon="info">
            {t("Açıklama bilgilerini girin. URL’yi girin.")} <br />
            {t("Etkileşim için ")} <b>{t("Herkes'i")}</b> {t(" seçin ve ")}{" "}
            <b>{t("Kaydet'e")}</b> {t(" basın.")}
          </TutorialElement>
        ),
        comp: "MyThing",
        onBeforeChange: (index) => {
          $("[data-fa-button='WEB_LINKS_ADD']").click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='WEB_LINKS_CARD']",
        intro: (
          <TutorialElement icon="info">
            {t("Web bağlantılarınızı bu alanda görebilirsiniz. ")} <br />
            {t(
              "Artık Web bağlantı(ları)nızı müşterilerinizle paylaşmaya hazırsınız."
            )}
          </TutorialElement>
        ),
        comp: "MyThing",
        onBeforeChange: (index) => {
          $("[aria-label='close']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "MyThing" && <MyThing ref={myThingRef} />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => window.history.back()}
      />
    </div>
  );
}
