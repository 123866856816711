import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Add, Cancel, Remove } from "@mui/icons-material";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import ThingHashtag from "components/hashtag/ThingHashtag";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaStepper } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccountService } from "services/AccountService";
import { StockTakingService } from "services/StockTakingService";
import { StockTakingUserService } from "services/StockTakingUserService";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

const StockTakingCreate = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, value, open } = props;

  const userInfo = useSelector(getUserInfo);
  const tableRef = useRef();
  const { t } = useTranslation("stockTaking", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [accountSubscription, setAccountSubscription] = useState(null);
  const [isUserEditFormOpen, setIsUserEditFormOpen] = useState(false);

  const [sectionHashtag, setSectionHashtag] = useState(false);
  const [sectionThingClass, setSectionThingClass] = useState(false);
  const [sectionThing, setSectionThing] = useState(false);
  const [sectionOrganization, setSectionOrganization] = useState(false);
  const [sectionLocation, setSectionLocation] = useState(false);

  const [accPreferences, setAccPreferences] = useState(null);

  const [selectedServiceOperationClass, setSelectedServiceOperationClass] =
    useState(null);
  const [isServiceOperationClassFormOpen, setIsServiceOperationClassFormOpen] =
    useState(false);

  const [isStockFormOpen, setIsStockFormOpen] = useState(false);
  const [solutionStocks, setSolutionStocks] = useState([]);

  const [solutionControlItems, setSolutionControlItems] = useState([]);

  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedStockAmount, setSelectedStockAmount] = useState(1);

  const [user, setUser] = useState(null);
  const [userGroup, setUserGroup] = useState(null);

  useEffect(() => {
    AccountService.getSubscription(
      accountId,
      (data) => {
        setAccountSubscription(data);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {}, [user]);

  useEffect(() => {
    if (open) {
      setStockTaking(value == null ? newStockTaking() : { ...value });
    }
    setIsShowNextButton(false);
  }, [open]);

  const accountId = localStorage.accountId;

  const populateControlItemsOnSuccess = (data) => {
    setSolutionControlItems(data);
  };

  const populateControlItemsOnError = () => {};

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        setAccPreferences(data);
      });
  }, [accountId]);

  const newStockTaking = () => {
    return {
      id: "",
      description: "",
      startDate: "",
      endDate: null,
      status: "DRAFT",
      endDescription: "",
      targets: [],
      users: [],
    };
  };
  const [stockTaking, setStockTaking] = useState(newStockTaking());

  const handleChange = (prop) => (event) => {
    setStockTaking({
      ...stockTaking,
      [prop]: event.target.value,
    });

    if (prop === "description") {
      if (event.target.value && event.target.value.length > 0)
        setIsShowNextButton(true);
      else setIsShowNextButton(false);
    }
  };

  const handleChangeV = (prop, value) => {
    setStockTaking({ ...stockTaking, [prop]: value });
  };

  const saveSuccess = (data) => {
    showAlert(!stockTaking.id ? t("SAVED") : t("EDITED"), "success");
    setStockTaking(newStockTaking());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const [isShowNextButton, setIsShowNextButton] = useState(false);
  const steps = ["Giriş", "Kapsam", "Sayım Ekibi"];

  const handleChipDelete = (index) => () => {
    var temp = [...stockTaking.targets];
    temp.splice(index, 1);
    setStockTaking({ ...stockTaking, targets: temp });
  };

  const addTargetHashtag = (value) => {
    let dto = {
      thingClass: null,
      thing: null,
      hashtag: value,
      label: value,
      key: "h_" + value,
    };

    addTarget(dto);
  };

  const addTargetThingClass = (value) => {
    let dto = {
      thingClass: { id: value.id },
      thing: null,
      hashtag: null,
      label: value.className,
      key: "c_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetOrganization = (value) => {
    let dto = {
      organization: { id: value.id },
      label: value.name,
      key: "o_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocation = (value) => {
    let dto = {
      location: { id: value.id },
      label: value.name,
      key: "l_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocationDetail = (value) => {
    let dto = {
      locationDetail: { id: value.id },
      label: value.name,
      key: "ld_" + value.id,
    };

    addTarget(dto);
  };

  const addTarget = (newValue) => {
    setStockTaking({
      ...stockTaking,
      targets: [...stockTaking.targets, newValue],
    });
  };

  const deleteUser = (index) => {
    var temp = [...stockTaking.users];
    temp.splice(index, 1);
    setStockTaking({
      ...stockTaking,
      users: [...temp],
    });
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={stockTaking.description || ""}
              />
            </Grid>
            <Grid item xs={12}>
              {accountSubscription &&
                accountSubscription.blindCount === "YES" && (
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={stockTaking.blindCount}
                          onChange={(event) => {
                            handleChangeV("blindCount", event.target.checked);
                          }}
                          color="primary"
                        />
                      }
                      label={t("BLIND_COUNT")}
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid item xs={12}>
            <Typography variant="caption">{t("TARGET_GROUP")}</Typography>

            <Paper component="ul" className={classes.chipRoot}>
              {stockTaking?.targets?.map((data, index) => {
                let icon;

                if (data.thingClass) {
                  icon = <FontAwesomeIcon icon={faSitemap} />;
                } else if (data.thing) {
                  icon = <FontAwesomeIcon icon={faCubes} />;
                } else if (data.hashtag) {
                  icon = <FontAwesomeIcon icon={faHashtag} />;
                } else if (data.organization) {
                  icon = <FontAwesomeIcon icon={faFolder} />;
                } else if (data.location) {
                  icon = <FontAwesomeIcon icon={faGlobe} />;
                } else if (data.locationDetail) {
                  icon = <FontAwesomeIcon icon={faMap} />;
                }

                return (
                  <li key={data.key}>
                    <Chip
                      icon={icon}
                      label={data.label}
                      onDelete={handleChipDelete(index)}
                      className={classes.chip}
                    />
                  </li>
                );
              })}
            </Paper>
            <div className={classes.buttonPanel}>
              <FaButton
                faClick={() => {
                  setSectionHashtag(true);
                }}
                fullWidth={false}
                startIcon={<Add />}
              >
                {t("ADD_HASHTAG")}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionThingClass(true);
                }}
                fullWidth={false}
                startIcon={<Add />}
              >
                {isTNF(t("ADD_THINGCLASS"), t("ADD_THINGCLASS_T2C"))}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionThing(true);
                }}
                fullWidth={false}
                startIcon={<Add />}
              >
                {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionOrganization(true);
                }}
                fullWidth={false}
                startIcon={<Add />}
              >
                {t("ADD_ORGANIZATION")}
              </FaButton>
              <FaButton
                faClick={() => {
                  setSectionLocation(true);
                }}
                fullWidth={false}
                startIcon={<Add />}
              >
                {t("ADD_LOCATION")}
              </FaButton>
            </div>
            {sectionHashtag ? (
              <div className={classes.hboxBlock}>
                <ThingHashtag
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetHashtag(data);
                    setSectionHashtag(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionHashtag(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionThingClass ? (
              <div className={classes.hboxBlock}>
                <ThingClassAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetThingClass(data);
                    setSectionThingClass(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionThingClass(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionThing ? (
              <div className={classes.hboxBlock}>
                <ThingAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetThing(data);
                    setSectionThing(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionThing(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionOrganization ? (
              <div className={classes.hboxBlock}>
                <OrganizationAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetOrganization(data);
                    setSectionOrganization(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionOrganization(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}

            {sectionLocation ? (
              <div className={classes.hboxBlock}>
                <LocationAutoComplete
                  accountId={accountId}
                  onChange={(data) => {
                    addTargetLocation(data);
                    setSectionLocation(false);
                  }}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Cancel />}
                  className={classes.cancelButton}
                  onClick={() => {
                    setSectionLocation(false);
                  }}
                >
                  {t("CANCEL")}
                </Button>
              </div>
            ) : null}
          </Grid>
        );
      case 2:
        return <>
          {renderUserEditForm()}
          <Grid item lg={12} xs={12} style={{ minHeight: 230 }}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sayım Ekibi</TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      <IconButton
                        onClick={() => {
                          setIsUserEditFormOpen(true);
                          setUser(null);
                          setUserGroup(null);
                        }}
                        size="large">
                        <Add />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockTaking?.users.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {data.user
                          ? data.user.fullname
                          : data.userGroup?.name}
                      </TableCell>
                      <TableCell style={{ textAlign: "right" }}>
                        <IconButton
                          onClick={() => {
                            deleteUser(index);
                          }}
                          size="large">
                          <Remove />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  {stockTaking.users.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={2}>
                        Kayıt bulunmamaktadır.
                      </TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>;
      default:
        return "";
    }
  };

  const addUser = () => {
    setIsUserEditFormOpen(false);
    if (user == null && userGroup == null) return;
    if (
      (user &&
        stockTaking.users.findIndex((i) => i.user && i.user.id === user.id) ===
          -1) ||
      (userGroup &&
        stockTaking.users.findIndex(
          (i) => i.userGroup && i.userGroup.id === userGroup.id
        ) === -1)
    ) {
      setStockTaking({
        ...stockTaking,
        users: [...stockTaking.users, { user, userGroup }],
      });
    }
  };

  const renderUserEditForm = () => {
    return (
      <>
        <Dialog
          open={isUserEditFormOpen}
          onClose={(data) => {
            setIsUserEditFormOpen(data);
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">Kullanıcı Tanımlama</DialogTitle>
          <DialogContent>
            <Grid item container justifyContent="center" spacing={1}>
              <Grid item xs={12}>
                <UserAndGroupAutoComplete
                  value={{ user: user, userGroup: userGroup }}
                  accountId={accountId}
                  onChange={(data) => {
                    if (data != null && data.user) {
                      setUser(data.user);
                      setUserGroup(null);
                    } else if (data != null && data.userGroup) {
                      setUser(null);
                      setUserGroup(data.userGroup);
                    } else {
                      setUser(null);
                      setUserGroup(null);
                    }
                  }}
                  label={t("USER")}
                  required={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={addUser} color="primary">
              Ekle
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const nextValidation = (stepIndex, callback) => {
    if (stepIndex === 2) {
      saveStockTaking(callback);
    }
    return callback(true);
  };

  const saveStockTaking = (callback) => {
    setLoading(true);
    StockTakingService.save(
      {
        ...stockTaking,
        account: { id: accountId },
      },
      (data) => {
        stockTaking.users.forEach((item) => {
          StockTakingUserService.save(
            { ...item, stockTaking: data },
            () => {},
            () => {}
          );
        });

        showAlert(!stockTaking.id ? t("SAVED") : t("EDITED"), "success");
        setStockTaking(newStockTaking());
        setLoading(false);
        callback(true);
        onChange(data);
      },
      saveError
    );
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Sayım Tanımlama"}
          faOpen={open}
          faOnSubmit={saveStockTaking}
          loading={loading}
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="lg"
        >
          <FaStepper
            stepContent={getStepContent}
            steps={steps}
            faResetButton={false}
            faNextButton={isShowNextButton}
            faNextValidation={nextValidation}
            faAlternativeLabel={false}
          ></FaStepper>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default StockTakingCreate;
