import { Add } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import TaskTypeButtonGroup from "components/buttonGroup/TaskTypeButtonGroup";
import ThingTableDecorator from "components/decorator/ThingTableDecorator";
import ThingsDataTable from "components/thingsDataTable";
import TaskEdit from "features/issues/TaskEdit";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { calculateDate, isTNF } from "services/utils";
import { buttonStyles } from "styles/ThingsButtonStyle";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { AccPreferencesService } from "../../services/accPreferences/accPreferencesService";
import { TASK } from "../../services/faApi";

export default function Tasks() {
  const tableRef = useRef();
  const { t } = useTranslation("todo", { i18n });
  const dispatch = useDispatch();
  const labelClasses = labelStyles();
  const buttonClasses = buttonStyles();
  let history = useHistory();
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [isNewTaskDialog, setIsNewTaskDialog] = useState(false);
  const [accPreferences, setAccPreferences] = useState(null);

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        setAccPreferences(data);
      });
  }, [accountId]);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    //dispatch(snackbar(snackBarObj));
  };

  const columns = [
    {
      field: "taskDate",
      label: t("TASK_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "30%",
      valueFormatter: (val, row) => {
        return val && val.id && <ThingTableDecorator value={val} />;
      },
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "20%",
      valueFormatter: (val, row) => {
        return row.userFullname ?? row.userGroupName;
      },
    },
    {
      field: "taskType",
      label: t("TASK_TYPE"),
      align: "center",
      width: "15%",
      valueFormatter: (status) => {
        return t("TASK_TYPE_" + status);
      },
    },
    {
      field: "description",
      label: t("Açıklama"),
      align: "center",
      width: "25%",
    },
  ];

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
      user: null,
      userId: null,
      thing: null,
      thingId: null,
      status: null,
      taskDateValue: null,
      taskDateStart: null,
      taskDateEnd: null,
      taskType: null,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    if (row?.taskType === "TASK") {
      const tmpTo = {
        pathname: `/todo/detail/${row?.id}`,
        breadCrumbKey: `/todo/detail/:id`,
      };
      history.push(tmpTo);
    }
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const taskOnChange = () => {
    refresh();
    setIsNewTaskDialog(false);
  };

  const addNewTaskRender = () => {
    return (
      <TaskEdit
        onChange={taskOnChange}
        open={isNewTaskDialog}
        onClose={() => {
          setIsNewTaskDialog(false);
        }}
        accPreferences={accPreferences}
      />
    );
  };

  const openTaskAssign = (data) => {
    setIsNewTaskDialog(true);
  };

  return (
    <>
      {addNewTaskRender()}
      <Suspense fallback={<LoadingBar />}>
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={6} xs={8}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TASK_TYPE")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TaskTypeButtonGroup
                value={searchForm.taskType}
                onChange={(data) => {
                  const temp = { ...searchForm, taskType: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
                // style={{ height: 60 }}
              />
            </Grid>
          </Grid>
          <Grid item lg={4} xs={8}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TASK_DATE")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  value={searchForm.taskDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      taskDateValue: data,
                      taskDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  // style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={TASK.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          queryParams={{
            page: 0,
            size: 10,
            sort: "taskDate",
            isAsc: false,
          }}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                openTaskAssign();
              }}
              startIcon={<Add />}
              data-fa-button="CREATE_TASK"
            >
              {t("CREATE_TASK")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <UserAutoComplete
                accountId={accountId}
                value={searchForm.user}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "user",
                    data,
                    "userId",
                    data?.id
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
