import { LinkPreview } from "@dhaiwat10/react-link-preview";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { ReportService } from "../../services/report/reportService";
//import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { RECAPTCHA_CODE } from "services/utils/Constants";
import useStyles from "./style";

export default function PublicInfo(props) {
  const { appLogo } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [publicThing, setPublicThing] = useState(null);
  const [publicInfo, setPublicInfo] = useState(null);
  let recaptchaRef = useRef();
  const lang = getCurrentLang();

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      setPublicThing(location.state.publicThing);
      ReportService.getPublicInfo(
        location.state.publicThing.id,
        getPublicInfoOnSuccess,
        getPublicInfoOnError
      );
      setLoading(false);
    }
  }, [location.state]);

  const getPublicInfoOnSuccess = (data) => {
    setPublicInfo(data);
    setLoading(false);
  };

  const getPublicInfoOnError = () => {
    setLoading(false);
  };

  const theme = useSelector(selectThemePrefer);

  const downloadDocument = (item) => {
    ReportService.downloadDocument(
      publicThing.id,
      item.object,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    //const url = window.URL.createObjectURL(new Blob([data.content]));
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const createFallbackUrl = (item) => {
    return (
      <div className={classes.fallbackUrlBox}>
        <a
          href={item.url}
          target="blank_"
          style={{ position: "relative", top: "50%" }}
        >
          {item.url}
        </a>
      </div>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      //className={classes.image}
      style={{ height: "100vh", width: "100vw" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        {loading ? (
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                height="78"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LinearProgress />
            </Grid>
          </Grid>
        ) : null}

        {publicThing && publicThing.status === "ACTIVE" && (
          <Grid
            container
            spacing={3}
            justifyContent="center"
            style={{ marginBottom: 5 }}
          >
            <Grid
              item
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <img
                    src={appLogo}
                    height="78"
                    alt="logo"
                    className={classes.appLogo}
                  />
                  {/* <Prompt when={!!reported} message={t("LEAVE_PAGE_WARNNNG")} /> */}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    hl={lang}
                    theme={theme}
                    badge="bottomright"
                    sitekey={RECAPTCHA_CODE}
                  />
                </Grid>
                {/* <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography variant="h5">{t("Public Info")}</Typography>
                </Grid> */}

                {publicInfo?.documentList?.length > 0 && (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h5">{t("Dosyalar")}</Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", justifyContent: "center" }}
                >
                  {publicInfo?.documentList?.map((item, index) => (
                    <div key={index}>
                      <Button
                        onClick={() => {
                          downloadDocument(item);
                        }}
                        startIcon={
                          <Avatar
                            style={{
                              width: 48,
                              height: 48,
                            }}
                            variant="square"
                          >
                            <FontAwesomeIcon icon={faFile} />
                          </Avatar>
                        }
                        title="İndir"
                      >
                        <div>
                          <div>{item.name}</div>
                          <div>
                            <small>{item.fileName}</small>
                          </div>
                        </div>
                      </Button>
                    </div>
                  ))}
                </Grid>

                {publicInfo?.thingLinkList?.length > 0 && (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="h5">{t("Bağlantılar")}</Typography>
                  </Grid>
                )}
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    {publicInfo?.thingLinkList?.map((item, index) => (
                      <div key={index} style={{ paddingRight: "5px" }}>
                        <LinkPreview
                          url={item.url}
                          width="300px"
                          height="200px"
                          fallback={createFallbackUrl(item)}
                        />
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

PublicInfo.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
