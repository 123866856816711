import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NoImage from "components/ui/NoImage";
import React, { useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";
import CampaignPhotosCarousel from "./CampaignPhotoCarousel";
import { useStyles } from "./style";

export default function CampaignPhoto(props) {
  const { campaign, onChange } = props;
  const { t } = useTranslation("campaign", { i18n });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const faDialog = useDialog();
  const theme = useTheme();

  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const fileInputRef = useRef();
  const [images, setImages] = useState("");

  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isCarouselOpen, setCarouselOpen] = useState(false);
  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [campaignHdImages, setCampaignHdImages] = useState([]);
  const closeCarousel = () => {
    setCarouselOpen(false);
  };
  const openCarousel = (index) => {
    setSelectedHdImageIndex(index);
    setCarouselOpen(true);
  };

  const [isAccountDetailsEditOpen, setIsAccountDetailsEditOpen] =
    useState(false);

  const editButtonClicked = () => {
    setIsAccountDetailsEditOpen(true);
  };

  const refresh = () => {
    CampaignService.getCampaignImage(
      campaign.id,
      (data) => {
        if (data) {
          setImages(data);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    refresh();
  }, [campaign]);

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const uploadImages = async (newImages) => {
    CampaignService.uploadImage(
      campaign.id,
      newImages[0],
      (data) => {
        onChange();
      },
      () => {
        showAlert(t("Disk full"), "error");
        setLoading(false);
      }
    );
  };

  const deleteImage = (objectId) => {
    faDialog({
      description: t("Görseli silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        setLoading(true);
        CampaignService.deleteImage(
          campaign.account.id,
          campaign.id,
          objectId,
          (data) => {
            onChange();
          },
          () => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const setDefaultImage = (objectId) => {
    CampaignService.setDefaultImage(
      campaign.id,
      objectId,
      () => {
        refresh();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return <>
    {images.length > 0 && (
      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogContent>
          <CampaignPhotosCarousel
            campaignId={campaign.id}
            images={images}
            setImages={setImages}
            selectedItem={selectedHdImageIndex}
            setDefaultImage={setDefaultImage}
          />
        </DialogContent>
      </Dialog>
    )}
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <Card style={{ height: 260, overflowY: "auto" }}>
          <CardHeader
            action={
              <IconButton data-fa-button="PHOTOS_ADD" aria-label="settings" size="large">
                <AddIcon onClick={() => fileInputRef.current.click()} />
              </IconButton>
            }
            title={t("PHOTOS")}
            data-fa-section="PHOTOS"
          />
          <CardContent>
            <Grid item container spacing={1}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <input
                  onChange={handleChange}
                  multiple
                  ref={fileInputRef}
                  type="file"
                  hidden
                />
              </div>
              {(!images || images.length === 0) && <NoImage />}
              <ImageList
                rowHeight={140}
                className={classes.imageList}
                cols={lg ? 4 : 2}
              >
                {images &&
                  images.length > 0 &&
                  images.map((item, index) => (
                    <ImageListItem
                      key={index}
                      cols={images.length === 1 ? 2 : 1}
                    >
                      <img
                        className={classes.thingImage}
                        src={`data:image/png;base64, ${item.content}`}
                        alt={item.object}
                        onClick={() => {
                          openCarousel(index);
                        }}
                      />
                      <IconButton
                        className={classes.imageDeleteButtom}
                        onClick={() => {
                          deleteImage(item.object);
                        }}
                        size="large">
                        <DeleteIcon color="secondary" />
                      </IconButton>
                      {/* <ImageListItemBar
                title={item.object}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${item.object}`}
                    className={classes.icon}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              /> */}
                    </ImageListItem>
                  ))}
              </ImageList>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>;
}
