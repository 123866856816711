import { SvgIcon } from "@mui/material";
import { default as React } from "react";

export default function ThingsHelpIcon(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <g>
        <path
          d="M25.474,3.59c-11.806,0-21.41,9.605-21.41,21.41s9.604,21.41,21.41,21.41s21.41-9.605,21.41-21.41
		S37.28,3.59,25.474,3.59z M25.474,42.841c-9.837,0-17.841-8.003-17.841-17.841c0-9.838,8.004-17.841,17.841-17.841
		S43.315,15.162,43.315,25C43.315,34.839,35.311,42.841,25.474,42.841z"
        />
      </g>
      <g>
        <rect x="21.558" y="32.831" width="3.915" height="3.915" />
        <path
          d="M27.432,13.254h-3.915c-3.239,0-5.873,2.635-5.873,5.873h3.915c0-1.078,0.877-1.958,1.958-1.958h3.915
		c1.08,0,1.958,0.879,1.958,1.958v1.958c0,1.08-0.877,1.958-1.958,1.958c-3.239,0-5.873,2.635-5.873,5.873v1.958h3.915v-1.958
		c0-1.078,0.877-1.958,1.958-1.958c3.239,0,5.873-2.635,5.873-5.873v-1.958C33.305,15.888,30.67,13.254,27.432,13.254z"
        />
      </g>
    </SvgIcon>
  );
}
