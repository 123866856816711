import { Grid } from "@mui/material";
import Adsense from "components/adsense/Adsense";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import CustomerIneractionOperationSelect from "components/select/CustomerIneractionOperationSelect";
import CustomerInteractionDailyHeatmap from "features/customerInteraction/CustomerInteractionDailyHeatmap";
import CustomerInteractionDayTimeHeatmap from "features/customerInteraction/CustomerInteractionDayTimeHeatmap";
import CustomerInteractionMapCard from "features/customerInteraction/CustomerInteractionMapCard";
import InteractionCountByDate from "features/customerInteraction/InteractionCountByDate";
import InteractionCountsByOperationAndDetails from "features/customerInteraction/InteractionCountsByOperationAndDetails";
import InteractionCountsByOperationChart from "features/customerInteraction/InteractionCountsByOperationChart";
import InteractionCountsByPrintQr from "features/customerInteraction/InteractionCountsByPrintQr";
import TopInteractionsList from "features/customerInteraction/TopInteractionsList";
import TotalInteractionsCount from "features/customerInteraction/TotalInteractionsCount";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { parseStringDate } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function CustomerInteractionDashboard() {
  const { t } = useTranslation(["customerInteraction", "components"], { i18n });
  const dispatch = useDispatch();

  let history = useHistory();
  const dialogRef = useRef();
  const chartRef = useRef();
  const pieChartRef = useRef();
  const pieChartPrintQrRef = useRef();
  const topInteractionsRef = useRef();
  const interactionCountRef = useRef();
  const totalInteractionsCountRef = useRef();
  const customerInteractionDayTimeHeatmapRef = useRef();
  const customerInteractionMapRef = useRef();
  const operationDetailPieRef = useRef();

  const accountId = localStorage.accountId;

  useEffect(() => {
    searchSubmit();
  }, []);

  const [loading, setLoading] = useState(false);

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      thing: null,
      thingId: null,
      operation: null,
      operationList: null,
      operationDateValue: null,
      operationDateStart: null,
      operationDateEnd: null,
      limit: 20, //chartLimit
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    chartRef.current.refreshTable(searchForm);
    pieChartRef.current.refreshTable(searchForm);
    pieChartPrintQrRef.current.refreshTable(searchForm);
    topInteractionsRef.current.refreshTable(searchForm);
    interactionCountRef.current.refreshTable(searchForm);
    totalInteractionsCountRef.current.refreshTable(searchForm);
    customerInteractionDayTimeHeatmapRef.current.refreshTable(searchForm);
    customerInteractionMapRef.current.refreshTable(searchForm);
    operationDetailPieRef.current.refreshTable(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    searchSubmit();
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onClickCalendarChart = (day) => {
    const date = parseStringDate(day);

    let tmp = {
      ...searchForm,
      operationDateStart: date,
      operationDateEnd: date,
    };

    //    tableRef.current.search(tmp);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1} style={{ marginTop: 5 }}>
          <Grid item xs={12}>
            <TotalInteractionsCount
              ref={totalInteractionsCountRef}
              searchForm={searchForm}
              showNumbers={true}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <div>
                  <CustomerInteractionDailyHeatmap
                    ref={chartRef}
                    searchForm={searchForm}
                    onClick={onClickCalendarChart}
                    showNumbers={true}
                    title={true}
                  />
                  <Adsense value="RklmKare" />
                  <TopInteractionsList
                    ref={topInteractionsRef}
                    searchForm={searchForm}
                    showThings={true}
                    title={true}
                  />
                </div>

                <InteractionCountsByOperationChart
                  ref={pieChartRef}
                  searchForm={searchForm}
                  //onClick={onClickCalendarChart}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <CustomerInteractionMapCard
                  ref={customerInteractionMapRef}
                  searchForm={searchForm}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomerInteractionDayTimeHeatmap
                  ref={customerInteractionDayTimeHeatmapRef}
                  searchForm={searchForm}
                  onClick={onClickCalendarChart}
                  showNumbers={true}
                  title={true}
                />
              </Grid>
              <Grid item xs={12}>
                <InteractionCountByDate
                  ref={interactionCountRef}
                  searchForm={searchForm}
                  showThings={true}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={12} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <InteractionCountsByPrintQr
                  ref={pieChartPrintQrRef}
                  // searchForm={searchForm}
                />
              </Grid>
              <Grid item xs={12}>
                <InteractionCountsByOperationAndDetails
                  ref={operationDetailPieRef}
                  //onClick={onClickCalendarChart}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={2}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={6}>
              <FaSearch
                onSearch={searchSubmit}
                onClear={searchClear}
                onSummarySearch={searchSummary}
                faSummary={searchForm?.summary}
                faClassName="appSearchBar"
              >
                <ThingAutoComplete
                  accountId={accountId}
                  value={searchForm.thing}
                  onChange={(data) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "thing",
                      data,
                      "thingId",
                      data?.id
                    );
                  }}
                />

                <CustomerIneractionOperationSelect
                  value={searchForm.operation}
                  onChange={(data) => {
                    setSearchForm({
                      ...searchForm,
                      operation: data,
                    });
                  }}
                />

                <DateRangeSelector
                  label={t("DATE")}
                  value={{
                    id: searchForm.operationDateId,
                    startDate: searchForm.operationDateStart,
                    endDate: searchForm.operationDateEnd,
                  }}
                  onChange={(data) => {
                    setSearchForm({
                      ...searchForm,
                      operationDateStart: data.startDate,
                      operationDateEnd: data.endDate,
                      operationDateId: data.id,
                    });
                  }}
                />
              </FaSearch>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
