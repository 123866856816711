import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { FaDatePicker } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function DateRangeSelector(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  const [customDate, setCustomDate] = useState(false);
  const [startDate, setStartDate] = useState(
    value?.startDate ? new Date(value?.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    value?.endDate ? new Date(value?.endDate) : null
  );

  const arrangeDate = (val) => {
    let start = null;
    let end = null;
    var today = new Date();

    if (val === "0")
      start = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
    else if (val === "1") {
      start = new Date(today);
      start.setDate(start.getDate() - 1);
    } else if (val === "2") {
      start = new Date(today);
      start.setDate(start.getDate() - 7);
    } else if (val === "3") {
      start = new Date(today);
      start.setDate(start.getDate() - 14);
    } else if (val === "4") {
      start = new Date(today.setMonth(today.getMonth() - 1));
    } else if (val === "5") {
      start = new Date(today.setMonth(today.getMonth() - 6));
    } else if (val === "6") {
      start = new Date(today.setMonth(today.getMonth() - 12));
    } else if (val === "7") {
      setCustomDate(true);
    }

    if (start != null) {
      setCustomDate(false);
      start = new Date(
        start.getFullYear(),
        start.getMonth(),
        start.getDate(),
        0,
        0,
        0
      );

      setStartDate(start);
      setEndDate(end);

      onChange({
        id: val,
        startDate: start ? start.toJSON().slice(0, 10) : null,
        endDate: end ? end.toJSON().slice(0, 10) : null,
      });
    } else {
      setStartDate(null);
      setEndDate(null);

      onChange({
        id: val,
        startDate: null,
        endDate: null,
      });
    }
  };

  return (
    <>
      <FormControl className={classes.w100}>
        <InputLabel>{label ? label : t("TARIH")}</InputLabel>
        <Select
          variant="outlined"
          value={value?.id}
          onChange={(event) => {
            arrangeDate(event.target.value);
          }}
          required={required}
          label={label ? label : t("TARIH")}
        >
          <MenuItem value={null}>...</MenuItem>
          <MenuItem value={"0"}>{t("Bugün")}</MenuItem>
          <MenuItem value={"1"}>{t("Dün")}</MenuItem>
          <MenuItem value={"2"}>{t("Son 1 hafta")}</MenuItem>
          <MenuItem value={"3"}>{t("Son 2 hafta")}</MenuItem>
          <MenuItem value={"4"}>{t("Son 1 ay")}</MenuItem>
          <MenuItem value={"5"}>{t("Son 6 ay")}</MenuItem>
          <MenuItem value={"6"}>{t("Son 1 yıl")}</MenuItem>
          <MenuItem value={"7"}>{t("Özel")}</MenuItem>
        </Select>
      </FormControl>
      {customDate || value?.id === "7" ? (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={6}>
            <FaDatePicker
              label={t("START_DATE")}
              faType="date"
              name="startDate"
              value={startDate}
              faOnChange={(val) => {
                setStartDate(val);
                onChange({
                  id: "7",
                  startDate: val ? val.toJSON().slice(0, 10) : val,
                  endDate: endDate ? endDate.toJSON().slice(0, 10) : endDate,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FaDatePicker
              label={t("END_DATE")}
              faType="date"
              name="endDate"
              value={endDate}
              faOnChange={(val) => {
                setEndDate(val);

                onChange({
                  id: "7",
                  startDate: startDate
                    ? startDate.toJSON().slice(0, 10)
                    : startDate,
                  endDate: val ? val.toJSON().slice(0, 10) : val,
                });
              }}
            />
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}
