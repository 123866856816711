import { Grid } from "@mui/material";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import CustomerIneractionOperationSelect from "components/select/CustomerIneractionOperationSelect";
import CustomerInteractionDailyHeatmap from "features/customerInteraction/CustomerInteractionDailyHeatmap";
import CustomerInteractionDayTimeHeatmap from "features/customerInteraction/CustomerInteractionDayTimeHeatmap";
import InteractionCountByDate from "features/customerInteraction/InteractionCountByDate";
import InteractionCountsByOperationChart from "features/customerInteraction/InteractionCountsByOperationChart";
import TopThingInteractions from "features/customerInteraction/TopThingInteractions";
import TotalInteractionsCount from "features/customerInteraction/TotalInteractionsCount";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { parseStringDate } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function ProductDashboard() {
  const { t } = useTranslation(["customerInteraction", "components"], { i18n });
  const { id } = useParams();

  const dispatch = useDispatch();

  let history = useHistory();
  const dialogRef = useRef();
  const chartRef = useRef();
  const pieChartRef = useRef();
  const topThingInteractionsRef = useRef();
  const interactionCountRef = useRef();
  const totalInteractionsCountRef = useRef();
  const customerInteractionDayTimeHeatmapRef = useRef();

  const accountId = localStorage.accountId;

  useEffect(() => {
    searchSubmit();
  }, []);

  const [loading, setLoading] = useState(false);

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      thing: null,
      thingId: null,
      productId: id,
      operation: null,
      operationList: null,
      operationDateValue: null,
      operationDateStart: null,
      operationDateEnd: null,
      limit: 20, //chartLimit
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    chartRef.current.refreshTable(searchForm);
    pieChartRef.current.refreshTable(searchForm);
    topThingInteractionsRef.current.refreshTable(searchForm);
    interactionCountRef.current.refreshTable(searchForm);
    totalInteractionsCountRef.current.refreshTable(searchForm);
    customerInteractionDayTimeHeatmapRef.current.refreshTable(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    searchSubmit();
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onClickCalendarChart = (day) => {
    const date = parseStringDate(day);

    let tmp = {
      ...searchForm,
      operationDateStart: date,
      operationDateEnd: date,
    };

    //    tableRef.current.search(tmp);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid item container spacing={1} style={{ marginTop: 5 }}>
          <Grid item xs={6}>
            <div>
              <CustomerInteractionDailyHeatmap
                ref={chartRef}
                searchForm={searchForm}
                onClick={onClickCalendarChart}
                showNumbers={true}
              />
              <TopThingInteractions
                ref={topThingInteractionsRef}
                searchForm={searchForm}
                showThings={true}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <CustomerInteractionDayTimeHeatmap
              ref={customerInteractionDayTimeHeatmapRef}
              searchForm={searchForm}
              onClick={onClickCalendarChart}
              showNumbers={true}
            />
          </Grid>

          <Grid item xs={12}>
            <TotalInteractionsCount
              ref={totalInteractionsCountRef}
              searchForm={searchForm}
              showNumbers={true}
            />
          </Grid>
          <Grid item xs={6}>
            <InteractionCountsByOperationChart
              ref={pieChartRef}
              searchForm={searchForm}
              //onClick={onClickCalendarChart}
            />
          </Grid>
          <Grid item xs={4}>
            <InteractionCountByDate
              ref={interactionCountRef}
              searchForm={searchForm}
              showThings={true}
            />
          </Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <CustomerIneractionOperationSelect
                value={searchForm.operation}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    operation: data,
                  });
                }}
              />

              <DateRangeSelector
                label={t("DATE")}
                value={{
                  id: searchForm.operationDateId,
                  startDate: searchForm.operationDateStart,
                  endDate: searchForm.operationDateEnd,
                }}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    operationDateStart: data.startDate,
                    operationDateEnd: data.endDate,
                    operationDateId: data.id,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
