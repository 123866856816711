import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import UserDetail from "components/detail/UserDetail";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PolicyService } from "services/PolicyService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import PolicyEdit from "./PolicyEdit";
import PolicyLog from "./PolicyLog";

export default function PolicyDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation(["policy", "components"], { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [policy, setPolicy] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    PolicyService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setPolicy({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const editDialogRender = () => {
    return (
      <PolicyEdit policy={policy} ref={dialogRef} onSave={onSave}></PolicyEdit>
    );
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  const changeStatusActive = () => {
    faDialog({
      description: t("Kuralı aktifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        PolicyService.active(
          id,
          changeStatusActiveOnSuccess,
          changeStatusActiveOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusActiveOnSuccess = (data) => {
    setPolicy({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusActiveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const changeStatusPassive = () => {
    faDialog({
      description: t("Kuralı pasif hale getirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        PolicyService.passive(
          id,
          changeStatusPassiveOnSuccess,
          changeStatusPassiveOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };
  const changeStatusPassiveOnSuccess = (data) => {
    setPolicy({
      ...data,
    });
    setLoading(false);
  };

  const changeStatusPassiveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {editDialogRender()}
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Grid item container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  title={t("POLICY_INFO")}
                  data-fa-section="POLICY_INFO"
                  action={
                    <IconButton
                      disabled={policy.status !== "PASSIVE"}
                      aria-label="settings"
                      onClick={() => openEditDialog()}
                      size="large">
                      <EditIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Grid item container spacing={1}>
                        <Grid item xs={6} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("NAME")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {policy.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DESCRIPTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {policy.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("SOURCE_ACTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t("ACTION_TYPE_" + policy.sourceAction, {
                              ns: "components",
                            })}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("TARGET_ACTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t("POLICY_ACTION_" + policy.targetAction, {
                              ns: "components",
                            })}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("SCHEDULE_PLAN")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {policy.schedulePlan?.name}
                            {!policy.schedulePlan &&
                              t("Her Zaman", { ns: "components" })}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("THING_CLASS")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {policy.thingClass?.className}
                          </Typography>
                        </Grid>
                        {policy.targetAction === "NEW_ISSUE" && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("ISSUE_CLASS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.issueClass?.className}
                            </Typography>
                          </Grid>
                        )}
                        {(policy.targetAction === "NEW_TASK" ||
                          policy.targetAction === "EMAIL_NOTIFICATION" ||
                          policy.targetAction === "SMS_NOTIFICATION" ||
                          policy.targetAction === "PUSH_NOTIFICATION") && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("TARGET_USER")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              <UserDetail
                                value={policy.targetUser}
                                type="USER"
                              />
                            </Typography>
                          </Grid>
                        )}
                        {(policy.targetAction === "EMAIL_NOTIFICATION" ||
                          policy.targetAction === "PUSH_NOTIFICATION") && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MESSAGE_TITLE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.messageTitle}
                            </Typography>
                          </Grid>
                        )}
                        {(policy.targetAction === "EMAIL_NOTIFICATION" ||
                          policy.targetAction === "PUSH_NOTIFICATION" ||
                          policy.targetAction === "SMS_NOTIFICATION") && (
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("MESSAGE_CONTENT")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.messageContent}
                            </Typography>
                          </Grid>
                        )}
                        {policy.targetAction === "NEW_TASK" && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("TASK_DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.taskDescription}
                            </Typography>
                          </Grid>
                        )}
                        {policy.targetAction === "NEW_ISSUE" && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NEW_ISSUE_CLASS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.newIssueClass?.className}
                            </Typography>
                          </Grid>
                        )}
                        {policy.targetAction === "NEW_ISSUE" && (
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("NEW_ISSUE_DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.newIssueDescription}
                            </Typography>
                          </Grid>
                        )}
                        {policy.targetAction === "DISPATCH_ORGANIZATION" && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("IP_RANGE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.ipRangeStart}-{policy.ipRangeEnd}
                            </Typography>
                          </Grid>
                        )}
                        {policy.targetAction === "DISPATCH_ORGANIZATION" && (
                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("ORGANIZATION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {policy.organization?.name}
                            </Typography>
                          </Grid>
                        )}

                        <Grid
                          item
                          xs={12}
                          className={labelClasses.LabelLastRow}
                        >
                          <Typography className={labelClasses.LabelHeader}>
                            {t("STATUS")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t(policy.status)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={labelClasses.LabelLastRow}
                        >
                          {policy.status === "PASSIVE" ? (
                            <FaButton
                              style={{ width: "20%" }}
                              variant="contained"
                              size="small"
                              faClick={() => {
                                changeStatusActive();
                              }}
                              //startIcon={<FontAwesomeIcon icon={faUserEdit} />}
                              data-fa-button="ACTIVATE"
                            >
                              {t("ACTIVATE")}
                            </FaButton>
                          ) : (
                            <>
                              {policy.status === "ACTIVE" && (
                                <FaButton
                                  style={{ width: "20%", marginLeft: 10 }}
                                  variant="contained"
                                  size="small"
                                  color="secondary"
                                  faClick={() => {
                                    changeStatusPassive();
                                  }}
                                  //startIcon={<Refresh />}
                                  data-fa-button="PASSIVATE"
                                >
                                  {t("PASSIVATE")}
                                </FaButton>
                              )}
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} xs={12}>
          {policy && policy.id > 0 && <PolicyLog policy={policy}></PolicyLog>}
        </Grid>
      </Grid>
    </Suspense>
  </>;
}
