import { Button, Grid } from "@mui/material";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getRole, getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReservationService } from "services/ReservationService";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";

const ReservationEdit = forwardRef((props, ref) => {
  const { onSave } = props;
  const { t } = useTranslation("reservation", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);

  const role = useSelector(getRole);
  const isRoleExists = checkRole([ROLES.ACCOUNTOWNER, ROLES.POWER_USER], role);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const newReservation = () => {
    return {
      account: { id: accountId },
      user: userInfo,
      thing: {},
      reservationDateStart: null,
      reservationDateEnd: null,
      description: null,
      status: "ACTIVE",
    };
  };
  const [reservationDTO, setReservationDTO] = useState(newReservation());
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog(obj) {
      if (obj) setReservationDTO({ ...obj });

      setIsEditDialogOpen(true);
    },
  }));

  const save = () => {
    ReservationService.save(reservationDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = () => {
    setReservationDTO(newReservation());
    setIsEditDialogOpen(false);
    onSave();
  };

  const saveOnError = (error) => {
    showAlert(t(error?.message), "error");
  };

  const handleChangeV = (prop, val) => {
    setReservationDTO({ ...reservationDTO, [prop]: val });
  };

  const cancelReservation = () => {
    faDialog({
      description: t("Rezervasyonu iptal etmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ReservationService.passive(reservationDTO.id, cancelSuccess, () => {});
      })
      .catch((err) => console.log("error:", err));
  };
  const cancelSuccess = () => {
    setIsEditDialogOpen(false);
    onSave();
  };

  const deleteReservation = () => {
    faDialog({
      description: t("Rezervasyonu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ReservationService.delete(reservationDTO, cancelSuccess, () => {});
      })
      .catch((err) => console.log("error:", err));
  };

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("EDIT_RESERVATION")}
          faOpen={isEditDialogOpen}
          faOnSubmit={save}
          showSaveButton={reservationDTO?.status === "ACTIVE"}
          faHandleClose={(data) => {
            setIsEditDialogOpen(false);
            setReservationDTO(newReservation());
            // setSelectedReservation(null);
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <UserAutoComplete
                value={reservationDTO?.user}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeV("user", data);
                }}
                label={t("USER")}
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <ThingAutoComplete
                value={reservationDTO?.thing}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeV("thing", data);
                }}
                required
                style={{ minHeight: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("RESERVATION_DATE_START")}
                faType="dateTime"
                required
                value={reservationDTO?.reservationDateStart}
                faOnChange={(val) => {
                  handleChangeV("reservationDateStart", val);
                }}
                data-fa-input="RESERVATION_DATE_START"
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("RESERVATION_DATE_END")}
                faType="dateTime"
                required
                value={reservationDTO?.reservationDateEnd}
                faOnChange={(val) => {
                  handleChangeV("reservationDateEnd", val);
                }}
                data-fa-input="RESERVATION_DATE_END"
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  handleChangeV("description", event.target.value);
                }}
                value={reservationDTO?.description}
              />
            </Grid>
            {reservationDTO?.id &&
              (isRoleExists || userInfo.id === reservationDTO.user.id) && (
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    onClick={cancelReservation}
                    disabled={reservationDTO?.status === "PASSIVE"}
                    color="secondary"
                  >
                    {reservationDTO?.status === "PASSIVE"
                      ? t("CANCELED")
                      : t("CANCEL")}
                  </Button>
                  <Button
                    onClick={() => {
                      deleteReservation();
                    }}
                    color="secondary"
                  >
                    {t("DELETE")}
                  </Button>
                </Grid>
              )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
});

export default ReservationEdit;
