import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { MapInteractionCSS } from "react-map-interaction";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingPhotosCarousel(props) {
  const {
    thingId,
    serviceOperationId,
    uploadImages,
    deleteImage,
    setDefaultImage,
    images,
    setImages,
    selectedItem,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("thingDetail", { i18n });
  const fileInputRef = useRef();
  const [loadingImages, setLoadingImages] = useState([]);

  let loadingItem = null;

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const createCarouselItemImage = (item, index) => (
    <div key={index} className={classes.image}>
      {item.hdImage && (
        // <img
        //   alt=""
        //   src={`data:image/png;base64, ${item.hdImage}`}
        //   style={{ maxHeight: "600px" }}
        // />
        <MapInteractionCSS showControls={true}>
          <img
            alt=""
            src={`data:image/png;base64, ${item.hdImage}`}
            style={{ maxHeight: "600px" }}
          />
        </MapInteractionCSS>
      )}
      {item.defaultContent === true && (
        <CheckCircleIcon
          style={{ color: green[500], zIndex: 1 }}
          className={classes.defaultImage}
        />
      )}
      {item.defaultContent !== true && thingId && (
        <div className={classes.defaultImage}>
          <FaButton
            label={t("Varsayılan Yap")}
            size="small"
            onClick={() => {
              setDefaultImage(item.object);
            }}
          />
        </div>
      )}
    </div>
  );

  const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  const customRenderThumb = (children) =>
    children.map((item, index) => {
      return (
        <img
          src={`data:image/png;base64, ${images[index].content}`}
          //className={classes.thingImage}
          alt={item.object}
        />
      );
    });

  const customRenderItem = (item, props) => {
    if (props.isSelected === true) {
      if (
        !images[item.key].hdImage &&
        loadingImages.indexOf(item.key) < 0 &&
        loading === false
      ) {
        setLoading(true);
        setLoadingImages((prev) => {
          return [...prev, item.key];
        });
        if (serviceOperationId) {
          console.log("service: ", serviceOperationId);
          ServiceOperationClassService.downloadServiceOperationDocument(
            serviceOperationId,
            images[item.key].object,
            (data) => {
              onDownloadImageSuccess(data, item.key);
            },
            (error) => {
              setLoading(false);
            }
          );
        } else if (thingId) {
          ThingService.downloadImage(
            thingId,
            images[item.key].object,
            (data) => {
              onDownloadImageSuccess(data, item.key);
            },
            (error) => {
              setLoading(false);
            }
          );
        }
      }
    }

    return <item.type {...item.props} {...props} />;
  };

  const onDownloadImageSuccess = (data, index) => {
    setLoading(false);
    setImages((prev) => {
      let row = { ...prev[index], hdImage: data?.content };
      let imagesTmp = [...prev];
      imagesTmp[index] = row;

      return [...imagesTmp];
    });
  };

  return (
    (thingId || serviceOperationId) && (
      <Carousel
        height="500px"
        showArrows={true}
        renderItem={customRenderItem}
        renderThumbs={customRenderThumb}
        selectedItem={selectedItem}
      >
        {baseChildren.props.children}
      </Carousel>
    )
  );
}
