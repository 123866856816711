import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MenuIcon from "@mui/icons-material/Menu";
import AccountDetailLogoHelp from "features/help/AccountDetailLogoHelp";
import React, { useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { ACCOUNT } from "../../services/faApi";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountPhoto(props) {
  const { accountDetails } = props;
  const { t } = useTranslation("accountDetails", { i18n });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  let history = useHistory();
  const fileInputRef = useRef();
  const [thumbnail, setThumbnail] = useState("");
  const faDialog = useDialog();
  const [anchorElPicture, setAnchorElPicture] = useState(null);

  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isAccountDetailsEditOpen, setIsAccountDetailsEditOpen] =
    useState(false);

  const editButtonClicked = () => {
    setIsAccountDetailsEditOpen(true);
  };

  useEffect(() => {
    refresh();
  }, [accountDetails]);

  const refresh = () => {
    if (accountDetails.id > 0) {
      setLoading(true);
      let req = requests.getObjectByIdRequest(
        ACCOUNT.downloadLogo,
        accountDetails.id
      );
      FaAxios(req)
        .then((response) => {
          setLoading(false);
          refreshImages(response);
        })
        .catch((error) => {
          showAlert(error.message, "error");
          setLoading(false);
        });
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const uploadImages = async (newImages) => {
    for await (const fileObj of newImages) {
      let fd = new FormData();
      fd.append("multipartFile", fileObj, fileObj.name);
      let req = requests.getSimplePostRequest(
        ACCOUNT.uploadLogo + "/" + accountDetails.id,
        fd
      );
      req["header"] = { "Content-Type": "multipart/form-data" };
      await FaAxios(req)
        .then((response) => {})
        .catch((error) => {
          showAlert(t("errorMessages:" + error.response.data), "error");
          setLoading(false);
        });
    }

    refresh();
  };

  const refreshImages = (response) => {
    if (response?.data?.content) {
      setThumbnail("data:image/jpeg;base64," + response.data.content);
    }
  };

  const deleteImage = () => {
    faDialog({
      description: t("Logoyu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        setLoading(true);

        let req = requests.getSimplePostRequest(
          ACCOUNT.deleteLogo + "/" + accountDetails.id
        );
        FaAxios(req)
          .then((response) => {})
          .catch((error) => {
            showAlert(error.message, "error");
            setLoading(false);
          });
      })
      .catch((err) => console.log("error:", err));
  };

  return <>
    <Menu
      id="picture-menu"
      anchorEl={anchorElPicture}
      keepMounted
      open={Boolean(anchorElPicture)}
      onClose={() => {
        setAnchorElPicture(null);
      }}
    >
      <MenuItem
        onClick={() => {
          fileInputRef.current.click();
          setAnchorElPicture(null);
        }}
      >
        {t("Fotoğraf Yükle")}
      </MenuItem>
      <MenuItem
        disabled={!!!thumbnail}
        onClick={() => {
          deleteImage();
          setAnchorElPicture(null);
        }}
      >
        {t("Fotoğrafı Kaldır")}
      </MenuItem>
    </Menu>
    <Card style={{ minHeight: 330, overflowY: "auto" }}>
      <CardHeader
        title={t("ACCOUNT_IMAGE")}
        action={
          <>
            <AccountDetailLogoHelp value={"accountDetails.logo"} />
            <IconButton
              aria-controls="picture-menu"
              aria-haspopup="true"
              onClick={(event) => {
                setAnchorElPicture(event.currentTarget);
              }}
              size="large">
              <MenuIcon />
            </IconButton>
          </>
        }
      ></CardHeader>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          <div className={classes.AccountDetailImage}>
            {thumbnail ? (
              <img src={thumbnail} alt="logo" style={{ width: "100%" }} />
            ) : (
              <Avatar className={classes.AccountDetailImage}>
                {accountDetails.fullname.charAt(0)}
              </Avatar>
            )}
          </div>
          <input
            onChange={handleChange}
            ref={fileInputRef}
            type="file"
            hidden
          />
        </Box>
      </CardContent>
    </Card>
  </>;
}
