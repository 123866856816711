import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    minHeight: "100%",
  },

  banner: {
    height: 56,
    width: "100%",
    borderBottom: "solid 1px #DDDDDD",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "white",
    zIndex: 20,
    justifyContent: "space-between",
    display: "flex",
  },

  bannerLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingLeft: 16,
  },

  bannerTitle: {
    display: "flex",
    fontSize: 18,
    alignSelf: "center",
    fontWeight: 700,
  },

  bannerRight: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  appLogo: {
    maxWidth: 200,
    maxHeight: 50,
  },

  thingTitle: {
    display: "flex",

    fontSize: 18,
    fontWeight: 700,
  },

  thingDescription: {
    paddingTop: 20,
    display: "flex",

    fontSize: 16,
  },

  customerButton: {
    color: "#1877F2",
    "&:hover": {
      color: "#3F8EF4",
    },
  },

  pageContent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 80,
  },

  tabs: {
    paddingTop: 30,
  },

  tabButtonSelected: {
    backgroundColor: "#F2F2F2",
    fontSize: 12,
    fontWeight: 600,
    color: "black",
  },

  tabButton: {
    backgroundColor: "#D9D9D9",
    fontSize: 12,
    fontWeight: 600,
    color: "black",
  },

  infoButton: {
    color: "white",
    backgroundColor: "#949398",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "solid 2px white",
    borderRadius: 10,
    width: "45%",
    fontSize: 11,
    float: "right",
  },

  photosDiv: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    padding: 4,
    paddingTop: 80,
  },

  photosDivNoMargin: {
    padding: 4,
    paddingBottom: 20,
  },

  actionButtons1Div: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    padding: 4,
  },

  actionButtons2Div: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 0,
    paddingTop: 4,
    paddingBottom: 4,
    display: "flex",
    justifyContent: "center",
  },

  midPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
    textAlign: "center",
  },

  surveyButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "1px solid #DDDDDD",
    color: "#151B26",
    width: "98%",
    maxWidth: 624,
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 11,
    justifyContent: "left",
  },

  registerButton: {
    backgroundColor: "#656DFC",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    width: 150,
    float: "right",
    borderRadius: 10,
    fontSize: 11,
  },

  mainPanel: {
    backgroundColor: "#E3E6E8",
    padding: 16,
  },

  expandDetailButton: {
    color: "#1877F2",
    float: "right",
  },

  closeDetailButton: {
    color: "#F44336",
    float: "right",
  },

  highlightItem: {
    backgroundColor: "white",
    borderRadius: 10,
    padding: 5,
    marginBottom: 3,
  },

  highlightItemTitle: {
    fontSize: 14,
    flex: 11,
    paddingLeft: 5,
    paddingTop: 10,
  },

  highlightItemExpand: {
    flex: 1,
    textAlign: "right",
  },

  highlightItemDetail: {
    paddingLeft: 5,
    paddingTop: 20,
    fontSize: 14,
  },

  highlightItemButton: {
    backgroundColor: "#1877F2",
    "&:hover": {
      backgroundColor: "#3F8EF4",
    },
    color: "white",
    width: "100%",
    marginTop: 10,
  },

  bottomPanel: {
    marginTop: 90,
    marginLeft: 30,
    marginRight: 30,
    display: "flex",
  },

  issueButton: {
    backgroundColor: "rgb(244, 67, 54)",
    "&:hover": {
      backgroundColor: "#FF9F07",
    },
    color: "white",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 500,
    width: "100%",
    height: 48,
    float: "right",
  },

  suggestionButton: {
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#808080",
    },
    color: "white",
    padding: 0,
    //marginTop: 10,
    width: "100%",
    border: "solid 2px white",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 500,
    height: 48,
  },

  likeButton: {
    backgroundColor: "#1877F2",
    "&:hover": {
      backgroundColor: "#3F8EF4",
    },
    color: "white",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 500,
    height: 48,
    width: "100%",
    float: "left",
  },

  downloadButton: {
    backgroundColor: "#1877F2",
    "&:hover": {
      backgroundColor: "#3F8EF4",
    },
    color: "white",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 500,
    minHeight: 48,
    minWidth: 48,
    float: "left",
  },

  detailItemType: {
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: 11,
    fontWeight: 400,
    paddingTop: 5,
  },

  detailItemTitle: {
    fontSize: 15,
    fontWeight: 600,
    paddingTop: 5,
  },

  bottomPanelIcon: {
    fontSize: 48,
    color: "white",
  },

  linksPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
  },

  linksTitle: {
    backgroundColor: "#E2E2E2",
    height: 30,
    width: "100%",
    paddingTop: "10px",
    color: "#656DFC",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
  },

  linkItem: {
    backgroundColor: "#E2E2E2",
    height: 30,
    width: "100%",
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    cursor: "pointer",
    color: "#656DFC",
    fontSize: 13,
    marginTop: 20,
  },

  actionButtonIcon: {
    paddingRight: 5,
  },

  documentButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "#151B26",
    border: "1px solid #DDDDDD",
    width: "98%",
    maxWidth: 624,
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 11,
    justifyContent: "left",
  },

  linkButton: {
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "#151B26",
    border: "1px solid #DDDDDD",
    width: "98%",
    maxWidth: 624,
    borderRadius: 10,
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 5,
    fontSize: 11,
    justifyContent: "left",
  },

  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    paddingBottom: "5px",
    paddingTop: "3px",
    borderTop: "solid 1px #DDDDDD",
    zIndex: 20,
  },

  headerLogo: {
    height: 40,
  },

  copyright: {
    marginTop: -37,
    paddingBottom: 10,
  },
}));
