import { Card, CardHeader, Grid } from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CAMPAIGN } from "services/faApi";
import { qsParse } from "services/utils";
import CampaignCustomerStatusDecorator from "../../../components/ui/CampaignCustomerStatusDecorator";
import i18n from "../../../i18n";

export default function HomepageCampaignJoinList(props) {
  const tableRef = useRef();
  const { t } = useTranslation("campaign", { i18n });
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  let history = useHistory();
  const { size } = props;
  const type = qsParse(history.location.search)?.type;

  const accountId = localStorage.accountId;

  const columns = [
    {
      field: "registerDate",
      label: t("REGISTER_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "campaign",
      label: type === "EVENT" ? t("Etkinlik") : t("Kampanya"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "name",
      label: t("Ad"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return row.customer?.name;
      },
    },
    {
      field: "surname",
      label: t("Soyad"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return row.customer?.surname;
      },
    },
    {
      field: "draw",
      label: t("Çekiliş Hakkı"),
      align: "center",
      width: "10%",
    },
    {
      field: "orderNo",
      label: t("ORDER_NO"),
      align: "center",
      width: "10%",
    },

    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => {
        return <CampaignCustomerStatusDecorator value={val} />;
      },
    },
    {
      field: "campaignAward",
      label: t("Ödül"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => {
        return val?.name;
      },
    },
  ];

  const newSearchForm = () => {
    let start = new Date();
    start.setDate(start.getDate() - 7);
    start.setHours(0, 0, 0, 0);
    return {
      accountId: accountId,
      campaign: null,
      campaignId: id,
      thingId: null,
      email: null,
      phone: null,
      name: null,
      surname: null,
      hashtag: null,
      recordType: null,
      status: location?.state?.status ?? null,
      description: null,
      type: type ?? null,
      registerDate: start,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  return (
    <>
      <Grid item lg={size} xs={12}>
        <Card>
          <CardHeader
            title={t("Kampanya Katılım Listesi")}
            data-fa-section="Kampanya Katılım Listesi"
          />

          <ThingsDataTable
            searchUrl={CAMPAIGN.searchCustomer}
            searchForm={searchForm}
            queryParams={{
              page: 0,
              size: 10,
              sort: "registerDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
          />
        </Card>
      </Grid>
    </>
  );
}
