import { CardHeader, FormControlLabel, Switch } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Card from "@mui/material/Card";
import { green, grey } from "@mui/material/colors";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { QUEUE_LOG } from "services/faApi";
import { IssueDesc } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function QueueLog(props) {
  const { queue } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();
  let history = useHistory();

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const { t } = useTranslation(["queue", "components"], { i18n });

  const columns = [
    {
      field: "createDate",
      label: t("DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "issue",
      label: t("ISSUE_STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.issue.status === "OPEN") {
          return <FormControlLabel control={<GreenSwitch checked={true} />} />;
        } else {
          return <FormControlLabel control={<Switch checked={false} />} />;
        }
      },
    },
    {
      field: "issue",
      label: t("ISSUE"),
      align: "center",
      width: "35%",
      valueFormatter: (val, row) => IssueDesc(row.issue),
    },
    {
      field: "task",
      label: t("TASK_STATUS"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        return t("TASK_STATUS_" + value?.status, { ns: "components" });
      },
    },
  ];

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/issues/detail/${row.issue.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-thingLinks">
        <CardHeader title={t("QUEUE_LOG")} data-fa-section="QUEUE_LOG" />
        {queue && queue.id > 0 && (
          <ThingsDataTable
            dataUrl={QUEUE_LOG.findLogs + "?id=" + queue.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "createDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("QUEUE_LOG")}
            //showHeader={false}
            columnClassName={gridClasses.GenericColumn}
            faOnDoubleClick={onDoubleClick}
          />
        )}
      </Card>
    </>
  );
}
