import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function AccountUserInvitationTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("tutorialAccountDetails", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Hesabınıza Kullanıcı(lar)ın <b>eklendiği*</b> ve yönetildiği
            Menü/Sayfadır.
            <br />
            <br />
            <b>*</b>: Başlangıç ve üstü paketlerde
            <p>
              Bu menüde;
              <ul>
                <li>
                  Hesabınıza eklemek istediğiniz kullanıcıları davet
                  edebilirsiniz.
                </li>
                <li>
                  Davet edilen Kullanıcıları listeleyebilir ve davet durumlarını
                  izleyebilirsiniz.
                </li>
                <li>Kullanıcı Detay bilgilerine erişebilirsiniz.</li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ADD']",
        intro: (
          <TutorialElement icon="click">
            Kullanıcı davet etmek için <b>Yeni Takım Arkadaşı Davet Et</b>’e
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#dialog-form",
        intro: (
          <TutorialElement icon="click">
            <b>İletişim bilgisi</b> seçimi yapın ve Kullanıcıya ait{" "}
            <b>E-posta</b> veya <b>Cep Telefonu </b>bilgilerini girin. <br />{" "}
            <br />
            <b>Kullanıcı Rolü</b> seçmek için{" "}
            <b>Faydalanıcı, Yetkili Kullanıcı Varlık Yöneticisi</b> ve{" "}
            <b>Operatör</b> seçeneklerinden birini seçin. <br /> <br />
            Kullanıcının <b>Ad{"&"}Soyadını</b> girin, <b>Davet mesajı</b> yazın
            ve <b>Kaydet</b>’e basın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $("[data-fa-button='ADD']")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"account_user_invitation"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
        }}
      />
    </div>
  );
}
