import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RecurrenceDesc, ThingDesc } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { RECURRENCE } from "../../services/faApi";
import DynamicRecurrenceEdit from "./DynamicRecurrenceEdit";
import StaticRecurrenceEdit from "./StaticRecurrenceEdit";
import { useStyles } from "./style";

export default function Recurrence() {
  const { t } = useTranslation("recurrence", { i18n });
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const userInfo = useSelector(getUserInfo);
  let history = useHistory();
  const dialogStaticRef = useRef();
  const dialogDynamicRef = useRef();
  const tableRef = useRef();
  // States
  const [loading, setLoading] = useState(false);

  const newRecurrence = () => {
    return {
      id: 0,
      name: "",
      description: "",
      account: {
        id: accountId,
      },
      user: {
        id: userInfo.id,
      },
      status: "PASSIVE",
      dateType: "LITERAL",
      userGroup: null,
      frequency: "hour",
      hourlyInterval: 8,

      dailyMod: "INTERVAL", //WEEKDAY
      dailyInterval: 1,

      weeklyInterval: 1,
      weeklyDays: [false, false, false, false, false, false, false],

      monthlyDay: 1,
      monthlyInterval: 1,

      yearlyInterval: 1,
      yearlyDay: 1,
      yearlyMonth: 0,

      startTime: new Date(2021, 1, 1, 12, 0, 0),
      startDate: new Date(),
      endDate: null,

      mtncRecurrenceTargets: [],
    };
  };

  const [recurence, setRecurence] = useState(newRecurrence());

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/recurrence/detail/${row.id}`,
      breadCrumbKey: `/recurrence/detail/:id`,
    };
    history.push(tmpTo);
  };

  const columns = [
    {
      field: "name",
      label: t("PERIODIC_TASK_NAME"),
      align: "center",
      width: "10%",
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.user ? (
          <UserDetail value={row.user} type="USER" />
        ) : (
          <UserDetail value={row.userGroup} type="USER_GROUP" />
        );
      },
    },
    {
      field: "previousDate",
      label: t("PREVIOUS_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "nextDate",
      label: t("NEXT_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "frequency",
      label: "Tekrarlanma",
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return RecurrenceDesc(row, t);
      },
    },
    {
      field: "mtncRecurrenceTargets",
      label: t("TARGET_GROUP"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        return (
          <ul className={classes.chipRoot}>
            {value?.map((data, index) => {
              let icon;
              let label;

              if (data.thingClass) {
                icon = <FontAwesomeIcon icon={faSitemap} />;
                label = data.thingClass.className;
              } else if (data.thing) {
                icon = <FontAwesomeIcon icon={faCubes} />;
                label = ThingDesc(data.thing);
              } else if (data.hashtag) {
                icon = <FontAwesomeIcon icon={faHashtag} />;
                label = data.hashtag;
              } else if (data.organization) {
                icon = <FontAwesomeIcon icon={faFolder} />;
              } else if (data.location) {
                icon = <FontAwesomeIcon icon={faGlobe} />;
              } else if (data.locationDetail) {
                icon = <FontAwesomeIcon icon={faMap} />;
              }

              return (
                <li key={data.key}>
                  <Chip icon={icon} label={label} className={classes.chip} />
                </li>
              );
            })}
          </ul>
        );
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (status) => {
        return t("" + status);
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
    });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const addDynamicRecurrenceRender = () => {
    return (
      <DynamicRecurrenceEdit
        ref={dialogDynamicRef}
        onSave={() => {
          tableRef.current.refreshTable();
        }}
      ></DynamicRecurrenceEdit>
    );
  };

  const addStaticRecurrenceRender = () => {
    return (
      <StaticRecurrenceEdit
        ref={dialogStaticRef}
        onSave={() => {
          tableRef.current.refreshTable();
        }}
      ></StaticRecurrenceEdit>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addStaticRecurrenceRender()}
        {addDynamicRecurrenceRender()}
        {
          <ThingsDataTable
            searchUrl={
              RECURRENCE.search + "?accountId=" + localStorage.accountId
            }
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
            faOnDoubleClick={onDoubleClick}
            queryParams={{
              page: 0,
              size: 10,
              sort: "description",
              isAsc: true,
            }}
          />
        }

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setRecurence(newRecurrence());
                dialogStaticRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD_STATIC")}
            </FaButton>
          </Grid>
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setRecurence(newRecurrence());
                dialogDynamicRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD_DYNAMIC")}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
