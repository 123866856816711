import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function HomepageTutorial(props) {
  const stepsRef = useRef();

  const { t } = useTranslation("tutorialProfile", { i18n });
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Hesaba giriş yapıldığında Etkileşim Noktaları ve ilgili
            istatistiklerin gösterildiği <b>Karşılama Sayfasıdır</b>.
            <p>
              Bu sayfada;
              <ul>
                <li>
                  <b>Dil ve Tema Tercihlerinizi</b> yönetebilirsiniz.
                </li>
                <li>
                  <b>Bildirimleri</b> görebilirsiniz.
                </li>
                <li>
                  <b>Profil </b> bilgilerinize erişebilirsiniz.
                </li>
                <li>
                  <b>Parola Değiştirme</b> yapabilirsiniz
                </li>
                <li>
                  <b>Çıkış</b> yapabilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(4)",
        intro: (
          <TutorialElement icon="click">
            <b>Dil ve Tema Tercihlerinizi</b> belirlemek için <b>tıklayın.</b>
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/assets/tutorial/temaSec.png"}
                width="323"
                height="349"
              ></img>
            </div>
            <br />
            Temayı <b>Aydınlık</b> yapmak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={process.env.PUBLIC_URL + "/assets/tutorial/dilSec.png"}
                width="323"
                height="349"
              ></img>
            </div>
            <br />
            <b>Dil seçimi</b> yapmak için seçeneklerden birine tıklayın.
          </TutorialElement>
        ),
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(2)",
        intro: (
          <TutorialElement icon="click">
            <b>Bildirimleri</b> görmek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(1)",
        intro: (
          <TutorialElement icon="click">
            <b>Profil</b> bilgileriniz, <b>Parola Değiştirme</b> veya{" "}
            <b>Çıkış</b> işlemleri için tıklayın.
          </TutorialElement>
        ),
        position: "left",
        onBeforeChange: (index) => {
          $(
            "#root > div > header > div > div > div:nth-child(3) > button:nth-child(1)"
          ).click();
        },
      },
      {
        element:
          "#primary-search-account-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded > ul > li:nth-child(2)",
        intro: (
          <TutorialElement icon="click">
            Profil bilgilerinize erişmek için tıklayın.
            <br />
            Bilgilerinizi <b>düzenlemek</b> ya da <b>güncellemek</b> için
            Kullanıcı Profili sayfasındaki yönergeleri takip edin.
          </TutorialElement>
        ),
        position: "left",
      },
      {
        element:
          "#primary-search-account-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded > ul > li:nth-child(3)",
        intro: (
          <TutorialElement icon="click">
            <b>Parolanızı değiştirmek</b> için tıklayın.
          </TutorialElement>
        ),
        position: "left",
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_mail_gonder.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            <b>E-posta Gönder</b>’e tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_aktivasyon_kodu.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            E-postanıza gelen <b>Aktivasyon Kodu</b>’nu girin.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_mevcut_parola.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            <b>Mevcut Parola</b>’nızı girin.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_yeni_parola.png"
                }
                width="489"
                height="373"
              ></img>{" "}
            </div>
            <br />
            <b>Yeni Parolanızı</b> girin ve <b>Kaydet</b>’e basın.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
      {
        element:
          "#primary-search-account-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded > ul > li:nth-child(4)",
        intro: (
          <TutorialElement icon="click">
            <b>Çıkış</b> yapmak için tıklayın.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"homepage_tutorial"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => {
          $("#primary-search-account-menu > div:nth-child(1)").click();
        }}
      />
    </div>
  );
}
