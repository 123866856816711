import {
  CardHeader,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Rating } from '@mui/material';
import React from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function SolutionControlItems(props) {
  const { solution } = props;
  const { t } = useTranslation("solutions", { i18n });

  const labelClasses = labelStyles();

  const createControlItem = (item, index) => {
    return (
      <div>
        <div>
          <b>{item.controlItem.orderNo}.</b> {item.controlItem.question}
        </div>
        <div>
          {item.controlItem.itemType === "YES_NO" && (
            <RadioGroup row aria-label="position" value={"" + item.yesNoValue}>
              <FormControlLabel
                value={"YES"}
                control={<Radio color="primary" />}
                label={item.controlItem.yesLabel}
                disabled
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="primary" />}
                label={item.controlItem.noLabel}
                disabled
              />
            </RadioGroup>
          )}
          {item.controlItem.itemType === "RATING_SCALE" && (
            <Rating
              max={item.maxRating}
              defaultValue={item.ratingValue}
              disabled
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Card>
        <CardHeader
          title={t("CHECK_QUESTIONS")}
          data-fa-section="CHECK_QUESTIONS"
        />
        <CardContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {solution?.solutionControlItems
                  .sort((a, b) =>
                    a.controlItem.orderNo > b.controlItem.orderNo
                      ? 1
                      : b.controlItem.orderNo > a.controlItem.orderNo
                      ? -1
                      : 0
                  )
                  .map((item, index) => (
                    <TableRow>
                      <TableCell className={labelClasses.LabelValue}>
                        {createControlItem(item, index)}
                      </TableCell>
                    </TableRow>
                  ))}
                {solution.solutionControlItems == null ||
                solution.solutionControlItems.length === 0 ? (
                  <TableRow>
                    <TableCell className={labelClasses.LabelValue}>
                      {t("NO_CHECKLIST_FOUND")}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
