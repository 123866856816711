import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Map, { GeolocateControl, Layer, Source } from "react-map-gl";
import { ParseLocation } from "services/utils";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "../../features/thingsMap/layers";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function MiniMapButton(props) {
  const { value, value2 } = props;

  const classes = useStyles();
  const dialogRef = useRef();

  useEffect(() => {
    if (value || value2) {
      let location = null;
      if (value) location = ParseLocation(value);
      else if (value2) location = ParseLocation(value2);

      setGeojson({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [location.lng, location.lat],
            },
          },
        ],
      });
    }
  }, [value, value2]);

  const { t } = useTranslation("components", { i18n });

  const mapRef = useRef();

  const [openMap, setOpenMap] = useState(false);

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA"; // Set your mapbox token here

  const geolocateStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  };

  const [viewport, setViewPort] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });

  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });

  const renderDialog = () => {
    return openMap && (
      <Dialog
        open={openMap}
        onClose={() => {
          setOpenMap(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth={true}
        ref={dialogRef}
      >
        <DialogTitle id="form-dialog-title">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setOpenMap(false);
            }}
            size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div
              style={{
                height: "80vh",
                width:
                  dialogRef && dialogRef.current
                    ? dialogRef.current.offsetWidth - 50
                    : 500,
                marginTop: 5,
              }}
            >
              <Map
                {...viewport}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/light-v10"
                onMove={(evt) => setViewPort(evt.viewState)}
                mapboxAccessToken={MAPBOX_TOKEN}
                //onClick={onClick}
                ref={mapRef}
                interactiveLayerIds={[
                  unclusteredPointLayer.id,
                  clusterLayer.id,
                ]}
              >
                <GeolocateControl
                  style={geolocateStyle}
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                />

                <Source
                  id="things"
                  type="geojson"
                  data={geojson}
                  cluster={true}
                  clusterMaxZoom={14}
                  clusterRadius={50}
                >
                  <Layer {...clusterLayer} />
                  <Layer {...clusterCountLayer} />
                  <Layer {...unclusteredPointLayer} />
                </Source>
              </Map>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMap(false);
            }}
            color="primary"
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const render = () => {
    if (value || value2) {
      return <>
        {renderDialog()}
        <IconButton
          onClick={() => {
            setOpenMap(true);
          }}
          title={t("Konumu haritada göster")}
          size="large">
          <FontAwesomeIcon icon={faMap} />
        </IconButton>
      </>;
    } else {
      return null;
      // <IconButton
      //   color="primary"
      //   title={t("Konum bulunmuyor")}
      //   disabled={true}
      // >
      //   <FontAwesomeIcon icon={faMap} />
      // </IconButton>
    }
  };

  return render();
}

MiniMapButton.propTypes = {
  value: PropTypes.any,
  value2: PropTypes.any,
};
