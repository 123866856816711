import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
  },
  modal: {
    width: "100%",
  },
  chipOpen: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  chipClose: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  drawer: {
    overflow: "hidden",
  },
  drawerContent: {
    padding: 10,
    height: 400,
  },
  drawerPaper: {
    height: 400,
    top: 80,
    width: "21.7%",
    minWidth: 200,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  success: {
    backgroundColor: green[500],
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  cancelButton: {
    marginBottom: "20px",
    marginLeft: "5px",
  },
  buttonPanel: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  CampaignImage: {
    //width: "100%",
    //height: "100%",
    maxHeight: "200px",
  },

  NumberPanel: {
    textAlign: "center",
    paddingBottom: 5,
    paddingTop: 10,
    cursor: "pointer",
  },

  container: {
    padding: 16,
  },

  input: {
    width: 60,
    height: 60,
    padding: 0,
    fontSize: 24,
    textAlign: "center",
    marginRight: 12,
    color: "#494949",
    border: "1px solid #d6d6d6",
    borderRadius: 4,
    background: "#fff",
    backgroundClip: "paddingBox",
  },
}));
