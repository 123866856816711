import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation } from "react-router-dom";

export default function RegisterCampaignResult(props) {
  const { appLogo } = props;

  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                width="250"
                height="92"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
          </Grid>
          <div>
            <Typography variant="h5">
              {t("Kampanyaya katıldığınız için teşekkürler.")}
            </Typography>
          </div>
          <div>
            <Typography variant="h7">
              {t(
                "Katılım durumunuz girdiğiniz bilgiler kontrol edildikten sonra iletişim bilgilerinize gönderilecektir."
              )}
            </Typography>
          </div>
          <Grid item xs={12}>
            <FaButton
              faType="button"
              color="primary"
              size="large"
              onClick={() => {
                let tmpTo = {
                  pathname: `/q/${publicThing.tid}`,
                  state: {
                    publicThing: {
                      ...publicThing,
                    },
                  },
                };
                history.push(tmpTo);
              }}
              //disabled={!isShowNextButton}
              style={{ height: "60px", marginTop: "20px" }}
              label={t("Geri Dön")}
            ></FaButton>
          </Grid>
        </div>
      </Box>
    </Box>
  );
}

RegisterCampaignResult.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
