import { faUserAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Grid, Paper } from "@mui/material";
import { Add, Cancel } from "@mui/icons-material";
import CustomerAutoComplete from "components/autocomplete/CustomerAutoComplete";
import CustomerListAutoComplete from "components/autocomplete/CustomerListAutoComplete";
import { default as React, useEffect, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CouponService } from "services/CouponService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CouponTargetEdit(props) {
  const { couponId, targets, open, onSave, onClose } = props;
  const { t } = useTranslation("coupon", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [editValue, setEditValue] = useState();
  const [newTargets, setNewTargets] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);
  const [sectionCustomer, setSectionCustomer] = useState(false);
  const [sectionCustomerList, setSectionCustomerList] = useState(false);

  useEffect(() => {
    let tmp = targets
      ? targets?.map((data) => {
          let dto = {
            id: data.id,
            customer: null,
            customerList: null,
            label: null,
            key: null,
          };

          if (data.customer) {
            dto.customer = data.customer;
            dto.label =
              data?.customer?.name && data?.customer?.surname
                ? data.customer.name + " " + data.customer.surname
                : data?.customer?.email;
            dto.key = "cu_" + data.id;
          } else if (data.customerList) {
            dto.customerList = data.customerList;
            dto.label = data.customerList.name;
            dto.key = "cl_" + data.id;
          }

          return dto;
        })
      : [];

    setEditValue(tmp);
  }, [targets]);

  const handleChipDelete = (data, index) => () => {
    var temp = [...editValue];
    temp.splice(index, 1);
    setEditValue(temp);

    setDeleteItems([...deleteItems, data.id]);
  };

  const addTargetCustomer = (value) => {
    let dto = {
      customer: { id: value.id },
      label:
        (value.name ?? "") +
        " " +
        (value.surname ?? "") +
        " " +
        (value.email ?? "") +
        " " +
        (value.phone ?? ""),
      key: "cu_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetCustomerList = (value) => {
    let dto = {
      customerList: { id: value.id },
      label: (value.name ?? "") + " " + (value.description ?? ""),
      key: "cl_" + value.id,
    };

    addTarget(dto);
  };

  const addTarget = (newValue) => {
    newValue.coupon = { id: couponId };
    setEditValue([...editValue, newValue]);
    setNewTargets([...newTargets, newValue]);
  };

  const save = () => {
    if (newTargets.length !== 0) {
      setLoading(true);
      saveTargets(newTargets);
    }
    if (deleteItems.length !== 0) {
      deleteTargets(deleteItems);
    }
  };

  const saveTargets = (data) => {
    CouponService.saveTargets(
      [...data],
      (data) => {
        setNewTargets([]);
        onSave();
        setLoading(false);
      },
      errorCallback
    );
  };

  const deleteTargets = (data) => {
    CouponService.deleteTargets(
      [...data],
      (data) => {
        setDeleteItems([]);
        onSave();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("COUPON_SCOPE")}
          faOpen={open}
          faOnSubmit={save}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setNewTargets([]);
            setDeleteItems([]);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
            data-fa-section="COUPON_SCOPE_DIALOG"
          >
            <Grid item xs={12}>
              <Paper component="ul" className={classes.chipRoot}>
                {editValue?.map((data, index) => {
                  let icon;

                  if (data.customer) {
                    icon = <FontAwesomeIcon icon={faUserAlt} />;
                  }
                  if (data.customerList) {
                    icon = <FontAwesomeIcon icon={faUsers} />;
                  }
                  return (
                    <li key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.label}
                        onDelete={handleChipDelete(data, index)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </Paper>
              <div className={classes.buttonPanel}>
                <FaButton
                  faClick={() => {
                    setSectionCustomer(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_CUSTOMER")}
                </FaButton>
              </div>
              <div className={classes.buttonPanel}>
                <FaButton
                  faClick={() => {
                    setSectionCustomerList(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_CUSTOMER_LIST")}
                </FaButton>
              </div>
              {sectionCustomer ? (
                <div className={classes.hboxBlock}>
                  <CustomerAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetCustomer(data);
                      setSectionCustomer(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionCustomer(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
              {sectionCustomerList ? (
                <div className={classes.hboxBlock}>
                  <CustomerListAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetCustomerList(data);
                      setSectionCustomerList(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionCustomerList(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
}
