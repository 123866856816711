import { Card, CardContent, CardHeader } from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CUSTOMER } from "services/faApi";
import { smartTimeDiff } from "services/utils";
import i18n from "../../i18n";

export default function AccessLogCustomers(props) {
  const { visitorId } = props;

  const tableRef = useRef();
  const { t } = useTranslation("customer", { i18n });

  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "membershipDate",
      label: t("MEMBERSHIP_DATE"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "20%",
    },
    {
      field: "surname",
      label: t("SURNAME"),
      align: "center",
      width: "20%",
    },
    {
      field: "email",
      label: t("E-MAIL"),
      align: "center",
      width: "20%",
    },
    {
      field: "phone",
      label: t("PHONE"),
      align: "center",
      width: "15%",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
  ];

  const newSearchForm = () => {
    let result = {
      thing: null,
      thingId: null,
      membershipDateStart: null,
      membershipDateEnd: null,
      email: null,
      phone: null,
      accountId: accountId,
      name: null,
      surname: null,
      hashtag: null,
      recordType: null,
      status: null,
      description: null,
      visitorId: visitorId,
    };

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/customer/detail/${row.id}`,
      breadCrumbKey: `/customer/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <Card>
      <CardHeader
        title={t("ACCESS_LOG_DETAIL_CUSTOMERS")}
        data-fa-section="ACCESS_LOG_DETAIL_CUSTOMERS"
      />
      <CardContent></CardContent>
      <ThingsDataTable
        searchUrl={CUSTOMER.search + "?accountId=" + localStorage.accountId}
        searchForm={searchForm}
        queryParams={{
          page: 0,
          size: 10,
          sort: "membershipDate",
          isAsc: false,
        }}
        columns={columns}
        ref={tableRef}
        showHeaderText={false}
        faOnDoubleClick={onDoubleClick}
      />
    </Card>
  );
}
