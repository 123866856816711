import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "../../../i18n";
import PublicImageList from "./PublicImageList";
import PublicPhotos3 from "./PublicPhotos3";
import PublicSinglePhoto from "./PublicSinglePhoto";
import { useStyles } from "./style";

export default function PublicPhotoBrowser(props) {
  const { publicThing } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [imageList, setImageList] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);

  // single, imageList, carousel
  const [mode, setMode] = useState("single");

  const singleImageClick = () => {
    setMode("imageList");
  };

  const carouselImageClick = (imageList, index) => {
    setMode("carousel");
    setImageIndex(index);
    setImageList(imageList);
  };

  useEffect(() => {
    console.log({ mode });
  }, [mode]);

  return <>
    {mode === "single" && (
      <PublicSinglePhoto
        publicThing={publicThing}
        imageOnClick={singleImageClick}
      />
    )}
    {(mode === "imageList" || mode === "carousel") && (
      <Dialog open={true} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setMode("single");
            }}
            size="large">
            <ChevronLeft />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PublicImageList
            publicThing={publicThing}
            imageOnClick={carouselImageClick}
          />
        </DialogContent>
      </Dialog>
    )}
    {mode === "carousel" && (
      <Dialog open={true} aria-labelledby="form-dialog-title" fullScreen>
        <DialogTitle id="form-dialog-title">
          <IconButton
            aria-label="close"
            onClick={() => {
              setMode("imageList");
            }}
            size="large">
            <ChevronLeft />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PublicPhotos3
            publicThing={publicThing}
            index={imageIndex}
            imageList={imageList}
          />
        </DialogContent>
      </Dialog>
    )}
  </>;
}

PublicPhotoBrowser.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
