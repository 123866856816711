import { Avatar, Link } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { THINGCLASS } from "services/faApi";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingClassChildren(props) {
  const { thingClass } = props;

  let history = useHistory();
  const classes = useStyles();

  const { t } = useTranslation("thingClass", { i18n });
  const tableRef = useRef();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);
  const [first, setFirst] = useState(true);

  useEffect(() => {
    if (thingClass && accountId && thingClass.id && thingClass.id !== 0) {
      if (first) setFirst(false);
      else tableRef.current.resetPage();
    }
  }, [thingClass]);

  useEffect(() => {
    console.log({ dataUrl });
  }, [dataUrl]);

  const onDoubleClick = (row) => {};

  const goChild = (row) => {
    const tmpTo = {
      pathname: `${row.id}`,
      breadCrumbKey: `/thingClassDetail/:id`,
    };
    history.push(tmpTo);
    window.scrollTo(0, 0);
  };

  const columns = [
    {
      field: "className",
      label: t("GENERIC_CLASSNAME"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="icon"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{ width: "48px", marginRight: "10px" }}
              />{" "}
              <Link
                onClick={() => {
                  goChild(row);
                }}
                underline="hover">
                {value}
              </Link>
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "48px", marginRight: "10px" }}
              >
                {" "}
                {value?.charAt(0)}
              </Avatar>{" "}
              <Link
                onClick={() => {
                  goChild(row);
                }}
                underline="hover">
                {value}
              </Link>
            </div>
          );
      },
    },
    { field: "domain", label: t("DOMAIN"), align: "center", width: "30%" },
  ];

  return (
    <>
      <Card>
        <CardHeader
          title={isTNF(
            t("DERIVED_THING_CLASSES"),
            t("DERIVED_THING_CLASSES_T2C")
          )}
          data-fa-section="DERIVED_THING_CLASSES"
        />
      </Card>
      {thingClass && accountId && thingClass.id && thingClass.id !== 0 && (
        <ThingsDataTable
          dataUrl={
            THINGCLASS.findChildren +
            "?accountId=" +
            accountId +
            "&parentId=" +
            thingClass?.id
          }
          columns={columns}
          ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          showHeader={false}
          queryParams={{
            page: 0,
            size: 10,
            sort: "className",
            isAsc: true,
          }}
        />
      )}
    </>
  );
}
