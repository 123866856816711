import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

export const buttonStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));
