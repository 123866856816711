import { Button, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ThingTeamAutoComplete from "components/autocomplete/ThingTeamAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingTeamService } from "services/ThingTeamService";
import { getThingsPackage } from "services/utils";

import i18n from "../../i18n";
const ThingTeamEdit = forwardRef((props, ref) => {
  const { thing, onChange, onDelete, onClose, value, open } = props;
  const THINGS_PACKAGE = getThingsPackage();
  const { t } = useTranslation(["thingTeam", "thingDetail", THINGS_PACKAGE], {
    i18n,
  });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const [companyCheck, setCompanyCheck] = useState(false);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const accountId = localStorage.accountId;
  useEffect(() => {
    if (open) {
      setThingTeam(value == null ? newThingTeam() : { ...value });
    }
  }, [open]);

  const newThingTeam = () => {
    return {
      id: "",
      role: "",
      user: thing?.user,
      email: "",
      description: "",
      thing: thing,
    };
  };
  const [thingTeam, setThingTeam] = useState(newThingTeam());

  const handleChange = (prop) => (event) => {
    setThingTeam({ ...thingTeam, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!thingTeam.id ? t("SAVED") : t("EDITED"), "success");
    setThingTeam(newThingTeam());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };
  useEffect(() => {
    if (thingTeam?.user) {
      setCompanyCheck(false);
    } else if (!thingTeam?.user) {
      setCompanyCheck(true);
    } else {
      setCompanyCheck(false);
    }
  }, [thingTeam?.user]);
  const saveThingTeam = () => {
    setLoading(true);
    ThingTeamService.save(thingTeam, saveSuccess, saveError);
  };
  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };
  const thingTeamDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ThingTeamService.delete(
          thingTeam,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            onDelete();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Yeni Takım Elemanı Tanımlama"}
          faOpen={open}
          faOnSubmit={saveThingTeam}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setCompanyCheck(false);
          }}
          faMaxWidth="lg"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={companyCheck ? true : false}
                onChange={(event) => {
                  setCompanyCheck(event.target.checked);
                  event.target.checked
                    ? handleChangeGeneric(setThingTeam, thingTeam, "user", null)
                    : handleChangeGeneric(
                        setThingTeam,
                        thingTeam,
                        "email",
                        null
                      );
                }}
                name="recurring"
                color="primary"
              />
            }
            label={t("Dış Kullanıcı")}
          />

          {!companyCheck ? (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <ThingTeamAutoComplete
                  label={t("Takım Rolü")}
                  accountId={accountId}
                  value={thingTeam.role}
                  required
                  onChange={(data) => {
                    handleChangeGeneric(setThingTeam, thingTeam, "role", data);
                  }}
                />

                <UserAutoComplete
                  label={t("Takım Elemanı")}
                  value={thingTeam?.user}
                  accountId={thing?.account?.id}
                  onChange={(data) => {
                    handleChangeGeneric(
                      setThingTeam,
                      thingTeam,
                      "user",
                      data,
                      "email",
                      null
                    );
                  }}
                />
                <FaInput
                  faType="description"
                  label={t("Açıklama")}
                  value={thingTeam.description}
                  inputProps={{ maxLengt: 100 }}
                  onChange={handleChange("description")}
                />
              </Grid>
              {thingTeam.id > 0 && (
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button onClick={thingTeamDelete} color="secondary">
                    Sil
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <ThingTeamAutoComplete
                  label={t("Takım Rolü")}
                  accountId={accountId}
                  value={thingTeam.role}
                  required
                  onChange={(data) => {
                    handleChangeGeneric(setThingTeam, thingTeam, "role", data);
                  }}
                />

                <FaInput
                  faType="description"
                  label={t("Açıklama")}
                  value={thingTeam.description}
                  inputProps={{ maxLengt: 100 }}
                  onChange={handleChange("description")}
                />
                <FaInput
                  faType="email"
                  label={t("Email")}
                  value={thingTeam.email}
                  inputProps={{ maxLengt: 100 }}
                  onChange={handleChange("email")}
                />
              </Grid>
              {thingTeam.id > 0 && (
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button onClick={thingTeamDelete} color="secondary">
                    Sil
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default ThingTeamEdit;
