import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    backgroundColor: "green",
    paddingBottom: "5px",
    paddingTop: "3px",
    borderTop: "solid 1px #DDDDDD",
    zIndex: 20,
    marginLeft: -8,
    color: "white",
  },
  highlightClassCustom: {
    width: "143px !important",
    height: "125px !important",
  },
  sorunlarListClass: {
    minWidth: "830px !important",
    minHeight: "300px !important",
  },
  gorevlendirButonuClass: {
    minWidth: "640px !important",
    minHeight: "600px !important",
  },
  gorevOlusturDialogClass: {
    minWidth: "740px !important",
    minHeight: "400px !important",
  },
  gorevSorumlusuClass: {
    minWidth: "910px !important",
    minHeight: "400px !important",
  },
  sorunKapatma1Class: {
    minWidth: "830px !important",
    minHeight: "300px !important",
  },
  sorunKapatDialog1Class: {
    minWidth: "830px !important",
    minHeight: "500px !important",
  },
  sorunKapat2Class: {
    minWidth: "480px !important",
    minHeight: "300px !important",
  },
  sorunSolutionAddClass: {
    minWidth: "850px !important",
    minHeight: "300px !important",
  },
  sorunSolutionDialogClass: {
    minWidth: "1050px !important",
    minHeight: "450px !important",
  },
  parolaDegistirClass: {
    minWidth: "560px !important",
    minHeight: "400px !important",
  },
  temaVeDilSecClass: {
    minWidth: "450px !important",
    minHeight: "400px !important",
  },
  filtreThingSecimi: {
    minWidth: "680px !important",
    minHeight: "400px !important",
  },
  filtreIslemVeTarih: {
    minWidth: "630px !important",
    minHeight: "300px !important",
  },
  tumEtkilesimlerElement: {
    minWidth: "900px !important",
    minHeight: "400px !important",
  },
  issueDateSettings: {
    minWidth: "750px !important",
    minHeight: "300px !important",
  },
  issueListElement: {
    minWidth: "830px !important",
    minHeight: "350px !important",
  },
  suggestionListElement: {
    minWidth: "880px !important",
    minHeight: "350px !important",
  },
  indoorMapSelect: {
    minWidth: "600px !important",
    minHeight: "340px !important",
  },
  indoorMap: {
    minWidth: "820px !important",
    minHeight: "400px !important",
  },
  customerInteractionDashboard: {
    minWidth: "700px !important",
  },
  accountDetail: {
    minWidth: "700px !important",
  },
  accountUserDetail: {
    minWidth: "550px !important",
  },
}));
