import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Add, Close } from "@mui/icons-material";
import ThingsDataTable from "components/thingsDataTable";
import IndoorLocationKrokiHelp from "features/help/IndoorLocationKrokiHelp";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { LocationDetailService } from "../../services/LocationDetailService";
import { useStyles } from "./style";

export default function LocationDetailItems(props) {
  const { location } = props;

  const classes = useStyles();

  const { t } = useTranslation("location", { i18n });
  const tableRef = useRef();
  const faDialog = useDialog();
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [locationDetailList, setLocationDetailDetailList] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const newLocationDetail = () => {
    return {
      name: "",
      thumbnailData: "",
      location: { id: location.id },
    };
  };

  const [locationDetail, setLocationDetail] = useState(newLocationDetail());
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (location && accountId && location.id > 0) {
      refresh();
    }
  }, [location, accountId]);

  const refresh = () => {
    LocationDetailService.findByLocation(
      location.id,
      findByLocationOnSuccess,
      findByLocationOnError
    );
  };

  const findByLocationOnSuccess = (data) => {
    setLocationDetailDetailList(data.list);
  };

  const findByLocationOnError = (data) => {};

  const update = () => {
    LocationDetailService.update(
      locationDetail.id,
      locationDetail.name,
      files[0],
      saveOnSuccess,
      saveOnError
    );
  };

  const addLocationDetail = () => {
    LocationDetailService.uploadDocument(
      location.id,
      locationDetail.name,
      files[0],
      saveOnSuccess,
      saveOnError
    );
  };

  const saveOnSuccess = (data) => {
    refresh();
    setIsDialogOpen(false);
  };

  const saveOnError = (data) => {};

  const copyLocationDetail = (data) => {
    faDialog({
      description: "İç mekanı kopyalamak istediğinize emin misiniz??",
      type: "confirm",
    })
      .then((confirmation) => {
        LocationDetailService.createCopy(data, copyOnSuccess, copyOnError);
      })
      .catch((err) => console.log("error:", err));
  };
  const deletelocationDetail = (data) => {
    faDialog({
      description: t("Krokiyi silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        LocationDetailService.delete(data, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const copyOnSuccess = (data) => {
    refresh();
  };

  const copyOnError = (data) => {};
  const deleteOnSuccess = (data) => {
    refresh();
  };

  const deleteOnError = (data) => {};

  const handleChange = (prop, val) => {
    setLocationDetail({ ...locationDetail, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setLocationDetail(row);
    setIsDialogOpen(true);
  };

  const removeFileFromLocationDetail = () => {
    setLocationDetail({
      ...locationDetail,
      thumbnailData: null,
    });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("ADD_DETAIL")}
        faOnSubmit={() => {
          locationDetail?.id ? update() : addLocationDetail();
        }}
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <FaInput
              required
              faType="name"
              label={t("NAME")}
              value={locationDetail.name}
              onChange={(event) => {
                handleChange("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {locationDetail?.thumbnailData ? (
              <div style={{ width: "300px" }}>
                <img
                  alt={locationDetail.name}
                  src={`data:image/png;base64, ${locationDetail.thumbnailData}`}
                  style={{
                    width: "100%",
                    position: "relative",
                  }}
                />
                <IconButton
                  style={{ position: "absolute", right: "280px" }}
                  color="secondary"
                  onClick={removeFileFromLocationDetail}
                  size="large">
                  <Close />
                </IconButton>
              </div>
            ) : (
              <FaFileUpload
                maxFileSize={5000000}
                onChange={setFiles}
                initialFiles={files}
                filesLimit={1}
              />
            )}
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "40%",
    },
    {
      field: "thumbnailData",
      label: t("THUMBNAIL"),
      align: "center",
      width: "50%",
      valueFormatter: (value) => {
        if (value != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt=""
                src={`data:image/png;base64, ${value}`}
                style={{ width: "200px", marginRight: "10px" }}
              />{" "}
            </div>
          );
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "5%",
      valueFormatter: (value, row) => {
        return (
          <>
            <IconButton
              onClick={() => deletelocationDetail(row)}
              size="small"
              color="primary"
              aria-label={t("SIL")}
            >
              <FontAwesomeIcon icon={faTrash} />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "5%",
      valueFormatter: (value, row) => {
        return (
          <>
            <IconButton
              onClick={() => copyLocationDetail(value)}
              size="small"
              color="primary"
              aria-label={t("SIL")}
            >
              <FontAwesomeIcon icon={faCopy} />
            </IconButton>
          </>
        );
      },
    },
  ];

  const locationDetailAddButtonClicked = () => {
    setLocationDetail(newLocationDetail());
    setIsDialogOpen(true);
  };

  return <>
    {renderDialog()}
    <Card>
      <CardHeader
        action={
          <>
            <IndoorLocationKrokiHelp value="locationDetail.kroki" />
            <IconButton
              aria-label="settings"
              onClick={locationDetailAddButtonClicked}
              size="large">
              <Add />
            </IconButton>
          </>
        }
        title={t("LOCATION_MAPS")}
        data-fa-section="LOCATION_MAPS"
      />
    </Card>
    <ThingsDataTable
      data={locationDetailList}
      columns={columns}
      ref={tableRef}
      showDeleteButton={false}
      showHeaderText={false}
      headerText={t("LOCATION_MAPS")}
      showPaging={false}
      faOnDoubleClick={onDoubleClick}
      showHeader={false}
    />
  </>;
}
