import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Adsense from "components/adsense/Adsense";
import SystemAccountButtonGroup from "components/buttonGroup/SystemAccountButtonGroup";
import ThingClassHashtagButtonGroup from "components/buttonGroup/ThingClassHashtagButtonGroup";
import ThingClassHashtag from "components/hashtag/ThingClassHashtag";
import ThingsDataTable from "components/thingsDataTable";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { Suspense, useRef, useState } from "react";
import {
  FaAutoComplete,
  FaButton,
  FaDialog,
  FaInput,
  FaSearch,
} from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getThingsPackage, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { THINGCLASS } from "../../services/faApi";
import { ThingClassService } from "../../services/thingClass/thingClassService";

export default function ThingClass() {
  const tableRef = useRef();
  const THINGS_PACKAGE = getThingsPackage();
  const { t } = useTranslation(
    ["thingClass", "errorMessages", THINGS_PACKAGE],
    { i18n }
  );
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const labelClasses = labelStyles();

  const accountId = localStorage.accountId;
  const accountName = localStorage.accountName;

  const [loading, setLoading] = useState(false);
  const [isThingClassRegisterFormOpen, setIsThingClassRegisterFormOpen] =
    useState(false);
  const [subscriptionError, setSubscriptionError] = useState(false);

  const columns = [
    {
      field: "count",
      label: t("#"),
      align: "center",
      width: "2%",
    },
    {
      field: "className",
      label: isTNF(
        t("GENERIC_CLASSNAME", { ns: "thingClass" }),
        t("GENERIC_CLASSNAME_T2C", { ns: "thingClass" })
      ),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{ width: "48px", marginRight: "10px" }}
                alt="thumbnail"
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "48px", marginRight: "10px" }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "parent",
      label: t("PARENT"),
      align: "center",
      width: "30%",
      inner: "className",
      valueFormatter: (parent) => {
        if (parent) {
          return parent.className;
        }
      },
    },
    { field: "domain", label: t("DOMAIN"), align: "center", width: "28%" },
    {
      field: "id",
      label: t("ACCOUNT"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.account ? row.account.fullname : t("SYSTEM");
      },
    },
  ];
  const [thingClassDTO, setThingClassDTO] = useState({
    className: "",
    domain: "",
    parent: null,
  });

  const handleChange = (prop) => (event) => {
    setThingClassDTO({ ...thingClassDTO, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!thingClassDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsThingClassRegisterFormOpen(false);
    setThingClassDTO({
      id: "",
      className: "",
      domain: "",
      parent: null,
    });
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(t("errorMessages:" + data.message), "error");
    setLoading(false);
  };

  const saveThingClass = () => {
    setLoading(true);
    ThingClassService.save(
      {
        ...thingClassDTO,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      genericClassName: null,
      className: null,
      domain: null,
      accountId: accountId,
      systemType: null,
      domain: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingClassDetail/${row.id}`,
      breadCrumbKey: `/thingClassDetail/:id`,
    };
    history.push(tmpTo);
  };

  const newThingClassRegisterRender = () => {
    return (
      <>
        <FaDialog
          title={isTNF(t("EDIT_THINGCLASS"), t("EDIT_THINGCLASS_T2C"))}
          faOpen={isThingClassRegisterFormOpen}
          faOnSubmit={saveThingClass}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsThingClassRegisterFormOpen(data);
            setThingClassDTO({
              id: "",
              className: "",
              domain: "",
              parent: null,
            });
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12} data-fa-section="PARENT_CLASS">
              <FaAutoComplete
                faType="async"
                value={thingClassDTO.parent || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sort="className"
                faFilterProp="className"
                getOptionLabel={(option) => option.className}
                requestUrl={
                  THINGCLASS.findClassName + "?accountId=" + accountId
                }
                onChange={(event, newValue) => {
                  setThingClassDTO({
                    ...thingClassDTO,
                    parent: newValue,
                  });
                }}
                label={t("PARENT")}
              />
            </Grid>
            <Grid item xs={12} data-fa-section="GENERIC_CLASSNAME">
              <FaInput
                required
                maxLength={100}
                label={t("GENERIC_CLASSNAME")}
                onChange={handleChange("className")}
                value={thingClassDTO.className || ""}
              />
            </Grid>
            <Grid item xs={12} data-fa-section="HASHTAG">
              <ThingClassHashtag
                label={t("DOMAIN")}
                accountId={accountId}
                value={thingClassDTO.domain}
                onChange={(data) => {
                  setThingClassDTO({
                    ...thingClassDTO,
                    domain: data,
                  });
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  const onAddClick = () => {
    ThingClassService.checkAddNewThingClass(
      accountId,
      (data) => {
        if (data) {
          setIsThingClassRegisterFormOpen(true);
        } else {
          setSubscriptionError(true);
        }
      },
      (error) => {
        setSubscriptionError(true);
      }
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newThingClassRegisterRender()}
        {subscriptionError && (
          <FeatureNotAllowed
            open={subscriptionError}
            onClose={() => {
              setSubscriptionError(false);
            }}
          />
        )}
        <div>
          <Adsense value="RklmUst" />
        </div>
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item xs={4}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("ACCOUNT")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SystemAccountButtonGroup
                data-fa-section="SYSTEM_BUTTON_GROUP"
                label={accountName}
                value={searchForm.systemType}
                onChange={(data) => {
                  const temp = { ...searchForm, systemType: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Hashtag")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ThingClassHashtagButtonGroup
                  data-fa-section="HASHTAG_BUTTON_GROUP"
                  value={searchForm.domain}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      domain: data,
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  // style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={THINGCLASS.search + "?accountId=" + localStorage.accountId}
          exportUrl={THINGCLASS.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "count",
            accountId: localStorage.accountId,
          }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={onAddClick}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("GENERIC_CLASSNAME")}
                value={searchForm.className}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "className",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DOMAIN")}
                value={searchForm.domain}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "domain",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
