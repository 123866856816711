import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    divider: {
        width: "100%",
        marginTop: "1em",
        marginBottom: "1em"
    },
    listItemPrimary: {
        fontWeight:"bold",
        fontSize:"1.1em",
        letterSpacing:-0.5
    },
    listItemSecondary: {
        fontSize:"1.2em"
    },
    indirim: {
        color:theme.palette.secondary.dark
    }
}));