import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add, Clear, Notifications } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  CardHeader,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import BeforeAfterSelect from "components/select/BeforeAfterSelect";
import ReminderTypeSelect from "components/select/ReminderTypeSelect";
import TimePeriodSelect from "components/select/TimePeriodSelect";
import ThingsEventsHelp from "features/help/ThingsEventsHelp";
import React, { useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { ThingDynamicFormService } from "services/ThingDynamicFormService";
import { ThingEventService } from "services/ThingEventService";
import {
  getDateTimeWFormat,
  getDateWFormat,
  GetEventStartDate,
} from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./ThingDetailCardStyle";

export default function ThingEvents(props) {
  const { thing, isEditable } = props;
  const faDialog = useDialog();
  const labelClasses = labelStyles();
  const classes = useStyles();

  const { t } = useTranslation("thingDetail", { i18n });

  const newThingEvent = () => {
    return {
      id: 0,
      account: { id: thing?.account?.id },
      thing: { id: thing?.id },
      user: null,
      userGroup: null,
      dateType: "LITERAL",
      dynamicForm: null,
      eventDate: GetEventStartDate(),
      name: "",
      description: "",
      status: "ACTIVE",
      reminders: [newReminder()],
    };
  };

  const newReminder = () => {
    return {
      value: 30,
      timePeriod: "MINUTE",
      reminderType: "NOTIFICATION",
      beforeAfter: "BEFORE",
      status: "ACTIVE",
    };
  };

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [thingEvents, setThingEvents] = useState([]);
  const [thingEvent, setThingEvent] = useState(newThingEvent());

  const [dynamicFormList, setDynamicFormList] = useState(null);
  const [dynamicFormData, setDynamicFormData] = useState(null);
  const [showAction, setShowAction] = useState(false);

  useEffect(() => {
    if (thing && thing.id) {
      reloadThingEvents();
      setThingEvent(newThingEvent());

      ThingDynamicFormService.findForm(
        thing.account.id,
        thing?.thingClass?.id,
        (data) => {
          setDynamicFormList(data);
          ThingDynamicFormService.findByObjectId(
            thing.id,
            (data2) => {
              setDynamicFormData(data2);
            },
            (error) => {}
          );
        },
        (error) => {}
      );
    }
  }, [thing]);

  useEffect(() => {
    if (isEditDialogOpen) {
      ThingDynamicFormService.findForm(
        thing.account.id,
        thing.thingClass.id,
        (data) => {
          setDynamicFormList(data);
          ThingDynamicFormService.findByObjectId(
            thing.id,
            (data2) => {
              setDynamicFormData(data2);
            },
            (error) => {}
          );
        },
        (error) => {}
      );
    }
  }, [isEditDialogOpen]);

  const reloadThingEvents = () => {
    ThingEventService.findEvents(
      thing.id,
      findThingEventsOnSuccess,
      findThingEventsOnError
    );
  };

  const findThingEventsOnSuccess = (data) => {
    setThingEvents(data);
  };

  const findThingEventsOnError = () => {};

  const saveEvent = () => {
    let tmp = null;
    if (thingEvent.dateType === "LITERAL")
      tmp = { ...thingEvent, dynamicForm: null };
    else tmp = { ...thingEvent, eventDate: null };
    ThingEventService.save(tmp, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = () => {
    reloadThingEvents();
    setIsEditDialogOpen(false);
  };

  const saveOnError = () => {};

  const handleChangeV = (prop, val) => {
    setThingEvent({ ...thingEvent, [prop]: val });
  };

  const deleteLink = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      ThingEventService.delete(item, deleteOnSuccess, deleteOnError);
    });
  };

  const deleteOnSuccess = (data) => {
    reloadThingEvents();

    setIsDetailDialogOpen(false);
  };

  const deleteOnError = (data) => {};

  const deleteReminder = (index) => {
    var temp = [...thingEvent.reminders];
    temp.splice(index, 1);
    setThingEvent({ ...thingEvent, reminders: temp });
  };

  const addReminder = () => {
    let newReminder = {
      value: 10,
      timePeriod: "MINUTE",
      reminderType: "NOTIFICATION",
      status: "ACTIVE",
    };

    setThingEvent({
      ...thingEvent,
      reminders: [...thingEvent.reminders, newReminder],
    });
  };

  const renderReminders = () => {
    return thingEvent?.reminders?.map((reminder, index) => (
      <>
        <Grid item xs={2} key={"p1" + index}>
          <FaInput
            label={t("Süre")}
            value={reminder.value}
            onChange={(event) => {
              let row = thingEvent.reminders[index];
              row = { ...row, value: event.target.value };
              let remindersTmp = [...thingEvent.reminders];
              remindersTmp[index] = row;
              setThingEvent({ ...thingEvent, reminders: [...remindersTmp] });
            }}
            style={{ minHeight: "53px" }}
          />
        </Grid>
        <Grid item xs={3} key={"p2" + index}>
          <TimePeriodSelect
            value={reminder.timePeriod}
            onChange={(data) => {
              let row = thingEvent.reminders[index];
              row = { ...row, timePeriod: data };
              let remindersTmp = [...thingEvent.reminders];
              remindersTmp[index] = row;
              setThingEvent({ ...thingEvent, reminders: [...remindersTmp] });
            }}
          />
        </Grid>
        <Grid item xs={2} key={"p3" + index}>
          <BeforeAfterSelect
            value={reminder.beforeAfter}
            onChange={(data) => {
              let row = thingEvent.reminders[index];
              row = { ...row, beforeAfter: data };
              let remindersTmp = [...thingEvent.reminders];
              remindersTmp[index] = row;
              setThingEvent({ ...thingEvent, reminders: [...remindersTmp] });
            }}
          />
        </Grid>
        <Grid item xs={3} key={"p4" + index}>
          <ReminderTypeSelect
            value={reminder.reminderType}
            onChange={(data) => {
              let row = thingEvent.reminders[index];
              row = { ...row, reminderType: data };
              let remindersTmp = [...thingEvent.reminders];
              remindersTmp[index] = row;
              setThingEvent({ ...thingEvent, reminders: [...remindersTmp] });
            }}
          />
        </Grid>
        <Grid item xs={2} key={"p5" + index}>
          {t("ile hatırlat")}{" "}
          <IconButton
            onClick={() => {
              deleteReminder(index);
            }}
            size="large"
          >
            <Clear />
          </IconButton>
        </Grid>
      </>
    ));
  };

  const getDynamicFormValue = () => {
    if (thingEvent.dynamicForm) {
      let selectedData = dynamicFormData.detailData.find(
        (x) => x.dynamicFormId === thingEvent.dynamicForm.id
      );
      if (selectedData) {
        if (thingEvent.dynamicForm.dataType === "DATE_TIME")
          return getDateTimeWFormat(selectedData.dataDate);
        else if (thingEvent.dynamicForm.dataType === "DATE")
          return getDateWFormat(selectedData.dataDate);
      }
    }
    return null;
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("HATIRLATICI_OLUSTUR")}
        faOpen={isEditDialogOpen}
        faOnSubmit={saveEvent}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
        faMaxWidth="md"
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
          data-fa-section="EVENT_DIALOG"
        >
          <Grid item xs={6}>
            <FaInput
              label={t("NAME")}
              onChange={(event) => handleChangeV("name", event.target.value)}
              value={thingEvent.name || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <FaInput
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={thingEvent.description || ""}
            />
          </Grid>
          <Grid item xs={6}>
            <UserAndGroupAutoComplete
              value={{ user: thingEvent.user, userGroup: thingEvent.userGroup }}
              accountId={thing?.account?.id}
              onChange={(data) => {
                setThingEvent({
                  ...thingEvent,
                  user: data.user ? data.user : null,
                  userGroup: data.userGroup ? data.userGroup : null,
                });
              }}
              label={t("USER")}
              required={true}
            />
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={12}>
            <RadioGroup
              value={thingEvent.dateType}
              onChange={(event) => {
                handleChangeV("dateType", event.target.value);
              }}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={2}>
                  <FormControlLabel
                    value="LITERAL"
                    control={<Radio />}
                    label={t("DATE_TYPE_LITERAL")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FaDatePicker
                    faType="dateTime"
                    label={t("EVENT_DATE")}
                    disabled={thingEvent.dateType !== "LITERAL"}
                    style={{ minHeight: 65 }}
                    value={thingEvent.eventDate}
                    faOnChange={(val) => {
                      handleChangeV("eventDate", val);
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>

                <Grid item xs={2}>
                  <FormControlLabel
                    value="DYNAMIC_FIELD"
                    control={<Radio />}
                    label={t("DATE_TYPE_DYNAMIC_FIELD")}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("DYNAMIC_FIELD")}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      disabled={thingEvent.dateType !== "DYNAMIC_FIELD"}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={thingEvent.dynamicForm}
                      onChange={(event) => {
                        handleChangeV("dynamicForm", event.target.value);
                      }}
                      label={t("DYNAMIC_FIELD")}
                      size="small"
                    >
                      {dynamicFormList
                        ?.filter(
                          (x) =>
                            x.dataType === "DATE_TIME" || x.dataType === "DATE"
                        )
                        .map((item) => (
                          <MenuItem value={item}>{item.label}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  {getDynamicFormValue()}
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>

          <Grid item xs={10}>
            <Notifications style={{ paddingRight: 5 }} />
            {t("NOTIFICATIONS")}
          </Grid>
          <Grid item xs={2}></Grid>
          {renderReminders()}
          <Grid item xs={2}>
            <Button onClick={addReminder} color="primary">
              <Add />
              {t("ADD")}
            </Button>
          </Grid>
          <Grid item xs={10}></Grid>
        </Grid>
      </FaDialog>
    );
  };
  return (
    <>
      {renderEditDialog()}
      <Card
        style={{ minHeight: 100, overflowY: "auto" }}
        className="fai-thingLinks"
      >
        <CardHeader
          action={
            <>
              <ThingsEventsHelp value="thingDetail.thingsEvents" />
              {isEditable && (
                <IconButton
                  data-fa-button="EVENT_EDIT_BUTTON"
                  aria-label="settings"
                  onClick={() => {
                    setThingEvent(newThingEvent());
                    setIsEditDialogOpen(true);
                  }}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              )}
            </>
          }
          title={t("REMINDERS")}
          data-fa-section="REMINDERS"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {thingEvents?.map((event, index) => (
                    <TableRow
                      key={index}
                      onMouseEnter={() => setShowAction(true)}
                      onMouseLeave={() => setShowAction(false)}
                    >
                      <TableCell className={labelClasses.LabelValue}>
                        {event.name}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {event.description}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {getDateTimeWFormat(event.eventDate)}
                        {event.dynamicForm?.label}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {event?.user?.fullname}
                        {event?.userGroup?.name}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {event.reminders.map((reminder, index2) => {
                          return (
                            reminder.value +
                            " " +
                            t(reminder.timePeriod) +
                            " " +
                            t(reminder.beforeAfter) +
                            " " +
                            t(reminder.reminderType)
                          );
                        })}
                      </TableCell>
                      <TableCell className={labelClasses.LabelValue}>
                        {t(event.status)}
                      </TableCell>
                      <TableCell align="right">
                        {showAction && isEditable ? (
                          <IconButton
                            onClick={() => {
                              setThingEvent(event);
                              setIsEditDialogOpen(true);
                            }}
                            size="small"
                            color="primary"
                            aria-label={t("EDIT")}
                            className={classes.ActionButton}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </IconButton>
                        ) : (
                          <div className={classes.ActionButtonPlaceHolder} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
