import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ReminderTypeSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100}>
      <InputLabel>{t("REMINDER_TYPE")}</InputLabel>
      <Select
        variant="outlined"
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("REMINDER_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"NOTIFICATION"}>
          {t("REMINDER_TYPE_NOTIFICATION")}
        </MenuItem>
        <MenuItem value={"EMAIL"}>{t("REMINDER_TYPE_EMAIL")}</MenuItem>
        <MenuItem value={"SMS"}>{t("REMINDER_TYPE_SMS")}</MenuItem>
      </Select>
    </FormControl>
  );
}
