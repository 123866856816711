import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import UserDetail from "components/detail/UserDetail";
import { default as React, Suspense, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function OrganizationDetail(props) {
  const { organization, onSave, onDelete } = props;
  const { t } = useTranslation("organization", { i18n });
  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [organizationForm, setOrganizationForm] = useState(null);

  const handleChange = (prop) => (event) => {
    setOrganizationForm({ ...organization, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setOrganizationForm({ ...organization, [prop]: value });
  };

  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={"Organizasyon Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={() => {
            onSave(organizationForm);
            setIsEditFormOpen(false);
          }}
          //loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
          }}
        >
          {organizationForm?.parent ? (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <OrganizationAutoComplete
                  label="Üst Organizasyon"
                  required={true}
                  value={organizationForm?.parent}
                  accountId={accountId}
                  onChange={(data) => {
                    if (!organizationForm?.parent) {
                      setOrganizationForm({
                        ...organizationForm,
                        parent: data,
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("NAME")}
                  onChange={handleChange("name")}
                  value={organizationForm?.name || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  maxLength={100}
                  label={t("ADDRESS")}
                  onChange={handleChange("address")}
                  value={organizationForm?.address || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <UserAutoComplete
                  value={organizationForm?.responsibleUser}
                  accountId={accountId}
                  powerUser={true}
                  onChange={(data) => {
                    handleChangeV("responsibleUser", data);
                  }}
                  label={t("Organizasyon Sorumlusu")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={organizationForm?.administrativeUnit}
                      onChange={(event) => {
                        setOrganizationForm({
                          ...organizationForm,
                          administrativeUnit: event.target.checked,
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={t("Yönetim Birimi")}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setIsEditFormOpen(false);
                    onDelete(organization);
                  }}
                  color="secondary"
                >
                  Sil
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <FaInput
                  required
                  maxLength={100}
                  label={t("NAME")}
                  onChange={handleChange("name")}
                  value={organizationForm?.name || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  maxLength={100}
                  label={t("ADDRESS")}
                  onChange={handleChange("address")}
                  value={organizationForm?.address || ""}
                />
              </Grid>
              <Grid item xs={12}>
                <UserAutoComplete
                  value={organizationForm?.responsibleUser}
                  accountId={accountId}
                  powerUser={true}
                  onChange={(data) => {
                    handleChangeV("responsibleUser", data);
                  }}
                  label={t("Organizasyon Sorumlusu")}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={organizationForm?.administrativeUnit}
                      onChange={(event) => {
                        setOrganizationForm({
                          ...organizationForm,
                          administrativeUnit: event.target.checked,
                        });
                      }}
                      color="primary"
                    />
                  }
                  label={t("Yönetim Birimi")}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    setIsEditFormOpen(false);
                    onDelete(organization);
                  }}
                  color="secondary"
                >
                  Sil
                </Button>
              </Grid>
            </Grid>
          )}
        </FaDialog>
      </>
    );
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {renderEditForm()}
      <Card style={{ minHeight: 100 }}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              disabled={!organization}
              onClick={() => {
                setIsEditFormOpen(true);
                setOrganizationForm({
                  ...organization,
                });
              }}
              size="large">
              <EditIcon />
            </IconButton>
          }
          title={t("ORGANIZATION_DETAIL")}
          data-fa-section="ORGANIZATION_DETAIL"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("NAME")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {organization?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("ADDRESS")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {organization?.address}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Organizasyon Sorumlusu")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    <UserDetail
                      value={organization?.responsibleUser}
                      type="USER"
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Yönetim Birimi")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {organization?.administrativeUnit === true ? "Evet" : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Suspense>
  </>;
}
