import { Grid, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getCurrentLang,
  getDateWFormat,
} from "react-base-fa/dist/services/utils";
import { ThingService } from "services/thing/thingService";
import {
  getDateTimeWFormat,
  GetFileByLanguage,
  GetFileCount,
  getTimeWFormat,
} from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import MultipleFileDisplay from "./MultipleFileDisplay";

export default function DynamicFieldsDetail(props) {
  const labelClasses = labelStyles();
  const { details, data, selectedDetails } = props;
  const [values, setValues] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [isMultipleFileDisplayOpen, setIsMultipleFileDisplayOpen] =
    useState(false);

  const lang = getCurrentLang().split("-")[0];

  const findDataByDynamicFormId = (dynamicFormId) => {
    var result = data.find((obj) => {
      return obj.dynamicFormId === dynamicFormId;
    });
    if (!result) {
      result = {
        dynamicFormId: dynamicFormId,
        dataString: "",
        dataNumeric: "",
        dataDate: null,
        dataBool: null,
      };
    }
    return result;
  };

  useEffect(() => {
    let detailData = [];
    selectedDetails.map((detail) => {
      let fieldData = findDataByDynamicFormId(detail.id);
      detailData.push(fieldData);
      return null;
    });
    setValues(detailData);
  }, [details, data, selectedDetails]);

  const downloadDocument = (fileId) => {
    ThingService.downloadDocument(
      props.objectId,
      fileId,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    //const url = window.URL.createObjectURL(new Blob([data.content]));
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const openLink = (url) => {
    window.open(url, "_blank").focus();
  };

  const renderField = (field, index) => {
    if (field.dataType === "STRING") {
      return values[index]?.dataString ? values[index].dataString : "";
    } else if (field.dataType === "NUMERIC") {
      return values[index]?.dataNumeric || values[index]?.dataNumeric === 0
        ? values[index].dataNumeric
        : "";
    } else if (field.dataType === "MONEY") {
      return values[index]?.dataNumeric || values[index]?.dataNumeric === 0
        ? values[index].dataNumeric
        : "";
    } else if (field.dataType === "DATE") {
      return values[index]?.dataDate
        ? getDateWFormat(values[index].dataDate)
        : "";
    } else if (field.dataType === "DATE_TIME") {
      return values[index]?.dataDate
        ? getDateTimeWFormat(values[index].dataDate)
        : "";
    } else if (field.dataType === "TIME") {
      return values[index]?.dataDate
        ? getTimeWFormat(values[index].dataDate)
        : "";
    } else if (field.dataType === "TEXT") {
      return values[index]?.dataString ? values[index].dataString : "";
    } else if (field.dataType === "BOOLEAN") {
      return !!values[index]?.dataBool;
    } else if (field.dataType === "YES_NO") {
      return values[index]?.dataBool == null
        ? ""
        : !!values[index]?.dataBool
        ? field.yesLabel
        : field.noLabel;
    } else if (field.dataType === "ENUMS") {
      return values[index]?.dataString ? values[index].dataString : "";
    } else if (field.dataType === "JSON") {
      return values[index]?.dataString ? values[index].dataString : "";
    } else if (field.dataType === "FILE") {
      if (values[index]?.dataFile) {
        return (
          <Link
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              downloadDocument(values[index]?.dataFile);
            }}
            underline="hover">
            {values[index].dataString}
          </Link>
        );
      } else if (
        field.defaultFileValue &&
        GetFileByLanguage(field.defaultFileValue, lang)
      ) {
        const file = GetFileByLanguage(field.defaultFileValue, lang);
        return (
          <Link
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              if (GetFileCount(field.defaultFileValue) > 1) {
                setIsMultipleFileDisplayOpen(true);
                setSelectedDetail(field);
              } else downloadDocument(file.value);
            }}
            underline="hover">
            {file.label ?? "İndir"}
          </Link>
        );
      } else {
        return "";
      }
    } else if (field.dataType === "LINK") {
      let linkValue =
        values[index]?.dataString || values[index]?.dataString === ""
          ? field.defaultStringValue
          : values[index]?.dataString;
      return linkValue ? (
        <Link
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            openLink(linkValue);
          }}
          underline="hover">
          {linkValue}
        </Link>
      ) : (
        ""
      );
    }
  };

  const renderDetails = selectedDetails.map((item, index) => (
    <Grid
      key={index}
      item
      xs={12}
      className={
        index === selectedDetails.length - 1
          ? labelClasses.LabelLastRow
          : labelClasses.LabelRow
      }
    >
      <Typography className={labelClasses.LabelHeader}>{item.label}</Typography>
      <Typography className={labelClasses.LabelValue}>
        {renderField(item, index)}
      </Typography>
    </Grid>
  ));

  return (
    <>
      {renderDetails}
      <MultipleFileDisplay
        value={selectedDetail}
        thingId={props.objectId}
        open={isMultipleFileDisplayOpen}
        onClose={() => {
          setIsMultipleFileDisplayOpen(false);
        }}
      />
    </>
  );
}
