import Grid from "@mui/material/Grid";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import { default as React, forwardRef, useImperativeHandle, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const SolutionDetailEdit = forwardRef((props, ref) => {
  
  const { solution, onSave } = props;
  const { t } = useTranslation("solutions", { i18n });
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsSolutionRegisterFormOpen(true);
    },
  }));


  const [isSolutionRegisterFormOpen, setIsSolutionRegisterFormOpen] =
    useState(false);
  const [solutionDTO, setSolutionDTO] = useState({...solution});

  const saveSolution = () => {
    onSave(solutionDTO);
    setIsSolutionRegisterFormOpen(false);
  };

  const handleChange = (prop, value) => {
    setSolutionDTO({ ...solutionDTO, [prop]: value });
  };

  return (
    <FaDialog
      title={"Servis Operasyon Düzenle"}
      faOpen={isSolutionRegisterFormOpen}
      faOnSubmit={saveSolution}
      loading={loading}
      faMaxWidth="lg"
      showSaveButton
      faHandleClose={(data) => {
        setIsSolutionRegisterFormOpen(false);
      }}
    >
      <Grid item container justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <ThingAutoComplete
            required={true}
            value={solutionDTO.thing}
            accountId={accountId}
            onChange={(data) => {
              setSolutionDTO({
                ...solutionDTO,
                thing: data,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaDatePicker
            faType="dateTime"
            label={t("NOTIFICATION_DATE")}
            style={{ minHeight: 65 }}
            value={solutionDTO.notificationDate}
            onChange={(value) => {
              handleChange("notificationDate", value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <UserAutoComplete
            value={solutionDTO.user}
            required={true}
            accountId={accountId}
            onChange={(data) => {
              setSolutionDTO({
                ...solutionDTO,
                user: data,
              });
            }}
            label={t("USER")}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            onChange={(event) => {
              handleChange("description", event.target.value);
            }}
            value={solutionDTO.description || ""}
          />
          <FaInput
                label={t("LABOR_TIME_MINUTES")}
                faType="number"
                onChange={(event) => {
                  handleChange("laborTime", event.target.value);
                }}
                value={solutionDTO.laborTime || ""}
              />
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default SolutionDetailEdit;
