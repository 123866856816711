import {
  faApple,
  faFacebookF,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { unwrapResult } from "@reduxjs/toolkit";
import { THINGS_ENVIRONMENT } from "environments/Environments";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import AppleLogin from "react-apple-login";
import {
  FaButton,
  FaCopyright,
  FaInput,
  FaUnAuthPages,
} from "react-base-fa/dist/fa";
import { clearArr } from "react-base-fa/dist/fa/faBreadCrumb/breadCrumbSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  getLoading,
  loginThunk,
  setAccountId,
  setAccountName,
  setAccountTimezone,
  setAccounts,
  setAuth,
  setGroups,
  setOrganizations,
  setRole,
  setUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import FacebookLoginCustomCSS from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getThingsApp } from "services/utils";
import { APP, LOCALSTORAGE } from "services/utils/Constants";
import i18n from "../../i18n";
import { AUT, USER } from "../../services/faApi";
import MobileStoreButton from "./MobileStoreButton";
import { useStyles } from "./style";
import ThingsUnAuthPages from "./ThingsUnAuthPages";

export default function LoginBase(props) {
  const {
    appLogo,
    requestUrl,
    align,
    backGroundImage,
    signUpPath,
    fgPasswordPath,
    usernameLogin,
    userPhoneNumberLogin,
  } = props;

  useEffect(() => {
    dispatch(setAuth(false));
    dispatch(clearArr());
    dispatch(setAuth(false));
    dispatch(setUserInfo(null));
    dispatch(setRole(null));
    dispatch(setAccountId(null));
    dispatch(setAccountName(null));
    dispatch(setAccountTimezone(null));
    dispatch(setAccounts(null));
    dispatch(setOrganizations(null));
    dispatch(setGroups(null));
    //FIXME kontrol diğer resetlenecek alanlar
    //localStorage.clear();
  }, []);

  const classes = useStyles();
  const { t } = useTranslation(["login", "errorMessages"], { i18n });
  const dispatch = useDispatch();
  let history = useHistory();
  const sessionLoading = useSelector(getLoading);
  let formRef = createRef();

  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.0/8 are considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  const [values, setValues] = useState({
    email: isLocalhost ? "koray.bastug@intellectia-cs.com" : "",
    username: "",
    password: isLocalhost ? "Pp12345678!." : "",
  });

  const [showSuggestSignup, setShowSuggestSignup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  async function logIn() {
    try {
      const res = unwrapResult(
        await dispatch(
          loginThunk(
            requests.getSimplePostRequest(requestUrl, {
              username: values.email,
              password: values.password,
            })
          )
        )
      );
      if (res && res.ERROR) {
        switch (res.ERROR) {
          case "USER_NOT_ENABLED":
            setErrorMessage(t("errorMessages:USER_NOT_ENABLED"));
            setShowError(true);
            break;
          case "USER_NOT_FOUND":
            setErrorMessage(t("errorMessages:USER_NOT_FOUND"));
            setShowError(true);
            break;
          case "USER_ACCOUNT_EXPIRED":
            setErrorMessage(t("errorMessages:USER_ACCOUNT_EXPIRED"));
            setShowError(true);
            break;
          case "USER_ACCOUNT_LOCKED":
            setErrorMessage(t("errorMessages:USER_ACCOUNT_LOCKED"));
            setShowError(true);
            break;
          case "USER_CREDENTIALS_EXPIRED":
            setErrorMessage(t("errorMessages:USER_CREDENTIALS_EXPIRED"));
            setShowError(true);
            break;
          case "WRONG_PASSWORD":
            setErrorMessage(t("errorMessages:WRONG_PASSWORD"));
            setShowError(true);
            break;
          default:
            break;
        }
      } else {
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());
        history.push("/");
      }
    } catch (err) {
      console.log("error", `Fetch failed: ${err.message}`);
      console.log(err);
      switch (err && err.response && err.response.status) {
        case 400:
          showErrorMessage(err.response);
          break;
        default:
          break;
      }
    }
  }

  const showErrorMessage = (err) => {
    let message;
    if (err.data.ERROR.includes("validationError")) {
      const errorObj = JSON.parse(err.data.ERROR);
      message = errorObj.validationError.map((error) => {
        return (
          <p className={classes.errorMessage}>
            {" "}
            <strong>{error.fieldName}</strong> {error.errorMessage}
          </p>
        );
      });
    } else {
      message = t("errorMessages:" + err.data.ERROR);
    }
    setErrorMessage(message);
    setShowError(true);
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const responseFacebookHandler = (thingsResponse, facebookResponse) => {
    console.log("responseFacebookHandler thingsResponse->", thingsResponse);
    console.log("responseFacebookHandler facebookResponse->", facebookResponse);

    if (thingsResponse) {
      if (thingsResponse.status === 302) {
        logInSocialMedia(facebookResponse.email);
      } else if (thingsResponse.status === 404) {
        showErrorMessage({
          data: {
            ERROR: t("errorMessages:FACEBOOK_USER_NOT_FOUND"),
          },
        });
        setShowSuggestSignup(true);
      }
    }
  };

  const responseFacebook = (facebookResponse) => {
    console.log("Facebook response->", facebookResponse);

    let req = requests.getSimpleGetRequest(
      USER.findByFacebookId + "/" + facebookResponse.id
    );
    console.log("findByFacebookId req->", req);
    FaAxios(req)
      .then((thingsResponse) => {
        responseFacebookHandler(thingsResponse, facebookResponse);
      })
      .catch((e) => {
        responseFacebookHandler(e.response, facebookResponse);
      });
  };

  const responseGoogleHandler = (thingsResponse, googleResponse) => {
    console.log("responseGoogleHandler thingsResponse->", thingsResponse);
    console.log("responseGoogleHandler googleResponse->", googleResponse);
    if (thingsResponse && googleResponse && googleResponse.profileObj) {
      if (thingsResponse.status === 302) {
        logInSocialMedia(googleResponse.profileObj.email);
      } else if (thingsResponse.status === 404) {
        showErrorMessage({
          data: {
            ERROR: t("errorMessages:GOOGLE_USER_NOT_FOUND"),
          },
        });
        setShowSuggestSignup(true);
      }
    }
  };

  const responseGoogle = (googleResponse) => {
    console.log("Google response->", googleResponse);

    if (googleResponse && googleResponse.googleId) {
      let req = requests.getSimpleGetRequest(
        USER.findByGoogleId + "/" + googleResponse.googleId
      );
      console.log("findByGoogleId req->", req);
      FaAxios(req)
        .then((thingsResponse) => {
          console.log("findByGoogleId res->", thingsResponse);
          responseGoogleHandler(thingsResponse, googleResponse);
        })
        .catch((e) => {
          responseGoogleHandler(e.response, googleResponse);
        });
    }
  };

  async function logInSocialMedia(email) {
    try {
      unwrapResult(
        await dispatch(
          loginThunk(
            requests.getSimplePostRequest(AUT.login, {
              username: email,
              password: "gecicisifre", //FIXME
            })
          )
        )
      );
      dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
      dispatch(clearArr());
      history.push("/");
    } catch (err) {
      console.log("error", `Fetch failed: ${err.message}`);
      console.log(err);
      showErrorMessage({
        data: {
          ERROR: t("errorMessages:LOGIN_ERROR"),
        },
      });
      switch (err && err.response && err.response.status) {
        case 400:
          console.log(err.response);
          break;
        default:
          break;
      }
    }
  }

  const appleResponse = async (data) => {
    console.log("appleResponse", data);
    console.log("authorization", data?.authorization);

    console.log("state", data?.authorization?.state);
    console.log("code", data?.authorization?.code);
    console.log("id_token", data?.authorization?.id_token);

    console.log("user", data?.user);
    console.log("email", data?.user?.email);
    console.log("name", data?.user?.name);
    console.log("firstName", data?.user?.name?.firstName);
    console.log("lastName", data?.user?.name?.lastName);

    if (data?.authorization) {
      try {
        const loginData = {
          state: data?.authorization?.state,
          code: data?.authorization?.code,
          idToken: data?.authorization?.id_token,
          email: data?.user?.email,
          firstName: data?.user?.name?.firstName,
          lastName: data?.user?.name?.lastName,
        };

        const res = unwrapResult(
          await dispatch(
            loginThunk(
              requests.getSimplePostRequest(USER.appleLogin, loginData)
            )
          )
        );
        if (res && res.ERROR) {
          switch (res.ERROR) {
            case "USER_NOT_ENABLED":
              setErrorMessage(t("errorMessages:USER_NOT_ENABLED"));
              setShowError(true);
              break;
            case "USER_NOT_FOUND":
              setErrorMessage(t("errorMessages:USER_NOT_FOUND"));
              setShowError(true);
              setShowSuggestSignup(true);
              break;
            case "USER_ACCOUNT_EXPIRED":
              setErrorMessage(t("errorMessages:USER_ACCOUNT_EXPIRED"));
              setShowError(true);
              break;
            case "USER_ACCOUNT_LOCKED":
              setErrorMessage(t("errorMessages:USER_ACCOUNT_LOCKED"));
              setShowError(true);
              break;
            case "USER_CREDENTIALS_EXPIRED":
              setErrorMessage(t("errorMessages:USER_CREDENTIALS_EXPIRED"));
              setShowError(true);
              break;
            case "WRONG_PASSWORD":
              setErrorMessage(t("errorMessages:WRONG_PASSWORD"));
              setShowError(true);
              break;
            case "USER_SOCIAL_MEDIA_INTEGRATION_ERROR":
              setErrorMessage(
                t("errorMessages:USER_SOCIAL_MEDIA_INTEGRATION_ERROR")
              );
              setShowError(true);
              break;
            default:
              break;
          }
        } else {
          dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
          dispatch(clearArr());
          history.push("/");
        }
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
        console.log(err);
        switch (err && err.response && err.response.status) {
          case 400:
            showErrorMessage(err.response);
            setShowSuggestSignup(true);
            break;
          default:
            break;
        }
      }
    }
  };

  const socialButtons = [
    {
      show: THINGS_ENVIRONMENT.showGoogleButton,
      content: (
        <GoogleLogin
          clientId="241190904512-ndpmk4v1fda8p4dhpfncpnq44mjkchjh.apps.googleusercontent.com"
          render={(renderProps) => (
            <Tooltip title={t("GOOGLE")}>
              <span>
                <Button
                  variant="outlined"
                  className={classes.googleButton}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<FontAwesomeIcon icon={faGoogle} />}
                />
              </span>
            </Tooltip>
          )}
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
        />
      ),
    },
    {
      show: THINGS_ENVIRONMENT.showFacebookButton,
      content: (
        <FacebookLoginCustomCSS
          appId="933568497435478"
          fields="name,email,picture"
          callback={responseFacebook}
          render={(renderProps) => (
            <Tooltip title={t("FACEBOOK")}>
              <Button
                variant="outlined"
                onClick={renderProps.onClick}
                className={classes.facebookButton}
                startIcon={<FontAwesomeIcon icon={faFacebookF} />}
              />
            </Tooltip>
          )}
        />
      ),
    },
    {
      show: THINGS_ENVIRONMENT.showAppleButton,
      content: (
        <AppleLogin
          clientId="app.thngs"
          redirectURI="https://thngs.app/sign-up"
          usePopup={true}
          callback={appleResponse}
          scope="email name"
          responseMode="query"
          render={(renderProps) => (
            <Tooltip title={t("Apple ile devam et")}>
              <span>
                <Button
                  variant="outlined"
                  className={classes.appleButton}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<FontAwesomeIcon icon={faApple} />}
                />
              </span>
            </Tooltip>
          )}
        />
      ),
    },
  ];

  // Aktif olan butonları filtreleyin
  const activeSocialButtons = socialButtons.filter((button) => button.show);
  const gridSize = 12 / activeSocialButtons.length; // Genişlik hesaplama

  const emailPhoneNumber = userPhoneNumberLogin ? (
    <FaInput
      required
      label={t("KULLANICI_E_POSTA_TELEFON")}
      autoComplete="email"
      onChange={handleChange("email")}
      value={values.email}
    />
  ) : (
    <FaInput
      faType="email"
      required
      label={t("KULLANICI_E_POSTA")}
      autoComplete="email"
      onChange={handleChange("email")}
      value={values.email}
    />
  );

  async function submitForm(event) {
    event.preventDefault();
    logIn();
  }

  const renderAppSwitch = () => {
    if (getThingsApp() === APP.T2C) {
      return (
        <Tooltip title={t("SWITCH_TF")}>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(LOCALSTORAGE.APP, APP.TF);
              document.location = THINGS_ENVIRONMENT.urlTF;
            }}
            style={{ width: "100%" }}
          >
            <img
              src={THINGS_ENVIRONMENT.appBarLogo}
              alt=""
              className={classes.appSwitchImage}
            ></img>
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title={t("SWITCH_T2C")}>
          <Button
            variant="contained"
            onClick={() => {
              localStorage.setItem(LOCALSTORAGE.APP, APP.T2C);
              document.location = THINGS_ENVIRONMENT.urlT2C;
            }}
            style={{ width: "100%" }}
          >
            <img
              src={THINGS_ENVIRONMENT.appLogoT2CWhite}
              alt=""
              className={classes.appSwitchImage}
            ></img>
          </Button>
        </Tooltip>
      );
    }
  };

  const rightLogIn = (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={9}
        className={classes.image}
        style={{
          backgroundImage: "url(" + backGroundImage + ")",
        }}
      />
      <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img
            src={appLogo}
            height="78"
            alt="logo"
            className={classes.appLogo}
          />
          <form
            ref={formRef}
            className={classes.form}
            validate="true"
            onSubmit={submitForm}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {emailPhoneNumber}
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  faType="password"
                  required
                  label={t("SIFRE")}
                  onChange={handleChange("password")}
                  value={values.password}
                  min={5}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={t("BENI_HATIRLA")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ paddingTop: 18 }}
                align="right"
              >
                {THINGS_ENVIRONMENT.showForgotPasswordButton && (
                  <Link
                    onClick={() => history.push(fgPasswordPath)}
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("SIFREMI_UNUTTUM")}
                  </Link>
                )}
              </Grid>
            </Grid>
            <Collapse in={showError}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowError(false);
                      setShowSuggestSignup(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}{" "}
                {showSuggestSignup && (
                  <Link
                    onClick={() => history.push("/sign-up")}
                    className={classes.linkUnderline}
                    underline="hover"
                  >
                    {t("UYE_OL_ONERI")}
                  </Link>
                )}
              </Alert>
            </Collapse>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FaButton
                  async
                  formRef={formRef}
                  type="submit"
                  label={t("GIRIS_YAP")}
                  variant="contained"
                  className={classes.submit}
                  loading={sessionLoading}
                  startIcon={<FontAwesomeIcon icon={faSignInAlt} />}
                  faClick={() => {
                    logIn();
                  }}
                />
              </Grid>
            </Grid>
            {THINGS_ENVIRONMENT.loginPageHelp && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Link
                    href={
                      process.env.PUBLIC_URL +
                      "/assets/help/Help-Yardım Masası.pdf"
                    }
                    target="_blank"
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("Kullanım kılavuzu")}
                  </Link>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              {(THINGS_ENVIRONMENT.showGoogleButton ||
                THINGS_ENVIRONMENT.showFacebookButton ||
                THINGS_ENVIRONMENT.showAppleButton ||
                THINGS_ENVIRONMENT.showBeneficiaryButton ||
                THINGS_ENVIRONMENT.showSignUpButton) && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.54)",
                      }}
                    />

                    <div>
                      <Typography
                        style={{
                          color: "rgba(0, 0, 0, 0.84)",
                          width: "70px",
                          textAlign: "center",
                        }}
                      >
                        {t("ALTERNATIVE_LOGIN")}
                      </Typography>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  </div>
                </Grid>
              )}

              {activeSocialButtons.map((button, index) => (
                <Grid
                  item
                  xs={gridSize}
                  key={index}
                  style={{ textAlign: "center" }}
                >
                  {button.content}
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {THINGS_ENVIRONMENT.showBeneficiaryButton && (
                  <Link
                    onClick={() => history.push("/bportal/login")}
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("BENEFICIARY_LOGIN")}
                  </Link>
                )}
              </Grid>

              <Grid item xs={6} align="right">
                {THINGS_ENVIRONMENT.showSignUpButton && (
                  <Link
                    onClick={() => history.push(signUpPath)}
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("UYE_OL")}
                  </Link>
                )}
              </Grid>
            </Grid>
            {THINGS_ENVIRONMENT.appSwitch && (
              <Box mt={5}>{renderAppSwitch()}</Box>
            )}
            <Box mt={5}>
              {/* <ButtonsContainer>
                <GooglePlayButton
                  url={
                    "https://play.google.com/store/apps/details?id=com.fortearge.thingsapp"
                  }
                  theme={"dark"}
                  className={"custom-style"}
                />
                <AppStoreButton
                  url={"https://apps.apple.com/tr/app/things/id1566552074"}
                  theme={"dark"}
                  className={"custom-style"}
                />
              </ButtonsContainer> */}
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item md={6} sm={12}>
                  <MobileStoreButton
                    store="android"
                    url={
                      "https://play.google.com/store/apps/details?id=com.fortearge.thingsapp"
                    }
                    linkProps={{ title: "Android Store Button" }}
                  />
                </Grid>
                <Grid item md={6} sm={12} style={{ textAlign: "right" }}>
                  <MobileStoreButton
                    store="ios"
                    url={"https://apps.apple.com/tr/app/things/id1566552074"}
                    linkProps={{ title: "iOS Store Button" }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={5}>
              <FaCopyright />
            </Box>
          </form>

          {THINGS_ENVIRONMENT.companyLogo && (
            <img
              src={THINGS_ENVIRONMENT.companyLogo}
              alt="company-logo"
              className={classes.companyLogo}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );

  const centeredLogIn = (
    <FaUnAuthPages backGroundImage={backGroundImage}>
      <img src={appLogo} height="78" alt="logo" className={classes.appLogo} />

      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <form
            ref={formRef}
            className={classes.form}
            validate="true"
            onSubmit={submitForm}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {emailPhoneNumber}
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  faType="password"
                  required
                  label={t("SIFRE")}
                  onChange={handleChange("password")}
                  value={values.password}
                  min={5}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={t("BENI_HATIRLA")}
                />
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                style={{ paddingTop: 18 }}
                align="right"
              >
                {THINGS_ENVIRONMENT.showForgotPasswordButton && (
                  <Link
                    onClick={() => history.push(fgPasswordPath)}
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("SIFREMI_UNUTTUM")}
                  </Link>
                )}
              </Grid>
            </Grid>
            <Collapse in={showError}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Collapse>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FaButton
                  async
                  formRef={formRef}
                  type="submit"
                  label={t("GIRIS_YAP")}
                  variant="contained"
                  className={classes.submit}
                  loading={sessionLoading}
                  startIcon={<FontAwesomeIcon icon={faSignInAlt} />}
                  faClick={() => {
                    logIn();
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {(THINGS_ENVIRONMENT.showGoogleButton ||
                THINGS_ENVIRONMENT.showFacebookButton ||
                THINGS_ENVIRONMENT.showAppleButton ||
                THINGS_ENVIRONMENT.showBeneficiaryButton ||
                THINGS_ENVIRONMENT.showSignUpButton) && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.54)",
                      }}
                    />

                    <div>
                      <Typography
                        style={{
                          color: "rgba(0, 0, 0, 0.84)",
                          width: "70px",
                          textAlign: "center",
                        }}
                      >
                        {t("Veya")}
                      </Typography>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  </div>
                </Grid>
              )}
              {THINGS_ENVIRONMENT.showGoogleButton && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <GoogleLogin
                    clientId="241190904512-ndpmk4v1fda8p4dhpfncpnq44mjkchjh.apps.googleusercontent.com"
                    render={(renderProps) => (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.googleButton}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        startIcon={<FontAwesomeIcon icon={faGoogle} />}
                      >
                        {t("GOOGLE")}
                      </Button>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={"single_host_origin"}
                  />
                </Grid>
              )}
              {THINGS_ENVIRONMENT.showFacebookButton && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <FacebookLoginCustomCSS
                    appId="933568497435478"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={renderProps.onClick}
                        className={classes.facebookButton}
                        startIcon={<FontAwesomeIcon icon={faFacebookF} />}
                      >
                        {t("FACEBOOK")}
                      </Button>
                    )}
                  ></FacebookLoginCustomCSS>
                </Grid>
              )}
              {THINGS_ENVIRONMENT.showAppleButton && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <AppleLogin
                    clientId="app.thngs"
                    redirectURI="https://thngs.app/sign-up"
                    usePopup={true}
                    callback={appleResponse}
                    scope="email name"
                    responseMode="query"
                    render={(renderProps) => (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.appleButton}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        startIcon={<FontAwesomeIcon icon={faApple} />}
                      >
                        {t("Apple ile devam et")}
                      </Button>
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {THINGS_ENVIRONMENT.showBeneficiaryButton && (
                  <Link
                    onClick={() => history.push("/bportal/login")}
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("Faydalanıcı Girişi")}
                  </Link>
                )}
              </Grid>

              <Grid item xs={6} align="right">
                {THINGS_ENVIRONMENT.showSignUpButton && (
                  <Link
                    onClick={() => history.push(signUpPath)}
                    className={classes.link}
                    variant="body2"
                    underline="hover"
                  >
                    {t("UYE_OL")}
                  </Link>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </FaUnAuthPages>
  );

  return <ThingsUnAuthPages>{rightLogIn}</ThingsUnAuthPages>;
}

LoginBase.propTypes = {
  appLogo: PropTypes.any,
  align: PropTypes.string,
  requestUrl: PropTypes.string,
  backGroundImage: PropTypes.any,
  usernameLogin: PropTypes.bool,
  signUpPath: PropTypes.string,
  fgPasswordPath: PropTypes.string,
};
