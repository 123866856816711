import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingsScanCountStatHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Müşteri Etkileşim Sayılarının <b>Grafik</b> veya <b>Liste</b> olarak
            gösterildiği alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='LIST_OR_GRAPHIC_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Sayılarını <b>Grafik</b> veya <b>Liste</b> olarak görmek
            için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='DATE_RANGE_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Sayılarını <b>Dönemsel</b> olarak görmek için tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.dateSettingsClass,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/thingScanCountMenuDate.png"
                }
                width="405"
                height="207"
              ></img>
            </div>
            <b>
              “Son 1 Hafta”, “Son 2 Hafta”, “Son 1 Ay”, “Son 2 Ay”, “Son 6 Ay”
              ve “Son 1 Yıl”
            </b>{" "}
            seçeneklerinden birini seçin.
            <br />
            Bu seçeneklerin dışında bir dönem seçmek için <b>Özel</b>’e
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/ozel_tarih_secimi.png"
                }
                width="380"
                height="219"
              ></img>
            </div>
            <b>Başlangıç Tarihi</b> ve <b>Bitiş Tarihi</b> seçin ve{" "}
            <b>Kaydet</b>’e basın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='MENU_PERIOD']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Sayılarını <b>Günlük, Haftalık, Aylık ve Yıllık</b> olarak
            görmek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/thingScanCountPeriod.png"
                }
                width="380"
                height="219"
              ></img>
            </div>
            <b>Günlük, Haftalık, Aylık ve Yıllık</b> seçeneklerinden birini
            seçin.
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingsScanCountStatHelp.propTypes = {
  value: PropTypes.string,
};
