import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Remove,
} from "@mui/icons-material";
import HomepageItemTypeSelect from "components/select/HomepageItemTypeSelect";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { UserService } from "services/UserService";
import { DEFAULT_UI_SETTINGS } from "services/utils";
import i18n from "../../i18n";

const HomepageSettings = forwardRef((props, ref) => {
  const { onChange, onClose, value, open } = props;
  const tableRef = useRef();
  const { t } = useTranslation(["profile", "components"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [newItemType, setNewItemType] = useState(null);

  const [uiSettings, setUiSettings] = useState(null);
  const [homepageItems, setHomepageItems] = useState(null);

  useEffect(() => {
    if (open) {
      UserService.getUiSettings(
        (data) => {
          if (data) {
            setUiSettings(data);
            setHomepageItems(data.homepageItems);
          } else {
            setUiSettings(DEFAULT_UI_SETTINGS);
            setHomepageItems(DEFAULT_UI_SETTINGS.homepageItems);
          }
        },
        (error) => {
          setUiSettings(DEFAULT_UI_SETTINGS);
          setHomepageItems(DEFAULT_UI_SETTINGS.homepageItems);
        }
      );
    }
  }, [open]);

  const saveSuccess = (data) => {
    showAlert(t("Anasayfa ayarları kaydedildi"), "success");
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveForm = () => {
    setLoading(true);
    let data = { ...uiSettings, homepageItems: homepageItems };
    UserService.saveUiSettings(data, saveSuccess, saveError);
  };

  const turnToDefault = () => {
    faDialog({
      description: t("Varsayılan ayarlara dönmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        setHomepageItems(DEFAULT_UI_SETTINGS.homepageItems);
        // MessageTemplateService.delete(
        //   homepageTemplate,
        //   (data) => {
        //     showAlert(t("Başarıyla sıfırlandı"), "success");
        //     onChange();
        //   },
        //   (error) => {
        //     showAlert(
        //       t("Ayarlar sıfırlanamadı, bir sorunla karşılaşıldı."),
        //       "error"
        //     );
        //   }
        // );
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteItem = (item, index) => {
    var temp = [...homepageItems];
    temp.splice(index, 1);
    setHomepageItems(temp);
  };
  const zoomInItem = (item, index) => {
    let temp = [...homepageItems];
    let element = temp[index];
    element = { ...element, size: 12 };
    temp[index] = element;
    setHomepageItems(temp);
  };
  const zoomOutItem = (item, index) => {
    let temp = [...homepageItems];
    let element = temp[index];
    element = { ...element, size: 6 };
    temp[index] = element;
    setHomepageItems(temp);
  };

  const moveUp = (item, index) => {
    if (index === 0) return;
    let temp = [...homepageItems];
    let element1 = temp[index];
    let element2 = temp[index - 1];
    temp[index] = element2;
    temp[index - 1] = element1;
    setHomepageItems(temp);
  };

  const moveDown = (item, index) => {
    if (index === homepageItems.length - 1) return;
    let temp = [...homepageItems];
    let element1 = temp[index];
    let element2 = temp[index + 1];
    temp[index] = element2;
    temp[index + 1] = element1;
    setHomepageItems(temp);
  };

  const addNewItem = (data) => {
    setHomepageItems([...homepageItems, { item: data, size: 6 }]);
  };

  const renderItem = (item, index) => {
    return (
      <Grid item xs={item.size}>
        <Card>
          <CardHeader
            title={t("HOMEPAGE_ITEM." + item.item, { ns: "components" })}
            action={
              <>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    deleteItem(item, index);
                  }}
                  startIcon={<Delete />}
                  style={{ marginRight: 5 }}
                ></Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    moveDown(item, index);
                  }}
                  startIcon={<ArrowDownward />}
                  style={{ marginRight: 5 }}
                ></Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => {
                    moveUp(item, index);
                  }}
                  startIcon={<ArrowUpward />}
                  style={{ marginRight: 5 }}
                ></Button>
                {item.size > 6 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      zoomOutItem(item, index);
                    }}
                    startIcon={<Remove />}
                    style={{ marginRight: 5 }}
                  ></Button>
                )}
                {item.size < 12 && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      zoomInItem(item, index);
                    }}
                    startIcon={<Add />}
                    style={{ marginRight: 5 }}
                  ></Button>
                )}
              </>
            }
          />
          <CardContent></CardContent>
        </Card>
      </Grid>
    );
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Anasayfa Ayarları"}
          faOpen={open}
          faOnSubmit={saveForm}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            //setMessageTemplate(newMessageTemplate());
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            // alignItems="center"
            // justifyContent="center"
            spacing={1}
          >
            {homepageItems &&
              homepageItems.map((item, index) => renderItem(item, index))}

            <Grid item xs={12}>
              <HomepageItemTypeSelect
                label="Eleman ekle"
                value={newItemType}
                onChange={(data) => {
                  addNewItem(data);
                }}
              />
            </Grid>
            {homepageItems && homepageItems.length > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={turnToDefault} color="secondary">
                  Varsayılan ayarlara dön
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default HomepageSettings;
