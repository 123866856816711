import { Button, Grid } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomerListService } from "services/CustomerListService";
import i18n from "../../i18n";

const CustomerListEdit = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, value, open } = props;
  const { t } = useTranslation("customerLists", { i18n });

  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const accountId = localStorage.accountId;
  useEffect(() => {
    if (open) {
      setCustomerList(value == null ? newCustomerList() : { ...value });
    }
  }, [open]);

  const newCustomerList = () => {
    return {
      id: "",
      name: "",
      description: "",
      account: { id: accountId },
    };
  };
  const [customerList, setCustomerList] = useState(newCustomerList());

  const handleChange = (prop) => (event) => {
    setCustomerList({ ...customerList, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!customerList.id ? t("SAVED") : t("EDITED"), "success");
    setCustomerList(newCustomerList());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };
  const deleteSuccess = (data) => {
    showAlert(t("Başarıyla silindi"), "success");
    onDelete();
  };

  const deleteError = (data) => {
    showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
  };

  const saveCustomerList = () => {
    setLoading(true);
    CustomerListService.save(customerList, saveSuccess, saveError);
  };

  const customerListDelete = () => {
    CustomerListService.delete(customerList, deleteSuccess, deleteError);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Müşteri Listesi Tanımlama"}
          faOpen={open}
          faOnSubmit={saveCustomerList}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                faType="name"
                label={t("Tanım")}
                value={customerList.name}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("name")}
              />
              <FaInput
                faType="description"
                label={t("Açıklama")}
                value={customerList.description}
                inputProps={{ maxLengt: 100 }}
                onChange={handleChange("description")}
              />
            </Grid>
            {customerList.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={customerListDelete} color="secondary">
                  Sil
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default CustomerListEdit;
