import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CardContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AccessTypeSelect from "components/select/AccessTypeSelect";
import ThingClassWebLinkHelp from "features/help/ThingClassWebLinkHelp";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ThingLinkService } from "services/thingLink/thingLinkService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingClassLink(props) {
  const { thingClass } = props;

  const { t } = useTranslation("thingClass", { i18n });
  const tableRef = useRef();
  const classes = useStyles();
  const faDialog = useDialog();

  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [thingClassLinks, setThingClassLinks] = useState([]);
  const [thingClassLink, setThingClassLink] = useState({});

  const newLink = () => {
    return {
      url: "",
      description: "",
      accessType: "PUBLIC",
      thingClass: { id: thingClass.id },
      account: { id: accountId },
    };
  };

  useEffect(() => {
    if (thingClass && thingClass.id) {
      refresh();
      setThingClassLink(newLink());
    }
  }, [thingClass]);

  const refresh = () => {
    ThingLinkService.findThingClassLinks(
      thingClass.id,
      accountId,
      findThingClassLinksOnSuccess,
      () => {}
    );
  };

  const findThingClassLinksOnSuccess = (data) => {
    setThingClassLinks(data);
  };

  const saveLink = () => {
    ThingLinkService.save(thingClassLink, saveLinkOnSuccess, () => {});
  };

  const saveLinkOnSuccess = () => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const handleChangeV = (prop, val) => {
    setThingClassLink({ ...thingClassLink, [prop]: val });
  };

  const deleteLink = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      ThingLinkService.delete(item, deleteLinkOnSuccess, deleteLinkonError);
    });
  };

  const deleteLinkOnSuccess = (data) => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const deleteLinkonError = (e) => {
    showAlert("Bağlı kayıtlar mevcut", "error");
    setIsEditDialogOpen(false);
  };

  const makePublic = (item) => {
    ThingLinkService.makePublic(
      item.id,
      accountId,
      makePublicOnSuccess,
      () => {}
    );
  };

  const makePublicOnSuccess = (data) => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const makePrivate = (item) => {
    ThingLinkService.makePrivate(
      item.id,
      accountId,
      makePrivateOnSuccess,
      () => {}
    );
  };

  const makePrivateOnSuccess = (data) => {
    refresh();
    setIsEditDialogOpen(false);
  };

  const moveUp = (item) => {
    ThingLinkService.moveUp(
      item,
      () => {
        refresh();
        setIsEditDialogOpen(false);
      },
      () => {}
    );
  };

  const moveDown = (item) => {
    ThingLinkService.moveDown(
      item,
      () => {
        refresh();
        setIsEditDialogOpen(false);
      },
      () => {}
    );
  };

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("ADD_WEB_LINK")}
        faOpen={isEditDialogOpen}
        faOnSubmit={saveLink}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={thingClassLink.description || ""}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              label={t("URL_ADDRESS")}
              onChange={(event) => handleChangeV("url", event.target.value)}
              value={thingClassLink.url || ""}
              required={true}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AccessTypeSelect
              value={thingClassLink.accessType}
              onChange={(data) => handleChangeV("accessType", data)}
            />
          </Grid>
          {thingClassLink?.id && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  deleteLink(thingClassLink);
                }}
                color="secondary"
              >
                {t("Sil")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  const createFallbackUrl = (item) => {
    return (
      <div className={classes.fallbackUrlBox}>
        <a
          href={item.url}
          target="blank_"
          style={{ position: "relative", top: "50%" }}
        >
          {item.url}
        </a>
      </div>
    );
  };

  return <>
    {renderEditDialog()}
    {/* {renderDetailDialog()} */}
    <Card
      style={{ minHeight: 100 }}
      data-fa-section="WEB_LINKS_CARD"
      className="fai-thingLinks"
    >
      <CardHeader
        action={
          <>
            <ThingClassWebLinkHelp value="thingClass.webLink" />
            <IconButton
              aria-label="settings"
              data-fa-button="WEB_LINKS_ADD"
              onClick={() => {
                setThingClassLink(newLink());
                setIsEditDialogOpen(true);
              }}
              size="large">
              <AddIcon />
            </IconButton>
          </>
        }
        title={t("WEB_LINKS")}
        data-fa-section="WEB_LINKS"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {thingClassLinks &&
                  thingClassLinks.length > 0 &&
                  thingClassLinks?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          onClick={() => {
                            window.open(item.url, "_blank").focus();
                          }}
                          style={{
                            textDecoration: "underline",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                          underline="hover">
                          <Typography className={classes.LabelValue}>
                            {item.description == null ||
                            item.description === ""
                              ? item.url
                              : item.description + " (" + item.url + ")"}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell className={classes.LabelValue}>
                        {t(item.accessType)}
                      </TableCell>
                      <TableCell align="right">
                        <>
                          <IconButton
                            onClick={() => {
                              moveUp(item);
                            }}
                            size="small"
                            color="primary"
                            aria-label={t("MOVE_UP")}
                            className={classes.ActionButton}
                          >
                            <KeyboardArrowUpIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              moveDown(item);
                            }}
                            size="small"
                            color="primary"
                            aria-label={t("MOVE_DOWN")}
                            className={classes.ActionButton}
                          >
                            <KeyboardArrowDownIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setThingClassLink(item);
                              setIsEditDialogOpen(true);
                            }}
                            size="small"
                            color="primary"
                            aria-label={t("EDIT")}
                            className={classes.ActionButton}
                            style={{ marginLeft: 2 }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </IconButton>
                        </>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
