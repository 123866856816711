import { Grid } from "@mui/material";
import PatrolDefAutoComplete from "components/autocomplete/PatrolDefAutoComplete";
import PatrolExecutionStatusSelect from "components/select/PatrolExecutionStatusSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { PATROL } from "../../services/faApi";

export default function Patrol() {
  const tableRef = useRef();
  const { t } = useTranslation("patrolDef", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "id",
      label: t("NAME"),
      align: "center",
      width: "20%",
      valueFormatter: (val, row) => {
        return row.patrolDef.name;
      },
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "20%",
      valueFormatter: (status) => {
        return t("" + status);
      },
    },
  ];

  const newSearchForm = () => {
    return {
      patrolDef: null,
      patrolDefId: null,
      status: null,
      accountId: accountId,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    let tmpTo = {
      pathname: `/patrolDetail/${row.id}`,
      state: {
        patrol: {
          ...row,
        },
      },
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <ThingsDataTable
          searchUrl={PATROL.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={6}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <PatrolDefAutoComplete
                required
                value={searchForm.patrolDef}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    patrolDef: data,
                    patrolDefId: data?.id,
                  });
                }}
              />

              <PatrolExecutionStatusSelect
                required
                value={searchForm.status}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "status",
                    data
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
