import { Button, ButtonGroup } from "@mui/material";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function ColoredBookmarkButtonGroup(props) {
  const { value, isChecked, onChange, ...other } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <ButtonGroup
      fullWidth
      size="small"
      variant="outlined"
      color="primary"
      aria-label="contained primary button group"
      {...other}
    >
      <Button
        color={"primary"}
        variant={value == null && isChecked === null ? "contained" : "outlined"}
        onClick={() => {
          onChange(null, null);
        }}
      >
        {t("ALL")}
      </Button>
      <Button
        color={"primary"}
        variant={
          value === null && isChecked === "NO" ? "contained" : "outlined"
        }
        onClick={() => {
          onChange(null, "NO");
        }}
      >
        <BookmarkBorder />
      </Button>
      <Button
        color={"primary"}
        variant={value === "GREEN" ? "contained" : "outlined"}
        onClick={() => {
          onChange("GREEN", "YES");
        }}
      >
        <Bookmark style={{ color: "GREEN" }} />
      </Button>
      <Button
        color={"primary"}
        variant={value === "YELLOW" ? "contained" : "outlined"}
        onClick={() => {
          onChange("YELLOW", "YES");
        }}
      >
        <Bookmark style={{ color: "YELLOW" }} />
      </Button>
      <Button
        color={"primary"}
        variant={value === "RED" ? "contained" : "outlined"}
        onClick={() => {
          onChange("RED", "YES");
        }}
      >
        <Bookmark style={{ color: "RED" }} />
      </Button>
      <Button
        color={"primary"}
        variant={value === "BLUE" ? "contained" : "outlined"}
        onClick={() => {
          onChange("BLUE", "YES");
        }}
      >
        <Bookmark style={{ color: "BLUE" }} />
      </Button>
      <Button
        color={"primary"}
        variant={value === "BLACK" ? "contained" : "outlined"}
        onClick={() => {
          onChange("BLACK", "YES");
        }}
      >
        <Bookmark style={{ color: "BLACK" }} />
      </Button>
    </ButtonGroup>
  );
}
