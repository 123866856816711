import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import Card from "@mui/material/Card";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import BeneficiaryNotesHelp from "features/help/BeneficiaryNotesHelp";
import React, { useEffect, useRef, useState } from "react";
import { FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BeneficiaryNoteService } from "services/BeneficiaryNoteService";
import { BENEFICIARY_NOTE } from "services/faApi";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import ChannelSelect from "../select/ChannelSelect";
import MessageTable from "./MessageTable";

export default function BeneficiaryNote(props) {
  const {
    customer,
    issue,
    campaignCustomer,
    suggestion,
    title,
    sms,
    email,
    push,
  } = props;
  const accountId = localStorage.accountId;
  const tableRef = useRef();
  const userInfo = useSelector(getUserInfo);
  const gridClasses = gridStyles();
  const { t } = useTranslation("beneficiaryNote", { i18n });
  const faDialog = useDialog();
  const newQueryParams = () => {
    return {
      accountId: accountId,
      page: 0,
      size: 10,
      sort: "createDate",
      isAsc: false,
    };
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [servisAddress, setServisAddress] = useState();
  const [queryParams, setQueryParams] = useState(newQueryParams());
  const newBeneficiaryNote = () => {
    return {
      messageTitle: "",
      messageContent: "",
      channel: email ? "EMAIL" : sms ? "SMS" : "",
      user: userInfo,
      account: { id: accountId },
      customer: customer,
      issue: issue,
      campaignCustomer: campaignCustomer,
      suggestion: suggestion,
    };
  };
  const [beneficiaryNote, setBeneficiaryNote] = useState(newBeneficiaryNote());

  useEffect(() => {
    if (customer) {
      setQueryParams({ ...queryParams, customerId: customer.id });
      setBeneficiaryNote({
        ...beneficiaryNote,
        customer: customer,
        channel: email ? "EMAIL" : sms ? "SMS" : "",
      });
      setServisAddress(BENEFICIARY_NOTE.findCustomerBeneficiaryNotes);
    } else if (issue) {
      setQueryParams({ ...queryParams, issueId: issue.id });
      setBeneficiaryNote({
        ...beneficiaryNote,
        issue: issue,
        channel: email ? "EMAIL" : sms ? "SMS" : "",
      });
      setServisAddress(BENEFICIARY_NOTE.findIssueBeneficiaryNotes);
    } else if (campaignCustomer) {
      setQueryParams({
        ...queryParams,
        campaignCustomerId: campaignCustomer.id,
      });
      setBeneficiaryNote({
        ...beneficiaryNote,
        campaignCustomer: campaignCustomer,
        channel: email ? "EMAIL" : sms ? "SMS" : "",
      });
      setServisAddress(BENEFICIARY_NOTE.findCampaignCustomerBeneficiaryNotes);
    } else if (suggestion) {
      setQueryParams({ ...queryParams, suggestionId: suggestion.id });
      setBeneficiaryNote({
        ...beneficiaryNote,
        suggestion: suggestion,
        channel: email ? "EMAIL" : sms ? "SMS" : "",
      });
      setServisAddress(BENEFICIARY_NOTE.findSuggestionBeneficiaryNotes);
    }
  }, [customer, issue, campaignCustomer, suggestion]);

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const saveNote = () => {
    setLoading(true);
    BeneficiaryNoteService.save(beneficiaryNote, saveOnSuccess, saveOnError);
  };
  const saveOnSuccess = () => {
    setBeneficiaryNote(newBeneficiaryNote());
    setLoading(false);
    setIsDialogOpen(false);
    refresh();
  };
  const saveOnError = (error) => {
    console.log(error);
  };
  const handleChange = (prop, val) => {
    setBeneficiaryNote({ ...beneficiaryNote, [prop]: val });
  };

  const renderNewNoteDialog = () => {
    return (
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setBeneficiaryNote(newBeneficiaryNote());
        }}
      >
        <DialogTitle id="alert-dialog-title">{t("ADD")}</DialogTitle>
        <DialogContent>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12} data-fa-section="CHANNEL_INPUT">
              <ChannelSelect
                value={beneficiaryNote.channel}
                onChange={(data) => {
                  handleChange("channel", data);
                }}
                required
                sms={sms}
                email={email}
                push={push}
              ></ChannelSelect>
            </Grid>
            {beneficiaryNote.channel === "EMAIL" && (
              <Grid item xs={12} data-fa-section="EMAIL_INPUT">
                <FaInput
                  required
                  value={beneficiaryNote.messageTitle}
                  label={t("MESSAGE_TITLE")}
                  faType="text"
                  onChange={(event) => {
                    handleChange("messageTitle", event.target.value);
                  }}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} data-fa-section="MESSAGE_CONTENT_INPUT">
              <FaInput
                required
                value={beneficiaryNote.messageContent}
                label={t("MESSAGE_CONTENT")}
                faType="description"
                onChange={(event) => {
                  handleChange("messageContent", event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              saveNote();
            }}
            color="primary"
          >
            {t("Mesajı Gönder")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return <>
    {renderNewNoteDialog()}
    <Card>
      <CardHeader
        title={title ?? t("BENEFICIARY_NOTES")}
        data-fa-section="BENEFICIARY_NOTES"
        action={
          <>
            <BeneficiaryNotesHelp value={"issueDetail.beneficiaryNotes"} />
            <IconButton
              aria-label="settings"
              onClick={() => {
                setIsDialogOpen(true);
              }}
              data-fa-button="ADD_BENEFICIARY_NOTES"
              size="large">
              <ThingsEditIcon />
            </IconButton>
          </>
        }
      />
      {servisAddress && (
        <>
          <MessageTable
            dataUrl={servisAddress}
            queryParams={queryParams}
            ref={tableRef}
          ></MessageTable>
          {/* <ThingsDataTable
            dataUrl={servisAddress}
            queryParams={queryParams}
            columns={columns}
            ref={tableRef}
            showHeaderText={false}
            headerText={t("BENEFICIARY_NOTES")}
            showHeader={false}
            columnClassName={gridClasses.GenericColumn}
          /> */}
        </>
      )}
    </Card>
  </>;
}
