import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import NoImage from "components/ui/NoImage";
import IssuePhotosHelp from "features/help/IssuePhotosHelp";
import React, { useRef, useState } from "react";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ISSUE } from "../../services/faApi";
import IssuePhotosCarousel from "./IssuePhotosCarousel";
import { useStyles } from "./style";

export default function IssuePhotos(props) {
  const { images, uploadImages, deleteImage, issueId } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("issueDetail", { i18n });
  const fileInputRef = useRef();
  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [issueHdImages, setIssueHdImages] = useState([]);

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    getIssueHdImages();
    setSelectedHdImageIndex(index);
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  const getIssueHdImages = () => {
    const req = requests.getSimplePostRequest(
      ISSUE.downloadFiles + "/" + issueId
    );
    FaAxios(req)
      .then((response) => {
        setIssueHdImages(response.data);
        setCarouselOpen(true);
      })
      .catch((error) => {
        console.log("refreshIssueImages error", issueId);
      });
  };

  return <>
    <Dialog
      open={isCarouselOpen}
      aria-labelledby="form-dialog-title"
      onClose={closeCarousel}
    >
      <DialogContent>
        <IssuePhotosCarousel
          images={issueHdImages}
          selectedItem={selectedHdImageIndex}
        />
      </DialogContent>
    </Dialog>

    <Card style={{ height: 260, overflowY: "auto" }}>
      <CardHeader
        action={
          <>
            <IssuePhotosHelp value="issueDetail.issuePhotos" />
            <IconButton aria-label="settings" data-fa-button="PHOTOS_ADD" size="large">
              <AddIcon onClick={() => fileInputRef.current.click()} />
            </IconButton>
          </>
        }
        title={t("PHOTOS")}
        data-fa-section="PHOTOS"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <input
              onChange={handleChange}
              multiple
              ref={fileInputRef}
              type="file"
              hidden
            />
          </div>
          {(!images || images.length === 0) && <NoImage />}
          <ImageList
            rowHeight={140}
            className={classes.imageList}
            cols={lg ? 4 : 2}
          >
            {images.map((item, index) => (
              <ImageListItem key={index} cols={images.length == 1 ? 2 : 1}>
                <img
                  className={classes.thingImage}
                  src={`data:image/png;base64, ${item.content}`}
                  alt={item.object}
                  onClick={() => {
                    openCarousel(index);
                  }}
                />
                <IconButton
                  className={classes.issueImageDeleteButtom}
                  onClick={() => {
                    deleteImage(item.object);
                  }}
                  size="large">
                  <DeleteIcon color="secondary" />
                </IconButton>
                {/* <ImageListItemBar
                title={item.object}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${item.object}`}
                    className={classes.icon}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              /> */}
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
