import { Grid } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import IssueClassAutoComplete from "components/autocomplete/IssueClassAutoComplete";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ISSUECLASS } from "../../services/faApi";
import { IssueClassService } from "../../services/issueClass/IssueClassService";

export default function IssueClass() {
  const tableRef = useRef();
  const { t } = useTranslation("issueClass", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);

  const columns = [
    {
      field: "className",
      label: t("GENERIC_CLASSNAME"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              variant="square"
              style={{ width: "48px", marginRight: "10px" }}
            >
              {" "}
              {value.charAt(0)}
            </Avatar>{" "}
            {value}
          </div>
        );
      },
    },
    {
      field: "parent",
      label: t("PARENT"),
      align: "center",
      width: "40%",
      inner: "className",
      valueFormatter: (parent) => {
        if (parent) {
          return parent.className;
        }
      },
    },
  ];

  const newIssueClass = () => {
    return {
      id: "",
      className: "",
      domain: "",
      parent: null,
    };
  };
  const [issueClass, setIssueClass] = useState(newIssueClass());

  const handleChange = (prop) => (event) => {
    setIssueClass({ ...issueClass, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!issueClass.id ? t("SAVED") : t("EDITED"), "success");
    setIsRegisterFormOpen(false);
    setIssueClass(newIssueClass());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveIssueClass = () => {
    setLoading(true);
    IssueClassService.save(
      {
        ...issueClass,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    return {
      genericClassName: null,
      className: null,
      domain: null,
      accountId: accountId,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `issueClassDetail/${row.id}`,
      breadCrumbKey: `/issueClassDetail/:id`,
    };
    history.push(tmpTo);
  };

  const newIssueClassRegisterRender = () => {
    return (
      <>
        <FaDialog
          title={"Sorun Tipi Tanımla"}
          faOpen={isRegisterFormOpen}
          faOnSubmit={saveIssueClass}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsRegisterFormOpen(data);
            setIssueClass(newIssueClass());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <IssueClassAutoComplete
                accountId={accountId}
                value={issueClass.parent}
                onChange={(data) => {
                  setIssueClass({
                    ...issueClass,
                    parent: data,
                  });
                }}
                label={t("PARENT")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                inputProps={{ maxLength: 100 }}
                label={t("GENERIC_CLASSNAME")}
                onChange={handleChange("className")}
                value={issueClass.className || ""}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newIssueClassRegisterRender()}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsRegisterFormOpen(true);
              }}
              startIcon={<AddIcon />}
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
            >
              <FaInput
                label={t("GENERIC_CLASSNAME")}
                value={searchForm.className}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "className",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DOMAIN")}
                value={searchForm.domain}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "domain",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={ISSUECLASS.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
      </Suspense>
    </>
  );
}
