import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import Map, { GeolocateControl, Layer, Popup, Source } from "react-map-gl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ParseLocation } from "services/utils";
import i18n from "../../i18n";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "../thingsMap/layers";
import { useStyles } from "./style";

const SurveyGPSLocation = forwardRef((props, ref) => {
  const { answer } = props;
  const { t } = useTranslation("survey", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const mapRef = useRef();

  const MAPBOX_TOKEN =
    "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA"; // Set your mapbox token here

  const geolocateStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  };
  const [viewport, setViewPort] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });
  const [gpsLocation, setGpsLocation] = useState(null);
  const [ipGpsLocation, setIpGpsLocation] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });
  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    if (answer) {
      let location = null;
      if (answer.gpsLocation) location = ParseLocation(answer.gpsLocation);
      else if (answer.ipGpsLocation)
        location = ParseLocation(answer.ipGpsLocation);

      if (location)
        setGeojson({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [location.lng, location.lat],
              },
              properties: {
                id: answer.id,
              },
            },
          ],
        });
    }
  }, [answer]);

  const getLat = () => {
    if (gpsLocation) return gpsLocation.lat;
    else if (ipGpsLocation) return ipGpsLocation.lat;
    else return null;
  };

  const getLng = () => {
    if (gpsLocation) return gpsLocation.lng;
    else if (ipGpsLocation) return ipGpsLocation.lng;
    else return null;
  };

  return (
    <Dialog
      open={isDialogOpen}
      onClose={() => {
        setIsDialogOpen(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title"></DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ height: "83vh", width: "500px", marginTop: 5 }}>
            <Map
              {...viewport}
              width="100%"
              height="100%"
              mapStyle="mapbox://styles/mapbox/light-v10"
              onMove={(evt) => setViewPort(evt.viewState)}
              mapboxAccessToken={MAPBOX_TOKEN}
              //onClick={onClick}
              ref={mapRef}
              interactiveLayerIds={[unclusteredPointLayer.id, clusterLayer.id]}
            >
              <GeolocateControl
                style={geolocateStyle}
                positionOptions={{ enableHighAccuracy: true }}
                trackUserLocation={true}
              />

              <Source
                id="things"
                type="geojson"
                data={geojson}
                cluster={true}
                clusterMaxZoom={14}
                clusterRadius={50}
              >
                <Layer {...clusterLayer} />
                <Layer {...clusterCountLayer} />
                <Layer {...unclusteredPointLayer} />
              </Source>

              {popupInfo && (
                <Popup
                  anchor="top"
                  longitude={Number(popupInfo.lng)}
                  latitude={Number(popupInfo.lat)}
                  closeOnClick={false}
                  onClose={() => setPopupInfo(null)}
                ></Popup>
              )}
            </Map>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsDialogOpen(false);
          }}
          color="primary"
        >
          Kapat
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default SurveyGPSLocation;
