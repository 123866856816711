import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faSitemap,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardContent, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Add } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SlaContractThingService } from "services/SlaContractThingService";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../i18n";
import SlaContractTargetEdit from "./slaContractTargetEdit";
import { useStyles } from "./style";

export default function SlaContractThings(props) {
  const { slaContract } = props;
  const faDialog = useDialog();
  const classes = useStyles();

  const { t } = useTranslation("slaContract", { i18n });
  const tableRef = useRef();
  const { id } = useParams();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [targets, setTargets] = useState();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    refresh();
  }, [slaContract]);

  const refresh = () => {
    setLoading(true);
    SlaContractThingService.findTargets(
      id,
      findTargetsOnSuccess,
      findTargetsOnError
    );
  };

  const findTargetsOnSuccess = (data) => {
    setTargets(data.list);
    setLoading(false);
  };

  const findTargetsOnError = (error) => {
    setLoading(false);
  };

  const renderTargets = () => {
    if (slaContract == null || targets == null || targets.length === 0) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <ul className={classes.chipRoot}>
          {targets?.map((target, index) => {
            let icon;
            let label;

            if (target.thingClass) {
              icon = <FontAwesomeIcon icon={faSitemap} />;
              label = target.thingClass.className;
            } else if (target.thing) {
              icon = <FontAwesomeIcon icon={faCubes} />;
              label = ThingDesc(target.thing);
            } else if (target.hashtag) {
              icon = <FontAwesomeIcon icon={faHashtag} />;
              label = target.hashtag;
            } else if (target.organization) {
              icon = <FontAwesomeIcon icon={faFolder} />;
              label = target.organization.name;
            } else if (target.location) {
              icon = <FontAwesomeIcon icon={faGlobe} />;
              label = target.location.name;
            } else if (target.allThings) {
              icon = <FontAwesomeIcon icon={faUser} />;
              label = isTNF(t("ALL_THINGS"), t("ALL_THINGS_T2C"));
            }

            return (
              <li key={index}>
                <Chip icon={icon} label={label} className={classes.chip} />
              </li>
            );
          })}
        </ul>
      );
  };

  return <>
    <SlaContractTargetEdit
      slaContractId={slaContract.id}
      targets={targets}
      onSave={() => {
        refresh();
        setIsDialogOpen(false);
      }}
      onClose={() => {
        setIsDialogOpen(false);
      }}
      onDelete={() => {
        refresh();
        setIsDialogOpen(false);
      }}
      open={isDialogOpen}
    />
    <Grid item lg={12} xs={12}>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={() => setIsDialogOpen(true)} size="large">
              <Add />
            </IconButton>
          }
          title={t("SLA_CONTRACT_SCOPE")}
          data-fa-section="SLA_CONTRACT_SCOPE"
        />
        <CardContent>
          <Grid item container spacing={1}>
            {renderTargets()}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  </>;
}
