import {
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IssueService } from "services/issue/issueService";
import { IssueDesc, smartTimeDiff } from "services/utils";
import i18n from "../../i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: "100%",
    height: 230,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

export default function OpenIssues(props) {
  const { t } = useTranslation(["issue", "issueCloseType"], { i18n });
  const {
    thing,
    selectedIssues,
    accPreferences,
    defaultIssue,
    onAddIssue,
    onDeleteIssue,
  } = props;

  const [activeIssues, setActiveIssues] = useState([]);

  useEffect(() => {
    if (thing) {
      IssueService.findByActiveIssues(
        thing.thid,
        thing.id,
        findByActiveIssuesCallback
      );
    }
  }, [thing]);

  const findByActiveIssuesCallback = (list) => {
    let feedbackDefault = false;
    if (accPreferences && accPreferences.feedbackTaskAssign === "ACCEPT")
      feedbackDefault = true;
    else if (accPreferences && accPreferences.feedbackTaskAssign === "NEVER")
      feedbackDefault = false;

    let tmpArr = list.map((value) => ({
      ...value,
      closed: true,
      feedback: feedbackDefault,
    }));

    setActiveIssues(tmpArr);

    if (defaultIssue?.id) {
      const myIssue = list.filter((item) => item.id === defaultIssue?.id)[0];
      if (list.indexOf(myIssue) > -1) {
        onAddIssue(myIssue);
      }
    }
  };

  const classes = useStyles();

  const closeIssue = (index) => {
    let tmpArry = [...activeIssues];
    tmpArry[index].closed = !tmpArry[index].closed;
    setActiveIssues(tmpArry);
  };

  const feedbackIssue = (index) => {
    let tmpArry = [...activeIssues];
    tmpArry[index].feedback = !tmpArry[index].feedback;
    setActiveIssues(tmpArry);
  };

  const createActiveIssuesList = () => (
    <Paper className={classes.paper}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("BILDIRIM_TARIH")}</TableCell>
              <TableCell>{t("ISSUE")}</TableCell>
              <TableCell>{t("SORUN_KAPAT")}</TableCell>
              <TableCell>{t("GERIBILDIRIM")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeIssues.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  {t("NO_NOTIFICATIONS_INTERACTION_POINT")}
                </TableCell>
              </TableRow>
            )}
            {activeIssues.map((issue, index) => {
              const labelId = `transfer-list-item-${index}-label`;
              const itemSelected = selectedIssues.some(
                (selectedIssue) => selectedIssue.id === issue.id
              );

              return (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={itemSelected}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      onChange={(event) => {
                        if (event.target.checked && onAddIssue) {
                          onAddIssue(issue);
                        } else if (!event.target.checked && onDeleteIssue) {
                          onDeleteIssue(issue);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell>{smartTimeDiff(issue.notificationDate)}</TableCell>
                  <TableCell>{IssueDesc(issue)}</TableCell>
                  <TableCell>
                    {itemSelected === true && (
                      <Switch
                        checked={issue.closed}
                        onChange={() => {
                          closeIssue(index);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    )}
                  </TableCell>

                  <TableCell>
                    {itemSelected === true && (
                      <Switch
                        checked={issue.feedback}
                        disabled={!issue.closed}
                        onChange={() => {
                          feedbackIssue(index);
                        }}
                        color="primary"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={12}>
        {createActiveIssuesList()}
      </Grid>
    </Grid>
  );
}
