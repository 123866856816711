import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Todo from "features/todo";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function HomepageTodo(props) {
  const { size } = props;
  const { t } = useTranslation("components", { i18n });

  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader title={t("HOMEPAGE_ITEM.TODO")} />
        <Todo />
      </Card>
    </Grid>
  );
}
