import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { Block } from "@mui/icons-material";
import Check from "@mui/icons-material/Check";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaSearch } from "react-base-fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { StockTakingService } from "services/StockTakingService";
import { CHART_COLORS } from "services/utils/ChartConstants";
import LoadingBar from "../../components/loadingBar";
import StockTakingScannedSelect from "../../components/select/StockTakingScannedSelect";
import i18n from "../../i18n";
import { STOCK_TAKING } from "../../services/faApi";
import { isTNF, smartTimeDiff } from "../../services/utils";
import StockTakingDetailCard from "./StockTakingDetailCard";
import StockTakingTargets from "./StockTakingTargets";
import StockTakingUsers from "./StockTakingUsers";
import { useStyles } from "./style";

export default function StockTakingDetail() {
  const tableRef = useRef();
  const classes = useStyles();
  const userInfo = useSelector(getUserInfo);
  let history = useHistory();
  const faDialog = useDialog();
  const { t } = useTranslation("stockTaking", { i18n });
  const { id } = useParams();
  const location = useLocation();
  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [stockTaking, setStockTaking] = useState(location.state.stockTaking);

  useEffect(() => {
    StockTakingService.stat(stockTaking.id, statOnSuccess, statOnError);
    StockTakingService.findById(
      stockTaking.id,
      (data) => {
        setStockTaking(data);
      },
      (error) => {}
    );
  }, []);

  const statOnSuccess = (data) => {
    setChartData([
      {
        id: t("COUNTED"),
        label: t("COUNTED"),
        value: data.scannedItems,
      },
      {
        id: t("NOT_COUNTED"),
        label: t("NOT_COUNTED"),
        value: data.totalItems - data.scannedItems,
      },
    ]);
  };

  const refresh = () => {
    StockTakingService.stat(stockTaking.id, statOnSuccess, statOnError);
    tableRef.current.refreshTable();
  };

  const statOnError = () => {};

  const goThingDetail = (thing) => {
    const tmpTo = {
      pathname: `/thingDetail/${thing.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: thing.description,
    };
    history.push(tmpTo);
  };

  const columns = [
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (val) => {
        return <ThingDetail value={val} />;
      },
    },
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => {
        return <UserDetail value={val} type="USER" />;
      },
    },
    {
      field: "scanDate",
      label: t("SCAN_DATE"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => {
        return val && smartTimeDiff(val);
      },
    },
    {
      field: "scanned",
      label: t("SCANNED"),
      align: "center",
      width: "20%",
      valueFormatter: (scanned) => {
        if (scanned === true) return <Check />;
        else return <Block />;
      },
    },
  ];

  const newSearchForm = () => {
    return {
      stockTakingId: stockTaking.id,
      thingId: null,
      thing: null,
      thingClassId: null,
      thingClass: null,
      scanned: null,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const complete = () => {
    faDialog({
      type: "input",
      okText: t("Sonlandır"),
      cancelText: t("İptal"),
      title: t("Sayımı sonlandır"),
      description: t(
        "Sayımı sonlandırmak istiyor musunuz? Kapatma açıklaması girebilirsiniz"
      ),
    })
      .then((endDescription) => {
        StockTakingService.complete(
          stockTaking.id,
          endDescription,
          completeOnSuccess,
          completeOnError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const completeOnSuccess = (data) => {
    window.location.reload();
  };

  const completeOnError = () => {};

  const onDoubleClick = (row) => {
    if (stockTaking.status === "ACTIVE") {
      faDialog({
        type: "confirm",
        description: t(
          "Seçilen varlığın sayılmış olarak işaretlenmesini istiyor musunuz?"
        ),
      })
        .then(() => {
          StockTakingService.scanDetail(
            stockTaking.id,
            userInfo.id,
            row.thing.thid,
            () => {
              refresh();
            },
            completeOnError
          );
        })
        .catch((err) => console.log("error:", err));
    }
  };

  const saveTargets = (data) => {
    let newData = { ...stockTaking, targets: data };
    StockTakingService.save(
      {
        ...newData,
        account: { id: accountId },
      },
      (data) => {
        setStockTaking(data);
      },
      () => {}
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <StockTakingDetailCard
                  stockTaking={stockTaking}
                  setStockTaking={setStockTaking}
                />
              </Grid>

              <Grid item xs={12}>
                {stockTaking && stockTaking.id > 0 && (
                  <StockTakingUsers stockTaking={stockTaking} />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card style={{ minHeight: 295 }}>
                  <CardHeader
                    title={t("STOCK_TAKING_STATUS")}
                    data-fa-section="STOCK_TAKING_STATUS"
                  />
                  <CardContent>
                    <Grid container spacing={1}>
                      <Grid item lg={12} xs={12}>
                        <Grid item>
                          {stockTaking.status !== "DRAFT" && (
                            <FaPieChart
                              data={chartData}
                              legends={[]}
                              height="300px"
                              colors={CHART_COLORS}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                {stockTaking && stockTaking.id > 0 && (
                  <StockTakingTargets
                    stockTaking={stockTaking}
                    onSave={saveTargets}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={t("STOCK_TAKING_DETAILS")}
                data-fa-section="STOCK_TAKING_DETAILS"
              />

              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
                style={{ marginTop: 10 }}
              >
                <Grid item xs={6}>
                  <div>{searchInfo}</div>
                </Grid>
                <Grid item xs={6}>
                  <FaSearch
                    onSearch={searchSubmit}
                    onClear={searchClear}
                    onSummarySearch={searchSummary}
                    setSearchInfo={setSearchInfo}
                  >
                    <ThingAutoComplete
                      value={searchForm.thing}
                      accountId={accountId}
                      onChange={(data) => {
                        setSearchForm({
                          ...searchForm,
                          thing: data,
                          thingId: data?.id,
                        });
                      }}
                    />

                    <ThingClassAutoComplete
                      accountId={accountId}
                      value={searchForm.thingClass}
                      onChange={(data) => {
                        setSearchForm({
                          ...searchForm,
                          thingClass: data,
                          thingClassId: data?.id,
                        });
                      }}
                    />

                    <StockTakingScannedSelect
                      value={searchForm.scanned}
                      onChange={(data) => {
                        setSearchForm({
                          ...searchForm,
                          scanned: data,
                        });
                      }}
                    />
                  </FaSearch>
                </Grid>
              </Grid>

              <ThingsDataTable
                searchUrl={
                  STOCK_TAKING.searchDetail + "?stockTakingId=" + stockTaking.id
                }
                searchForm={searchForm}
                columns={columns}
                ref={tableRef}
                showHeaderText={false}
                queryParams={{
                  page: 0,
                  size: 50,
                  sort: "scanDate",
                  isAsc: false,
                }}
                faOnDoubleClick={onDoubleClick}
              />
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
