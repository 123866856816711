import { Grid, Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaInput, FaUnAuthPages } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { clearArr } from "react-base-fa/dist/fa/faBreadCrumb/breadCrumbSlice";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  loginThunk,
  setUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { DEFAULT_COUNTRY } from "services/utils";
import { RECAPTCHA_CODE } from "services/utils/Constants";
import i18n from "../../i18n";
import { INVITATION, USER } from "../../services/faApi";

export default function InvitationAcception(props) {
  let { invitationCode } = useParams();
  const dispatch = useDispatch();
  const { backGroundImage, loginPath, appLogo, loginUrl } = props;
  const faDialog = useDialog();
  const classes = useStyles();
  const { t } = useTranslation(
    ["invitationAcception", "errorMessages", "components"],
    {
      i18n,
    }
  );
  const history = useHistory();
  const [showError, setShowError] = useState(false);
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [userInvitation, setUserInvitation] = useState();
  const [isInvitationCodeValid, setIsInvitationCodeValid] = useState(false);
  const [invitationDTO, setInvitationDTO] = useState({
    email: "",
    authority: "",
    fullname: "",
    phone: "",
    account: {},
  });
  const [errorMessages, setErrorMessages] = useState({
    email: "",
    fullname: "",
    phone: "",
    account: "",
  });
  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    email: true,
    fullname: true,
    phone: true,
    account: true,
  });
  const [values, setValues] = useState({
    password: "",
    passwordConfirm: "",
    email: "",
    fullname: "",
    phone: "",
    country: DEFAULT_COUNTRY,
  });
  const [isActivated, setIsActivated] = useState({
    sms: false,
    email: false,
  });
  const [loading, setLoading] = useState(false);
  let formRef = createRef();
  const { confirmationCode } = useParams();
  let recaptchaRef = useRef();
  const lang = getCurrentLang();
  const theme = useSelector(selectThemePrefer);
  const [serverErrorMessage, setServerErrorMessage] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (invitationCode) {
      let req = requests.getSimpleGetRequest(INVITATION.findByInvitationCode, {
        code: invitationCode,
      });
      //console.log(req);
      FaAxios(req)
        .then((response) => {
          //console.log(response);
          if (response.data) {
            setIsInvitationCodeValid(true);

            let tmpData = { ...response.data };
            if (tmpData.phone && tmpData.phone.length === 10) {
              tmpData.phone = "90" + tmpData.phone;
            }

            setInvitationDTO({ ...tmpData });
            setIsFieldsEmpty({
              ...isFieldsEmpty,
              ["fullname"]: !response.data.fullname,
              ["email"]: !response.data.email,
              ["phone"]: !response.data.phone,
            });
            setValues({
              ...values,
              ["fullname"]: response.data.fullname,
              ["email"]: response.data.email,
              ["phone"]: response.data.phone,
            });
          }
          setLoading(false);
        })
        .catch((e) => {
          if (e?.response?.data?.message)
            setServerErrorMessage(e?.response?.data?.message);
          setLoading(false);
        });
    }
  }, [invitationCode]);

  const CheckEmail = () => {
    //setErrorMessages({...errorMessages,["email"]:t("EPOSTA_MEVCUT")})
    let req = requests.getSimpleGetRequest(
      USER.findByEmail + "/" + values.email
    );
    //console.log("req->", req);
    FaAxios(req)
      .then((response) => {
        setErrorMessages({ ...errorMessages, ["email"]: t("EPOSTA_MEVCUT") });
        //console.log("res->", response);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 302) {
            setErrorMessages({
              ...errorMessages,
              ["email"]: t("EPOSTA_MEVCUT"),
            });
          } else {
            setErrorMessages({ ...errorMessages, ["email"]: "" });
          }
        }
      });
  };

  const CheckPhone = () => {
    let req = requests.getSimpleGetRequest(
      USER.findByPhone + "/" + values.phone
    );
    //console.log("req->", req);
    FaAxios(req)
      .then((response) => {
        setErrorMessages({ ...errorMessages, ["phone"]: t("TELEFON_MEVCUT") });
        //console.log("res->", response);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 302) {
            //console.log("error->", e.response);
            setErrorMessages({
              ...errorMessages,
              ["phone"]: t("TELEFON_MEVCUT"),
            });
          } else {
            setErrorMessages({ ...errorMessages, ["phone"]: "" });
          }
        }
      });
  };

  const handleChange = (prop) => (event) => {
    if (prop === "password") {
      setValues({
        ...values,
        [prop]: event.target.value,
        ["passwordConfirm"]: event.target.value,
      });
      setIsFieldsEmpty({
        ...isFieldsEmpty,
        [prop]: event.target.value ? false : true,
      });
    } else {
      setValues({ ...values, [prop]: event.target.value });
      setIsFieldsEmpty({
        ...isFieldsEmpty,
        [prop]: event.target.value ? false : true,
      });
    }
  };

  const handleChangeInvitation = (prop) => (event) => {
    setInvitationDTO({ ...invitationDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setValues({ ...values, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: value ? false : true });
  };
  const handleChangeInvitationV = (prop, value) => {
    setInvitationDTO({ ...invitationDTO, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: value ? false : true });
  };

  const rejectInvitation = (event) => {
    event.preventDefault();

    setLoading(true);
    let req = requests.getSimplePostRequest(INVITATION.rejection, {
      ...invitationDTO,
    });
    //console.log(req);
    FaAxios(req)
      .then((response) => {
        //console.log(response);
        showAlert(t("REJECT_SUCCSESS"), "success");
        setTimeout(() => {
          setLoading(false);
          history.push("/login");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
        showAlert(t("RECORD_FAIL"), "error");
        setLoading(false);
      });
  };

  const confirmInvitation = (event) => {
    event.preventDefault();
    //console.log("event_>",event.submitter)
    setLoading(true);
    let req = requests.getSimplePostRequest(INVITATION.acception, {
      ...invitationDTO,
      ["user"]: values,
    });
    //console.log(req);
    FaAxios(req)
      .then((response) => {
        //console.log(response);
        showAlert(t("RECORD_SUCCSESS"), "success");
        setTimeout(() => {
          setLoading(false);
          continueAndLogin();
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
        showAlert(t("RECORD_FAIL"), "error");
        setLoading(false);
      });
  };

  const continueAndLogin = async () => {
    if (invitationDTO.email) {
      //console.log("continueAndLogin email basıldı", invitationDTO.email);
      try {
        unwrapResult(
          await dispatch(
            loginThunk(
              requests.getSimplePostRequest(loginUrl, {
                username: invitationDTO.email,
                password: values.password,
              })
            )
          )
        );
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());
        history.push("/");
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
        console.log(err);
      }
    } else if (invitationDTO.phone) {
      //console.log("continueAndLogin telefon basıldı");
      try {
        unwrapResult(
          await dispatch(
            loginThunk(
              requests.getSimplePostRequest(loginUrl, {
                username: invitationDTO.phone,
                password: values.password,
              })
            )
          )
        );
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());
        history.push("/");
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
        console.log(err);
      }
    }
  };

  const forwardingScreen = () => {
    return (
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        style={{ marginBottom: 5 }}
      >
        <Grid
          container
          item
          spacing={2}
          lg={12}
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item lg={12} xs={12}>
            <Typography
              variant="subtitle1"
              justifyContent="center"
              align="center"
            >
              {serverErrorMessage == null
                ? t("WRONG_INVITATION_CODE_MSG")
                : t(
                    "errorMessages:" + serverErrorMessage ?? "UNEXPECTED_ERROR"
                  )}
            </Typography>
          </Grid>

          <Grid item lg={3} xs={12}>
            <Button
              variant="contained"
              fullWidth
              component="span"
              color="primary"
              disableElevation
              onClick={() => {
                history.push(loginPath);
              }}
            >
              {t("CONTINUE")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const invitationAcceptionRender = () => {
    return (
      <form ref={formRef} validate="true">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: 5 }}
        >
          <Grid
            container
            item
            spacing={2}
            lg={12}
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={10} xs={12}>
              <FaInput
                label={t("FULL_NAME")}
                onChange={handleChangeInvitation("fullname")}
                value={invitationDTO.fullname || ""}
                required
              />
            </Grid>
            {values.email ? (
              <Grid item lg={10} xs={12}>
                <FaInput
                  helperText={isFieldsEmpty.phone ? errorMessages.email : ""}
                  faError={isFieldsEmpty.phone ? errorMessages.email : ""}
                  required={isFieldsEmpty.phone}
                  faRequired={isFieldsEmpty.phone}
                  faType="email"
                  disabled={!!userInvitation}
                  label={t("USER_E_MAIL")}
                  autoComplete="email"
                  onChange={handleChangeInvitation("email")}
                  onBlur={CheckEmail}
                  value={invitationDTO.email || ""}
                />
              </Grid>
            ) : null}

            <Grid item lg={10} xs={12}>
              <FaInput
                disabled
                fullWidth
                variant="outlined"
                label={t("ROL")}
                required
                value={t("ROLE_" + invitationDTO.authority, {
                  ns: "components",
                })}
              ></FaInput>
            </Grid>
            {values.phone ? (
              <Grid item lg={10} xs={12}>
                <FaInput
                  required={isFieldsEmpty.phone && isFieldsEmpty.email}
                  faRequired={isFieldsEmpty.email}
                  faError={errorMessages.phone}
                  helperText={errorMessages.phone}
                  label={t("PHONE_NUMBER")}
                  onChange={(event) =>
                    handleChangeInvitationV(
                      "phone",
                      event.target.allValues.value
                    )
                  }
                  onBlur={CheckPhone}
                  faMask="+## (###) ### ## ##"
                  faMaskChar="*"
                  //helperText="Örnek : +999999999999"
                  value={invitationDTO.phone}
                />
              </Grid>
            ) : null}

            <Grid item lg={10} xs={12}>
              <FaInput
                faType="password"
                passwordRepeat={values.passwordRepeat}
                required
                faRequired={isFieldsEmpty.password}
                label={t("PASSWORD")}
                onChange={handleChange("password")}
                value={values.password || ""}
                min={5}
              />
              <PasswordStrengthBar
                password={values.password}
                scoreWords={[
                  t("PASSWORD_LVL1"),
                  t("PASSWORD_LVL2"),
                  t("PASSWORD_LVL3"),
                  t("PASSWORD_LVL4"),
                  t("PASSWORD_LVL5"),
                ]}
                shortScoreWord={t("PASSWORD_TOO_SHORT")}
              />
            </Grid>

            <Grid item lg={5} xs={6}>
              <FaButton
                async
                formRef={formRef}
                disabled={loading}
                loading={loading}
                type="submit"
                label={t("CONFIRM")}
                variant="contained"
                faClick={confirmInvitation}
              />
            </Grid>
            <Grid item lg={5} xs={6}>
              <FaButton
                async
                formRef={formRef}
                disabled={loading}
                label={t("REJECT")}
                variant="contained"
                type="submit"
                color="secondary"
                loading={loading}
                faClick={rejectInvitation}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom align="center">
                {t("HESAP_VAR_MI")}
                <Link
                  onClick={() => history.push(loginPath)}
                  className={classes.link}
                  variant="body2"
                  underline="hover"
                >
                  {t("LOG_IN")}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  };

  return (
    <FaUnAuthPages maxWidth="md">
      <Grid
        container
        spacing={3}
        justifyContent="center"
        style={{ marginBottom: 5 }}
      >
        <Grid
          item
          container
          spacing={3}
          lg={8}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={appLogo}
            height="78"
            alt="logo"
            className={classes.appLogo}
          />
          <Prompt when={!!userInvitation} message={t("LEAVE_PAGE_WARNNNG")} />
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            hl={lang}
            theme={theme}
            badge="bottomright"
            sitekey={RECAPTCHA_CODE}
          />
          {invitationCode && isInvitationCodeValid
            ? invitationAcceptionRender()
            : forwardingScreen()}
        </Grid>
      </Grid>
    </FaUnAuthPages>
  );
}

InvitationAcception.propTypes = {
  loginPath: PropTypes.string,
  backGroundImage: PropTypes.any,
  appLogo: PropTypes.any,
};
