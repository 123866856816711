import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import JournalTypeAutoComplete from "components/autocomplete/JournalTypeAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import DynamicFieldsDetail from "components/dynamicFieldsDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingDynamicFormService } from "services/ThingDynamicFormService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { JOURNAL } from "../../services/faApi";

export default function Journal() {
  const { t } = useTranslation(["journal", "errorMessages"], { i18n });
  const labelClasses = labelStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  let history = useHistory();
  const role = useSelector(getRole);
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;

  const accountId = localStorage.accountId;

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      description: "",
      thingId: null,
      journalTypeId: null,
      journalDateStart: null,
      journalDateEnd: null,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const [selectedJournal, setSelectedJournal] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);

  const columns = [
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "30%",
      valueFormatter: (value) => <ThingDetail value={value} />,
    },
    {
      field: "journalType",
      label: t("JOURNAL_TYPE"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
    },
    {
      field: "journalDate",
      label: t("JOURNAL_DATE"),
      align: "center",
      width: "20%",
      type: "smartDate",
    },
  ];

  const onDoubleClick = (row) => {
    setSelectedJournal(row);
    setIsDetailDialogOpen(true);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  useEffect(() => {
    if (selectedJournal) {
      ThingDynamicFormService.findFormAndData(
        accountId,
        "JOURNAL_CLASS",
        selectedJournal.journalType.id,
        selectedJournal.id,
        (data) => {
          if (data) {
            setDynamicFormList(data.fieldList);
            setDynamicValues(data.data.detailData ? data.data.detailData : []);
          }
        },
        (error) => {}
      );
    }
  }, [selectedJournal]);

  const renderDetail = () => {
    return (
      <FaDialog
        title={"Günce Detay"}
        faOpen={isDetailDialogOpen}
        faHandleClose={() => {
          setIsDetailDialogOpen(false);
          setSelectedJournal(null);
          setDynamicFormList([]);
          setDynamicValues([]);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {isTNF(t("THING"), t("THING_T2C"))}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <ThingDetail value={selectedJournal?.thing} />
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("USER")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <UserDetail value={selectedJournal?.user} type="USER" />
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("JOURNAL_TYPE")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {selectedJournal?.journalType?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DESCRIPTION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {selectedJournal?.description}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("JOURNAL_DATE")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {getDateTimeWFormat(selectedJournal?.journalDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("GPS_LOCATION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {selectedJournal?.gpsLocation}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("VISITOR_ID")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {selectedJournal?.clientInfo?.visitorId}
                </Typography>
              </Grid>
              <Grid item container spacing={1}>
                <DynamicFieldsDetail
                  selectedDetails={dynamicFormList}
                  details={dynamicFormList}
                  data={dynamicValues}
                  objectId={selectedJournal?.id}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderDetail()}
        <ThingsDataTable
          searchUrl={JOURNAL.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          data-fa-list="List"
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />
              <JournalTypeAutoComplete
                value={searchForm.journalType}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "journalType",
                    data,
                    "journalTypeId",
                    data?.id
                  );
                }}
              />
              <DateRangeSelector
                label={t("JOURNAL_DATE")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    journalDateStart: data.startDate,
                    journalDateEnd: data.endDate,
                  });
                }}
                data-fa-search="TARIH"
              />
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>

        {/* <Adsense value="RklmAlt" /> */}
      </Suspense>
    </>
  );
}
