import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { CAMPAIGN } from "../../services/faApi";

export default function CampaignAutoComplete(props) {
  const { value, accountId, onChange, required, type } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="name"
      faFilterProp="query"
      getOptionLabel={(option) => (option.name ? option.name : "")}
      requestUrl={
        CAMPAIGN.findCampaign + "?accountId=" + accountId + "&type=" + type
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={type === "EVENT" ? t("EVENT") : t("CAMPAIGN")}
      required={required}
    />
  );
}
