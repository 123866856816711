import { SvgIcon } from "@mui/material";
import { default as React } from "react";

export default function ThingsNoImageIcon(props) {
  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <g>
        <path
          d="M14.4,30.9h18.8c1.7,0,3.2-1,3.7-2.6l2.6-6.8h21l2.6,6.7c0.6,1.6,2.1,2.6,3.7,2.6h3.7l12.1-8h-13L67,16.1
		c-0.6-1.6-2.1-2.6-3.7-2.6H36.7c-1.7,0-3.2,1-3.7,2.6l-2.6,6.8h-20c-2.2,0-4,1.8-4,4v46.6l8-5.3V30.9z"
        />
      </g>
      <path
        d="M97.2,21.7c-1.4-2.1-4.2-2.7-6.3-1.3L82.6,26l-12.1,8l-8.1,5.4c-3.3-2.9-7.6-4.6-12.4-4.6
	c-10.4,0-18.9,8.5-18.9,18.9c0,2.1,0.3,4,0.9,5.9L14.4,71.2v0l-8,5.3L4,78.1c-2.1,1.4-2.7,4.2-1.3,6.3c0.8,1.2,2.1,1.9,3.5,2
	c1,0.1,2-0.2,2.8-0.7l0.7-0.4c0.2,0,0.4,0.1,0.7,0.1h79.2c2.2,0,4-1.8,4-4V30c0-0.1,0-0.3,0-0.4L96,28
	C98.1,26.6,98.6,23.8,97.2,21.7z M50,42.8c1.9,0,3.7,0.5,5.2,1.3L39.1,54.8c0-0.4-0.1-0.7-0.1-1.1C39.1,47.7,44,42.8,50,42.8z
	 M85.6,77.3H21.7l15-10c3.4,3.4,8.1,5.5,13.3,5.5c10.4,0,18.9-8.6,18.9-19.1c0-2.4-0.5-4.7-1.3-6.9l-7,4.6c0.2,0.7,0.2,1.5,0.2,2.3
	c0,6.1-4.9,11.1-10.9,11.1c-2.4,0-4.5-0.8-6.3-2.1l17-11.3l7-4.6l17.9-11.9V77.3z"
      />
    </SvgIcon>
  );
}
