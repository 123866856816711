import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingsMapHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Noktanızın <b>Konumu</b>’nun{" "}
            <b>Seçildiği/Güncellendiği</b> alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='THING_MAP_EDIT_BUTTON']",
        intro: (
          <TutorialElement icon="click">
            Etkileşim Noktanızın <b>Konumu</b>’nu seçmek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='SELECT_LOCATION_DIALOG']",
        intro: (
          <TutorialElement icon="info">
            <b>“Harita üzerinden coğrafi konum seç”</b> veya{" "}
            <b>“Mekanlar içinden seç”</b> seçeneklerinden birine tıklayın.
            <br />
            <br />
            <b>ÖNEMLİ NOT:</b>
            <br />
            İç mekân içinden konum seçmek için{" "}
            <b>Tanım ve Şablonlar {">"} İç Mekanlar</b> alt menüsünden mekâna
            ait kroki, çizim vb dokümanın eklenmiş olması gerekmektedir. İç
            Mekânın tanımlandığından emin olun.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='THING_MAP_EDIT_BUTTON']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#dialog-form",
        intro: (
          <TutorialElement icon="info">
            <div style={{ textAlign: "center" }}>
              <b>Konum Açıklama</b>’yı girin ve Kaydet’e basın.
              <br />
              Ya da
              <br />
              Haritadaki konumu değiştirmek için{" "}
              <b>imleci sürükleyip bırakın</b> ve <b>Kaydet</b>’e basın.
            </div>
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("[data-fa-button='COGRAFI_KONUM_SEC']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/ic_mekan_secimi.png"
                }
                width="451"
                height="266"
              ></img>
              <br />
              Tanımlanmış İç Mekanlardan birini seçin.
            </div>
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/ic_mekan_kroki.png"
                }
                width="681"
                height="452"
              ></img>
              <br />
              Konum belirlemek için <b>imleci sürükleyip</b> bırakın.
              <br />
              <b>Konum, Kat ve Oda No</b> bilgilerini girin.
              <br />
              <b>Kaydet</b>’e basın.
            </div>
          </TutorialElement>
        ),
        tooltipClass: classes.icMekanKrokiClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingsMapHelp.propTypes = {
  value: PropTypes.string,
};
