import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import { green, grey } from "@mui/material/colors";
import FormControlLabel from "@mui/material/FormControlLabel";
import withStyles from '@mui/styles/withStyles';
import Switch from "@mui/material/Switch";
import ThingsDataTable from "components/thingsDataTable";
import ThingsNotificationsHelp from "features/help/ThingsNotificationsHelp";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ISSUE } from "services/faApi";
import { IssueDesc, smartTimeDiff } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingIssues(props) {
  const { thing } = props;
  const classes = useStyles();
  const tableRef = useRef();
  let history = useHistory();
  const gridClasses = gridStyles();

  const { t } = useTranslation("thingDetail", { i18n });

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columns = [
    {
      field: "status",
      label: t("DURUM"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        if (value === "OPEN") {
          return <FormControlLabel control={<GreenSwitch checked={true} />} />;
        } else {
          return <FormControlLabel control={<Switch checked={false} />} />;
        }
      },
    },
    {
      field: "id",
      label: t("ISSUE"),
      align: "center",
      width: "60%",
      valueFormatter: (val, row) => IssueDesc(row),
    },
    {
      field: "notificationDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
  ];

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/issues/detail/${row.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-thingLinks">
        <CardHeader
          title={t("ISSUES")}
          data-fa-section="ISSUES"
          action={
            <ThingsNotificationsHelp value="thingDetail.thingNotifications" />
          }
        />
        {thing && thing.id > 0 && (
          <ThingsDataTable
            dataUrl={ISSUE.findThingIssues + "?thingId=" + thing.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "notificationDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("ISSUES")}
            showHeader={false}
            faOnDoubleClick={onDoubleClick}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
