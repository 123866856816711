import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ProjectService } from "services/ProjectService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import ProjectEdit from "./ProjectEdit";
import ProjectThings from "./ProjectThings";
import ProjectUsers from "./ProjectUsers";

export default function ProjectDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation(["project", "components"], { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    ProjectService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setProject({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onSave = () => {
    refresh();
  };

  const editDialogRender = () => {
    return (
      <ProjectEdit
        project={project}
        ref={dialogRef}
        onSave={onSave}
      ></ProjectEdit>
    );
  };
  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {editDialogRender()}
      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={t("PROJECT_INFO")}
                  data-fa-section="PROJECT_INFO"
                  action={
                    <IconButton aria-label="settings" size="large">
                      <EditIcon onClick={() => openEditDialog()} />
                    </IconButton>
                  }
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("NAME")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {project.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DESCRIPTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {project.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("START_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(project.startDate)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={labelClasses.LabelLastRow}
                        >
                          <Typography className={labelClasses.LabelHeader}>
                            {t("END_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(project.endDate)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {project && project.id > 0 && (
                <ProjectUsers project={project}></ProjectUsers>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {project && project.id > 0 && (
                <ProjectThings project={project}></ProjectThings>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Suspense>
  </>;
}
