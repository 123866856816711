import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import IssueCloseTypeRadio from "components/radio/IssueCloseTypeRadio";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { IssueService } from "services/issue/issueService";
import { IsFeedbackInfoExists } from "services/utils/IssueUtils";
import i18n from "../../i18n";

const IssueClose = forwardRef((props, ref) => {
  const { onChange, onClose, open, accPreferences, issue } = props;
  const { t } = useTranslation("issue", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [openFeedbackCloseDialog, setOpenFeedbackCloseDialog] = useState(false);

  const newCloseIssue = () => {
    return {
      issueId: issue?.id,
      closeNote: "",
      closeDate: new Date(),
      closeType: {
        id: null,
      },
      taskStatus: "CANCELED",
    };
  };

  const [closeIssueDTO, setCloseIssueDTO] = useState(newCloseIssue());

  useEffect(() => {
    if (open) {
      setCloseIssueDTO(newCloseIssue());
    }
  }, [open]);

  const handleChange = (prop) => (event) => {
    setCloseIssueDTO({ ...closeIssueDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setCloseIssueDTO({ ...closeIssueDTO, [prop]: value });
  };

  const saveSuccess = (data) => {
    showAlert(t("TASK_CLOSED"), "success");
    setCloseIssueDTO(newCloseIssue());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const sorunKapat = () => {
    if (accPreferences && accPreferences.feedbackTaskClose === "ACCEPT") {
      sorunKapatRequest(true);
    } else if (
      (accPreferences && accPreferences.feedbackTaskClose === "NEVER") ||
      !IsFeedbackInfoExists(issue)
    ) {
      sorunKapatRequest(false);
    } else {
      setOpenFeedbackCloseDialog(true);
    }
  };

  const sorunKapatRequest = (feedback) => {
    setLoading(true);
    let tmp_closeDate = closeIssueDTO.closeDate
      ? new Date(closeIssueDTO.closeDate).addHours(+3)
      : null; //FIXME +3 saat

    let closeIssueRequestData = {
      issueId: closeIssueDTO.issueId,
      closeNote: closeIssueDTO.closeNote,
      closeDate: tmp_closeDate
        ? tmp_closeDate.toJSON().slice(0, 10) +
          " " +
          tmp_closeDate.toJSON().slice(11, 16)
        : null,
      closeTypeId: closeIssueDTO.closeType?.id,
      feedback: feedback,
      taskStatus: closeIssueDTO.taskStatus,
    };
    IssueService.closeIssue(closeIssueRequestData, saveSuccess, saveError);
  };

  const newFormRender = () => {
    return <>
      <FaDialog
        title={t("SORUN_KAPAT")}
        faOpen={open}
        faOnSubmit={sorunKapat}
        loading={loading}
        showSaveButton
        faHandleClose={(data) => {
          onClose();
          setCloseIssueDTO(newCloseIssue());
        }}
        faMaxWidth="md"
        saveButtonTitle={t("SORUN_KAPAT")}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <IssueCloseTypeRadio
              label={t("CLOSE_TYPE")}
              required={true}
              value={closeIssueDTO.closeType}
              accountId={issue?.account?.id}
              onChange={(data) => {
                handleChangeV("closeType", data);
              }}
              id="issueCloseTypeRadio"
            />
          </Grid>

          <Grid item xs={12}>
            <FaDatePicker
              faType="dateTime"
              label={t("CLOSE_DATE")}
              style={{ minHeight: 65 }}
              value={closeIssueDTO.closeDate}
              faOnChange={(val) => {
                handleChangeV("closeDate", val);
              }}
              id="issueCloseDate"
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("CLOSE_NOTE")}
              value={closeIssueDTO.closeNote}
              inputProps={{ maxLength: 4000 }}
              onChange={(event) => {
                handleChangeV("closeNote", event.target.value);
              }}
              id="issueCloseDescription"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" component="fieldset">
              <FormLabel component="legend">
                Açık görevlerin durumu ne yapılsın?
              </FormLabel>
              <RadioGroup
                row
                aria-label="position"
                value={"" + closeIssueDTO.taskStatus}
                onChange={(event) => {
                  handleChangeV("taskStatus", event.target.value);
                }}
              >
                <FormControlLabel
                  value={"CANCELED"}
                  control={<Radio color="primary" />}
                  label={"İptal"}
                />
                <FormControlLabel
                  value={"COMPLETED"}
                  control={<Radio color="primary" />}
                  label={"Tamamlandı"}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </FaDialog>
    </>;
  };
  const feedbackCloseDialog = () => {
    return (
      <Dialog
        open={openFeedbackCloseDialog}
        onClose={() => {
          setOpenFeedbackCloseDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Geribildirim gönderilsin mi?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bildirim sahibine kapanmasıyla ilgili geribildirim gönderilecektir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenFeedbackCloseDialog(false);
              sorunKapatRequest(false);
            }}
            color="primary"
          >
            Gönderme
          </Button>
          <Button
            onClick={() => {
              setOpenFeedbackCloseDialog(false);
              sorunKapatRequest(true);
            }}
            color="primary"
            autoFocus
          >
            Gönder
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      {newFormRender()}
      {feedbackCloseDialog()}
    </>
  );
});

export default IssueClose;
