import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import IssueClassByThingClassAutoComplete from "./IssueClassByThingClassAutoComplete";
import ThingClassAutoComplete from "./ThingClassAutoComplete";

export default function IssueClassAndThingClassAutoComplete(props) {
  const { value, accountId, thingClassId, onChange, required } = props;

  const [thingClass, setThingClass] = useState(null);

  const { t } = useTranslation("components", { i18n });

  return (
    <Grid
      item
      container
      alignItems="center"
      justifyContent="center"
      spacing={1}
    >
      <Grid item xs={6}>
        <ThingClassAutoComplete
          accountId={accountId}
          onChange={(data) => {
            setThingClass(data);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <IssueClassByThingClassAutoComplete
          accountId={accountId}
          thingClassId={thingClass?.id}
          value={value}
          disabled={!!!thingClass}
          onChange={(data) => {
            onChange(data);
          }}
        />
      </Grid>
    </Grid>
  );
}
