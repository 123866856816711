import { Button, Grid } from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CampaignService } from "services/CampaignService";
import { GetFieldByLanguage } from "services/utils";
import i18n from "../../i18n";

const CampaignPageEdit = forwardRef((props, ref) => {
  const { onSave, campaign, page, currentLang } = props;
  const { t } = useTranslation("campaign", { i18n });

  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newPage = () => {
    return {
      campaign: { id: campaign.id },
      name: null,
      description: null,
    };
  };
  const [pageDTO, setPageDTO] = useState(newPage());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (page && page?.id) {
      setPageDTO({ ...page });
    } else if (campaign && campaign?.id) {
      setPageDTO({
        name: null,
        description: null,
        campaign: { id: campaign.id },
      });
    }
  }, [page, campaign]);

  const savePage = () => {
    setLoading(true);

    CampaignService.savePage(pageDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(!pageDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    setPageDTO(newPage());
    onSave(data);
  };

  const saveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setPageDTO({ ...pageDTO, [prop]: event.target.value });
  };

  const onPageDelete = (data) => {
    faDialog({
      description: t("Sayfayı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CampaignService.deletePage(
          pageDTO,
          (data) => {
            showAlert(t("Sayfa Silindi"), "success");
            setIsDialogOpen(false);
            onSave();
          },
          (error) => {
            showAlert(t("Bağlı Kayıtlar Mevcut"), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <>
      <FaDialog
        title={t("EDIT_CAMPAIGN_PAGE")}
        faOpen={isDialogOpen}
        faOnSubmit={savePage}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsDialogOpen(false);
          setPageDTO(page ? page : newPage());
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("NAME")}
              required
              onChange={(event) => {
                setPageDTO({
                  ...pageDTO,
                  multiLangName: {
                    ...pageDTO.multiLangName,
                    [currentLang]: event.target.value,
                  },
                });
              }}
              value={pageDTO.multiLangName?.[currentLang] || ""}
              helperText={
                pageDTO.multiLangName?.[currentLang] === undefined
                  ? GetFieldByLanguage(pageDTO.multiLangName, currentLang)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FaInput
              label={t("DESCRIPTION")}
              faType="description"
              inputProps={{ maxLength: 255 }}
              onChange={(event) => {
                setPageDTO({
                  ...pageDTO,
                  multiLangDescription: {
                    ...pageDTO.multiLangDescription,
                    [currentLang]: event.target.value,
                  },
                });
              }}
              value={pageDTO.multiLangDescription?.[currentLang] || ""}
              helperText={
                pageDTO.multiLangDescription?.[currentLang] === undefined
                  ? GetFieldByLanguage(
                      pageDTO.multiLangDescription,
                      currentLang
                    )
                  : ""
              }
            />
          </Grid>
        </Grid>

        {pageDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={onPageDelete} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </FaDialog>
    </>
  );
});

export default CampaignPageEdit;
