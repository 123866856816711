import { Button, Grid } from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";
import { useStyles } from "./style";

const CampaignCalendarEdit = forwardRef((props, ref) => {
  const { campaign, onSave, calendar, onDelete } = props;
  const { t } = useTranslation("campaign", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const newEvent = () => {
    return {
      campaign: { id: campaign?.id },
      name: null,
      eventDate: new Date(),
    };
  };

  const [campaignEvent, setCampaignEvent] = useState(newEvent());

  useEffect(() => {
    if (calendar) {
      setCampaignEvent({ ...calendar });
    }
  }, [calendar]);
  useImperativeHandle(ref, () => ({
    openDialog(date) {
      if (date) setCampaignEvent({ ...campaignEvent, eventDate: date });
      setIsDialogOpen(true);
    },
  }));
  const saveCalendar = () => {
    setLoading(true);
    CampaignService.saveCalendar(
      campaignEvent,
      (data) => {
        onSave();
        setIsDialogOpen(false);
        setCampaignEvent(newEvent());
        setLoading(false);
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };
  const handleChange = (prop) => (event) => {
    setCampaignEvent({ ...campaignEvent, [prop]: event.target.value });
  };
  const handleChangeV = (prop, value) => {
    setCampaignEvent({ ...campaignEvent, [prop]: value });
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("CAMPAIGN_CALENDAR")}
          faOpen={isDialogOpen}
          faOnSubmit={saveCalendar}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsDialogOpen(false);
            setCampaignEvent(newEvent());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={campaignEvent?.name || ""}
              />
            </Grid>

            <Grid item xs={12}>
              <FaDatePicker
                label={t("EVENT_DATE")}
                faType="dateTime"
                name="eventDate"
                value={campaignEvent?.eventDate || ""}
                faOnChange={(val) => {
                  handleChangeV("eventDate", val);
                }}
              />
            </Grid>
            {calendar && calendar.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button
                  onClick={() => {
                    onDelete(calendar);
                    setIsDialogOpen(false);
                  }}
                  color="secondary"
                >
                  {t("Sil")}
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
});

export default CampaignCalendarEdit;
