import { Grid } from "@mui/material";
import { default as React, Suspense } from "react";
import LoadingBar from "../../components/loadingBar";
import SolutionCountByLocation from "./solution/SolutionCountByLocation";
import SolutionCountByOrganization from "./solution/SolutionCountByOrganization";
import SolutionCountBySolutionUser from "./solution/SolutionCountBySolutionUser";
import SolutionCountByThingClass from "./solution/SolutionCountByThingClass";
import SolutionCountByUser from "./solution/SolutionCountByUser";
import SolutionCountByVendor from "./solution/SolutionCountByVendor";
import SolutionCountStat from "./solution/SolutionCountStat";
import SolutionLaborTimeBySolutionUser from "./solution/SolutionLaborTimeBySolutionUser";
import SolutionLaborTimeByThingClass from "./solution/SolutionLaborTimeByThingClass";
import SolutionStocks from "./solution/SolutionStocks";

export default function SolutionStats() {
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <SolutionCountStat />
          </Grid>
          <Grid item lg={12}>
            <SolutionCountByLocation />
          </Grid>
          <Grid item lg={12}>
            <SolutionCountByOrganization />
          </Grid>
          <Grid item lg={12}>
            <SolutionCountByThingClass />
          </Grid>
          <Grid item lg={12}>
            <SolutionCountByUser />
          </Grid>
          <Grid item lg={12}>
            <SolutionCountByVendor />
          </Grid>
          <Grid item lg={12}>
            <SolutionCountBySolutionUser />
          </Grid>
          <Grid item lg={12}>
            <SolutionLaborTimeBySolutionUser />
          </Grid>
          <Grid item lg={12}>
            <SolutionLaborTimeByThingClass />
          </Grid>
          <Grid item lg={12}>
            <SolutionStocks />
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
