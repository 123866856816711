import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function IssueDetailTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        //0
        intro: (
          <TutorialElement icon="info">
            <div>
              <p>
                Bildirilen Sorunlara ait detay bilgilerin gösterildiği sayfadır.
              </p>
              <p>
                Bu sayfada;
                <ul>
                  <li>
                    <b>Sorun Bilgileri</b>’ni, <b>Açık/Kapalı</b> olma
                    durumlarını, soruna ait Görsel(ler)i, sorunla ilgili ek
                    bilgileri (Ek Alanlar) ve Görev Geçmişi’ni izleyebilirsiniz.
                  </li>
                  <li>
                    <b>Görsel</b> ekleyebilirsiniz.
                  </li>
                  <li>
                    Açık soruna <b>Görev Ataması</b> yapabilirsiniz.
                  </li>
                  <li>
                    Açık soruna <b>Servis/Operasyon Bilgileri</b>{" "}
                    girebilirsiniz.
                  </li>
                  <li>
                    <b>Açık Sorun</b>’u kapatabilirsiniz.
                  </li>
                  <li>
                    <b>Not</b> ekleyebilirsiniz.
                  </li>
                  <li>
                    Sorun bildirimi yapan müşterinize <b>mesaj</b>{" "}
                    gönderebilirsiniz.
                  </li>
                </ul>
              </p>
            </div>
          </TutorialElement>
        ),
      },
      // {
      //   //1
      //   element: "[data-fa-button='PHOTOS_ADD']",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("Görsel eklemek için tıklayın.")}
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     stepsRef.current.updateStepElement(index);
      //   },
      // },
      // {
      //   //2
      //   element: "#statusSwitch",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("Sorunu kapatmak için tıklayın.")}
      //     </TutorialElement>
      //   ),
      //   comp: "IssueDetail",
      //   onBeforeChange: (index) => {
      //     stepsRef.current.updateStepElement(index);
      //   },
      // },
      // {
      //   //
      //   element: "#issueCloseTypeRadio",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("Kapatma Tipi’ni seçin.")}
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     $("#statusSwitch")[0].click();
      //   },
      //   onAfterChange: (index) => {
      //     stepsRef.current.updateStepElement(index);
      //   },
      // },
      // {
      //   //
      //   element: "#issueCloseDate",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("Kapatma Tarihi’ni seçin.")}
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     stepsRef.current.updateStepElement(index);
      //   },
      // },
      // {
      //   //
      //   element: "#issueCloseDescription",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("İsteğe bağlı Açıklama girin ve Sorunu Kapat’a basın.")}
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     stepsRef.current.updateStepElement(index);
      //   },
      // },
      // {
      //   //
      //   element: "[data-fa-button='SOLUTION_INFO_ADD']",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t(
      //         "Servis/Operasyon Bilgisi girmek için tıklayın ve “Yapılan İşlemler”, “İşçilik Süresi”, “Kullanılan Malzemeler” ve “Kontrol Listesi” gibi bilgileri girin."
      //       )}
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     $("[aria-label='close']")[0].click();
      //     stepsRef.current.updateStepElement(index);
      //     $("[data-fa-button='PHOTOS_ADD']")[0].focus();
      //   },
      // },
      // {
      //   //
      //   element: "[data-fa-button='NOTE_ADD']",
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("Not eklemek için tıklayın. ")}
      //     </TutorialElement>
      //   ),
      //   comp: "IssueDetail",
      // },
      // {
      //   //
      //   element: null,
      //   intro: (
      //     <TutorialElement icon="click">
      //       {t("Notunuzu girin ve Kaydet’e basın.")}
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     $("[data-fa-button='NOTE_ADD']")[0].click();
      //   },
      // },
      // {
      //   //
      //   element: "[data-fa-button='ADD_BENEFICIARY_NOTES']",
      //   intro: (
      //     <TutorialElement icon="click">
      //       <div>
      //         <p>
      //           {t(
      //             "Sorun bildiren müşterinize mesaj göndermek için tıklayın. "
      //           )}
      //         </p>
      //         <p>
      //           <ul>
      //             <li>
      //               Mesaj Kanalı olarak “SMS”, “E-Posta” veya “Mobil
      //               Bildirim”den birini seçin.
      //             </li>
      //             <li>Mesajınızı girin ve Mesaj Gönder’e tıklayın.</li>
      //           </ul>
      //         </p>
      //       </div>
      //     </TutorialElement>
      //   ),
      //   onBeforeChange: (index) => {
      //     $("[aria-label='close']")[0].click();
      //   },
      // },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"issue_detail"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
