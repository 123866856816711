import { faMap, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Card from "@mui/material/Card";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import Map, { GeolocateControl, Layer, Popup, Source } from "react-map-gl";
import { useHistory } from "react-router-dom";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { CUSTOMER_INTERACTION } from "services/faApi";
import { ParseLocation, isTNF } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "../thingsMap/layers";
import { useStyles } from "./style";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA"; // Set your mapbox token here

const geolocateStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  margin: 10,
};

export default function CustomerInteractions(props) {
  const { customer } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();
  const accountId = localStorage.accountId;

  const { t } = useTranslation(["customerInteraction", "components"], { i18n });
  const mapRef = useRef();

  const [openMap, setOpenMap] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [gpsLocation, setGpsLocation] = useState(null);
  const [ipGpsLocation, setIpGpsLocation] = useState(null);
  const [popupInfo, setPopupInfo] = useState(null);

  const [viewport, setViewPort] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });

  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });

  let history = useHistory();

  const columns = [
    {
      field: "date",
      label: t("DATE"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "operation",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return t("CUSTOMER_INTERACTION_OPERATION." + value, {
          ns: "components",
        });
      },
    },
    {
      field: "gpsLocation",
      label: t(""),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (row.gpsLocation || row.ipGpsLocation) {
          return (
            <IconButton
              onClick={() => {
                setSelectedDetail(row);
                let location = null;
                if (row.gpsLocation) location = ParseLocation(row.gpsLocation);
                else if (row.ipGpsLocation)
                  location = ParseLocation(row.ipGpsLocation);

                setGeojson({
                  type: "FeatureCollection",
                  features: [
                    {
                      type: "Feature",
                      geometry: {
                        type: "Point",
                        coordinates: [location.lng, location.lat],
                      },
                      properties: {
                        id: row.id,
                      },
                    },
                  ],
                });
                setOpenMap(true);
              }}
              size="small"
              color="primary"
              title={t("Harita")}
            >
              <FontAwesomeIcon icon={faMap} />
            </IconButton>
          );
        }
      },
    },
    {
      field: "tid",
      label: t(""),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        if (
          row.operation === "CUSTOMER" ||
          row.operation === "SUGGESTION" ||
          row.operation === "ISSUE"
        ) {
          return (
            <IconButton
              onClick={() => {
                onDoubleClick(row);
              }}
              size="small"
              color="primary"
              title={t("Detay")}
            >
              <FontAwesomeIcon icon={faSearch} />
            </IconButton>
          );
        }
      },
    },
  ];

  const getLat = () => {
    if (gpsLocation) return gpsLocation.lat;
    else if (ipGpsLocation) return ipGpsLocation.lat;
    else return null;
  };

  const getLng = () => {
    if (gpsLocation) return gpsLocation.lng;
    else if (ipGpsLocation) return ipGpsLocation.lng;
    else return null;
  };

  const showAllInteractions = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "date" };
    CustomerInteractionService.search(
      searchForm,
      paging,
      findCustomerInteractionOnSuccess,
      (error) => {}
    );
  };

  const findCustomerInteractionOnSuccess = (data) => {
    const temp = data
      .filter((x) => x.gpsLocation || x.ipGpsLocation)
      .map((item) => {
        var location = ParseLocation(item.gpsLocation ?? item.ipGpsLocation);
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [location.lng, location.lat],
          },
          properties: {
            id: item.id,
          },
        };
      });

    setGeojson({
      type: "FeatureCollection",
      features: temp,
    });

    setOpenMap(true);
  };

  const onDoubleClick = (row) => {
    if (row.operation === "CUSTOMER") {
      const tmpTo = {
        pathname: `/customer/detail/${row.tid}`,
        breadCrumbKey: `/customer/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "LIKE") {
      const tmpTo = {
        pathname: `/like/detail/${row.tid}`,
        breadCrumbKey: `/like/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "SUGGESTION") {
      const tmpTo = {
        pathname: `/suggestions/detail/${row.tid}`,
        breadCrumbKey: `/suggestions/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "ISSUE") {
      const tmpTo = {
        pathname: `/issues/detail/${row.tid}`,
        breadCrumbKey: `/issues/detail/:id`,
      };
      history.push(tmpTo);
    }
  };

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      thing: null,
      thingId: null,
      operation: null,
      operationList: null,
      operationDateValue: null,
      operationDateStart: null,
      operationDateEnd: null,
      limit: 20, //chartLimit
      customerId: customer?.id,
    };

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const renderMapDialog = () => {
    return (
      <Dialog
        open={openMap}
        onClose={() => {
          setOpenMap(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div style={{ height: "83vh", width: "500px", marginTop: 5 }}>
              <Map
                {...viewport}
                width="100%"
                height="100%"
                mapStyle="mapbox://styles/mapbox/light-v10"
                onMove={(evt) => setViewPort(evt.viewState)}
                mapboxAccessToken={MAPBOX_TOKEN}
                //onClick={onClick}
                ref={mapRef}
                interactiveLayerIds={[
                  unclusteredPointLayer.id,
                  clusterLayer.id,
                ]}
              >
                <GeolocateControl
                  style={geolocateStyle}
                  positionOptions={{ enableHighAccuracy: true }}
                  trackUserLocation={true}
                />

                <Source
                  id="things"
                  type="geojson"
                  data={geojson}
                  cluster={true}
                  clusterMaxZoom={14}
                  clusterRadius={50}
                >
                  <Layer {...clusterLayer} />
                  <Layer {...clusterCountLayer} />
                  <Layer {...unclusteredPointLayer} />
                </Source>

                {popupInfo && (
                  <Popup
                    anchor="top"
                    longitude={Number(popupInfo.lng)}
                    latitude={Number(popupInfo.lat)}
                    closeOnClick={false}
                    onClose={() => setPopupInfo(null)}
                  ></Popup>
                )}
              </Map>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenMap(false);
            }}
            color="primary"
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return <>
    {renderMapDialog()}
    <Card style={{ minHeight: 200 }} className="fai-customerInteractions">
      <CardHeader
        title={t("Müşteri Etkileşimi")}
        data-fa-section="Müşteri Etkileşimi"
        action={
          <IconButton aria-label="settings" onClick={showAllInteractions} size="large">
            <FontAwesomeIcon icon={faMap} />
          </IconButton>
        }
      />
      {customer && customer.id > 0 && (
        <ThingsDataTable
          searchUrl={CUSTOMER_INTERACTION.search}
          searchForm={searchForm}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "date",
          }}
          columns={columns}
          ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          headerText={t("Müşteri Etkileşimi")}
          showHeader={false}
          columnClassName={gridClasses.GenericColumn}
          faOnDoubleClick={onDoubleClick}
        />
      )}
    </Card>
  </>;
}
