import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SchedulePlanService } from "services/SchedulePlanService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SchedulePlanSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });
  const accountId = localStorage.accountId;

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!list || list.length === 0) populateList();
  }, []);

  useEffect(() => {
    console.log("vv:", value);
  }, [value]);

  const populateList = () => {
    SchedulePlanService.findSchedulePlan(
      accountId,
      (data) => {
        setList([{ id: 0, name: t("SCHEDULE_PLAN_ALWAYS") }, ...data.list]);
      },
      (error) => {}
    );
  };

  return (
    <FormControl className={classes.w100}>
      <InputLabel>{t("SCHEDULE_PLAN")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          let selected = list.filter(
            (item) => item.id === (event.target.value ?? 0)
          );
          if (selected && selected.length > 0) selected = selected[0];
          else selected = null;

          onChange(selected);
        }}
        required={required}
        label={t("SCHEDULE_PLAN")}
      >
        {list &&
          list.map((item, index) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          })}
      </Select>
    </FormControl>
  );
}
