import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import i18n from "../../../i18n";
import PublicPhotosCarousel2 from "./PublicPhotosCarousel2";
import { useStyles } from "./style";

export default function PublicPhotos2(props) {
  const { publicThing } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [images, setImages] = useState([]);

  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    setSelectedHdImageIndex(index);
    setCarouselOpen(true);
  };

  useEffect(() => {
    if (publicThing && publicThing.id) {
      refreshThingImages();
    }
  }, [publicThing]);

  const refreshThingImages = () => {
    ReportService.downloadThumbnails(
      publicThing.id,
      (data) => {
        setImages(data);
        if (data) props.onImageCount(data.length);

        setLoading(false);
      },
      (error) => {}
    );
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  return (
    <Grid
      item
      container
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <PublicPhotosCarousel2
        thingId={publicThing.id}
        setImages={setImages}
        images={images}
        selectedItem={selectedHdImageIndex}
      />
    </Grid>
  );
}

PublicPhotos2.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
