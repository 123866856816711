import { Avatar, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import i18n from "i18n";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import BeneficiaryLoginLayout from "./BeneficiaryLoginLayout";
import { useStyles } from "./style";

export default function BeneficiaryAccountSelection(props) {
  const { appLogo } = props;
  const { t } = useTranslation("beneficiaryPortal", { i18n });

  const location = useLocation();
  const [accountList, setAccountList] = useState(location?.state?.accountList);
  let history = useHistory();

  const classes = useStyles();

  const accountSelected = (item) => {
    localStorage.setItem("beneficiaryAccountId", item.id);
    localStorage.setItem("beneficiaryAccountName", item.fullname);

    history.push("/bportal/homepage");
  };

  const accountCard = (element, index) => {
    return (
      <Grid
        container
        wrap="nowrap"
        spacing={2}
        alignItems={"center"}
        key={index}
      >
        <Grid item xs={3} xl={3} lg={3}>
          <Avatar style={{ width: 60, height: 60 }} src={element.img}></Avatar>
        </Grid>
        <Grid item xs={6} xl={6} lg={6} zeroMinWidth>
          <Typography noWrap>{element.fullname} </Typography>
        </Grid>
        <Grid item xs={3} xl={3} lg={3}>
          <Button
            variant="contained"
            component="span"
            color="primary"
            disableElevation
            onClick={() => {
              accountSelected(element);
            }}
          >
            {t("Devam Et")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <BeneficiaryLoginLayout appLogo={appLogo}>
      <Grid
        item
        container
        spacing={1}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item xs={10}>
          <Typography variant="caption">
            {t(
              "Kullanıcızla ilişkili birden fazla hesap bulunmaktadır. Devam etmek istediğiniz hesabı seçiniz."
            )}
          </Typography>
        </Grid>
        <Grid item xs={11}>
          {accountList ? (
            accountList.map((element, index) => {
              return accountCard(element, index);
            })
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
    </BeneficiaryLoginLayout>
  );
}
