import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import i18n from "../../i18n";

export default function ServiceOperationHashtagButtonGroup(props) {
  const { value, limit, onChange, ...other } = props;

  const { t } = useTranslation("components", { i18n });

  const accountId = localStorage.accountId;
  const [list, setList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [others, setOthers] = useState(false);

  const [anchorPeriodEl, setAnchorPeriodEl] = useState(null);

  const handlePeriodClick = (event) => {
    setAnchorPeriodEl(event.currentTarget);
  };

  const handlePeriodClose = () => {
    setAnchorPeriodEl(null);
  };

  useEffect(() => {
    if (!list || list.length === 0) populateList();
  }, []);

  const populateList = () => {
    ServiceOperationClassService.getHashtagList(
      accountId,
      limit,
      (data) => {
        let _limit = limit ?? 3;
        if (data.length < _limit) {
          setList(data);
        } else {
          setList(data.slice(0, _limit));
          setMenuList(data.slice(_limit, data.length));
        }
      },
      (error) => {}
    );
  };

  const renderButton = (data) => {
    return (
      <Button
        color={"primary"}
        variant={value === data ? "contained" : "outlined"}
        onClick={() => {
          setOthers(false);
          onChange(data);
        }}
      >
        {data}
      </Button>
    );
  };

  const renderMenuItem = (data) => {
    return (
      <MenuItem
        selected={value === data}
        onClick={() => {
          setOthers(true);
          onChange(data);
        }}
      >
        {data}
      </MenuItem>
    );
  };

  return (
    <ButtonGroup
      fullWidth
      size="small"
      variant="outlined"
      color="primary"
      aria-label="contained primary button group"
      {...other}
    >
      <Button
        color={"primary"}
        variant={value == null ? "contained" : "outlined"}
        onClick={() => {
          setOthers(false);
          onChange(null);
        }}
      >
        {t("ALL")}
      </Button>
      {list && list.map((item) => renderButton(item))}
      <Button
        color={"primary"}
        variant={others ? "contained" : "outlined"}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handlePeriodClick}
      >
        {t("...")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorPeriodEl}
        keepMounted
        open={Boolean(anchorPeriodEl)}
        onClose={handlePeriodClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menuList && menuList.map((item) => renderMenuItem(item))}
      </Menu>
    </ButtonGroup>
  );
}
