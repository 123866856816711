import { green } from "@mui/material/colors";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  activeColor: {
    color: "#28a745",
  },
  passiveColor: {
    color: "#dc3545",
  },
  boldFont: {
    fontWeight: "bold",
  },
  thingDescRoot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  modal: {
    width: "100%",
  },
  chipOpen: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  chipClose: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  drawer: {
    overflow: "hidden",
  },
  drawerContent: {
    padding: 10,
    height: 400,
  },
  drawerPaper: {
    height: 400,
    top: 80,
    width: "21.7%",
    minWidth: 200,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  success: {
    backgroundColor: green[500],
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  cancelButton: {
    marginBottom: "20px",
    marginLeft: "5px",
  },

  w100: {
    marginTop: 10,
  },
  wrapperClass: {
    border: "1px solid #BFBFC0",
    borderRadius: 10,
  },
  editorClass: {},
  toolbarClass: { borderBottom: "1px solid #BFBFC0", borderRadius: 10 },
}));
