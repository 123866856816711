import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerInteractionMapHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();
  const mapRef = useRef(null);

  useEffect(() => {
    setSteps([
      {
        element: "[data-fa-button='CUSTOMER_INTERACTION_MAP_ZOOM']",
        intro: (
          <TutorialElement icon="tutorial">
            Harita’yı tam ekran yapmak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/customerInteractionMapNumericVsHeat.png"
                }
                width="647"
                height="355"
              ></img>
            </div>
            Etkileşimleri <b>Sayısal</b> ve <b>Isı Haritası</b> şeklinde izlemek
            için düğmelere tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.customerInteractionMap,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/customerInteractionMapBiricik.png"
                }
                width="647"
                height="355"
              ></img>
            </div>
            Etkileşimleri <b>Biricik Kullanıcı Etkileşimi*</b> şeklinde görmek
            için tıklayın.
            <br />
            <br />
            <b>*</b>: Aynı kullanıcı aynı etkileşim noktası ile aynı veya farklı
            gün ve saatlerde birden fazla sayıda etkileşime geçebilir. Etkileşim
            Noktası ile etkileşime geçen tekilleştirilmiş kişilerin sayısı
            Biricik Kullanıcı Etkileşimi olarak ifade edilir.
          </TutorialElement>
        ),
        tooltipClass: classes.customerInteractionMap,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },

      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/customerInteractionMapAllDays.png"
                }
                width="647"
                height="355"
              ></img>
              <br />
              Belirli bir tarihteki Etkileşim sayılarını görmek için{" "}
              <b>Bütün Günlerin Toplamı</b> düğmesini kapatın.
            </div>
          </TutorialElement>
        ),
        tooltipClass: classes.customerInteractionMap,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/customerInteractionMapDaySelect.png"
                }
                width="647"
                height="355"
              ></img>
              Sürgüyü sağa veya sola kaydırın.
            </div>
          </TutorialElement>
        ),
        tooltipClass: classes.customerInteractionMap,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/customerInteractionMapBackButton.png"
                }
                width="647"
                height="355"
              ></img>
              Harita’yı küçültüp Etkileşim Panel’ine geri dönmek için tıklayın.
            </div>
          </TutorialElement>
        ),
        tooltipClass: classes.customerInteractionMap,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

CustomerInteractionMapHelp.propTypes = {
  value: PropTypes.string,
};
