import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerMessageStatusSelect(props) {
  const { value, onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation("customerMessage", { i18n });

  return (
    <FormControl className={classes.w100}>
      <InputLabel>{t("STATUS")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        label={t("STATUS")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value="DRAFT">{t("DRAFT")}</MenuItem>
        <MenuItem value="ACTIVE">{t("ACTIVE")}</MenuItem>
        <MenuItem value="SENT">{t("SENT")}</MenuItem>
      </Select>
    </FormControl>
  );
}
