import { Grid } from "@mui/material";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingDesc, isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { THING_CHANGE } from "../../services/faApi";

export default function ThingChanges() {
  const tableRef = useRef();
  const { t } = useTranslation("thingChange", { i18n });
  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "changeDate",
      label: t("CHANGE_DATE"),
      align: "center",
      width: "10%",
      type: "smartDate",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      inner: "description",
      width: "30%",
      valueFormatter: (val) => ThingDesc(val),
    },
    {
      field: "newOwnerUser",
      label: t("OWNER"),
      align: "center",
      width: "15%",
      valueFormatter: (val, row) => (
        <>
          {row.newOwnerUser?.fullname && (
            <UserDetail value={row.newOwnerUser} type="USER" />
          )}
          {row.newOwnerUserGroup?.name && (
            <UserDetail value={row.newOwnerUserGroup} type="USER_GROUP" />
          )}
          <div
            style={{
              fontSize: "11px",
            }}
          >
            {row.newOwnerUserText}
          </div>
        </>
      ),
    },
    {
      field: "newMtncUser",
      label: t("MTNC"),
      align: "center",
      width: "15%",
      valueFormatter: (val, row) => (
        <>
          {row.newMtncUser?.fullname && (
            <UserDetail value={row.newMtncUser} type="USER" />
          )}
          {row.newMtncUserGroup?.name && (
            <UserDetail value={row.newMtncUserGroup} type="USER_GROUP" />
          )}
          <div
            style={{
              fontSize: "11px",
            }}
          >
            {row.newMtncUserText}
          </div>
        </>
      ),
    },
    {
      field: "newOwnerUser",
      label: t("USER"),
      align: "center",
      width: "15%",
      valueFormatter: (val, row) => (
        <>
          {row.newUser?.fullname && (
            <UserDetail value={row.newUser} type="USER" />
          )}
          {row.newUserGroup?.name && (
            <UserDetail value={row.newUserGroup} type="USER_GROUP" />
          )}
          <div
            style={{
              fontSize: "11px",
            }}
          >
            {row.newUserText}
          </div>
        </>
      ),
    },
    {
      field: "newOwnerUser",
      label: t("ORGANIZATION"),
      align: "center",
      width: "15%",
      valueFormatter: (val, row) => (
        <>
          <div>{row.newOrganization?.name}</div>
        </>
      ),
    },
  ];

  const newSearchForm = () => {
    let result = {
      thing: null,
      thingId: null,
      changeDateStart: null,
      changeDateEnd: null,
      description: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingChanges/detail/${row.id}`,
      breadCrumbKey: `/thingChanges/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <ThingsDataTable
          searchUrl={
            THING_CHANGE.search + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{ page: 0, size: 10, isAsc: false, sort: "changeDate" }}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    changeDateStart: data.startDate,
                    changeDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
