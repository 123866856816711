import makeStyles from '@mui/styles/makeStyles';

export const imageStyles = makeStyles((theme) => ({
  ThingListImage: {
    width: "48px",
    height: "48px",
    marginRight: "10px",
    borderRadius: "15%",
  },

  ThingListImageSm: {
    width: "16px",
    height: "16px",
    marginRight: "10px",
    borderRadius: "15%",
  },
}));
