import { Button, FormControl, Grid } from "@mui/material/";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MessageTemplateAutoComplete from "components/autocomplete/MessageTemplateAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import CurrencySelect from "components/select/CurrencySelect";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import { GetFieldByLanguage } from "services/utils";
import VerificationTypeSelect from "../../components/select/VerificationTypeSelect";
import i18n from "../../i18n";

const CampaignEdit = forwardRef((props, ref) => {
  const { onSave, campaign, type, currentLang } = props;
  const { t } = useTranslation("campaign", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newCampaign = () => {
    return {
      account: { id: accountId },
      name: null,
      description: null,
      registerStartDate: null,
      registerEndDate: null,
      imageObjectId: null,
      managerUser: null,
      status: "DRAFT",
      userVerificationType: null,
      drawRate: null,
      currency: type === "CAMPAIGN" ? "TRY" : null,
      type: type,
      messageTemplate: null,
      languages: ["TR"],
    };
  };
  const [campaignDTO, setCampaignDTO] = useState(newCampaign());
  const [drawRateCheck, setDrawRateCheck] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (campaign) {
      setCampaignDTO(campaign == null ? newCampaign() : { ...campaign });
      setDrawRateCheck(campaign?.drawRate ? true : false);
    }
  }, [campaign]);

  const saveCampaign = () => {
    setLoading(true);
    CampaignService.save(campaignDTO, saveOnSuccess, saveOnError);
  };
  const saveOnSuccess = (response) => {
    showAlert(!campaignDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    onSave(response);
  };
  const saveOnError = (error) => {
    showAlert("error", "error");
    setLoading(false);
  };
  const handleChangeV = (prop, val) => {
    setCampaignDTO({ ...campaignDTO, [prop]: val });
  };

  const deleteCampaign = () => {
    faDialog({
      description: t("Kampanya kalıcı olarak silinecektir. Emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CampaignService.deleteCampaign(
          campaignDTO,
          (data) => {
            showAlert(t("Silindi"), "success");
            history.goBack();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <FaDialog
      title={
        campaignDTO.type === "CAMPAIGN" ? t("EDIT_CAMPAIGN") : t("EDIT_EVENT")
      }
      faOpen={isDialogOpen}
      faOnSubmit={saveCampaign}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setCampaignDTO(campaign ? campaign : newCampaign());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        data-fa-section="CAMPAIGN_EDIT_DIALOG"
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) =>
              setCampaignDTO({
                ...campaignDTO,
                multiLangName: {
                  ...campaignDTO.multiLangName,
                  [currentLang]: event.target.value,
                },
              })
            }
            value={campaignDTO.multiLangName?.[currentLang] || ""}
            helperText={
              campaignDTO.multiLangName?.[currentLang] === undefined
                ? GetFieldByLanguage(campaignDTO.multiLangName, currentLang)
                : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              setCampaignDTO({
                ...campaignDTO,
                multiLangDescription: {
                  ...campaignDTO.multiLangDescription,
                  [currentLang]: event.target.value,
                },
              })
            }
            value={campaignDTO.multiLangDescription?.[currentLang] || ""}
            helperText={
              campaignDTO.multiLangDescription?.[currentLang] === undefined
                ? GetFieldByLanguage(
                    campaignDTO.multiLangDescription,
                    currentLang
                  )
                : ""
            }
          />
        </Grid>
        {campaignDTO.type === "EVENT" && (
          <Grid item xs={12}>
            <FaInput
              label={t("QUOTA")}
              faType={"number"}
              onChange={(event) =>
                setCampaignDTO({
                  ...campaignDTO,
                  quota: event.target.value,
                })
              }
              value={campaignDTO.quota}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FaDatePicker
            label={t("REGISTER_START_DATE")}
            faType="dateTime"
            value={campaignDTO.registerStartDate}
            faOnChange={(val) => {
              handleChangeV("registerStartDate", val);
            }}
            data-fa-input="REGISTER_START_DATE"
          />
        </Grid>
        <Grid item xs={12}>
          <FaDatePicker
            label={t("REGISTER_END_DATE")}
            faType="dateTime"
            name="registerEndDate"
            value={campaignDTO.registerEndDate}
            faOnChange={(val) => {
              handleChangeV("registerEndDate", val);
            }}
            data-fa-input="REGISTER_END_DATE"
          />
        </Grid>
        <Grid item xs={12}>
          <UserAutoComplete
            value={campaignDTO?.managerUser}
            accountId={accountId}
            onChange={(data) => {
              handleChangeV("managerUser", data);
            }}
            label={t("MANAGER_USER")}
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <VerificationTypeSelect
            captcha={false}
            value={campaignDTO.userVerificationType || ""}
            label="Kullanıcı Doğrulama Modeli"
            onChange={(data) => {
              handleChangeV("userVerificationType", data);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <MessageTemplateAutoComplete
            value={campaignDTO.messageTemplate}
            accountId={accountId}
            label={t("Katılım Geri Bildirim Mesajı")}
            onChange={(data) => {
              handleChangeV("messageTemplate", data);
            }}
          />
        </Grid>
        {campaignDTO.type === "CAMPAIGN" && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={drawRateCheck}
                  onChange={(event) => {
                    setDrawRateCheck(event.target.checked);
                    if (!event.target.checked) {
                      handleChangeV("drawRate", null);
                    }
                  }}
                  name="recurring"
                  color="primary"
                />
              }
              label={t("Çekiliş hakkı için alışveriş tutarı kullan")}
            />
          </Grid>
        )}
        {drawRateCheck && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item xs={1}>
              Her
            </Grid>
            <Grid item xs={3}>
              <FaInput
                // label={t("DRAW_RATE")}
                faType="number"
                onChange={(event) =>
                  handleChangeV("drawRate", event.target.value)
                }
                value={campaignDTO.drawRate || ""}
              />
            </Grid>
            <Grid item xs={2}>
              <CurrencySelect
                value={campaignDTO.currency}
                label={"Birim"}
                onChange={(data) => {
                  handleChangeV("currency", data);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              alışveriş için 1 çekiliş hakkı
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl variant="outlined" size="small">
            <FormControlLabel
              control={
                <Checkbox
                  checked={campaignDTO?.languages?.includes("TR")}
                  onChange={(event) => {
                    if (event.target.checked) {
                      let temp = campaignDTO.languages;
                      temp.push("TR");
                      setCampaignDTO({ ...campaignDTO, languages: temp });
                    } else {
                      let temp = campaignDTO.languages;
                      var index = temp.indexOf("TR");

                      if (index !== -1) {
                        temp.splice(index, 1);
                      }
                      setCampaignDTO({ ...campaignDTO, languages: temp });
                    }
                  }}
                  color="primary"
                />
              }
              label={t("TURKISH")}
            />
          </FormControl>
          <FormControl variant="outlined" size="small">
            <FormControlLabel
              control={
                <Checkbox
                  checked={campaignDTO?.languages?.includes("EN")}
                  onChange={(event) => {
                    if (event.target.checked) {
                      let temp = campaignDTO.languages;
                      temp.push("EN");
                      setCampaignDTO({ ...campaignDTO, languages: temp });
                    } else {
                      let temp = campaignDTO.languages;
                      var index = temp.indexOf("EN");

                      if (index !== -1) {
                        temp.splice(index, 1);
                      }
                      setCampaignDTO({ ...campaignDTO, languages: temp });
                    }
                  }}
                  name="recurring"
                  color="primary"
                />
              }
              label={t("ENGLISH")}
            />
          </FormControl>
        </Grid>
        {campaignDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deleteCampaign} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default CampaignEdit;
