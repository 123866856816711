import { Typography } from "@mui/material";
import ThingsNoImageIcon from "components/icons/ThingsNoImageIcon";
import i18n from "i18n";
import React from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function NoImage(props) {
  const { label } = props;

  const { t } = useTranslation("components", { i18n });

  const labelClasses = labelStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ThingsNoImageIcon
        style={{ height: 48, width: 48, color: "#532363", marginRight: 5 }}
      />
      <Typography variant="subtitle1" className={labelClasses.LabelValue}>
        {label ?? t("NO_IMAGE_FOUND")}
      </Typography>
    </div>
  );
}
