import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function IndoorLocationKrokiHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            İç Mekân <b>Kroki güncelleme</b> ve <b>Kroki Ekleme/Silme</b>{" "}
            işlemlerinin yapıldığı alandır.
          </TutorialElement>
        ),
      },
      {
        element:
          "[data-fa-section='LOCATION_MAPS'] > div.MuiCardHeader-action > button:nth-child(2)",
        intro: (
          <TutorialElement icon="click">
            Kroki eklemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#dialog-form",
        intro: (
          <TutorialElement>
            <b>Kroki Adını</b> girin, <b>Krokiyi</b> yükleyin ve <b>Kaydet</b>’e
            basın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $(
            "[data-fa-section='LOCATION_MAPS'] > div.MuiCardHeader-action > button:nth-child(2)"
          )[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/icMekanKrokiSilme.png"
                }
                width="412"
                height="210"
              ></img>
            </div>
            <br />
            <b>Krokiyi silmek</b> için tıklayın ve <b>Evet</b> butonuna basın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var el = $("[aria-label='close']");
          if (el != null && el[0] != null) el[0].click();
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

IndoorLocationKrokiHelp.propTypes = {
  value: PropTypes.string,
};
