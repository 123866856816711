import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SlaContractLevelService } from "services/SlaContractLevelService";
import i18n from "../../i18n";

export default function SlaContractLevelRadio(props) {
  const { value, thingId, slaContractId, onChange, required, label, task } =
    props;

  const { t } = useTranslation("slaContract", { i18n });

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!list || list.length === 0) populateList();
  }, []);

  const populateList = () => {
    SlaContractLevelService.findByThing(
      thingId,
      slaContractId,
      task,
      (data) => {
        setList(data.list);
      },
      (error) => {}
    );
  };

  return (
    <RadioGroup
      row
      aria-label="position"
      value={value == null ? null : "" + value.id}
      label={label && t("SLA_CONTRACT_LEVEL")}
      required={required}
      onChange={(event) => {
        let item = list.find((x) => "" + x.id === event.target.value);
        onChange(item);
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="15%">SLA</TableCell>
              <TableCell width="10%">Tanım</TableCell>
              <TableCell width="15%">Arıza Sebebi</TableCell>
              <TableCell width="15%">
                Tanım, Problem ve/veya Açıklaması
              </TableCell>
              <TableCell width="15%">Yapılacak İş</TableCell>
              <TableCell width="10%">Ceza Katsayısı</TableCell>
              <TableCell width="10%">Müdahale Süresi</TableCell>
              <TableCell width="10%">Çözüm Süresi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <FormControlLabel
                        key={index}
                        value={"" + item.id}
                        control={<Radio color="primary" />}
                        label={item.slaContract.name}
                      />
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.cause}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.todo}</TableCell>
                    <TableCell>{item.factor}</TableCell>
                    <TableCell>
                      {item.responseTime &&
                        item.responseTime + " " + t("" + item.responsePeriod)}
                    </TableCell>
                    <TableCell>
                      {item.solutionTime &&
                        item.solutionTime + " " + t("" + item.solutionPeriod)}
                    </TableCell>
                  </TableRow>
                );
              })}
            {!!!task && (
              <TableRow>
                <TableCell component="th" scope="row">
                  <FormControlLabel
                    key={-1}
                    value={null}
                    control={<Radio color="primary" />}
                    label={"Hiçbiri"}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </RadioGroup>
  );
}
