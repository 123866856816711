import { Check } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Checkbox, FormControlLabel, Grid } from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RelationTypeService } from "services/RelationTypeService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { RELATION_TYPE } from "../../services/faApi";

export default function RelationType() {
  const tableRef = useRef();
  const { t } = useTranslation("relationType", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    {
      field: "nameSource",
      label: t("NAME_SOURCE"),
      align: "center",
      width: "20%",
    },
    {
      field: "nameTarget",
      label: t("NAME_TARGET"),
      align: "center",
      width: "20%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "duplex",
      label: t("DUPLEX"),
      align: "center",
      width: "20%",
      valueFormatter: (duplex) => {
        if (duplex === true) return <Check />;
        return null;
      },
    },
    {
      field: "exclusive",
      label: t("EXCLUSIVE"),
      align: "center",
      width: "20%",
      valueFormatter: (exclusive) => {
        if (exclusive === true) return <Check />;
        return null;
      },
    },
  ];
  const newRelationType = () => {
    return {
      id: "",
      nameSource: "",
      nameTarget: "",
      description: "",
      duplex: false,
      exclusive: false,
    };
  };
  const [relationType, setRelationType] = useState(newRelationType());

  const handleChange = (prop) => (event) => {
    if (prop === "nameSource") {
      if (!relationType.duplex)
        setRelationType({
          ...relationType,
          nameSource: event.target.value,
          nameTarget: event.target.value,
        });
      else setRelationType({ ...relationType, [prop]: event.target.value });
    } else setRelationType({ ...relationType, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!relationType.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setRelationType(newRelationType());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveStock = () => {
    setLoading(true);
    RelationTypeService.save(
      {
        ...relationType,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      nameSource: null,
      nameTarget: null,
      description: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setRelationType(row);
    setIsEditFormOpen(true);
  };

  const onDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        RelationTypeService.delete(
          relationType,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            tableRef.current.refreshTable();
            setIsEditFormOpen(false);
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Etkileşim Noktası İlişki Türü Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveStock}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setRelationType(newRelationType());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME_SOURCE")}
                onChange={handleChange("nameSource")}
                value={relationType.nameSource || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                disabled={!relationType.duplex}
                maxLength={100}
                label={t("NAME_TARGET")}
                onChange={handleChange("nameTarget")}
                value={relationType.nameTarget || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={relationType.description || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={relationType.duplex}
                    onChange={(event) => {
                      if (!event.target.checked) {
                        handleChangeGeneric(
                          setRelationType,
                          relationType,
                          "duplex",
                          event.target.checked,
                          "nameTarget",
                          relationType.nameSource
                        );
                      } else {
                        handleChangeGeneric(
                          setRelationType,
                          relationType,
                          "duplex",
                          event.target.checked
                        );
                      }
                    }}
                    color="primary"
                  />
                }
                label={t("DUPLEX")}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={relationType.exclusive}
                    onChange={(event) => {
                      handleChangeGeneric(
                        setRelationType,
                        relationType,
                        "exclusive",
                        event.target.checked
                      );
                    }}
                    color="primary"
                  />
                }
                label={t("EXCLUSIVE")}
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={onDelete} color="secondary">
                Sil
              </Button>
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newFormRender()}
        <ThingsDataTable
          searchUrl={
            RELATION_TYPE.search + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{ page: 0, size: 10, sort: "nameSource", isAsc: true }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME_SOURCE")}
                value={searchForm.nameSource}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "nameSource",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("NAME_TARGET")}
                value={searchForm.nameTarget}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "nameTarget",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
