import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  footer: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "white",
    paddingBottom: "5px",
    paddingTop: "3px",
    borderTop: "solid 1px #DDDDDD",
    zIndex: 20,
  },

  actionButtons2Div: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 0,
    paddingTop: 4,
    paddingBottom: 4,
    display: "flex",
    justifyContent: "center",
  },

  nextButton: {
    backgroundColor: "#1877F2",
    "&:hover": {
      backgroundColor: "#3F8EF4",
    },
    color: "white",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 500,
    height: 48,
    width: "100%",
    float: "left",
  },

  backButton: {
    backgroundColor: "#000000",
    "&:hover": {
      backgroundColor: "#808080",
    },
    color: "white",
    padding: 0,
    //marginTop: 10,
    width: "100%",
    border: "solid 2px white",
    borderRadius: 10,
    fontSize: 14,
    fontWeight: 500,
    height: 48,
  },
}));
