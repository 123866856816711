import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditIcon from "@mui/icons-material/Edit";
import {
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import React, { Suspense, useEffect, useState } from "react";
import {
  FaDataTable,
  FaDatePicker,
  FaDialog,
  FaInput,
} from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
// import TimezoneSelect from 'react-timezone-select';
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import {
  SLA_CALENDAR,
  SLA_CALENDAR_HOLIDAY,
  SLA_CALENDAR_WEEK,
} from "../../services/faApi";
import { dateToMinutes, hourMinuteToDate } from "../../services/utils";
import { useStyles } from "./style";

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export default function SlaCalendarEdit() {
  const classes = useStyles();
  const { t } = useTranslation("slaCalendar", { i18n });

  const { id } = useParams();

  const dispatch = useDispatch();
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  // States
  const [loading, setLoading] = useState(false);
  const [newCalendarOpen, setNewCalendarOpen] = useState(false);

  const [newHoursOpen, setNewHoursOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [calendarDTO, setCalendarDTO] = useState({
    id: 0,
    name: "",
    timeZoneId: "",
  });
  const [editCalendarDTO, setEditCalendarDTO] = useState({
    name: "",
    timeZoneId: "",
  });
  const [newCalendarDTO, setNewCalendarDTO] = useState({
    id: 0,
    name: "",
    timeZoneId: "",
  });
  const [newHours, setNewHours] = useState({
    start: hourMinuteToDate(0, 0),
    end: hourMinuteToDate(23, 59),
  });
  const [calendarHours, setCalendarHours] = useState([]);
  const [weekDay, setWeekDay] = useState(0);
  const [holidays, setHolidays] = useState([]);
  const [editSLAOpen, setEditSLAOpen] = useState(false);
  const [newHolidayOpen, setNewHolidayOpen] = useState(false);
  const [holidayDTO, setHolidayDTO] = useState({
    name: "",
    halfWhole: false,
    startTime: hourMinuteToDate(0, 0),
    endTime: hourMinuteToDate(23, 59),
    recurring: false,
    holidaydate: new Date(),
  });

  const columns = [
    {
      field: "dayOfWeek",
      label: t("DAY"),
      align: "center",
      valueFormatter: (value) => {
        return days[value];
      },
    },
    {
      field: "startTime",
      label: t("START"),
      align: "center",
      valueFormatter: (value) => {
        return (
          String(Math.floor(value / 60)).padStart(2, "0") +
          ":" +
          String(value % 60).padStart(2, "0")
        );
      },
    },
    {
      field: "endTime",
      label: t("END"),
      align: "center",
      valueFormatter: (value) => {
        return (
          String(Math.floor(value / 60)).padStart(2, "0") +
          ":" +
          String(value % 60).padStart(2, "0")
        );
      },
    },
  ];

  const holidayColumns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
    },
    {
      field: "holidaydate",
      label: t("DATE"),
      align: "center",
      valueFormatter: (value) => {
        if (!value) {
          return null;
        }
        return value.substr(0, value.indexOf("T"));
      },
    },
    {
      field: "recurring",
      label: t("RECURRING"),
      align: "center",
      valueFormatter: (value) => {
        if (!value) {
          return <CheckBoxOutlineBlankIcon />;
        } else {
          return <CheckBoxIcon />;
        }
      },
    },
    {
      field: "halfWhole",
      label: t("TYPE"),
      align: "center",
      valueFormatter: (value) => {
        return value ? t("WHOLE_DAY") : t("HALF_DAY");
      },
    },
    {
      field: "startTime",
      label: t("START"),
      align: "center",
      valueFormatter: (value) => {
        if (!value && value !== 0) {
          return null;
        }
        return (
          String(Math.floor(value / 60)).padStart(2, "0") +
          ":" +
          String(value % 60).padStart(2, "0")
        );
      },
    },
    {
      field: "endTime",
      label: t("END"),
      align: "center",
      valueFormatter: (value) => {
        if (!value && value !== 0) {
          return null;
        }
        return (
          String(Math.floor(value / 60)).padStart(2, "0") +
          ":" +
          String(value % 60).padStart(2, "0")
        );
      },
    },
  ];

  const handleChangeCalendar = (prop, val) => {
    setEditCalendarDTO({ ...editCalendarDTO, [prop]: val });
  };

  const handleChangeNew = (prop, val) => {
    setNewCalendarDTO({ ...newCalendarDTO, [prop]: val });
  };

  const handleChangeTime = (prop, val) => {
    setNewHours({ ...newHours, [prop]: val });
  };

  const handleChangeHoliday = (prop, val) => {
    setHolidayDTO({ ...holidayDTO, [prop]: val });
  };

  const refresh = () => {
    let req = requests.getObjectByIdRequest(SLA_CALENDAR.findById, id);
    FaAxios(req)
      .then((res) => {
        setCalendarDTO(res.data);
        setEditCalendarDTO(res.data);
        getCalendarBusinessHours(res.data.id);
        getCalendarHolidays(res.data.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCalendarBusinessHours = (calendarId) => {
    const req = requests.getSimpleGetRequest(SLA_CALENDAR_WEEK.findByCalendar, {
      calendarId,
    });
    FaAxios(req)
      .then((res) => {
        setCalendarHours(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCalendarHolidays = (calendarId) => {
    const req = requests.getSimpleGetRequest(
      SLA_CALENDAR_HOLIDAY.findByCalendar,
      { calendarId, page: 0, size: 10, isAsc: false, sort: "name" }
    );
    FaAxios(req)
      .then((res) => {
        if (res.data.list) setHolidays(res.data.list);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveCalendar = () => {
    const req = requests.getSimplePostRequest(SLA_CALENDAR.save, {
      ...newCalendarDTO,
      account: { id: accountId },
    });
    FaAxios(req)
      .then((res) => {
        console.log(res.data);
        let name = newCalendarDTO.name;
        setNewCalendarDTO({ name: "", timeZoneId: "" });
        setNewCalendarOpen(false);
        refresh(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCalendar = () => {
    faDialog({ description: t("DELETE_CONFIRM"), type: "confirm" })
      .then((confirmation) => {
        console.log(confirmation);
        setLoading(true);
        var req = requests.getSimplePostRequest(
          SLA_CALENDAR.delete,
          calendarDTO
        );
        FaAxios(req)
          .then((response) => {
            showAlert(t("DELETED"), "success");
            refresh();
            setLoading(false);
          })
          .catch((error) => {
            showAlert(error.message, "error");
            setLoading(false);
          });
      })
      .catch((err) => console.log(err));
  };

  const updateCalendar = () => {
    const req = requests.getSimplePostRequest(SLA_CALENDAR.update, {
      ...editCalendarDTO,
      account: { id: accountId },
    });
    FaAxios(req)
      .then((res) => {
        refresh(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveBusinessHours = () => {
    const startTime = dateToMinutes(newHours.start);
    const endTime = dateToMinutes(newHours.end);
    const req = requests.getSimplePostRequest(SLA_CALENDAR_WEEK.save, {
      calendar: { id: calendarDTO.id },
      dayOfWeek: weekDay,
      startTime,
      endTime,
    });
    console.log(req);
    FaAxios(req)
      .then((res) => {
        console.log(res);
        setNewHoursOpen(false);
        getCalendarBusinessHours(calendarDTO.id);
        getCalendarHolidays(calendarDTO.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onBusinessHourDelete = (event, data) => {
    faDialog({ description: t("DELETE_CONFIRM"), type: "confirm" })
      .then((confirmation) => {
        console.log(confirmation);
        setLoading(true);
        var req = requests.getSimplePostRequest(SLA_CALENDAR_WEEK.delete, data);
        FaAxios(req)
          .then((response) => {
            showAlert(t("DELETED"), "success");
            getCalendarBusinessHours(calendarDTO.id);
            getCalendarHolidays(calendarDTO.id);
            setLoading(false);
          })
          .catch((error) => {
            showAlert(error.message, "error");
            setLoading(false);
          });
      })
      .catch((err) => console.log(err));
  };

  const saveHoliday = () => {
    let startTime = undefined;
    let endTime = undefined;
    if (holidayDTO.startTime) startTime = dateToMinutes(holidayDTO.startTime);
    if (holidayDTO.endTime) endTime = dateToMinutes(holidayDTO.endTime);
    console.log(holidayDTO);
    const req = requests.getSimplePostRequest(SLA_CALENDAR_HOLIDAY.save, {
      ...holidayDTO,
      startTime,
      endTime,
      calendar: { id: calendarDTO.id },
    });
    FaAxios(req)
      .then((res) => {
        //setCalendarDTO(res.data);
        //setNewCalendarDTO({ name: "", timeZoneId: "" });
        getCalendarHolidays(calendarDTO.id);
        setNewHolidayOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onHolidayDelete = (event, data) => {
    faDialog({ description: t("DELETE_CONFIRM"), type: "confirm" })
      .then((confirmation) => {
        console.log(confirmation);
        setLoading(true);
        var req = requests.getSimplePostRequest(
          SLA_CALENDAR_HOLIDAY.delete,
          data
        );
        FaAxios(req)
          .then((response) => {
            showAlert(t("DELETED"), "success");
            getCalendarHolidays(calendarDTO.id);
            setLoading(false);
          })
          .catch((error) => {
            showAlert(error.message, "error");
            setLoading(false);
          });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    refresh();
  }, [id]);

  useEffect(() => {
    setEditCalendarDTO(calendarDTO);
  }, [calendarDTO.id]);

  const saveSLA = () => {
    const req = requests.getSimplePostRequest(SLA_CALENDAR.update, {
      ...newCalendarDTO,
      account: { id: accountId },
    });
    FaAxios(req)
      .then((res) => {
        console.log(res.data);
        let name = newCalendarDTO.name;
        setCalendarDTO({
          name: newCalendarDTO.name,
          timeZone: newCalendarDTO.timezone,
        });
        setEditSLAOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editButtonClicked = () => {
    setNewCalendarDTO({ ...calendarDTO });
    setEditSLAOpen(true);
  };

  const renderEditSLADialog = () => {
    return (
      <FaDialog
        title={t("SLA_EDIT")}
        faOpen={editSLAOpen}
        faOnSubmit={() => saveSLA()}
        loading={loading}
        faHandleClose={() => setEditSLAOpen(false)}
        showSaveButton
      >
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <FaInput
              required
              value={newCalendarDTO.name}
              label={t("CALENDAR_NAME")}
              onChange={(event) => {
                handleChangeNew("name", event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <TimezoneSelect
              required
              value={newCalendarDTO.timeZoneId || ""}
              onChange={(tz) => {
                handleChangeNew("timeZoneId", tz.value);
              }}
              placeholder={t("TIMEZONE")}
            /> */}
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderNewHolidayDialog = () => {
    return (
      <FaDialog
        title={t("ADD_BUSINESS_HOURS")}
        faOpen={newHolidayOpen}
        faOnSubmit={() => saveHoliday()}
        loading={loading}
        faHandleClose={() => setNewHolidayOpen(false)}
        showSaveButton
      >
        <Grid item container spacing={3}>
          <Grid item xs={4}>
            <FaInput
              required
              value={holidayDTO.name}
              onChange={(event) => {
                handleChangeHoliday("name", event.target.value);
              }}
              label={t("NAME")}
            ></FaInput>
          </Grid>
          <Grid item xs={4}>
            <FaDatePicker
              required
              label={t("DATE")}
              faType="date"
              name="holidaydate"
              value={holidayDTO.holidaydate}
              faOnChange={(val) => {
                handleChangeHoliday("holidaydate", val);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              style={{ minHeight: 55 }}
              control={
                <Checkbox
                  checked={holidayDTO.recurring}
                  onChange={(event) => {
                    handleChangeHoliday("recurring", event.target.checked);
                  }}
                  name="recurring"
                  color="primary"
                />
              }
              label={t("RECURRING")}
            />
          </Grid>
          <Grid item container justifyContent="center" xs={4}>
            <FormControlLabel
              style={{ minHeight: 55 }}
              control={
                <Checkbox
                  checked={holidayDTO.halfWhole}
                  onChange={async (event) => {
                    if (event.target.checked) {
                      setHolidayDTO({
                        ...holidayDTO,
                        halfWhole: event.target.checked,
                        startTime: undefined,
                        endTime: undefined,
                      });
                    } else {
                      setHolidayDTO({
                        ...holidayDTO,
                        halfWhole: event.target.checked,
                      });
                    }
                    console.log(holidayDTO);
                  }}
                  name="halfWhole"
                  color="primary"
                />
              }
              label={t("WHOLE_DAY")}
            />
          </Grid>
          <Grid item xs={4}>
            {!holidayDTO.halfWhole && (
              <FaDatePicker
                required={!holidayDTO.halfWhole}
                label={t("START")}
                faType="time"
                name="startTime"
                value={holidayDTO.startTime}
                faOnChange={(val) => {
                  handleChangeHoliday("startTime", val);
                }}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {!holidayDTO.halfWhole && (
              <FaDatePicker
                required={!holidayDTO.halfWhole}
                label={t("END")}
                faType="time"
                name="endTime"
                value={holidayDTO.endTime}
                minDate={holidayDTO.startTime}
                faOnChange={(val) => {
                  handleChangeHoliday("endTime", val);
                }}
              />
            )}
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderNewHoursDialog = () => {
    return (
      <FaDialog
        title={t("ADD_BUSINESS_HOURS")}
        faOpen={newHoursOpen}
        faOnSubmit={() => saveBusinessHours()}
        loading={loading}
        faHandleClose={() => setNewHoursOpen(false)}
        showSaveButton
      >
        <Grid item container spacing={3}>
          <Grid item xs={4}>
            <FormControl className={classes.w100}>
              <Select
                variant="outlined"
                classes={{
                  outlined: classes.padding10,
                }}
                value={weekDay}
                onChange={(event) => {
                  setWeekDay(event.target.value);
                }}
              >
                {days.map((day, index) => (
                  <MenuItem key={index} value={index}>
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FaDatePicker
              required
              label={t("START")}
              faType="time"
              value={newHours.start}
              faOnChange={(val) => {
                handleChangeTime("start", val);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FaDatePicker
              required
              label={t("END")}
              faType="time"
              value={newHours.end}
              minDate={newHours.start}
              faOnChange={(val) => {
                handleChangeTime("end", val);
              }}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={editButtonClicked}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  }
                  title={t("SLA_CALENDAR_INFO")}
                  data-fa-section="SLA_CALENDAR_INFO"
                />
                <CardContent>
                  <Grid
                    item
                    container
                    spacing={1}
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    {/* name */}
                    <Grid item xs={4}>
                      <Typography variant="caption">
                        {t("CALENDAR_NAME")}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{calendarDTO.name}</Typography>
                    </Grid>
                    {/* timezone */}
                    <Grid item xs={4}>
                      <Typography variant="caption">{t("TIMEZONE")}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{calendarDTO.timeZoneId}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item lg={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setNewHoursOpen(true);
                      }}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  }
                  title={t("BUSINESS_HOURS")}
                  data-fa-section="BUSINESS_HOURS"
                />
                <FaDataTable
                  showHeaderText={false}
                  headerText={null}
                  columns={columns}
                  showDeleteButton
                  onDeleteAction={onBusinessHourDelete}
                  rowKey="id"
                  data={calendarHours}
                  faMultiSelect={false}
                />
              </Card>
            </Grid>

            <Grid item lg={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={() => {
                        setNewHolidayOpen(true);
                      }}
                      size="large"
                    >
                      <AddIcon />
                    </IconButton>
                  }
                  title={t("HOLIDAYS")}
                  data-fa-section="HOLIDAYS"
                />
                <FaDataTable
                  columns={holidayColumns}
                  showDeleteButton
                  onDeleteAction={onHolidayDelete}
                  rowKey="id"
                  data={holidays}
                  faMultiSelect={false}
                />
              </Card>
            </Grid>
          </Grid>
        </div>

        {renderEditSLADialog()}
        {renderNewHoursDialog()}
        {renderNewHolidayDialog()}
        <Grid item container spacing={2} className={classes.root}>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Suspense>
    </>
  );
}
