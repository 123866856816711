import { Button, Grid } from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ProjectService } from "services/ProjectService";
import { DEFAULT_TIMEZONE, getGMTTime } from "services/utils";
import i18n from "../../i18n";

const ProjectEdit = forwardRef((props, ref) => {
  const { onSave, onClose, project } = props;
  const { t } = useTranslation("project", { i18n });
  const accountId = localStorage.accountId;
  const accountTimezone = localStorage.accountTimezone;
  const faDialog = useDialog();
  let history = useHistory();
  const userInfo = useSelector(getUserInfo);
  const timezoneOffset = () => {
    if (userInfo?.timeZoneOffset) return userInfo?.timeZoneOffset;
    else if (accountTimezone) return accountTimezone;
    else return DEFAULT_TIMEZONE;
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newProject = () => {
    return {
      account: { id: accountId },
      description: null,
      name: null,
      startDate: null,
      endDate: null,
      startDateTemp: null,
      endDateTemp: null,
    };
  };
  const [projectDTO, setProjectDTO] = useState(newProject());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (project?.id) {
      setProjectDTO(project == null ? newProject() : { ...project });
    } else {
      setProjectDTO(newProject());
    }
  }, [project, isDialogOpen]);

  const saveProject = () => {
    console.log("save project");
    setLoading(true);
    ProjectService.save({ ...projectDTO }, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    console.log("saveOnSuccess: ", data);
    showAlert(!projectDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    onSave(data);
  };

  const saveOnError = (error) => {
    console.log("saveOnError: ", error);
    showAlert(error?.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setProjectDTO({ ...projectDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setProjectDTO({ ...projectDTO, [prop]: val });
  };

  const handleChangeTime = (method, dto, prop, val, prop2) => {
    let gmtTime = getGMTTime(timezoneOffset, val);

    method({ ...dto, [prop]: val, [prop2]: gmtTime });
  };

  // const handleChangeTime = (prop, val) => {
  //   let timezoneOffset = userInfo.timeZoneOffset;
  //   let gmtTime = getGMTTime(timezoneOffset, val);
  //   setProjectDTO({ ...projectDTO, [prop]: gmtTime });
  // };

  const onDelete = (data) => {
    faDialog({
      description: t("Projeyi silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ProjectService.delete(
          projectDTO,
          (data) => {
            showAlert(t("Proje Silindi"), "success");
            history.goBack();
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("EDIT_PROJECT")}
      faOpen={isDialogOpen}
      faOnSubmit={saveProject}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        onClose();
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            onChange={handleChange("name")}
            value={projectDTO.name || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={handleChange("description")}
            value={projectDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaDatePicker
            label={t("START_DATE")}
            faType="dateTime"
            value={projectDTO.startDateTemp}
            faOnChange={(val) => {
              handleChangeTime(
                setProjectDTO,
                projectDTO,
                "startDateTemp",
                val,
                "startDate"
              );
            }}
            data-fa-input="START_DATE"
          />
        </Grid>

        <Grid item xs={12}>
          <FaDatePicker
            label={t("END_DATE")}
            faType="dateTime"
            value={projectDTO.endDateTemp}
            faOnChange={(val) => {
              handleChangeTime(
                setProjectDTO,
                projectDTO,
                "endDateTemp",
                val,
                "endDate"
              );
            }}
            data-fa-input="END_DATE"
          />
        </Grid>
      </Grid>

      {projectDTO.id && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              setIsDialogOpen(false);
              onDelete();
            }}
            color="secondary"
          >
            {t("Sil")}
          </Button>
        </Grid>
      )}
    </FaDialog>
  );
});

export default ProjectEdit;
