import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ThingDetail from "components/detail/ThingDetail";
import React, { useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getAccountId } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserContentService } from "services/UserContentService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import UserContentPhotos from "./UserContentPhotos";
import { useStyles } from "./style";

export default function UserContentDetail() {
  const { t } = useTranslation("UserContent", { i18n });
  const { id } = useParams();
  const accountId = useSelector(getAccountId);
  const classes = useStyles();
  const dispatch = useDispatch();
  const labelClasses = labelStyles();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [userContent, setUserContent] = useState({});

  const [images, setImages] = useState([]);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    UserContentService.findById(
      id,
      (data) => {
        setUserContent(data);
        refreshImages();
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const refreshImages = () => {
    UserContentService.downloadThumbnails(
      id,
      (data) => {
        setImages(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={6} lg={6}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Card style={{ minHeight: 250 }}>
                <CardHeader
                  title={t("BASIC_INFO")}
                  data-fa-section="BASIC_INFO"
                />
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {isTNF(t("THING"), t("THING_T2C"))}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            <ThingDetail value={userContent?.thing} />
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("USER_NAME")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {(userContent?.userName ?? "") +
                              " " +
                              (userContent?.userSurname ?? "")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("EMAIL_OR_PHONE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {userContent?.email
                              ? userContent.email
                              : userContent?.phone
                              ? userContent.phone
                              : null}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(userContent.uploadDate)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={labelClasses.LabelLastRow}
                        >
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DESCRIPTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {userContent?.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <UserContentPhotos
                images={images}
                setImages={setImages}
                userContentId={userContent.id}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
