import { FormControlLabel, Grid, IconButton, Switch, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { green, grey } from "@mui/material/colors";
import { CardGiftcard, Search } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import CampaignAutoComplete from "components/autocomplete/CampaignAutoComplete";
import CampaignStatusButtonGroup from "components/buttonGroup/CampaignStatusButtonGroup";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import { CAMPAIGN } from "services/faApi";
import { qsParse } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import CampaignAwardAutoComplete from "../../components/autocomplete/CampaignAwardAutoComplete";
import LoadingBar from "../../components/loadingBar";
import CampaignCustomerStatusSelect from "../../components/select/CampaignCustomerStatusSelect";
import CampaignCustomerStatusDecorator from "../../components/ui/CampaignCustomerStatusDecorator";
import i18n from "../../i18n";
import CampaignCustomerDetail from "./CampaignCustomerDetail";
import CampaignCustomerEvaluate from "./CampaignCustomerEvaluate";

export default function CampaignCustomers() {
  const tableRef = useRef();
  const { t } = useTranslation("campaign", { i18n });
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const labelClasses = labelStyles();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const type = qsParse(history.location.search)?.type;

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [isStatusEditOpen, setIsStatusEditOpen] = useState(false);
  const [isEvaluateOpen, setIsEvaluateOpen] = useState(false);

  const GreenSwitch = withStyles({
    switchBase: {
      color: grey[300],
      "&$checked": {
        color: green[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columns = [
    {
      field: "registerDate",
      label: t("REGISTER_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "campaign",
      label: type === "EVENT" ? t("Etkinlik") : t("Kampanya"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "name",
      label: t("Ad"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return row.customer?.name;
      },
    },
    {
      field: "surname",
      label: t("Soyad"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        return row.customer?.surname;
      },
    },
    {
      field: "draw",
      label: t("Çekiliş Hakkı"),
      align: "center",
      width: "5%",
    },
    {
      field: "orderNo",
      label: t("ORDER_NO"),
      align: "center",
      width: "10%",
    },

    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => {
        return <CampaignCustomerStatusDecorator value={val} />;
      },
    },
    {
      field: "campaignAward",
      label: t("Ödül"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => {
        return val?.name;
      },
    },
    {
      field: "joinStatus",
      label: t("Katılım Durumu"),
      align: "center",
      width: "5%",
      valueFormatter: (val, row) => {
        return (
          <FormControlLabel
            control={
              <GreenSwitch
                checked={val === "YES"}
                onChange={(e) => {
                  let value = e.target.checked ? "YES" : "NO";
                  changeJoinStatus(row, value);
                }}
              />
            }
          />
        );
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (val, row, rowIndex) => {
        return <>
          <IconButton
            aria-label="settings"
            onClick={() => {
              findCampaignCustomer(val);
            }}
            size="large">
            <CardGiftcard />
          </IconButton>
          <IconButton
            aria-label="settings"
            onClick={() => {
              onDoubleClick(row, rowIndex);
            }}
            size="large">
            <Search />
          </IconButton>
          <IconButton
            aria-label="settings"
            onClick={() => {
              evaluateCampaignCustomer(val, rowIndex);
            }}
            size="large">
            <Edit />
          </IconButton>
        </>;
      },
    },
  ];

  const changeJoinStatus = (row, value) => {
    CampaignService.changeJoinStatus(
      row.id,
      value,
      () => {
        refresh();
      },
      () => {}
    );
  };

  const newSearchForm = () => {
    return {
      accountId: accountId,
      campaign: null,
      campaignId: id,
      thingId: null,
      email: null,
      phone: null,
      name: null,
      surname: null,
      hashtag: null,
      recordType: null,
      status: location?.state?.status ?? null,
      description: null,
      type: type ?? null,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row, index) => {
    const queryParams = tableRef.current.getQueryParams();
    setCurrentItem(queryParams.page * queryParams.size + index);
    setTotalItems(queryParams.totalItems);
    setSelectedCustomer(row);
    setIsDialogOpen(true);
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onPrevious = () => {
    const queryParams = tableRef.current.getQueryParams();
    CampaignService.getPrevious(
      accountId,
      id,
      selectedCustomer.id,
      queryParams.sort,
      queryParams.isAsc,
      setSelectedCustomer,
      (error) => {}
    );
    setCurrentItem((prev) => prev - 1);
  };

  const onNext = () => {
    const queryParams = tableRef.current.getQueryParams();
    CampaignService.getNext(
      accountId,
      id,
      selectedCustomer.id,
      queryParams.sort,
      queryParams.isAsc,
      setSelectedCustomer,
      (error) => {}
    );
    setCurrentItem((prev) => prev + 1);
  };
  const renderCampaignCustomerDetail = () => {
    return (
      <CampaignCustomerDetail
        id={selectedCustomer?.id}
        campaignId={selectedCustomer?.campaign?.id}
        isOpen={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          setSelectedCustomer(null);
        }}
        onPrevious={onPrevious}
        onNext={onNext}
        currentItem={currentItem}
        totalItems={totalItems}
      ></CampaignCustomerDetail>
    );
  };

  const renderCampaignCustomerEvaluate = () => {
    return (
      <CampaignCustomerEvaluate
        id={selectedCustomer?.id}
        campaignId={selectedCustomer?.campaign?.id}
        isOpen={isEvaluateOpen}
        onClose={() => {
          setIsEvaluateOpen(false);
          setSelectedCustomer(null);
        }}
        onPrevious={onPrevious}
        onNext={onNext}
        currentItem={currentItem}
        totalItems={totalItems}
      ></CampaignCustomerEvaluate>
    );
  };

  const findCampaignCustomer = (id) => {
    setLoading(true);
    CampaignService.customerFindById(
      id,
      (data) => {
        setSelectedCustomer(data);
        setLoading(false);
        setIsStatusEditOpen(true);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const evaluateCampaignCustomer = (id, rowIndex) => {
    setLoading(true);
    CampaignService.customerFindById(
      id,
      (data) => {
        setSelectedCustomer(data);
        setLoading(false);
        setIsEvaluateOpen(true);

        const queryParams = tableRef.current.getQueryParams();
        setCurrentItem(queryParams.page * queryParams.size + rowIndex);
        setTotalItems(queryParams.totalItems);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const changeStatus = () => {
    CampaignService.changeCustomerStatus(
      selectedCustomer?.id,
      selectedCustomer?.status,
      selectedCustomer?.orderNo,
      selectedCustomer?.campaignAward?.id,
      changeStatusOnSuccess,
      changeStatusOnError
    );
  };
  const changeStatusOnSuccess = (data) => {
    setSelectedCustomer({ ...selectedCustomer, status: data?.status });
    setIsStatusEditOpen(false);
    setLoading(false);
    setSelectedCustomer(null);
    setSelectedCustomer({ ...selectedCustomer, status: null });
    refresh();
  };
  const changeStatusOnError = () => {};

  const renderStatusEdit = () => {
    return (
      <FaDialog
        title={t("CHANGE_STATUS")}
        faOpen={isStatusEditOpen}
        faOnSubmit={() => changeStatus()}
        loading={loading}
        faHandleClose={() => {
          setIsStatusEditOpen(false);
          setSelectedCustomer(null);
        }}
        showSaveButton
      >
        <CampaignCustomerStatusSelect
          value={selectedCustomer?.status || ""}
          onChange={(data) => {
            setSelectedCustomer({ ...selectedCustomer, status: data });
          }}
        ></CampaignCustomerStatusSelect>
        {selectedCustomer?.status === "WIN" && (
          <>
            <FaInput
              label={t("ORDER_NO")}
              value={selectedCustomer?.orderNo || ""}
              onChange={(event) => {
                setSelectedCustomer({
                  ...selectedCustomer,
                  orderNo: event.target.value,
                });
              }}
            />
            <CampaignAwardAutoComplete
              campaignId={selectedCustomer.campaign.id}
              value={selectedCustomer.campaignAward}
              onChange={(data) => {
                setSelectedCustomer({
                  ...selectedCustomer,
                  campaignAward: data,
                });
              }}
            ></CampaignAwardAutoComplete>
          </>
        )}
      </FaDialog>
    );
  };
  const calculateDate = (data) => {
    var today = new Date();
    let start = null;

    if (data === -7) {
      start = new Date(today);
      start.setDate(start.getDate() - 7);
    } else if (data === -31) {
      start = new Date(today.setMonth(today.getMonth() - 1));
    }

    return start;
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderCampaignCustomerDetail()}
        {renderCampaignCustomerEvaluate()}
        {renderStatusEdit()}

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={6}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <CampaignAutoComplete
                accountId={accountId}
                value={searchForm.campaign}
                type={type}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "campaign",
                    data,
                    "campaignId",
                    data?.id
                  );
                }}
              />

              <FaInput
                label={t("Ad")}
                value={searchForm.name || ""}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("Soyad")}
                value={searchForm.surname || ""}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "surname",
                    event.target.value
                  );
                }}
              />

              <CampaignCustomerStatusSelect
                value={searchForm.status || ""}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "status",
                    data
                  );
                }}
              ></CampaignCustomerStatusSelect>

              {/* <ThingAutoComplete
                value={searchForm.thing || ""}
                accountId={accountId}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thingId",
                    data?.id
                  );
                  handleChangeGeneric(setSearchForm, searchForm, "thing", data);
                }}
              /> */}
              {/* <FaDatePicker
                label={t("REGISTER_DATE")}
                faType="dateTime"
                name="registerDate"
                value={searchForm?.registerDate || ""}
                faOnChange={(value) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "registerDate",
                    value
                  );
                }}
              /> */}
            </FaSearch>
          </Grid>
        </Grid>
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={8} xs={12}>
            <Grid item xs={20} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("DURUM")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CampaignStatusButtonGroup
                value={searchForm.status}
                onChange={(data) => {
                  const temp = { ...searchForm, status: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
              />
            </Grid>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TARIH")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  value={searchForm.notificationDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      notificationDateValue: data,
                      registerDate: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={CAMPAIGN.searchCustomer}
          exportUrl={CAMPAIGN.exportCustomer}
          searchForm={searchForm}
          queryParams={{
            page: 0,
            size: 10,
            sort: "registerDate",
            isAsc: false,
          }}
          //data={campaignCustomers}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
      </Suspense>
    </>
  );
}
