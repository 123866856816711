import {
  faCubes,
  faFolder,
  faGlobe,
  faHashtag,
  faMap,
  faSitemap,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Chip, Grid, Paper } from "@mui/material";
import { Add, Cancel } from "@mui/icons-material";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingHashtag from "components/hashtag/ThingHashtag";
import { default as React, useEffect, useState } from "react";
import { FaButton, FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { SlaContractThingService } from "services/SlaContractThingService";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SlaContractTargetEdit(props) {
  const { slaContractId, targets, open, onSave, onClose, onDelete } = props;
  const { t } = useTranslation("slaContract", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [editValue, setEditValue] = useState();
  const [newTargets, setNewTargets] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);
  const [sectionHashtag, setSectionHashtag] = useState(false);
  const [sectionThingClass, setSectionThingClass] = useState(false);
  const [sectionThing, setSectionThing] = useState(false);
  const [sectionOrganization, setSectionOrganization] = useState(false);
  const [sectionLocation, setSectionLocation] = useState(false);

  const initialize = () => {
    let tmp = targets
      ? targets?.map((data) => {
          let dto = {
            id: data.id,
            thingClass: null,
            thing: null,
            hashtag: null,
            organization: null,
            location: null,
            locationDetail: null,
            label: null,
            key: null,
            allThings: null,
          };

          if (data.hashtag) {
            dto.hashtag = data.hashtag;
            dto.label = data.hashtag;
            dto.key = "h_" + data.hashtag;
          } else if (data.thingClass) {
            dto.thingClass = data.thingClass;
            dto.label = data.thingClass.className;
            dto.key = "c_" + data.thingClass.id;
          } else if (data.thing) {
            dto.thing = data.thing;
            dto.label = ThingDesc(data.thing);
            dto.key = "t_" + data.id;
          } else if (data.organization) {
            dto.organization = data.organization;
            dto.label = data.organization.name;
            dto.key = "o_" + data.organization.id;
          } else if (data.location) {
            dto.location = data.location;
            dto.label = data.location.name;
            dto.key = "l_" + data.location.id;
          } else if (data.locationDetail) {
            dto.locationDetail = data.locationDetail;
            dto.label = data.locationDetail.name;
            dto.key = "ld_" + data.locationDetail.id;
          } else if (data.allThings) {
            dto.allThings = data.allThings;
            dto.label = isTNF(t("ALL_THINGS"), t("ALL_THINGS_T2C"));
            dto.key = "all_things";
          }

          return dto;
        })
      : [];

    setEditValue(tmp);
  };

  useEffect(() => {
    if (targets) initialize();
  }, [targets]);

  const handleChipDelete = (data, index) => () => {
    var temp = [...editValue];
    temp.splice(index, 1);
    setEditValue(temp);

    setDeleteItems([...deleteItems, data.id]);
  };

  const addTargetHashtag = (value) => {
    let dto = {
      hashtag: value,
      label: value,
      key: "h_" + value,
    };

    addTarget(dto);
  };

  const addTargetThingClass = (value) => {
    let dto = {
      thingClass: { id: value.id },
      thing: null,
      hashtag: null,
      label: value.className,
      key: "c_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetThing = (value) => {
    let dto = {
      thingClass: null,
      thing: { id: value.id },
      hashtag: null,
      label: ThingDesc(value),
      key: "t_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetOrganization = (value) => {
    let dto = {
      organization: { id: value.id },
      label: value.name,
      key: "o_" + value.id,
    };

    addTarget(dto);
  };

  const addTargetLocation = (value) => {
    let dto = {
      location: { id: value.id },
      label: value.name,
      key: "l_" + value.id,
    };

    addTarget(dto);
  };
  const addTargetAllThings = () => {
    let dto = {
      allThings: true,
      label: isTNF(t("ALL_THINGS"), t("ALL_THINGS_T2C")),
      key: "all_things",
    };
    addTarget(dto);
  };

  const addTarget = (newValue) => {
    newValue.slaContract = { id: slaContractId };
    setEditValue([...editValue, newValue]);
    setNewTargets([...newTargets, newValue]);
  };

  const save = () => {
    if (newTargets.length !== 0) {
      setLoading(true);
      saveTargets(newTargets);
    }
    if (deleteItems.length !== 0) {
      deleteTargets(deleteItems);
    }
  };

  const saveTargets = (data) => {
    SlaContractThingService.saveAll(
      [...data],
      (data) => {
        setNewTargets([]);
        onSave();
        setLoading(false);
      },
      errorCallback
    );
  };

  const deleteTargets = (data) => {
    SlaContractThingService.deleteTargets(
      [...data],
      (data) => {
        setDeleteItems([]);
        onSave();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("SLA_CONTRACT_SCOPE")}
          faOpen={open}
          faOnSubmit={save}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
            setNewTargets([]);
            setDeleteItems([]);
            initialize();
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <Paper component="ul" className={classes.chipRoot}>
                {editValue?.map((data, index) => {
                  let icon;

                  if (data.thingClass) {
                    icon = <FontAwesomeIcon icon={faSitemap} />;
                  } else if (data.thing) {
                    icon = <FontAwesomeIcon icon={faCubes} />;
                  } else if (data.hashtag) {
                    icon = <FontAwesomeIcon icon={faHashtag} />;
                  } else if (data.organization) {
                    icon = <FontAwesomeIcon icon={faFolder} />;
                  } else if (data.location) {
                    icon = <FontAwesomeIcon icon={faGlobe} />;
                  } else if (data.locationDetail) {
                    icon = <FontAwesomeIcon icon={faMap} />;
                  } else if (data.allThings) {
                    icon = <FontAwesomeIcon icon={faUser} />;
                  }

                  return (
                    <li key={data.key}>
                      <Chip
                        icon={icon}
                        label={data.label}
                        onDelete={handleChipDelete(data, index)}
                        className={classes.chip}
                      />
                    </li>
                  );
                })}
              </Paper>
              <div className={classes.buttonPanel}>
                <FaButton
                  faClick={() => {
                    setSectionHashtag(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_HASHTAG")}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionThingClass(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {isTNF(t("ADD_THINGCLASS"), t("ADD_THINGCLASS_T2C"))}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionThing(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {isTNF(t("ADD_THING"), t("ADD_THING_T2C"))}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionOrganization(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_ORGANIZATION")}
                </FaButton>
                <FaButton
                  faClick={() => {
                    setSectionLocation(true);
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {t("ADD_LOCATION")}
                </FaButton>
                <FaButton
                  faClick={() => {
                    addTargetAllThings();
                  }}
                  fullWidth={false}
                  startIcon={<Add />}
                >
                  {isTNF(t("ADD_ALL_THINGS"), t("ADD_ALL_THINGS_T2C"))}
                </FaButton>
              </div>
              {sectionHashtag ? (
                <div className={classes.hboxBlock}>
                  <ThingHashtag
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetHashtag(data);
                      setSectionHashtag(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionHashtag(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
              {sectionThingClass ? (
                <div className={classes.hboxBlock}>
                  <ThingClassAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetThingClass(data);
                      setSectionThingClass(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionThingClass(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
              {sectionThing ? (
                <div className={classes.hboxBlock}>
                  <ThingAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetThing(data);
                      setSectionThing(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionThing(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
              {sectionOrganization ? (
                <div className={classes.hboxBlock}>
                  <OrganizationAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetOrganization(data);
                      setSectionOrganization(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionOrganization(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
              {sectionLocation ? (
                <div className={classes.hboxBlock}>
                  <LocationAutoComplete
                    accountId={accountId}
                    onChange={(data) => {
                      addTargetLocation(data);
                      setSectionLocation(false);
                    }}
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Cancel />}
                    className={classes.cancelButton}
                    onClick={() => {
                      setSectionLocation(false);
                    }}
                  >
                    {t("CANCEL")}
                  </Button>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
}
