import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: -50,
  },
  text: {
    fontSize: 16,
  },
  icon: {
    width: 64,
    height: 64,
  },
}));
