import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CustomerInteractionIndoorTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCouponDetail", { i18n });
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim sayılarının İç Mekân üzerinde izlendiği sayfadır.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL + "/assets/tutorial/indoor_map1.png"
                }
                width="521"
                height="293"
              ></img>
            </div>
            İç Mekân seçmek için tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.indoorMapSelect,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/indoorMap_mapType.png"
                }
                width="743"
                height="368"
              ></img>
            </div>
            Etkileşimleri <b>Sayısal</b> ve <b>Isı Haritası</b> şeklinde izlemek
            için düğmelere tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.indoorMap,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/indoorMap_biricik.png"
                }
                width="743"
                height="368"
              ></img>
            </div>
            Etkileşimleri <b>Biricik Kullanıcı Etkileşimi*</b> şeklinde görmek
            için tıklayın.
            <br />
            <br />
            <b>*</b>: Aynı kullanıcı aynı etkileşim noktası ile aynı veya farklı
            gün ve saatlerde birden fazla sayıda etkileşime geçebilir. Etkileşim
            Noktası ile etkileşime geçen tekilleştirilmiş kişilerin sayısı
            Biricik Kullanıcı Etkileşimi olarak ifade edilir.
          </TutorialElement>
        ),
        tooltipClass: classes.indoorMap,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/indoorMap_allDays.png"
                }
                width="743"
                height="368"
              ></img>
            </div>
            Belirli bir tarihteki Etkileşim sayılarını görmek için{" "}
            <b>Bütün Günlerin Toplamı</b> düğmesini kapatın.
          </TutorialElement>
        ),

        tooltipClass: classes.indoorMap,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/indoorMap_gunSec.png"
                }
                width="743"
                height="389"
              ></img>
            </div>
            Sürgüyü sağa veya sola kaydırın.
          </TutorialElement>
        ),
        tooltipClass: classes.indoorMap,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"customer_interaction_indoor"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
