import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  chipOpen: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
  chipClose: {
    backgroundColor: theme.palette.success.dark,
    color: "white",
  },
  waiting: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.secondary.contrastText,
  },
  accept: {
    backgroundColor: theme.palette.success.dark,
    color: theme.palette.secondary.contrastText,
  },
  reject: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.secondary.contrastText,
  },
}));
