import { Avatar, Card, CardContent, CardHeader } from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CUSTOMER_INTERACTION } from "services/faApi";
import { getDateTimeWFormat, isTNF } from "services/utils";
import i18n from "../../i18n";

export default function AccessLogInteractions(props) {
  const { visitorId } = props;
  const tableRef = useRef();
  const { t } = useTranslation(["customerInteraction", "components"], { i18n });
  let history = useHistory();
  const accountId = localStorage.accountId;

  const columns = [
    {
      field: "date",
      label: t("DATE"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "operation",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return t("CUSTOMER_INTERACTION_OPERATION." + value, {
          ns: "components",
        });
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      thing: null,
      thingId: null,
      operation: null,
      operationList: null,
      operationDateValue: null,
      operationDateStart: null,
      operationDateEnd: null,
      limit: 20, //chartLimit
      visitorIdList: [visitorId],
    };

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const onDoubleClick = (row) => {
    if (row.operation === "ISSUE_REPORT") {
      const tmpTo = {
        pathname: `/issues/detail/${row.operationId}`,
        breadCrumbKey: `/issues/detail/:id`,
      };
      history.push(tmpTo);
    } else if (row.operation === "LIKE") {
    } else if (row.operation === "SUGGESTION") {
      const tmpTo = {
        pathname: `/suggestions/detail/${row.operationId}`,
        breadCrumbKey: `/suggestions/detail/:id`,
      };

      history.push(tmpTo);
    } else if (row.operation === "SURVEY") {
      navigateToThing(row.thingId);
    } else if (row.operation === "CAMPAIGN") {
      navigateToThing(row.thingId);
    } else if (row.operation === "CUSTOMER") {
      navigateToThing(row.thingId);
    } else if (row.operation === "SCAN_QR") {
      navigateToAccessLog(row.operationId);
    } else if (row.operation === "PUBLIC_INFO") {
      navigateToThing(row.thingId);
    } else if (row.operation === "DOWNLOAD_DOCUMENT") {
      navigateToThing(row.thingId);
    } else if (row.operation === "DOWNLOAD_IMAGE") {
      navigateToThing(row.thingId);
    } else if (row.operation === "THING_LINK") {
      navigateToThing(row.thingId);
    }
  };

  const navigateToThing = (thingId) => {
    const tmpTo = {
      pathname: `/thingDetail/${thingId}`,
      breadCrumbKey: `/thingDetail/:id`,
    };
    history.push(tmpTo);
  };

  const navigateToAccessLog = (id) => {
    const tmpTo = {
      pathname: `/accessLog/detail/${id}`,
      breadCrumbKey: `/accessLog/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <Card>
      <CardHeader
        title={t("ACCESS_LOG_DETAIL_INTERACTIONS")}
        data-fa-section="ACCESS_LOG_DETAIL_INTERACTIONS"
      />
      <CardContent></CardContent>
      <ThingsDataTable
        searchUrl={CUSTOMER_INTERACTION.search}
        searchForm={searchForm}
        columns={columns}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "date",
          accountId: localStorage.accountId,
        }}
        ref={tableRef}
        showHeaderText={false}
        faOnDoubleClick={onDoubleClick}
      />
    </Card>
  );
}
