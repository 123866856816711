import {
  faCubes,
  faExclamationCircle,
  faFolder,
  faGlobe,
  faHashtag,
  faSitemap,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { QueueService } from "services/QueueService";
import { ThingDesc, isTNF } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import QueueTargetEdit from "./QueueTargetEdit";
import { useStyles } from "./style";

export default function QueueTarget(props) {
  const { queue } = props;

  const { t } = useTranslation("queue", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [targets, setTargets] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [queue]);

  const refresh = () => {
    setLoading(true);
    QueueService.findTargets(id, findTargetsOnSuccess, findTargetsOnError);
  };

  const findTargetsOnSuccess = (data) => {
    setTargets(data);
    setLoading(false);
  };

  const findTargetsOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const renderTargets = () => {
    if (queue == null || targets == null || targets.length === 0) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <ul className={classes.chipRoot}>
          {targets?.map((target, index) => {
            let icon;
            let label;

            if (target.thingClass) {
              icon = <FontAwesomeIcon icon={faSitemap} />;
              label = target.thingClass.className;
            } else if (target.thing) {
              icon = <FontAwesomeIcon icon={faCubes} />;
              label = ThingDesc(target.thing);
            } else if (target.hashtag) {
              icon = <FontAwesomeIcon icon={faHashtag} />;
              label = target.hashtag;
            } else if (target.organization) {
              icon = <FontAwesomeIcon icon={faFolder} />;
              label = target.organization.name;
            } else if (target.location) {
              icon = <FontAwesomeIcon icon={faGlobe} />;
              label = target.location.name;
            } else if (target.issueClass) {
              icon = <FontAwesomeIcon icon={faExclamationCircle} />;
              label = target.issueClass.className;
            } else if (target.allThings) {
              icon = <FontAwesomeIcon icon={faCubes} />;
              label = isTNF(t("ALL_THINGS"), t("ALL_THINGS_T2C"));
            }

            return (
              <li key={target.key}>
                <Chip icon={icon} label={label} className={classes.chip} />
              </li>
            );
          })}
        </ul>
      );
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      <QueueTargetEdit
        queueId={queue.id}
        targets={targets}
        onSave={() => {
          refresh();
          setIsEditFormOpen(false);
        }}
        onClose={() => {
          setIsEditFormOpen(false);
        }}
        onDelete={() => {
          refresh();
          setIsEditFormOpen(false);
        }}
        open={isEditFormOpen}
      />
      <Card style={{ minHeight: 204 }}>
        <CardHeader
          action={
            <IconButton aria-label="settings" disabled={queue.status !== "PASSIVE"} size="large">
              <Edit
                onClick={() => {
                  setIsEditFormOpen(true);
                }}
              />
            </IconButton>
          }
          title={t("QUEUE_SCOPE")}
          data-fa-section="QUEUE_SCOPE"
        />
        <CardContent>
          <Grid item container spacing={1}>
            {renderTargets()}
          </Grid>
        </CardContent>
      </Card>
    </Suspense>
  </>;
}
