import {
  faFile,
  faKey,
  faLink,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "../../../i18n";
import PublicDocument from "./PublicDocument";
import PublicPhotos2 from "./PublicPhotos2";

export default function LandingTemplate5(props) {
  const { publicThing, onLinkClick } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [documentParam, setDocumentParam] = useState(null);
  const [infoAccordionExpanded, setInfoAccordionExpanded] = useState(true);

  const [selectedTab, setSelectedTab] = useState("info");

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const renderDocumentsAndLinks = () => {
    return (
      <Grid container spacing={1}>
        {publicThing?.documentList?.map((item, index) => (
          <Grid
            item
            lg={12}
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
          >
            <Typography
              onClick={() => {
                setDocumentParam(item);
              }}
              style={{
                color: "#532363",
                borderBottom: "1px solid",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faFile} /> {item.name ?? item.fileName}
            </Typography>
          </Grid>
        ))}

        {publicThing?.thingLinkList?.map((item, index) => (
          <Grid
            item
            lg={12}
            xs={12}
            key={index}
            style={{ textAlign: "center" }}
          >
            <Typography
              onClick={() => {
                window.open(item.url, "_blank").focus();
                onLinkClick(item);
              }}
              style={{
                color: "#532363",
                borderBottom: "1px solid",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faLink} /> {item.description ?? item.url}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const navigateReportIssue = () => {
    let tmpTo = {
      pathname: `/report/issue/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportLike = () => {
    let tmpTo = {
      pathname: `/report/like/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigatePublicInfo = () => {
    let tmpTo = {
      pathname: `/report/public/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportSuggestion = () => {
    let tmpTo = {
      pathname: `/report/suggestion/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCustomer = () => {
    let tmpTo = {
      pathname: `/report/customer/tid=${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const getDocumentsAndLinksCount = () => {
    let docs =
      publicThing?.documentList && publicThing?.documentList.length > 0
        ? publicThing?.documentList.length
        : 0;
    let links =
      publicThing?.thingLinkList && publicThing?.thingLinkList.length > 0
        ? publicThing?.thingLinkList.length
        : 0;

    return docs + links;
  };

  const handleInfoAccordionChange = () => (event, newExpanded) => {
    setInfoAccordionExpanded(!infoAccordionExpanded);
  };

  const navigateSurvey = (survey) => {
    let tmpTo = {
      pathname: `/report/survey/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const populateSurveys = () => {
    return (
      <>
        {publicThing.surveyList.map((item, index) => {
          return (
            <Grid item lg={12} xs={12} style={{ textAlign: "left" }}>
              <Typography
                onClick={() => {
                  navigateSurvey(item);
                }}
                style={{
                  color: "#532363",
                  cursor: "pointer",
                }}
              >
                <FontAwesomeIcon icon={faPen} /> {item.name}
              </Typography>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <p>
          <Typography style={{ fontWeight: "bold" }}>
            {publicThing.thingDesc}
          </Typography>
        </p>
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <PublicDocument
          value={documentParam}
          publicThing={publicThing}
          onClose={() => {
            setDocumentParam(null);
          }}
        />
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
        <PublicPhotos2 publicThing={publicThing} />
      </Grid>
      <Grid item lg={10} xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <Accordion
          expanded={infoAccordionExpanded}
          onChange={handleInfoAccordionChange()}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Bilgi</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography style={{ paddingLeft: 10, fontSize: 14 }}>
              {publicThing.thingDesc}
            </Typography>
          </AccordionDetails>
        </Accordion>

        {((publicThing?.documentList && publicThing?.documentList.length > 0) ||
          (publicThing?.thingLinkList &&
            publicThing?.thingLinkList.length > 0)) && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                Dokümanlar ve Linkler ({getDocumentsAndLinksCount()})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                {publicThing?.documentList?.map((item, index) => (
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    key={index}
                    style={{ textAlign: "left" }}
                  >
                    <Typography
                      onClick={() => {
                        setDocumentParam(item);
                      }}
                      style={{
                        color: "#532363",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faFile} />{" "}
                      {item.name ?? item.fileName}
                    </Typography>
                  </Grid>
                ))}
                {publicThing?.thingLinkList?.map((item, index) => (
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    key={index}
                    style={{ textAlign: "left" }}
                  >
                    <Typography
                      onClick={() => {
                        window.open(item.url, "_blank").focus();
                        onLinkClick(item);
                      }}
                      style={{
                        color: "#532363",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon icon={faLink} />{" "}
                      {item.description ?? item.url}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}

        <Grid item lg={12} xs={12} style={{ textAlign: "left" }}>
          <Typography
            onClick={() => {
              navigateCustomer();
            }}
            style={{
              color: "#532363",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon icon={faKey} /> Kayıt ol
          </Typography>
        </Grid>

        {publicThing &&
          publicThing.surveyList &&
          publicThing.surveyList.length > 0 &&
          populateSurveys()}
      </Grid>

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        style={{
          backgroundColor: "#512562",
          position: "absolute",
          bottom: "10px",
          left: 0,
          height: "100px",
          width: "100%",
          margin: 0,
        }}
      >
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Button onClick={navigateReportLike}>
            <div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/pageTemplate/icons/begen2.png"
                }
                style={{ height: 48 }}
                alt="suggestion"
              />
              <div style={{ color: "white" }}>Beğendim</div>
            </div>
          </Button>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Button onClick={navigateReportSuggestion}>
            <div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/pageTemplate/icons/oneri2.png"
                }
                style={{ height: 48 }}
                alt="suggestion"
              />
              <div style={{ color: "white" }}>Önerim var</div>
            </div>
          </Button>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "center" }}>
          <Button onClick={navigateReportIssue}>
            <div>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/pageTemplate/icons/sorun2.png"
                }
                style={{ height: 48 }}
                alt="suggestion"
              />
              <div style={{ color: "white" }}>Sorun var</div>
            </div>
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

LandingTemplate5.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
