import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import GetAppIcon from "@mui/icons-material/GetApp";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import ListIcon from "@mui/icons-material/List";
import SaveIcon from "@mui/icons-material/Save";
import {
  Badge,
  CardHeader,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Paper,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import QRCode from "qrcode.react";
import React, { useEffect, useRef, useState } from "react";
import { FaInput } from "react-base-fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { qsStringfy } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { DropzoneDialog } from "react-mui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "../../i18n";
import { ACCOUNT, FILES, ISSUE, THING } from "../../services/faApi";
import { useStyles } from "./style";

export default function ThingsDetail(props) {
  const { t } = useTranslation("things", { i18n });
  const location = useLocation();
  const qr = useRef(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const classes = useStyles();
  const userInfo = useSelector(getUserInfo);
  let history = useHistory();
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const initialValue = {
    id: "",
    thid: "",
    type: "",
    description: "",
    account: {},
  };
  const [values, setValues] = useState(initialValue);
  const [imgList, setImgList] = useState([]);
  const [mainImg, setMainImg] = useState();
  const [issueCount, setIssueCount] = useState(0);
  const getImgReq = (thingId) => {
    return requests.getSimpleGetRequest(FILES.downloadFiles, {
      thingId: thingId,
      type: "IMAGE",
    });
  };
  let issueReq = requests.getSimpleGetRequest(ISSUE.findByThing, {
    thingId: id,
    page: 0,
    size: 10,
    sort: "notificationDate",
    isAsc: false,
  });

  useEffect(() => {
    setLoading(true);
    if (location.state && location.state.row && location.state.row.id) {
      setId(location.state.row.id);
    } else {
      setId("new");
    }
  }, [location]);

  useEffect(() => {
    setLoading(false);
    if (id !== "new") {
      let req = requests.getObjectByIdRequest(THING.findById, id);
      FaAxios(req)
        .then((response) => {
          if (!loading) {
            setValues({
              ...values,
              id: response.data.id,
              thid: response.data.thid,
              type: response.data.type,
              description: response.data.description,
              account: response.data.account,
            });
            return FaAxios(getImgReq(response.data.id));
          }
        })
        .then((response) => {
          if (response.data.length > 0) {
            setMainImg(response.data[0].content);
            setImgList(response.data);
          }
          return FaAxios(issueReq);
        })
        .then((response) => {
          if (response.data.totalItems > 0)
            setIssueCount(response.data.totalItems);
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            showAlert(error.message, "error");
          }
        });
      setIsNew(false);
    } else {
      if (!loading) {
        let req = requests.getSimpleGetRequest(ACCOUNT.findByUsername, {
          username: userInfo.username,
        });
        FaAxios(req).then((response) => {
          setValues({
            ...values,
            account: response.data,
          });
        });
      }
    }

    return () => {
      setLoading(true);
    };
  }, [id]);

  const goBack = () => {
    props.history.goBack();
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const saveThing = (e) => {
    e.preventDefault();
    setLoading(true);
    let req = requests.getSimplePostRequest(THING.checkAndSave, values);
    FaAxios(req)
      .then((response) => {
        showAlert(t("KAYDET_UYARI"), "success");
        const tmpTo = {
          pathname: `/things/detail/${response.data.id}`,
          breadCrumbKey: `/things/detail/:id`,
        };
        setIsNew(false);
        setValues({
          ...values,
          id: response.data.id,
        });
        history.push(tmpTo);
      })
      .catch((error) => {
        showAlert(error.message, "error");
      });
    setLoading(false);
  };
  const updateThing = (e) => {
    e.preventDefault();
    setLoading(true);
    let req = requests.getSimplePostRequest(THING.update, values);
    FaAxios(req)
      .then((response) => {
        showAlert(t("GUNCELLE_UYARI"), "success");
        const tmpTo = {
          pathname: `/things/detail/${response.data.id}`,
          breadCrumbKey: `/things/detail/:id`,
        };
        setIsNew(false);
        setValues({
          ...values,
          id: response.data.id,
        });
        history.push(tmpTo);
      })
      .catch((error) => {
        console.log("error->", error);
        showAlert(error.message, "error");
      });
    setLoading(false);
  };

  const [openUpload, setOpenUpload] = useState(false);

  const lg = useMediaQuery(theme.breakpoints.up("lg"));

  const fileUpload = (files) => {
    setLoading(true);
    let file = files[0];
    var bodyFormData = new FormData();
    bodyFormData.append("multipartFile", file);
    var req = requests.getSimplePostRequest(
      FILES.uploadFile + "/" + values.id,
      bodyFormData
    );
    FaAxios(req)
      .then((response) => {
        return FaAxios(getImgReq(values.id));
      })
      .then((response) => {
        setMainImg(response.data[0].content);
        setImgList(response.data);
        showAlert(t("GORSEL_KAYDET_UYARI"), "success");
        setOpenUpload(false);
      })
      .catch((error) => {
        showAlert(error.message, "error");
      });
    setLoading(false);
  };

  const showIssues = () => {
    let serchParams = {
      thingId: id,
      page: 0,
      size: 10,
      sort: "notificationDate",
      isAsc: false,
    };
    let search = "?" + qsStringfy(serchParams);
    const tmpTo = {
      pathname: "/issues/",
      breadCrumbKey: "/issues",
      search: search,
    };
    history.push(tmpTo);
  };

  const downloadQr = () => {
    var domToPdf = require("dom-to-pdf");

    var element = document.getElementById("thingQr");
    var options = {
      filename: "qr.pdf",
      overrideWidth: 128,
    };
    domToPdf(element, options, function () {});
  };
  const denemeData = [
    { title: "Masa" },
    { title: "Sandalye" },
    { title: "Elektronik Esya" },
    { title: "Dolap" },
    { title: "Tehsisat" },
    { title: "Diger" },
  ];
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item lg={12}>
          <Card>
            <CardHeader
              action={
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={goBack}
                  startIcon={<ListIcon />}
                >
                  {t("VARLIK_LISTESI")}
                </Button>
              }
              title={isNew ? t("ADD") : values.description}
            />
            <CardContent>
              {loading ? <LinearProgress /> : null}
              <Grid container spacing={1}>
                <Grid item lg={4} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item lg={6} xs={6}>
                      <Paper
                        className={classes.imgPaper}
                        ref={qr}
                        id="qrPaper"
                        elevation={3}
                      >
                        <QRCode
                          id="thingQr"
                          value={values.thidUrl}
                          renderAs="svg"
                        />
                      </Paper>
                      {!isNew ? (
                        <Button
                          onClick={downloadQr}
                          style={{ width: "100%", marginTop: "10px" }}
                          startIcon={<GetAppIcon />}
                          variant="outlined"
                          size="small"
                          color="primary"
                        >
                          {t("QR_INDIR")}
                        </Button>
                      ) : null}
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      {!isNew ? (
                        <Paper className={classes.imgPaper}>
                          <img
                            alt=""
                            style={{ width: "100%" }}
                            src={`data:image/png;base64, ${mainImg}`}
                          />
                        </Paper>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={8} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item lg={3} xs={12}>
                      <FaInput
                        required
                        id="type"
                        autoComplete="off"
                        label={t("TIP")}
                        value={values.type}
                        onChange={handleChange("type")}
                        name="type"
                      />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <FaInput
                        required
                        multiline
                        id="type"
                        autoComplete="off"
                        label={t("ACIKLAMA")}
                        value={values.description}
                        onChange={handleChange("description")}
                        name="description"
                      />
                    </Grid>
                    <Grid
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{ display: "flex" }}
                      item
                      lg={3}
                      xs={12}
                    >
                      {isNew ? (
                        <FaInput
                          required
                          id="tid"
                          label={t("TID")}
                          value={values.thid}
                          onChange={handleChange("thid")}
                          name="tid"
                          autoComplete="thid"
                        />
                      ) : (
                        <Badge badgeContent={issueCount} color="secondary">
                          <Button
                            onClick={showIssues}
                            variant="outlined"
                            disabled={issueCount === 0}
                            color="secondary"
                            startIcon={<ErrorOutlineIcon />}
                          >
                            {t("SORUNLAR")}
                          </Button>
                        </Badge>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {isNew ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveThing}
                  endIcon={<SaveIcon />}
                >
                  {t("KAYDET")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateThing}
                  endIcon={<CheckIcon />}
                >
                  {t("GUNCELLE")}
                </Button>
              )}
            </CardActions>
          </Card>
          {!isNew ? (
            <Card className={classes.mt}>
              <CardHeader
                title="Görseller"
                action={
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ImageIcon />}
                    size="small"
                    onClick={() => setOpenUpload(true)}
                  >
                    {t("GORSEL_EKLE")}
                  </Button>
                }
              />
              <CardContent>
                <DropzoneDialog
                  acceptedFiles={["image/*"]}
                  cancelButtonText={t("IPTAL")}
                  submitButtonText={t("YUKLE")}
                  maxFileSize={20000000}
                  filesLimit={1}
                  open={openUpload}
                  dialogTitle={t("GORSEL_YUKLE")}
                  onClose={() => setOpenUpload(false)}
                  previewText={t("ONIZLEME")}
                  dropzoneText={t("SURUKLE_BIRAK")}
                  onSave={fileUpload}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />

                <ImageList
                  cellHeight={180}
                  cols={lg ? 5 : 2}
                  className={classes.gridList}
                >
                  {imgList.map((img) => (
                    <ImageListItem key={img.id}>
                      <img
                        src={`data:image/png;base64, ${img.content}`}
                        alt={img.description}
                      />
                      <ImageListItemBar
                        title={img.name}
                        actionIcon={
                          <IconButton
                            aria-label={`info about ${img.name}`}
                            className={classes.icon}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </CardContent>
            </Card>
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}
