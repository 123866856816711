import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  map: {
    flexGrow: 1,
    height: 500,
  },
  closeButton: {
    color: theme.palette.grey[500],
    right: 0,
    top: 0,
    position: "absolute",
  },
  drawer: {
    overflow: "hidden",
  },
  drawerContent: {
    padding: 10,
    height: 400,
  },
  drawerPaper: {
    height: 400,
    top: 80,
    width: "21.7%",
    minWidth: 200,
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  success: {
    backgroundColor: green[500],
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  }
}));
