import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CustomerInteractionsSuggestions(props) {
  const { code } = useParams();

  const stepsRef = useRef();
  const classes = useStyles();

  const { t } = useTranslation("tutorialSuggestion", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Müşterilerinizin bildirdiği <b>Öneriler</b>'in toplu liste olarak
            gösterildiği menü/sayfadır.
            <p>
              Bu menüde bildirilen Önerileri;
              <ul>
                <li>
                  <b>Tarih</b>’e göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim Noktası</b>’na göre listeleyebilirsiniz
                </li>
                <li>
                  <b>İşareti</b>’ne göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>Açıklama</b>, <b>E-Posta</b> ve <b>Telefon</b>'a göre
                  listeleyebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim noktası</b>’na göre filtreleyebilirsiniz.
                </li>
                <li>
                  <b>Excel</b> dosyası olarak dışarı aktarabilirsiniz.
                </li>
                <li>
                  Öneri <b>detay bilgilerine</b> erişebilirsiniz.{" "}
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='DATE_BUTTON_GROUP']",
        intro: (
          <TutorialElement icon="click">
            Önerileri Tarih’e göre listelemek için <b>Bugün, Son 7 Gün</b> ve{" "}
            <b>Son 1 Ay</b> seçeneklerinden birine tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='suggestionDate']",
        intro: (
          <TutorialElement icon="click">
            Önerilerin tümünü <b>ilk</b> veya <b>son</b> beğeni tarihine göre
            listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='COLORED_BOOKMARK_BUTTON_GROUP']",
        intro: (
          <TutorialElement icon="click">
            Önerileri <b>İşaret</b>’ine göre listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='bookmarked']",
        intro: (
          <TutorialElement icon="click">
            Önerilerin tümünü <b>İşaret</b>’lere göre listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='description']",
        intro: (
          <TutorialElement icon="click">
            Önerilerin tümünü <b>Açıklama</b>’lara göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: () => {
          stepsRef.current.updateStepElement(7);
        },
      },
      {
        element: "[data-fa-column='email']",
        intro: (
          <TutorialElement icon="click">
            Önerilerin tümünü <b>E-Postalara</b> göre listelemek için tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: () => {
          stepsRef.current.updateStepElement(8);
        },
      },
      {
        element: "[data-fa-column='phone']",
        intro: (
          <TutorialElement icon="click">
            Önerilerin tümünü <b>Telefon Numaralarına</b> göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: () => {
          stepsRef.current.updateStepElement(9);
        },
      },
      {
        element: ".appSearchBar",
        intro: (
          <TutorialElement icon="click">
            Hızlı arama için <b>Ara</b>’ya ve detaylı arama için <b>Filtre</b>
            ’ye tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: ".MuiButton-textSizeSmall",
        intro: (
          <TutorialElement icon="click">
            <b> Tarih</b> gösterimini ayarlamak için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issues_date_settings.png"
                }
                width="700"
                height="261"
              ></img>
            </div>
            <br />
            <b>Görünüm</b>’e tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issues_date_settings.png"
                }
                width="700"
                height="261"
              ></img>
            </div>
            <br />
            <b>Açık Tarih</b> veya <b>Akıllı Tarih</b>’ten birini seçin ve{" "}
            <b>Uygula</b> butonuna basın.
            <br />
            <br />
            <b>ÖNEMLİ NOT</b> :<br />
            <b>Açık Tarih</b>, etkileşim noktası ile ilgili her bir işlemin
            gerçekleştiği tarih ve saat bilgilerini içerir.
            <b>Akıllı Tarih</b> ise işlemin gerçekleşmesi sonrasında geçen
            süreyi içerir.
          </TutorialElement>
        ),
        tooltipClass: classes.issueDateSettings,
      },
      {
        element: "div.MuiTablePagination-selectRoot",
        intro: (
          <TutorialElement icon="click">
            {t(
              "Sayfa başına Öneri Sayısını ayarlamak için tıklayın ve 10, 25, 50 ve 100 seçeneklerinden birini seçin."
            )}
          </TutorialElement>
        ),
      },
      {
        element: ".MuiTablePagination-actions",
        intro: (
          <TutorialElement icon="click">
            {t(
              "Sayfalar arasında geçiş yapmak için “<” ve “>” seçeneklerine tıklayın."
            )}
          </TutorialElement>
        ),
      },
      {
        element: "[title='Excel']",
        intro: (
          <TutorialElement icon="click">
            Listeyi <b>Dışarı Aktarmak</b> için <b>Excel</b> ikonuna tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/oneri_list_element.png"
                }
                width="814"
                height="356"
              ></img>
            </div>
            <br />
            Detay bilgilerine erişmek istediğiniz <b>Öneri</b>’nin üzerine
            <b> çift</b> tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.suggestionListElement,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"suggestions"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
