import { Check, Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { PROXY } from "../../services/faApi";
import ProxyEdit from "./ProxyEdit";

export default function Proxy() {
  const tableRef = useRef();
  const { t } = useTranslation(["proxy", "components"], { i18n });
  const dialogRef = useRef();
  const accountId = localStorage.accountId;
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [selectedProxy, setSelectedProxy] = useState();

  const columns = [
    {
      field: "sourceUser",
      label: t("SOURCE_USER"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "targetUser",
      label: t("TARGET_USER"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "15%",
    },
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "includeQueueDispatch",
      label: t("INCLUDE_QUEUE_DISPATCH"),
      align: "center",
      width: "5%",
      valueFormatter: (value, row) => {
        if (value == null || value === false) return <Close />;
        else return <Check />;
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      description: null,
      sourceUser: null,
      targetUser: null,
      sourceUserId: null,
      targetUserId: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setSelectedProxy(row);
    dialogRef.current.openDialog();
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const renderNewProxy = () => {
    return (
      <ProxyEdit
        ref={dialogRef}
        onSave={refresh}
        proxy={selectedProxy}
        onClose={() => {
          setSelectedProxy(null);
        }}
      ></ProxyEdit>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {renderNewProxy()}
        <ThingsDataTable
          searchUrl={PROXY.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{ page: 0, size: 10, sort: "id", isAsc: false }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                value={searchForm.description || ""}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <Grid item xs={12}>
                <UserAutoComplete
                  value={searchForm.sourceUser}
                  accountId={accountId}
                  onChange={(data) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "sourceUser",
                      data,
                      "sourceUserId",
                      data?.id
                    );
                  }}
                  label={t("SOURCE_USER")}
                />
              </Grid>

              <Grid item xs={12}>
                <UserAutoComplete
                  value={searchForm.targetUser}
                  accountId={accountId}
                  onChange={(data) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "targetUser",
                      data,
                      "targetUserId",
                      data?.id
                    );
                  }}
                  label={t("TARGET_USER")}
                />
              </Grid>
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
