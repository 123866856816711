import Grid from "@mui/material/Grid";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./ThingDetailCardStyle";

const ThingOrganizationEdit = forwardRef((props, ref) => {
  const { thing, onUpdate } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsOrganizationFormOpen(true);
    },
  }));

  const [thingChange, setThingChange] = useState({});

  useEffect(() => {
    setThingChange({
      thingId: thing.id,
      thingOrganization: thing.organization,
      account: thing.account,
    });
  }, [thing]);

  const [isOrganizationFormOpen, setIsOrganizationFormOpen] = useState(false);

  const updateOrganization = () => {
    ThingService.changeOrganization(
      thingChange.thingId,
      thingChange.organization.id,
      updateSuccessful,
      updateFail
    );
  };

  const updateSuccessful = (newData) => {
    onUpdate(newData);
    setIsOrganizationFormOpen(false);
  };

  const updateFail = () => {};

  return (
    <FaDialog
      title={"Etkileşim Noktası Organizasyonunu Değiştirme"}
      faOpen={isOrganizationFormOpen}
      faOnSubmit={updateOrganization}
      showSaveButton
      faHandleClose={(data) => {
        setIsOrganizationFormOpen(data);
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={0}
      >
        <Grid item xs={12}>
          <OrganizationAutoComplete
            label="Organizasyon"
            required={true}
            value={thingChange?.organization}
            accountId={thingChange?.account?.id}
            onChange={(data) => {
              setThingChange({
                ...thingChange,
                organization: data,
              });
            }}
          />
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default ThingOrganizationEdit;
