import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import Adsense from "components/adsense/Adsense";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ColoredBookmarkButtonGroup from "components/buttonGroup/ColoredBookmarkButtonGroup";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingTableDecorator from "components/decorator/ThingTableDecorator";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SuggestionService } from "services/SuggestionService";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { SUGGESTION } from "../../services/faApi";

export default function Suggestions(props) {
  const { t } = useTranslation("suggestion", { i18n });

  const accountId = localStorage.accountId;
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;

  const newSearchForm = () => {
    let result = {
      notifier: null,
      thing: null,
      thingId: null,
      description: null,
      email: null,
      phone: null,
      suggestionDateStart: props.startDate ? props.startDate : null,
      suggestionDateEnd: null,
      accountId: accountId,
      bookmarked: null,
      bookmarkColor: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const bookmarkColors = ["GREEN", "YELLOW", "RED", "BLUE", "BLACK"];
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleBookmarkClick = (row, isChecked, newColor) => {
    setAnchorEl(null);
    changeBookmark(row, isChecked, newColor);
  };

  const handleClick = (id, event) => {
    setSelectedRowId(id);
    setAnchorEl(event.currentTarget);
  };
  const columns = [
    {
      width: "10%",
      field: "suggestionDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      type: "smartDate",
    },
    {
      width: "30%",
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      inner: "description",
      valueFormatter: (value, row) => {
        return <ThingTableDecorator value={row.thing} />;
      },
    },
    {
      width: "25%",
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
    },
    {
      width: "15%",
      field: "email",
      label: t("EMAIL"),
      align: "center",
    },
    {
      width: "15%",
      field: "phone",
      label: t("PHONE"),
      align: "center",
    },
    {
      width: "5%",
      field: "bookmarked",
      label: t("BOOKMARK"),
      align: "center",
      valueFormatter: (val, row) => (
        <>
          <IconButton onClick={(e) => handleClick(row.id, e)} size="large">
            {val === "YES" && row?.bookmarkColor ? (
              <Bookmark style={{ color: row?.bookmarkColor }} />
            ) : (
              <BookmarkBorder style={{ color: "black" }} />
            )}
          </IconButton>
          {selectedRowId && row.id === selectedRowId ? (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                key={0}
                selected={val === "NO"}
                onClick={() => handleBookmarkClick(row, "NO", null)}
              >
                <BookmarkBorder />
              </MenuItem>
              {bookmarkColors.map((c, index) => {
                return (
                  <MenuItem
                    key={index}
                    selected={c === row?.bookmarkColor}
                    onClick={() => handleBookmarkClick(row, "YES", c)}
                  >
                    <Bookmark style={{ color: c }} />
                  </MenuItem>
                );
              })}
            </Menu>
          ) : null}
        </>
      ),
    },
  ];

  const changeBookmark = (row, checked, color) => {
    let item = { ...row };
    item.bookmarked = checked;
    item.bookmarkColor = color;

    SuggestionService.save(
      item,
      () => {
        tableRef.current.refreshTable();
      },
      () => {}
    );
  };

  useEffect(() => {
    refresh();
  }, [history.location.search]);

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `suggestions/detail/${row.id}`,
      breadCrumbKey: `/suggestions/detail/:id`,
    };
    history.push(tmpTo);
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const calculateDate = (data) => {
    var today = new Date();
    let start = null;

    if (data === -7) {
      start = new Date(today);
      start.setDate(start.getDate() - 7);
    } else if (data === -31) {
      start = new Date(today.setMonth(today.getMonth() - 1));
    }

    return start;
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Adsense value="RklmAlt" />
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("EMAIL")}
                value={searchForm.email}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "email",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("PHONE")}
                value={searchForm.phone}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "phone",
                    event.target.value
                  );
                }}
              />

              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    suggestionDateStart: data.startDate,
                    suggestionDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={1}
          style={{ marginBottom: 5, overflowY: "auto" }}
        >
          <Grid item lg={4}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TARIH")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  data-fa-section="DATE_BUTTON_GROUP"
                  value={searchForm.notificationDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      notificationDateValue: data,
                      suggestionDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("BOOKMARKED")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ColoredBookmarkButtonGroup
                  data-fa-section="COLORED_BOOKMARK_BUTTON_GROUP"
                  value={searchForm.bookmarkColor}
                  isChecked={searchForm.bookmarked}
                  onChange={(color, isChecked) => {
                    const temp = {
                      ...searchForm,
                      bookmarkColor: color,
                      bookmarked: isChecked,
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={SUGGESTION.search + "?accountId=" + localStorage.accountId}
          exportUrl={SUGGESTION.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "suggestionDate",
            accountId: localStorage.accountId,
          }}
        />
      </Suspense>
    </>
  );
}
