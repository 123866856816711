import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  ThingDetailImage: {
    width: "100%",
    objectFit: "scale-down",
  },
  ThingDetailLabelHeader: {
    //textAlign: "right"
  },
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
