import {
  Avatar,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionRanking(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;
  useEffect(() => {
    if (answer && !answer.selectedOptions) {
      let list = question.options.map((item) => item.id);
      onChange(question, list, "selectedOptions");
    }
  }, [answer]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tmpList = reorder(
      answer.selectedOptions,
      result.source.index,
      result.destination.index
    );

    onChange(question, tmpList, "selectedOptions");
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const findOptionName = (id) => {
    const result = question.options.find((x) => x.id === id);
    return GetFieldByLanguage(result.multiLangName, currentLang);
  };

  return (
    <Grid item xs={12}>
      <FormControl
        variant="standard"
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}>
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
      </FormControl>
      {answer && answer.selectedOptions && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {answer.selectedOptions.map((item, index) => (
                  <Draggable
                    key={item}
                    draggableId={"" + item}
                    index={index}
                    isDragDisabled={readOnly}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItemAvatar>
                          <Avatar>{index + 1}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={findOptionName(item)} />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Grid>
  );
}

SurveyQuestionRanking.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
