import { Grid } from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import FaMap, { default as MapSearchBox } from "react-base-fa/dist/fa/faMap";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocationService } from "services/LocationService";
import i18n from "../../i18n";
import { useStyles } from "./style";

const LocationEdit = forwardRef((props, ref) => {
  const { value, onSave, thing } = props;
  const { t } = useTranslation("location", { i18n });
  const accountId = localStorage.accountId;
  const classes = useStyles();

  const userInfo = useSelector(getUserInfo);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (value) {
      setLocation(value == null ? newLocation() : { ...value });
    }
  }, [value]);

  const saveLocation = () => {
    setLoading(true);
    LocationService.save(
      {
        ...location,
        account: { id: accountId },
        gpsLocation: JSON.stringify(location.gpsLocation),
      },
      saveOnSuccess,
      saveOnError
    );
  };

  const saveOnSuccess = (data) => {
    showAlert(t("SAVED"), "success"); // edited silindi
    setIsDialogOpen(false);
    setLoading(false);
    onSave(data);
  };
  const saveOnError = (error) => {
    console.log(error);
    showAlert(error, "error");
    setLoading(false);
  };

  const newLocation = () => {
    return {
      id: "",
      name: "",
      description: "",
      gpsLocation: {
        lat: 39.862645729977174,
        lng: 32.738810051232576,
      },
    };
  };

  const [location, setLocation] = useState(newLocation());

  const handleChange = (prop) => (event) => {
    setLocation({ ...location, [prop]: event.target.value });
  };

  const onChangeKonum = (event) => {
    setLocation({
      ...location,
      gpsLocation: {
        lng: event.lngLat[0],
        lat: event.lngLat[1],
      },
    });
  };

  const onChangeHaritaKonum = (event) => {
    setLocation({
      ...location,
      gpsLocation: {
        lng: event.lngLat[0],
        lat: event.lngLat[1],
      },
    });
  };

  const [viewport, setViewport] = useState({
    latitude: location?.gpsLocation.lat,
    longitude: location?.gpsLocation.lng,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });

  const handleLocationSelect = (value) => {
    const [longitude, latitude] = value.center;

    setLocation({
      ...location,
      gpsLocation: {
        lng: longitude,
        lat: latitude,
      },
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 17 });
  };

  return (
    <FaDialog
      title={t("EDIT_LOCATION")}
      loading={loading}
      showSaveButton
      faHandleClose={(data) => {
        setIsDialogOpen(false);
        setLocation(location ? location : newLocation());
      }}
      faOpen={isDialogOpen}
      faOnSubmit={() => saveLocation()}
      faMaxWidth="xl"
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            required
            maxLength={100}
            label={t("NAME")}
            onChange={handleChange("name")}
            value={location.name || ""}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            maxLength={100}
            label={t("DESCRIPTION")}
            onChange={handleChange("description")}
            value={location.description || ""}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            container
            spacing={1}
            className={classes.map}
            style={{ position: "relative" }}
          >
            <MapSearchBox onSelectLocation={handleLocationSelect} />
            <FaMap
              viewport={viewport}
              className={classes.map}
              marker={{
                latitude: location.gpsLocation.lat,
                longitude: location.gpsLocation.lng,
                onMarkerDragEnd: onChangeKonum,
              }}
              onClick={onChangeHaritaKonum}
              onDblClick={onChangeHaritaKonum}
              onViewportChange={setViewport}
            ></FaMap>
          </Grid>
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default LocationEdit;
