import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { CHART_COLORS } from "services/utils/ChartConstants";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const InteractionCountsByOperationChart = forwardRef((props, ref) => {
  const { onClick, faType, ...other } = props;
  const { t } = useTranslation(["customer", "components"], { i18n });
  const labelClasses = labelStyles();

  const [dailyCounts, setDailyCounts] = useState(null);

  const [masterSearchForm, setMasterSearchForm] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);

  const config = {
    default: {
      containerHeight: "22rem",
      chartHeight: "20rem",
      margin: { top: 15, right: 5, bottom: 45, left: -100 },
      title: true,
      legends: [
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 50,
          translateY: 0,
          itemWidth: 200,
          itemHeight: 20,
          itemsSpacing: 0,
          symbolSize: 20,
          itemDirection: "left-to-right",
        },
      ],
    },
    small: {
      containerHeight: "10rem",
      chartHeight: "8rem",
      margin: { top: 5, right: 0, bottom: 5, left: -80 },
      title: false,
      legends: [
        {
          anchor: "right",
          direction: "column",
          justify: false,
          translateX: 45,
          translateY: 0,
          itemWidth: 120,
          itemHeight: 12,
          itemsSpacing: 0,
          symbolSize: 12,
          itemDirection: "left-to-right",
        },
      ],
    },
  };

  const mergedProps = faType
    ? { ...config.default, ...config[faType], ...other }
    : { ...config.default, ...other };

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        ThingStatisticService.getInteractionCountsByOperation(
          searchForm,
          getInteractionCountsByOperationOnSuccess,
          (error) => {}
        );
      }
    },
  }));

  const getInteractionCountsByOperationOnSuccess = (data) => {
    let tmp = data.map((item) => {
      if (item.label === "USER_CONTENT" || item.label === "SOCIAL_MEDIA")
        item.label += "_SHORT";
      return {
        ...item,
        id: t("CUSTOMER_INTERACTION_OPERATION." + item.label, {
          ns: "components",
        }),
        label: t("CUSTOMER_INTERACTION_OPERATION." + item.label, {
          ns: "components",
        }),
      };
    });

    // piechartta labelların üst üste gelmemesi için
    // veriler bir büyük bir küçük değer olacak şekilde sıralandı.
    let mixedTmp = [];
    let i = 0;
    while (i !== Math.ceil(tmp.length / 2)) {
      mixedTmp.push(tmp[i]);
      if (i + 1 === tmp.length - i) break;
      mixedTmp.push(tmp[tmp.length - 1 - i]);
      i += 1;
    }
    setDailyCounts(mixedTmp);
  };

  return (
    <>
      {dailyCounts && (
        <Grid container spacing={2} style={{ overflowY: "auto" }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card
                  data-fa-section="INTERACTION_COUNTS_BY_OPERATION_CHART"
                  style={{ height: mergedProps.containerHeight }}
                >
                  {mergedProps.title && (
                    <CardHeader
                      title={t("INTERACTION_DISTRIBUTION")}
                      style={{ height: "40px" }}
                    />
                  )}
                  <CardContent>
                    <div>
                      <FaPieChart
                        data={dailyCounts}
                        legends={mergedProps.legends}
                        height={mergedProps.chartHeight}
                        //width="300px"
                        colors={CHART_COLORS}
                        enableArcLabels={true}
                        enableArcLinkLabels={false}
                        innerRadius={0.5}
                        padAngle={1}
                        cornerRadius={5}
                        //onClick={chartClicked}
                        margin={mergedProps.margin}
                        arcLinkLabelsDiagonalLength={68}
                        arcLinkLabelsStraightLength={16}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
});
export default InteractionCountsByOperationChart;

InteractionCountsByOperationChart.propTypes = {
  faType: PropTypes.string,
  onClick: PropTypes.func,
};
