import {
  faEdit,
  faLock,
  faLockOpen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccessTypeSelect from "components/select/AccessTypeSelect";
import ThingClassDocumentHelp from "features/help/ThingClassDocumentHelp";
import React, { useEffect, useState } from "react";
import { FaDialog, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ThingClassService } from "services/thingClass/thingClassService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingClassDocuments(props) {
  const { thingClass } = props;
  const faDialog = useDialog();
  const labelClasses = labelStyles();
  const classes = useStyles();
  const theme = useTheme();
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [files, setFiles] = useState([]);

  const { t } = useTranslation(["thingClass", "errorMessages"], { i18n });

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [thingClassDocuments, setThingClassDocuments] = useState([]);
  const [thingClassDocument, setThingClassDocument] = useState({
    name: "",
    description: "",
    accessType: "PUBLIC",
  });

  useEffect(() => {
    if (thingClass && thingClass.id)
      ThingClassService.findDocuments(
        thingClass.id,
        accountId,
        findDocumentsOnSuccess,
        findDocumentsOnError
      );
  }, [thingClass]);

  const findDocumentsOnSuccess = (data) => {
    setThingClassDocuments(data);
  };

  const findDocumentsOnError = () => {};

  const saveDocument = () => {
    ThingClassService.uploadThingClassDocument(
      thingClass.id,
      files[0],
      thingClassDocument.name,
      thingClassDocument.description,
      thingClassDocument.accessType,
      accountId,
      uploadDocumentOnSuccess,
      uploadDocumentOnError
    );
  };

  const reloadThingDocuments = () => {
    ThingClassService.findDocuments(
      thingClass.id,
      accountId,
      findDocumentsOnSuccess,
      findDocumentsOnError
    );
  };

  const uploadDocumentOnSuccess = () => {
    ThingClassService.findDocuments(
      thingClass.id,
      accountId,
      findDocumentsOnSuccess,
      findDocumentsOnError
    );
    setIsEditDialogOpen(false);
    setFiles([]);
  };

  const uploadDocumentOnError = (error) => {
    showAlert(t("errorMessages:" + error.message), "error");
  };

  const handleChangeV = (prop, val) => {
    setThingClassDocument({ ...thingClassDocument, [prop]: val });
  };

  const downloadDocument = (item) => {
    ThingClassService.downloadThingClassDocument(
      thingClass.id,
      item.object,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    //const url = window.URL.createObjectURL(new Blob([data.content]));
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  //
  const makeDocumentPublic = (item) => {
    ThingClassService.makeDocumentPublic(
      item.object,
      thingClass.account.id,
      makePublicOnSuccess,
      makePublicOnError
    );
  };

  const makePublicOnSuccess = (data) => {
    reloadThingDocuments();
    setIsDetailDialogOpen(false);
  };

  const makePublicOnError = (data) => {};

  const makeDocumentPrivate = (item) => {
    ThingClassService.makeDocumentPrivate(
      item.object,
      thingClass.account.id,
      makePrivateOnSuccess,
      makePrivateOnError
    );
  };

  const makePrivateOnSuccess = (data) => {
    reloadThingDocuments();
    setIsDetailDialogOpen(false);
  };

  const makePrivateOnError = (data) => {};
  //

  const makeDocumentAttendant = (item) => {
    ThingClassService.makeDocumentAttendant(
      item.object,
      thingClass.account.id,
      makePublicOnSuccess,
      makePublicOnError
    );
  };

  const deleteDocument = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      ThingClassService.deleteDocument(
        thingClass.id,
        item.object,
        deleteDocumentOnSuccess,
        deleteDocumentOnError
      );
    });
  };

  const deleteDocumentOnSuccess = (data) => {
    ThingClassService.findDocuments(
      thingClass.id,
      accountId,
      findDocumentsOnSuccess,
      findDocumentsOnError
    );

    setIsDetailDialogOpen(false);
  };

  const deleteDocumentOnError = (data) => {};

  const renderEditDialog = () => {
    return (
      <FaDialog
        data-fa-section="FILES_EDIT"
        title={t("Dosya Ekle")}
        faOpen={isEditDialogOpen}
        faOnSubmit={saveDocument}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("NAME")}
              onChange={(event) => handleChangeV("name", event.target.value)}
              value={thingClassDocument.name || ""}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={thingClassDocument.description || ""}
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AccessTypeSelect
              value={thingClassDocument.accessType}
              onChange={(data) => handleChangeV("accessType", data)}
            />
          </Grid>
          <Grid item xs={12}>
            <FaFileUpload
              maxFileSize={20000000}
              onChange={setFiles}
              initialFiles={files}
              filesLimit={1}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderMakePublic = () => {
    return (
      <Grid item xs={6}>
        <Button
          style={{ width: "100%" }}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<FontAwesomeIcon icon={faLockOpen} />}
          onClick={() => {
            makeDocumentPublic(thingClassDocument);
          }}
        >
          {t("PUBLIC")}
        </Button>
      </Grid>
    );
  };

  const renderMakePrivate = () => {
    return (
      <Grid item xs={6}>
        <Button
          style={{ width: "100%" }}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<FontAwesomeIcon icon={faLock} />}
          onClick={() => {
            makeDocumentPrivate(thingClassDocument);
          }}
        >
          {t("PRIVATE")}
        </Button>
      </Grid>
    );
  };

  const renderMakeAttendant = () => {
    return (
      <Grid item xs={6}>
        <Button
          style={{ width: "100%" }}
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<AssignmentIndIcon />}
          onClick={() => {
            makeDocumentAttendant(thingClassDocument);
          }}
        >
          {t("ATTENDANT")}
        </Button>
      </Grid>
    );
  };

  const renderDetailDialog = () => {
    return (
      <FaDialog
        title={t("Dosya Detay")}
        faOpen={isDetailDialogOpen}
        faHandleClose={() => {
          setIsDetailDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NAME")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {thingClassDocument.name}
                </Typography>
              </Grid>

              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("ACCESS_TYPE")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {t("" + thingClassDocument.accessType)}
                </Typography>
              </Grid>

              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DESCRIPTION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {thingClassDocument.description}
                </Typography>
              </Grid>

              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Link
                  onClick={() => {
                    downloadDocument(thingClassDocument);
                  }}
                  style={{
                    textDecoration: "underline",
                    fontSize: "1rem",
                    cursor: "pointer",
                  }}
                  underline="hover">
                  <Typography className={labelClasses.LabelValue}>
                    {thingClassDocument.fileName}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={7}>
                <Grid container spacing={1}>
                  {thingClassDocument.accessType === "PRIVATE" && (
                    <>
                      {renderMakePublic()}
                      {renderMakeAttendant()}
                    </>
                  )}
                  {thingClassDocument.accessType === "PUBLIC" && (
                    <>
                      {renderMakePrivate()}
                      {renderMakeAttendant()}
                    </>
                  )}
                  {thingClassDocument.accessType === "ATTENDANT" && (
                    <>
                      {renderMakePublic()}
                      {renderMakePrivate()}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  startIcon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => {
                    deleteDocument(thingClassDocument);
                  }}
                  title={t("Sil")}
                >
                  {t("Sil")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return <>
    {renderEditDialog()}
    {renderDetailDialog()}
    <Card
      data-fa-section="FILES_CARD"
      style={{ minHeight: 100 }}
      className="fai-thingDocuments"
    >
      <CardHeader
        action={
          <>
            <ThingClassDocumentHelp value="thingClass.document" />

            <IconButton
              aria-label="settings"
              data-fa-button="FILES_ADD"
              onClick={() => {
                setThingClassDocument({
                  name: "",
                  description: "",
                  accessType: "PUBLIC",
                });
                setIsEditDialogOpen(true);
              }}
              size="large">
              <AddIcon />
            </IconButton>
          </>
        }
        title={t("FILES")}
        data-fa-section="FILES"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {thingClassDocuments &&
                  thingClassDocuments.length > 0 &&
                  thingClassDocuments?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          onClick={() => {
                            downloadDocument(item);
                          }}
                          style={{
                            textDecoration: "underline",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                          underline="hover">
                          <Typography className={classes.LabelValue}>
                            {item.name == null
                              ? item.fileName
                              : item.name + " (" + item.fileName + ")"}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell className={classes.LabelValue}>
                        {t(item.accessType)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => {
                            setThingClassDocument(item);
                            setIsDetailDialogOpen(true);
                          }}
                          size="small"
                          color="primary"
                          aria-label={t("EDIT")}
                          className={classes.ActionButton}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
