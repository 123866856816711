import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { ParseLocation } from "services/utils";
import { imageResize } from "services/utils/imageUtils";
import { PUBLIC } from "../../services/faApi";

const populateVisitorHeaders = (req) => {
  if (localStorage.getItem("visitorId")) {
    req.header = req.header
      ? { ...req.header, T_VisitorId: localStorage.getItem("visitorId") }
      : { T_VisitorId: localStorage.getItem("visitorId") };
  }
  if (localStorage.getItem("location1")) {
    req.header = req.header
      ? { ...req.header, T_Location1: localStorage.getItem("location1") }
      : { T_Location1: localStorage.getItem("location1") };
  }
  if (localStorage.getItem("location2")) {
    req.header = req.header
      ? { ...req.header, T_Location2: localStorage.getItem("location2") }
      : { T_Location2: localStorage.getItem("location2") };
  }
};

export const ReportService = {
  findByTid: (tid, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.findByTid + "?tid=" + tid);

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getPublicInfo: (id, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.getPublicInfo + "/" + id);

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  linkClick: (id, linkId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PUBLIC.linkClick + "/" + id + "/" + linkId
    );

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadDocument: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PUBLIC.downloadDocument +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  reportIssue: (issue, files, callback, errorCallback) => {
    let reqBody = {
      ...issue,
    };

    let req = requests.getSimplePostRequest(PUBLIC.reportIssue, reqBody);

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        if (files != null && files.length > 0) {
          let fd = new FormData();
          files.forEach((item) => {
            //fd.append("multipartFiles", item.file, item.file.name);
            fd.append("multipartFiles", item.file, item.name);
          });

          let req2 = requests.getSimplePostRequest(
            PUBLIC.uploadFiles + "/" + response.data.id,
            fd
          );
          req2["header"] = { "Content-Type": "multipart/form-data" };
          FaAxios(req2)
            .then((response2) => {
              callback(response.data);
            })
            .catch((error) => {
              errorCallback(error?.response?.data);
            });
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  reportLike: (like, callback, errorCallback) => {
    let reqBody = {
      ...like,
    };

    let req = requests.getSimplePostRequest(PUBLIC.reportLike, reqBody);
    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        console.log("error", error);
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  reportSuggestion: (suggestion, files, callback, errorCallback) => {
    let reqBody = {
      ...suggestion,
    };

    let req = requests.getSimplePostRequest(PUBLIC.reportSuggestion, reqBody);
    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        if (files != null && files.length > 0) {
          let fd = new FormData();
          files.forEach((item) => {
            fd.append("multipartFiles", item.file, item.name);
          });

          let req2 = requests.getSimplePostRequest(
            PUBLIC.uploadSuggestionFiles + "/" + response.data,
            fd
          );
          req2["header"] = { "Content-Type": "multipart/form-data" };
          FaAxios(req2)
            .then((response2) => {
              callback(response.data);
            })
            .catch((error) => {
              errorCallback(error?.response?.data);
            });
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  reportUserContent: (userContent, files, callback, errorCallback) => {
    let reqBody = {
      ...userContent,
    };

    let req = requests.getSimplePostRequest(PUBLIC.reportUserContent, reqBody);
    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        if (files != null && files.length > 0) {
          let fd = new FormData();
          files.forEach((item) => {
            fd.append("multipartFiles", item.file, item.name);
          });

          let req2 = requests.getSimplePostRequest(
            PUBLIC.uploadUserContentFiles + "/" + response.data,
            fd
          );
          req2["header"] = { "Content-Type": "multipart/form-data" };
          FaAxios(req2)
            .then((response2) => {
              callback(response.data);
            })
            .catch((error) => {
              errorCallback(error?.response?.data);
            });
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadThumbnails: (thingId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(PUBLIC.downloadThumbnails, {
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  downloadImages: (thingId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(PUBLIC.downloadImages, {
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getDefaultImage: (thingId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(PUBLIC.getDefaultImage, {
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getImageCount: (thingId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(PUBLIC.getImageCount, {
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  downloadImage: (thingId, objectId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PUBLIC.downloadImage +
        "?thingId=" +
        thingId +
        "&objectId=" +
        encodeURI(objectId)
    );

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkVisitor: (
    accountId,
    visitorId,
    reportType,
    thingId,
    callback,
    errorCallback
  ) => {
    let req = requests.getSimpleGetRequest(PUBLIC.checkVisitor, {
      accountId,
      visitorId,
      reportType,
      thingId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  customer: async (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(PUBLIC.customer, reqBody);

    populateVisitorHeaders(req);

    FaAxios(req)
      .then(async (response) => {
        if (data.campaignCustomer) {
          let campaignData = {
            ...data.campaignCustomer,
            customer: { id: response.data.id },
          };
          ReportService.registerCampaign(
            campaignData,
            (s) => {
              callback(response.data);
            },
            errorCallback
          );
        } else if (data.surveyCustomer) {
          let surveyData = {
            ...data.surveyCustomer,
            customer: { id: response.data.id },
          };
          ReportService.answerSurvey(
            surveyData,
            (s) => {
              callback(response.data);
            },
            errorCallback
          );
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  existingCustomerRegisterThing: async (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(
      PUBLIC.existingCustomerRegisterThing,
      reqBody
    );

    populateVisitorHeaders(req);

    FaAxios(req)
      .then(async (response) => {
        if (data.campaignCustomer) {
          let campaignData = {
            ...data.campaignCustomer,
            customer: { id: response.data.id },
          };
          ReportService.registerCampaign(
            campaignData,
            (s) => {
              callback(response.data);
            },
            errorCallback
          );
        } else if (data.surveyCustomer) {
          let surveyData = {
            ...data.surveyCustomer,
            customer: { id: response.data.id },
          };
          ReportService.answerSurvey(
            surveyData,
            (s) => {
              callback(response.data);
            },
            errorCallback
          );
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveAccessLog: (accessLog, callback, errorCallback) => {
    let reqBody = {
      ...accessLog,
    };

    let req = requests.getSimplePostRequest(PUBLIC.saveAccessLog, reqBody);

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getSurveyByThing: (id, thingId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.getSurvey, {
      id,
      thingId,
      accountId,
    });

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  getSurveyWithoutThing: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.getSurveyWithoutThing, {
      id,
      accountId,
    });

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  answerSurvey: (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(PUBLIC.answerSurvey, reqBody);

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getCampaign: (id, thingId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.getCampaign, {
      id,
      thingId,
      accountId,
    });

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  registerCampaign: async (data, callback, errorCallback) => {
    let reqBody = {
      ...data,
    };

    let req = requests.getSimplePostRequest(PUBLIC.registerCampaign, reqBody);

    populateVisitorHeaders(req);
    // req.header = req.header
    //   ? { ...req.header, "Content-Type": "multipart/form-data" }
    //   : { "Content-Type": "multipart/form-data" };

    await FaAxios(req)
      .then(async (response) => {
        let shoppingFileAnswers = data.answers.filter(
          (item) => item.shoppingList
        );
        if (shoppingFileAnswers && shoppingFileAnswers.length > 0) {
          for (const shoppingFileAnswer of shoppingFileAnswers) {
            // geçici olarak yüklenmiş dosyaları atla
            let files = shoppingFileAnswer.shoppingList.filter(
              (item) => item.multipartFile && !!!item.tempFileId
            );
            if (files != null && files.length > 0) {
              let fd = new FormData();
              files.forEach((item) => {
                fd.append(
                  "multipartFiles",
                  item.multipartFile.file,
                  item.multipartFile.name
                );
                fd.append("answerId", shoppingFileAnswer.campaignQuestion.id);
              });

              let req2 = requests.getSimplePostRequest(
                PUBLIC.uploadCampaignShoppingFiles + "/" + response.data,
                fd
              );
              req2["header"] = { "Content-Type": "multipart/form-data" };
              await FaAxios(req2)
                .then((response2) => {})
                .catch((error) => {});
            } else {
            }
          }
        }

        return response;
      })
      .then(async (response) => {
        // temp olarak yüklenmemiş dosyaları upload et
        let files = data.answers.filter(
          (item) => item.multipartFile && !!!item.tempFileId
        );
        if (files != null && files.length > 0) {
          let fd = new FormData();
          files.forEach((item) => {
            fd.append(
              "multipartFiles",
              item.multipartFile.file,
              item.multipartFile.name
            );
            fd.append("answerIds", item.campaignQuestion.id);
          });

          let req2 = requests.getSimplePostRequest(
            PUBLIC.uploadCampaignFiles + "/" + response.data,
            fd
          );
          req2["header"] = { "Content-Type": "multipart/form-data" };
          await FaAxios(req2)
            .then((response2) => {
              callback(response.data);
            })
            .catch((error) => {
              errorCallback(error?.response?.data);
            });
        } else {
          callback(response.data);
        }
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getIssue: (id, thingId, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PUBLIC.getIssue + "/" + id + "/" + thingId + "/" + accountId
    );

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  beneficiaryNote: (id, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PUBLIC.beneficiaryNote + "/" + id + "/" + accountId
    );

    populateVisitorHeaders(req);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getLegal: (type, accountId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(
      PUBLIC.getLegal + "?type=" + type + "&accId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadTempFile: async (accountId, document, callback, errorCallback) => {
    let formData = new FormData();

    formData.append("multipartFile", document.file, document.file.name);

    let req = requests.getSimplePostRequest(
      PUBLIC.uploadTempFile + "/" + accountId,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  uploadTempImage: async (accountId, document, callback, errorCallback) => {
    let formData = new FormData();

    const compressedImage = await imageResize(document.file);

    formData.append("multipartFile", compressedImage, document.file.name);

    let req = requests.getSimplePostRequest(
      PUBLIC.uploadTempImage + "/" + accountId,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkCustomer: (accountId, visitorId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.checkCustomer, {
      accountId,
      visitorId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findFormByName: (name, accountId, classId, callback, errorCallback) => {
    let reqBody = {
      name: name,
      accountId: accountId,
      classId: classId,
    };

    let req = requests.getSimpleGetRequest(PUBLIC.findFormByName, reqBody);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  saveCustomer: (
    email,
    phone,
    gpsLocation,
    thingId,
    accountId,
    callback,
    errorCallback
  ) => {
    let reqBody = {
      email: email,
      phone: phone,
      gpsLocation: gpsLocation,
    };
    let req = requests.getSimplePostRequest(
      PUBLIC.saveCustomer + "?thingId=" + thingId + "&accountId=" + accountId,
      reqBody
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  isSurveyAnswerBlock: (surveyId, visitorId, callback, errorCallback) => {
    let req = requests.getSimpleGetRequest(PUBLIC.isSurveyAnswerBlock, {
      surveyId,
      visitorId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  getLocationLink: (publicThing) => {
    if (publicThing.gpsLocation) {
      let location = ParseLocation(publicThing.gpsLocation);

      const googleMapsUrl = `https://www.google.com/maps?q=${location.lat},${location.lng}`;
      return googleMapsUrl;
    }

    return null;
  },
};
