import { Grid } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TaskService } from "services/TaskService";
import i18n from "../../i18n";

const Pause = forwardRef((props, ref) => {
  const { onClose, value, open } = props;
  const { t } = useTranslation("todo", { i18n });
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;
  const userInfo = useSelector(getUserInfo);
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);

  const newPauseForm = () => {
    return {
      id: value?.id,
      accountId: accountId,
      userId: userInfo.id,
      description: "",
      date: new Date(),
    };
  };
  const [pauseForm, setPauseForm] = useState(newPauseForm());

  useEffect(() => {
    if (open) {
      setPauseForm(newPauseForm());
    }
  }, [open]);

  const handleChange = (prop) => (event) => {
    setPauseForm({ ...pauseForm, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(t("TASK_SAVED"), "success");
    setPauseForm(newPauseForm());
    setLoading(false);
    onClose();
  };

  const saveError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const savePause = () => {
    TaskService.pause(
      {
        ...pauseForm,
      },
      saveSuccess,
      saveError
    );
  };

  return (
    <>
      <FaDialog
        title={t("Beklemeye Al")}
        faOpen={open}
        faOnSubmit={savePause}
        loading={loading}
        showSaveButton
        faHandleClose={(data) => {
          onClose();
          setPauseForm(newPauseForm());
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              faType="description"
              value={pauseForm.description}
              label={t("DESCRIPTION")}
              onChange={handleChange("description")}
            />
          </Grid>
        </Grid>
      </FaDialog>
    </>
  );
});

export default Pause;
