import { Grid } from "@mui/material";
import { Done, Info, TouchApp, WbIncandescent } from "@mui/icons-material";
import PropTypes from "prop-types";
import React from "react";
import { useStyles } from "./TutorialElementStyle";

export default function TutorialElement(props) {
  const { header, icon, text } = props;

  const classes = useStyles();

  return (
    <Grid item container spacing={0}>
      {header && (
        <Grid item xs={10}>
          <h2 className={classes.header}>{header}</h2>
        </Grid>
      )}
      {icon && (
        <Grid item xs={3}>
          {icon === "tutorial" && (
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/teacher.png"}
              alt="tutorial"
            />
          )}
          {icon === "click" && <TouchApp className={classes.icon} />}
          {icon === "info" && <Info className={classes.icon} />}
          {icon === "done" && <Done className={classes.icon} />}
          {icon === "tip" && <WbIncandescent className={classes.icon} />}
        </Grid>
      )}
      {text && (
        <Grid item xs={9} className={classes.text}>
          {text}
        </Grid>
      )}
      {props?.children && (
        <Grid item xs={icon ? 9 : 12} className={classes.text}>
          {props.children}
        </Grid>
      )}
    </Grid>
  );
}

TutorialElement.propTypes = {
  header: PropTypes.string,
  image: PropTypes.any,
  text: PropTypes.string,
};
