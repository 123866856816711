import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TableCell,
} from "@mui/material";
import { Alert } from '@mui/material';
import React, { Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SubscriptionService } from "services/SubscriptionService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import SubscriptionPlan from "./SubscriptionPlan";
import TrialSuccessResult from "./TrialSuccessResult";
import { useStyles } from "./style";

export default function Trial() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation(["subscriptions", "errorMessages"], { i18n });
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  const [period, setPeriod] = useState("MONTHLY");
  const [newCampaign, setNewCampaign] = useState(null);
  const [deposite, setDeposite] = useState(null);
  const [trialResult, setTrialResult] = useState(null);

  const [subscription, setSubscription] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);

  const [listType, setListType] = useState("SUBSCRIPTIONS");
  const [paymentFormHtml, setPaymentFormHtml] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);

  const [trialSalesPackage, setTrialSalesPackage] = useState(null);
  const [defaultSalesPackage, setDefaultSalesPackage] = useState(null);

  const handleTabChange = (event, newValue) => {
    setPeriod(newValue);
  };

  const handleListChange = (event, newValue) => {
    setListType(newValue);
  };

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    setLoading(true);
    SubscriptionService.findTrialSalesPackage(
      (data) => {
        setTrialSalesPackage(data);
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
      }
    );

    SubscriptionService.findActiveSubscription(
      accountId,
      (data) => {
        setActiveSubscription(data);
      },
      (error) => {}
    );

    SubscriptionService.findDefaultSalesPackage(
      (data) => {
        setDefaultSalesPackage(data);
      },
      (error) => {}
    );
  }, []);

  const packageSelected = (selected) => {
    setSubscription({
      period,
      campaign: selected,
      price:
        period === "YEARLY"
          ? selected.discountPriceYearly * 12
          : selected.discountPriceMonthly,
      country: null,
      paymentMethod: null,
    });
    setNewCampaign(selected);
    setShowConfirmation(true);
  };

  const startTrial = () => {
    const data = {
      ...trialSalesPackage,
      price:
        period === "YEARLY"
          ? trialSalesPackage.discountPriceYearly * 12
          : trialSalesPackage.discountPriceMonthly,
      accountId: accountId,
      salesPackageId: trialSalesPackage.id,
      salesPackagePriceId: trialSalesPackage.salesPackagePriceId,
      monthPeriod: subscription.period === "YEARLY" ? 12 : 1,
    };

    setLoading(true);

    SubscriptionService.startTrial(
      data,
      (data) => {
        setShowConfirmation(false);
        setLoading(false);
        if (data) {
          setTrialResult(true);
        }
      },
      (error) => {
        showAlert(
          t("errorMessages:" + error?.message ?? "UNEXPECTED_ERROR"),
          "error"
        );
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12}>
            <Alert severity="info">
              Ücretsiz deneme paketini kısıtlı bir süre için kullanabilirsiniz.
            </Alert>
          </Grid>
          <Grid item lg={4} xs={6}>
            {defaultSalesPackage && (
              <TableCell>
                <SubscriptionPlan
                  value={defaultSalesPackage}
                  activeSubscription={activeSubscription}
                  period={period}
                />
              </TableCell>
            )}
          </Grid>
          <Grid item lg={4} xs={6}>
            {trialSalesPackage && (
              <TableCell>
                <SubscriptionPlan
                  value={trialSalesPackage}
                  activeSubscription={activeSubscription}
                  onSelect={packageSelected}
                  period={period}
                  trial={true}
                />
              </TableCell>
            )}
          </Grid>
        </Grid>

        {showConfirmation && (
          <Dialog
            open={showConfirmation}
            onClose={() => {
              setShowConfirmation(false);
            }}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Ücretsiz denemenizi başlatın
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p>
                  {t(
                    "Ücretsiz deneme paketinizi başlatmak istediğinizi emin misiniz? "
                  )}
                </p>
                {trialSalesPackage && (
                  <p>
                    {trialSalesPackage.monthlySubscribeDuration}{" "}
                    {trialSalesPackage.monthlySubscribeDuration !== -1 &&
                      t("" + trialSalesPackage.monthlySubscribeTimePeriod) +
                        " süreyle " +
                        trialSalesPackage.name +
                        " paketiniz başlatılacaktır. "}
                  </p>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowConfirmation(false);
                }}
                color="primary"
              >
                Kapat
              </Button>
              <Button onClick={startTrial} color="primary">
                Başlat
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {trialResult && <TrialSuccessResult />}
      </Suspense>
    </>
  );
}
