import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { Add } from "@mui/icons-material";
import { useStyles } from "./style";
import { FaButton } from "react-base-fa/dist/fa";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";


export default function IssuePhotosCarousel(props) {
  const { images, uploadImages, deleteImage } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("thingDetail", { i18n });
  const fileInputRef = useRef();

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }

  }


  const createCarouselItemImage = (item, index) => (
    <div key={index}>
        <img src={`data:image/png;base64, ${item.content}`} />
        
    </div>
);

const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  return (
    <Carousel showArrows={true}>
      {baseChildren.props.children}
    </Carousel>
  );
}
