import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button, Grid
} from "@mui/material";
import { default as React, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

export default function MenuCumulative(props) {
  const { value, onChange } = props;
  const { t } = useTranslation("statistic", { i18n });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [limit, setLimit] = useState(value);
  const [newLimit, setNewLimit] = useState(value);


  const handleCumulativeClick = (event) => {
    setNewLimit(limit);
    setIsModalOpen(true);
  };

  
  const saveLimit = () => {
    setLimit(newLimit);
    onChange(newLimit);
    setIsModalOpen(false);
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Ayarlar"}
        faOpen={isModalOpen}
        faOnSubmit={saveLimit}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("Diğer için gruplama limiti")}
              faType="number"
              required
              onChange={(event) => setNewLimit(event.target.value)}
              value={newLimit}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderMenu = () => {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleCumulativeClick}
          startIcon={<FontAwesomeIcon icon={faWrench} style={{color: "#6F7782"}} />}
        >
          
        </Button>
      </>
    );
  };

  return (
    <>
        {renderMenu()}
        {renderDialog()}
    </>
  );
}
