import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import ThingsMapButtonGroup from "components/buttonGroup/ThingsMapButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingStatusSelect from "components/select/ThingStatusSelect";
import LocationTree from "features/thingDetail/LocationTree";
import React, { useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { MapInteractionCSS } from "react-map-interaction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LocationDetailService } from "services/LocationDetailService";
import { ThingService } from "services/thing/thingService";
import { isTNF } from "services/utils";
import i18n from "../../i18n";
import ThingMapDetail from "./ThingMapDetail";
import { useStyles } from "./style";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 400,
  },
}))(Tooltip);

export default function ThingsIndoorMap(props) {
  const [loading, setLoading] = useState(false);
  const imgRef = useRef();

  const classes = useStyles();
  const { t } = useTranslation("things", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [selectedLocationDetail, setSelectedLocationDetail] = useState(null);
  const [selectedLocationDetailMap, setSelectedLocationDetailMap] =
    useState(null);
  const [things, setThings] = useState([]);
  const [selectedThing, setSelectedThing] = useState(null);

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [searchForm, setSearchForm] = useState({
    thingClass: null,
    thingClassId: null,
    thingClassList: [],
    thingClassIdList: [],
    description: null,
    thingCode: null,
    vendor: null,
    vendorId: null,
    vendorList: [],
    vendorIdList: [],
    summary: null,
    accountId: accountId,
    status: null,
    createDateStart: null,
    createDateEnd: null,
    images: false,
    locationDetailId: selectedLocationDetail?.id,
  });

  const searchSubmit = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    ThingService.search(searchForm, paging, findOnSuccess, (error) => {});
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    const paging = { page: 0, size: 10000, isAsc: false, sort: "createDate" };
    ThingService.search(
      { ...searchForm, summary: summary },
      paging,
      findOnSuccess,
      (error) => {}
    );
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      thingClass: null,
      thingClassId: null,
      thingClassList: [],
      thingClassIdList: [],
      description: null,
      thingCode: null,
      vendor: null,
      vendorId: null,
      vendorList: null,
      vendorIdList: null,
      summary: null,
      accountId: accountId,
      status: null,
      createDateStart: null,
      createDateEnd: null,
      images: false,
      locationDetailId: selectedLocationDetail?.id,
    });
  };

  const findOnSuccess = (data) => {
    setThings(data);
  };

  const locationDetailSelected = (item) => {
    setSelectedLocationDetail(item);
    setSearchForm({
      ...searchForm,
      locationDetailId: item?.id,
    });
    LocationDetailService.downloadDocument(
      item.id,
      (data) => {
        setSelectedLocationDetailMap(data.content);

        const paging = {
          page: 0,
          size: 10000,
          isAsc: false,
          sort: "createDate",
        };
        ThingService.search(
          { ...searchForm, locationDetailId: item?.id },
          paging,
          findOnSuccess,
          (error) => {}
        );
      },
      downloadDocumentOnError
    );
  };

  const downloadDocumentOnError = () => {};

  const parseIndoorLocation = (indoorLocation) => {
    if (indoorLocation && indoorLocation.length > 3) {
      let parsedData = JSON.parse(indoorLocation);
      if (parsedData.x && parsedData.y) {
        return {
          ...parsedData,
          locationDetailId: props?.thing?.locationDetail.id,
        };
      }
    }
    return null;
  };

  const getLocationFromRatio = (ref, location, type) => {
    if (ref && ref.current) {
      if (type === "x") {
        var x = Math.round((ref.current.clientWidth * location.x) / 100);
        return (x > 10 ? x - 10 : x) + "px";
      } else if (type === "y") {
        var y = Math.round((ref.current.clientHeight * location.y) / 100);
        return (y > 10 ? y - 10 : y) + "px";
      }
    }
    return "0px";
  };

  const goToThingsMap = () => {
    const tmpTo = {
      pathname: `/mapThings`,
      breadCrumbKey: `/mapThings`,
    };
    history.push(tmpTo);
  };

  const goThingDetail = (value) => {
    const tmpTo = {
      pathname: `/thingDetail/${value?.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: value?.description,
    };
    history.push(tmpTo);
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleTooltipClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <ThingMapDetail thingId={selectedThing?.id} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {renderDialog()}
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item xs={3}>
            <Grid item xs={12}>
              <ThingsMapButtonGroup />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <FaSearch
            onSearch={searchSubmit}
            onClear={searchClear}
            onSummarySearch={searchSummary}
            setSearchInfo={setSearchInfo}
            faClassName="appSearchBar"
          >
            <ThingClassAutoComplete
              accountId={accountId}
              value={searchForm.thingClassList}
              filterExists={true}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  thingClassList: data,
                  thingClassIdList: data?.map((item) => {
                    return item.id;
                  }),
                });
              }}
              multiple
            />
            <FaInput
              label={t("DESCRIPTION")}
              value={searchForm.description}
              onChange={(event) => {
                setSearchForm({
                  ...searchForm,
                  description: event.target.value,
                });
              }}
            />
            <FaInput
              label={t("THING_CODE")}
              value={searchForm.thingCode}
              onChange={(event) => {
                setSearchForm({
                  ...searchForm,
                  thingCode: event.target.value,
                });
              }}
            />
            <VendorAutoComplete
              accountId={accountId}
              value={searchForm.vendorList}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  vendorList: data,
                  vendorIdList: data?.map((item) => {
                    return item.id;
                  }),
                });
              }}
              multiple
            />
            <ThingStatusSelect
              value={searchForm.status}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  status: data,
                });
              }}
            />
            <DateRangeSelector
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  createDateStart: data.startDate,
                  createDateEnd: data.endDate,
                });
              }}
            />
          </FaSearch>
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={3}>
          <Card elevation={6}>
            <CardHeader title={t("INDOORS")} />
            <CardContent style={{ minHeight: 700 }}>
              <LocationTree
                accountId={accountId}
                onChange={(data) => {
                  locationDetailSelected(data);
                }}
                style={{ height: "80vh" }}
                selectDefault={true}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={9}>
          <Card elevation={6}>
            <CardHeader title={isTNF(t("INTERACTIVE_THINGS"), t("THINGS"))} />
            <CardContent style={{ height: 700 }}>
              {selectedLocationDetailMap && (
                <>
                  <MapInteractionCSS
                    showControls={true}
                    //disablePan={true}
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      {things?.map((item, index) => {
                        let newIndoorLocation = parseIndoorLocation(
                          item.indoorLocation
                        );
                        return (
                          <div>
                            <div
                              style={{
                                position: "absolute",
                                left: getLocationFromRatio(
                                  imgRef,
                                  newIndoorLocation,
                                  "x"
                                ),
                                top: getLocationFromRatio(
                                  imgRef,
                                  newIndoorLocation,
                                  "y"
                                ),
                                backgroundColor: "transparent",
                                xopacity: "0.8",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedThing(item);
                                handleTooltipOpen();
                              }}
                            >
                              <img
                                alt="Thing"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/icons/star.png"
                                }
                                style={{ width: "20px" }}
                              ></img>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <img
                      alt=""
                      src={`data:image/png;base64, ${selectedLocationDetailMap}`}
                      style={{ height: 700 }}
                      ref={imgRef}
                    ></img>
                  </MapInteractionCSS>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
