import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { CUSTOMER_LIST } from "../../services/faApi";

export default function CustomerListAutoComplete(props) {
  const { value, accountId, onChange, required, label, multiple } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="name"
      faFilterProp="query"
      getOptionLabel={(option) => {
        return (
          (option.name ?? "") +
          " " +
          (option.surname ?? "") +
          " " +
          (option.email ?? "") +
          " " +
          (option.phone ?? "")
        );
      }}
      requestUrl={CUSTOMER_LIST.findCustomerList + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ? label : t("Müşteri Listesi")}
      required={required}
      multiple={multiple}
    />
  );
}
