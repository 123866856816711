import { Avatar } from "@mui/material";
import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { ThingDesc } from "services/utils";
import i18n from "../../i18n";
import { BENEFICIARY_PORTAL } from "../../services/faApi";

export default function CustomerThingAutoComplete(props) {
  const { value, accountId, onChange, required, label } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="description"
      faFilterProp="description"
      getOptionLabel={(option) => ThingDesc(option)}
      requestUrl={
        BENEFICIARY_PORTAL.searchCustomerThings + "?accountId=" + accountId
      }
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={label ?? t("THING")}
      required={required}
      renderOption={(props, option, { inputValue }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.thumbnail != null && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  alt="thing"
                  src={`data:image/png;base64, ${option.thumbnail}`}
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    borderRadius: "15%",
                  }}
                />{" "}
                {ThingDesc(option)}
              </div>
            )}
            {option.thumbnail == null && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  variant="square"
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                >
                  {" "}
                  {option.identifier.charAt(0)}
                </Avatar>
              </div>
            )}
            <span
              style={{
                fontSize: "14px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              title={ThingDesc(option)}
            >
              {ThingDesc(option)}
            </span>
            {/* {ThingDesc(option)} */}
          </li>
        );
      }}
    />
  );
}
