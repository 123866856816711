import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    fallbackUrlBox: {
      borderColor: "rgb(204, 204, 204)", 
      borderTopLeftRadius: "7px", 
      borderTopRightRadius: "7px", 
      borderRadius: "7px", 
      border: "1px solid #ccc", 
      width: "300px", 
      height: "200px", 
      wordBreak: "break-all"
    }
}));
