import { Box, Grid, LinearProgress, Paper } from "@mui/material";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import { selectThemePrefer, setThemePrefer } from "react-base-fa/dist/AppSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { FingerPrintService } from "services/FingerPrintService";
import { GpsLocationService } from "services/location/GpsLocationService";
import { RECAPTCHA_CODE } from "services/utils/Constants";
import i18n from "../../i18n";
import { ReportService } from "../../services/report/reportService";
import LandingTemplate1 from "./landing/LandingTemplate1";
import LandingTemplate10 from "./landing/LandingTemplate10";
import LandingTemplate2 from "./landing/LandingTemplate2";
import LandingTemplate3 from "./landing/LandingTemplate3";
import LandingTemplate4 from "./landing/LandingTemplate4";
import LandingTemplate5 from "./landing/LandingTemplate5";
import LandingTemplate6 from "./landing/LandingTemplate6";
import LandingTemplate7 from "./landing/LandingTemplate7";
import LandingTemplate9 from "./landing/LandingTemplate9";
import LandingTemplateCustom from "./landing/LandingTemplateCustom";

export default function Report(props) {
  const { loginPath, appLogo, miniAppLogo } = props;
  const { tid } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  const [loading, setLoading] = useState(true);
  const [publicThing, setPublicThing] = useState(null);

  let formRef = createRef();
  let recaptchaRef = useRef();
  const lang = getCurrentLang();

  const dispatch = useDispatch();

  useEffect(() => {
    GpsLocationService.getLocation(
      () => {},
      () => {}
    );
    dispatch(setThemePrefer("light"));
    FingerPrintService.getVisitorId(visitorIdOnSuccess, visitorIdOnSuccess);
  }, []);

  const visitorIdOnSuccess = (data) => {
    setLoading(true);
    ReportService.findByTid(tid, findByTidOnSuccess, findByTidOnError);
  };

  const findByTidOnSuccess = (data) => {
    setPublicThing(data);
    setLoading(false);

    if (data.publicReportPageTemplate) {
    }
  };

  const findByTidOnError = () => {
    setLoading(false);
  };

  const theme = useSelector(selectThemePrefer);

  const onLinkClick = (link) => {
    ReportService.saveAccessLog(
      {
        account: { id: publicThing.accountId },
        thing: { id: publicThing.id },
        thingLink: { id: link.id },
        logType: "THING_LINK",
      },
      () => {},
      () => {}
    );
  };

  const renderDetails = () => {
    if (
      publicThing &&
      publicThing.publicReportPageTemplate === "TEMPLATE_CUSTOM"
    ) {
      return (
        <Box
          display="flex"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
          }}
        >
          <LandingTemplateCustom
            publicThing={publicThing}
            onLinkClick={onLinkClick}
          />
        </Box>
      );
    } else if (
      publicThing &&
      publicThing.publicReportPageTemplate === "TEMPLATE_7"
    ) {
      return (
        <LandingTemplate7
          publicThing={publicThing}
          onLinkClick={onLinkClick}
          appLogo={appLogo}
          miniAppLogo={miniAppLogo}
        />
      );
    } else if (
      publicThing &&
      publicThing.publicReportPageTemplate === "TEMPLATE_8"
    ) {
      return (
        <LandingTemplate9
          publicThing={publicThing}
          onLinkClick={onLinkClick}
          appLogo={appLogo}
          miniAppLogo={miniAppLogo}
        />
      );
    } else if (
      publicThing &&
      publicThing.publicReportPageTemplate === "TEMPLATE_9"
    ) {
      return (
        <LandingTemplate9
          publicThing={publicThing}
          onLinkClick={onLinkClick}
          appLogo={appLogo}
          miniAppLogo={miniAppLogo}
        />
      );
    } else if (
      publicThing &&
      publicThing.publicReportPageTemplate === "TEMPLATE_10"
    ) {
      return (
        <LandingTemplate10
          publicThing={publicThing}
          onLinkClick={onLinkClick}
          appLogo={appLogo}
          miniAppLogo={miniAppLogo}
        />
      );
    } else {
      return (
        <Box
          display="flex"
          alignItems="center"
          //className={classes.image}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            component={Paper}
            elevation={6}
            style={{
              height: "100%",
              width: "100vw",
              //margin: "10px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            {loading ? (
              <Grid
                item
                container
                spacing={0}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                  <img
                    src={appLogo}
                    width="250"
                    height="92"
                    alt="logo"
                    className={classes.appLogo}
                  />
                </Grid>
                <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                  <LinearProgress />
                </Grid>
              </Grid>
            ) : null}

            {!loading && !publicThing ? (
              <Grid
                item
                container
                spacing={0}
                lg={8}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                  <img
                    src={appLogo}
                    width="250"
                    height="92"
                    alt="logo"
                    className={classes.appLogo}
                  />
                </Grid>
                <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                  :( Üzgünüz, aradığınız etkileşim noktası bulunamadı.
                </Grid>
              </Grid>
            ) : null}

            {!loading && publicThing && publicThing.status !== "ACTIVE" ? (
              <Grid
                item
                container
                spacing={0}
                lg={8}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                  <img
                    src={appLogo}
                    width="250"
                    height="92"
                    alt="logo"
                    className={classes.appLogo}
                  />
                </Grid>
                <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                  :( Üzgünüz, aradığınız etkileşim noktası aktif değil.
                </Grid>
              </Grid>
            ) : null}

            <LinearProgress />
            {publicThing && publicThing.status === "ACTIVE" && (
              <Grid
                container
                spacing={0}
                justifyContent="center"
                style={{ marginBottom: 5 }}
              >
                <Grid
                  item
                  container
                  spacing={0}
                  lg={8}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                  >
                    {publicThing && publicThing.logo && (
                      <Grid
                        item
                        lg={10}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={"data:image/jpeg;base64," + publicThing.logo}
                          alt="Logo"
                          style={{ maxHeight: "60px", maxWidth: "200px" }}
                        />
                      </Grid>
                    )}
                    {publicThing && publicThing.logo == null && (
                      <Grid
                        item
                        lg={10}
                        xs={12}
                        style={{ textAlign: "center" }}
                      >
                        <img
                          src={appLogo}
                          width="250"
                          height="92"
                          alt="logo"
                          className={classes.appLogo}
                        />
                      </Grid>
                    )}
                    <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        hl={lang}
                        theme={theme}
                        badge="bottomright"
                        sitekey={RECAPTCHA_CODE}
                      />
                    </Grid>

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_1" && (
                      <LandingTemplate1
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                      />
                    )}

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_2" && (
                      <LandingTemplate2
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                      />
                    )}

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_3" && (
                      <LandingTemplate3
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                      />
                    )}

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_4" && (
                      <LandingTemplate4
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                      />
                    )}

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_5" && (
                      <LandingTemplate5
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                      />
                    )}

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_6" && (
                      <LandingTemplate6
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                      />
                    )}

                    {publicThing?.publicReportPageTemplate === "TEMPLATE_7" && (
                      <LandingTemplate7
                        publicThing={publicThing}
                        onLinkClick={onLinkClick}
                        appLogo={appLogo}
                        miniAppLogo={miniAppLogo}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      );
    }
  };

  return renderDetails();
}

Report.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
  miniAppLogo: PropTypes.any,
};
