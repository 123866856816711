import { Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import i18n from "i18n";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BeneficiaryPortalService } from "services/BeneficiaryPortalService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function BeneficiaryPortalCampaignDetail() {
  const accountId = localStorage.beneficiaryAccountId;
  const { id, accId } = useParams();

  const { t } = useTranslation("beneficiaryPortal", { i18n });
  const [campaignCustomer, setCampaignCustomer] = useState({});
  const labelClasses = labelStyles();

  useEffect(() => {
    BeneficiaryPortalService.getCampaignCustomer(
      id,
      accountId ?? accId,
      setCampaignCustomer,
      (error) => {}
    );
  }, []);

  return (
    <Card>
      <CardHeader
        title={t(
          "Kampanya Katılım Bilgileri - " + campaignCustomer?.campaign?.name
        )}
        data-fa-section="CUSTOMER_INFO"
      />
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12} className={labelClasses.LabelRow}>
              {campaignCustomer &&
                campaignCustomer.campaign &&
                campaignCustomer.campaign.thumbnail && (
                  <img
                    src={
                      "data:image/jpeg;base64," +
                      campaignCustomer.campaign.thumbnail
                    }
                    alt="CampaignImage"
                    style={{ maxHeight: 200 }}
                  />
                )}
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Kampanya Başlangıç Tarihi")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {getDateTimeWFormat(
                  campaignCustomer?.campaign?.registerStartDate
                )}
              </Typography>
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Kampanya Bitiş Tarihi")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {getDateTimeWFormat(
                  campaignCustomer?.campaign?.registerEndDate
                )}
              </Typography>
            </Grid>

            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Katılım tarihi")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {getDateTimeWFormat(campaignCustomer.registerDate)}
              </Typography>
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Çekiliş Hakkı")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {campaignCustomer.draw}
              </Typography>
            </Grid>
            <Grid item xs={6} className={labelClasses.LabelRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Durum")}
              </Typography>
              <Typography className={labelClasses.LabelValue}>
                {campaignCustomer.status &&
                  t("CAMPAIGN_STATUS_" + campaignCustomer.status)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
