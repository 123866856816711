import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CampaignTeamEdit from "./CampaignTeamEdit";
import { useStyles } from "./style";

export default function CampaignTeam(props) {
  const { campaign } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  const faDialog = useDialog();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [team, setTeam] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [campaign]);

  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "80%",
      inner: "fullname",
      valueFormatter: (value) => {
        return value.fullname;
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            disabled={campaign.status !== "DRAFT"}
            onClick={() => deleteUser(row)}
            size="small"
            color="primary"
            aria-label={t("SIL")}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];

  const refresh = () => {
    setLoading(true);
    CampaignService.findUsers(id, findTeamOnSuccess, errorCallback);
  };
  const findTeamOnSuccess = (data) => {
    setTeam(data);
    setLoading(false);
  };

  const errorCallback = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const deleteUser = (user) => {
    faDialog({
      description: t(t("Soruyu silmek istediğinize emin misiniz?")),
      type: "confirm",
    })
      .then((confirmation) => {
        CampaignService.deleteUser(
          user,
          (data) => {
            refresh();
          },
          errorCallback
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const openEditDialog = () => {
    dialogRef.current.openDialog();
  };
  const editDialogRender = () => {
    return (
      <CampaignTeamEdit
        campaign={campaign}
        ref={dialogRef}
        onSave={refresh}
      ></CampaignTeamEdit>
    );
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      {editDialogRender()}
      <Card>
        <CardHeader
          title={t("CAMPAIGN_TEAM")}
          data-fa-section="CAMPAIGN_TEAM"
          action={
            <IconButton
              disabled={campaign.status !== "DRAFT"}
              aria-label="settings"
              onClick={() => openEditDialog()}
              size="large">
              <AddIcon />
            </IconButton>
          }
        />
        <ThingsDataTable
          data={team}
          columns={columns}
          ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          headerText={t("TEAM")}
          showPaging={false}
          //faOnDoubleClick={onDoubleClick}
        />
      </Card>
    </Suspense>
  </>;
}
