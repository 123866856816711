import { SvgIcon } from "@mui/material";
import { default as React } from "react";

export default function RegisterIcon(props) {
  return (
    <SvgIcon viewBox="0 0 100 100" {...props}>
      <path
        d="M79.72,73.776c0-11.472-9.334-20.804-20.804-20.804H41.084c-11.47,0-20.804,9.332-20.804,20.804v5.944h59.44V73.776z
		 M26.224,73.776c0-8.193,6.667-14.86,14.86-14.86h17.832c8.193,0,14.86,6.667,14.86,14.86H26.224z"
      />
      <path
        d="M64.86,35.14c0-8.193-6.667-14.86-14.86-14.86c-8.193,0-14.86,6.667-14.86,14.86S41.807,50,50,50
		C58.193,50,64.86,43.333,64.86,35.14z M41.084,35.14c0-4.917,3.999-8.916,8.916-8.916c4.917,0,8.916,3.999,8.916,8.916
		S54.917,44.056,50,44.056C45.083,44.056,41.084,40.057,41.084,35.14z"
      />
    </SvgIcon>
  );
}
