import { Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Delete,
  Remove,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import HomepageItemTypeSelect from "components/select/HomepageItemTypeSelect";
import RoleSelect from "components/select/RoleSelect";
import AccountUISettingsHelp from "features/help/AccountUISettingsHelp";
import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { AccountUISettingsService } from "services/AccountUISettingsService";
import { UserService } from "services/UserService";
import { DEFAULT_UI_SETTINGS } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountUISettings(props) {
  const { account, onSave } = props;
  const { t } = useTranslation(["accountUISettings", "components"], {
    i18n,
  });
  const labelClasses = labelStyles();
  const accountId = localStorage.accountId;
  const classes = useStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [uiSettings, setUISettings] = useState([]);
  const [editUISettings, setEditUISettings] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [newItem, setNewItem] = useState();
  const [defaultUserSettings, setDefaultUserSettings] = useState([]);

  const refresh = () => {
    AccountUISettingsService.findByAccountId(accountId, (data) => {
      setUISettings(data);
      setEditUISettings(data);
    });
  };

  useEffect(() => {
    if (account) {
      refresh();
    }
  }, [account]);

  useEffect(() => {
    UserService.getUiSettings(
      (data) => {
        if (data) {
          setDefaultUserSettings(data);
        } else {
          setDefaultUserSettings(DEFAULT_UI_SETTINGS);
        }
      },
      (error) => {
        setDefaultUserSettings(DEFAULT_UI_SETTINGS);
      }
    );
  }, []);

  const save = () => {
    AccountUISettingsService.saveAll(
      editUISettings,
      (data) => {
        refresh();
        setSelectedRole(null);
      },
      (error) => {}
    );
  };

  const handleChange = (event) => {};

  const deleteItem = (item, role, index) => {
    let tempList = [...editUISettings];
    tempList.map((element) => {
      if (element.role === role) {
        var temp = JSON.parse(element.uiSettings);
        var homepageItems = temp.homepageItems;
        homepageItems.splice(index, 1);
        temp.homepageItems = homepageItems;
        element.uiSettings = JSON.stringify(temp);
      }
      setEditUISettings(tempList);
    });
  };
  const zoomInItem = (item, role, index) => {
    let tempList = [...editUISettings];
    tempList.map((element) => {
      if (element.role === role) {
        var temp = JSON.parse(element.uiSettings);
        var homepageItems = temp.homepageItems;
        let x = homepageItems[index];
        x = { ...x, size: 12 };
        homepageItems[index] = x;

        temp.homepageItems = homepageItems;
        element.uiSettings = JSON.stringify(temp);
      }
      setEditUISettings(tempList);
    });
  };
  const zoomOutItem = (item, role, index) => {
    let tempList = [...editUISettings];
    tempList.map((element) => {
      if (element.role === role) {
        var temp = JSON.parse(element.uiSettings);
        var homepageItems = temp.homepageItems;
        let x = homepageItems[index];
        x = { ...x, size: 6 };
        homepageItems[index] = x;

        temp.homepageItems = homepageItems;
        element.uiSettings = JSON.stringify(temp);
      }
      setEditUISettings(tempList);
    });
  };

  const moveUp = (item, role, index) => {
    if (index === 0) return;

    let tempList = [...editUISettings];
    tempList.map((element) => {
      if (element.role === role) {
        var temp = JSON.parse(element.uiSettings);
        var homepageItems = temp.homepageItems;
        let x1 = homepageItems[index];
        let x2 = homepageItems[index - 1];

        homepageItems[index] = x2;
        homepageItems[index - 1] = x1;
        temp.homepageItems = homepageItems;
        element.uiSettings = JSON.stringify(temp);
      }
      setEditUISettings(tempList);
    });
  };

  const moveDown = (item, role, index) => {
    let tempList = [...editUISettings];
    tempList.map((element) => {
      if (element.role === role) {
        var temp = JSON.parse(element.uiSettings);
        var homepageItems = temp.homepageItems;
        if (index === homepageItems.length - 1) return;

        let x1 = homepageItems[index];
        let x2 = homepageItems[index + 1];

        homepageItems[index] = x2;
        homepageItems[index + 1] = x1;
        temp.homepageItems = homepageItems;
        element.uiSettings = JSON.stringify(temp);
      }
      setEditUISettings(tempList);
    });
  };

  const getRoleSettings = (uiSettings, role) => {
    return (
      <Grid item container spacing={1}>
        {JSON.parse(uiSettings).homepageItems.map((item, index) => (
          <Grid item xs={item.size}>
            <Card>
              <CardHeader
                title={t("HOMEPAGE_ITEM." + item.item, { ns: "components" })}
                action={
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        deleteItem(item, role, index);
                      }}
                      startIcon={<Delete />}
                      style={{ marginRight: 5 }}
                    ></Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        moveDown(item, role, index);
                      }}
                      startIcon={<ArrowDownward />}
                      style={{ marginRight: 5 }}
                    ></Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => {
                        moveUp(item, role, index);
                      }}
                      startIcon={<ArrowUpward />}
                      style={{ marginRight: 5 }}
                    ></Button>
                    {item.size > 6 && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          zoomOutItem(item, role, index);
                        }}
                        startIcon={<Remove />}
                        style={{ marginRight: 5 }}
                      ></Button>
                    )}
                    {item.size < 12 && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          zoomInItem(item, role, index);
                        }}
                        startIcon={<Add />}
                        style={{ marginRight: 5 }}
                      ></Button>
                    )}
                  </>
                }
              />
              <CardContent></CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  const getSettingsByRole = () => {
    if (selectedRole === null || selectedRole === undefined) return null;
    else if (!editUISettings.map((item) => item.role).includes(selectedRole)) {
      let settings = JSON.stringify(
        defaultUserSettings ? defaultUserSettings : DEFAULT_UI_SETTINGS
      );
      let tempSettings = [...editUISettings];
      tempSettings.push({
        account: { id: accountId },
        role: selectedRole,
        uiSettings: settings,
      });
      setEditUISettings(tempSettings);
      return getRoleSettings(settings, selectedRole);
    } else {
      return (
        <>
          {editUISettings.map((item) => (
            <>
              {item &&
                item.role === selectedRole &&
                getRoleSettings(item.uiSettings, item.role)}
            </>
          ))}
        </>
      );
    }
  };

  const addNewItem = (data) => {
    let tempList = [...editUISettings];
    tempList.map((element) => {
      if (element.role === selectedRole) {
        var temp = JSON.parse(element.uiSettings);
        var homepageItems = temp.homepageItems;

        homepageItems.push({ item: data, size: 6 });

        temp.homepageItems = homepageItems;
        element.uiSettings = JSON.stringify(temp);
      }
      setEditUISettings(tempList);
    });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={t("ACCOUNT_UI_SETTINGS")}
        faOpen={isEditOpen}
        faOnSubmit={() => {
          save();
          setIsEditOpen(false);
        }}
        showSaveButton
        faHandleClose={(data) => {
          setIsEditOpen(false);
          setSelectedRole(null);
          refresh();
        }}
        faMaxWidth="sm"
      >
        <Grid container spacing={1} data-fa-section="ACCOUNT_UI_SETTINGS_EDIT">
          <Grid item xs={12}>
            <RoleSelect
              required
              value={selectedRole}
              onChange={(data) => {
                setSelectedRole(data);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {getSettingsByRole()}
          </Grid>
          {selectedRole && (
            <Grid item xs={12}>
              <HomepageItemTypeSelect
                label="Eleman ekle"
                value={newItem}
                onChange={(data) => {
                  addNewItem(data);
                }}
              />
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  return <>
    {renderDialog()}
    <Card style={{ marginTop: "16px" }}>
      <CardHeader
        action={
          <>
            <AccountUISettingsHelp value="accountDetails.uiSettings" />
            <IconButton
              aria-label="settings"
              onClick={setIsEditOpen}
              data-fa-button="ACCOUNT_UI_SETTINGS"
              size="large">
              <EditIcon />
            </IconButton>
          </>
        }
        title={t("ACCOUNT_UI_SETTINGS")}
      />
      <CardContent>
        <Grid
          item
          container
          spacing={1}
          data-fa-section="ACCOUNT_UI_SETTINGS"
        >
          <Grid item xs={12}>
            <Grid
              item
              container
              spacing={1}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              {uiSettings && uiSettings.length > 0 && (
                <>
                  {uiSettings.map((item) => (
                    <Grid item xs={12} className={labelClasses.LabelRow}>
                      <Typography className={labelClasses.LabelHeader}>
                        {t("components:ROLE_" + item.role)}
                      </Typography>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
