import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add, CheckCircleOutlined, Close } from "@mui/icons-material";
import {
  Chip,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { green } from "@mui/material/colors";
import ActionAllTypeSelect from "components/select/ActionAllTypeSelect";
import BooleanSelect from "components/select/BooleanSelect";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MessageTemplateService } from "services/MessageTemplateService";
import { MessageTemplateTargetService } from "services/MessageTemplateTargetService";
import { qsParse } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import SelectTargetDialog from "./SelectTargetDialog";
import { useStyles } from "./style";
export default function MessageTemplateTargets() {
  const { t } = useTranslation(["messageTemplateTarget", "components"], {
    i18n,
  });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const selectTargetDialog = useRef();
  const accountId = localStorage.accountId;
  const classes = useStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState();
  const [rows, setRows] = useState([]);

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      paging: {
        page: 0,
        size: 10,
        asc: true,
        sort: "id",
      },
      enabled: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());
  const [pagination, setPagination] = useState();
  const search = (param) => {
    MessageTemplateService.getListWithTargets(
      param ? param : searchForm,
      onSuccess,
      () => {}
    );
  };

  useEffect(() => {
    search();
  }, []);

  const onSuccess = (data) => {
    setRows(data.list);
    setPagination({
      page: data.currentPage,
      totalItems: data.totalItems,
      totalPages: data.totalPages,
    });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchSubmit = () => {
    search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const handleChangePage = (event, newPage) => {
    setSearchForm({
      ...searchForm,
      paging: { ...searchForm.paging, page: newPage },
    });
    search({
      ...searchForm,
      paging: { ...searchForm.paging, page: newPage },
    });
  };
  const handleRowsPerPageChange = (event) => {
    setSearchForm({
      ...searchForm,
      paging: {
        ...searchForm.paging,
        page: 0,
        size: parseInt(event.target.value, 10),
      },
    });

    search({
      ...searchForm,
      paging: {
        ...searchForm.paging,
        page: 0,
        size: parseInt(event.target.value, 10),
      },
    });
  };

  const deleteTarget = (target) => {
    faDialog({
      description: t("Kaldırmak istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        MessageTemplateTargetService.delete(
          target,
          () => {
            search();
          },
          (error) => {
            showAlert("Hata", "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const save = (dto) => {
    MessageTemplateTargetService.save(
      dto,
      () => {
        search();
      },
      () => {
        showAlert("Hata", "error");
      }
    );
  };

  const addTarget = (messageTemplateId, field, value) => {
    let dto = {
      account: { id: accountId },
      messageTemplate: { id: messageTemplateId },
      [field]: value,
    };

    if (field === "role" || field === "user" || field === "userGroup")
      save(dto);
    else
      faDialog({
        description: t("Eklemek istediğinize emin misiniz?"),
        type: "confirm",
      })
        .then((confirmation) => {
          save(dto);
        })
        .catch((err) => console.log("error:", err));
  };

  const getIcon = (rowId, targetList, field) => {
    let result = null;
    let trueIndex = null;
    targetList.map((item, index) => {
      if (item[field]) {
        result = true;
        trueIndex = index;
      }
    });
    return result ? (
      <IconButton
        onClick={() => deleteTarget(targetList[trueIndex])}
        size="large"
      >
        <CheckCircleOutlined style={{ color: green[500] }} />
      </IconButton>
    ) : (
      <IconButton
        onClick={() => {
          addTarget(rowId, field, true);
        }}
        size="large"
      >
        <Close fontSize="small" />
      </IconButton>
    );
  };

  const toggleEnabled = (templateId, accountId, currentEnabled, onSuccess) => {
    const newEnabled = !currentEnabled;
    MessageTemplateService.toggleEnabled(
      templateId,
      accountId,
      newEnabled,
      () => {
        onSuccess(newEnabled);
      },
      () => {
        showAlert("Hata", "error");
      }
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <SelectTargetDialog
          ref={selectTargetDialog}
          onSave={addTarget}
          selectedMessageTemplateId={selectedMessageTemplate?.id}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ padding: 15 }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("MESSAGE_TEMPLATE")}</TableCell>
                    <TableCell>{t("ENABLED")}</TableCell>
                    <TableCell align="right">{t("ROLE")}</TableCell>
                    <TableCell align="right">{t("USER")}</TableCell>
                    <TableCell align="right">{t("USER_GROUP")}</TableCell>
                    <TableCell align="right">{t("THING_MNTC")}</TableCell>
                    <TableCell align="right">{t("THING_OWNER")}</TableCell>
                    <TableCell align="right">{t("THING_USER")}</TableCell>
                    <TableCell align="right">{t("RELATED_USERS")}</TableCell>
                    <TableCell align="right">{t("NOTIFIER")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id} hover>
                      <TableCell component="th" scope="row">
                        <b>
                          {t("ACTION_TYPE." + row.actionType, {
                            ns: "components",
                          })}
                        </b>
                        <br />
                        <text style={{ fontSize: "12px" }}>
                          {row.description}
                        </text>
                      </TableCell>
                      <TableCell align="right">
                        <Switch
                          checked={row.enabled}
                          onChange={() => {
                            toggleEnabled(
                              row.id,
                              accountId,
                              row.enabled,
                              (newEnabled) => {
                                // Güncellenmiş durumu `rows`'a uygula
                                const updatedRows = rows.map((r) =>
                                  r.id === row.id
                                    ? { ...r, enabled: newEnabled }
                                    : r
                                );
                                setRows(updatedRows);
                              }
                            );
                          }}
                          color="primary"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {row?.targetList.map((target) =>
                          target.role ? (
                            <>
                              <Chip
                                label={t("ROLE_" + target.role, {
                                  ns: "components",
                                })}
                                onDelete={() => {
                                  deleteTarget(target);
                                }}
                                className={classes.chip}
                              />
                              <br />
                            </>
                          ) : null
                        )}

                        <IconButton
                          onClick={() => {
                            setSelectedMessageTemplate(row);
                            selectTargetDialog.current.openDialog("role");
                          }}
                          size="small"
                          aria-label={t("ADD")}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {row?.targetList.map((target, index) =>
                          target?.user ? (
                            <>
                              <Chip
                                icon={<FontAwesomeIcon icon={faUser} />}
                                label={target?.user?.fullname}
                                onDelete={() => {
                                  deleteTarget(target);
                                }}
                                className={classes.chip}
                              />
                              <br />
                            </>
                          ) : null
                        )}

                        <IconButton
                          onClick={() => {
                            setSelectedMessageTemplate(row);
                            selectTargetDialog.current.openDialog("user");
                          }}
                          size="small"
                          aria-label={t("ADD")}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {row?.targetList.map((target) =>
                          target?.userGroup ? (
                            <>
                              <Chip
                                icon={<FontAwesomeIcon icon={faUsers} />}
                                label={target?.userGroup?.name}
                                onDelete={() => {
                                  deleteTarget(target);
                                }}
                                className={classes.chip}
                              />
                              <br />
                            </>
                          ) : null
                        )}

                        <IconButton
                          onClick={() => {
                            setSelectedMessageTemplate(row);
                            selectTargetDialog.current.openDialog("userGroup");
                          }}
                          size="small"
                          aria-label={t("ADD")}
                        >
                          <Add fontSize="small" />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        {getIcon(row.id, row?.targetList, "thingMntc")}
                      </TableCell>
                      <TableCell align="right">
                        {getIcon(row.id, row?.targetList, "thingOwner")}
                      </TableCell>
                      <TableCell align="right">
                        {getIcon(row.id, row?.targetList, "thingUser")}
                      </TableCell>
                      <TableCell align="right">
                        {getIcon(row.id, row?.targetList, "relatedUsers")}
                      </TableCell>
                      <TableCell align="right">
                        {getIcon(row.id, row?.targetList, "notifier")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={11}>
            {pagination && pagination?.totalItems && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Sayfa başına kayıt"
                component="div"
                count={pagination?.totalItems}
                rowsPerPage={rows?.length}
                page={pagination?.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            )}
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <ActionAllTypeSelect
                label="İşlem Tipi"
                value={searchForm?.actionType}
                onChange={(data) =>
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "actionType",
                    data
                  )
                }
              />
              <BooleanSelect
                label={t("ENABLED")}
                value={searchForm.enabled}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "enabled",
                    data
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
