import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  w100: {
    width: "100%",
    //minHeight: 40,
    //margin: "8px",
    //marginLeft: 8,
  },
  padding10: {
    padding: "14.5px 14px",
  },
}));
