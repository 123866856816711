import { Remove } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserGroupService } from "services/UserGroupService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { USER_GROUP } from "../../services/faApi";

export default function UserGroup() {
  const tableRef = useRef();
  const { t } = useTranslation("userGroup", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isRegisterFormOpen, setIsRegisterFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "30%",
      valueFormatter: (val, row) => (
        <UserDetail value={row} type="USER_GROUP" />
      ),
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "id",
      label: t("USERS"),
      align: "center",
      width: "50%",
      valueFormatter: (value, row) => {
        return row.userGroupMembers.map((item, index) => {
          return <li>{item.user.fullname}</li>;
        });
      },
    },
  ];

  const newUserGroup = () => {
    return {
      id: "",
      name: "",
      description: "",
      userGroupMembers: [],
    };
  };
  const [userGroup, setUserGroup] = useState(newUserGroup());

  const handleChange = (prop) => (event) => {
    setUserGroup({ ...userGroup, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!userGroup.id ? t("SAVED") : t("EDITED"), "success");
    setIsRegisterFormOpen(false);
    //setUserGroup(newUserGroup());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveUserGroup = () => {
    setLoading(true);
    UserGroupService.save(
      {
        ...userGroup,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setUserGroup(row);
    setIsRegisterFormOpen(true);
  };

  const addUser = (newValue) => {
    if (newValue == null) return;
    if (
      userGroup.userGroupMembers.findIndex((i) => i.user.id === newValue.id) ===
      -1
    ) {
      setUserGroup({
        ...userGroup,
        userGroupMembers: [...userGroup.userGroupMembers, { user: newValue }],
      });
    }

    setSelectedUser({});
  };

  const handleChipDelete = (index) => {
    var temp = [...userGroup.userGroupMembers];
    temp.splice(index, 1);
    setUserGroup({ ...userGroup, userGroupMembers: temp });
  };

  const newUserGroupRegisterRender = () => {
    return (
      <>
        <FaDialog
          title={t("EDIT_USER_GROUP")}
          faOpen={isRegisterFormOpen}
          faOnSubmit={saveUserGroup}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsRegisterFormOpen(data);
            setUserGroup(newUserGroup());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                inputProps={{ maxLength: 100 }}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={userGroup.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                inputProps={{ maxLength: 255 }}
                onChange={handleChange("description")}
                value={userGroup.description || ""}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Grup Kullanıcıları</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userGroup?.userGroupMembers?.map((data, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <UserDetail value={data.user} type="USER" />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              handleChipDelete(index);
                            }}
                            size="large"
                          >
                            <Remove />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                    {userGroup?.userGroupMembers?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={2}>
                          Kayıt bulunmamaktadır.
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </TableContainer>
              <UserAutoComplete
                accountId={accountId}
                value={selectedUser}
                onChange={(data) => {
                  addUser(data);
                }}
                label="Kullanıcı eklemek için seçiniz"
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newUserGroupRegisterRender()}
        <ThingsDataTable
          searchUrl={USER_GROUP.search + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{ page: 0, size: 10, isAsc: true, sort: "name" }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsRegisterFormOpen(true);
                setUserGroup(newUserGroup());
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
