import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { THINGS_ENVIRONMENT } from "environments/Environments";
import LanguageSelectButton from "features/login/LanguageSelectButton";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaUnAuthPages } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { clearArr } from "react-base-fa/dist/fa/faBreadCrumb/breadCrumbSlice";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  getLoading,
  loginThunk,
  setUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { getThingsApp } from "services/utils";
import { APP, RECAPTCHA_CODE } from "services/utils/Constants";
import i18n from "../../i18n";
import { THINGS_USER } from "../../services/faApi";
import { codeStyles } from "./style";

export default function SmsAndEmailConfirmation(props) {
  const { backGroundImage, loginPath, appLogo, loginUrl } = props;
  const faDialog = useDialog();
  const dispatch = useDispatch();
  const sessionLoading = useSelector(getLoading);
  const classes = useStyles();
  const { t } = useTranslation("smsAndEmailConfirmation", { i18n });
  const history = useHistory();
  const codeClasses = codeStyles();
  const [isActivated, setIsActivated] = useState({
    sms: false,
    email: false,
  });

  const [emailRetryCount, setEmailRetryCount] = useState(1);
  const [smsRetryCount, setSmsRetryCount] = useState(1);

  const [verificationMethod, setVerificationMethod] = useState(null);
  const [countDownTime, setCountDownTime] = useState();
  const [countDownTime2, setCountDownTime2] = useState();
  const [isCountDownOn, setIsCountDownOn] = useState(false);
  const [isCountDownOn2, setIsCountDownOn2] = useState(false);
  const [userInvitation, setUserInvitation] = useState();
  const [confirmationTokens, setConfirmationTokens] = useState({
    token: "",
    smsHash: "",
    emailHash: "",
  });
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  let timer = 0;
  let timer2 = 0;
  let seconds = 180;
  let seconds2 = 180;

  let recaptchaRef = useRef();
  const lang = getCurrentLang();
  const theme = useSelector(selectThemePrefer);

  const logo =
    getThingsApp() === APP.T2C
      ? THINGS_ENVIRONMENT.appLogoT2CDark
      : THINGS_ENVIRONMENT.appLogo;

  console.log({ logo });

  useEffect(() => {
    if (!location.state) {
      setTimeout(() => {
        history.push("login");
      }, 2000);
    }
  }, []);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  async function getMailConfirmationToken() {
    let req = requests.getSimpleGetRequest(
      THINGS_USER.verificationTokenViaEmail,
      {
        email: location.state.formData.email,
      }
    );
    console.log("myReq->", req);
    FaAxios(req)
      .then((response) => {
        console.log("response->", response);
        setConfirmationTokens({
          ...confirmationTokens,
          ["emailHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }
  async function getSMSConfirmationToken() {
    let myPhoneNumber = location.state.formData.phone;
    const regEx = new RegExp(/[\s()*]/g);
    myPhoneNumber = myPhoneNumber.replaceAll(regEx, "");
    //console.log("myphone number",myPhoneNumber)
    let req = requests.getSimpleGetRequest(
      THINGS_USER.verificationTokenViaPhone,
      {
        phone: myPhoneNumber,
      }
    );
    //console.log("myReq->", req);
    FaAxios(req)
      .then((response) => {
        console.log("response->", response);
        setConfirmationTokens({
          ...confirmationTokens,
          ["smsHash"]: response.data.HASH,
        });
      })
      .catch((e) => {
        console.log("error -> ", e);
      });
  }

  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  function countDown() {
    console.log("inside countdown", seconds);
    let tempSeconds = seconds - 1;
    //console.log("inside countdown", tempSeconds);
    seconds = tempSeconds;
    setCountDownTime(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer);
      setCountDownTime(null);
      setIsCountDownOn(false);
      setSmsRetryCount((prev) => {
        return prev + 1;
      });
    }
  }
  function startTimer() {
    //console.log("inside start timer");
    if (timer == 0 && seconds > 0) {
      setIsCountDownOn(true);
      timer = setInterval(countDown, 1000);
    }
  }

  function countDown2() {
    console.log("inside countdown", seconds2);
    let tempSeconds = seconds2 - 1;
    //console.log("inside countdown", tempSeconds);
    seconds2 = tempSeconds;
    setCountDownTime2(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer2);
      setCountDownTime2(null);
      setIsCountDownOn2(false);

      setEmailRetryCount((prev) => {
        return prev + 1;
      });
    }
  }
  function startTimer2() {
    //console.log("inside start timer");
    if (timer2 == 0 && seconds2 > 0) {
      setIsCountDownOn2(true);
      timer2 = setInterval(countDown2, 1000);
    }
  }

  async function phoneConfirmation(event) {
    event.preventDefault();
    setVerificationMethod("SMS");
    startTimer();
    getSMSConfirmationToken();
    console.log("telefon onaylama");
  }
  async function emailConfirmation(event) {
    event.preventDefault();

    setVerificationMethod("EMAIL");
    startTimer2();
    getMailConfirmationToken();
  }
  async function sendSmsToken() {
    let req = requests.getSimplePostRequest(
      THINGS_USER.thingsPhoneVerify +
        "/" +
        confirmationTokens.token +
        "/" +
        encodeURI(confirmationTokens.smsHash),
      location.state.formData
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          ["sms"]: true,
        });
        showAlert(t("SMS_CONFIRMATION_SUCCSESS_MSG"), "success");
        console.log("response ->", response);

        if (seconds > 1) seconds = 1;
        if (seconds2 > 1) seconds2 = 1;

        continueAndLogin();
      })
      .catch((err) => {
        showAlert("SMS_CONFIRMATION_ERROR_MSG", "error");
        console.error(err);
      });
  }

  async function sendEmailToken() {
    let req = requests.getSimplePostRequest(
      THINGS_USER.thingsEmailVerify +
        "/" +
        confirmationTokens.token +
        "/" +
        encodeURI(confirmationTokens.emailHash),
      location.state.formData
    );
    FaAxios(req)
      .then((response) => {
        setIsActivated({
          ...isActivated,
          ["email"]: true,
        });
        showAlert(t("EMAIL_CONFIRMATION_SUCCSESS_MSG"), "success");
        console.log("response ->", response);

        if (seconds > 1) seconds = 1;
        if (seconds2 > 1) seconds2 = 1;

        continueAndLogin();
      })
      .catch((err) => {
        showAlert(t("EMAIL_CONFIRMATION_ERROR_MSG"), "error");
        console.warn(err);
      });
  }

  const handleChange = (prop) => (event) => {
    setConfirmationTokens({
      ...confirmationTokens,
      [prop]: event.target.value,
    });
  };

  const myPhoneMask = (text) => {
    const regEx = new RegExp("\\d");
    let textLen = text.length;
    let tempTex = text.substr(0, 6);
    //console.log("text len ve text ", text, textLen);

    for (let i = 6; i < textLen - 2; i++) {
      //console.log("text i and reg", text[i], regEx.test(text[i]));
      if (regEx.test(text[i])) {
        tempTex = tempTex.concat("*");
      } else {
        tempTex = tempTex.concat(text[i]);
      }
    }
    tempTex = tempTex.concat(text.substr(textLen - 2, textLen - 1));
    // console.log(tempTex);
    return tempTex;
  };
  const myEmailMask = (text) => {
    let textLen = text.length;
    let tempTex = text.substr(0, 3);
    for (let i = 3; i < textLen - 5; i++) {
      tempTex = tempTex.concat("*");
    }
    tempTex = tempTex.concat(text.substr(textLen - 5, textLen - 1));
    // console.log(tempTex);
    return tempTex;
  };

  const forwardingScreen = () => {
    return (
      <FaUnAuthPages maxWidth="md">
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: 5 }}
        >
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={logo}
              height="78"
              alt="logo"
              className={classes.appLogo}
            />

            <Grid
              container
              item
              spacing={2}
              lg={12}
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item lg={12} xs={12}>
                <Typography variant="subtitle1">
                  {t("FORWARDING_PAGE_TEXT")}
                </Typography>
              </Grid>

              <Grid item lg={3} xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  component="span"
                  color="primary"
                  disableElevation
                  onClick={() => {
                    history.push("sign-up");
                  }}
                >
                  {t("DEVAM_ET")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaUnAuthPages>
    );
  };

  const verifyActivationToken = () => {
    if (verificationMethod === "EMAIL") {
      sendEmailToken();
    } else if (verificationMethod === "SMS") {
      sendSmsToken();
    }
  };

  const verificationRender = (email, phone) => {
    const partialSize = email && phone ? 6 : 12;

    return (
      <React.Fragment>
        <Grid container item spacing={2} lg={12} xs={12}>
          {email && (
            <Grid item lg={partialSize} xs={partialSize}>
              <Typography variant="subtitle1">{email}</Typography>
            </Grid>
          )}

          {phone && (
            <Grid item lg={partialSize} xs={partialSize}>
              <Typography variant="subtitle1">{phone}</Typography>
            </Grid>
          )}

          {email && (
            <Grid item lg={partialSize} xs={partialSize}>
              <Button
                disabled={isCountDownOn || isCountDownOn2 || isActivated.email}
                variant="contained"
                fullWidth
                component="span"
                color="primary"
                disableElevation
                onClick={emailConfirmation}
              >
                {t("EPOSTA_GONDER")}
                {countDownTime2
                  ? "-" +
                    (countDownTime2.h * 3600 +
                      countDownTime2.m * 60 +
                      countDownTime2.s)
                  : ""}
              </Button>
            </Grid>
          )}

          {phone && (
            <Grid item lg={partialSize} xs={partialSize}>
              <Button
                disabled={isCountDownOn || isCountDownOn2 || isActivated.sms}
                variant="contained"
                fullWidth
                component="span"
                color="primary"
                disableElevation
                onClick={phoneConfirmation}
              >
                {t("SMS_GONDER")}
                {countDownTime
                  ? "-" +
                    (countDownTime.h * 3600 +
                      countDownTime.m * 60 +
                      countDownTime.s)
                  : ""}
              </Button>
            </Grid>
          )}
          <Grid item lg={12} xs={12}>
            <Typography variant="subtitle1">
              {t("KOD_GIRIS_ACIKLAMA")}
            </Typography>
          </Grid>

          <Grid item lg={8} xs={12} style={{ height: 70 }}>
            <AuthCode
              onChange={(val) => {
                setConfirmationTokens({
                  ...confirmationTokens,
                  token: val,
                });
              }}
              containerClassName={codeClasses.container}
              inputClassName={codeClasses.input}
              allowedCharacters="numeric"
              disabled={
                (!isCountDownOn && !isCountDownOn2) ||
                isActivated.sms ||
                isActivated.email
              }
            />

            {/* <TextField
              variant="outlined"
              label={t("AKTIVASYON_KODU")}
              onChange={handleChange("token")}
              value={confirmationTokens.token || ""}
              fullWidth
              disabled={
                (!isCountDownOn && !isCountDownOn2) ||
                isActivated.sms ||
                isActivated.email
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isActivated.sms || isActivated.email ? (
                      <CheckIcon>add_circle</CheckIcon>
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid item lg={4} xs={12} style={{ height: 70 }}>
            <Button
              disabled={
                (!isCountDownOn && !isCountDownOn2) ||
                isActivated.sms ||
                isActivated.email
              }
              variant="contained"
              fullWidth
              component="span"
              color="primary"
              style={{ height: 60 }}
              disableElevation
              onClick={verifyActivationToken}
            >
              {t("DOGRULA")}
            </Button>
          </Grid>
          {(smsRetryCount > 1 || emailRetryCount > 1) && (
            <Grid item lg={12} xs={12}>
              <Typography variant="subtitle1">
                {t("TEKRAR_GONDER_ACIKLAMA")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  const continueAndLogin = async () => {
    if (verificationMethod === "EMAIL") {
      try {
        unwrapResult(
          await dispatch(
            loginThunk(
              requests.getSimplePostRequest(loginUrl, {
                username: location.state.formData.email,
                password: location.state.formData.password,
              })
            )
          )
        );
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());
        history.push("/");
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
        console.log(err);
      }
    } else if (verificationMethod === "SMS") {
      try {
        unwrapResult(
          await dispatch(
            loginThunk(
              requests.getSimplePostRequest(loginUrl, {
                username: location.state.formData.phone,
                password: location.state.formData.password,
              })
            )
          )
        );
        dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
        dispatch(clearArr());
        history.push("/");
      } catch (err) {
        console.log("error", `Fetch failed: ${err.message}`);
        console.log(err);
      }
    }
  };

  const confirmationScreen = () => {
    let phone = location.state.formData.phone
      ? location.state.formData.phone // myPhoneMask(location.state.formData.phone)
      : "";

    let email = location.state.formData.email
      ? location.state.formData.email //myEmailMask(location.state.formData.email)
      : "";
    return (
      <FaUnAuthPages maxWidth="lg">
        <LanguageSelectButton />
        <Grid
          container
          spacing={3}
          justifyContent="center"
          style={{ marginBottom: 30 }}
        >
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={logo}
              height="78"
              alt="logo"
              className={classes.appLogo}
            />
            <Prompt when={!!userInvitation} message={t("LEAVE_PAGE_WARNNNG")} />
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              hl={lang}
              theme={theme}
              badge="bottomright"
              sitekey={RECAPTCHA_CODE}
            />

            {verificationRender(email, phone)}
          </Grid>
        </Grid>
      </FaUnAuthPages>
    );
  };

  return location.state && location.state.formData
    ? confirmationScreen()
    : forwardingScreen();
}

SmsAndEmailConfirmation.propTypes = {
  loginPath: PropTypes.string,
  backGroundImage: PropTypes.any,
  appLogo: PropTypes.any,
};
