import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import AccountPreferencesHelp from "features/help/AccountPreferencesHelp";
import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { AccountPreferencesService } from "services/AccountPreferencesService";
import { AccountService } from "services/AccountService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import AccountPreferencesEdit from "./AccountPreferencesEdit";

export default function AccountPreferencesCard(props) {
  const { accPreferences, onSave } = props;
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const labelClasses = labelStyles();
  const accountId = localStorage.accountId;

  const [accountPreferences, setAccountPreferences] = useState({});
  const [accountSubscription, setAccountSubscription] = useState(null);

  const [isAccountPreferencesEditOpen, setIsAccountPreferencesEditOpen] =
    useState(false);
  const [newValues, setNewValues] = useState(null);

  const editButtonClicked = () => {
    setIsAccountPreferencesEditOpen(true);
  };

  useEffect(() => {
    refresh();
  }, [accPreferences]);

  const refresh = () => {
    if (accPreferences) {
      setAccountPreferences({ ...accPreferences });
    }

    if (accountId) {
      AccountService.getSubscription(
        accountId,
        (data) => {
          setAccountSubscription(data);
        },
        (error) => {}
      );
    }
  };

  const saveAccountPreferences = () => {
    AccountPreferencesService.saveAccountPreferences(
      {
        ...newValues,
        account: { id: accountId },
      },
      () => {
        setIsAccountPreferencesEditOpen(false);
        refresh();
        if (onSave) onSave();
      },
      () => {}
    );
  };

  const getThingDescriptor = (str) => {
    let strArr = str.split(" ");
    strArr.pop();
    let text = "";

    if (strArr && strArr.length > 0)
      strArr.map((data, index) => {
        text += t(data);
        if (index !== strArr.length - 1) text += ", ";
        return text;
      });
    return text;
  };

  return <>
    <FaDialog
      title={"Hesap Seçenekleri Güncelle"}
      data-fa-section="ACCOUNT_PREFERENCES_EDIT"
      faOpen={isAccountPreferencesEditOpen}
      faOnSubmit={saveAccountPreferences}
      showSaveButton
      faHandleClose={(data) => {
        setIsAccountPreferencesEditOpen(false);
      }}
      faMaxWidth="sm"
    >
      <AccountPreferencesEdit
        accountPreferences={accountPreferences}
        accountSubscription={accountSubscription}
        onNewValues={setNewValues}
      />
    </FaDialog>
    <Card style={{ marginTop: "16px", overflowY: "auto" }}>
      <CardHeader
        action={
          <>
            <AccountPreferencesHelp value="accountDetails.accountPreferences" />
            <IconButton
              aria-label="settings"
              onClick={editButtonClicked}
              data-fa-button="ACCOUNT_PREFERENCES_BUTTON"
              size="large">
              <EditIcon />
            </IconButton>
          </>
        }
        data-fa-section="ACCOUNT_PREFERENCES"
        title={t("ACCOUNT_PREFERENCES")}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid
              item
              container
              spacing={1}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Yetki Modeli")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.authorizationType &&
                    t("" + accountPreferences?.authorizationType)}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Etkileşim Noktası Tipi Veri Kaynakları")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.thingClassDataSources &&
                    t("" + accountPreferences?.thingClassDataSources)}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Servis/Operasyon boş kontrol listesi gönderebilme")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.emptySolutionControlList &&
                    t(
                      "YES_NO_SELECT_" +
                        accountPreferences?.emptySolutionControlList,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Servis/Operasyon malzeme seçim yöntemi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.stockSelectType &&
                    t(
                      "STOCK_SELECT_TYPE_" +
                        accountPreferences?.stockSelectType,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("QR etiket yönlendirme adresi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.qrUrl}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Silme izinleri")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.deleteThing === "YES" &&
                    t("Etkileşim Noktası,")}
                  {accountPreferences?.deleteIssue === "YES" && t("Bildirim")}
                </Typography>
              </Grid>

              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Varsayılan Etkileşim Noktası Tipi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.defaultThingClass?.className}
                </Typography>
              </Grid>

              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Varsayılan İlişki Tipi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.relationType?.nameSource}
                </Typography>
              </Grid>

              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Biricik Etkileşim Noktası Tanımları")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.uniqueThingIdentifier &&
                    t(
                      "ALLOW_SELECT_TYPE_" +
                        accountPreferences?.uniqueThingIdentifier,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>

              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("KVKK ve İletişim İzinlerinin El İle Verilmesi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.customerPermissionUpdate &&
                    t(
                      "CUSTOMER_PERMISSION_UPDATE_" +
                        accountPreferences?.customerPermissionUpdate,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelLastRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Varlık Tanımlayıcısı")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.thingDescriptor &&
                    getThingDescriptor(accountPreferences?.thingDescriptor)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
