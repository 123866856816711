import { Grid } from "@mui/material";
import CustomerSelector from "components/selector/CustomerSelector";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomerListDetailService } from "services/CustomerListDetailService";
import i18n from "../../i18n";

const CustomerListDetailEdit = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, customerList, open, value } = props;
  const { t } = useTranslation("customerListDetail", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setCustomerListDetail(
        value == null ? newCustomerListDetail() : { ...value }
      );
    }
  }, [open]);

  const newCustomerListDetail = () => {
    let tmp = {
      id: "",
      customer: null,
      customerList: customerList,
    };
    return tmp;
  };
  const [customerListDetail, setCustomerListDetail] = useState(
    newCustomerListDetail()
  );
  const [customerListDetails, setCustomerListDetails] = useState([]);

  const addCustomers = () => {
    let tmp = customerListDetails.map((item) => {
      return {
        customer: item,
        customerList: customerList,
      };
    });
    CustomerListDetailService.saveAll(tmp, saveSuccess, saveError);
    setCustomerListDetails([]);
  };
  const saveSuccess = (data) => {
    showAlert(!customerListDetail.id ? t("SAVED") : t("EDITED"), "success");
    setCustomerListDetail(newCustomerListDetail());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    console.log("saveError", data);
    //showAlert(data.message, "error");
    showAlert("Kayıt hatası", "error");
    setLoading(false);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Müşteri"}
          faOpen={open}
          faOnSubmit={addCustomers}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {/* <CustomerAutoComplete
                accountId={accountId}
                value={customerListDetail.customer}
                onChange={(data) => {
                  setCustomerListDetail({
                    ...customerListDetail,
                    customer: data,
                  });
                }}
              /> */}
              <CustomerSelector
                required={true}
                value={customerListDetails}
                accountId={accountId}
                onChange={(data) => {
                  setCustomerListDetails(data);
                }}
                // onChange={(data) => {
                //   console.log("DATA", data);
                //   setCustomerListDetail({
                //     ...customerListDetail,
                //     customer: data,
                //   });
                // }}
                multiple={true}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default CustomerListDetailEdit;
