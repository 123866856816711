import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CurrencySelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100} style={{ minHeight: 40 }}>
      <InputLabel>{label ?? t("CURRENCY")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("CURRENCY")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"TRY"}>{t("TRY")}</MenuItem>
        <MenuItem value={"EUR"}>{t("EUR")}</MenuItem>
        <MenuItem value={"USD"}>{t("USD")}</MenuItem>
        <MenuItem value={"GBP"}>{t("GBP")}</MenuItem>
      </Select>
    </FormControl>
  );
}
