import {
  FormControl,
  FormHelperText,
  FormLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionMatrix(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  const isRadioChecked = (row, column) => {
    let value = null;

    if (
      answer &&
      answer.selectedOptions &&
      answer.selectedOptions.length > row.orderNo - 1
    )
      value = answer.selectedOptions[row.orderNo - 1];

    if (value === "" + column.id) return true;

    return false;
  };

  return <>
    <Grid item container alignItems="center" spacing={1}>
      <Grid item xs={12}>
        <FormControl
          variant="standard"
          component="fieldset"
          style={{ width: "100%" }}
          required={question.notEmpty}>
          <Grid container item row xs={12}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                {question.orderNo}.{" "}
                {GetFieldByLanguage(question.multiLangName, currentLang)}
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                {GetFieldByLanguage(question.multiLangHint, currentLang)}
              </FormHelperText>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {question.options
                  .filter((item) => item.optionType === "COLUMN")
                  .map((item, index) => {
                    return (
                      <TableCell key={index}>
                        {GetFieldByLanguage(item.multiLangName, currentLang)}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {question.options
                .filter((item) => item.optionType === "ROW")
                .map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {GetFieldByLanguage(row.multiLangName, currentLang)}
                      </TableCell>
                      {question.options
                        .filter((item) => item.optionType === "COLUMN")
                        .map((column, index) => {
                          return (
                            <TableCell key={index}>
                              <Radio
                                checked={isRadioChecked(row, column)}
                                color="primary"
                                value={"" + column.id}
                                disabled={readOnly}
                                onChange={(event) => {
                                  if (event.target.value) {
                                    if (answer.selectedOptions) {
                                      let tmp = [...answer.selectedOptions];
                                      tmp[row.orderNo - 1] =
                                        event.target.value;
                                      onChange(
                                        question,
                                        tmp,
                                        "selectedOptions"
                                      );
                                    } else {
                                      let tmp = question.options
                                        .filter(
                                          (item) => item.optionType === "ROW"
                                        )
                                        .map((i) => null);
                                      tmp[row.orderNo - 1] =
                                        event.target.value;
                                      onChange(
                                        question,
                                        tmp,
                                        "selectedOptions"
                                      );
                                    }
                                  }
                                }}
                              />
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </>;
}

SurveyQuestionMatrix.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
