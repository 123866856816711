import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { Settings } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function RefreshPeriodButton(props) {
  const { anchorEl, setAnchorEl, refreshPeriod, setRefreshPeriod, title } =
    props;

  const { t } = useTranslation("components", { i18n });

  const changeRefreshPeriod = (param) => {
    setRefreshPeriod(param);
    setAnchorEl(null);
  };

  return <>
    <Tooltip title={title ?? t("REFRESH_PERIOD")}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        size="large">
        <Settings />
      </IconButton>
    </Tooltip>
    <Menu
      id="refresh-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      <MenuItem disabled={true}>{t("AUTO_REFRESH")}</MenuItem>
      <MenuItem
        onClick={() => {
          changeRefreshPeriod(15);
        }}
      >
        15 {t("SECOND")} {refreshPeriod === 15 ? <CheckIcon /> : null}
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeRefreshPeriod(30);
        }}
      >
        30 {t("SECOND")} {refreshPeriod === 30 ? <CheckIcon /> : null}
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeRefreshPeriod(60);
        }}
      >
        1 {t("MINUTE")} {refreshPeriod === 60 ? <CheckIcon /> : null}
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeRefreshPeriod(120);
        }}
      >
        2 {t("MINUTE")} {refreshPeriod === 120 ? <CheckIcon /> : null}
      </MenuItem>
      <MenuItem
        onClick={() => {
          changeRefreshPeriod(300);
        }}
      >
        5 {t("MINUTE")} {refreshPeriod === 300 ? <CheckIcon /> : null}
      </MenuItem>
    </Menu>
  </>;
}
