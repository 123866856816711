import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CampaignSurveyQuestionRender from "features/campaign/CampaignSurveyQuestionRender";
import i18n from "i18n";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import ReactPuzzleConfirm from "react-puzzle-confirm";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FingerPrintService } from "services/FingerPrintService";
import { GpsLocationService } from "../../../services/location/GpsLocationService";
import { ReportService } from "../../../services/report/reportService";
import LandingHeader from "../landing/header/LandingHeader";
// import useStyles from "./style";
import parse from "html-react-parser";
import { useStyles } from "../ReportIssueStyle";

export default function Survey(props) {
  const { appLogo, miniAppLogo } = props;
  const { id, thingId, accountId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const formRef = createRef();
  const [loading, setLoading] = useState(true);
  const [gpsError, setGpsError] = useState(false);
  const lang = getCurrentLang();
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);

  const [isShowNextButton, setIsShowNextButton] = useState(false);

  const [visitorId, setVisitorId] = useState(null);
  const [blockReport, setBlockReport] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState(false);
  const [endMessage, setEndMessage] = useState(false);

  const [survey, setSurvey] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const [anchor, setAnchor] = useState(null);
  const [selectedLang, setSelectedLang] = useState("tr");

  const [showCaptcha, setShowCaptcha] = useState(false);

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      GpsLocationService.getLocation(getLocationOnSuccess, getLocationOnError);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    FingerPrintService.getVisitorId(visitorIdOnSuccess, (error) => {
      visitorIdOnSuccess(null);
    });
  }, []);

  const visitorIdOnSuccess = (dataVisitorId) => {
    setVisitorId(dataVisitorId);
    setSurveyAnswer((prev) => {
      return { ...prev, visitorId: dataVisitorId };
    });
    ReportService.checkVisitor(
      accountId,
      dataVisitorId,
      "CUSTOMER",
      publicThing?.id,
      (data) => {
        if (data === false) {
          setBlockReport(true);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (thingId)
      ReportService.getSurveyByThing(
        id,
        thingId,
        accountId,
        getSurveyOnSuccess,
        (error) => {}
      );
    else {
      ReportService.getSurveyWithoutThing(
        id,
        accountId,
        getSurveyOnSuccess,
        (error) => {}
      );
    }
  }, []);

  useEffect(() => {
    if (survey && visitorId) {
      ReportService.isSurveyAnswerBlock(
        survey.id,
        visitorId,
        (data) => {
          if (data === true) {
            setBlockReport(true);
          }
        },
        (error) => {}
      );
    }
  }, [survey, visitorId]);

  const getSurveyOnSuccess = (data) => {
    setSurvey(data);

    let details = [];
    data.questionList.forEach((element) => {
      details.push({
        surveyQuestion: {
          id: element.id,
          notEmpty: element.notEmpty,
          pageNo: element.surveyPage.orderNo,
        },
        dataString: "",
        dataNumeric: null,
        dataDate: null,
        dataBool: null,
        dataFile: null,
        comment: "",
      });
    });

    setSurveyAnswer((prev) => {
      return { ...prev, details };
    });

    if (data?.showWelcomeMessage && data?.welcomeMessage)
      setWelcomeMessage(true);

    if (data?.showEndMessage && data?.endMessage)
      setEndMessage(data?.endMessage);
  };

  const getLocationOnSuccess = (data) => {
    setSurveyAnswer((prev) => {
      return { ...prev, gpsLocation: JSON.stringify(data) };
    });
  };

  const getLocationOnError = (data) => {
    setGpsError(data);
  };

  const [surveyAnswer, setSurveyAnswer] = useState({
    account: { id: accountId },
    thing: { id: thingId },
    survey: { id: id },
    gpsLocation: null,
    details: [],
    startDate: new Date(),
    filled: false,
  });

  const [reported, setReported] = useState(false);

  const theme = useSelector(selectThemePrefer);
  const dispatch = useDispatch();

  const nextPage = () => {
    window.scrollTo(0, 0);
    setActivePage((prev) => prev + 1);
  };

  const previousPage = () => {
    if (activePage === 1) navigateMainPage();
    else setActivePage((prev) => prev - 1);
  };
  const navigateMainPage = () => {
    let tmpTo = {
      pathname: `/q/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };
  const submitSurvey = () => {
    setLoading(true);

    let completionTime =
      (new Date().getTime() - surveyAnswer.startDate.getTime()) / 1000;
    const data = { ...surveyAnswer, completionTime: completionTime };

    if (
      survey.userVerificationType === "SMS" ||
      survey.userVerificationType === "EMAIL" ||
      survey.userVerificationType === "SMS_EMAIL"
    ) {
      let tmpTo = {
        pathname: publicThing
          ? `/report/customer/tid=${publicThing.tid}`
          : `/report/customer/tid=`,
        state: {
          publicThing: {
            ...publicThing,
          },
          survey: {
            ...survey,
          },
          surveyCustomer: data,
          userVerificationType: survey.userVerificationType,
        },
      };
      history.push(tmpTo);
    } else {
      ReportService.answerSurvey(
        data,
        submitSurveyOnSuccess,
        submitSurveyOnError
      );
    }
  };

  const submitSurveyOnSuccess = (data) => {
    setLoading(false);
    setReported(true);
    let tmpTo = {
      pathname: `/report/surveyResult/1`,
      state: {
        publicThing: publicThing
          ? {
              ...publicThing,
            }
          : null,
        endMessage: endMessage,
      },
    };
    history.push(tmpTo);
  };

  const submitSurveyOnError = (data) => {
    setLoading(false);
    showAlert(t("SUBMIT_ERROR_MSG"), "error");
  };

  const onChangeAnswer = (question, value, field, value2, field2) => {
    let filled = false;
    if (question.notEmpty && (value || value2)) {
      filled = true;
    }
    setSurveyAnswer((prev) => {
      let tempDetails = [...prev.details];
      tempDetails[question.orderNo - 1] = {
        ...tempDetails[question.orderNo - 1],
        [field]: value,
        filled: filled,
      };

      if (field2) {
        tempDetails[question.orderNo - 1] = {
          ...tempDetails[question.orderNo - 1],
          [field2]: value2,
          filled: filled,
        };
      }

      return { ...prev, details: tempDetails };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (
      surveyAnswer &&
      surveyAnswer.details &&
      surveyAnswer.details.length > 0
    ) {
      for (let index = 0; index < surveyAnswer.details.length; index++) {
        const element = surveyAnswer.details[index];
        if (
          !element.filled &&
          element.surveyQuestion.notEmpty &&
          activePage === element.surveyQuestion.pageNo
        ) {
          showAlert("Cevaplanmayan sorular var", "error");
          return;
        }
      }
    }

    if (activePage === survey.pageList.length) {
      if (survey.userVerificationType === "CAPTCHA") {
        nextPage();
        setShowCaptcha(true);
      } else {
        submitSurvey();
      }
    } else if (activePage !== survey.pageList.length) {
      nextPage();
    }
  };

  const getSurveyPageContent = () => {
    let selectedPage = survey.pageList.filter(
      (item) => item.orderNo === activePage
    );
    if (selectedPage && selectedPage.length > 0) selectedPage = selectedPage[0];
    else selectedPage = null;

    if (!selectedPage) return;

    let pageQuestions = survey.questionList.filter(
      (item) => item.surveyPage.orderNo === activePage
    );

    const handleClick = (event) => {
      setAnchor(event.currentTarget);
    };
    const onMenuItemClick = (event, index) => {
      setAnchor(null);
      setSelectedLang(index);
    };
    const handleClose = () => {
      setAnchor(null);
    };

    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        style={{ paddingBottom: 50 }}
      >
        {survey && survey.languages && survey.languages.length > 0 && (
          <Grid item xs={12}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              size="small"
              style={{
                color: "#532363",
                marginRight: "10px",
                fontSize: "22px",
              }}
              title={selectedLang.toUpperCase()}
            >
              <Typography style={{ marginRight: 5 }}>Dil: </Typography>
              <ReactCountryFlag
                countryCode={
                  selectedLang === "en" ? "US" : selectedLang.toUpperCase()
                }
                svg
              />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchor}
              keepMounted
              open={Boolean(anchor)}
              onClose={handleClose}
            >
              {survey.languages &&
                survey.languages.map((lang) => (
                  <MenuItem
                    onClick={(event) =>
                      onMenuItemClick(event, lang.toLowerCase())
                    }
                  >
                    <ReactCountryFlag
                      countryCode={lang === "EN" ? "US" : lang.toUpperCase()}
                      svg
                    />
                  </MenuItem>
                ))}
            </Menu>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography style={{ fontSize: "20px" }}>
            {survey?.pageList?.length > 1 &&
              `${activePage}/${survey?.pageList?.length}`}{" "}
            {selectedPage.multiLangName?.[selectedLang]}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "16px" }}>
            {selectedPage.multiLangDescription?.[selectedLang]}
          </Typography>
        </Grid>
        {pageQuestions &&
          pageQuestions.map((item, index) => {
            return (
              <Grid
                item
                xs={12}
                style={{
                  borderBottom: "1px dashed",
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 20,
                  marginTop: 20,
                  paddingBottom: 20,
                }}
              >
                <CampaignSurveyQuestionRender
                  key={index}
                  question={item}
                  onChangeAnswer={onChangeAnswer}
                  answer={
                    surveyAnswer.details.filter(
                      (i) => i.surveyQuestion.id === item.id
                    )[0]
                  }
                  editable={true}
                  readOnly={false}
                  accountId={accountId}
                  currentLang={selectedLang}
                />
              </Grid>
            );
          })}
      </Grid>
    );
  };

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const puzzleRender = () => {
    return (
      <>
        <div>
          <ReactPuzzleConfirm
            onClose={() => {
              setShowCaptcha(false);
              previousPage();
            }}
            onSuccess={() => {
              setShowCaptcha(false);
              submitSurvey();
            }}
            onFail={() => console.log("failed")}
            title="Lütfen bulmaca parçasını dikkatlice yerleştirin"
            sliderTitle=""
            failMessage="Başarısız"
            successMessage="Başarılı"
            closeButtonLabel="Geri"
            refrefButtonLabel="Yenile"
            disableRefreshIdleState={true}
          />
        </div>
        <div style={{ textAlign: "center", paddingTop: 10 }}>
          <Typography style={{ fontSize: "12px" }}>
            Bulmacayı tamamlamak için kaydırın
          </Typography>
        </div>
        <div style={{ textAlign: "center" }}>
          <img
            alt="Hint"
            height="90px"
            src={process.env.PUBLIC_URL + "/assets/hint/captcha_hint.gif"}
          ></img>
        </div>
      </>
    );
  };

  const renderReported = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              lg={10}
              xs={12}
              style={{ textAlign: "center", marginBottom: 92 }}
            >
              <LandingHeader
                publicThing={publicThing}
                miniAppLogo={miniAppLogo}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ fontSize: "64px", color: green[500] }}
              />
              <Typography style={{ fontSize: "20px" }}>
                Anket katılımınız daha öncesinde alınmıştır, teşekkürler.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <FaButton
              faType="button"
              size="large"
              label={t("Geri")}
              onClick={() => {
                history.goBack();
              }}
              style={{ height: "60px" }}
            ></FaButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderWelcomeMessage = () => {
    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        style={{ paddingBottom: 50 }}
      >
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          {parse(survey.welcomeMessage)}
        </Grid>

        <div className={classes.footer}>
          <div className={classes.actionButtons2Div}>
            <div style={{ flex: 1 }}>
              <Button
                className={classes.backButton}
                onClick={() => {
                  setWelcomeMessage(false);
                }}
              >
                {t("Devam")}
              </Button>
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ minHeight: "100vh", width: "100vw" }}
    >
      {blockReport && renderReported()}
      {!blockReport && (
        <form
          id="survey-form"
          ref={formRef}
          validate="true"
          onSubmit={onSubmit}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            component={Paper}
            elevation={6}
            style={{
              minHeight: "100vh",
              width: "100vw",
              margin: "10px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <div>
              <Grid
                item
                container
                spacing={3}
                lg={8}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  lg={10}
                  xs={12}
                  style={{ textAlign: "center", marginBottom: 92 }}
                >
                  <LandingHeader
                    publicThing={publicThing}
                    miniAppLogo={miniAppLogo}
                  />
                </Grid>
              </Grid>
              <div>
                <Typography variant="h5">
                  {survey?.multiLangName?.[selectedLang]}
                </Typography>
              </div>
              {welcomeMessage && renderWelcomeMessage()}
            </div>
            {!welcomeMessage && (
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  {survey && survey.pageList && getSurveyPageContent()}
                </Grid>
                <Grid item xs={12}>
                  {showCaptcha && puzzleRender()}
                </Grid>

                {survey && survey.pageList && (
                  <div className={classes.footer}>
                    <div className={classes.actionButtons2Div}>
                      {!showCaptcha && activePage >= 1 && (
                        <div style={{ flex: 1 }}>
                          <Button
                            className={classes.backButton}
                            onClick={previousPage}
                          >
                            {t("Geri")}
                          </Button>
                        </div>
                      )}

                      {!showCaptcha &&
                        activePage !== survey.pageList.length && (
                          <div style={{ flex: 1 }}>
                            <Button
                              className={classes.nextButton}
                              onClick={onSubmit}
                            >
                              {t("İleri")}
                            </Button>
                          </div>
                        )}

                      {!showCaptcha &&
                        activePage === survey.pageList.length && (
                          <div style={{ flex: 1 }}>
                            <Button
                              className={classes.nextButton}
                              onClick={onSubmit}
                            >
                              {survey.userVerificationType
                                ? t("Devam")
                                : t("Katıl")}
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </Grid>
            )}
          </Box>
        </form>
      )}
    </Box>
  );
}

Survey.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
  miniAppLogo: PropTypes.any,
};
