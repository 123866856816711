import { THINGS_ENVIRONMENT } from "environments/Environments";
import PropTypes from "prop-types";
import React from "react";
import { getThingsApp } from "services/utils";
import { APP } from "services/utils/Constants";
import LoginCNF from "./LoginCNF";
import LoginT2C from "./LoginT2C";
import LoginTF from "./LoginTF";

export default function Login(props) {
  const {
    appLogo,
    requestUrl,
    align,
    backGroundImage,
    signUpPath,
    fgPasswordPath,
    usernameLogin,
    userPhoneNumberLogin,
  } = props;

  const app = getThingsApp();

  const renderTF = () => {
    return (
      <LoginTF
        appLogo={THINGS_ENVIRONMENT.appLogo}
        requestUrl={requestUrl}
        align={align}
        backGroundImage={backGroundImage}
        signUpPath={signUpPath}
        fgPasswordPath={fgPasswordPath}
        usernameLogin={usernameLogin}
        userPhoneNumberLogin={userPhoneNumberLogin}
      />
    );
  };

  const renderT2C = () => {
    return (
      <LoginT2C
        appLogo={THINGS_ENVIRONMENT.appLogoT2CDark}
        requestUrl={requestUrl}
        align={align}
        backGroundImage={backGroundImage}
        signUpPath={signUpPath}
        fgPasswordPath={fgPasswordPath}
        usernameLogin={usernameLogin}
        userPhoneNumberLogin={userPhoneNumberLogin}
      />
    );
  };

  const renderCNF = () => {
    return (
      <LoginCNF
        appLogo={THINGS_ENVIRONMENT.appLogoT2CDark}
        requestUrl={requestUrl}
        align={align}
        backGroundImage={backGroundImage}
        signUpPath={signUpPath}
        fgPasswordPath={fgPasswordPath}
        usernameLogin={usernameLogin}
        userPhoneNumberLogin={userPhoneNumberLogin}
      />
    );
  };

  if (app === APP.T2C) return renderT2C();
  else if (app === APP.CNF) return renderCNF();
  else return renderTF();
}

Login.propTypes = {
  appLogo: PropTypes.any,
  align: PropTypes.string,
  requestUrl: PropTypes.string,
  backGroundImage: PropTypes.any,
  usernameLogin: PropTypes.bool,
  signUpPath: PropTypes.string,
  fgPasswordPath: PropTypes.string,
};
