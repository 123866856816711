import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { default as React, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function CampaignCustomerLog(props) {
  const { id } = props;
  const labelClasses = labelStyles();
  const { t } = useTranslation("campaign", { i18n });

  const [logList, setLogList] = useState([]);

  useEffect(() => {
    if (id) {
      CampaignService.getCustomerLogList(id, setLogList, (error) => {});
    }
  }, [id]);

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader title={t("Geçmiş")} />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell className={labelClasses.LabelHeader}>
                                  Tarih
                                </TableCell>
                                <TableCell className={labelClasses.LabelHeader}>
                                  İşlem
                                </TableCell>
                                <TableCell className={labelClasses.LabelHeader}>
                                  Kullanıcı
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {logList.map((item, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    className={labelClasses.LabelValue}
                                  >
                                    {getDateTimeWFormat(item.logDate)}
                                  </TableCell>

                                  <TableCell
                                    className={labelClasses.LabelValue}
                                  >
                                    {t(item.status)}
                                  </TableCell>
                                  <TableCell
                                    className={labelClasses.LabelValue}
                                  >
                                    {item.userFullName}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
