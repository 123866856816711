import { Avatar, Link, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { THING } from "services/faApi";
import { ThingDesc } from "services/utils";
import { BASE_URL } from "serviceWorker";
import { imageStyles } from "styles/ThingsImageStyle";
import { labelStyles } from "styles/ThingsLabelStyle";

export default function ThingDetail(props) {
  const { value, noLink, maxWidth } = props;
  let history = useHistory();
  const labelClasses = labelStyles();
  const imageClasses = imageStyles();

  const goThingDetail = () => {
    const tmpTo = {
      pathname: `/thingDetail/${value?.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: value?.description,
    };
    history.push(tmpTo);
    document.querySelector("#root > div > main").scrollTo(0, 0);
  };

  const getImageSrc = () => {
    if (value && value.thumbnailUrl) {
      return (
        BASE_URL + THING.thumbnail + "/" + value.id + "/" + value.thumbnailUrl
      );
    } else if (value && value.thumbnail) {
      return `data:image/png;base64, ${value.thumbnail}`;
    }
  };

  const render = () => {
    return (
      <>
        {value && (value.thumbnailUrl || value.thumbnail) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: maxWidth === "sm" ? "0" : "2%",
            }}
          >
            <img
              alt=""
              src={getImageSrc()}
              className={
                maxWidth === "sm"
                  ? imageClasses.ThingListImageSm
                  : imageClasses.ThingListImage
              }
            />{" "}
            <Typography className={labelClasses.LabelValue}>
              {ThingDesc(value)}
            </Typography>
          </div>
        )}
        {value && !(value.thumbnailUrl || value.thumbnail) && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "2%",
            }}
          >
            <Avatar variant="square" className={imageClasses.ThingListImage}>
              {" "}
              {ThingDesc(value).charAt(0)}
            </Avatar>{" "}
            <Typography className={labelClasses.LabelValue}>
              {ThingDesc(value)}
            </Typography>
          </div>
        )}
      </>
    );
  };

  return <>
    {noLink && render()}
    {!!!noLink && (
      <Link
        onClick={goThingDetail}
        style={{
          cursor: "pointer",
        }}
        underline="hover">
        {render()}
      </Link>
    )}
  </>;
}
