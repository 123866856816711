import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CampaignCustomerStatusSelect(props) {
  const { value, onChange, required, type } = props;
  const classes = useStyles();
  const { t } = useTranslation("campaign", { i18n });

  return (
    <FormControl className={classes.w100}>
      <InputLabel>{t("STATUS")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("STATUS")}
      >
        <MenuItem value={null}>...</MenuItem>
        {type !== "fill" && (
          <MenuItem value={"REGISTERED"}>{t("REGISTERED")}</MenuItem>
        )}
        <MenuItem value={"WAITING_FOR_INFORMATION"}>
          {" "}
          {t("WAITING_FOR_INFORMATION")}
        </MenuItem>
        <MenuItem value={"VERIFIED"}>{t("VERIFIED")}</MenuItem>
        {type !== "fill" && <MenuItem value={"WIN"}>{t("WIN")}</MenuItem>}
        {type !== "fill" && (
          <MenuItem value={"WIN_BACKUP"}>{t("WIN_BACKUP")}</MenuItem>
        )}
        <MenuItem value={"REJECT"}>{t("REJECT")}</MenuItem>
      </Select>
    </FormControl>
  );
}
