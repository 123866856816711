import { Check, Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { MessageTemplateService } from "services/MessageTemplateService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import MessageTemplateEdit from "./MessageTemplateEdit";

export default function MessageTemplateDetail() {
  const tableRef = useRef();
  const labelClasses = labelStyles();
  const { t } = useTranslation(["messageTemplate", "components"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = (data) => {
    setLoading(true);
    MessageTemplateService.findById(
      data?.id ? data?.id : id,
      (data) => {
        setMessageTemplate(data);
        setLoading(false);
      },
      (error) => {
        showAlert(error.message, "error");
        setLoading(false);
      }
    );
  };

  const [messageTemplate, setMessageTemplate] = useState({});

  const editEmailClicked = () => {
    const tmpTo = {
      pathname: `/messageTemplateEmailEditor/${messageTemplate.id}`,
      breadCrumbKey: `/messageTemplateEmailEditor/:id`,
    };
    history.push(tmpTo);
  };

  const editButtonClicked = () => {
    setIsEditFormOpen(true);
  };

  const messageTemplateOnChange = (data) => {
    setIsEditFormOpen(false);
    refresh(data);
  };

  const messageTemplateOnDelete = () => {
    history.goBack();
    setIsEditFormOpen(false);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <div>
          <MessageTemplateEdit
            onChange={messageTemplateOnChange}
            onDelete={messageTemplateOnDelete}
            onClose={() => {
              setIsEditFormOpen(false);
            }}
            value={messageTemplate}
            open={isEditFormOpen}
          />
          <Grid container spacing={1}>
            <Grid item lg={12}>
              <Grid container spacing={1}>
                <Grid item lg={12}>
                  <Card>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={editButtonClicked}
                          size="large"
                        >
                          <Edit />
                        </IconButton>
                      }
                      title="Mesaj Şablon Bilgileri"
                    />
                    <CardContent>
                      <Grid item container spacing={1}>
                        <Grid item xs={12}>
                          <Grid item container spacing={1}>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("İşlem Tipi")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {messageTemplate?.actionType &&
                                  t(
                                    "ACTION_TYPE." +
                                      messageTemplate?.actionType,
                                    { ns: "components" }
                                  )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("Açıklama")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {messageTemplate?.description}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("Ana Şablon")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                {messageTemplate?.template && <Check />}
                              </Typography>
                            </Grid>
                            {!!!messageTemplate.template && (
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("Şablon")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {messageTemplate?.baseTemplate?.description}
                                </Typography>
                              </Grid>
                            )}

                            {!!!messageTemplate.template && (
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("Mesaj Konusu")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {messageTemplate?.title}
                                </Typography>
                              </Grid>
                            )}

                            <Grid
                              item
                              xs={12}
                              className={labelClasses.LabelRow}
                            >
                              <Typography className={labelClasses.LabelHeader}>
                                {t("Mesaj İçeriği")}
                              </Typography>
                              <Typography className={labelClasses.LabelValue}>
                                <IconButton
                                  aria-label="settings"
                                  onClick={editEmailClicked}
                                  size="large"
                                >
                                  <Edit />
                                </IconButton>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: messageTemplate?.content,
                                  }}
                                />
                              </Typography>
                            </Grid>

                            {!!!messageTemplate.template && (
                              <Grid
                                item
                                xs={4}
                                className={labelClasses.LabelLastRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("E-Posta")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {messageTemplate?.email && <Check />}
                                </Typography>
                              </Grid>
                            )}

                            {!!!messageTemplate.template && (
                              <Grid
                                item
                                xs={4}
                                className={labelClasses.LabelLastRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("Mobil - Push")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {messageTemplate?.push && <Check />}
                                </Typography>
                              </Grid>
                            )}

                            {!!!messageTemplate.template && (
                              <Grid
                                item
                                xs={4}
                                className={labelClasses.LabelLastRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("SMS")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {messageTemplate?.sms && <Check />}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Suspense>
    </>
  );
}
