import {
  faEdit,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Add, Close, Settings } from "@mui/icons-material";
import ThingsDataTable from "components/thingsDataTable";
import AdditionalFieldsEdit from "features/additionalFields/additionalFieldsEdit";
import ThingClassLikeClassHelp from "features/help/ThingClassLikeClassHelp";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { LikeClassService } from "services/LikeClassService";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingClassLikeClassEdit(props) {
  const { thingClass } = props;

  const { t } = useTranslation("thingClass", { i18n });
  const tableRef = useRef();
  const classes = useStyles();
  const faDialog = useDialog();

  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [likeClass, setLikeClass] = useState(null);
  const [likeClassList, setLikeClassList] = useState([]);

  const [selectedLikeClass, setSelectedLikeClass] = useState(null);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [isAdditionalFieldsDialogOpen, setIsAdditionalFieldsDialogOpen] =
    useState(false);

  useEffect(() => {
    if (thingClass && accountId && thingClass.id && thingClass.id !== 0) {
      LikeClassService.findByThingClass(
        thingClass.id,
        accountId,
        findByThingClassOnSuccess,
        findByThingClassOnError
      );
    }
  }, [thingClass, accountId]);

  const findByThingClassOnSuccess = (data) => {
    setLikeClassList(data.list);
  };

  const findByThingClassOnError = (data) => {};

  const addLikeClass = () => {
    LikeClassService.save(likeClass, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    LikeClassService.findByThingClass(
      thingClass.id,
      accountId,
      findByThingClassOnSuccess,
      findByThingClassOnError
    );
    setIsDialogOpen(false);
  };

  const saveOnError = (data) => {};

  const deleteLikeClass = () => {
    faDialog({
      description: t("Beğeni tipini silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        LikeClassService.delete(likeClass, deleteOnSuccess, deleteOnError);
      })
      .catch((err) => console.log("error:", err));
  };

  const deleteOnSuccess = (data) => {
    LikeClassService.findByThingClass(
      thingClass.id,
      accountId,
      findByThingClassOnSuccess,
      findByThingClassOnError
    );

    setOpenEnableDialog(false);
    setOpenDisableDialog(false);
    setIsDialogOpen(false);
  };

  const deleteOnError = (data) => {
    showAlert("Kayıt silinemedi, bağlı kayıtlar mevcut", "error");
  };

  const handleChange = (prop, val) => {
    setLikeClass({ ...likeClass, [prop]: val });
  };

  const renderDialog = () => {
    return (
      <FaDialog
        loading={loading}
        showSaveButton
        faOpen={isDialogOpen}
        title={t("Beğeni Tipi Ekle")}
        faOnSubmit={addLikeClass}
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid item container spacing={1} data-fa-section="LIKE_CLASS_DIALOG">
          <Grid item xs={12}>
            <FaInput
              required
              id="label"
              label={t("Beğeni Tipi")}
              value={likeClass?.className}
              onChange={(event) => {
                handleChange("className", event.target.value);
              }}
              name="label"
              inputProps={{
                maxLength: 255,
              }}
            />
          </Grid>
        </Grid>
        {likeClass?.id ? (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deleteLikeClass} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        ) : null}
      </FaDialog>
    );
  };

  const renderAdditionalFieldsDialog = () => {
    return isAdditionalFieldsDialogOpen &&
    likeClass &&
    likeClass.id && (
      <Dialog
        open={isAdditionalFieldsDialogOpen}
        onClose={() => {
          setIsAdditionalFieldsDialogOpen(false);
        }}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              setIsAdditionalFieldsDialogOpen(false);
            }}
            size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <AdditionalFieldsEdit
                dynamicName="LIKE_CLASS"
                dynamicId={likeClass.id}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const columns = [
    {
      field: "className",
      label: t("Beğeni Tipi"),
      align: "center",
      width: "80%",
      valueFormatter: (value, row) => {
        if (row.thingClass.id === thingClass.id) return value;
        else
          return (
            <>
              {value} <i>({row.thingClass.className})</i>
            </>
          );
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        if (row.thingClass.id === thingClass.id)
          return (
            <>
              <IconButton
                onClick={() => {
                  setLikeClass({ ...row });
                  setIsDialogOpen(true);
                }}
                size="small"
                color="primary"
                title={t("Düzenle")}
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
              <IconButton
                onClick={() => {
                  setLikeClass({ ...row });
                  setIsAdditionalFieldsDialogOpen(true);
                }}
                size="small"
                color="primary"
                title={t("Ayarlar")}
                style={{ marginLeft: 10 }}
              >
                <Settings />
              </IconButton>
            </>
          );
        else if (!row.likeClassDisable) {
          return (
            <IconButton
              onClick={() => {
                setSelectedLikeClass(row);
                setOpenDisableDialog(true);
              }}
              size="small"
              color="primary"
              aria-label={t("GECERLI")}
              title={t("GECERLI")}
            >
              <FontAwesomeIcon icon={faVolumeUp} />
            </IconButton>
          );
        } else if (row.likeClassDisable) {
          return (
            <IconButton
              onClick={() => {
                setSelectedLikeClass(row);
                setOpenEnableDialog(true);
              }}
              size="small"
              color="primary"
              aria-label={t("GECERSIZ")}
              title={t("GECERSIZ")}
            >
              <FontAwesomeIcon icon={faVolumeMute} />
            </IconButton>
          );
        }
      },
    },
  ];

  const likeClassAddButtonClicked = () => {
    setLikeClass({
      id: "",
      className: "",
      domain: "",
      parent: null,
      thingClass: thingClass,
      account: { id: accountId },
    });
    setIsDialogOpen(true);
  };

  const disableControlItem = () => {
    LikeClassService.disable(
      selectedLikeClass.id,
      thingClass.id,
      deleteOnSuccess,
      deleteOnError
    );
  };

  const enableControlItem = () => {
    LikeClassService.enable(
      selectedLikeClass.likeClassDisable.id,
      deleteOnSuccess,
      deleteOnError
    );
  };

  const renderDisableDialog = () => {
    return (
      <Dialog
        open={openDisableDialog}
        onClose={() => {
          setOpenDisableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçersiz Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz beğeni tipi, mevcut etkileşim noktası tipi ve alt
            etkileşim noktası tiplerinde gösterilmeyecektir. Devam etmek istiyor
            musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDisableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              disableControlItem();
            }}
            color="primary"
            autoFocus
          >
            Geçersiz Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEnableDialog = () => {
    return (
      <Dialog
        open={openEnableDialog}
        onClose={() => {
          setOpenEnableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçerli Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz beğeni tipi, mevcut etkileşim noktası tipi ve alt
            etkileşim noktası tiplerinde gösterilmeye başlanacaktır. Devam etmek
            istiyor musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEnableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              enableControlItem();
            }}
            color="primary"
            autoFocus
          >
            Geçerli Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return <>
    {renderDialog()}
    {renderDisableDialog()}
    {renderEnableDialog()}
    {renderAdditionalFieldsDialog()}
    <Card>
      <CardHeader
        action={
          <>
            <ThingClassLikeClassHelp value="thingClass.likeClass" />
            <IconButton
              aria-label="settings"
              onClick={likeClassAddButtonClicked}
              data-fa-button="LIKE_CLASSES_BUTTON"
              size="large">
              <Add />
            </IconButton>
          </>
        }
        title={t("LIKE_CLASSES")}
        data-fa-section="LIKE_CLASSES"
      />
    </Card>
    <ThingsDataTable
      data={likeClassList}
      columns={columns}
      ref={tableRef}
      showDeleteButton={false}
      showHeaderText={false}
      headerText={t("Beğeni Tipleri Listesi")}
      showPaging={false}
      showHeader={false}
    />
  </>;
}
