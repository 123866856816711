import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Badge, Grid, IconButton, Tooltip } from "@mui/material";
import { Check } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import ThingStatusSelect from "components/select/ThingStatusSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { THING } from "services/faApi";
import { isTNF, smartTimeDiff } from "services/utils";
import i18n from "../../i18n";

export default function ThingSelector(props) {
  const { value, accountId, onChange, required, multiple } = props;
  const tableRef = useRef();

  const { t } = useTranslation(["things", "components"], { i18n });

  const [showWindow, setShowWindow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const columns = [
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        //if(row.thumbnail != null)
        //FIXME public url haline getirildi
        if (row.thumbnail != null)
          //return <div style={{display: "flex", alignItems: "center"}}><img src={BASE_URL + THING.getImage + "?thingId=0" + "&objectId=" + row.defaultThumbnail } style={{width: "48px", height: "40px", marginRight: "10px"}} /> {value}</div>
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "organization",
      label: t("ORGANIZATION"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "ownerUser",
      label: t("OWNER"),
      align: "center",
      width: "20%",
      inner: "fullname",
      valueFormatter: (value) => {
        return value?.fullname;
      },
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
    {
      field: "openIssueCount",
      label: t("OPEN_ISSUES"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value && value > 0)
          return (
            <Badge
              badgeContent={value}
              color="secondary"
              style={{ paddingLeft: 12 }}
            ></Badge>
          );
        return <CheckIcon />;
      },
    },
  ];

  const onDoubleClick = (newValue) => {
    if (multiple === true) {
      if (selectedItems.findIndex((i) => i.id === newValue.id) === -1) {
        setSelectedItems([...selectedItems, newValue]);
      }
    } else {
      setShowWindow(false);
      if (onChange) onChange(newValue);
    }
  };

  const multipleSelectedOk = () => {
    setShowWindow(false);
    if (onChange) onChange(selectedItems);
  };

  const newSearchForm = () => {
    return {
      thingClass: null,
      thingClassId: null,
      description: null,
      thingCode: null,
      vendor: null,
      vendorId: null,
      summary: null,
      accountId: accountId,
      status: null,
      createDateStart: null,
      createDateEnd: null,
      images: true,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.clearSelectedRows();
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.clearSelectedRows();
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const renderSelector = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <ThingAutoComplete
          value={value}
          accountId={accountId}
          onChange={(newValue) => {
            if (onChange) onChange(newValue);
          }}
          required={required}
          style={{ minHeight: 0 }}
          multiple={multiple}
        />
        <Tooltip title={t("DETAILED_SELECTION")}>
          <IconButton color="primary" style={{ marginLeft: "8px" }} size="large">
            <FontAwesomeIcon
              icon={faMagic}
              onClick={() => {
                setShowWindow(true);
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  const handleChipDelete = (index) => () => {
    var temp = [...selectedItems];
    temp.splice(index, 1);
    setSelectedItems(temp);
  };

  const onSelectedChange = (rows) => {
    if (multiple) setSelectedItems(rows);
  };

  const renderWindow = () => {
    return (
      <>
        <FaDialog
          title={isTNF(t("SELECT_THING"), t("SELECT_THING_T2C"))}
          faOpen={showWindow}
          faHandleClose={(data) => {
            setShowWindow(false);
          }}
          faMaxWidth="xl"
        >
          <div style={{ minHeight: "800px" }}>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12}>
                <FaSearch
                  onSearch={searchSubmit}
                  onClear={searchClear}
                  onSummarySearch={searchSummary}
                  faType="form"
                >
                  <FaInput
                    label={t("Genel")}
                    value={searchForm.summary}
                    onChange={(event) => {
                      setSearchForm({
                        ...searchForm,
                        summary: event.target.value,
                      });
                    }}
                  />
                  <ThingClassAutoComplete
                    accountId={accountId}
                    value={searchForm.thingClass}
                    filterExists={true}
                    onChange={(data) => {
                      setSearchForm({
                        ...searchForm,
                        thingClass: data,
                        thingClassId: data?.id,
                      });
                    }}
                  />
                  <FaInput
                    label={t("DESCRIPTION")}
                    value={searchForm.description}
                    onChange={(event) => {
                      setSearchForm({
                        ...searchForm,
                        description: event.target.value,
                      });
                    }}
                  />
                  <FaInput
                    label={t("THING_CODE")}
                    value={searchForm.thingCode}
                    onChange={(event) => {
                      setSearchForm({
                        ...searchForm,
                        thingCode: event.target.value,
                      });
                    }}
                  />
                  <VendorAutoComplete
                    accountId={accountId}
                    value={searchForm.vendor}
                    onChange={(data) => {
                      setSearchForm({
                        ...searchForm,
                        vendor: data,
                        vendorId: data.id,
                      });
                    }}
                  />
                  <ThingStatusSelect
                    value={searchForm.status}
                    onChange={(data) => {
                      setSearchForm({
                        ...searchForm,
                        status: data,
                      });
                    }}
                  />
                  <DateRangeSelector
                    onChange={(data) => {
                      setSearchForm({
                        ...searchForm,
                        createDateStart: data.startDate,
                        createDateEnd: data.endDate,
                      });
                    }}
                  />
                </FaSearch>
              </Grid>
              {/* <Grid item xs={12}>
                {selectedItems.map((item, index) => {
                  return (
                    <Chip
                      label={ThingDesc(item)}
                      onDelete={handleChipDelete(index)}
                      style={{ marginRight: 10 }}
                    />
                  );
                })}
              </Grid> */}
            </Grid>
            <ThingsDataTable
              searchUrl={THING.search + "?accountId=" + localStorage.accountId}
              searchForm={searchForm}
              columns={columns}
              ref={tableRef}
              showDeleteButton={false}
              showHeaderText={false}
              headerText={t("VARLIK_LISTESI")}
              faOnDoubleClick={onDoubleClick}
              queryParams={{
                page: 0,
                size: 10,
                sort: "id",
                isAsc: false,
              }}
              onSelectedChange={multiple && onSelectedChange}
            />
            {multiple === true && (
              <div style={{ textAlign: "center" }}>
                <FaButton
                  faClick={() => {
                    multipleSelectedOk();
                  }}
                  fullWidth={false}
                  startIcon={<Check />}
                >
                  {t("SELECT")}
                </FaButton>
              </div>
            )}
          </div>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      {renderSelector()}
      {showWindow && renderWindow()}
    </>
  );
}
