import { Grid } from "@mui/material";
import SurveyQuestionCategoryAutoComplete from "components/autocomplete/SurveyQuestionCategoryAutoComplete";
import ThingsDataTable from "components/thingsDataTable";
import {
  default as React,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SURVEY } from "services/faApi";
import { SurveyService } from "services/SurveyService";
import { GetFieldByLanguage } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

const ChooseFromLibrary = forwardRef((props, ref) => {
  const { onSave, multiple, page, currentLang } = props;
  const { t } = useTranslation("survey", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const tableRef = useRef();
  const [buttonGroupValue, setButtonGroupValue] = useState("ALL");
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchInfo, setSearchInfo] = useState(null);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const columns = [
    {
      field: "multiLangName",
      label: t("NAME"),
      align: "center",
      width: "40%",
      valueFormatter: (val) => {
        return GetFieldByLanguage(val, currentLang);
      },
    },
    {
      field: "questionType",
      label: t("SURVEY_QUESTION_TYPE"),
      align: "center",
      width: "35%",
      valueFormatter: (val) => {
        return t(val);
      },
    },
    {
      field: "surveyQuestionCategory",
      label: t("SURVEY_QUESTION_CATEGORY"),
      align: "center",
      width: "35%",
      valueFormatter: (val) => {
        return val?.name;
      },
    },
  ];

  const newSearchForm = () => {
    return {
      surveyPageId: null,
      accountId: accountId,
      resultType: buttonGroupValue,
      name: null,
      surveyQuestionCategory: null,
      surveyQuestionCategoryId: null,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.clearSelectedRows();
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const onSelectedChange = (rows) => {
    if (multiple) setSelectedItems(rows);
  };

  const saveAll = () => {
    let tempList = [];

    selectedItems.forEach((element) => {
      let newQuestion = {
        ...element,
        id: null,
        surveyPage: page,
        account: { id: accountId },
        refQuestionId: element.id,
      };
      tempList.push(newQuestion);
    });
    SurveyService.saveAllQuestions(
      tempList,
      () => {
        setIsDialogOpen(false);
        onSave();
      },
      () => {}
    );
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  return (
    <FaDialog
      title={t("Kütüphaneden Seç")}
      faOpen={isDialogOpen}
      faOnSubmit={saveAll}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
      }}
      faMaxWidth="lg"
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <div>{searchInfo}</div>
        </Grid>
        <Grid item xs={6}>
          <FaSearch
            onSearch={searchSubmit}
            onClear={searchClear}
            onSummarySearch={searchSummary}
            setSearchInfo={setSearchInfo}
            faSummary={searchForm?.summary}
          >
            <FaInput
              label={t("NAME")}
              value={searchForm.name}
              onChange={(event) => {
                handleChangeGeneric(
                  setSearchForm,
                  searchForm,
                  "name",
                  event.target.value
                );
              }}
            />
            <SurveyQuestionCategoryAutoComplete
              required={false}
              accountId={accountId}
              value={searchForm.surveyQuestionCategory}
              onChange={(data) => {
                handleChangeGeneric(
                  setSearchForm,
                  searchForm,
                  "surveyQuestionCategory",
                  data,
                  "surveyQuestionCategoryId",
                  data?.id
                );
              }}
            />
          </FaSearch>
        </Grid>
      </Grid>
      <ThingsDataTable
        searchUrl={SURVEY.libraryQuestions}
        searchForm={searchForm}
        columns={columns}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "createDate",
          accountId: localStorage.accountId,
        }}
        ref={tableRef}
        showHeaderText={false}
        onSelectedChange={multiple && onSelectedChange}
      />
    </FaDialog>
  );
});

export default ChooseFromLibrary;
