import {
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function SolutionStocks(props) {
  const { solution } = props;
  const { t } = useTranslation("solutions", { i18n });
  const labelClasses = labelStyles();

  return (
    <>
      <Card>
        <CardHeader
          title={t("USED_MATERIALS")}
          data-fa-section="USED_MATERIALS"
        />
        <CardContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {solution?.solutionStocks.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={labelClasses.LabelValue}>
                      {item.stock.name}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      {item.stock.code}
                    </TableCell>
                    <TableCell className={labelClasses.LabelValue}>
                      {item.amount} {item.stock.unit.name}
                    </TableCell>
                  </TableRow>
                ))}
                {solution.solutionStocks == null ||
                solution.solutionStocks.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={3} className={labelClasses.LabelValue}>
                      {t("NO_MATERIAL_RECORDS_FOUND")}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
