import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import ThingDetail from "components/detail/ThingDetail";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CampaignTargetEdit from "./CampaignTargetEdit";
import { useStyles } from "./style";

export default function CampaignTarget(props) {
  const { campaign } = props;

  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [targets, setTargets] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [campaign]);

  const refresh = () => {
    setLoading(true);
    CampaignService.findTargets(id, findTargetsOnSuccess, findTargetsOnError);
  };
  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "90%",
      valueFormatter: (value, row) => {
        return row.user ? row.user.fullname : row.userGroup?.name;
      },
    },
    {
      field: "id",
      label: t("İşlem"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            //onClick={() => deleteUser(row)}
            size="small"
            color="primary"
            aria-label={t("Sil")}
            //disabled={campaign.status !== "DRAFT"}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        );
      },
    },
  ];
  const findTargetsOnSuccess = (data) => {
    setTargets(data);
    setLoading(false);
  };

  const findTargetsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const renderTargets = () => {
    if (campaign == null || targets == null || targets.length === 0) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <div>
          {targets?.map((target, index) => {
            return <ThingDetail value={target.thing} />;
          })}
        </div>
      );
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      <CampaignTargetEdit
        campaignId={campaign.id}
        targets={targets}
        onSave={() => {
          refresh();
          setIsEditFormOpen(false);
        }}
        onClose={() => {
          setIsEditFormOpen(false);
        }}
        open={isEditFormOpen}
      />
      <Card style={{ minHeight: 204 }}>
        <CardHeader
          action={
            <IconButton
              data-fa-button="CAMPAIGN_SCOPE_ADD"
              aria-label="settings"
              disabled={campaign.status !== "DRAFT"}
              onClick={() => {
                setIsEditFormOpen(true);
              }}
              size="large">
              <Edit />
            </IconButton>
          }
          title={t("CAMPAIGN_SCOPE")}
          data-fa-section="CAMPAIGN_SCOPE"
        />
        <CardContent>
          <Grid item container spacing={1}>
            {renderTargets()}
          </Grid>
        </CardContent>
      </Card>
    </Suspense>
  </>;
}
