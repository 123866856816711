import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function IssueSolutionsHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Sorunun çözümü ile ilgili{" "}
            <b>
              Yapılan İşlemler, İşçilik Süresi, Kullanılan Malzemeler ve Kontrol
              Listesi
            </b>{" "}
            gibi Servis/Operasyon bilgilerinin girildiği alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='SOLUTION_INFO_ADD']",
        intro: (
          <TutorialElement>
            <b>Servis/Operasyon Bilgisi</b> girmek için tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep1.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>Tarih</b> seçin, isteğe bağlı <b>Açıklama</b> girin ve{" "}
            <b>İleri</b>’ye tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep2.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            Servis/Operasyon yapılacak <b>Sorun</b> veya <b>Sorunları</b> seçin
            ve <b>İleri</b>’ye tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep3.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>Yapılan işlemi</b> eklemek için tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep3_2.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>Yapılan işlemi</b> seçmek için tıklayın, işlemi <b>seçin</b> ve{" "}
            <b>Ekle</b>’ye tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep3_3.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>İşçilik Süresi</b> girin ve <b>İleri</b>’ye tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep4.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>Kullanılan Malzemeleri</b> girmek için tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep4_2.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>Kullanılan Malzeme</b>’yi seçmek için tıklayın ve malzemeyi
            seçin.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep4_3.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            Kullanılan Malzeme <b>miktarını</b> girin ve <b>Ekle</b>’ye
            tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep4_4.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            <b>İleri</b>'ye tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/issueSolutionStep5.png"
                }
                width="763"
                height="329"
              ></img>
            </div>
            <br />
            Varsa bildirilen sorunla ilgili tanımlanmış <b>Kontrol Listesi</b>
            ’ni doldurun ve <b>Tamamla</b>’ya tıklayın.
            <br />
            <br />
            <b>ÖNEMLİ NOT</b>
            <br />
            Kontrol Listesi{" "}
            <b>
              Standart, Profesyonel (Pro) ve Gelişmiş (Advanced) paketlerde
            </b>{" "}
            mevcuttur.
          </TutorialElement>
        ),
        tooltipClass: classes.issueSolutionClass,
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

IssueSolutionsHelp.propTypes = {
  value: PropTypes.string,
};
