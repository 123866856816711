import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CampaignDetailTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCampaignDetail", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement
            header="Merhaba!"
            icon="tutorial"
            text={t("Kampanya detayı kılavuzuna hoş geldiniz.")}
          />
        ),
      },
      {
        element: "[data-fa-button='PHOTOS_ADD']",
        intro: (
          <TutorialElement icon="info">
            <p>Kampanya ile ilgili Görsel yüklemek için tıklayın.</p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='CAMPAIGN_AWARDS_ADD']",
        intro: (
          <TutorialElement icon="info">
            <div>
              <p>{t("Kampanya ile Ödül belirlemek için tıklayın.")}</p>
            </div>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='CAMPAIGN_CHECKLIST_ADD']",
        intro: (
          <TutorialElement icon="click">
            {t("Katılım koşulu belirlemek için tıklayın.")}
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='CAMPAIGN_SCOPE_ADD']",
        intro: (
          <TutorialElement icon="click">
            {t("Etkileşim Noktası eklemek için tıklayın.")}
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='FILES_ADD']",
        intro: (
          <TutorialElement icon="click">
            {t("Dosya eklemek için tıklayın.")}
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='CAMPAIGN_PAGE_ADD']",
        intro: (
          <TutorialElement icon="click">
            {t(
              "Kampanya katılımında sorulacak soruları düzenlemek için sayfa ekleyin."
            )}
            <p>Her sayfa için sorularınızı belirleyin.</p>
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='SEND_COUPONS']",
        intro: (
          <TutorialElement icon="click">
            {t("Kampanyanızı başlatabilirsiniz.")}
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"campaign_detail"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
