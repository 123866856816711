import { faChartArea, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ActivePassiveSelect from "components/select/ActivePassiveSelect";
import ThingsDataTable from "components/thingsDataTable";
import ActivePassiveDecorator from "components/ui/ActivePassiveDecorator";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GetFieldByLanguage, getDateTimeWFormat } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { SURVEY } from "../../services/faApi";
import SurveyEdit from "./SurveyEdit";

export default function Surveys() {
  const tableRef = useRef();
  const { t } = useTranslation(["survey", "components"], { i18n });
  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "multiLangName",
      label: t("NAME"),
      align: "center",
      width: "30%",
      valueFormatter: (val, row) => {
        let langs = row?.languages;
        let name = "";
        langs.map((lang, index) => {
          let nameTmp = val?.[lang.toLowerCase()]
            ? val?.[lang.toLowerCase()]
            : "";

          if (nameTmp && nameTmp.length > 0) {
            if (name.length > 0) {
              name = name.concat(" / ");
            }
            name = name + nameTmp;
          }
        });
        if (name && name.length > 0) return name;
        else return GetFieldByLanguage(val, "tr", row.name);
      },
    },
    {
      field: "multiLangDescription",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
      valueFormatter: (val) => {
        return val?.["tr"];
      },
    },
    {
      field: "registerStartDate",
      label: t("REGISTER_START_DATE"),
      align: "center",
      width: "15%",
      valueFormatter: (val) => getDateTimeWFormat(val),
    },
    {
      field: "registerEndDate",
      label: t("REGISTER_END_DATE"),
      align: "center",
      width: "15%",
      valueFormatter: (val) => getDateTimeWFormat(val),
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => <ActivePassiveDecorator value={val} />,
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => {
        return (
          <div style={{ display: "flex" }}>
            <IconButton
              onClick={() => {
                navigateToAnalysis(val);
              }}
              size="small"
              color="primary"
              title={t("Analiz")}
            >
              <FontAwesomeIcon icon={faChartArea} />
            </IconButton>
            <IconButton
              onClick={() => {
                navigateToAnswers(val);
              }}
              size="small"
              color="primary"
              title={t("Anket Cevapları")}
            >
              <FontAwesomeIcon icon={faList} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const navigateToAnalysis = (id) => {
    const tmpTo = {
      pathname: `survey/analysis/${id}`,
      breadCrumbKey: `/survey/analysis/:id`,
    };
    history.push(tmpTo);
  };

  const navigateToAnswers = (id) => {
    const tmpTo = {
      pathname: `survey/answers/${id}`,
      breadCrumbKey: `/survey/answers/:id`,
    };
    history.push(tmpTo);
  };

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      name: null,
      description: null,
      status: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `survey/detail/${row.id}`,
      breadCrumbKey: `/survey/detail/:id`,
    };
    history.push(tmpTo);
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onCreate = (data) => {
    const tmpTo = {
      pathname: `/survey/detail/${data.id}`,
      breadCrumbKey: `/survey/detail/:id`,
    };
    history.push(tmpTo);
  };

  const addSurvey = () => {
    return (
      <SurveyEdit
        ref={dialogRef}
        onSave={onCreate}
        currentLang="tr"
      ></SurveyEdit>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addSurvey()}
        <ThingsDataTable
          searchUrl={SURVEY.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                value={searchForm.descripton}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <ActivePassiveSelect
                value={searchForm.status}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "status",
                    data
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
