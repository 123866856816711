import { faGlobe, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import ThingsMapHelp from "features/help/ThingsMapHelp";
import LocationEdit from "features/location/LocationEdit";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import FaLocationAutocomplete from "react-base-fa/dist/fa/faLocationAutocomplete";
import { default as FaMap } from "react-base-fa/dist/fa/faMap";
import { useTranslation } from "react-i18next";
import { MapInteractionCSS } from "react-map-interaction";
import { LocationDetailService } from "services/LocationDetailService";
import i18n from "../../i18n";
import LocationTree from "./LocationTree";
import { useStyles } from "./style";

export default function ThingMap(props) {
  const { isEditable } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();
  const [harita, setHarita] = useState(true);
  const [selectedLocationDetail, setSelectedLocationDetail] = useState(null);
  const [selectedLocationDetailMap, setSelectedLocationDetailMap] =
    useState(null);

  const [thingLocationDetailMap, setThingLocationDetailMap] = useState(null);

  const imgRef = useRef();
  const imgThingDetailRef = useRef();
  const mapRef = useRef();

  const [openIcMekanHaritaDialog, setOpenIcMekanHaritaDialog] = useState(false);
  const [openIcMekanSecimDialog, setOpenIcMekanSecimDialog] = useState(false);
  const [openIcMekanPointDialog, setOpenIcMekanPointDialog] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [newGpsLocation, setNewGpsLocation] = useState(null);
  const [newIndoorLocation, setNewIndoorLocation] = useState({
    x: 0.0,
    y: 0.0,
  });
  const [newLocationText, setNewLocationText] = useState("");
  const [floor, setFloor] = useState();
  const [roomNumber, setRoomNumber] = useState();
  const [isGpsLocationEditFormOpen, setIsGpsLocationEditFormOpen] =
    useState(false);
  const [showPoint, setShowPoint] = useState();
  const [locationRefresh, setLocationRefresh] = useState(1);
  const dialogRef = useRef();

  const [viewport, setViewport] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 16,
    bearing: 0,
    pitch: 0,
  });

  const parseLocation = (gpsLocation, isDefault) => {
    if (gpsLocation && gpsLocation.length > 3) {
      let parsedData = JSON.parse(gpsLocation);
      if (parsedData.lat && parsedData.lng) {
        return parsedData;
      } else if (parsedData.latitude && parsedData.longitude) {
        return { lat: parsedData.latitude, lng: parsedData.longitude };
      }
    }

    if (isDefault === true)
      return { lat: 39.862645729977174, lng: 32.738810051232576 }; //FIXME initial konum = Beytepe?

    return null;
  };

  const parseIndoorLocation = (indoorLocation) => {
    if (indoorLocation && indoorLocation.length > 3) {
      let parsedData = JSON.parse(indoorLocation);
      if (parsedData.x && parsedData.y) {
        return {
          ...parsedData,
          locationDetailId: props?.thing?.locationDetail.id,
        };
      }
    }
    return null;
  };

  const [konum, setKonum] = useState(
    parseLocation(props.thing.gpsLocation, true)
  );
  const [indoorLocation, setIndoorLocation] = useState(
    parseIndoorLocation(props.thing.indoorLocation)
  );

  useEffect(() => {
    setKonum(parseLocation(props.thing.gpsLocation, false));
    setNewGpsLocation(parseLocation(props.thing.gpsLocation, true));
    setIndoorLocation(parseIndoorLocation(props.thing.indoorLocation));
    setNewIndoorLocation(parseIndoorLocation(props.thing.indoorLocation));
    setNewLocationText(props.thing.location);
    setFloor(props.thing.floor);
    setRoomNumber(props.thing.roomNumber);
    if (props.thing.indoorLocation) {
      setHarita(false);
      if (props.thing.locationDetail) {
        LocationDetailService.downloadDocument(
          props.thing.locationDetail.id,
          (data) => {
            setThingLocationDetailMap(data.content);
          },
          () => {}
        );
      }
    } else {
      setHarita(true);
    }

    setTimeout(() => {
      if (imgThingDetailRef && imgThingDetailRef.current) setShowPoint(true);
    }, 3000);
  }, [
    props.thing,
    props.thing.gpsLocation,
    props.thing.indoorLocation,
    props.thing.location,
  ]);

  const closeIcMekanHaritaDialog = () => {
    setOpenIcMekanHaritaDialog(false);
  };

  const devamIcMekanHaritaDialog = () => {
    setOpenIcMekanHaritaDialog(false);
    setOpenIcMekanSecimDialog(true);
  };

  const renderEditDialog = () => {
    return (
      <Dialog
        open={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("Konum seç")}</DialogTitle>
        <DialogContent data-fa-section="SELECT_LOCATION_DIALOG">
          <DialogContentText id="alert-dialog-description">
            {t("Konum seç açıklama")}
          </DialogContentText>
          <Grid item container spacing={1}>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setIsGpsLocationEditFormOpen(true);
                  setIsEditDialogOpen(false);
                }}
                color="primary"
                data-fa-button="COGRAFI_KONUM_SEC"
                variant="outlined"
                size="large"
                style={{ height: "200px", width: "100%" }}
              >
                <div>
                  <FontAwesomeIcon icon={faGlobe} style={{ height: 80 }} />
                  <br />
                  {t("Coğrafi konum seç")}
                </div>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  setOpenIcMekanSecimDialog(true);
                  setIsEditDialogOpen(false);
                  setNewIndoorLocation({ x: 0.0, y: 0.0 });
                }}
                color="primary"
                variant="outlined"
                size="large"
                style={{ height: "200px", width: "100%" }}
              >
                <div>
                  <FontAwesomeIcon icon={faMap} style={{ height: 80 }} />
                  <br />
                  {t("Mekanlar içinden seç")}
                </div>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsEditDialogOpen(false);
            }}
            color="secondary"
            data-fa-button="THING_MAP_CANCEL"
          >
            {t("İptal")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const renderIcMekanHaritaDialog = () => {
    return (
      <Dialog
        open={openIcMekanHaritaDialog}
        onClose={closeIcMekanHaritaDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"İç mekan harita kullanılsın mı?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("İç mekan değişim mesaj")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeIcMekanHaritaDialog} color="primary">
            {t("İptal")}
          </Button>
          <Button onClick={devamIcMekanHaritaDialog} color="primary" autoFocus>
            {t("Devam Et")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const locationDetailSelected = (item) => {
    setOpenIcMekanSecimDialog(false);
    setSelectedLocationDetail(item);
    LocationDetailService.downloadDocument(
      item.id,
      downloadDocumentOnSuccess,
      downloadDocumentOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    setSelectedLocationDetailMap(data.content);
    setOpenIcMekanPointDialog(true);
  };

  const downloadDocumentOnError = () => {};

  const onChangeHaritaKonum = (data) => {
    setNewGpsLocation({
      lng: data.geoCode.lng,
      lat: data.geoCode.lat,
      location: newLocationText,
    });
  };

  const haritaGspKonumSecildi = () => {
    props.onChange({ ...newGpsLocation, location: newLocationText });
    setIsGpsLocationEditFormOpen(false);
  };

  const handleLocationSelect = (location) => {
    const [longitude, latitude] = location.center;

    setNewGpsLocation({
      lng: longitude,
      lat: latitude,
      location: newLocationText,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 17 });
  };

  useEffect(() => {
    console.log({ viewport });
  }, [viewport]);

  useEffect(() => {
    if (mapRef && mapRef.current && konum) {
      mapRef.current.setViewport({
        latitude: konum.lat,
        longitude: konum.lng,
      });
    }
  }, [konum]);

  const renderGpsLocationEditWindow = () => {
    return (
      <>
        {isGpsLocationEditFormOpen && (
          <FaDialog
            title={t("Harita Konum Seç")}
            faOpen={isGpsLocationEditFormOpen}
            faOnSubmit={haritaGspKonumSecildi}
            showSaveButton
            faHandleClose={(data) => {
              setIsGpsLocationEditFormOpen(false);
            }}
            faMaxWidth="xl"
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                <FaLocationAutocomplete
                  faLocation={{
                    lat: newGpsLocation.lat,
                    lng: newGpsLocation.lng,
                  }}
                  faSetLocation={onChangeHaritaKonum}
                ></FaLocationAutocomplete>
              </Grid>
              <Grid item xs={12}>
                {t("Konum Değiştir İpucu")}
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  value={newLocationText}
                  label={t("LOCATION_TEXT")}
                  onChange={(event) => {
                    setNewLocationText(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setNewIndoorLocation({ x: 0.0, y: 0.0 });
                    setIsGpsLocationEditFormOpen(false);
                    setOpenIcMekanSecimDialog(true);
                  }}
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faMap} />}
                  data-fa-button="MEKAN_SEC"
                >
                  {t("İç mekan konum seç")}
                </Button>
              </Grid>
            </Grid>
          </FaDialog>
        )}
      </>
    );
  };

  const renderIcMekanSecimDialog = () => {
    return (
      <>
        <LocationEdit
          ref={dialogRef}
          onSave={(data) => {
            setLocationRefresh((prev) => prev + 1);
          }}
        ></LocationEdit>
        <Dialog
          open={openIcMekanSecimDialog}
          onClose={() => {
            setOpenIcMekanSecimDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("INDOOR_SELECTION")}
          </DialogTitle>
          <DialogContent data-fa-section="IC_MEKAN_SECIM_DIALOG">
            <DialogContentText id="alert-dialog-description">
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <LocationTree
                    accountId={props?.thing?.account?.id}
                    refresh={locationRefresh}
                    onChange={(data) => {
                      locationDetailSelected(data);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenIcMekanSecimDialog(false);
              }}
              color="primary"
            >
              {t("CANCEL")}
            </Button>
            <Button
              onClick={() => {
                dialogRef.current.openDialog();
              }}
              color="primary"
            >
              {t("ADD")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const getPointCoordinates = (e) => {
    var offset = imgRef.current.getBoundingClientRect();

    var x = e.pageX - offset.left;
    var y = e.pageY - offset.top;

    var xRatio =
      Math.round(((x * 100) / imgRef.current.clientWidth) * 100) / 100;
    var yRatio =
      Math.round(((y * 100) / imgRef.current.clientHeight) * 100) / 100;

    setNewIndoorLocation({
      x: xRatio,
      y: yRatio,
      locationDetailId: selectedLocationDetail.id,
    });
  };

  const mekanNoktaSecildi = () => {
    props.onIndoorLocationChange({
      ...newIndoorLocation,
      location: newLocationText,
      floor: floor,
      roomNumber: roomNumber,
    });
    setOpenIcMekanPointDialog(false);
  };

  const getLocationFromRatio = (ref, location, type) => {
    if (ref && ref.current) {
      if (type === "x") {
        var x = Math.round((ref.current.clientWidth * location.x) / 100);

        return (x > 10 ? x - 10 : x) + "px";
      } else if (type === "y") {
        var y = Math.round((ref.current.clientHeight * location.y) / 100);
        return (y > 10 ? y - 10 : y) + "px";
      }
    }

    return "0px";
  };

  useEffect(() => {
    if (props?.thing?.indoorLocation)
      setIndoorLocation(parseIndoorLocation(props.thing.indoorLocation));
  }, [imgRef, imgRef?.current]);

  const renderIcMekanPointDialog = () => {
    return (
      <FaDialog
        title={"Konum"}
        faOpen={openIcMekanPointDialog}
        faOnSubmit={mekanNoktaSecildi}
        showSaveButton
        faHandleClose={(data) => {
          setOpenIcMekanPointDialog(false);
        }}
        faMaxWidth="md"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={12}>
                {selectedLocationDetailMap && newIndoorLocation && (
                  <>
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: getLocationFromRatio(
                            imgRef,
                            newIndoorLocation,
                            "x"
                          ),
                          top: getLocationFromRatio(
                            imgRef,
                            newIndoorLocation,
                            "y"
                          ),
                          backgroundColor: "transparent",
                          xopacity: "0.8",
                        }}
                      >
                        <img
                          alt="Thing"
                          src={
                            process.env.PUBLIC_URL + "/assets/icons/star.png"
                          }
                          style={{ width: "20px" }}
                        ></img>
                      </div>
                    </div>
                    <img
                      alt=""
                      src={`data:image/png;base64, ${selectedLocationDetailMap}`}
                      style={{ width: 500 }}
                      onClick={getPointCoordinates}
                      ref={imgRef}
                    ></img>
                  </>
                )}
              </Grid>
              <Grid item xs={12}>
                {t("İç Mekan Konum Değiştir İpucu")}
              </Grid>
              <Grid item xs={12}>
                <FaInput
                  value={newLocationText}
                  label={t("LOCATION")}
                  onChange={(event) => {
                    setNewLocationText(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FaInput
                  value={floor}
                  label={t("FLOOR")}
                  onChange={(event) => {
                    setFloor(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <FaInput
                  value={roomNumber}
                  label={t("ROOM_NUMBER")}
                  onChange={(event) => {
                    setRoomNumber(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setIsGpsLocationEditFormOpen(true);
                    setOpenIcMekanPointDialog(false);
                  }}
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faGlobe} />}
                >
                  {t("Harita Konum Seç")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setOpenIcMekanPointDialog(false);
                    setNewIndoorLocation({ x: 0.0, y: 0.0 });
                    setOpenIcMekanSecimDialog(true);
                  }}
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faMap} />}
                >
                  {t("Başka bir mekan seç")}
                </Button>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </FaDialog>
    );
  };

  const editButtonClicked = () => {
    if (indoorLocation) {
      setOpenIcMekanSecimDialog(false);
      setSelectedLocationDetail(props.thing.locationDetail);
      setSelectedLocationDetailMap(thingLocationDetailMap);
      setOpenIcMekanPointDialog(true);
    } else if (konum) {
      setIsGpsLocationEditFormOpen(true);
      setOpenIcMekanPointDialog(false);
    } else {
      setIsEditDialogOpen(true);
    }
  };

  useEffect(() => {
    if (openIcMekanPointDialog) {
      // konum imgRef yüzünden refresh olmadı, workaround
      setTimeout(() => {
        setNewIndoorLocation({ ...newIndoorLocation });
      }, 200);
    }
  }, [openIcMekanPointDialog]);
  return (
    <>
      {console.log("konum", konum)}
      {renderIcMekanHaritaDialog()}
      {renderIcMekanSecimDialog()}
      {renderIcMekanPointDialog()}
      {renderGpsLocationEditWindow()}
      {renderEditDialog()}
      <Card className="fai-thingMap" style={{ overflowY: "auto" }}>
        <CardHeader
          action={
            <>
              <ThingsMapHelp value="thingDetail.thingsMap" />
              {isEditable && (
                <IconButton
                  aria-label="settings"
                  onClick={editButtonClicked}
                  data-fa-button="THING_MAP_EDIT_BUTTON"
                  size="large"
                >
                  <ThingsEditIcon />
                </IconButton>
              )}
            </>
          }
          title={t("LOCATION")}
          data-fa-section="LOCATION"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {props?.thing?.location}
            </Grid>
            {konum && konum.lat && konum.lng && !indoorLocation && (
              <Grid
                item
                lg={12}
                xs={12}
                className={classes.map}
                style={{ textAlign: "center" }}
              >
                <FaMap
                  ref={mapRef}
                  viewport={{
                    latitude: konum ? konum.lat : 39.862645729977174,
                    longitude: konum ? konum.lng : 32.738810051232576,
                    zoom: 16,
                    bearing: 0,
                    pitch: 0,
                  }}
                  marker={
                    konum
                      ? {
                          latitude: konum.lat,
                          longitude: konum.lng,
                        }
                      : null
                  }
                ></FaMap>
              </Grid>
            )}

            {thingLocationDetailMap && indoorLocation && (
              <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
                <MapInteractionCSS showControls={true}>
                  {showPoint && (
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: getLocationFromRatio(
                            imgThingDetailRef,
                            indoorLocation,
                            "x"
                          ),
                          top: getLocationFromRatio(
                            imgThingDetailRef,
                            indoorLocation,
                            "y"
                          ),
                          backgroundColor: "transparent",
                          xopacity: "0.8",
                        }}
                      >
                        <img
                          alt="Thing"
                          src={
                            process.env.PUBLIC_URL + "/assets/icons/star.png"
                          }
                          style={{ width: "20px" }}
                        ></img>
                      </div>
                    </div>
                  )}
                  <img
                    alt=""
                    src={`data:image/png;base64, ${thingLocationDetailMap}`}
                    style={{ width: "100%" }}
                    ref={imgThingDetailRef}
                  ></img>
                </MapInteractionCSS>
              </Grid>
            )}

            <Grid item xs={12} style={{ textAlign: "center" }}>
              {props?.thing?.locationDetail &&
                props?.thing?.locationDetail.location.name +
                  " - " +
                  props?.thing?.locationDetail.name}
              {props?.thing?.floor && " - Kat:" + props?.thing?.floor}
              {props?.thing?.roomNumber &&
                " - Oda No:" + props?.thing?.roomNumber}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
