import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { default as React, useEffect, useState } from "react";
import { FaPieChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { CouponService } from "services/CouponService";
import { CHART_COLORS } from "services/utils/ChartConstants";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CouponUsagePieChart(props) {
  const { coupon } = props;
  const { t } = useTranslation("coupon", { i18n });
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    CouponService.stat(
      coupon.id,
      (data) => {
        setChartData([
          {
            id: t("USED"),
            label: t("USED"),
            value: data.totalUsedCoupon,
          },
          {
            id: t("NOT_USED"),
            label: t("NOT_USED"),
            value: data.totalCoupon - data.totalUsedCoupon,
          },
        ]);
      },
      (error) => {}
    );
  }, []);

  return (
    <Card>
      <CardHeader
        title={t("Kullanım Durumu")}
        data-fa-section="Kullanım Durumu"
      ></CardHeader>
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item lg={12} xs={12}>
            <Grid item>
              <FaPieChart
                data={chartData}
                legends={[]}
                height="300px"
                colors={CHART_COLORS}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
