import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SubscriptionService } from "services/SubscriptionService";
import i18n from "../../i18n";

export default function FeatureNotAllowed(props) {
  const { open, onClose } = props;

  let history = useHistory();
  const accountId = localStorage.accountId;

  const { t } = useTranslation("subscriptions", { i18n });

  const [trialPackage, setTrialPackage] = useState(null);

  useEffect(() => {
    SubscriptionService.findTrialSalesPackage(
      (data) => {
        SubscriptionService.checkAvailability(
          accountId,
          data.id,
          (data2) => {
            if (data2) {
              setTrialPackage(data);
            }
          },
          (error2) => {}
        );
      },
      (error) => {}
    );
  }, []);

  const navigate = () => {
    const tmpTo = {
      pathname: `/subscriptions`,
      breadCrumbKey: `/subscriptions`,
    };
    history.push(tmpTo);
  };

  const showError = () => {
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {t("UPGRADE_MEMBERSHIP_PACKAGE")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              {t("FEATURE_NOT_ALLOWED")} {t("UPGRADE_PLAN")}
            </p>
            {trialPackage && (
              <p>
                {trialPackage.monthlySubscribeDuration !== -1 &&
                  t(
                    "Ücretsiz deneme paketini isterseniz hemen başlatabilirsiniz. " +
                      trialPackage.monthlySubscribeDuration +
                      " "
                  ) +
                    t("" + trialPackage.monthlySubscribeTimePeriod) +
                    " süreyle " +
                    trialPackage.name +
                    " paketinizi başlatmak için "}

                <Link
                  onClick={() => history.push("/trial")}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  underline="hover">
                  {t("hemen tıklayın.")}
                </Link>
              </p>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={navigate} color="primary">
            {t("MEMBERSHIP_OPTIONS")}
          </Button>
          <Button onClick={onClose} color="primary">
            {t("CLOSE")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return <>{open && showError()}</>;
}

FeatureNotAllowed.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
