import Grid from "@mui/material/Grid";
import RelationTypeAutoComplete from "components/autocomplete/RelationTypeAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import ThingClassHashtag from "components/hashtag/ThingClassHashtag";
import AllowSelectTypeSelect from "components/select/AllowSelectTypeSelect";
import AuthorizationTypeSelect from "components/select/AuthorizationTypeSelect";
import CustomerPermissionUpdateSelect from "components/select/CustomerPermissionUpdateSelect";
import StockSelectTypeSelect from "components/select/StockSelectTypeSelect";
import YesNoSelect from "components/select/YesNoSelect";
import ThingDescriptor from "features/accountDetails/thingDescriptor";
import React, { useEffect, useState } from "react";
import { FaInput } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountPreferencesEdit(props) {
  const { accountPreferences, thumbnail, onNewValues, accountSubscription } =
    props;
  const { t } = useTranslation("accountDetails", { i18n });
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const role = useSelector(getRole);

  const [values, setValues] = useState({
    ...accountPreferences,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    onNewValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeV = (prop) => (data) => {
    if (prop === "relationType") {
      setValues({ ...values, [prop]: data, relationTypeSource: data?.source });
      onNewValues({
        ...values,
        [prop]: data,
        relationTypeSource: data?.source,
      });
    } else {
      setValues({ ...values, [prop]: data });
      onNewValues({ ...values, [prop]: data });
    }
  };

  useEffect(() => {
    console.log("useEffect");
    onNewValues({ ...values });
  }, []);

  const isFreeRole = checkRole([ROLES.FREE], role);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <AuthorizationTypeSelect
          value={values.authorizationType}
          accountSubscription={accountSubscription}
          label="Yetki Modeli"
          onChange={handleChangeV("authorizationType")}
        />
      </Grid>
      <Grid item xs={12}>
        <ThingClassHashtag
          label={t("Etkileşim Noktası Tipi Veri Kaynakları")}
          accountId={accountId}
          value={values.thingClassDataSources}
          onChange={handleChangeV("thingClassDataSources")}
        />
      </Grid>
      <Grid item xs={12}>
        <YesNoSelect
          label={t("Servis/Operasyon boş kontrol listesi gönderebilme")}
          value={values.emptySolutionControlList}
          onChange={handleChangeV("emptySolutionControlList")}
        />
      </Grid>
      <Grid item xs={12}>
        <StockSelectTypeSelect
          label={t("Servis/Operasyon malzeme seçim yöntemi")}
          value={values.stockSelectType}
          onChange={handleChangeV("stockSelectType")}
        />
      </Grid>
      <Grid item xs={12}>
        <FaInput
          label={t("QR etiket yönlendirme adresi")}
          onChange={handleChange("qrUrl")}
          value={values.qrUrl}
          disabled={isFreeRole}
          helperText={
            "Etiketlere basılacak qr kodları burada belirteceğiniz adresi barındıracaktır."
          }
        />
      </Grid>
      <Grid item xs={12}>
        <YesNoSelect
          label={t("Etkileşim noktası silmeye izin ver")}
          value={values.deleteThing}
          onChange={handleChangeV("deleteThing")}
        />
      </Grid>
      <Grid item xs={12}>
        <YesNoSelect
          label={t("Bildirim silmeye izin ver")}
          value={values.deleteIssue}
          onChange={handleChangeV("deleteIssue")}
        />
      </Grid>
      <Grid item xs={12}>
        <ThingClassAutoComplete
          label={t("Varsayılan Etkileşim Noktası Tipi")}
          value={values.defaultThingClass}
          accountId={accountId}
          onChange={handleChangeV("defaultThingClass")}
        />
      </Grid>
      <Grid item xs={12}>
        <RelationTypeAutoComplete
          label={t("Varsayılan İlişki Tipi")}
          value={values.relationType}
          accountId={accountId}
          onChange={handleChangeV("relationType")}
        />
      </Grid>
      <Grid item xs={12}>
        <AllowSelectTypeSelect
          label={t("Biricik Etkileşim Noktası Tanımları")}
          value={values.uniqueThingIdentifier}
          onChange={handleChangeV("uniqueThingIdentifier")}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomerPermissionUpdateSelect
          label={t("KVKK ve İletişim İzinlerinin El İle Verilmesi")}
          value={values.customerPermissionUpdate}
          onChange={handleChangeV("customerPermissionUpdate")}
        />
      </Grid>
      <Grid item xs={12}>
        <ThingDescriptor
          value={values.thingDescriptor}
          onChange={handleChangeV("thingDescriptor")}
        ></ThingDescriptor>
      </Grid>
    </Grid>
  );
}
