import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  wrapperClass: {
    border: "1px solid #BFBFC0",
    borderRadius: 10,
  },
  editorClass: {},
  toolbarClass: { borderBottom: "1px solid #BFBFC0", borderRadius: 10 },
}));
