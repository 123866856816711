import { faCartPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Chip, Grid } from "@mui/material";
import { AddCircleOutline, Chat, CheckCircleOutline } from "@mui/icons-material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GetFieldByLanguage,
  getCurrentLangCode,
  getDateTimeWFormat,
  priceToString,
} from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SubscriptionPlan(props) {
  const { value, onSelect, period, activeSubscription, trial } = props;

  const classes = useStyles();
  const { t } = useTranslation("subscriptions", { i18n });
  const [lang, setLang] = useState(getCurrentLangCode());

  const campaignSelected = (campagin) => {
    onSelect(campagin);
  };

  const getSubscriptionEndDateStr = () => {
    if (activeSubscription.endDate != null)
      return getDateTimeWFormat(activeSubscription.endDate) + " tarihine kadar";
    else return "Zaman sınırı yok";
  };

  const renderIcon = (type) => {
    if (type === "PACKAGE_INCLUDE") return <CheckCircleOutline />;
    else return <AddCircleOutline />;
  };

  const renderContent = (item, campaign) => {
    const text = GetFieldByLanguage(item.content, lang);
    if (item.type === "THING_COUNT") {
      return text ? text.replace("?", campaign?.thingCount) : text;
    } else if (item.type === "USER_COUNT") {
      return text ? text.replace("?", campaign?.userCount) : text;
    } else if (item.type === "STORAGE_AREA") {
      return text
        ? text.replace("?", campaign?.diskSpace + " " + campaign?.diskUnit)
        : text;
    } else return text;
  };

  const renderPriceString = (price, currency) => {
    if (price && price > 0) return priceToString(price, currency);
    else return t("FREE");
  };

  const renderActionButton = (campaign) => {
    if (campaign.contactLink) {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<Chat />}
          onClick={() => {
            window.open(campaign.contactLink, "_blank").focus();
          }}
        >
          {t("CONTACT_US")}
        </Button>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<FontAwesomeIcon icon={faCartPlus} />}
          onClick={() => {
            campaignSelected(campaign);
          }}
        >
          {t("BUY")}
        </Button>
      );
    }
  };

  const renderPlan = (campaign) => {
    const active =
      activeSubscription && activeSubscription.salesPackage.id === campaign.id;

    return (
      <Grid container style={{ height: "100%" }}>
        <Grid item xs={12}>
          <Card key={campaign.id} className={classes.campaignCard}>
            <Grid container direction="row" alignItems="flex-start">
              {active && (
                <Grid item xs={12} style={{ position: "relative", padding: 5 }}>
                  <Chip
                    label={t("CURRENT_PLAN")}
                    color="primary"
                    size="small"
                  />
                </Grid>
              )}
              {!active && (
                <Grid
                  item
                  xs={12}
                  style={{ position: "relative", padding: 5, height: 35 }}
                ></Grid>
              )}
              <Grid item xs={12} className={classes.campaignCardHeader}>
                {campaign.name}
              </Grid>
              <Grid item xs={12} className={classes.campaignCardDescription}>
                {((period === "YEARLY" &&
                  campaign.priceYearly !== campaign.discountPriceYearly) ||
                  (period === "MONTHLY" &&
                    campaign.priceMonthly !==
                      campaign.discountPriceMonthly)) && (
                  <span className={classes.oldPrice}>
                    {renderPriceString(
                      period === "YEARLY"
                        ? campaign.priceYearly
                        : campaign.priceMonthly,
                      campaign.currency
                    )}
                  </span>
                )}

                <span className={classes.price}>
                  {renderPriceString(
                    period === "YEARLY"
                      ? campaign.discountPriceYearly
                      : campaign.discountPriceMonthly,
                    campaign.currency
                  )}
                </span>
                <span className={classes.description}>
                  {campaign.description}
                </span>
              </Grid>
              {campaign.saleable === true && (
                <Grid item xs={12} className={classes.campaignCardButton}>
                  {trial && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<FontAwesomeIcon icon={faStar} />}
                      onClick={() => {
                        campaignSelected(campaign);
                      }}
                    >
                      {t("Denemeye Başla")}
                    </Button>
                  )}
                  {!!!trial && !active && renderActionButton(campaign)}
                </Grid>
              )}
              {campaign.saleable !== true && (
                <Grid
                  item
                  xs={12}
                  className={classes.campaignCardButton}
                ></Grid>
              )}
              <Grid
                item
                xs={12}
                style={{ padding: 5, minHeight: 30, textAlign: "center" }}
              >
                {active && getSubscriptionEndDateStr()}
                {!active && " "}
              </Grid>
              <Grid item xs={12} style={{ minHeight: 400 }}>
                {campaign.salesPackageDetail &&
                  campaign.salesPackageDetail.map((item) => (
                    <Grid
                      container
                      direction="row"
                      justfiyContent="center"
                      style={{ paddingLeft: 10 }}
                    >
                      <Grid item xs={2}>
                        {renderIcon(item.type)}
                      </Grid>
                      <Grid item xs={10}>
                        {renderContent(item, campaign)}
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    );
  };

  return renderPlan(value);
}
