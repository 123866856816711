import { Box, Grid, Paper } from "@mui/material";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { CustomerService } from "services/CustomerService";
import { ReportService } from "services/report/reportService";
import i18n from "../../i18n";

export default function BeneficiaryNoteReply(props) {
  const { loginPath, appLogo } = props;
  const { id, accountId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("beneficiary", { i18n });
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  const [loading, setLoading] = useState(true);

  const [success, setSuccess] = useState(false);
  const [files, setFiles] = useState([]);

  let formRef = createRef();
  let recaptchaRef = useRef();
  const lang = getCurrentLang();

  const dispatch = useDispatch();

  const theme = useSelector(selectThemePrefer);

  useEffect(() => {
    ReportService.beneficiaryNote(
      id,
      accountId,
      getBeneficiaryNoteOnSuccess,
      (error) => {}
    );
  }, []);

  const getBeneficiaryNoteOnSuccess = (data) => {
    setLoading(true);
    setReply({
      id: data.id,
      accountId: data.accountId,
      note: null,
    });
  };

  const newReply = () => {
    return {
      id: id,
      accountId: accountId,
      note: null,
    };
  };
  const [reply, setReply] = useState(newReply());

  const handleChange = (prop) => (event) => {
    setReply({ ...reply, [prop]: event.target.value });
  };

  const postReply = () => {
    CustomerService.beneficiaryNoteReply(
      reply,
      files,
      (data) => {
        setSuccess(true);
      },
      (error) => {}
    );
  };

  const onFileChange = (data) => {
    if (data && data.length > 0) {
      setFiles([...files, ...data]);
    } else setFiles(data);
  };

  const renderDetails = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        //className={classes.image}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          component={Paper}
          elevation={6}
          style={{
            height: "100%",
            width: "100vw",
            //margin: "10px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <Grid
            item
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                width="250"
                height="92"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
            {!success && (
              <>
                <Grid item xs={12}>
                  <FaInput
                    faType="description"
                    required
                    value={reply.note}
                    label={t("Yanıtla")}
                    onChange={handleChange("note")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FaFileUpload
                    maxFileSize={20000000}
                    onChange={onFileChange}
                    filesLimit={5}
                    dropZoneBoxProps={{ style: { minHeight: "50px" } }}
                    // dropzoneText={() => {
                    //   return "Belgenin fotoğrafını yükleyin";
                    // }}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              lg={10}
              xs={12}
              style={{ textAlign: "center", marginTop: 50 }}
            >
              {!success && (
                <FaButton
                  style={{ width: "25%", marginRight: 5 }}
                  size="small"
                  color="primary"
                  faClick={() => postReply()}
                >
                  {t("Cevap Gönder")}
                </FaButton>
              )}
              {success && t("Cevabınız gönderildi.")}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return renderDetails();
}

BeneficiaryNoteReply.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
