import { Grid } from "@mui/material";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import UserGroupAutoComplete from "components/autocomplete/UserGroupAutoComplete";
import RoleSelect from "components/select/RoleSelect";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";

const SelectTargetDialog = forwardRef((props, ref) => {
  const { onSave, selectedMessageTemplateId } = props;
  const tableRef = useRef();
  const { t } = useTranslation(["messageTemplateTarget", "components"], {
    i18n,
  });

  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedUserGroup, setSelectedUserGroup] = useState();
  const [fieldType, setFieldType] = useState();

  useImperativeHandle(ref, () => ({
    openDialog(fieldType) {
      setIsDialogOpen(true);
      setFieldType(fieldType);
    },
  }));

  const save = () => {
    if (fieldType === "role")
      onSave(selectedMessageTemplateId, fieldType, selectedRole);
    else if (fieldType === "user")
      onSave(selectedMessageTemplateId, fieldType, selectedUser);
    else if (fieldType === "userGroup")
      onSave(selectedMessageTemplateId, fieldType, selectedUserGroup);
    setIsDialogOpen(false);
    setSelectedRole(null);
    setSelectedUser(null);
    setSelectedUserGroup(null);
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={
            (fieldType === "role"
              ? "Rol"
              : fieldType === "user"
              ? "Kullanıcı"
              : "Kullanıcı Grubu") + " seçiniz"
          }
          faOpen={isDialogOpen}
          faOnSubmit={save}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsDialogOpen(false);
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            {fieldType === "role" && (
              <RoleSelect
                required
                value={selectedRole}
                onChange={(data) => {
                  setSelectedRole(data);
                }}
              />
            )}
            {fieldType === "user" && (
              <UserAutoComplete
                value={selectedUser}
                accountId={accountId}
                onChange={(data) => {
                  setSelectedUser(data);
                }}
                label={t("USER")}
              />
            )}
            {fieldType === "userGroup" && (
              <UserGroupAutoComplete
                value={selectedUserGroup}
                accountId={accountId}
                onChange={(data) => {
                  setSelectedUserGroup(data);
                }}
                label={t("USER_GROUP")}
              />
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default SelectTargetDialog;
