import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ListIcon from "@mui/icons-material/List";
import TodayIcon from "@mui/icons-material/Today";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAutoComplete from "components/autocomplete/UserAutoComplete";
import ActivePassiveButtonGroup from "components/buttonGroup/ActivePassiveButtonGroup";
import ReservationOwnerButtonGroup from "components/buttonGroup/ReservationOwnerButtonGroup";
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RESERVATION } from "services/faApi";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CalendarView from "./CalendarView";
import ReservationEdit from "./ReservationEdit";

export default function Reservation() {
  const tableRef = useRef();
  const { t } = useTranslation("reservation", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const userInfo = useSelector(getUserInfo);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();
  const labelClasses = labelStyles();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [showCalendarView, setShowCalendarView] = useState(false);

  const [selectedReservation, setSelectedReservation] = useState();

  const columns = [
    {
      field: "user",
      label: t("USER"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "20%",
      valueFormatter: (val) => {
        return <ThingDetail value={val} />;
      },
    },
    {
      field: "reservationDateStart",
      label: t("RESERVATION_DATE_START"),
      align: "center",
      width: "15%",
      type: "dateTime",
    },
    {
      field: "reservationDateEnd",
      label: t("RESERVATION_DATE_END"),
      align: "center",
      width: "15%",
      type: "dateTime",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "10%",
      valueFormatter: (val) => (val === "PASSIVE" ? <CloseIcon /> : null),
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      userId: null,
      thingId: null,
      status: null,
      description: null,
      reservationDateStart: null,
      reservationDateEnd: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    if (tableRef && tableRef.current) tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    if (tableRef && tableRef.current)
      tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const onDoubleClick = (row) => {
    dialogRef.current.openDialog(row);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <ReservationEdit ref={dialogRef} onSave={refresh}></ReservationEdit>
        <Grid container spacing={1}>
          <Grid item lg={3} xs={4}>
            <ActivePassiveButtonGroup
              value={searchForm.status}
              onChange={(data) => {
                const temp = { ...searchForm, status: data };
                setSearchForm(temp);
                if (tableRef && tableRef.current) tableRef.current.search(temp);
              }}
              // style={{ height: 60 }}
            />
          </Grid>
          <Grid item lg={3} xs={5}>
            <ReservationOwnerButtonGroup
              value={searchForm.userId}
              userId={userInfo.id}
              onChange={(data) => {
                const temp = { ...searchForm, userId: data };
                setSearchForm(temp);
                if (tableRef && tableRef.current) tableRef.current.search(temp);
              }}
              // style={{ height: 60 }}
            />
          </Grid>
          <Grid item lg={3} xs={0}></Grid>
          <Grid item lg={3} xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setShowCalendarView(
                  (currentShowCalendarView) => !currentShowCalendarView
                );
              }}
              startIcon={showCalendarView ? <ListIcon /> : <TodayIcon />}
              data-fa-button="ADD_POLICY"
            >
              {showCalendarView ? t("LIST_VIEW") : t("CALENDAR_VIEW")}
            </FaButton>
          </Grid>
        </Grid>
        {!showCalendarView && (
          <>
            <ThingsDataTable
              searchUrl={RESERVATION.search}
              searchForm={searchForm}
              columns={columns}
              ref={tableRef}
              showHeaderText={false}
              faOnDoubleClick={onDoubleClick}
            />
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item xs={2} className={"thingsDataTableButtons"}>
                <FaButton
                  variant="contained"
                  disabled={loading}
                  color="primary"
                  size="medium"
                  faClick={() => {
                    dialogRef.current.openDialog();
                  }}
                  startIcon={<AddIcon />}
                  data-fa-button="ADD_POLICY"
                >
                  {t("ADD")}
                </FaButton>
              </Grid>
              <Grid item xs={4}>
                <div>{searchInfo}</div>
              </Grid>
              <Grid item xs={6}>
                <FaSearch
                  onSearch={searchSubmit}
                  onClear={searchClear}
                  onSummarySearch={searchSummary}
                  setSearchInfo={setSearchInfo}
                  faSummary={searchForm?.summary}
                  faClassName="appSearchBar"
                >
                  <ThingAutoComplete
                    accountId={accountId}
                    value={searchForm.thing}
                    onChange={(data) => {
                      handleChangeGeneric(
                        setSearchForm,
                        searchForm,
                        "thing",
                        data,
                        "thingId",
                        data?.id
                      );
                    }}
                  />
                  <UserAutoComplete
                    value={searchForm?.user}
                    accountId={accountId}
                    onChange={(data) => {
                      handleChangeGeneric(
                        setSearchForm,
                        searchForm,
                        "userId",
                        data?.id
                      );
                    }}
                    label={t("USER")}
                    required={true}
                  />
                  <FaInput
                    label={t("DESCRIPTION")}
                    faType="description"
                    value={searchForm.descripton}
                    inputProps={{ maxLength: 255 }}
                    onChange={(event) => {
                      handleChangeGeneric(
                        setSearchForm,
                        searchForm,
                        "description",
                        event.target.value
                      );
                    }}
                  />
                </FaSearch>
              </Grid>
            </Grid>
          </>
        )}
        {showCalendarView && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <CalendarView searchForm={searchForm}></CalendarView>
            </Grid>
          </Grid>
        )}
      </Suspense>
    </>
  );
}
