import Grid from "@mui/material/Grid";
import BeneficiaryNote from "components/beneficiaryNote";
import AdditionalFieldsCard from "features/additionalFields/AdditionalFieldsCard";
import React, { useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getAccountId } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SuggestionService } from "services/SuggestionService";
import i18n from "../../i18n";
import { useStyles } from "./style";
import SuggestionDetailCard from "./SuggestionDetailCard";
import SuggestionPhotos from "./SuggestionPhotos";

export default function SuggestionDetail() {
  const { t } = useTranslation("suggestionDetail", { i18n });
  const { id } = useParams();
  const accountId = useSelector(getAccountId);
  const classes = useStyles();
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [solutionList, setSolutionList] = useState([]);
  const [suggestion, setSuggestion] = useState({});
  const [issue, setIssue] = useState({
    issueFolder: { name: "" },
    hashtag: "",
    issueCode: "",
    issueClass: { className: "" },
    tasks: [],
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    SuggestionService.findById(
      id,
      (data) => {
        setSuggestion(data);
        refreshImages();
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const refreshImages = () => {
    SuggestionService.downloadThumbnails(
      id,
      (data) => {
        setImages(data);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={6} lg={6}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <SuggestionDetailCard suggestion={suggestion} />
            </Grid>

            <Grid item xs={12}>
              <AdditionalFieldsCard
                classId={suggestion?.suggestionClass?.id}
                className="SUGGESTION_CLASS"
                isEditable={false}
                objectId={suggestion?.id}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={6}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <SuggestionPhotos
                images={images}
                setImages={setImages}
                suggestionId={suggestion.id}
              />
            </Grid>

            {suggestion && suggestion.id && (
              <Grid item xs={12}>
                <BeneficiaryNote
                  suggestion={suggestion}
                  title={t("Mesajlaşma Geçmişi")}
                  sms={
                    suggestion && suggestion.phone && suggestion.phone !== ""
                      ? true
                      : false
                  }
                  email={
                    suggestion && suggestion.email && suggestion.email !== ""
                      ? true
                      : false
                  }
                  push={false}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
