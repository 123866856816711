import { Badge, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import CheckIcon from "@mui/icons-material/Check";
import ThingDetail from "components/detail/ThingDetail";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDateWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { ThingService } from "../../services/thing/thingService";
import { useStyles } from "./ThingDetailCardStyle";

export default function ThingMapDetail(props) {
  const { thingId } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();
  const labelClasses = labelStyles();

  const [thing, setThing] = useState({});

  useEffect(() => {
    if (thingId) {
      ThingService.findById(
        thingId,
        (data) => {
          setThing(data);
          console.log({ data });
        },
        (error) => {}
      );
    }
  }, [thingId]);

  const renderOpenIssueCount = () => {
    if (thing?.openIssueCount && thing?.openIssueCount > 0)
      return (
        <Badge
          badgeContent={thing?.openIssueCount}
          color="secondary"
          style={{ paddingLeft: 12 }}
        ></Badge>
      );
    return <CheckIcon />;
  };

  return (
    <>
      <Card className="fai-temelBilgiler">
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Grid item container spacing={1}>
                <Grid item xs={12} className={classes.ThingDetailLabelHeader}>
                  {thing && thing.id && <ThingDetail value={thing} />}
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Nesne Kimlik Numarası")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.thid}
                  </Typography>
                </Grid>

                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("CLASS_NAME")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.thingClass?.className}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("THING_CODE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.thingCode}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("INTERACTIVE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.interactive === true ? t("EVET") : t("HAYIR")}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("VENDOR")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.vendor?.name}
                  </Typography>
                </Grid>

                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("HASHTAG")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.hashtag}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("DESCRIPTION")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.description}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("PUBLIC_IDENTIFIER")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {thing?.publicIdentifier}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={labelClasses.LabelLastRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("SERVICE_START_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateWFormat(thing?.serviceStartDate)}
                  </Typography>
                </Grid>
                <Grid item xs={6} className={classes.ThingDetailLabelHeader}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("SERVICE_STATUS")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {renderOpenIssueCount()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
