import { IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import EditIcon from "@mui/icons-material/Edit";
import ThingsDataTable from "components/thingsDataTable";
import CustomerMessageTemplateStatusDecorator from "components/ui/CustomerMessageTemplateStatusDecorator";
import CustomerMessageEdit from "features/customerMessage/CustomerMessageEdit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CUSTOMER_MESSAGE } from "services/faApi";
import { getDateTimeWFormat, qsParse } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function SurveySendMessage(props) {
  const { survey } = props;
  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });
  const dispatch = useDispatch();
  const dialogRef = useRef();
  let history = useHistory();
  const accountId = localStorage.accountId;
  const tableRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [loading, setLoading] = useState(false);
  const [surveyDTO, setSurveyDTO] = useState({});

  const columns = [
    {
      field: "plannedSendDate",
      label: t("PLANNED_SEND_DATE"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "30%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "20%",
    },
    {
      field: "channel",
      label: t("CHANNEL"),
      align: "center",
      width: "15%",
      valueFormatter: (val) => t(val),
    },
    {
      field: "status",
      label: t("STATUS"),
      align: "center",
      width: "15%",
      valueFormatter: (val) => (
        <CustomerMessageTemplateStatusDecorator value={val} />
      ),
    },
  ];

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      name: null,
      description: null,
      channel: null,
      status: null,
      messageType: "SURVEY",
      surveyId: survey.id,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  useEffect(() => {
    setSurveyDTO(survey);
  }, [survey]);

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `/customerMessage/detail/${row.id}`,
      breadCrumbKey: `/customerMessage/detail/:id`,
    };
    history.push(tmpTo);
  };

  const onSave = (data) => {
    console.log(data);
    const tmpTo = {
      pathname: `/customerMessage/detail/${data.id}`,
      breadCrumbKey: `/customerMessage/detail/:id`,
    };
    history.push(tmpTo);
  };

  const editDialogRender = () => {
    return (
      <CustomerMessageEdit
        ref={dialogRef}
        onSave={onSave}
        type="SURVEY"
        survey={survey}
      ></CustomerMessageEdit>
    );
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {editDialogRender()}
      <Card>
        <CardHeader
          title={t("Anket Bağlantısı Gönder")}
          data-fa-section="SURVEY_INFO"
          action={
            <IconButton onClick={() => dialogRef.current.openDialog()} size="large">
              <EditIcon />
            </IconButton>
          }
        />
        <ThingsDataTable
          searchUrl={CUSTOMER_MESSAGE.search}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          queryParams={{
            page: 0,
            size: 10,
            sort: "id",
            isAsc: false,
          }}
          faOnDoubleClick={onDoubleClick}
        />
      </Card>
    </Suspense>
  </>;
}
