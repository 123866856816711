import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import { Check, Remove } from "@mui/icons-material";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef } from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { CUSTOMER } from "services/faApi";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerHistory(props) {
  const { customer, customerRefresh } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();

  const { t } = useTranslation("customer", { i18n });
  const mapRef = useRef();

  useEffect(() => {
    tableRef.current.refreshTable();
  }, [customerRefresh]);

  const columns = [
    {
      field: "historyType",
      label: t("İşlem"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        return t("HISTORY_TYPE_" + value);
      },
    },
    {
      field: "operationDate",
      label: t("Tarih"),
      align: "center",
      width: "30%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "contactPermission",
      label: t("CONTACT_PERMISSION"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        if (value === true) return <Check />;
        else return <Remove />;
      },
    },
    {
      field: "gdprPermission",
      label: t("GPDR_PERMISSION"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        if (value === true) return <Check />;
        else return <Remove />;
      },
    },
  ];

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-customerInteractions">
        <CardHeader title={t("İzin Geçmişi")} data-fa-section="İzin Geçmişi" />
        {customer && customer.id > 0 && (
          <ThingsDataTable
            dataUrl={CUSTOMER.findHistory + "?id=" + customer.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "operationDate",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("İzin Geçmişi")}
            //showHeader={false}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
