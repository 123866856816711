import makeStyles from "@mui/styles/makeStyles";

export const labelStyles = makeStyles((theme) => ({
  // ThingDetailImage: {
  //   width: "100%",
  //   objectFit: "scale-down",
  // },
  // ThingDetailLabelHeader: {
  //   fontSize: "12px",
  //   lineHeight: 1.42857,
  //   //borderTop: "1px solid #ddd",
  //   borderBottom: "1px solid #cfdae0",
  //   fontFamily: "Open Sans,Helvetica,Arial,sans-serif",
  //   fontWeight: 400,
  //   color: "#9A9A9A",
  // },

  LabelRow: {
    borderBottom: "1px solid #cfdae0",
  },

  LabelLastRow: {},

  LabelHeader: {
    fontSize: "13px",
    fontWeight: 400,
    color: "#9A9A9A",
  },
  LabelValue: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#151B26",
    minHeight: "10px",
  },
  PanelHeader: {
    fontSize: "11px",
    fontWeight: 400,
    color: "#9A9A9A",
  },

  SimpleLink: {
    textDecoration: "underline",
    fontSize: 14,
    cursor: "pointer",
  },
}));
