import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionMenuMatrix(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  const getCellValue = (row, column) => {
    let value = null;

    let columnCount = question.options.filter(
      (item) => item.optionType === "COLUMN"
    ).length;

    if (answer && answer.selectedOptions && answer.selectedOptions.length > 0)
      value =
        answer.selectedOptions[
          (row.orderNo - 1) * columnCount + (column.orderNo - 1)
        ];

    console.log("getCellValue", value);

    if (value) return "" + value;

    return null;
  };

  return <>
    <Grid
      item
      container
      alignItems="center"
      spacing={1}
      style={{ marginTop: "10px" }}
    >
      <Grid item xs={12}>
        <FormControl
          variant="standard"
          component="fieldset"
          style={{ width: "100%" }}
          required={question.notEmpty}>
          <Grid container item row xs={12}>
            <Grid item xs={12}>
              <FormLabel component="legend">
                {question.orderNo}.{" "}
                {GetFieldByLanguage(question.multiLangName, currentLang)}
              </FormLabel>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText>
                {GetFieldByLanguage(question.multiLangHint, currentLang)}
              </FormHelperText>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {question.options
                  .filter((item) => item.optionType === "COLUMN")
                  .map((item, index) => {
                    return (
                      <TableCell key={index}>
                        {GetFieldByLanguage(item.multiLangName, currentLang)}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {question.options
                .filter((item) => item.optionType === "ROW")
                .map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {GetFieldByLanguage(row.multiLangName, currentLang)}
                      </TableCell>
                      {question.options
                        .filter((item) => item.optionType === "COLUMN")
                        .map((column, index) => {
                          return (
                            <TableCell key={index}>
                              <Select
                                variant="standard"
                                value={getCellValue(row, column) || ""}
                                disabled={readOnly}
                                onChange={(event) => {
                                  if (event.target.value) {
                                    if (answer.selectedOptions) {
                                      let tmp = [...answer.selectedOptions];
                                      let columnCount =
                                        question.options.filter(
                                          (item) =>
                                            item.optionType === "COLUMN"
                                        ).length;

                                      tmp[
                                        (row.orderNo - 1) * columnCount +
                                          (column.orderNo - 1)
                                      ] = event.target.value;
                                      onChange(
                                        question,
                                        tmp,
                                        "selectedOptions"
                                      );
                                    } else {
                                      let rowCount = question.options.filter(
                                        (item) => item.optionType === "ROW"
                                      ).length;
                                      let columnCount =
                                        question.options.filter(
                                          (item) =>
                                            item.optionType === "COLUMN"
                                        ).length;
                                      let tmp = new Array(
                                        rowCount * columnCount
                                      ).fill(null);
                                      tmp[
                                        (row.orderNo - 1) * columnCount +
                                          (column.orderNo - 1)
                                      ] = event.target.value;
                                      onChange(
                                        question,
                                        tmp,
                                        "selectedOptions"
                                      );
                                    }
                                  } else {
                                    let tmp = [...answer.selectedOptions];
                                    let columnCount = question.options.filter(
                                      (item) => item.optionType === "COLUMN"
                                    ).length;
                                    tmp[
                                      (row.orderNo - 1) * columnCount +
                                        (column.orderNo - 1)
                                    ] = null;
                                    onChange(
                                      question,
                                      tmp,
                                      "selectedOptions"
                                    );
                                  }
                                }}>
                                {question.options
                                  .filter(
                                    (item) => item.optionType === "MENU"
                                  )
                                  .map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={"" + item.id}
                                      >
                                        {GetFieldByLanguage(
                                          item.multiLangName,
                                          currentLang
                                        )}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  </>;
}

SurveyQuestionMenuMatrix.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
