import {
  CloudDownload,
  FavoriteBorder,
  Link,
  LocationOn,
  NotificationsNone,
} from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { Box, Button } from "@mui/material";
import ThingsSurveyIcon from "components/icons/ThingsSurveyIcon";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { FaCopyright } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import { getDateWFormat } from "services/utils";
import i18n from "../../../i18n";
import AnnouncementDetail from "./AnnouncementDetail";
import { useStyles } from "./LandingTemplate9Style";
import PublicDocumentDownload from "./PublicDocumentDownload";
import PublicPhotoBrowser from "./PublicPhotoBrowser";
import LikeIcon from "./icons/LikeIcon";
import ReportIcon from "./icons/ReportIcon";
import SuggestionIcon from "./icons/SuggestionIcon";
export default function LandingTemplate9(props) {
  const { publicThing, onLinkClick, appLogo, miniAppLogo } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [pageMode, setPageMode] = useState("main");
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [documentParam, setDocumentParam] = useState(null);

  const navigateReportIssue = () => {
    let tmpTo = {
      pathname: `/report/issue/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportLike = () => {
    let tmpTo = {
      pathname: `/report/like/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportSuggestion = () => {
    let tmpTo = {
      pathname: `/report/suggestion/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportUserContent = () => {
    let tmpTo = {
      pathname: `/report/userContent/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCustomer = () => {
    let tmpTo = {
      pathname: `/report/customer/tid=${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateSurvey = (survey) => {
    let tmpTo = {
      pathname: `/report/survey/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCampaign = (survey) => {
    let tmpTo = {
      pathname: `/report/registerCampaign/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  // const navigateAnnouncement = (announcement) => {
  //   let tmpTo = {
  //     pathname: `/report/announcement/${announcement.id}/${publicThing.id}/${publicThing.accountId}`,
  //     state: {
  //       publicThing: {
  //         ...publicThing,
  //       },
  //     },
  //   };
  //   history.push(tmpTo);
  // };

  const renderMain = () => {
    return (
      <>
        {publicThing &&
          publicThing.interactionPageSettings.ipCampaign &&
          publicThing.campaignList &&
          publicThing.campaignList.length > 0 && (
            <div className={classes.campaignsPanel}>
              {publicThing.campaignList.map((item, index) => {
                return (
                  <div
                    className={classes.campaign}
                    onClick={() => {
                      navigateCampaign(item);
                    }}
                  >
                    {item.thumbnail && (
                      <img
                        src={"data:image/jpeg;base64," + item.thumbnail}
                        alt="campaignLogo"
                        className={classes.campaignLogo}
                      />
                    )}
                    {!item.thumbnail && (
                      <img
                        src={appLogo}
                        alt="campaignLogo"
                        className={classes.campaignLogo}
                      />
                    )}

                    <div className={classes.campaignTexts}>
                      <div className={classes.campaignTitle}>
                        {item.multiLangName?.["tr"]}
                      </div>
                      <div className={classes.campaignDate}>
                        {`${getDateWFormat(
                          item.registerStartDate
                        )} - ${getDateWFormat(item.registerEndDate)}`}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
      </>
    );
  };

  const getDocumentsAndLinksCount = () => {
    let docs =
      publicThing?.documentList && publicThing?.documentList.length > 0
        ? publicThing?.documentList.length
        : 0;
    let links =
      publicThing?.thingLinkList && publicThing?.thingLinkList.length > 0
        ? publicThing?.thingLinkList.length
        : 0;

    return docs + links;
  };

  const renderDetail = () => {
    return <></>;
  };

  const surveyName = (item) => {
    let langs = item?.languages;
    let name = "";
    langs.map((lang, index) => {
      if (item?.multiLangName?.[lang.toLowerCase()]) {
        if (name.length > 0) {
          name = name.concat(" / ");
        }
        name = name.concat(item?.multiLangName?.[lang.toLowerCase()]);
      }
    });
    if (name && name.length > 0) return name;
    else return item.name;
  };

  const renderHeader = () => {
    return (
      <div className={classes.banner}>
        <div>
          {publicThing.logo && (
            <img
              src={"data:image/jpeg;base64," + publicThing.logo}
              alt="logo"
              className={classes.appLogo}
            />
          )}

          {!publicThing.logo && (
            <img src={miniAppLogo} alt="logo" className={classes.appLogo} />
          )}

          <div className={classes.bannerAccount}>{publicThing.accountName}</div>

          {publicThing.interactionPageSettings.ipCustomer && (
            <Button
              className={classes.customerButton}
              onClick={() => {
                navigateCustomer();
              }}
            >
              <FavoriteBorder className={classes.actionButtonIcon} />
              ÜYE OL
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <Box className={classes.root}>
      {renderHeader()}

      <div style={{ zIndex: 1 }}>
        {publicThing.interactionPageSettings.ipImage && (
          <div className={classes.photosDivNoMargin}>
            <PublicPhotoBrowser publicThing={publicThing} />
          </div>
        )}

        <div className={classes.bannerThing}>{publicThing.thingDesc}</div>

        <div className={classes.actionButtons2Div}>
          {publicThing.interactionPageSettings.ipIssue && (
            <div style={{ flex: 1 }}>
              <Button
                className={classes.issueButton}
                onClick={navigateReportIssue}
              >
                <div>
                  <ReportIcon className={classes.bottomPanelIcon} />
                  <div>SORUN BİLDİR</div>
                </div>
              </Button>
            </div>
          )}
          {publicThing.interactionPageSettings.ipLike && (
            <div style={{ flex: 1, paddingLeft: 20 }}>
              <Button
                className={classes.likeButton}
                onClick={navigateReportLike}
              >
                <div>
                  <LikeIcon className={classes.bottomPanelIcon} />
                  <div>BEĞENDİM</div>
                </div>
              </Button>
            </div>
          )}
        </div>
        {publicThing.interactionPageSettings.ipSuggestion && (
          <div className={classes.actionButtons2Div} style={{ height: 45 }}>
            <Button
              className={classes.suggestionButton}
              onClick={navigateReportSuggestion}
            >
              <SuggestionIcon className={classes.bottomPanelIcon} />
              ÖNERİM VAR
            </Button>
          </div>
        )}
        {publicThing.interactionPageSettings.ipUserContent && (
          <div className={classes.actionButtons2Div} style={{ height: 45 }}>
            <Button
              className={classes.suggestionButton}
              onClick={navigateReportUserContent}
            >
              <BackupOutlinedIcon style={{ fontSize: 36, marginRight: 7 }} />
              İÇERİK EKLE
            </Button>
          </div>
        )}
        {/* {publicThing &&
          (publicThing?.announcementList?.length > 0 ||
            publicThing?.surveyList?.length > 0 ||
            publicThing?.documentList?.length > 0 ||
            publicThing?.thingLinkList?.length > 0) && (
            <div className={classes.bannerThing}>{"Öne Çıkanlar"}</div>
          )} */}

        {pageMode === "main" && renderMain()}

        {selectedAnnouncement && (
          <AnnouncementDetail
            announcement={selectedAnnouncement}
            onClose={() => {
              setSelectedAnnouncement(null);
            }}
          />
        )}
        <div className={classes.midPanel}>
          {publicThing &&
            publicThing.interactionPageSettings.ipLocation &&
            publicThing.gpsLocation &&
            publicThing.gpsLocation.length > 5 && (
              <Button
                className={classes.surveyButton}
                onClick={() => {
                  window
                    .open(ReportService.getLocationLink(publicThing), "_blank")
                    .focus();
                }}
              >
                <LocationOn className={classes.actionButtonIcon} />
                {t("LOCATION")}
              </Button>
            )}

          {publicThing &&
            publicThing.interactionPageSettings.ipAnnouncement &&
            publicThing.announcementList &&
            publicThing.announcementList.length > 0 &&
            publicThing.announcementList.map((item, index) => {
              return (
                <div>
                  <Button
                    className={classes.surveyButton}
                    onClick={() => {
                      setSelectedAnnouncement(item);
                    }}
                  >
                    <NotificationsNone className={classes.actionButtonIcon} />
                    {item.subject}
                  </Button>
                </div>
                // <div style={{ marginTop: 20 }}>
                //   <div className={classes.campaign}>
                //     <div className={classes.campaignHeader}>
                //       <div className={classes.campaignIcon}>
                //         <NotificationsNone />
                //       </div>

                //       <div className={classes.campaignTitle}>{item.subject}</div>
                //       <div className={classes.campaignDate}>
                //         {`${getDateWFormat(item.startDate)}`}
                //       </div>
                //     </div>
                //     <div className={classes.campaignTexts}>
                //       <div className={classes.campaignContentTexts}>
                //         {item.content}
                //       </div>
                //     </div>
                //   </div>
                // </div>
              );
            })}

          {publicThing &&
            publicThing.interactionPageSettings.ipSurvey &&
            publicThing.surveyList &&
            publicThing.surveyList.length > 0 &&
            publicThing.surveyList.map((item, index) => {
              return (
                <div>
                  <Button
                    className={classes.surveyButton}
                    onClick={() => {
                      navigateSurvey(item);
                    }}
                  >
                    <ThingsSurveyIcon className={classes.actionButtonIcon} />
                    {surveyName(item)}
                  </Button>
                </div>
              );
            })}

          {publicThing &&
            publicThing.interactionPageSettings.ipDocument &&
            publicThing.documentList &&
            publicThing.documentList.length > 0 &&
            publicThing.documentList.map((item, index) => {
              return (
                <div>
                  <Button
                    className={classes.documentButton}
                    onClick={() => {
                      setDocumentParam(item);
                    }}
                  >
                    <CloudDownload className={classes.actionButtonIcon} />
                    {item.name ?? item.fileName}
                  </Button>
                </div>
              );
            })}
          {publicThing &&
            publicThing.interactionPageSettings.ipDocument &&
            publicThing.documentList &&
            publicThing.documentList.length > 0 && (
              <div>
                <PublicDocumentDownload
                  value={documentParam}
                  publicThing={publicThing}
                  onClose={() => {
                    setDocumentParam(null);
                  }}
                />
              </div>
            )}

          {publicThing &&
            publicThing.interactionPageSettings.ipLink &&
            publicThing.thingLinkList &&
            publicThing.thingLinkList.length > 0 &&
            publicThing.thingLinkList.map((item, index) => {
              return (
                <div>
                  <Button
                    className={classes.linkButton}
                    onClick={() => {
                      window.open(item.url, "_blank").focus();
                      onLinkClick(item);
                    }}
                  >
                    <Link className={classes.actionButtonIcon} />
                    {item.description ?? item.url}
                  </Button>
                </div>
              );
            })}
        </div>

        {pageMode === "detail" && renderDetail()}
      </div>

      <div style={{ height: 100 }}></div>

      <div className={classes.footer}>
        <img
          src={process.env.PUBLIC_URL + "/assets/things.png"}
          alt="logo"
          className={classes.footerLogo}
        />
        <Box className={classes.copyright}>
          <FaCopyright style={{ fontSize: 10 }} />
        </Box>
      </div>
    </Box>
  );
}

LandingTemplate9.propTypes = {
  loginPath: PropTypes.string,
  onLinkClick: PropTypes.any,
  appLogo: PropTypes.any,
  miniAppLogo: PropTypes.any,
};
