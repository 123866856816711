import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ThingDetail from "components/detail/ThingDetail";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const TopInteractionsList = forwardRef((props, ref) => {
  const { showThings, title } = props;
  const { t } = useTranslation(["customer", "components"], { i18n });

  const labelClasses = labelStyles();

  const [topList, setTopList] = useState(null);

  const [masterSearchForm, setMasterSearchForm] = useState(null);

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        ThingStatisticService.getTopCounts(
          { ...searchForm, limit: 4 },
          getTopCountsOnSuccess,
          (error) => {}
        );
      }
    },
  }));

  const getTopCountsOnSuccess = (data) => {
    setTopList(data);
  };

  const renderThing = (row) => {
    if (row.thumbnail != null)
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="thing"
            src={`data:image/png;base64, ${row.thumbnail}`}
            style={{
              width: "16px",
              height: "16px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          />{" "}
          <Typography className={labelClasses.LabelValue}>
            {row.identifier}
          </Typography>
        </div>
      );
    else
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            variant="square"
            style={{
              width: "16px",
              height: "16px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          >
            {" "}
            {row.identifier.charAt(0)}
          </Avatar>{" "}
          <Typography className={labelClasses.LabelValue}>
            {row.identifier}
          </Typography>
        </div>
      );
  };

  return (
    topList &&
    topList.length > 0 && (
      <Grid container spacing={2} style={{ overflowY: "auto" }}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Card data-fa-section="TOP_INTERACTIONS">
                {title && (
                  <CardHeader title={t("TOP_INTERACTIONS")}></CardHeader>
                )}
                <CardContent>
                  {topList && (
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={3}>
                              {!!!title && (
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("TOP_INTERACTIONS")}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {topList.map((item, index) => (
                            <TableRow key={index}>
                              {showThings && (
                                <TableCell component="th" scope="row">
                                  {/* {renderThing(item)} */}
                                  <ThingDetail
                                    value={{ ...item, id: item.thingId }}
                                    maxWidth="sm"
                                  />
                                </TableCell>
                              )}
                              <TableCell>
                                <Typography className={labelClasses.LabelValue}>
                                  {item.operationDetail}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={labelClasses.LabelValue}>
                                  {t(
                                    "CUSTOMER_INTERACTION_OPERATION." +
                                      item.operation,
                                    {
                                      ns: "components",
                                    }
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={labelClasses.LabelValue}>
                                  {item.count}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  );
});
export default TopInteractionsList;
