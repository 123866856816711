import React from "react";
import { FaAutoComplete } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { STOCK } from "../../services/faApi";

export default function StockAutoComplete(props) {
  const { value, accountId, onChange, required } = props;

  const { t } = useTranslation("components", { i18n });

  return (
    <FaAutoComplete
      faType="async"
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      sort="name"
      faFilterProp="query"
      faInfinite
      pageSize={100}
      getOptionLabel={(option) => (option.name ? option.name : "")}
      requestUrl={STOCK.findStock + "?accountId=" + accountId}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      label={t("STOCK")}
      required={required}
      renderOption={(props, option, { inputValue }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            {option.name}
            <small style={{ paddingLeft: 10 }}>{option.code}</small>
          </li>
        );
      }}
    />
  );
}
