import { faSitemap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { IssueClassService } from "../../services/issueClass/IssueClassService";
import { useStyles } from "./style";

export default function IssueClassDetail() {
  const fileInputRef = useRef();
  const classes = useStyles();
  const tableRef = useRef();
  const { t } = useTranslation("issueClass", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [issueClass, setIssueClass] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    IssueClassService.findById(id, findByIdOnSuccess, findByIdOnError);
  };

  const findByIdOnSuccess = (data) => {
    setIssueClass(data);
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onIssueClassInfoUpdate = (newData) => {
    setIssueClass(newData);
    showAlert("Etkileşim noktası bilgileri güncellendi", "success");
  };

  const editButtonClicked = () => {
    setIsModalOpen(true);
  };

  const saveIssueClass = () => {
    setLoading(true);
    IssueClassService.save(
      {
        ...issueClass,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!issueClass.id ? t("SAVED") : t("EDITED"), "success");
    setIsModalOpen(false);
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setIssueClass({ ...issueClass, [prop]: event.target.value });
  };

  const handleUpload = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      IssueClassService.upload(
        issueClass.id,
        event.target.files[0],
        uploadOnSuccess,
        uploadOnError
      );
    }
  };

  const uploadOnSuccess = (data) => {
    window.location.reload();
  };

  const uploadOnError = (data) => {};

  const deleteImage = () => {
    IssueClassService.deleteFile(
      issueClass.id,
      deleteImageOnSuccess,
      deleteImageOnError
    );
  };

  const deleteImageOnSuccess = (data) => {
    setIssueClass({ ...issueClass, thumbnail: null });
  };

  const deleteImageOnError = (data) => {};

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Sorun Tipi Güncelle"}
        faOpen={isModalOpen}
        faOnSubmit={saveIssueClass}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <ThingClassAutoComplete
              accountId={accountId}
              value={issueClass.parent}
              onChange={(data) => {
                setIssueClass({
                  ...issueClass,
                  parent: data,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={t("GENERIC_CLASSNAME")}
              onChange={handleChange("className")}
              value={issueClass.className || ""}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {renderDialog()}
      <div>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    action={
                      <IconButton aria-label="settings" onClick={editButtonClicked} size="large">
                        <EditIcon />
                      </IconButton>
                    }
                    title="Sorun Tipi Bilgileri"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={3}>
                        <div className={classes.image}>
                          <input
                            onChange={handleUpload}
                            ref={fileInputRef}
                            type="file"
                            hidden
                          />

                          {!issueClass.thumbnail && (
                            <Button
                              onClick={() => {
                                fileInputRef.current.click();
                              }}
                              startIcon={
                                <Avatar
                                  style={{
                                    width: 200,
                                    height: 200,
                                  }}
                                  variant="square"
                                >
                                  <FontAwesomeIcon icon={faSitemap} />
                                </Avatar>
                              }
                              title="Değiştirmek için tıkla"
                            ></Button>
                          )}
                          {issueClass.thumbnail && (
                            <>
                              <Button
                                onClick={() => {
                                  fileInputRef.current.click();
                                }}
                                startIcon={
                                  <img
                                    src={`data:image/png;base64, ${issueClass.thumbnail}`}
                                    style={{ width: "200px" }}
                                  />
                                }
                                title="Değiştirmek için tıkla"
                              ></Button>
                              <Button
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  deleteImage();
                                }}
                                fullWidth={false}
                                className={classes.imageDeleteButtom}
                                title={t("Görseli Kaldır")}
                              ></Button>
                            </>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid
                          item
                          container
                          spacing={1}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Grid item xs={4}>
                            <Typography variant="caption">
                              {t("GENERIC_CLASSNAME")}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography>{issueClass.className}</Typography>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="caption">
                              {t("PARENT")}
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography>
                              {issueClass?.parent?.className}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Suspense>
  </>;
}
