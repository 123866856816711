import { IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Refresh } from "@mui/icons-material";
import Adsense from "components/adsense/Adsense";
import RefreshPeriodButton from "components/buttons/RefreshPeriodButton";
import HomepageSettings from "features/profile/HomepageSettings";
import React, { useEffect, useState } from "react";
import {
  getAccountId,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AccountService } from "services/AccountService";
import { AccountUISettingsService } from "services/AccountUISettingsService";
import { DEFAULT_UI_SETTINGS } from "services/utils";
import i18n from "../../i18n";
import { UserService } from "../../services/UserService";
import HomepageAnnouncements from "./items/HomepageAnnouncements";
import HomepageCalendar from "./items/HomepageCalendar";
import HomepageCampaignJoinList from "./items/HomepageCampaignJoinList";
import HomepageIssueList from "./items/HomepageIssueList";
import HomepageIssueReportButton from "./items/HomepageIssueReportButton";
import HomepageIssueReportForm from "./items/HomepageIssueReportForm";
import HomepageIssueStats from "./items/HomepageIssueStats";
import HomepageLikeList from "./items/HomepageLikeList";
import HomepageMyThings from "./items/HomepageMyThings";
import HomepageSuggestionList from "./items/HomepageSuggestionList";
import HomepageSurveyJoinList from "./items/HomepageSurveyJoinList";
import HomepageThings from "./items/HomepageThings";
import HomepageThings12Months from "./items/HomepageThings12Months";
import HomepageTodo from "./items/HomepageTodo";
import HomepageUserLocation from "./items/HomepageUserLocation";
import { useStyles } from "./style";

export default function Homepage() {
  const { t } = useTranslation("homepage", { i18n });
  let history = useHistory();

  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);
  startDate.setHours(0, 0, 0, 0);

  const classes = useStyles();
  const accountId = useSelector(getAccountId); //localStorage.accountId;

  const userInfo = useSelector(getUserInfo);
  const roles = localStorage.role;
  const [uiSettings, setUiSettings] = useState(null);
  const [accountUISettings, setAccountUISettings] = useState();
  const [isHomepageSettingsOpen, setIsHomepageSettingsOpen] = useState(false);
  const [refreshPeriod, setRefreshPeriod] = useState(60);
  const [refreshCount, setRefreshCount] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const refreshTimer = window.setInterval(() => {
      refreshHomepage();
    }, refreshPeriod * 1000);

    if (!accountId) {
      setTimeout(() => {
        refreshHomepage();
      }, 1000);
    }

    return () => {
      window.clearInterval(refreshTimer);
    };
  }, [refreshPeriod]);

  const refreshHomepage = () => {
    if (uiSettings) {
      setRefreshCount((prev) => {
        return prev + 1;
      });
      console.log("refreshHomepage", uiSettings);
    }
  };

  useEffect(() => {
    if (userInfo) {
      AccountService.findUserAccounts(
        userInfo,
        (data) => {
          if (data && data.length > 0) {
          } else {
            navigateAccountIncentiveScreen();
          }
        },
        (error) => {
          navigateAccountIncentiveScreen();
        }
      );
    }
  }, [userInfo]);

  const getAccountUISettings = () => {
    AccountUISettingsService.findByAccountId(
      accountId,
      (data) => {
        if (data && data.length > 0) {
          if (roles.includes("BENEFICIARY")) {
            data.map((item) => {
              if (item.role === "BENEFICIARY") {
                setUiSettings(JSON.parse(item.uiSettings));
              }
            });
          } else {
            data.map((item) => {
              if (roles.includes(item.role)) {
                setUiSettings(JSON.parse(item.uiSettings));
                return;
              }
            });
          }
        } else {
          setUiSettings(DEFAULT_UI_SETTINGS);
        }
      },
      (error) => {
        setUiSettings(DEFAULT_UI_SETTINGS);
      }
    );
  };

  useEffect(() => {
    if (!isHomepageSettingsOpen && accountId) {
      UserService.getUiSettings(
        (data) => {
          if (data) {
            setUiSettings(data);
          } else {
            getAccountUISettings();
          }
        },
        (error) => {
          getAccountUISettings();
        }
      );
    }
  }, [isHomepageSettingsOpen, accountId]);

  useEffect(() => {
    initPage(false);
  }, [history.location.search, accountId]);

  const initPage = (checkAccount) => {
    if (accountId && accountId > 0) {
    } else if (checkAccount == true && !accountId) {
    }
  };

  const navigateAccountIncentiveScreen = () => {
    const tmpTo = {
      pathname: `accountIncentiveScreen`,
      breadCrumbKey: `/accountIncentiveScreen`,
    };
    history.push(tmpTo);
  };

  const renderHomePageItem = (item, index) => {
    if (item.item === "THINGS")
      return (
        <HomepageThings
          size={item.size}
          key={index}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "THINGS_12_MONTH_CHART")
      return (
        <HomepageThings12Months
          size={item.size}
          key={index}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "ISSUES_CHART")
      return (
        <HomepageIssueStats
          size={item.size}
          key={index}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "MY_THINGS")
      return (
        <HomepageMyThings
          size={item.size}
          key={index}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "CALENDAR")
      return (
        <HomepageCalendar
          size={item.size}
          key={index}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "TODO")
      return (
        <HomepageTodo
          size={item.size}
          key={index}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "ISSUE_LIST")
      return (
        <HomepageIssueList
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "LIKE_LIST")
      return (
        <HomepageLikeList
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "SUGGESTION_LIST")
      return (
        <HomepageSuggestionList
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "SURVEY_JOIN_LIST")
      return (
        <HomepageSurveyJoinList
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "CAMPAIGN_JOIN_LIST")
      return (
        <HomepageCampaignJoinList
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "ISSUE_REPORT_BUTTON")
      return (
        <HomepageIssueReportButton
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "ISSUE_REPORT_FORM")
      return (
        <HomepageIssueReportForm
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "ANNOUNCEMENTS")
      return (
        <HomepageAnnouncements
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
    else if (item.item === "USER_LOCATION")
      return (
        <HomepageUserLocation
          size={item.size}
          key={index}
          startDate={startDate}
          refreshCount={refreshCount}
        />
      );
  };

  const changeRefreshPeriod = (param) => {
    setRefreshPeriod(param);
    setAnchorEl(null);
  };

  const renderHomePage = () => {
    return (
      <div className={classes.root}>
        {isHomepageSettingsOpen && (
          <HomepageSettings
            open={isHomepageSettingsOpen}
            onClose={() => {
              setIsHomepageSettingsOpen(false);
            }}
            onChange={() => {
              setIsHomepageSettingsOpen(false);
            }}
          />
        )}
        <div style={{ float: "right", marginBottom: -40 }}>
          <RefreshPeriodButton
            title={t("HOME_PAGE_SETTINGS")}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            refreshPeriod={refreshPeriod}
            setRefreshPeriod={setRefreshPeriod}
          />
        </div>
        <div style={{ float: "right", marginBottom: -40, marginRight: 30 }}>
          <Tooltip title={t("Yenile")}>
            <IconButton onClick={refreshHomepage} size="large">
              <Refresh />
            </IconButton>
          </Tooltip>
        </div>
        <Adsense value="RklmUst" />
        <Grid container spacing={1}>
          {uiSettings &&
            uiSettings.homepageItems &&
            uiSettings.homepageItems.length > 0 &&
            uiSettings.homepageItems.map((item, index) =>
              renderHomePageItem(item, index)
            )}
        </Grid>
      </div>
    );
  };

  return <>{accountId && renderHomePage()}</>;
}
