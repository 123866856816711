import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionService } from "services/SubscriptionService";
import { getDateTimeWFormat } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function SubscriptionHistory() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("subscriptions", { i18n });
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  const [subscriptionList, setSubscriptionList] = useState([]);

  useEffect(() => {
    reloadList();
  }, []);

  const reloadList = () => {
    SubscriptionService.findSubscriptionList(
      accountId,
      (data) => {
        setSubscriptionList(data);
      },
      (error) => {}
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Card style={{ minHeight: 100 }}>
              <CardHeader data-fa-section="Üyelik Geçmişi" />
              <CardContent>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("TYPE")}</TableCell>
                          <TableCell>{t("NAME")}</TableCell>
                          <TableCell>{t("START_DATE")}</TableCell>
                          <TableCell>{t("END_DATE")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subscriptionList.map((value, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                {t(value.salesPackage.salesPackageType)}
                              </TableCell>
                              <TableCell>{value.name}</TableCell>
                              <TableCell>
                                {getDateTimeWFormat(value.startDate)}
                              </TableCell>
                              <TableCell>
                                {getDateTimeWFormat(value.endDate)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
