import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ActionTypeSelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100} required={required}>
      <InputLabel>{label ?? t("ACTION_TYPE.ACTION_TYPE")}</InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("ACTION_TYPE.ACTION_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"THING_ADD"}>{t("ACTION_TYPE.THING_ADD")}</MenuItem>
        <MenuItem value={"THING_UPDATE"}>
          {t("ACTION_TYPE.THING_UPDATE")}
        </MenuItem>
        <MenuItem value={"THING_DELETE"}>
          {t("ACTION_TYPE.THING_DELETE")}
        </MenuItem>
        <MenuItem value={"RELATED_THING_UPDATE"}>
          {t("ACTION_TYPE.RELATED_THING_UPDATE")}
        </MenuItem>
        <MenuItem value={"RELATED_THING_NOTIFICATION"}>
          {t("ACTION_TYPE.RELATED_THING_NOTIFICATION")}
        </MenuItem>
        <MenuItem value={"RELATED_THING_SOLUTION"}>
          {t("ACTION_TYPE.RELATED_THING_SOLUTION")}
        </MenuItem>
        <MenuItem value={"ISSUE_ADD"}>{t("ACTION_TYPE.ISSUE_ADD")}</MenuItem>
        <MenuItem value={"ISSUE_TASK_ASSIGNED"}>
          {t("ACTION_TYPE.ISSUE_TASK_ASSIGNED")}
        </MenuItem>
        <MenuItem value={"TASK_RETURN_BACK"}>
          {t("ACTION_TYPE.TASK_RETURN_BACK")}
        </MenuItem>
        <MenuItem value={"ISSUE_CLOSED"}>
          {t("ACTION_TYPE.ISSUE_CLOSED")}
        </MenuItem>
        <MenuItem value={"SOLUTION_ADD"}>
          {t("ACTION_TYPE.SOLUTION_ADD")}
        </MenuItem>
        <MenuItem value={"PATROL_STARTED"}>
          {t("ACTION_TYPE.PATROL_STARTED")}
        </MenuItem>
        <MenuItem value={"PATROL_NEW_TASK"}>
          {t("ACTION_TYPE.PATROL_NEW_TASK")}
        </MenuItem>
        <MenuItem value={"PATROL_COMPLETE_TASK"}>
          {t("ACTION_TYPE.PATROL_COMPLETE_TASK")}
        </MenuItem>
        <MenuItem value={"PATROL_COMPLETE"}>
          {t("ACTION_TYPE.PATROL_COMPLETE")}
        </MenuItem>
        <MenuItem value={"STOCK_TAKING_START"}>
          {t("ACTION_TYPE.STOCK_TAKING_START")}
        </MenuItem>
        <MenuItem value={"STOCK_TAKING_COMPLETE"}>
          {t("ACTION_TYPE.STOCK_TAKING_COMPLETE")}
        </MenuItem>
        <MenuItem value={"INVITATION_SEND"}>
          {t("ACTION_TYPE.INVITATION_SEND")}
        </MenuItem>
        <MenuItem value={"INVITATION_ACCEPT"}>
          {t("ACTION_TYPE.INVITATION_ACCEPT")}
        </MenuItem>
        <MenuItem value={"INVITATION_REJECT"}>
          {t("ACTION_TYPE.INVITATION_REJECT")}
        </MenuItem>
        <MenuItem value={"EVENT_REMINDER"}>
          {t("ACTION_TYPE.EVENT_REMINDER")}
        </MenuItem>
        <MenuItem value={"USER_ACCOUNT_DISCONNECT"}>
          {t("ACTION_TYPE.USER_ACCOUNT_DISCONNECT")}
        </MenuItem>
        <MenuItem value={"USER_ADD"}>{t("ACTION_TYPE.USER_ADD")}</MenuItem>
        <MenuItem value={"ACCOUNT_ADD"}>
          {t("ACTION_TYPE.ACCOUNT_ADD")}
        </MenuItem>
        <MenuItem value={"ACCOUNT_SUBSCRIPTION_ADD"}>
          {t("ACTION_TYPE.ACCOUNT_SUBSCRIPTION_ADD")}
        </MenuItem>
        <MenuItem value={"ACCOUNT_SUBSCRIPTION_REMINDER1"}>
          {t("ACTION_TYPE.ACCOUNT_SUBSCRIPTION_REMINDER1")}
        </MenuItem>
        <MenuItem value={"ACCOUNT_SUBSCRIPTION_REMINDER2"}>
          {t("ACTION_TYPE.ACCOUNT_SUBSCRIPTION_REMINDER2")}
        </MenuItem>
        <MenuItem value={"ACCOUNT_SUBSCRIPTION_END"}>
          {t("ACTION_TYPE.ACCOUNT_SUBSCRIPTION_END")}
        </MenuItem>
        <MenuItem value={"ACCOUNT_SUBSCRIPTION_UPGRADE"}>
          {t("ACTION_TYPE.ACCOUNT_SUBSCRIPTION_UPGRADE")}
        </MenuItem>
        <MenuItem value={"INVACTIVE_ACCOUNT_ISSUE_ADD"}>
          {t("ACTION_TYPE.INVACTIVE_ACCOUNT_ISSUE_ADD")}
        </MenuItem>
        <MenuItem value={"USER_PASSWORD_CHANGE"}>
          {t("ACTION_TYPE.USER_PASSWORD_CHANGE")}
        </MenuItem>
        <MenuItem value={"USER_INFO_CHANGE"}>
          {t("ACTION_TYPE.USER_INFO_CHANGE")}
        </MenuItem>
        <MenuItem value={"PERIODIC_TASK_STARTED"}>
          {t("ACTION_TYPE.PERIODIC_TASK_STARTED")}
        </MenuItem>
        <MenuItem value={"GENERIC_NOTIFICATION"}>
          {t("ACTION_TYPE.GENERIC_NOTIFICATION")}
        </MenuItem>
        <MenuItem value={"ISSUE_ADD_FEEDBACK"}>
          {t("ACTION_TYPE.ISSUE_ADD_FEEDBACK")}
        </MenuItem>
        <MenuItem value={"CAMPAIGN_REGISTER"}>
          {t("ACTION_TYPE.CAMPAIGN_REGISTER")}
        </MenuItem>
        <MenuItem value={"COUPON"}>{t("ACTION_TYPE.COUPON")}</MenuItem>
        <MenuItem value={"SUGGESTION"}>{t("ACTION_TYPE.SUGGESTION")}</MenuItem>
        <MenuItem value={"LIKE"}>{t("ACTION_TYPE.LIKE")}</MenuItem>
      </Select>
    </FormControl>
  );
}
