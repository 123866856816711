import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingsNotificationsHelp(props) {
  const { value } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Etkileşim Notanız ile ilgili <b>bildirimlerin</b> gösterildiği ve{" "}
            <b>bildirim detaylarına</b> erişilebildiği alandır.
          </TutorialElement>
        ),
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/thingNotifications.png"
                }
                width="407"
                height="134"
              ></img>
              <br />
              <br />
              <b>Sorun detay</b> sayfasına erişmek istediğiniz bildirimin üstüne
              çift tıklayın.
            </div>
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

ThingsNotificationsHelp.propTypes = {
  value: PropTypes.string,
};
