import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Link } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ThingsDataTable from "components/thingsDataTable";
import React, { useEffect, useRef, useState } from "react";
import { FaDialog, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DocumentService } from "services/DocumentService";
import { DOCUMENT } from "services/faApi";
import { getDateTimeWFormat, HumanFileSize } from "services/utils";
import { gridStyles } from "styles/ThingsGridStyle";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function CustomerDocuments(props) {
  const { customer, customerRefresh } = props;
  const faDialog = useDialog();
  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const accountId = localStorage.accountId;
  const gridClasses = gridStyles();
  const tableRef = useRef();

  const [files, setFiles] = useState([]);

  const { t } = useTranslation("customer", { i18n });

  const [showAction, setShowAction] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [messageDocuments, setMessageDocuments] = useState([]);
  const [theDocument, setTheDocument] = useState({
    name: "",
    description: "",
  });
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  useEffect(() => {
    tableRef.current.refreshTable();
  }, [customerRefresh]);

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "30%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
    },
    {
      field: "createDate",
      label: t("Tarih"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "fileSize",
      label: t("Boyut"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return HumanFileSize(value);
      },
    },
    {
      field: "id",
      label: "",
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return (
          <IconButton
            onClick={() => {
              setTheDocument(row);
              setIsDetailDialogOpen(true);
            }}
            size="small"
            color="primary"
            aria-label={t("EDIT")}
          >
            <FontAwesomeIcon icon={faEdit} />
          </IconButton>
        );
      },
    },
  ];

  const onDoubleClick = (row) => {
    setTheDocument(row);
    setIsDetailDialogOpen(true);
  };

  const saveDocument = () => {
    DocumentService.uploadCustomerDocument(
      customer.id,
      accountId,
      theDocument.name,
      theDocument.description,
      files[0],
      uploadDocumentOnSuccess,
      uploadDocumentOnError
    );
  };

  const uploadDocumentOnSuccess = () => {
    setIsEditDialogOpen(false);
    setFiles([]);
    setLoading(false);
    tableRef.current.refreshTable();
  };

  const uploadDocumentOnError = (e) => {
    showAlert(t(e), "error");
    setLoading(false);
  };

  const handleChangeV = (prop, val) => {
    setTheDocument({ ...theDocument, [prop]: val });
  };

  const downloadDocument = (item) => {
    DocumentService.downloadCustomerDocument(
      accountId,
      customer.id,
      item.id,
      downloadDocumentOnSuccess,
      downloadDocumentOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    console.log("downloadDocumentOnSuccess", data);
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocumentOnError = (data) => {
    console.log("downloadDocumentOnError", data);
  };

  const deleteDocument = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      DocumentService.deleteCustomerDocument(
        accountId,
        customer.id,
        item.id,
        deleteDocumentOnSuccess,
        deleteDocumentOnError
      );
    });
  };

  const deleteDocumentOnSuccess = (data) => {
    setIsDetailDialogOpen(false);
    tableRef.current.refreshTable();
  };

  const deleteDocumentOnError = (data) => {};

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("Dosya Ekle")}
        loading={loading}
        faOpen={isEditDialogOpen}
        faOnSubmit={() => {
          setLoading(true);
          saveDocument();
        }}
        showSaveButton
        faHandleClose={() => setIsEditDialogOpen(false)}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              label={t("NAME")}
              onChange={(event) => handleChangeV("name", event.target.value)}
              value={theDocument.name || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="description"
              label={t("DESCRIPTION")}
              onChange={(event) =>
                handleChangeV("description", event.target.value)
              }
              value={theDocument.description || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaFileUpload
              maxFileSize={20000000}
              onChange={(data) => {
                if (data != null && data.length > 0) {
                  setFiles(data);
                } else setFiles(null);
              }}
              initialFiles={files}
              filesLimit={1}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const renderDetailDialog = () => {
    return (
      <FaDialog
        title={t("Dosya Detay")}
        faOpen={isDetailDialogOpen}
        faHandleClose={() => {
          setIsDetailDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("NAME")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {theDocument.name}
                </Typography>
              </Grid>

              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DESCRIPTION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {theDocument.description}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Boyut")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {HumanFileSize(theDocument.fileSize)}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Link
                  onClick={() => {
                    downloadDocument(theDocument);
                  }}
                  title={t("İndir")}
                  className={labelClasses.SimpleLink}
                  underline="hover">
                  <Typography className={labelClasses.LabelValue}>
                    {theDocument.fileName}
                  </Typography>
                </Link>
              </Grid>

              <Grid item xs={2}>
                {
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => {
                      deleteDocument(theDocument);
                    }}
                    title={t("Sil")}
                  >
                    {t("Sil")}
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FaDialog>
    );
  };
  return <>
    {renderEditDialog()}
    {renderDetailDialog()}
    <Card className="fai-customerDocuments">
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={() => {
              setTheDocument({
                name: "",
                description: "",
                fileName: "",
              });
              setIsEditDialogOpen(true);
            }}
            size="large">
            <AddIcon />
          </IconButton>
        }
        title={t("Müşteri Dosyaları")}
        data-fa-section="FILES"
      />
      {customer && customer.id > 0 && (
        <ThingsDataTable
          dataUrl={
            DOCUMENT.findCustomerDocuments +
            "?customerId=" +
            customer.id +
            "&accountId=" +
            accountId
          }
          queryParams={{
            page: 0,
            size: 10,
            sort: "createDate",
            isAsc: false,
          }}
          columns={columns}
          ref={tableRef}
          showDeleteButton={false}
          showHeaderText={false}
          headerText={t("Müşteri Dokümanları")}
          faOnDoubleClick={onDoubleClick}
          //showHeader={false}
          columnClassName={gridClasses.GenericColumn}
        />
      )}
    </Card>
  </>;
}
