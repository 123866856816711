import { SvgIcon } from "@mui/material";
import { default as React } from "react";

export default function ThingsCampaignIcon(props) {
  return (
    <SvgIcon viewBox="0 0 50 50" {...props}>
      <g>
        <path
          d="M35.957,9.155L19.17,15.869h-8.78c-3.021,0-5.479,2.458-5.479,5.479v7.305c0,2.569,1.783,4.714,4.171,5.304
		l3.724,9.306h6.715v-8.99l16.436,6.574v0.59v1.826h9.131V6.738h-9.131V9.155z M8.564,21.348c0-1.007,0.818-1.826,1.826-1.826h7.305
		v1.826H10.39V25h7.305v5.479H10.39c-1.008,0-1.826-0.819-1.826-1.826V21.348z M15.279,39.61l-2.192-5.479h2.782v5.479H15.279z
		 M41.436,39.61H39.61v-1.237V21.348h-3.652v15.564l-14.608-5.843V18.932l14.608-5.843v4.607h3.652v-6.067v-1.237l1.826-0.001V39.61
		z"
        />
      </g>
    </SvgIcon>
  );
}
