import React from "react";
import { FaUnAuthSettings } from "react-base-fa/dist/fa";
import styled from "styled-components";

export default function LanguageSelectButton() {
  const StyledDiv = styled.div`
    button {
      color: #151b26;
    }
  `;

  return (
    <StyledDiv>
      <FaUnAuthSettings />
    </StyledDiv>
  );
}
