import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    minHeight: "100%",
    backgroundColor: "white",
    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/pageTemplate/Template7_Background.png)`,
    backgroundSize: "100% 100%",
  },

  banner: {
    height: "125px",
    width: "100%",
    backgroundColor: "#062882",
  },

  appLogo: {
    position: "absolute",
    left: 30,
    top: 20,
    width: 90,
    height: 90,
    //border: "solid 2px #062882",
    //borderRadius: 10,
  },

  bannerAccount: {
    position: "absolute",
    left: 150,
    top: 20,
    color: "white",
    fontSize: 20,
    fontWeight: "bold",
  },

  bannerThing: {
    position: "absolute",
    left: 150,
    top: 45,
    color: "#F1BD1A",
    fontSize: 14,
    fontWeight: "bold",
  },

  customerButton: {
    position: "absolute",
    left: 150,
    top: 105,
    color: "white",
    backgroundColor: "#279A1B",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "solid 2px white",
    borderRadius: 10,
    width: 110,
    fontSize: 11,
  },

  infoButton: {
    position: "absolute",
    left: 270,
    top: 105,
    color: "white",
    backgroundColor: "#949398",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    border: "solid 2px white",
    borderRadius: 10,
    width: 110,
    fontSize: 11,
  },

  photosDiv: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 50,
    border: "solid 7px #062882",
    borderRadius: 10,
    padding: 4,
  },

  midPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
  },

  surveyButton: {
    backgroundColor: "#062882",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    width: 150,
    borderRadius: 10,
    marginRight: 10,
    fontSize: 11,
  },

  registerButton: {
    backgroundColor: "#062882",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    width: 150,
    float: "right",
    borderRadius: 10,
    fontSize: 11,
  },

  campaignsPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
  },

  campaign: {
    backgroundColor: "#E2E2E2",
    height: 70,
    width: "100%",
    display: "flex",
    marginBottom: 50,
    cursor: "pointer",
  },

  campaignLogo: {
    height: "100%",
  },

  campaignTexts: {
    flex: 1,
    textAlign: "center",
    paddingTop: 10,
  },

  campaignContentTexts: {
    color: "#062882",
    fontSize: 12,
    fontWeight: "bold",
  },

  campaignTitle: {
    color: "#062882",
    fontSize: 13,
    fontWeight: "bold",
  },

  campaignDate: {
    color: "#062882",
    fontSize: 11,
    paddingTop: 10,
  },

  bottomPanel: {
    marginTop: 90,
    marginLeft: 30,
    marginRight: 30,
    display: "flex",
    height: 80,
  },

  issueButton: {
    backgroundColor: "#F3AF10",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    borderRadius: 10,
    flex: 2,
    fontSize: 11,
  },

  suggestionButton: {
    backgroundColor: "#062882",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    borderRadius: 50,
    flex: 1,
    padding: 0,
    width: "95%",
    height: "90%",
  },

  likeButton: {
    backgroundColor: "#EF7812",
    "&:hover": {
      backgroundColor: "#F1BD1A",
    },
    color: "white",
    borderRadius: 10,
    flex: 2,
    fontSize: 11,
  },

  bottomPanelIcon: {
    fontSize: 48,
    color: "white",
  },

  linksPanel: {
    marginTop: 20,
    marginLeft: 30,
    marginRight: 30,
  },

  linksTitle: {
    backgroundColor: "#E2E2E2",
    height: 30,
    width: "100%",
    paddingTop: "10px",
    color: "#062882",
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
  },

  linkItem: {
    backgroundColor: "#E2E2E2",
    height: 30,
    width: "100%",
    display: "flex",
    paddingTop: "10px",
    paddingLeft: "10px",
    cursor: "pointer",
    color: "#062882",
    fontSize: 13,
    marginTop: 20,
  },
}));
