import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "../../../i18n";
import PublicPhotosCarousel3 from "./PublicPhotosCarousel3";
import { useStyles } from "./style";

export default function PublicPhotos3(props) {
  const { publicThing, index, imageList } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [images, setImages] = useState(imageList);

  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(index);

  return (
    <Grid
      item
      container
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <PublicPhotosCarousel3
        thingId={publicThing.id}
        setImages={setImages}
        images={images}
        selectedItem={selectedHdImageIndex}
      />
    </Grid>
  );
}

PublicPhotos3.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
