import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Bookmark, BookmarkBorder } from "@mui/icons-material";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import ColoredBookmarkButtonGroup from "components/buttonGroup/ColoredBookmarkButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingTableDecorator from "components/decorator/ThingTableDecorator";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { UserContentService } from "services/UserContentService";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { USER_CONTENT } from "../../services/faApi";

export default function UserContent() {
  const { t } = useTranslation(["userContent", "errorMessages"], { i18n });
  const labelClasses = labelStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  let history = useHistory();
  const role = useSelector(getRole);

  const accountId = localStorage.accountId;

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      description: null,
      thingId: null,
      thing: null,
      bookmarked: null,
      bookmarkColor: null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const bookmarkColors = ["GREEN", "YELLOW", "RED", "BLUE", "BLACK"];
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleBookmarkClick = (row, isChecked, newColor) => {
    setAnchorEl(null);
    changeBookmark(row, isChecked, newColor);
  };

  const handleClick = (id, event) => {
    setSelectedRowId(id);
    setAnchorEl(event.currentTarget);
  };
  const changeBookmark = (row, checked, color) => {
    let item = { ...row };
    item.bookmarked = checked;
    item.bookmarkColor = color;

    UserContentService.save(
      item,
      () => {
        tableRef.current.refreshTable();
      },
      () => {}
    );
  };
  const columns = [
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "25%",
      valueFormatter: (val) => {
        return val && val.id && <ThingTableDecorator value={val} />;
      },
    },
    {
      field: "userName",
      label: t("USER_NAME"),
      align: "center",
      width: "20%",
      valueFormatter: (val, row) => {
        return (val ?? "") + " " + (row.userSurname ?? "");
      },
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "25%",
    },
    {
      field: "id",
      label: t("EMAIL_OR_PHONE"),
      align: "center",
      width: "15%",
      valueFormatter: (val, row) => {
        return row?.email ? row.email : row?.phone ? row.phone : null;
      },
    },
    {
      field: "uploadDate",
      label: t("DATE"),
      align: "center",
      width: "10%",
      type: "smartDate",
    },
    {
      width: "5%",
      field: "bookmarked",
      label: t("BOOKMARK"),
      align: "center",
      valueFormatter: (val, row) => (
        <>
          <IconButton onClick={(e) => handleClick(row.id, e)} size="large">
            {val === "YES" && row?.bookmarkColor ? (
              <Bookmark style={{ color: row?.bookmarkColor }} />
            ) : (
              <BookmarkBorder style={{ color: "black" }} />
            )}
          </IconButton>
          {selectedRowId && row.id === selectedRowId ? (
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                key={0}
                selected={val === "NO"}
                onClick={() => handleBookmarkClick(row, "NO", null)}
              >
                <BookmarkBorder />
              </MenuItem>
              {bookmarkColors.map((c, index) => {
                return (
                  <MenuItem
                    key={index}
                    selected={c === row?.bookmarkColor}
                    onClick={() => handleBookmarkClick(row, "YES", c)}
                  >
                    <Bookmark style={{ color: c }} />
                  </MenuItem>
                );
              })}
            </Menu>
          ) : null}
        </>
      ),
    },
  ];

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `userContent/detail/${row.id}`,
      breadCrumbKey: `/userContent/detail/:id`,
    };
    history.push(tmpTo);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={3}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("BOOKMARKED")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ColoredBookmarkButtonGroup
                  data-fa-section="COLORED_BOOKMARK_BUTTON_GROUP"
                  value={searchForm.bookmarkColor}
                  isChecked={searchForm.bookmarked}
                  onChange={(color, isChecked) => {
                    const temp = {
                      ...searchForm,
                      bookmarkColor: color,
                      bookmarked: isChecked,
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={USER_CONTENT.search}
          exportUrl={
            USER_CONTENT.export + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                value={searchForm.thing}
                accountId={accountId}
                onChange={(newValue) => {
                  setSearchForm({
                    ...searchForm,
                    thing: newValue,
                    thingId: newValue?.id,
                  });
                }}
              />
              <FaInput
                label={t("DESCRIPTION")}
                faType="description"
                value={searchForm.descripton}
                inputProps={{ maxLength: 255 }}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    description: event.target.value,
                  });
                }}
              />

              <DateRangeSelector
                label={t("CREATE_DATE")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    uploadDateStart: data.startDate,
                    uploadDateEnd: data.endDate,
                  });
                }}
                data-fa-search="TARIH"
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
