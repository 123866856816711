import { Grid, Paper } from "@mui/material";
import LoadingBar from "components/loadingBar";
import GenericBarChart from "features/statistic/ui/GenericBarChart";
import React, {
  forwardRef,
  Suspense,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const InteractionCountByDate = forwardRef((props, ref) => {
  const { searchForm } = props;
  const { t } = useTranslation("components", { i18n });

  const labelClasses = labelStyles();

  const [dailyCounts, setDailyCounts] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const barChartRef = useRef();

  const [masterSearchForm, setMasterSearchForm] = useState(null);

  const countLabel = t("Etkileşim Sayısı");

  var today = new Date();
  const statSettings = {
    start: new Date(today.setDate(today.getDate() - 14)),
    end: null,
    period: "DAY",
    cumulative: false,
    dateRange: 4,
    dateRangeType: 2,
    thingClassId: null,
  };

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        ThingStatisticService.interactionCountByDate(
          searchForm,
          countLabel,
          (data) => {
            setChartData(data.chartData);
            setChartKeys(data.chartKeys);
          },
          (error) => {}
        );
      }
    },
  }));

  const getData = (settings, getDataOnSuccess, getDataOnError) => {
    let tempForm = {
      ...searchForm,
      ...settings,
      operationDateStart: settings.start,
      operationDateEnd: settings.end,
    };

    ThingStatisticService.interactionCountByDate2(
      tempForm,
      countLabel,
      t,
      getDataOnSuccess,
      getDataOnError
    );
  };

  const renderChart = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
            data-fa-section="INTERACTION_COUNT_BY_DATE"
          >
            <Grid item xs={12}>
              <Paper
              // style={{ height: "10rem" }}
              >
                <GenericBarChart
                  ref={barChartRef}
                  title={t("Trend")}
                  getData={getData}
                  bAxisLabel={t("Gün")}
                  lAxisLabel={t("Sorun Sayısı")}
                  menuDate
                  menuPeriod
                  settings={statSettings}
                />
                {/* <FaBarChart
                  data={chartData}
                  keys={chartKeys}
                  indexBy={"label"}
                  height="140px"
                  bAxisLabel={""}
                  lAxisLabel={""}
                  legends={[]}
                  stacked
                  colors={CHART_COLORS}
                  margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
                  axisBottom={null}
                  axisLeft={null}
                  enableGridY={true}
                /> */}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {chartData && (
        <Suspense fallback={<LoadingBar />}>{renderChart()}</Suspense>
      )}
    </>
  );
});
export default InteractionCountByDate;
