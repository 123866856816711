import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { PUBLIC, SALES_PACKAGE, SUBSCRIPTION } from "./faApi";
import { getThingsApp } from "./utils";

export const SubscriptionService = {
  findAll: (callback, errorCallback) => {
    const queryParams = {
      page: 0,
      size: 20,
      sort: "id",
      isAsc: true,
    };

    const req = requests.getSimpleGetRequest(
      SALES_PACKAGE.findAll,
      queryParams
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data.list);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findActiveList: (callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      PUBLIC.findActiveList + "?productType=" + getThingsApp()
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  subscribe: (data, callback, errorCallback) => {
    const req = requests.getSimplePostRequest(SUBSCRIPTION.subscribe, data);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findSubscriptionList: (accountId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SUBSCRIPTION.findSubscriptionList + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findInvoiceList: (accountId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SUBSCRIPTION.findInvoiceList + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findActiveSubscription: (accountId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SUBSCRIPTION.findActiveSubscription + "?accountId=" + accountId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  createCheckoutForm: (data, callback, errorCallback) => {
    const req = requests.getSimplePostRequest(
      SUBSCRIPTION.createCheckoutForm,
      data
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findDefaultSalesPackage: (callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SALES_PACKAGE.findDefaultSalesPackage + "?productType=" + getThingsApp()
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  findTrialSalesPackage: (callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SALES_PACKAGE.findTrialSalesPackage
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  startTrial: (data, callback, errorCallback) => {
    const req = requests.getSimplePostRequest(SUBSCRIPTION.startTrial, data);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },

  checkAvailability: (accountId, salesPackageId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(
      SUBSCRIPTION.checkAvailability +
        "?accountId=" +
        accountId +
        "&salesPackageId=" +
        salesPackageId
    );

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
