import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useHistory } from "react-router-dom";

import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SchedulePlanService } from "services/SchedulePlanService";
import i18n from "../../i18n";

const SchedulePlanEdit = forwardRef((props, ref) => {
  const { onSave, schedulePlan } = props;
  const { t } = useTranslation("schedulePlan", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newSchedulePlan = () => {
    return {
      account: { id: accountId },
      name: null,
      description: null,
      type: null,
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
      sunday: null,
      startTime: null,
      stopTime: null,
      startDate: null,
      endDate: null,
    };
  };
  const [schedulePlanDTO, setSchedulePlanDTO] = useState(newSchedulePlan());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    setSchedulePlanDTO(
      schedulePlan == null ? newSchedulePlan() : { ...schedulePlan }
    );
  }, [schedulePlan]);

  const saveSchedulePlan = () => {
    SchedulePlanService.save(
      schedulePlanDTO,
      (data) => {
        onSave(data);
        setIsDialogOpen(false);
      },
      (error) => {
        showAlert(error, "error");
      }
    );
  };

  const handleChange = (prop, val) => {
    setSchedulePlanDTO({ ...schedulePlanDTO, [prop]: val });
  };
  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const handleChangeV = (prop, val) => {
    setSchedulePlanDTO({ ...schedulePlanDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Zamanı Planını silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SchedulePlanService.delete(
          schedulePlanDTO,
          (data) => {
            showAlert(t("Zaman Planı Silindi"), "success");
            setIsDialogOpen(false);
            const tmpTo = {
              pathname: `/schedulePlan`,
              breadCrumbKey: `/schedulePlan`,
              search: "?page=0&size=10&isAsc=false&sort=name",
            };
            history.push(tmpTo);
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("NEW_SCHEDULE_PLAN")}
      faOpen={isDialogOpen}
      faOnSubmit={saveSchedulePlan}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setSchedulePlanDTO(schedulePlan ? schedulePlan : newSchedulePlan());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) => handleChange("name", event.target.value)}
            value={schedulePlanDTO.name || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              handleChange("description", event.target.value)
            }
            value={schedulePlanDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              row
              value={schedulePlanDTO.type}
              onChange={(event) => {
                handleChange("type", event.target.value);
              }}
            >
              <FormControlLabel
                value="RECURRING"
                control={<Radio />}
                label={t("RECURRING")}
              />

              <FormControlLabel
                value="ONE_TIME"
                control={<Radio />}
                label={t("ONE_TIME")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {schedulePlanDTO.type === "RECURRING" ? (
          <>
            <Grid item xs={12}>
              <Grid
                item
                container
                spacing={1}
                direction="row"
                row
                style={{ marginTop: 5 }}
              >
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.monday}
                        onChange={(event) => {
                          handleChange("monday", event.target.checked);
                        }}
                        name="monday"
                        color="primary"
                      />
                    }
                    label={t("MONDAY")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.tuesday}
                        onChange={(event) => {
                          handleChange("tuesday", event.target.checked);
                        }}
                        name="tuesday"
                        color="primary"
                      />
                    }
                    label={t("TUESDAY")}
                  />
                </Grid>

                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.wednesday}
                        onChange={(event) => {
                          handleChange("wednesday", event.target.checked);
                        }}
                        name="wednesday"
                        color="primary"
                      />
                    }
                    label={t("WEDNESDAY")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.thursday}
                        onChange={(event) => {
                          handleChange("thursday", event.target.checked);
                        }}
                        name="thursday"
                        color="primary"
                      />
                    }
                    label={t("THURSDAY")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.friday}
                        onChange={(event) => {
                          handleChange("friday", event.target.checked);
                        }}
                        name="friday"
                        color="primary"
                      />
                    }
                    label={t("FRIDAY")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.saturday}
                        onChange={(event) => {
                          handleChange("saturday", event.target.checked);
                        }}
                        name="saturday"
                        color="primary"
                      />
                    }
                    label={t("SATURDAY")}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={schedulePlanDTO.sunday}
                        onChange={(event) => {
                          handleChange("sunday", event.target.checked);
                        }}
                        name="sunday"
                        color="primary"
                      />
                    }
                    label={t("SUNDAY")}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("START_TIME")}
                faType="time"
                name="startTime"
                value={schedulePlanDTO.startTime}
                faOnChange={(val) => {
                  handleChangeV("startTime", val);
                }}
                data-fa-input="START_TIME"
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("STOP_TIME")}
                faType="time"
                name="stopTime"
                value={schedulePlanDTO.stopTime}
                faOnChange={(val) => {
                  handleChangeV("stopTime", val);
                }}
                data-fa-input="STOP_TIME"
              />
            </Grid>
          </>
        ) : null}
        {schedulePlanDTO.type === "ONE_TIME" ? (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("START_DATE")}
                faType="date"
                name="startDate"
                value={schedulePlanDTO.startDate}
                faOnChange={(val) => {
                  handleChangeV("startDate", val);
                }}
                data-fa-input="START_DATE"
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("END_DATE")}
                faType="date"
                name="endDate"
                value={schedulePlanDTO.endDate}
                faOnChange={(val) => {
                  handleChangeV("endDate", val);
                }}
                data-fa-input="END_DATE"
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("START_TIME")}
                faType="time"
                name="startTime"
                value={schedulePlanDTO.startTime}
                faOnChange={(val) => {
                  handleChangeV("startTime", val);
                }}
                data-fa-input="START_TIME"
              />
            </Grid>
            <Grid item xs={12}>
              <FaDatePicker
                label={t("STOP_TIME")}
                faType="time"
                name="stopTime"
                value={schedulePlanDTO.stopTime}
                faOnChange={(val) => {
                  handleChangeV("stopTime", val);
                }}
                data-fa-input="STOP_TIME"
              />
            </Grid>
          </Grid>
        ) : null}
        {schedulePlanDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={onDelete} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default SchedulePlanEdit;
