import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import i18n from "i18n";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ReportService } from "services/report/reportService";
import { useStyles } from "./style";

export default function PublicPhotosCarousel3(props) {
  const { thingId, uploadImages, images, setImages, selectedItem } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation("thingDetail", { i18n });
  const fileInputRef = useRef();
  const [loadingImages, setLoadingImages] = useState([]);

  let loadingItem = null;

  const handleChange = (event) => {
    event.preventDefault();
    if (event?.target?.files?.length > 0) {
      uploadImages(event.target.files);
    }
  };

  const createCarouselItemImage = (item, index) => (
    <div key={index} className={classes.image}>
      {item.hdImage && (
        <img
          alt=""
          src={`data:image/png;base64, ${item.hdImage}`}
          style={{ width: "100%" }}
        />
      )}
    </div>
  );

  const baseChildren = <div>{images.map(createCarouselItemImage)}</div>;

  const customRenderThumb = (children) =>
    children.map((item, index) => {
      return (
        <img
          src={`data:image/png;base64, ${images[index].content}`}
          alt={item.object}
        />
      );
    });

  const customRenderItem = (item, props) => {
    if (props.isSelected === true) {
      if (
        !images[item.key].hdImage &&
        loadingImages.indexOf(item.key) < 0 &&
        loading === false
      ) {
        setLoading(true);
        setLoadingImages((prev) => {
          return [...prev, item.key];
        });
        ReportService.downloadImage(
          thingId,
          images[item.key].object,
          (data) => {
            onDownloadImageSuccess(data, item.key);
          },
          (error) => {
            setLoading(false);
          }
        );
      }
    }

    return <item.type {...item.props} {...props} />;
  };

  const onDownloadImageSuccess = (data, index) => {
    setLoading(false);
    setImages((prev) => {
      let row = { ...prev[index], hdImage: data?.content };
      let imagesTmp = [...prev];
      imagesTmp[index] = row;

      return [...imagesTmp];
    });
  };

  return (
    <Carousel
      showArrows={true}
      renderItem={customRenderItem}
      renderThumbs={customRenderThumb}
      showThumbs={false}
      selectedItem={selectedItem}
    >
      {baseChildren.props.children}
    </Carousel>
  );
}
