import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import ThingsDataTable from "components/thingsDataTable";
import ThingsHistoryHelp from "features/help/ThingsHistoryHelp";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { THING } from "services/faApi";
import { gridStyles } from "styles/ThingsGridStyle";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ThingHistory(props) {
  const { thing } = props;
  const classes = useStyles();
  const tableRef = useRef();
  const gridClasses = gridStyles();

  const { t } = useTranslation("thingDetail", { i18n });

  const [recurrenceList, setRecurrenceList] = useState([]);

  const columns = [
    {
      field: "operation",
      label: t("İşlem"),
      align: "center",
      width: "40%",
      valueFormatter: (value) => {
        return t("" + value);
      },
    },
    {
      field: "date",
      label: t("Tarih"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "description",
      label: t("Açıklama"),
      align: "center",
      width: "40%",
    },
  ];

  return (
    <>
      <Card style={{ minHeight: 100 }} className="fai-thingLinks">
        <CardHeader
          title={t("HISTORY")}
          data-fa-section="Etkileşim Noktası Geçmişi"
          action={<ThingsHistoryHelp value="thingDetail.thingHistory" />}
        />
        {thing && thing.id > 0 && (
          <ThingsDataTable
            dataUrl={THING.findThingHistory + "?thingId=" + thing.id}
            queryParams={{
              page: 0,
              size: 10,
              sort: "date",
              isAsc: false,
            }}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("Geçmiş")}
            showHeader={false}
            columnClassName={gridClasses.GenericColumn}
          />
        )}
      </Card>
    </>
  );
}
