import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { FaDialog, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { DocumentService } from "services/DocumentService";
import { DynamicFormService } from "services/dynamicForm/dynamicFormService";
import { ThingClassService } from "services/thingClass/thingClassService";
import i18n from "../../i18n";
import {
  DATA_TYPES,
  LANGUAGES,
  REMINDER_DATE_TYPES,
} from "../../services/utils";
import { useStyles } from "./style";

export default function DynamicFieldDialog(props) {
  const { faOpen, faOnSubmit, faHandleClose, faEditValue, onDelete } = props;
  const { t } = useTranslation("dynamicFieldDialog", { i18n });
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  const [values, setValues] = useState(
    faEditValue != null
      ? {
          ...faEditValue,
          //dataTypeEnum: DATA_TYPES.indexOf(faEditValue.dataTypeEnum),
        }
      : {
          dataType: "STRING",
          label: "",
          notEmpty: false,
          hint: "",
          options: [],
          future: true,
          past: true,
          min: null,
          max: null,
          reminder: false,
          reminderDateType: null,
          reminderDateValue: null,
          yesLabel: null,
          noLabel: null,
          maxFileSize: null,
          fileTypes: null,
          enumsMultiple: null,
          multiLang: null,
        }
  );
  const [uploadedFileValues, setUploadedFileValues] = useState([]);
  const defaultLang = getCurrentLang().split("-")[0];
  const [deleteFileObjectId, setDeleteFileObjectId] = useState([]);

  useEffect(() => {
    setValues(
      faEditValue != null
        ? {
            ...faEditValue,
            //dataTypeEnum: DATA_TYPES.indexOf(faEditValue.dataTypeEnum),
            reminder: faEditValue.reminderDateValue ? true : false,
          }
        : {
            dataType: "STRING",
            label: "",
            notEmpty: false,
            hint: "",
            options: [],
            future: true,
            past: true,
            min: null,
            max: null,
            reminder: false,
            reminderDateType: null,
            reminderDateValue: null,
            yesLabel: null,
            noLabel: null,
            maxFileSize: null,
            fileTypes: null,
            enumsMultiple: null,
            multiLang: null,
          }
    );
  }, [faOpen, faEditValue]);

  const handleChange = (prop, val) => {
    setValues({ ...values, [prop]: val });
  };

  const onSubmit = () => {
    if (values.dataType === "ENUMS") {
      faOnSubmit(values, uploadedFileValues);
    } else {
      faOnSubmit({ ...values, options: [] }, uploadedFileValues);
    }
    setUploadedFileValues([]);

    if (deleteFileObjectId && deleteFileObjectId.length > 0) {
      DocumentService.deleteDynamicFormDocument(
        accountId,
        faEditValue.id,
        deleteFileObjectId,
        (data) => {
          setDeleteFileObjectId([]);
        },
        (e) => {}
      );
    }
  };

  const dataTypes = DATA_TYPES.map((item, index) => (
    <MenuItem key={index} value={item}>
      {t(item)}
    </MenuItem>
  ));

  const reminderDateTypes = REMINDER_DATE_TYPES.map((item, index) => (
    <MenuItem key={index} value={item}>
      {t(item)}
    </MenuItem>
  ));

  const downloadDocument = (fileId) => {
    ThingClassService.downloadDocument(
      faEditValue.id,
      fileId,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const renderFileContents = () => {};

  const deleteField = () => {
    faDialog({
      description: t("Kaydı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        DynamicFormService.delete(values, onDelete, (data) => {
          showAlert("Bağlı kayıtlar mevcut", "error");
        });
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <FaDialog
      loading={loading}
      showSaveButton
      faOpen={faOpen}
      title={t("ADD_FIELD")}
      faOnSubmit={onSubmit}
      faHandleClose={faHandleClose}
    >
      <Grid item container spacing={1} data-fa-section="DYNAMIC_FIELD_DIALOG">
        <Grid item style={{ paddingTop: 12 }} xs={6}>
          <FaInput
            required
            id="label"
            label={t("LABEL")}
            value={values.label}
            onChange={(event) => {
              handleChange("label", event.target.value);
            }}
            name="label"
          />
        </Grid>
        {values.dataType !== "BOOLEAN" && (
          <Grid
            item
            container
            alignItems="left"
            justifyContent="left"
            xs={6}
            style={{ paddingLeft: 20 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.notEmpty}
                  onChange={(event) => {
                    handleChange("notEmpty", event.target.checked);
                  }}
                  name="notEmpty"
                  color="primary"
                />
              }
              label={t("NOT_EMPTY")}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-outlined-label">
              {t("FIELD_TYPE")}
            </InputLabel>
            <Select
              variant="outlined"
              classes={{
                outlined: classes.padding10,
              }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={values.dataType}
              onChange={(event) => {
                setValues({
                  ...values,
                  dataType: event.target.value,
                });
              }}
              style={{ height: 40 }}
              label={t("FIELD_TYPE")}
            >
              {dataTypes}
            </Select>
          </FormControl>
        </Grid>
        {(values.dataType === "TEXT" ||
          values.dataType === "STRING" ||
          values.dataType === "FILE") && (
          <>
            <Grid
              item
              container
              alignItems="left"
              justifyContent="left"
              xs={6}
              style={{ paddingLeft: 20 }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.multiLang}
                    onChange={(event) => {
                      handleChange("multiLang", event.target.checked);
                    }}
                    name="multiLang"
                    color="primary"
                  />
                }
                label={t("MULTI_LANG_ACTIVE")}
              />
            </Grid>
          </>
        )}
        {(values.dataType === "TEXT" || values.dataType === "STRING") &&
          values.multiLang && (
            <Grid item xs={12} style={{ marginTop: 5 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {t("DEFAULT_VALUES")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item container spacing={1}>
                    {LANGUAGES.map((lang) => (
                      <Grid item xs={12}>
                        <FaInput
                          maxLength={100}
                          label={t(lang.toUpperCase())}
                          onChange={(event) =>
                            setValues({
                              ...values,
                              defaultTextValues: {
                                ...values.defaultTextValues,
                                [lang]: event.target.value,
                              },
                            })
                          }
                          value={values?.defaultTextValues?.[lang] || ""}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        {(values.dataType === "DATE" || values.dataType === "DATE_TIME") && (
          <>
            <Grid
              item
              container
              spacing={1}
              direction="row"
              xs={6}
              style={{ marginTop: 5 }}
            >
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.future}
                      onChange={(event) => {
                        handleChange("future", event.target.checked);
                      }}
                      name="recurring"
                      color="primary"
                    />
                  }
                  label={t("FUTURE")}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.past}
                      onChange={(event) => {
                        handleChange("past", event.target.checked);
                      }}
                      name="recurring"
                      color="primary"
                    />
                  }
                  label={t("PAST")}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={1} direction="row" xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.reminder}
                      onChange={(event) => {
                        if (!event.target.checked) {
                          handleChange("reminderDateValue", "");
                          handleChange("reminderDateType", "");
                          setValues({
                            ...values,
                            reminder: event.target.checked,
                            reminderDateValue: null,
                            reminderDateType: null,
                          });
                        } else {
                          setValues({
                            ...values,
                            reminder: event.target.checked,
                            reminderDateValue: 1,
                            reminderDateType: "DAY",
                          });
                        }
                      }}
                      name="recurring"
                      color="primary"
                    />
                  }
                  label={t("REMINDER")}
                />
              </Grid>
              {values.reminder && (
                <Grid item xs={4}>
                  <FaInput
                    faType="number"
                    id="reminderDateValue"
                    label={t("REMINDER_DATE")}
                    value={values.reminderDateValue}
                    onChange={(event) => {
                      handleChange("reminderDateValue", event.target.value);
                    }}
                    name="reminderDateValue"
                    style={{ marginTop: 0 }}
                  />
                </Grid>
              )}
              {values.reminder && (
                <Grid item xs={4}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("REMINDER_DATE_TYPE")}
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.reminderDateType}
                      onChange={(event) => {
                        setValues({
                          ...values,
                          reminderDateType: event.target.value,
                        });
                      }}
                      style={{ height: 40 }}
                      label={t("REMINDER_DATE_TYPE")}
                    >
                      {reminderDateTypes}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {values.dataType === "NUMERIC" && (
          <Grid item container spacing={1} direction="row" xs={6}>
            <Grid item xs={6}>
              <FaInput
                faType="number"
                id="min"
                label={t("MIN")}
                value={values.min}
                onChange={(event) => {
                  handleChange("min", event.target.value);
                }}
                name="min"
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                faType="number"
                id="max"
                label={t("MAX")}
                value={values.max}
                onChange={(event) => {
                  handleChange("max", event.target.value);
                }}
                name="max"
              />
            </Grid>
          </Grid>
        )}
        {values.dataType === "YES_NO" && (
          <Grid item container spacing={1} direction="row" xs={12}>
            <Grid item xs={6}>
              <FaInput
                id="yesLabel"
                label={t("YES_LABEL")}
                value={values.yesLabel}
                onChange={(event) => {
                  handleChange("yesLabel", event.target.value);
                }}
                name="yesLabel"
              />
            </Grid>
            <Grid item xs={6}>
              <FaInput
                id="noLabel"
                label={t("NO_LABEL")}
                value={values.noLabel}
                onChange={(event) => {
                  handleChange("noLabel", event.target.value);
                }}
                name="noLabel"
              />
            </Grid>
          </Grid>
        )}
        {values.dataType === "ENUMS" && (
          <>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={6}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.enumsMultiple}
                    onChange={(event) => {
                      handleChange("enumsMultiple", event.target.checked);
                    }}
                    name="enumsMultiple"
                    color="primary"
                  />
                }
                label={t("ENUMS_MULTIPLE")}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={values.options}
                multiple={true}
                options={[]}
                style={{ minHeight: 65 }}
                isOptionEqualToValue={(option, value) => {
                  return option === value;
                }}
                autoSelect
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option;
                }}
                onChange={(event, newValue) => {
                  handleChange("options", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("SELECT_OPTIONS")}
                    onKeyDown={(e) => {
                      if (e.key === ";") {
                        e.preventDefault();
                      } else if (e.key === "Enter") {
                        e.preventDefault();
                        if (
                          e.target &&
                          e.target.value &&
                          e.target.value.indexOf(";") < 0
                        ) {
                          handleChange("options", e.target.value);
                        }
                      }
                    }}
                    variant="outlined"
                  />
                )}
                freeSolo
                label={t("HASHTAG")}
              />
            </Grid>
          </>
        )}

        {values.dataType === "FILE" && !values.multiLang && (
          <Grid item xs={12} style={{ marginTop: 5 }}>
            <Grid item container spacing={1}>
              <>
                {values.dataType === "FILE" &&
                  defaultLang &&
                  values.defaultFileValue &&
                  values.defaultFileValue[defaultLang] &&
                  values.defaultFileValue[defaultLang].value && (
                    <>
                      <Grid item xs={1}>
                        <ReactCountryFlag countryCode={defaultLang} svg />
                      </Grid>
                      <Grid item xs={9}>
                        <Link
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            downloadDocument(
                              values.defaultFileValue[defaultLang].value
                            );
                          }}
                          underline="hover"
                        >
                          {values.defaultFileValue[defaultLang]?.label ??
                            "İndir"}
                        </Link>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          size="small"
                          startIcon={<FontAwesomeIcon icon={faTrash} />}
                          onClick={() => {
                            let deletedFiles = deleteFileObjectId;
                            deletedFiles.push(
                              values.defaultFileValue[defaultLang].value
                            );
                            setDeleteFileObjectId(deletedFiles);
                            setValues({
                              ...values,
                              defaultFileValue: {
                                ...values.defaultFileValue,
                                [defaultLang]: null,
                              },
                            });

                            setUploadedFileValues([]);
                          }}
                          title="Sil"
                        ></Button>
                      </Grid>
                    </>
                  )}

                {values.dataType === "FILE" &&
                  (!values.defaultFileValue ||
                    !values.defaultFileValue[defaultLang]) && (
                    <>
                      <Grid item xs={1}>
                        <ReactCountryFlag countryCode={defaultLang} svg />
                      </Grid>
                      <Grid item xs={11}>
                        <FaFileUpload
                          maxFileSize={
                            values.maxFileSize == null
                              ? 20000000
                              : values.maxFileSize * 1000000
                          }
                          acceptedFiles={
                            values.fileTypes == null
                              ? [""]
                              : values.fileTypes.split(",")
                          }
                          onChange={(data) => {
                            if (data != null && data.length > 0) {
                              let tempUploadedFiles = uploadedFileValues;
                              tempUploadedFiles.push({
                                file: data[0],
                                language: defaultLang,
                              });
                              setUploadedFileValues(tempUploadedFiles);
                            } else setUploadedFileValues([]);
                          }}
                          filesLimit={1}
                          dropZoneBoxProps={{
                            style: { minHeight: "30px" },
                          }}
                        />
                      </Grid>
                    </>
                  )}
              </>
            </Grid>
          </Grid>
        )}
        {values.dataType === "FILE" && values.multiLang && (
          <Grid item xs={12} style={{ marginTop: 5 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  {t("DEFAULT_FILE")}
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Grid item container spacing={1}>
                  {LANGUAGES.map((lang) => (
                    <>
                      {values.dataType === "FILE" &&
                        lang &&
                        values.defaultFileValue &&
                        values.defaultFileValue[lang] &&
                        values.defaultFileValue[lang].value && (
                          <>
                            <Grid item xs={1}>
                              <ReactCountryFlag
                                countryCode={lang === "en" ? "us" : lang}
                                svg
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Link
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  downloadDocument(
                                    values.defaultFileValue[lang].value
                                  );
                                }}
                                underline="hover"
                              >
                                {values.defaultFileValue[lang]?.label ??
                                  "İndir"}
                              </Link>
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                size="small"
                                startIcon={<FontAwesomeIcon icon={faTrash} />}
                                onClick={() => {
                                  let deletedFiles = deleteFileObjectId;
                                  deletedFiles.push(
                                    values.defaultFileValue[lang].value
                                  );
                                  setDeleteFileObjectId(deletedFiles);
                                  setValues({
                                    ...values,
                                    defaultFileValue: {
                                      ...values.defaultFileValue,
                                      [lang]: null,
                                    },
                                  });
                                  setUploadedFileValues([]);
                                }}
                                title="Sil"
                              ></Button>
                            </Grid>
                          </>
                        )}

                      {values.dataType === "FILE" &&
                        lang &&
                        (!values.defaultFileValue ||
                          !values.defaultFileValue[lang]) && (
                          <>
                            <Grid item xs={1}>
                              <ReactCountryFlag
                                countryCode={lang === "en" ? "us" : lang}
                                svg
                              />
                            </Grid>
                            <Grid item xs={11}>
                              <FaFileUpload
                                maxFileSize={
                                  values.maxFileSize == null
                                    ? 20000000
                                    : values.maxFileSize * 1000000
                                }
                                acceptedFiles={
                                  values.fileTypes == null
                                    ? [""]
                                    : values.fileTypes.split(",")
                                }
                                onChange={(data) => {
                                  if (data != null && data.length > 0) {
                                    let tempUploadedFiles = uploadedFileValues;
                                    tempUploadedFiles.push({
                                      file: data[0],
                                      language: lang,
                                    });
                                    setUploadedFileValues(tempUploadedFiles);
                                  } else setUploadedFileValues([]);
                                }}
                                filesLimit={1}
                                dropZoneBoxProps={{
                                  style: { minHeight: "30px" },
                                }}
                              />
                            </Grid>
                          </>
                        )}
                    </>
                  ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        {values.dataType === "LINK" && (
          <Grid item container spacing={1} direction="row" xs={12}>
            <Grid item xs={12}>
              <FaInput
                label={t("DEFAULT_VALUE")}
                value={values.defaultStringValue}
                onChange={(event) => {
                  handleChange("defaultStringValue", event.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <FaInput
            faType="description"
            id="hint"
            label={t("HINT")}
            value={values.hint}
            onChange={(event) => {
              handleChange("hint", event.target.value);
            }}
            name="hint"
          />
        </Grid>
      </Grid>
      {values?.id ? (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={deleteField} color="secondary">
            {t("Sil")}
          </Button>
        </Grid>
      ) : null}
    </FaDialog>
  );
}
