import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { FaBarChart } from "react-base-fa/dist/fa/facharts";
import { useTranslation } from "react-i18next";
import { ThingStatisticService } from "services/statistic/statisticService";
import { isTNF } from "services/utils";
import { CHART_COLORS } from "services/utils/ChartConstants";
import i18n from "../../../i18n";

export default function HomepageThings12Months(props) {
  const { size, refreshCount } = props;
  const { t } = useTranslation(["homepage", "components"], { i18n });

  const accountId = localStorage.accountId;

  const [thingCountByMonth, setThingCountByMonth] = useState([]);
  const [thingCountByMonthCategory, setThingCountByMonthCategory] = useState(
    []
  );

  useEffect(() => {
    setThingCountByMonthCategory([t("SAYI")]);

    ThingStatisticService.thingCountByMonth(
      accountId,
      t("SAYI"),
      setThingCountByMonth
    );
  }, [refreshCount]);

  return (
    <Grid item lg={size} xs={12}>
      <Card>
        <CardHeader
          title={isTNF(
            t("components:HOMEPAGE_ITEM.THINGS_12_MONTH_CHART"),
            t("components:HOMEPAGE_ITEM.THINGS_12_MONTH_CHART_T2C")
          )}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item lg={12} xs={12}>
              <Grid item>
                <FaBarChart
                  data={thingCountByMonth}
                  keys={thingCountByMonthCategory}
                  indexBy={"label"}
                  legends={[]}
                  height="300px"
                  bAxisLabel={t("AY")}
                  lAxisLabel={t("NESNE_SAYISI")}
                  colors={CHART_COLORS}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
