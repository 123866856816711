import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function PolicyActionEnum(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100} required={required}>
      <InputLabel>{label ?? t("POLICY_ACTION")}</InputLabel>
      <Select
        variant="outlined"
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("ACCESS_TYPE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"NEW_TASK"}>{t("POLICY_ACTION_NEW_TASK")}</MenuItem>
        <MenuItem value={"NEW_ISSUE"}>{t("POLICY_ACTION_NEW_ISSUE")}</MenuItem>
        <MenuItem value={"EMAIL_NOTIFICATION"}>
          {t("POLICY_ACTION_EMAIL_NOTIFICATION")}
        </MenuItem>
        <MenuItem value={"SMS_NOTIFICATION"}>
          {t("POLICY_ACTION_SMS_NOTIFICATION")}
        </MenuItem>
        <MenuItem value={"PUSH_NOTIFICATION"}>
          {t("POLICY_ACTION_PUSH_NOTIFICATION")}
        </MenuItem>
        <MenuItem value={"DISPATCH_ORGANIZATION"}>
          {t("POLICY_ACTION_DISPATCH_ORGANIZATION")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
