import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ThingDetail from "components/detail/ThingDetail";
import React from "react";
import { getDateTimeWFormat } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function LikeDetailCard(props) {
  const { like } = props;
  const { t } = useTranslation("like", { i18n });
  const labelClasses = labelStyles();

  return (
    <Card style={{ minHeight: 250 }}>
      <CardHeader title={t("BASIC_INFO")} data-fa-section="BASIC_INFO" />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {isTNF(t("THING"), t("THING_T2C"))}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  <ThingDetail value={like?.thing} />
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("BILDIRIM_TARIH")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {getDateTimeWFormat(like.likeDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("CLASS_NAME")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {like?.likeClass?.className}
                </Typography>
              </Grid>
              <Grid item xs={12} className={labelClasses.LabelLastRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DESCRIPTION")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {like?.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
