import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Rating } from '@mui/material';
import ThingDetail from "components/detail/ThingDetail";
import UserDetail from "components/detail/UserDetail";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PatrolService } from "services/PatrolService";
import { SolutionService } from "services/SolutionService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function PatrolDetail() {
  const tableRef = useRef();
  const labelClasses = labelStyles();

  const { t } = useTranslation("patrolDef", { i18n });
  const dispatch = useDispatch();
  const location = useLocation();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [patrol, setPatrol] = useState(location.state.patrol);
  const [patrolThings, setPatrolThings] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [allData, setAllData] = useState([]);
  const [selectedControlItem, setSelectedControlItem] = useState();
  const [openControlQuestionsDialog, setOpenControlQuestionsDialog] =
    useState(false);

  useEffect(() => {
    if (patrol) {
      PatrolService.findDetailsByPatrol(
        patrol.id,
        (data) => {
          setPatrolThings(data);
        },
        (error) => {}
      );
      SolutionService.findByPatrol(
        patrol.id,
        (data) => {
          setSolutions(data);
        },
        (error) => {}
      );
    }
  }, [patrol]);

  useEffect(() => {
    if (patrolThings && patrolThings.length > 0) {
      let tempAllData = [];
      patrolThings.map((item) => {
        let solution;
        if (solutions && solutions.length > 0)
          solution = solutions.find((s) => s.patrolThing.id === item.id);
        let newData = { ...item, controlItems: solution?.solutionControlItems };
        tempAllData.push(newData);
      });
      setAllData(tempAllData);
    }
  }, [patrolThings, solutions]);

  const renderControlItemsDialog = () => {
    return (
      <FaDialog
        title={t("CONTROL_ITEMS")}
        faOpen={openControlQuestionsDialog}
        faHandleClose={() => {
          setOpenControlQuestionsDialog(false);
        }}
        faMaxWidth="md"
      >
        <Grid container spacing={1}>
          <Grid item lg={12} xs={12}>
            {allData?.map((patrolThing, index) => {
              if (
                patrolThing?.controlItems &&
                patrolThing.controlItems.length > 0
              )
                return patrolThing?.controlItems
                  .sort((a, b) =>
                    a.controlItem.orderNo > b.controlItem.orderNo
                      ? 1
                      : b.controlItem.orderNo > a.controlItem.orderNo
                      ? -1
                      : 0
                  )
                  ?.map((q, index) => (
                    <TableRow>
                      <TableCell className={labelClasses.LabelValue}>
                        {createControlItem(q, index)}
                      </TableCell>
                    </TableRow>
                  ));
            })}
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const createControlItem = (item, index) => {
    return (
      <div>
        <div>
          <b>{item?.controlItem?.orderNo}.</b> {item?.controlItem?.question}
        </div>
        <div>
          {item?.controlItem?.itemType === "YES_NO" && (
            <RadioGroup row aria-label="position" value={"" + item?.yesNoValue}>
              <FormControlLabel
                value={"YES"}
                control={<Radio color="primary" />}
                label={item?.controlItem?.yesLabel}
                disabled
              />
              <FormControlLabel
                value={"NO"}
                control={<Radio color="primary" />}
                label={item?.controlItem?.noLabel}
                disabled
              />
            </RadioGroup>
          )}
          {item?.controlItem?.itemType === "RATING_SCALE" && (
            <Rating
              max={item?.maxRating}
              defaultValue={item?.ratingValue}
              disabled
            />
          )}
        </div>
      </div>
    );
  };

  return <>
    {allData && allData.length > 0 && renderControlItemsDialog()}
    <Suspense fallback={<LoadingBar />}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={t("PATROL_INFO")}
              data-fa-section="PATROL_INFO"
            />
            <CardContent>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("NAME")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {patrol?.patrolDef.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("START_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateTimeWFormat(patrol?.startDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("END_DATE")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {getDateTimeWFormat(patrol?.plannedEndDate)}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("STATUS")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {t("" + patrol?.status)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={t("PATROL_STATIONS")}
              data-fa-section="PATROL_STATIONS"
            />
            <CardContent>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={labelClasses.LabelHeader}>
                            #
                          </TableCell>
                          <TableCell className={labelClasses.LabelHeader}>
                            {isTNF("Etkileşim Noktası", "Varlık")}
                          </TableCell>
                          <TableCell className={labelClasses.LabelHeader}>
                            Kontrol Soruları
                          </TableCell>
                          <TableCell className={labelClasses.LabelHeader}>
                            Tarih
                          </TableCell>
                          <TableCell className={labelClasses.LabelHeader}>
                            Kullanıcı
                          </TableCell>
                          <TableCell className={labelClasses.LabelHeader}>
                            Durum
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allData &&
                          allData.length > 0 &&
                          allData?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell className={labelClasses.LabelValue}>
                                {item?.orderNo}
                              </TableCell>
                              <TableCell className={labelClasses.LabelValue}>
                                <ThingDetail value={item?.thing} />
                              </TableCell>
                              <TableCell className={labelClasses.LabelValue}>
                                {item?.status === "SCANNED" && (
                                  <IconButton
                                    aria-label="settings"
                                    onClick={() => {
                                      setSelectedControlItem(
                                        item?.controlItems
                                      );
                                      setOpenControlQuestionsDialog(true);
                                    }}
                                    size="large">
                                    <QuestionAnswerIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                              <TableCell className={labelClasses.LabelValue}>
                                {getDateTimeWFormat(item?.scanDate)}
                              </TableCell>
                              <TableCell className={labelClasses.LabelValue}>
                                <UserDetail value={item?.user} type="USER" />
                              </TableCell>
                              <TableCell className={labelClasses.LabelValue}>
                                {t("" + item?.status)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Suspense>
  </>;
}
