import { faFile, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LocationOn } from "@mui/icons-material";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import { getDateWFormat } from "services/utils";
import i18n from "../../../i18n";
import AnnouncementDetail from "./AnnouncementDetail";
import LikeIcon from "./icons/LikeIcon";
import ReportIcon from "./icons/ReportIcon";
import SuggestionIcon from "./icons/SuggestionIcon";
import { useStyles } from "./LandingTemplate7Style";
import PublicDocumentDownload from "./PublicDocumentDownload";
import PublicPhotos2 from "./PublicPhotos2";

export default function LandingTemplate7(props) {
  const { publicThing, onLinkClick, appLogo, miniAppLogo } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [pageMode, setPageMode] = useState("main");
  const [documentParam, setDocumentParam] = useState(null);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [showDiv, setShowDiv] = useState(true);

  const navigateReportIssue = () => {
    let tmpTo = {
      pathname: `/report/issue/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportLike = () => {
    let tmpTo = {
      pathname: `/report/like/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportSuggestion = () => {
    let tmpTo = {
      pathname: `/report/suggestion/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCustomer = () => {
    let tmpTo = {
      pathname: `/report/customer/tid=${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateSurvey = (survey) => {
    let tmpTo = {
      pathname: `/report/survey/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCampaign = (survey) => {
    let tmpTo = {
      pathname: `/report/registerCampaign/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  // const navigateAnnouncement = (announcement) => {
  //   let tmpTo = {
  //     pathname: `/report/announcement/${announcement.id}/${publicThing.id}/${publicThing.accountId}`,
  //     state: {
  //       publicThing: {
  //         ...publicThing,
  //       },
  //     },
  //   };
  //   history.push(tmpTo);
  // };

  const navigateReportUserContent = () => {
    let tmpTo = {
      pathname: `/report/userContent/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const onImageCount = (imageLength) => {
    if (imageLength > 0) setShowDiv(true);
    else setShowDiv(false);
  };

  const renderMain = () => {
    return (
      <>
        {publicThing &&
          publicThing.interactionPageSettings.ipCampaign &&
          publicThing.campaignList &&
          publicThing.campaignList.length > 0 && (
            <div className={classes.campaignsPanel}>
              {publicThing.campaignList.map((item, index) => {
                return (
                  <div
                    className={classes.campaign}
                    onClick={() => {
                      navigateCampaign(item);
                    }}
                  >
                    {item.thumbnail && (
                      <img
                        src={"data:image/jpeg;base64," + item.thumbnail}
                        alt="campaignLogo"
                        className={classes.campaignLogo}
                      />
                    )}
                    {!item.thumbnail && (
                      <img
                        src={appLogo}
                        alt="campaignLogo"
                        className={classes.campaignLogo}
                      />
                    )}

                    <div className={classes.campaignTexts}>
                      <div className={classes.campaignTitle}>
                        {item.multiLangName?.["tr"]}
                      </div>
                      <div className={classes.campaignDate}>
                        {`${getDateWFormat(
                          item.registerStartDate
                        )} - ${getDateWFormat(item.registerEndDate)}`}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

        <div className={classes.bottomPanel}>
          {publicThing.interactionPageSettings.ipIssue && (
            <Button
              className={classes.issueButton}
              onClick={navigateReportIssue}
            >
              <div>
                <ReportIcon className={classes.bottomPanelIcon} />
                <div>BEĞENMEDİM</div>
              </div>
            </Button>
          )}

          <div style={{ width: "32%" }}>
            <div style={{ height: "50%", textAlign: "center" }}>
              {publicThing.interactionPageSettings.ipSuggestion && (
                <Button
                  className={classes.suggestionButton}
                  onClick={navigateReportSuggestion}
                >
                  <SuggestionIcon style={{ fontSize: 30 }} />
                  <div style={{ fontSize: "80%" }}> ÖNERİ YAP</div>
                </Button>
              )}
            </div>
            <div style={{ height: "50%", textAlign: "center" }}>
              {publicThing.interactionPageSettings.ipUserContent && (
                <Button
                  className={classes.suggestionButton}
                  onClick={navigateReportUserContent}
                >
                  <BackupOutlinedIcon style={{ marginRight: "1%" }} />
                  <div style={{ fontSize: "80%" }}> İÇERİK EKLE</div>
                </Button>
              )}
            </div>
          </div>

          {publicThing.interactionPageSettings.ipLike && (
            <Button className={classes.likeButton} onClick={navigateReportLike}>
              <div>
                <LikeIcon className={classes.bottomPanelIcon} />
                <div>BEĞENDİM</div>
              </div>
            </Button>
          )}
        </div>
      </>
    );
  };

  const getDocumentsAndLinksCount = () => {
    let docs =
      publicThing?.documentList && publicThing?.documentList.length > 0
        ? publicThing?.documentList.length
        : 0;
    let links =
      publicThing?.thingLinkList && publicThing?.thingLinkList.length > 0
        ? publicThing?.thingLinkList.length
        : 0;

    return docs + links;
  };

  const renderDetail = () => {
    return (
      <>
        {publicThing.interactionPageSettings.ipLocation &&
          publicThing.gpsLocation &&
          publicThing.gpsLocation.length > 5 && (
            <div
              className={classes.linkItem}
              onClick={() => {
                window
                  .open(ReportService.getLocationLink(publicThing), "_blank")
                  .focus();
              }}
            >
              <LocationOn icon={faLink} style={{ marginRight: 5 }} />{" "}
              {t("LOCATION")}
            </div>
          )}
        {((publicThing?.documentList && publicThing?.documentList.length > 0) ||
          (publicThing?.thingLinkList &&
            (publicThing.interactionPageSettings.ipDocument ||
              publicThing.interactionPageSettings.ipLink) &&
            publicThing?.thingLinkList.length > 0)) && (
          <div className={classes.linksPanel}>
            <div className={classes.linksTitle}>
              Dokümanlar ve Linkler ({getDocumentsAndLinksCount()})
            </div>
            <PublicDocumentDownload
              value={documentParam}
              publicThing={publicThing}
              onClose={() => {
                setDocumentParam(null);
              }}
            />
            {publicThing.interactionPageSettings.ipDocument &&
              publicThing?.documentList?.map((item, index) => (
                <div
                  className={classes.linkItem}
                  onClick={() => {
                    setDocumentParam(item);
                  }}
                >
                  <FontAwesomeIcon icon={faFile} style={{ marginRight: 5 }} />{" "}
                  {item.name ?? item.fileName}
                </div>
              ))}
            {publicThing.interactionPageSettings.ipLink &&
              publicThing?.thingLinkList?.map((item, index) => (
                <div
                  className={classes.linkItem}
                  onClick={() => {
                    window.open(item.url, "_blank").focus();
                    onLinkClick(item);
                  }}
                >
                  <FontAwesomeIcon icon={faLink} style={{ marginRight: 5 }} />{" "}
                  {item.description ?? item.url}
                </div>
              ))}
          </div>
        )}
      </>
    );
  };

  const surveyName = (item) => {
    let langs = item?.languages;
    let name = "";
    langs.map((lang, index) => {
      if (index > 0) {
        name = name.concat(" / ");
      }
      name = name.concat(item?.multiLangName?.[lang.toLowerCase()]);
    });
    return name;
  };

  return (
    <Box className={classes.root}>
      <div className={classes.banner}></div>

      {publicThing.logo && (
        <img
          src={"data:image/jpeg;base64," + publicThing.logo}
          alt="logo"
          className={classes.appLogo}
        />
      )}
      {!publicThing.logo && (
        <img src={miniAppLogo} alt="logo" className={classes.appLogo} />
      )}
      <div className={classes.bannerAccount}>{publicThing.accountName}</div>
      <div className={classes.bannerThing}>{publicThing.thingDesc}</div>
      {publicThing.interactionPageSettings.ipCustomer && (
        <Button
          className={classes.customerButton}
          onClick={() => {
            navigateCustomer();
          }}
        >
          ÜYE OL
        </Button>
      )}
      {pageMode === "main" &&
        publicThing.interactionPageSettings.ipDetailInfo && (
          <Button
            className={classes.infoButton}
            onClick={() => {
              setPageMode("detail");
            }}
          >
            DETAYLI BİLGİ
          </Button>
        )}
      {pageMode === "detail" && (
        <Button
          className={classes.infoButton}
          onClick={() => {
            setPageMode("main");
          }}
        >
          ANA SAYFA
        </Button>
      )}

      {publicThing.interactionPageSettings.ipImage && showDiv && (
        <div className={classes.photosDiv}>
          <PublicPhotos2
            publicThing={publicThing}
            onImageCount={onImageCount}
          />
        </div>
      )}

      <div className={classes.midPanel}>
        {publicThing &&
          publicThing.interactionPageSettings.ipSurvey &&
          publicThing.surveyList &&
          publicThing.surveyList.length > 0 &&
          publicThing.surveyList.map((item, index) => {
            return (
              <Button
                className={classes.surveyButton}
                onClick={() => {
                  navigateSurvey(item);
                }}
              >
                {surveyName(item)}
              </Button>
            );
          })}
        {selectedAnnouncement && (
          <AnnouncementDetail
            announcement={selectedAnnouncement}
            onClose={() => {
              setSelectedAnnouncement(null);
            }}
          />
        )}
        {publicThing &&
          publicThing.interactionPageSettings.ipAnnouncement &&
          publicThing.announcementList &&
          publicThing.announcementList.length > 0 &&
          publicThing.announcementList.map((item, index) => {
            return (
              <div style={{ marginTop: 20 }}>
                <div
                  className={classes.campaign}
                  onClick={() => setSelectedAnnouncement(item)}
                >
                  <div className={classes.campaignTexts}>
                    <div className={classes.campaignTitle}>{item.subject}</div>

                    <div className={classes.campaignDate}>
                      {`${getDateWFormat(item.startDate)}`}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        {publicThing.interactionPageSettings.ipCustomer && (
          <Button
            className={classes.registerButton}
            onClick={() => {
              navigateCustomer();
            }}
          >
            HABERDAR OL
          </Button>
        )}
      </div>

      {pageMode === "main" && renderMain()}
      {pageMode === "detail" && renderDetail()}
    </Box>
  );
}

LandingTemplate7.propTypes = {
  loginPath: PropTypes.string,
  onLinkClick: PropTypes.any,
  appLogo: PropTypes.any,
  miniAppLogo: PropTypes.any,
};
