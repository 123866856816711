import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { GetFieldByLanguage } from "services/utils";

export default function SurveyQuestionDropdown(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;

  return (
    <Grid item xs={12}>
      <FormControl
        variant="standard"
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}>
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
        <Select
          variant="standard"
          required={question.notEmpty}
          style={{ width: "50%" }}
          value={"" + answer?.surveyQuestionOption?.id}
          onChange={(event) => {
            if (event.target.value)
              onChange(
                question,
                { id: event.target.value },
                "surveyQuestionOption"
              );
            else onChange(question, event.target.value, "surveyQuestionOption");
          }}
          label={GetFieldByLanguage(question.multiLangName, currentLang)}
          disabled={readOnly}>
          {question.options.map((item, index) => {
            return (
              <MenuItem key={index} value={"" + item.id}>
                {GetFieldByLanguage(item.multiLangName, currentLang)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionDropdown.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
