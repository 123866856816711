import { Grid, Link } from "@mui/material";
import React from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";

export default function MultipleFileDisplay(props) {
  const { value, thingId, onClose, open } = props;

  const { t } = useTranslation("dynamicFieldDialog", { i18n });

  const downloadDocument = (fileId) => {
    ThingService.downloadDocument(
      thingId,
      fileId,
      downloadDocumentOnSuccess,
      downloadDocuemntOnError
    );
  };

  const downloadDocumentOnSuccess = (data) => {
    //const url = window.URL.createObjectURL(new Blob([data.content]));
    const link = document.createElement("a");
    link.href = "data:application/octet-stream;base64," + data.content; //url;
    link.setAttribute("download", data.fileName);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocuemntOnError = (data) => {};

  const renderFiles = () => {
    let list = [];
    for (var lang in value.defaultFileValue) {
      if (value.defaultFileValue.hasOwnProperty(lang)) {
        list.push({ lang: lang, value: value.defaultFileValue[lang] });
      }
    }

    return (
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        {list.map((item, index) => renderFile(item, index))}
      </Grid>
    );
  };

  const renderFile = (item, index) => {
    return <>
      <Grid item xs={2}>
        <ReactCountryFlag
          countryCode={
            item.lang.toUpperCase() === "EN" ? "US" : item.lang.toUpperCase()
          }
          svg
        />
      </Grid>
      <Grid item xs={10}>
        <Link
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            downloadDocument(item.value.value);
          }}
          underline="hover">
          {item.value.label ?? "İndir"}
        </Link>
      </Grid>
    </>;
  };
  return (
    <FaDialog
      title={value?.label}
      faOpen={open}
      faHandleClose={onClose}
      faMaxWidth="md"
    >
      {value && renderFiles()}
    </FaDialog>
  );
}
