import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ReplayIcon from "@mui/icons-material/Replay";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import CustomerIneractionOperationSelect from "components/select/CustomerIneractionOperationSelect";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaButton, FaSearch } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import Map, { GeolocateControl, Layer, Popup, Source } from "react-map-gl";
import { useHistory, useLocation } from "react-router-dom";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { ParseLocation } from "services/utils";
import i18n from "../../i18n";
import {
  clusterCountLayer,
  clusterLayer,
  heatmapLayer,
  unclusteredPointLayer,
} from "../thingsMap/layers";
import ControlPanel from "./controlPanel";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiYmlsYWxha2luY2kiLCJhIjoiY2thcDJhb2RoMHdoZzJ6cGZnOHZ6cDFicCJ9._lPdrre7P6yDWV2F3vbwpA"; // Set your mapbox token here

const geolocateStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  margin: 10,
};

function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  var date1 = new Date(a);
  var date2 = new Date(b);

  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((utc1 - utc2) / _MS_PER_DAY);
}

function filterFeaturesByDay(featureCollection, time) {
  const dateStart = new Date(time[0]);
  const dateEnd = new Date(time[1]);

  const features = featureCollection.features.filter((feature) => {
    const featureDate = new Date(feature.date);
    return featureDate >= dateStart && featureDate <= dateEnd;
  });
  return { type: "FeatureCollection", features };
}

const CustomerInteractionMap = forwardRef((props, ref) => {
  const { open, onClose } = props;
  const { t } = useTranslation("customerInteraction", { i18n });
  const mapRef = useRef();
  const [popupInfo, setPopupInfo] = useState(null);
  let history = useHistory();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [allDays, setAllDays] = useState(true);
  const [selectedTime, setSelectedTime] = useState([0, 0]);
  const [noCoordCount, setNoCoordCount] = useState(0);

  const [unique, setUnique] = useState(false);

  const [mapType, setMapType] = useState("numericMap");

  const location = useLocation();

  const [dailyCounts, setDailyCounts] = useState(null);

  const [marks, setMarks] = useState([]);

  const accountId = localStorage.accountId;

  useImperativeHandle(ref, () => ({
    switchHeatMap() {
      setMapType("heatMap");
    },
    switchNumericMap() {
      setMapType("numericMap");
    },
    switchAllDays() {
      setAllDays(!allDays);
    },
  }));

  const newSearchForm = () => {
    let result = {
      accountId: accountId,
      thing: null,
      thingId: null,
      operation: null,
      operationList: null,
      operationDateValue: null,
      operationDateStart: null,
      operationDateEnd: null,
      limit: 20, //chartLimit
    };

    return result;
  };

  const [searchForm, setSearchForm] = useState(
    location?.state?.searchFrom ?? newSearchForm()
  );

  const getDailyCountsOnSuccess = (data) => {
    setDailyCounts(data);
    showAllInteractions();
  };

  const divRef = useRef();

  const refreshData = () => {
    CustomerInteractionService.getDailyCounts(
      searchForm,
      getDailyCountsOnSuccess,
      (error) => {}
    );
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (dailyCounts) setSelectedTime([startDate, endDate]);
    showAllInteractions();
  }, [open, unique]);

  const initialViewPort = () => {
    return {
      latitude: 39.862645729977174,
      longitude: 32.738810051232576,
      zoom: 6,
      bearing: 0,
      pitch: 0,
    };
  };
  const [viewport, setViewPort] = useState(initialViewPort());

  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });

  const [noCoordGeojson, setNoCoordGeojson] = useState({
    type: "FeatureCollection",
    features: [],
  });

  const showAllInteractions = () => {
    const paging = { page: 0, size: 10000, isAsc: false, sort: "date" };
    CustomerInteractionService.search(
      { ...searchForm, unique: unique },
      paging,
      findCustomerInteractionOnSuccess,
      (error) => {}
    );
  };

  const findCustomerInteractionOnSuccess = (data) => {
    const temp = data
      .filter((x) => x.gpsLocation || x.ipGpsLocation)
      .map((item) => {
        var location = ParseLocation(item.gpsLocation ?? item.ipGpsLocation);
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [location.lng, location.lat],
            mag: 1,
          },
          properties: {
            id: item.id,
          },
          date: item.date,
        };
      });
    const maxDate = Math.max(
      ...data.map((element) => {
        return new Date(element.date).getTime();
      })
    );

    const minDate = Math.min(
      ...data.map((element) => {
        return new Date(element.date).getTime();
      })
    );

    const noCoord = data.filter(
      (x) => !!!x.gpsLocation && !!!x.ipGpsLocation
    ).length;

    setStartDate(minDate);
    setEndDate(maxDate);

    setSelectedTime([minDate, maxDate]);

    let tmpMarks = temp.map((item) => {
      return dateDiffInDays(item.date, minDate);
    });
    tmpMarks = [...new Set(tmpMarks), 0]; // removes duplicates
    let newMarks = tmpMarks.map((x) => {
      return { value: x };
    });
    setMarks(newMarks);

    setGeojson({
      type: "FeatureCollection",
      features: temp,
    });

    setNoCoordGeojson({
      type: "FeatureCollection",
      features: data
        .filter((x) => !!!x.gpsLocation && !!!x.ipGpsLocation)
        .map((x) => {
          return { date: x.date };
        }),
    });
    setNoCoordCount(noCoord);
  };

  const data = useMemo(() => {
    return allDays ? geojson : filterFeaturesByDay(geojson, selectedTime);
  }, [geojson, allDays, selectedTime]);

  const searchSubmit = () => {
    refreshData();
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    searchSubmit();
  };

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const returnBack = () => {
    history.goBack();
  };

  const backToInitialView = () => {
    setViewPort(initialViewPort());
  };

  return (
    <Grid item container row>
      <Grid item container spacing={1} style={{ marginTop: 5 }}>
        <Grid item xs={2}></Grid>
        <Grid item xs={4}></Grid>
        <Grid item xs={6}>
          <FaSearch
            onSearch={searchSubmit}
            onClear={searchClear}
            onSummarySearch={searchSummary}
            faSummary={searchForm?.summary}
            faClassName="appSearchBar"
          >
            <ThingAutoComplete
              accountId={accountId}
              value={searchForm.thing}
              onChange={(data) => {
                handleChangeGeneric(
                  setSearchForm,
                  searchForm,
                  "thing",
                  data,
                  "thingId",
                  data?.id
                );
              }}
            />

            <CustomerIneractionOperationSelect
              value={searchForm.operation}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  operation: data,
                });
              }}
            />

            <DateRangeSelector
              label={t("DATE")}
              value={{
                id: searchForm.operationDateId,
                startDate: searchForm.operationDateStart,
                endDate: searchForm.operationDateEnd,
              }}
              onChange={(data) => {
                setSearchForm({
                  ...searchForm,
                  operationDateStart: data.startDate,
                  operationDateEnd: data.endDate,
                  operationDateId: data.id,
                });
              }}
            />
          </FaSearch>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: 5 }}
      >
        <Grid item xs={1} style={{ marginBottom: 3 }}>
          <FaButton
            variant="contained"
            color="primary"
            size="medium"
            faClick={() => returnBack()}
            startIcon={<KeyboardBackspaceIcon />}
            data-fa-button="BACK_BUTTON"
          >
            {t("BACK")}
          </FaButton>
        </Grid>
        <Grid item xs={2} style={{ marginBottom: 3, marginLeft: 3 }}>
          <FaButton
            variant="contained"
            color="primary"
            size="medium"
            faClick={() => backToInitialView()}
            startIcon={<ReplayIcon />}
          >
            {t("INITIAL_VIEW")}
          </FaButton>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: "70vh" }}>
        <Map
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/light-v10"
          onMove={(evt) => setViewPort(evt.viewState)}
          mapboxAccessToken={MAPBOX_TOKEN}
          //onClick={onClick}
          ref={mapRef}
          interactiveLayerIds={[unclusteredPointLayer.id, clusterLayer.id]}
        >
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          {mapType === "numericMap" && (
            <Source
              id="things"
              type="geojson"
              data={data}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...clusterLayer} />
              <Layer {...clusterCountLayer} />

              <Layer {...unclusteredPointLayer} />
            </Source>
          )}
          {mapType === "heatMap" && (
            <Source id="things" type="geojson" data={data}>
              <Layer {...heatmapLayer} />
            </Source>
          )}
          {popupInfo && (
            <Popup
              anchor="top"
              longitude={Number(popupInfo.lng)}
              latitude={Number(popupInfo.lat)}
              closeOnClick={false}
              onClose={() => setPopupInfo(null)}
            ></Popup>
          )}
        </Map>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ marginTop: 7 }}>
          {"Konumu paylaşılmamış etkileşim #: "}
          {allDays
            ? noCoordCount
            : filterFeaturesByDay(noCoordGeojson, selectedTime)?.features
                .length}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ControlPanel
          startTime={startDate}
          endTime={endDate}
          selectedTime={selectedTime}
          allDays={allDays}
          onChangeTime={setSelectedTime}
          onChangeAllDays={setAllDays}
          unique={unique}
          onChangeUnique={setUnique}
          mapType={mapType}
          onChangeMapType={setMapType}
          marks={marks}
        />
      </Grid>
    </Grid>
  );
});

export default CustomerInteractionMap;

CustomerInteractionMap.propTypes = {
  searchForm: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
