import {
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

export default function SolutionServiceOperations(props) {
  const { solution } = props;
  const { t } = useTranslation("solutions", { i18n });
  const labelClasses = labelStyles();

  return (
    <>
      <Card>
        <CardHeader
          title={t("SOLUTION_DETAILS")}
          data-fa-section="SOLUTION_DETAILS"
        />
        <CardContent>
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody>
                {solution?.serviceOperationClasses.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={labelClasses.LabelValue}>
                      {item.className}
                    </TableCell>
                  </TableRow>
                ))}
                {solution.serviceOperationClasses == null ||
                solution.serviceOperationClasses.length === 0 ? (
                  <TableRow>
                    <TableCell className={labelClasses.LabelValue}>
                      Yapılan işlem kaydı bulunmamaktadır.
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
