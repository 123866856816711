import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import FeedbackConfirmTypeSelect from "components/select/FeedbackConfirmTypeSelect";
import VerificationTypeSelect from "components/select/VerificationTypeSelect";
import YesNoSelect from "components/select/YesNoSelect";
import ThingsSwitch from "components/ui/ThingsSwitch";
import React, { useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { AccountPreferencesService } from "services/AccountPreferencesService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountPreferencesFeedback(props) {
  const { accountPreferences, onSave } = props;
  const { t } = useTranslation(["accountDetails", "components"], {
    i18n,
  });
  const classes = useStyles();
  const labelClasses = labelStyles();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editValue, setEditValue] = useState({});

  const editButtonClicked = () => {
    setEditValue({ ...accountPreferences });
    setIsEditOpen(true);
  };

  const handleChangeV = (prop) => (data) => {
    setEditValue({ ...editValue, [prop]: data });
  };

  const handleChange = (prop) => (event) => {
    setEditValue({ ...editValue, [prop]: event.target.value });
  };

  const saveFeedbacks = () => {
    AccountPreferencesService.saveFeedbacks(
      editValue,
      () => {
        setIsEditOpen(false);
        onSave();
      },
      () => {}
    );
  };

  const renderDialog = () => {
    return (
      isEditOpen && (
        <FaDialog
          title={"Bildirim Seçenekleri"}
          faOpen={isEditOpen}
          faOnSubmit={saveFeedbacks}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditOpen(false);
          }}
          faMaxWidth="sm"
        >
          <Grid
            container
            spacing={1}
            data-fa-section="ACCOUNT_PREFERENCES_DIALOG"
          >
            <Grid item lg={12} xs={12}>
              <FeedbackConfirmTypeSelect
                value={editValue.feedbackTaskAssign}
                label={t("Görev atama sonrası bildirim gönder")}
                onChange={handleChangeV("feedbackTaskAssign")}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <FeedbackConfirmTypeSelect
                value={editValue.feedbackTaskClose}
                label={t("Görev kapatma sonrası bildirim gönder")}
                onChange={handleChangeV("feedbackTaskClose")}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <VerificationTypeSelect
                value={editValue.publicReportVerificationType}
                label={t("Sorun Bildirimi Kullanıcı Doğrulama Modeli")}
                onChange={handleChangeV("publicReportVerificationType")}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <VerificationTypeSelect
                value={editValue.publicReportSuggestionVerificationType}
                label={t("Öneri Kullanıcı Doğrulama Modeli")}
                onChange={handleChangeV(
                  "publicReportSuggestionVerificationType"
                )}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <VerificationTypeSelect
                value={editValue.publicReportLikeVerificationType}
                label={t("Beğeni Kullanıcı Doğrulama Modeli")}
                onChange={handleChangeV("publicReportLikeVerificationType")}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <VerificationTypeSelect
                value={editValue.publicReportUserContentVerificationType}
                label={t("Kullanıcı İçeriği Kullanıcı Doğrulama Modeli")}
                onChange={handleChangeV(
                  "publicReportUserContentVerificationType"
                )}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <FaInput
                label={t("Ardışık bildirim bekleme süresi (Dakika)")}
                faType="number"
                onChange={handleChange("cooldownIssue")}
                value={editValue.cooldownIssue}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <FaInput
                label={t("Ardışık bildirim öneri süresi (Dakika)")}
                faType="number"
                onChange={handleChange("cooldownSuggestion")}
                value={editValue.cooldownSuggestion}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <FaInput
                label={t("Ardışık bildirim beğeni süresi (Dakika)")}
                faType="number"
                onChange={handleChange("cooldownLike")}
                value={editValue.cooldownLike}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <FaInput
                label={t("Müşteri 'beni hatırla' süresi (Saat)")}
                faType="number"
                onChange={handleChange("cooldownRememberCustomer")}
                value={editValue.cooldownRememberCustomer}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <ThingsSwitch
                label={t(
                  "Bildirimde varlık seçimi yapmadan devam et (varsayılan)"
                )}
                value={editValue.reportIssueDefaultNoThing}
                onChange={handleChangeV("reportIssueDefaultNoThing")}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <ThingsSwitch
                label={t("Bildirim kapanışında kullanıcı doğrulaması")}
                value={editValue.closedIssueNotifierConfirmation}
                onChange={handleChangeV("closedIssueNotifierConfirmation")}
              />
            </Grid>
            {editValue.closedIssueNotifierConfirmation && (
              <Grid item lg={12} xs={12}>
                <FaInput
                  label={t("Otomatik kullanıcı onay süresi (Dakika)")}
                  faType="number"
                  onChange={handleChange("issueAutoConfirmationTime")}
                  value={editValue.issueAutoConfirmationTime}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <YesNoSelect
                label={t("Bildirim yapan kişinin bilgilerini göster")}
                value={editValue.shareNotificationContactInfo}
                onChange={handleChangeV("shareNotificationContactInfo")}
              />
            </Grid>
          </Grid>
        </FaDialog>
      )
    );
  };

  return <>
    {renderDialog()}
    <Card style={{ marginTop: "16px" }}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            onClick={editButtonClicked}
            data-fa-section="NOTIFICATION_PREFERENCES_BUTTON"
            size="large">
            <EditIcon />
          </IconButton>
        }
        title={t("NOTIFICATION_PREFERENCES")}
        data-fa-section="NOTIFICATION_PREFERENCES"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid
              item
              container
              spacing={1}
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Görev atama sonrası bildirim gönder")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.feedbackTaskAssign &&
                    t("" + accountPreferences?.feedbackTaskAssign)}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Görev kapatma sonrası bildirim gönder")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.feedbackTaskClose &&
                    t("" + accountPreferences?.feedbackTaskClose)}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Sorun Bildirimi Kullanıcı Doğrulama Modeli")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.publicReportVerificationType &&
                    t(
                      "VERIFICATION_TYPE_" +
                        accountPreferences?.publicReportVerificationType,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Öneri Kullanıcı Doğrulama Modeli")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.publicReportSuggestionVerificationType &&
                    t(
                      "VERIFICATION_TYPE_" +
                        accountPreferences?.publicReportSuggestionVerificationType,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Beğeni Bildirimi Kullanıcı Doğrulama Modeli")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.publicReportLikeVerificationType &&
                    t(
                      "VERIFICATION_TYPE_" +
                        accountPreferences?.publicReportLikeVerificationType,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Kullanıcı İçeriği Kullanıcı Doğrulama Modeli")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.publicReportUserContentVerificationType &&
                    t(
                      "VERIFICATION_TYPE_" +
                        accountPreferences?.publicReportUserContentVerificationType,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Ardışık bildirim bekleme süresi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.cooldownIssue &&
                    accountPreferences?.cooldownIssue + t(" Dakika")}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Ardışık öneri bekleme süresi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.cooldownSuggestion &&
                    accountPreferences?.cooldownSuggestion + t(" Dakika")}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Ardışık beğeni bekleme süresi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.cooldownLike &&
                    accountPreferences?.cooldownLike + t(" Dakika")}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Müşteri 'beni hatırla' süresi")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.cooldownRememberCustomer &&
                    accountPreferences?.cooldownRememberCustomer + t(" Saat")}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t(
                    "Bildirimde varlık seçimi yapmadan devam et (varsayılan)"
                  )}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.reportIssueDefaultNoThing === true &&
                    t("YES_NO_SELECT_YES", { ns: "components" })}
                  {accountPreferences?.reportIssueDefaultNoThing !== true &&
                    t("YES_NO_SELECT_NO", { ns: "components" })}
                </Typography>
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Bildirim kapanışında kullanıcı doğrulaması")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.closedIssueNotifierConfirmation ===
                    true && t("YES_NO_SELECT_YES", { ns: "components" })}
                  {accountPreferences?.closedIssueNotifierConfirmation !==
                    true && t("YES_NO_SELECT_NO", { ns: "components" })}
                </Typography>
              </Grid>
              {accountPreferences?.closedIssueNotifierConfirmation ===
                true && (
                <Grid item xs={6} className={labelClasses.LabelRow}>
                  <Typography className={labelClasses.LabelHeader}>
                    {t("Otomatik kullanıcı onay süresi")}
                  </Typography>
                  <Typography className={labelClasses.LabelValue}>
                    {accountPreferences.issueAutoConfirmationTime &&
                      accountPreferences.issueAutoConfirmationTime +
                        " " +
                        t("Dakika", { ns: "components" })}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={6} className={labelClasses.LabelLastRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("Bildirim yapan kişinin bilgilerini göster ")}
                </Typography>
                <Typography className={labelClasses.LabelValue}>
                  {accountPreferences?.shareNotificationContactInfo &&
                    t(
                      "YES_NO_SELECT_" +
                        accountPreferences?.shareNotificationContactInfo,
                      { ns: "components" }
                    )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>;
}
