import { SvgIcon } from "@mui/material";
import { default as React } from "react";

export default function SuggestionIcon(props) {
  return (
    <SvgIcon viewBox="0 0 200 200" {...props}>
      <path
        d="M143.282,41.743H56.718c-7.956,0-14.427,6.472-14.427,14.427v62.519c0,7.956,6.472,14.427,14.427,14.427h4.809v25.14
		l28.277-25.14h53.478c7.956,0,14.427-6.472,14.427-14.427V56.171C157.709,48.215,151.238,41.743,143.282,41.743z M148.091,118.689
		c0,2.651-2.155,4.809-4.809,4.809H86.15l-15.005,13.336v-13.336H56.718c-2.654,0-4.809-2.158-4.809-4.809V56.171
		c0-2.651,2.155-4.809,4.809-4.809h86.564c2.654,0,4.809,2.158,4.809,4.809V118.689z"
      />
      <circle cx="75.752" cy="85.025" r="4.809" />
      <circle cx="99.798" cy="85.025" r="4.809" />
      <circle cx="123.844" cy="85.025" r="4.809" />
    </SvgIcon>
  );
}
