import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubscriptionService } from "services/SubscriptionService";
import { getDateWFormat } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function Invoices() {
  const tableRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("subscriptions", { i18n });
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  const [invoiceList, setInvoiceList] = useState([]);

  useEffect(() => {
    reloadList();
  }, []);

  const reloadList = () => {
    SubscriptionService.findInvoiceList(
      accountId,
      (data) => {
        setInvoiceList(data);
      },
      (error) => {}
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Card style={{ minHeight: 100 }}>
              <CardHeader data-fa-section="Faturalar" />
              <CardContent>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("PAYMENT_TYPE")}</TableCell>
                          <TableCell>{t("INVOICE_NUMBER")}</TableCell>
                          <TableCell>{t("DATE")}</TableCell>
                          <TableCell>{t("AMOUNT")}</TableCell>
                          <TableCell>{t("CURRENCY")}</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {invoiceList.map((value, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{value.invoiceNo}</TableCell>
                              <TableCell>{value.invoiceNo}</TableCell>
                              <TableCell>
                                {getDateWFormat(value.invoiceDate)}
                              </TableCell>
                              <TableCell>{value.totalPrice}</TableCell>
                              <TableCell>{value.currency}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
