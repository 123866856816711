import { Icon } from "@iconify/react";
import { Grid, Typography } from "@mui/material";
import Adsense from "components/adsense/Adsense";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingTableDecorator from "components/decorator/ThingTableDecorator";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { calculateDate, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { LIKE } from "../../services/faApi";

export const getSocialMediaIcon = (socialMedia) => {
  if (socialMedia === "FACEBOOK") return <Icon icon="logos:facebook" />;
  else if (socialMedia === "TWITTER") return <Icon icon="logos:twitter" />;
  else if (socialMedia === "GOOGLE") return <Icon icon="logos:google-icon" />;
  else if (socialMedia === "INSTAGRAM") return <Icon icon="logos:instagram" />;
  else if (socialMedia === "TRIPADVISOR")
    return <Icon icon="fontisto:tripadvisor" />;
  else if (socialMedia === "LINKEDIN")
    return <Icon icon="skill-icons:linkedin" />;
};

export default function Like(props) {
  const tableRef = useRef();
  const { t } = useTranslation("like", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "likeDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      type: "smartDate",
      width: "20%",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      inner: "description",
      width: "60%",
      valueFormatter: (value, row) => {
        return <ThingTableDecorator value={row.thing} />;
      },
    },
    {
      field: "accessLogRecords",
      label: t("SOCIAL_MEDIA"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        if (value && value.length > 0) {
          return (
            <div style={{ display: "flex" }}>
              {value.map((item) => (
                <div style={{ marginRight: 10 }}>
                  {getSocialMediaIcon(item.socialMediaType)}
                </div>
              ))}
            </div>
          );
        }
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      thing: null,
      thingId: null,
      likeDateStart: props.startDate ? props.startDate : null,
      likeDateEnd: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `like/detail/${row.id}`,
      breadCrumbKey: `/like/detail/:id`,
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Adsense value="RklmAlt" />
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    likeDateStart: data.startDate,
                    likeDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <Grid
          item
          container
          spacing={0}
          style={{ marginBottom: 5, overflowY: "auto" }}
        >
          <Grid item lg={4} md={6} xs={12} id="dateSpeedFilter">
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TARIH")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  value={searchForm.notificationDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      notificationDateValue: data,
                      likeDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={LIKE.search + "?accountId=" + localStorage.accountId}
          exportUrl={LIKE.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "likeDate",
            accountId: localStorage.accountId,
          }}
        />
      </Suspense>
    </>
  );
}
