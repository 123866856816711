import {
  faApple,
  faFacebookF,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import CountryAutoComplete from "components/autocomplete/CountryAutoComplete";
import DataCenterSelect from "components/select/DataCenterSelect";
import LanguageSelect from "components/select/LanguageSelect";
import { THINGS_ENVIRONMENT } from "environments/Environments";
import LanguageSelectButton from "features/login/LanguageSelectButton";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useRef, useState } from "react";
import AppleLogin from "react-apple-login";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaInput, FaUnAuthPages } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { clearArr } from "react-base-fa/dist/fa/faBreadCrumb/breadCrumbSlice";
import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  loginThunk,
  setUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { getCurrentLang } from "react-base-fa/dist/services/utils";
import FacebookLoginCustomCSS from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import PasswordChecklist from "react-password-checklist";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";
import { LegalTextService } from "services/LegalTextService";
import { DEFAULT_COUNTRY, getThingsApp } from "services/utils";
import { APP, RECAPTCHA_CODE } from "services/utils/Constants";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { AUT, THINGS_USER, USER } from "../../services/faApi";
import SignUpSalesPackages from "./SignUpSalesPackages";

export default function SignUp(props) {
  const { backGroundImage, loginPath, appLogo } = props;
  const faDialog = useDialog();
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation(["signUp", "errorMessages"], { i18n });
  const history = useHistory();
  const [showError, setShowError] = useState(false);

  const [scene, setScene] = useState("REGISTER_FORM"); //REGISTER_FORM, CONTRACTS

  const [errorMessages, setErrorMessages] = useState({
    fullname: "",
    phone: "",
    password: "",
    email: "",
    country: "",
  });
  const [socialMediaErrorMessage, setSocialMediaErrorMessage] = useState(null);

  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    fullname: true,
    phone: true,
    password: true,
    email: true,
    country: true,
    kvkk: true,
    kullaniciSozlesme: true,
    uyelikSozlesme: true,
  });
  const [isFieldsValid, setIsFieldsValid] = useState({
    email: true,
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [userInvitation, setUserInvitation] = useState();
  const [values, setValues] = useState({
    password: "",
    passwordConfirm: "",
    email: userInvitation && userInvitation.email,
    fullname: "",
    phone: "",
    country: DEFAULT_COUNTRY,
    iletiAl: false,
    iletiAlLegalTextId: null,
    kvkk: false,
    kvkkLegalTextId: null,
    kvkkOnay: false,
    kvkkOnayLegalTextId: null,
    kullaniciSozlesme: false,
    kullaniciSozlesmeLegalTextId: null,
    uyelikSozlesme: false,
    uyelikSozlesmeLegalTextId: null,
    selectedProduct: null,
    newAccount: {
      fullname: "",
      country: DEFAULT_COUNTRY,
      dataCenter: "TR",
      language: "TR",
      things2Care: getThingsApp() === APP.T2C ? true : false,
    },
    registerType: "FORM",
    apple: null,
  });
  const [loading, setLoading] = useState(false);
  let formRef = createRef();

  let recaptchaRef = useRef();
  const lang = getCurrentLang();
  const theme = useSelector(selectThemePrefer);
  const dispatch = useDispatch();
  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(false);

  //box dışındaki çerçeveleri çıkar
  var elements = document.getElementsByClassName("MuiPaper-elevation6");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].className = "show-class";
    }
  }

  useEffect(() => {
    if (values.country && values.phone && values.phone.length <= 5) {
      setValues({ ...values, phone: values.country.phoneCode });
    }
  }, [values.country]);

  async function moveConfirmation(event) {
    if (event) event.preventDefault();

    setLoading(true);

    if (values.registerType === "FORM") {
      let tempData = { ...values };
      const regEx = new RegExp(/[\s()*]/g);

      tempData.phone = tempData.phone.replaceAll(regEx, "");

      let confLoc = {
        pathname: "/confirmation",
        state: {
          formData: tempData,
        },
      };
      //console.log("tempData->",tempData);
      history.push(confLoc);
    } else if (values.registerType === "GOOGLE") {
      let req = requests.getSimplePostRequest(
        THINGS_USER.thingsGoogleVerify,
        values
      );
      FaAxios(req)
        .then((resp) => {
          logIn(values.email);
        })
        .catch((error) => {
          console.log("socialMediaRegister error->", error);
          setLoading(false);
        });
    } else if (values.registerType === "FACEBOOK") {
      let req = requests.getSimplePostRequest(
        THINGS_USER.thingsFacebookVerify,
        values
      );
      FaAxios(req)
        .then((resp) => {
          logIn(values.email);
        })
        .catch((error) => {
          console.log("socialMediaRegister error->", error);
          setLoading(false);
        });
    } else if (values.registerType === "APPLE") {
      try {
        const tempData = {
          ...values,
          apple: {
            ...values.apple,
            firstName: values.fullname,
            lastName: null,
          },
        };

        const res = unwrapResult(
          await dispatch(
            loginThunk(
              requests.getSimplePostRequest(
                THINGS_USER.thingsAppleRegister,
                tempData
              )
            )
          )
        );

        console.log("appleResponse res", res);
        if (res && res.ERROR) {
          switch (res.ERROR) {
            case "USER_SOCIAL_MEDIA_EXISTS":
              setSocialMediaErrorMessage(
                t("errorMessages:USER_SOCIAL_MEDIA_EXISTS")
              );
              setLoading(false);
              break;
            case "EMAIL_EXISTS":
              setSocialMediaErrorMessage(t("errorMessages:EMAIL_EXISTS"));
              setLoading(false);
              break;
            default:
              break;
          }
        } else {
          dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
          dispatch(clearArr());
          history.push("/");
        }
      } catch (err) {
        console.log("appleResponse res2", err);
        console.log("error", `Fetch failed: ${err.message}`);
        console.log(err);
        switch (err && err.response && err.response.status) {
          case 302:
            setSocialMediaErrorMessage(t("EPOSTA_MEVCUT"));
            setLoading(false);
            break;
          default:
            break;
        }
      }
    }
  }

  const handleChangeAccount = (prop) => (event) => {
    const tmpAccount = { ...values.newAccount, [prop]: event.target.value };
    setValues({ ...values, newAccount: { ...tmpAccount } });
  };

  const handleChangeVAccount = (prop, value) => {
    const tmpAccount = { ...values.newAccount, [prop]: value };
    setValues({ ...values, newAccount: { ...tmpAccount } });
  };

  const showContracts = (event) => {
    if (event) event.preventDefault();
    setScene("CONTRACTS");
  };

  const CheckEmail = () => {
    //setErrorMessages({...errorMessages,["email"]:t("EPOSTA_MEVCUT")})
    let req = requests.getSimpleGetRequest(
      USER.findByEmail + "/" + values.email
    );
    FaAxios(req)
      .then((response) => {
        setErrorMessages({ ...errorMessages, ["email"]: t("EPOSTA_MEVCUT") });
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 302) {
            setErrorMessages({
              ...errorMessages,
              ["email"]: t("EPOSTA_MEVCUT"),
            });
          } else {
            setErrorMessages({ ...errorMessages, ["email"]: "" });
          }
        }
      });
  };
  const CheckPhone = (newPhoneValue) => {
    let myPhoneNumber = newPhoneValue ?? values.phone;
    const regEx = new RegExp(/[\s()*]/g);
    myPhoneNumber = myPhoneNumber.replaceAll(regEx, "");

    if (
      myPhoneNumber.length > 0 &&
      myPhoneNumber.length < "+905321234567".length
    ) {
      setErrorMessages({
        ...errorMessages,
        ["phone"]: t("TELEFON_GECERSIZ"),
      });
    } else {
      let req = requests.getSimpleGetRequest(
        USER.findByPhone + "/" + myPhoneNumber
      );
      FaAxios(req)
        .then((response) => {
          setErrorMessages({
            ...errorMessages,
            ["phone"]: t("TELEFON_MEVCUT"),
          });
        })
        .catch((e) => {
          if (e.response) {
            if (e.response.status === 302) {
              setErrorMessages({
                ...errorMessages,
                ["phone"]: t("TELEFON_MEVCUT"),
              });
            } else {
              setErrorMessages({ ...errorMessages, ["phone"]: "" });
            }
          }
        });
    }
  };

  const handleChange = (prop) => (event) => {
    if (prop === "password") {
      setValues({
        ...values,
        [prop]: event.target.value,
        ["passwordConfirm"]: event.target.value,
      });
      setIsFieldsEmpty({
        ...isFieldsEmpty,
        [prop]: event.target.value ? false : true,
      });
    } else if (
      prop === "iletiAl" ||
      prop === "kvkk" ||
      prop === "kvkkOnay" ||
      prop === "kullaniciSozlesme" ||
      prop === "uyelikSozlesme"
    ) {
      setValues({
        ...values,
        [prop]: event.target.checked,
      });
      setIsFieldsEmpty({
        ...isFieldsEmpty,
        [prop]: event.target.checked ? false : true,
      });
    } else {
      setValues({ ...values, [prop]: event.target.value });
      setIsFieldsEmpty({
        ...isFieldsEmpty,
        [prop]: event.target.value ? false : true,
      });
    }

    if (prop === "email") {
      setIsFieldsValid({
        ...isFieldsValid,
        email: event.target.validity.valid,
      });
    }
  };

  const handleChangeV = (prop, value) => {
    setValues({ ...values, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: value ? false : true });

    if (prop === "phone" && value) {
      const regEx = new RegExp(/[\s()*]/g);
      let myPhoneNumber = value.replaceAll(regEx, "");
      if (
        myPhoneNumber.length > 0 &&
        myPhoneNumber.length < "+905321234567".length
      ) {
        setErrorMessages({
          ...errorMessages,
          ["phone"]: t("TELEFON_GECERSIZ"),
        });
      } else {
        setErrorMessages({ ...errorMessages, ["phone"]: "" });
      }
    }
  };

  const responseFacebookHandler = (thingsResponse, facebookResponse) => {
    if (thingsResponse) {
      if (thingsResponse.status === 404) {
        let reqEmail = requests.getSimpleGetRequest(
          USER.findByEmail + "/" + facebookResponse.email
        );
        FaAxios(reqEmail)
          .then((response) => {
            if (response.status === 302) {
              setErrorMessages({
                ...errorMessages,
                ["email"]: t("EPOSTA_MEVCUT"),
              });
              setShowError(true);
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              setValues({
                ...values,
                fullname: facebookResponse.name,
                email: facebookResponse.email,
                facebookId: facebookResponse.id,
                registerType: "FACEBOOK",
              });

              formSubmit();
            }
          });
      } else if (thingsResponse.status === 302) {
        setSocialMediaErrorMessage(t("FACEBOOK_KULLANICI_MEVCUT"));
      }
    }
  };

  const responseFacebook = (facebookResponse) => {
    console.log("Facebook response->", facebookResponse);

    let req = requests.getSimpleGetRequest(
      USER.findByFacebookId + "/" + facebookResponse.id
    );
    console.log("findByFacebookId req->", req);
    FaAxios(req)
      .then((thingsResponse) => {
        console.log("findByFacebookId res->", thingsResponse);
        responseFacebookHandler(thingsResponse, facebookResponse);
      })
      .catch((e) => {
        responseFacebookHandler(e.response, facebookResponse);
      });
  };

  const responseGoogleHandler = (thingsResponse, googleResponse) => {
    if (thingsResponse && googleResponse && googleResponse.profileObj) {
      if (thingsResponse.status === 404) {
        let reqEmail = requests.getSimpleGetRequest(
          USER.findByEmail + "/" + googleResponse.profileObj.email
        );
        console.log("req->", reqEmail);
        FaAxios(reqEmail)
          .then((response) => {
            if (response.status === 302) {
              setErrorMessages({
                ...errorMessages,
                ["email"]: t("EPOSTA_MEVCUT"),
              });
              setShowError(true);
            }
          })
          .catch((e) => {
            if (e.response && e.response.status === 404) {
              setValues({
                ...values,
                fullname: googleResponse.profileObj.name,
                email: googleResponse.profileObj.email,
                googleId: googleResponse.googleId,
                registerType: "GOOGLE",
              });

              formSubmit();

              // let urData = {
              //   fullname: googleResponse.profileObj.name,
              //   email: googleResponse.profileObj.email,
              //   googleId: googleResponse.googleId,
              //   country: values?.country,
              //   selectedProduct: values?.selectedProduct,
              // };
              // let req = requests.getSimplePostRequest(
              //   USER.googleVerify,
              //   urData
              // );
              // FaAxios(req)
              //   .then((resp) => {
              //     logIn(urData.email);
              //   })
              //   .catch((error) => {
              //     console.log("socialMediaRegister error->", error);
              //   });
            }
          });
      } else if (thingsResponse.status === 302) {
        setSocialMediaErrorMessage(t("GOOGLE_KULLANICI_MEVCUT"));
      }
    }
  };

  const appleResponseHandler = (thingsResponse, appleResponse) => {
    if (thingsResponse && appleResponse) {
      if (thingsResponse.status === 404) {
        setValues({
          ...values,
          fullname:
            appleResponse?.user?.name?.firstName +
            " " +
            appleResponse?.user?.name?.lastName,
          email: appleResponse?.user?.email,
          code: appleResponse?.authorization?.code,
          registerType: "APPLE",
          apple: {
            state: appleResponse?.authorization?.state,
            code: appleResponse?.authorization?.code,
            idToken: appleResponse?.authorization?.id_token,
            email: appleResponse?.user?.email,
            firstName: appleResponse?.user?.name?.firstName,
            lastName: appleResponse?.user?.name?.lastName,
          },
        });

        formSubmit();
      } else if (thingsResponse.status === 302) {
        setSocialMediaErrorMessage(t("errorMessages:USER_SOCIAL_MEDIA_EXISTS"));
      }
    }
  };

  const appleResponse = async (data) => {
    if (data?.authorization) {
      let req = requests.getSimpleGetRequest(
        USER.findByAppleCode + "/" + encodeURI(data?.authorization?.code)
      );
      console.log("findByAppleCode req->", req);
      FaAxios(req)
        .then((thingsResponse) => {
          console.log("findByAppleCode res->", thingsResponse);
          appleResponseHandler(thingsResponse, data);
        })
        .catch((e) => {
          appleResponseHandler(e.response, data);
        });
    }
  };

  const responseGoogle = (googleResponse) => {
    console.log("Google response->", googleResponse);

    let req = requests.getSimpleGetRequest(
      USER.findByGoogleId + "/" + googleResponse.googleId
    );
    console.log("findByGoogleId req->", req);
    FaAxios(req)
      .then((thingsResponse) => {
        console.log("findByGoogleId res->", thingsResponse);
        responseGoogleHandler(thingsResponse, googleResponse);
      })
      .catch((e) => {
        responseGoogleHandler(e.response, googleResponse);
      });
  };

  async function logIn(email) {
    try {
      unwrapResult(
        await dispatch(
          loginThunk(
            requests.getSimplePostRequest(AUT.login, {
              username: email,
              password: "gecicisifre", //FIXME
            })
          )
        )
      );
      dispatch(setUserInfo(JSON.parse(localStorage.userInfo)));
      dispatch(clearArr());
      history.push("/");
    } catch (err) {
      console.log("error", `Fetch failed: ${err.message}`);
      console.log(err);
      switch (err.response && err.response.status) {
        case 400:
          console.log(err.response);
          break;
        default:
          break;
      }
    }
  }

  const showLegalText = (type, legalTextIdProp) => {
    LegalTextService.getLegalTextByType(
      type,
      (data) => {
        showLegalTextOnSuccess(data, legalTextIdProp);
      },
      (error) => {
        setLegalText(null);
      }
    );
  };

  const showLegalTextOnSuccess = (data, legalTextIdProp) => {
    setLegalText(data);
    setOpenLegalText(true);
    setValues({ ...values, [legalTextIdProp]: data.id });
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  const renderSocialMediaErrorDialog = () => {
    return (
      <Dialog open={true} maxWidth="md">
        <DialogTitle>{t("SOCIAL_MEDIA_ERROR")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{socialMediaErrorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSocialMediaErrorMessage(null);
            }}
            color="primary"
          >
            {t("TAMAM")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const onFocusPhone = () => {
    if (!values.phone || values.phone.length === 0)
      handleChangeV("phone", "+90 (5");
  };

  const onBlurPhone = () => {
    if (values.phone === "+90 (5**) *** ** **") {
      handleChangeV("phone", "");
      CheckPhone("");
    } else CheckPhone();
  };

  const onChangeSalesPackage = (data) => {
    handleChangeV("selectedProduct", data);
  };

  const renderContracts = () => {
    return (
      <>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          {values.registerType === "APPLE" && (
            <Grid item lg={10} xs={12} style={{ paddingTop: 10 }}>
              <FaInput
                required
                label={t("FULL_NAME")}
                onChange={handleChange("fullname")}
                value={values.fullname || ""}
                inputProps={{
                  autoComplete: "new-fullname",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Grid>
          )}
          <Grid item lg={10} xs={12} style={{ paddingTop: 10 }}>
            <FaInput
              label={t("ACCOUNT_NAME")}
              onChange={handleChangeAccount("fullname")}
              value={values.newAccount.fullname}
            />
            <FormHelperText>{t("ACCOUNT_NAME_HELPER_TEXT")}</FormHelperText>
          </Grid>
          <Grid item lg={10} xs={12}>
            <CountryAutoComplete
              required
              value={values.newAccount.country}
              onChange={(data) => {
                handleChangeVAccount("country", data);
              }}
            />
            <FormHelperText>{t("COUNTRY_SELECT_HELPER_TEXT")}</FormHelperText>
          </Grid>
          <Grid item lg={10} xs={12} style={{ paddingTop: 10 }}>
            <DataCenterSelect
              required
              value={values.newAccount.dataCenter}
              onChange={(data) => {
                handleChangeVAccount("dataCenter", data);
              }}
            />
            <FormHelperText>{t("DATA_CENTER_HELPER_TEXT")}</FormHelperText>
          </Grid>
          <Grid item lg={10} xs={12}></Grid>
          <Grid item lg={10} xs={12}>
            <LanguageSelect
              required
              value={values.newAccount.language}
              onChange={(data) => {
                handleChangeVAccount("language", data);
              }}
            />
            <FormHelperText>{t("LANGUAGE_SELECT_HELPER_TEXT")}</FormHelperText>
          </Grid>
        </Grid>

        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={values.iletiAl}
              onChange={handleChange("iletiAl")}
              name="iletiAl"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText(
                    "TICARI_ELEKTRONIK_ILETI_BILGILENDIRME",
                    "iletiAlLegalTextId"
                  );
                }}
                className={labelClasses.SimpleLink}
                underline="hover"
              >
                {t("ELECTRONIC_COMMERCIAL_COMMUNICATION_INFO")}
              </Link>{" "}
              {t("ELECTRONIC_COMMUNICATION_ACCEPT")}
              <Link
                onClick={() => {
                  showLegalText(
                    "TICARI_ELEKTRONIK_ILETI_RIZA",
                    "iletiAlLegalTextId"
                  );
                }}
                className={labelClasses.SimpleLink}
                underline="hover"
              >
                {t("ELECTRONIC_COMMERCIAL_COMMUNICATION")}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}></Grid>

          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={values.kvkk}
              onChange={handleChange("kvkk")}
              name="kvkk"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText("KVKK", "kvkkLegalTextId");
                }}
                className={labelClasses.SimpleLink}
                underline="hover"
              >
                {t("KVKK_DISCLOSURE_TEXT")}
              </Link>{" "}
              {t("KVKK_READ")}
            </Typography>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={values.kvkkOnay}
              onChange={handleChange("kvkkOnay")}
              name="kvkkOnay"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText("KVKK", "kvkkOnayLegalTextId");
                }}
                className={labelClasses.SimpleLink}
                underline="hover"
              >
                {t("KVKK_DISCLOSURE_TEXT")}
              </Link>{" "}
              {t("KVKK_CONSENT")}
            </Typography>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={values.kullaniciSozlesme}
              onChange={handleChange("kullaniciSozlesme")}
              name="kullaniciSozlesme"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText(
                    "KULLANICI_SOZLESMESI",
                    "kullaniciSozlesmeLegalTextId"
                  );
                }}
                className={labelClasses.SimpleLink}
                underline="hover"
              >
                {t("USER_AGREEMENT")}
              </Link>{" "}
              {t("USER_AGREEMENT_ACCEPT")}
            </Typography>
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={values.uyelikSozlesme}
              onChange={handleChange("uyelikSozlesme")}
              name="uyelikSozlesme"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText(
                    "LISANS_SOZLESMESI",
                    "uyelikSozlesmeLegalTextId"
                  );
                }}
                className={labelClasses.SimpleLink}
                underline="hover"
              >
                {t("LICENSE_AGREEMENT")}
              </Link>{" "}
              {t("LICENSE_AGREEMENT_ACCEPT")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          className={classes.buttonContainer}
        >
          <Grid item lg={10} xs={12}>
            <FaButton
              async
              type="submit"
              disabled={
                isFieldsEmpty.kvkk ||
                isFieldsEmpty.kullaniciSozlesme ||
                isFieldsEmpty.uyelikSozlesme ||
                loading
              }
              label={t("SIGN_UP")}
              formRef={formRef}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faCheck} />}
              className={classes.submit}
              loading={loading}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const renderRegisterForm = () => {
    return (
      <>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item lg={10} xs={12}>
            <FaInput
              required
              label={t("FULL_NAME")}
              onChange={handleChange("fullname")}
              value={values.fullname || ""}
              inputProps={{
                autoComplete: "new-fullname",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item lg={10} xs={12}>
            <FaInput
              helperText={errorMessages.email}
              faError={errorMessages.email}
              required={isFieldsEmpty.phone}
              faRequired={isFieldsEmpty.phone}
              faType="email"
              disabled={!!userInvitation}
              label={t("USER_E_MAIL")}
              autoComplete="email"
              onChange={handleChange("email")}
              onBlur={CheckEmail}
              value={values.email || ""}
              inputProps={{
                autoComplete: "new-email",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item lg={10} xs={12}>
            <FaInput
              required={isFieldsEmpty.phone && isFieldsEmpty.email}
              faRequired={isFieldsEmpty.email}
              faError={errorMessages.phone}
              helperText={errorMessages.phone}
              label={t("PHONE_NUMBER")}
              onFocus={onFocusPhone}
              onBlur={onBlurPhone}
              onChange={(event) =>
                handleChangeV("phone", event.target.allValues.formattedValue)
              }
              faMask="+## (###) ### ## ##"
              faMaskChar="*"
              //helperText="Örnek : +999999999999"
              value={values.phone}
              inputProps={{
                autoComplete: "new-phone",
                form: {
                  autoComplete: "off",
                },
              }}
            />
          </Grid>
          <Grid item lg={10} xs={12}>
            <FaInput
              faType="password"
              passwordRepeat={values.passwordRepeat}
              required
              label={t("PASSWORD")}
              onChange={handleChange("password")}
              value={values.password || ""}
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
              }}
            />

            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital"]}
              minLength={5}
              value={values.password}
              onChange={(isValid) => {
                setIsPasswordValid(isValid);
              }}
              messages={{
                minLength: t("PASSWORD_MIN_LENGTH"),
                specialChar: t("PASSWORD_SPECIAL_CHAR"),
                number: t("PASSWORD_NUMBER"),
                capital: t("PASSWORD_CAPITAL"),
              }}
              iconSize={12}
              style={{
                fontSize: 14,
                paddingTop: 10,
                marginBottom: 20,
              }}
            />
          </Grid>
          {/* {renderCheckboxes()} */}
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          className={classes.buttonContainer}
        >
          <Grid item lg={10} xs={12}>
            <FaButton
              async
              type="submit"
              disabled={
                errorMessages.email ||
                errorMessages.phone ||
                (isFieldsEmpty.phone && isFieldsEmpty.email) ||
                isFieldsEmpty.fullname ||
                isFieldsEmpty.password ||
                !isPasswordValid ||
                !isFieldsValid.email
              }
              label={t("SIGN_UP")}
              formRef={formRef}
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faCheck} />}
              className={classes.submit}
              loading={loading}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const formSubmit = (event) => {
    if (event) event.preventDefault();
    if (scene === "REGISTER_FORM") {
      setValues((prev) => {
        return {
          ...prev,
          newAccount: { ...prev.newAccount, fullname: prev.fullname },
        };
      });

      showContracts(event);
    } else if (scene === "CONTRACTS") moveConfirmation(event);
  };

  const renderSocials = () => {
    return (
      <>
        {socialMediaErrorMessage && renderSocialMediaErrorDialog()}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          {(THINGS_ENVIRONMENT.showGoogleButton ||
            THINGS_ENVIRONMENT.showFacebookButton ||
            THINGS_ENVIRONMENT.showAppleButton ||
            THINGS_ENVIRONMENT.showBeneficiaryButton ||
            THINGS_ENVIRONMENT.showSignUpButton) && (
            <Grid item lg={10} xs={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "rgba(0, 0, 0, 0.54)",
                  }}
                />

                <div>
                  <Typography
                    style={{
                      color: "rgba(0, 0, 0, 0.84)",
                      width: "70px",
                      textAlign: "center",
                    }}
                  >
                    {t("ALTERNATIVE_LOGIN")}
                  </Typography>
                </div>

                <div
                  style={{
                    flex: 1,
                    height: "1px",
                    backgroundColor: "rgba(0, 0, 0, 0.54)",
                  }}
                />
              </div>
            </Grid>
          )}
        </Grid>

        <Grid
          item
          container
          justifyContent="center"
          spacing={1}
          className={classes.buttonContainer}
        >
          <Grid item>
            <GoogleLogin
              clientId="241190904512-ndpmk4v1fda8p4dhpfncpnq44mjkchjh.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.googleButton}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<FontAwesomeIcon icon={faGoogle} />}
                >
                  {t("GOOGLE")}
                </Button>
              )}
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </Grid>
          <Grid item>
            <FacebookLoginCustomCSS
              appId="933568497435478"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={renderProps.onClick}
                  className={classes.facebookButton}
                  startIcon={<FontAwesomeIcon icon={faFacebookF} />}
                >
                  {t("FACEBOOK")}
                </Button>
              )}
            ></FacebookLoginCustomCSS>
          </Grid>
          <Grid item>
            <AppleLogin
              clientId="app.thngs"
              redirectURI="https://thngs.app/sign-up"
              usePopup={true}
              callback={appleResponse}
              scope="email name"
              responseMode="query"
              render={(renderProps) => (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    backgroundColor: "#000000",
                    width: "100%",
                  }}
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<FontAwesomeIcon icon={faApple} />}
                >
                  {t("Apple ile üye ol")}
                </Button>
              )}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid item lg={1} md={12}></Grid>
        <Grid item lg={3} md={12}>
          <SignUpSalesPackages onChange={onChangeSalesPackage} />
        </Grid>
        <Grid item lg={6} md={12}>
          <FaUnAuthPages maxWidth="lg">
            {renderLegalTextDialog()}
            <LanguageSelectButton />
            <Grid
              item
              container
              spacing={3}
              justifyContent="center"
              style={{ marginBottom: 5 }}
            >
              <Grid
                item
                container
                spacing={2}
                lg={10}
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={appLogo}
                  height="78"
                  alt="logo"
                  className={classes.appLogo}
                  style={{ objectFit: "contain" }}
                />
                <Prompt
                  when={!!userInvitation}
                  message={t("LEAVE_PAGE_WARNNNG")}
                />
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  hl={lang}
                  theme={theme}
                  badge="bottomright"
                  sitekey={RECAPTCHA_CODE}
                />
                <form
                  ref={formRef}
                  className={classes.form}
                  validate="true"
                  onSubmit={formSubmit}
                  //onSubmit={moveConfirmation}
                >
                  {scene === "REGISTER_FORM" && renderRegisterForm()}
                  {scene === "CONTRACTS" && renderContracts()}
                  {scene === "REGISTER_FORM" && renderSocials()}

                  <Grid
                    item
                    container
                    spacing={1}
                    className={classes.buttonContainer}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body1" gutterBottom align="center">
                        {t("HESAP_VAR_MI")}{" "}
                        <Link
                          onClick={() => history.push(loginPath)}
                          variant="body2"
                          className={labelClasses.SimpleLink}
                          underline="hover"
                        >
                          {t("LOG_IN")}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </FaUnAuthPages>
        </Grid>
        <Grid item lg={1} md={12}></Grid>
      </Grid>
    </>
  );
}

SignUp.propTypes = {
  loginPath: PropTypes.string,
  backGroundImage: PropTypes.any,
  appLogo: PropTypes.any,
};
