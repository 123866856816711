import { Button, Grid } from "@mui/material/";
import MessageTemplateAutoComplete from "components/autocomplete/MessageTemplateAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CouponService } from "services/CouponService";
import i18n from "../../i18n";

const CouponEdit = forwardRef((props, ref) => {
  const { onSave, coupon, type } = props;
  const { t } = useTranslation("coupon", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newCoupon = () => {
    return {
      account: { id: accountId },
      name: null,
      description: null,
      startDate: null,
      endDate: null,
      status: "PASSIVE",
    };
  };
  const [couponDTO, setCouponDTO] = useState(newCoupon());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (coupon) {
      setCouponDTO(coupon == null ? newCoupon() : { ...coupon });
    }
  }, [coupon]);

  const saveCoupon = () => {
    if (couponDTO.startDate > couponDTO.endDate)
      showAlert("Başlangıç tarihi bitiş tarihinden sonra olamaz.", "error");
    else {
      setLoading(true);
      CouponService.save(couponDTO, saveOnSuccess, saveOnError);
    }
  };

  const saveOnSuccess = (response) => {
    showAlert(!couponDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    onSave(response);
  };

  const saveOnError = (error) => {
    showAlert("error", "error");
    setLoading(false);
    setIsDialogOpen(false);
  };

  const handleChangeV = (prop, val) => {
    setCouponDTO({ ...couponDTO, [prop]: val });
  };

  const deleteCoupon = () => {
    faDialog({
      description: t("Kuponu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CouponService.delete(
          couponDTO,
          (data) => {
            showAlert(t("Silindi"), "success");
            history.goBack();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <FaDialog
      title={t("EDIT_COUPON")}
      faOpen={isDialogOpen}
      faOnSubmit={saveCoupon}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setCouponDTO(coupon ? coupon : newCoupon());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        data-fa-section="COUPON_EDIT_DIALOG"
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) => handleChangeV("name", event.target.value)}
            value={couponDTO.name || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              handleChangeV("description", event.target.value)
            }
            value={couponDTO.description || ""}
          />
        </Grid>
        <Grid item xs={12}>
          <FaDatePicker
            label={t("START_DATE")}
            faType="dateTime"
            required
            value={couponDTO.startDate}
            faOnChange={(val) => {
              handleChangeV("startDate", val);
            }}
            data-fa-input="START_DATE"
          />
        </Grid>
        <Grid item xs={12}>
          <FaDatePicker
            label={t("END_DATE")}
            faType="dateTime"
            required
            name="endDate"
            value={couponDTO.endDate}
            faOnChange={(val) => {
              handleChangeV("endDate", val);
            }}
            data-fa-input="END_DATE"
          />
        </Grid>
        <Grid item xs={12}>
          <MessageTemplateAutoComplete
            value={couponDTO.messageTemplate}
            accountId={accountId}
            label={t("Katılım Geri Bildirim Mesajı")}
            actionType="COUPON"
            onChange={(data) => {
              handleChangeV("messageTemplate", data);
            }}
          />
        </Grid>
        {couponDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={deleteCoupon} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default CouponEdit;
