import { CardHeader } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ThingsEditIcon from "components/icons/ThingsEditIcon";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { ThingDynamicFormService } from "services/ThingDynamicFormService";
import DynamicFieldsDetail from "../../components/dynamicFieldsDetail";
import DynamicFieldsEdit from "../../components/dynamicFieldsEdit";
import i18n from "../../i18n";
import { DYNAMIC_FORM_DATA, THING_DYNAMIC_FORM } from "../../services/faApi";

export default function AdditionalFieldsCard(props) {
  const { className, classId, objectId, editMode, onChange, isEditable } =
    props;

  const accountId = localStorage.accountId;

  const { t } = useTranslation("thingDetail", { i18n });
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(!editMode ? false : true);
  const [dynamicValues, setDynamicValues] = useState([]);

  const newDynamicFormData = () => {
    return {
      name: className,
      objectId: objectId,
      detailData: [],
    };
  };

  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicFormData, setDynamicFormData] = useState(newDynamicFormData());

  useEffect(() => {
    if (classId && objectId) {
      setLoading(true);
      ThingDynamicFormService.findFormAndData(
        accountId,
        className,
        classId,
        objectId,
        (data) => {
          if (data) {
            setDynamicFormList(data.fieldList);
            setDynamicFormData(data.data ? data.data : newDynamicFormData());
          }
        }
      );

      setIsDialogOpen(!editMode ? false : true);
    }
  }, [classId, objectId]);

  const uploadFile = (data) => {
    let formData = new FormData();
    formData.append(
      "multipartFile",
      data.dataFile.file,
      data.dataFile.file.name
    );
    formData.append("name", className);
    formData.append("objectId", objectId);
    formData.append("dynamicFormId", data.dynamicFormId);

    let req = requests.getSimplePostRequest(
      THING_DYNAMIC_FORM.uploadDocument,
      formData
    );
    req["header"] = { "Content-Type": "multipart/form-data" };

    FaAxios(req)
      .then((response) => {
        let tempValues = [...dynamicValues];
        tempValues[data.index] = {
          ...tempValues[data.index],
          dataFile: response.data.dataFile,
          dataString: response.data.dataString,
        };

        setDynamicValues(tempValues);
        setDynamicFormData({ ...dynamicFormData, detailData: tempValues });
      })
      .catch((error) => {});
    return () => {
      //setLoading(false);
    };
  };

  const saveDynamicFormData = () => {
    let uploadFiles = [];
    dynamicValues.map((item, index) => {
      if (item.dataFile && item.dataFile.file != null) {
        uploadFiles.push({ ...item, index: index });
        item.dataFile = null;
      }
    });

    let formData = dynamicFormData;
    formData.detailData = dynamicValues;
    formData.name = className;
    formData.objectId = objectId;

    const req = requests.getSimplePostRequest(DYNAMIC_FORM_DATA.save, formData);
    FaAxios(req)
      .then((res) => {
        //setDynamicFormData(res.data); showAlert(t('FORM_SAVED'), "success");
        if (uploadFiles != null && uploadFiles.length > 0) {
          uploadFiles.map(uploadFile);

          setIsDialogOpen(false);
          if (onChange) onChange(dynamicValues);
        } else {
          setIsDialogOpen(false);
          if (onChange) onChange(dynamicValues);
        }
      })
      .catch((err) => {
        console.log(err); //showAlert(err.message, "error");
      });
  };

  const updateFail = () => {};

  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={t("Additional Info Update Title")}
        faOpen={isDialogOpen}
        faOnSubmit={saveDynamicFormData}
        showSaveButton
        faHandleClose={() => {
          setIsDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
        >
          <DynamicFieldsEdit
            selectedDetails={dynamicFormList}
            details={dynamicFormList}
            data={dynamicFormData.detailData}
            onValuesChange={onDynamicValuesChange}
            objectId={objectId}
          />
        </Grid>
      </FaDialog>
    );
  };

  return <>
    {renderDialog()}
    {!editMode && (
      <Card style={{ minHeight: 100 }} className="fai-additionalFields">
        <CardHeader
          action={
            <>
              {isEditable && (
                <IconButton
                  aria-label="settings"
                  onClick={() => {
                    setIsDialogOpen(true);
                  }}
                  size="large">
                  <ThingsEditIcon />
                </IconButton>
              )}
            </>
          }
          title={t("ADDITIONAL_FIELDS")}
          data-fa-section="ADDITIONAL_FIELDS"
        />
        <CardContent>
          <Grid item container spacing={1}>
            <DynamicFieldsDetail
              selectedDetails={dynamicFormList}
              details={dynamicFormList}
              data={dynamicFormData.detailData}
              objectId={objectId}
            />
          </Grid>
        </CardContent>
      </Card>
    )}
  </>;
}

AdditionalFieldsCard.propTypes = {
  className: PropTypes.string,
  classId: PropTypes.any,
  objectId: PropTypes.any,
  editMode: PropTypes.bool,
  onChange: PropTypes.func,
  isEditable: PropTypes.bool,
};
