import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import LoadingBar from "components/loadingBar";
import i18n from "i18n";
import PropTypes from "prop-types";
import { default as React, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { labelStyles } from "styles/ThingsLabelStyle";
import AnswerDistribution from "./AnswerDistribution";

export default function SurveyAnalysisGeneric(props) {
  const { question, questionAnalysis } = props;

  const labelClasses = labelStyles();
  const { t } = useTranslation("survey", { i18n });

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={
                      question.orderNo +
                      ". " +
                      (question?.multiLangName !== null
                        ? question?.multiLangName["tr"]
                        : "")
                    }
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <AnswerDistribution
                          questionAnalysis={questionAnalysis}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}

SurveyAnalysisGeneric.propTypes = {
  question: PropTypes.any,
  questionAnalysis: PropTypes.any,
};
