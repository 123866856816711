// Thingsin çalıştığı ortamı belirlemek için kullanılıyor.
// Yeni bir değişken eklenirse diğer bütün ortamlara eklenmesi gerekli.

import { APP } from "services/utils/Constants";

const DEFAULT_TF = {
  showGoogleButton: true, //Login sayfasında google gösterilecek mi?
  showFacebookButton: true, //Login sayfasında facebook gösterilecek mi?
  showAppleButton: false, //Login sayfasında apple gösterilecek mi? //FIXME
  showBeneficiaryButton: true, //Login sayfasında faydalanıcı girişi gösterilecek mi?
  showSignUpButton: true, //Login sayfasında kayıt ol gösterilecek mi?
  showForgotPasswordButton: true, //Login sayfasında şifremi unuttum gösterilecek mi?
  fingerprint: true, //fingerprint modülü aktif olacak mı?
  companyLogo: null, //Kurulum yapılan kurumun logosu
  loginPageHelp: false, //Login sayfasında yardım masası kılavuzu gösterilecek mi?
  appLogo: process.env.PUBLIC_URL + "/assets/things.png", //login sayfası logo
  appBarLogo: process.env.PUBLIC_URL + "/assets/things_menu.png", //menu logo
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/t2c-logo-white.png", //Things2Care logo
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/t2c-logo-dark.png", //Things2Care logo
  forceUserLocation: true, //Kullanıcı konumunun girişini zorla (Dahili, kat, oda)
  app: APP.TF, // ThingsAndFeedbacks mi Things2Care mi çalışacağı belirlenir
  urlT2C: "https://t2c.thngs.app/login",
  urlTF: "https://thngs.app/login",
  appSwitch: true, // login ekranında TF / T2C uygulama geçiş butonunu gösterir
};

const DEFAULT_T2C = {
  showGoogleButton: true, //Login sayfasında google gösterilecek mi?
  showFacebookButton: true, //Login sayfasında facebook gösterilecek mi?
  showAppleButton: false, //Login sayfasında apple gösterilecek mi? //FIXME
  showBeneficiaryButton: true, //Login sayfasında faydalanıcı girişi gösterilecek mi?
  showSignUpButton: true, //Login sayfasında kayıt ol gösterilecek mi?
  showForgotPasswordButton: true, //Login sayfasında şifremi unuttum gösterilecek mi?
  fingerprint: true, //fingerprint modülü aktif olacak mı?
  companyLogo: null, //Kurulum yapılan kurumun logosu
  loginPageHelp: false, //Login sayfasında yardım masası kılavuzu gösterilecek mi?
  appLogo: process.env.PUBLIC_URL + "/assets/t2c-logo-dark.png", //login sayfası logo
  appBarLogo: process.env.PUBLIC_URL + "/assets/things_menu.png", //menu logo
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/t2c-logo-white.png", //Things2Care logo
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/t2c-logo-dark.png", //Things2Care logo
  forceUserLocation: true, //Kullanıcı konumunun girişini zorla (Dahili, kat, oda)
  app: APP.T2C, // ThingsAndFeedbacks mi Things2Care mi çalışacağı belirlenir
  urlT2C: "https://t2c.thngs.app/login",
  urlTF: "https://thngs.app/login",
  appSwitch: true, // login ekranında TF / T2C uygulama geçiş butonunu gösterir
};

const DEFAULT_CNF = {
  showGoogleButton: true, //Login sayfasında google gösterilecek mi?
  showFacebookButton: true, //Login sayfasında facebook gösterilecek mi?
  showAppleButton: false, //Login sayfasında apple gösterilecek mi? //FIXME
  showBeneficiaryButton: true, //Login sayfasında faydalanıcı girişi gösterilecek mi?
  showSignUpButton: true, //Login sayfasında kayıt ol gösterilecek mi?
  showForgotPasswordButton: true, //Login sayfasında şifremi unuttum gösterilecek mi?
  fingerprint: true, //fingerprint modülü aktif olacak mı?
  companyLogo: null, //Kurulum yapılan kurumun logosu
  loginPageHelp: false, //Login sayfasında yardım masası kılavuzu gösterilecek mi?
  appLogo: process.env.PUBLIC_URL + "/assets/cnf-logo-dark.png", //login sayfası logo
  appBarLogo: process.env.PUBLIC_URL + "/assets/cnf-logo-white.png", //menu logo
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/cnf-logo-white.png", //Things2Care logo
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/cnf-logo-dark.png", //Things2Care logo
  forceUserLocation: true, //Kullanıcı konumunun girişini zorla (Dahili, kat, oda)
  app: APP.CNF, // ThingsAndFeedbacks mi Things2Care mi çalışacağı belirlenir
  urlT2C: "https://t2c.thngs.app/login",
  urlTF: "https://thngs.app/login",
  appSwitch: false, // login ekranında TF / T2C uygulama geçiş butonunu gösterir
};

const CEVRE_BAKANLIGI = {
  showGoogleButton: false,
  showFacebookButton: false,
  showAppleButton: false,
  showBeneficiaryButton: false,
  showSignUpButton: false,
  showForgotPasswordButton: false,
  fingerprint: false,
  companyLogo: process.env.PUBLIC_URL + "/assets/things_menu.png",
  loginPageHelp: true,
  appLogo: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  appBarLogo: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/cevreLogo.png",
  forceUserLocation: true,
  app: APP.T2C,
  urlT2C: null,
  urlTF: null,
  appSwitch: false,
};

const TANAP = {
  showGoogleButton: false,
  showFacebookButton: false,
  showAppleButton: false,
  showBeneficiaryButton: false,
  showSignUpButton: false,
  showForgotPasswordButton: false,
  fingerprint: false,
  companyLogo: process.env.PUBLIC_URL + "/assets/things_menu.png",
  loginPageHelp: false,
  appLogo: process.env.PUBLIC_URL + "/assets/tanap.svg",
  appBarLogo: process.env.PUBLIC_URL + "/assets/tanap.svg",
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/tanap.svg",
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/tanap.svg",
  forceUserLocation: false,
  app: APP.T2C,
  urlT2C: null,
  urlTF: null,
  appSwitch: false,
};

const MGM = {
  showGoogleButton: false,
  showFacebookButton: false,
  showAppleButton: false,
  showBeneficiaryButton: false,
  showSignUpButton: false,
  showForgotPasswordButton: false,
  fingerprint: false,
  companyLogo: null,
  loginPageHelp: false,
  appLogo: process.env.PUBLIC_URL + "/assets/things.png",
  appBarLogo: process.env.PUBLIC_URL + "/assets/things_menu.png",
  appLogoT2CWhite: process.env.PUBLIC_URL + "/assets/t2c-logo-white.png",
  appLogoT2CDark: process.env.PUBLIC_URL + "/assets/t2c-logo-dark.png",
  forceUserLocation: false,
  app: APP.T2C,
  urlT2C: null,
  urlTF: null,
  appSwitch: false,
};

export const THINGS_ENVIRONMENT = {
  showGoogleButton: DEFAULT_TF.showGoogleButton,
  showFacebookButton: DEFAULT_TF.showFacebookButton,
  showAppleButton: DEFAULT_TF.showAppleButton,
  showBeneficiaryButton: DEFAULT_TF.showBeneficiaryButton,
  showSignUpButton: DEFAULT_TF.showSignUpButton,
  showForgotPasswordButton: DEFAULT_TF.showForgotPasswordButton,
  fingerprint: DEFAULT_TF.fingerprint,
  companyLogo: DEFAULT_TF.companyLogo,
  loginPageHelp: DEFAULT_TF.loginPageHelp,
  appLogo: DEFAULT_TF.appLogo,
  appBarLogo: DEFAULT_TF.appBarLogo,
  appLogoT2CWhite: DEFAULT_TF.appLogoT2CWhite,
  appLogoT2CDark: DEFAULT_TF.appLogoT2CDark,
  forceUserLocation: DEFAULT_TF.forceUserLocation,
  app: DEFAULT_TF.app,
  urlT2C: DEFAULT_TF.urlT2C,
  urlTF: DEFAULT_TF.urlTF,
  appSwitch: DEFAULT_TF.appSwitch,
};
