import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThingDictionaryService } from "services/ThingDictionaryService";
import i18n from "../../i18n";

export default function IssueCloseTypeRadio(props) {
  const { value, accountId, onChange, required, label, ...other } = props;

  const { t } = useTranslation("components", { i18n });

  const [list, setList] = useState([]);

  useEffect(() => {
    if (!list || list.length === 0) populateList();
  }, []);

  const populateList = () => {
    ThingDictionaryService.findClassName(
      accountId,
      (data) => {
        setList(data.list);
      },
      (error) => {}
    );
  };

  return (
    <FormControl variant="standard" component="fieldset" {...other}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        row
        aria-label="position"
        value={value == null ? null : "" + value.id}
        label={label}
        required={required}
        onChange={(event) => {
          let item = list.find((x) => "" + x.id === event.target.value);
          onChange(item);
        }}
      >
        {list &&
          list?.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                value={"" + item.id}
                control={<Radio color="primary" />}
                label={item.className}
              />
            );
          })}
      </RadioGroup>
    </FormControl>
  );
}
