import AccountInteractionPage from "features/accountDetails/AccountInteractionPage";
import ThingDetail from "features/thingDetail";
import Things from "features/things";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function QuickStartHighPackageTutorial(props) {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  const thingDetailRef = useRef(null);

  let history = useHistory();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);
  const [thingId, setThingId] = useState(null);

  useEffect(() => {
    ThingService.getSystemThing(
      localStorage.accountId,
      (data) => {
        setThingId(data?.id);
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    if (thingId)
      setSteps([
        {
          element: "#menuThings",
          intro: (
            <TutorialElement icon="click">
              <b>{t("interactionPointsMenu")}</b> {t("clickMenu")}
            </TutorialElement>
          ),
        },
        {
          element: "#menuThingList",
          intro: (
            <TutorialElement icon="click">
              <b>{t("viewInteractionPoints")}</b> {t("clickListToView")}
            </TutorialElement>
          ),
          onBeforeChange: (index) => {
            var element = document.querySelector("#menuThings");
            if (element?.childElementCount > 1 == false)
              $("#menuThings")[0].firstElementChild.click();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="info">
              {t("interactionPointsDisplayed")}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "div.MuiTableContainer-root > table > tbody > tr",
          intro: (
            <TutorialElement icon="click">
              {t("interact_with_customers")} <b>{t("point")}</b>{" "}
              {t("clickPoint")}
            </TutorialElement>
          ),
          comp: "Things",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: null,
          intro: (
            <TutorialElement icon="info" text={t("interactionPointInfo")} />
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            console.log(thingId);
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-input='QR_MENU']",
          intro: <TutorialElement icon="info" text={t("downloadQRCode")} />,
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element:
            "#qr-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded",
          intro: (
            <TutorialElement icon="click">
              <div>
                <p>{t("clickQRCode")}</p>
                <p>
                  <b>{t("downloadQR")}</b> {t("orText")}{" "}
                  <b>{t("printQRLabel")}</b>
                  {t("clickMenu")}
                </p>
              </div>
            </TutorialElement>
          ),
          comp: "ThingDetail",
          highlightClass: classes.highlightClassCustom,
          onBeforeChange: (index) => {
            thingDetailRef.current.openPhotoMenu();
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement icon="click">
              <div>
                <p>{t("placeQRCode")}</p>
              </div>
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          intro: (
            <TutorialElement header="TIP" icon="click">
              <div style={{ textAlign: "center" }}>
                <p>
                  {t("multipleQRPlacement")}
                  <b>{t("sameQRCode")}</b>
                  {t("placeQRCodeMultiple")}
                  <br />
                  {t("orText")}
                  <br />
                  {t("uniqueQRPlacement")}
                  <b>{t("collectFeedback")}</b>
                  <b>{t("placeDifferentQRCode")}</b>
                  {t("qrCodePlacementInstructions")}
                </p>
              </div>
            </TutorialElement>
          ),
          comp: "ThingDetail",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
        {
          element: "[data-fa-section='THING_INTERACTION_PAGE_OPTIONS']",
          intro: (
            <TutorialElement
              icon="done"
              text={t("readyToInteract") + " " + t("interactionOptions")}
            />
          ),
          comp: "AccountInteraction",
          onBeforeChange: (index) => {
            stepsRef.current.updateStepElement(index);
          },
        },
      ]);
  }, [thingId]);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Things" && <Things />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "ThingDetail" &&
        thingId && <ThingDetail ref={thingDetailRef} thingId={thingId} />}
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "AccountInteraction" && (
          <AccountInteractionPage />
        )}
      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuThings")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
