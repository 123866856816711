import { Card, CardHeader } from "@mui/material";
import { default as React, Suspense, useEffect, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import ReactFlow, {
  Background,
  MarkerType,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { QueueService } from "services/QueueService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function QueueState(props) {
  const { queue } = props;
  const { t } = useTranslation("queue", { i18n });
  const accountId = localStorage.accountId;
  const faDialog = useDialog();
  const labelClasses = labelStyles();

  const [userList, setUserList] = useState([]);
  const [nextUser, setNextUser] = useState(null);
  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  const colors = [
    "#94ab90",
    "#d3bea0",
    "#f8f4ef",
    "#f7d671",
    "#c5b8d9",
    "#00A6FB",
    "#0582CA",
    "#006494",
    "#003554",
    "#051923",
  ];

  useEffect(() => {
    refresh();
  }, [queue]);

  const refresh = () => {
    if (queue && queue.id > 0) {
      QueueService.findUsers(queue.id, findUsersOnSuccess, findUsersOnError);
      QueueService.findNextUser(
        queue.id,
        (data) => {
          setNextUser(data);
        },
        (error) => {}
      );
    }
  };

  const findUsersOnSuccess = (data) => {
    setUserList(data);
  };

  const findUsersOnError = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (nextUser && userList) {
      let initialNodes = [];
      let initialPositions = { x: 10, y: 150 };
      let queueNode = {
        id: "queue",
        type: "input",
        data: { label: queue?.name },
        position: { x: initialPositions.x + 26, y: initialPositions.y },
        style: {
          borderRadius: "50%",
        },
      };
      initialNodes.push(queueNode);

      let positions = {
        x: 250,
        y: 20,
      };

      console.log("initialNodes", initialNodes);

      userList.map((user, index) => {
        positions.y = positions.y + 90;
        console.log("color:" + colors[index % colors.length]);
        let node = {
          id: "u_" + user.user.id,
          type: "default",
          data: { label: user.user.fullname },
          position: { x: positions.x, y: positions.y },
          style: { backgroundColor: colors[index % colors.length] },
        };
        initialNodes.push(node);
      });

      console.log("initialNodes", initialNodes);

      setNodes(initialNodes);

      let tempInitialEdges = [
        {
          id: "edge1",
          source: "queue",
          target: "u_" + nextUser.id,
          label: t("NEXT_USER"),
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        },
      ];

      setEdges(tempInitialEdges);
    }
  }, [nextUser, userList]);

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Card style={{ minHeight: 100 }}>
          <CardHeader title={t("QUEUE_STATE")} data-fa-section="QUEUE_STATE" />
          <div style={{ width: "100%", height: 400 }}>
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
            >
              <Background color="#aaa" gap={16} />
            </ReactFlow>
          </div>
        </Card>
      </Suspense>
    </>
  );
}
