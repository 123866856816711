import { Grid } from "@mui/material";
import { default as React, forwardRef, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { CampaignService } from "services/CampaignService";
import i18n from "../../i18n";
import { useStyles } from "./style";

const CampaignAwardEdit = forwardRef((props, ref) => {
  const { campaign, onSave, selectedAward, onClose, open } = props;
  const { t } = useTranslation("campaign", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const newCampaignAward = () => {
    return {
      campaign: campaign,
      name: null,
      amount: null,
    };
  };

  const [campaignAward, setCampaignAward] = useState(newCampaignAward());

  useEffect(() => {
    if (selectedAward?.id) {
      setCampaignAward({ ...selectedAward });
    } else {
      setCampaignAward(newCampaignAward());
    }
  }, [selectedAward, open]);

  const saveAward = () => {
    setLoading(true);
    CampaignService.saveAward(
      { ...campaignAward },
      (data) => {
        onSave();
        // setCampaignAward(newCampaignAward());
        setLoading(false);
        onClose();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };
  const handleChangeV = (prop, val) => {
    setCampaignAward({ ...campaignAward, [prop]: val });
  };
  const handleChange = (prop) => (event) => {
    setCampaignAward({ ...campaignAward, [prop]: event.target.value });
  };
  const renderEditForm = () => {
    return (
      <>
        <FaDialog
          title={t("CAMPAIGN_AWARD")}
          faOpen={open}
          faOnSubmit={saveAward}
          loading={loading}
          showSaveButton
          faHandleClose={onClose}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={campaignAward?.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                required
                label={t("AMOUNT")}
                onChange={handleChange("amount")}
                value={campaignAward?.amount || ""}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return renderEditForm();
});

export default CampaignAwardEdit;
