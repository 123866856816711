import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ThingDetail from "components/detail/ThingDetail";
import MiniMapButton from "components/map/MiniMapButton";
import { default as React, Suspense, useEffect, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AccessLogService } from "services/AccessLogService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import AccessLogCustomers from "./AccessLogCustomers";
import AccessLogInteractions from "./AccessLogInteractions";

export default function AccessLogDetail() {
  const labelClasses = labelStyles();
  const { t } = useTranslation("customer", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  let history = useHistory();

  const [accessLog, setAccessLog] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    AccessLogService.findById(id, setAccessLog, findByIdOnError);
  };

  const findByIdOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item lg={12} xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("ACCESS_LOG_DETAIL")}
                    data-fa-section="ACCESS_LOG_DETAIL"
                  />
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Grid item container spacing={1}>
                          <Grid item xs={12} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {isTNF(t("THING"), t("THING_T2C"))}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              <ThingDetail value={accessLog.thing} />
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("ACCESS_DATE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {getDateTimeWFormat(accessLog.accessDate)}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <div style={{ display: "flex" }}>
                              <div>
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("OPERATION")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {t(
                                    "INTERACTION_ACCESS_LOG_" +
                                      accessLog.logType
                                  )}
                                </Typography>
                              </div>
                              <div>
                                {/* <FaButton
                                  style={{
                                    marginLeft: 10,
                                    marginTop: 2,
                                  }}
                                  size="small"
                                  color="primary"
                                  faClick={() => {
                                    onDoubleClick();
                                  }}
                                  data-fa-button="OPERATION_DETAIL"
                                >
                                  {t("Detay")}
                                </FaButton> */}
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("VISITOR_ID")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.visitorId}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("IP_ADDRESS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.ipAddress}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("BROWSER")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.browser}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("BROWSER_TYPE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.browserType}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("BROWSER_MAJOR_VERSION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.browserMajorVersion}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DEVICE_TYPE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.deviceType}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PLATFORM")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.platform}
                            </Typography>
                          </Grid>

                          <Grid item xs={6} className={labelClasses.LabelRow}>
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PLATFORM_VERSION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {accessLog.clientInfo?.platformVersion}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("LOCATION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              <MiniMapButton
                                value={accessLog?.gpsLocation}
                                value2={accessLog?.ipGpsLocation}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={6}>
                {accessLog.clientInfo?.visitorId && (
                  <AccessLogCustomers
                    visitorId={accessLog.clientInfo?.visitorId}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {accessLog.clientInfo?.visitorId && (
                  <AccessLogInteractions
                    visitorId={accessLog.clientInfo?.visitorId}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
