import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import ThingHashtag from "components/hashtag/ThingHashtag";
import ThingProductTypeSelect from "components/select/ThingProductTypeSelect";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { getUserInfo } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { ThingService } from "services/thing/thingService";
import { ThingDesc, isTNF } from "services/utils";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";

const ThingCreate = forwardRef((props, ref) => {
  const { product, equipment, onCreate } = props;
  const { t } = useTranslation(["things", "errorMessages"], { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsThingRegisterFormOpen(true);
      setThingDTO(createThing());
    },
  }));

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const accountId = localStorage.accountId;
  const userInfo = useSelector(getUserInfo);
  const [isThingRegisterFormOpen, setIsThingRegisterFormOpen] = useState(false);
  const [location, setLocation] = useState({
    address: "",
    geoCode: { lat: "", lng: "" },
  });
  const [files, setFiles] = useState([]);
  const [isGenerateThingDesc, setIsGenerateThingDesc] = useState(true);
  const [loading, setLoading] = useState(false);
  const [advanceMode, setAdvanceMode] = useState(false);
  const [defaultThingClass, setDefaultThingClass] = useState(null);
  const [uniqueThingIdentifier, setUniqueThingIdentifier] = useState(null);

  const createThing = () => {
    return {
      description: "",
      status: "ACTIVE",
      serviceStatus: "IN_SERVICE",
      thingcode: "",
      account: "",
      thid: "",
      thingClass: defaultThingClass,
      hashtag: "",
      vendor: null,
      serviceStartDate: null,
      publicIdentifier: "",
      location: "",
      interactive: true,
      productType: product ? "PRODUCT" : equipment ? "EQUIPMENT" : null,
    };
  };

  const [thingDTO, setThingDTO] = useState(createThing());

  useEffect(() => {
    AccPreferencesService.findByAccount(accountId, (data) => {
      setDefaultThingClass(data?.defaultThingClass);
      setUniqueThingIdentifier(data?.uniqueThingIdentifier);
    });
  }, []);

  useEffect(() => {}, []);

  const handleChangeV = (prop, val) => {
    setThingDTO({ ...thingDTO, [prop]: val });
    if (prop === "description") setIsGenerateThingDesc(false);
  };

  const saveFiles = (tid) => {
    files.forEach((fileObj) => {
      let fd = new FormData();
      fd.append("multipartFile", fileObj.file, fileObj.file.name);
      let req = requests.getSimplePostRequest(THING.uploadFile + "/" + tid, fd);
      req["header"] = { "Content-Type": "multipart/form-data" };
      FaAxios(req)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          showAlert(error.message, "error");
          setLoading(false);
        });
    });
  };

  const saveThing = () => {
    if (uniqueThingIdentifier === "FORCE" || uniqueThingIdentifier === "WARN") {
      ThingService.checkThingDesc(
        accountId,
        thingDTO.id ?? -1,
        ThingDesc(thingDTO),
        checkThingDescSuccess,
        (error) => {
          showAlert(error, "error");
        }
      );
    } else saveForm();
  };

  const checkThingDescSuccess = (data) => {
    if (data) saveForm();
    else if (uniqueThingIdentifier === "WARN") {
      faDialog({
        description: t(
          "Bu varlığın ayırdedici bir tanımlayıcısı görünmüyor. Bu durum bir karışıklığa yol açabilir. Yine de bu şekilde kaydetmek ister misiniz"
        ),
        type: "confirm",
      })
        .then((confirmation) => {
          saveForm();
        })
        .catch((err) => console.log("error:", err));
    } else if (uniqueThingIdentifier === "FORCE")
      showAlert(
        "Bu varlığın ayırdedici bir tanımlayıcısı görünmüyor. Bu durum bir karışıklığa yol açabilir. İşlem gerçekleştirilmedi.",
        "error"
      );
  };

  const saveForm = () => {
    setLoading(true);
    let req = requests.getSimpleGetRequest(THING.getTids, {
      accountId: accountId,
      count: 1,
    });
    FaAxios(req)
      .then((response) => {
        let reqBody = {
          ...thingDTO,
          thid: response.data[0],
          account: { id: accountId },
          ownerUser: { ...userInfo },
        };
        let req = requests.getSimplePostRequest(THING.checkAndSave, reqBody);
        FaAxios(req)
          .then((res) => {
            // Thing kaydedildikten sonra kaydedilen Thing objesi alınır
            let tid = res.data.id;
            if (files && tid) {
              // Eklenti olarak dosya varsa dosyalar ayrı istek ile kaydedilir
              saveFiles(tid);
            }
            showAlert(
              isTNF("Etkileşim noktası kayıt edildi", "Varlık kayıt edildi"),
              "success"
            );
            props.onCreate(res.data);
            /*setTimeout(() => {
              setIsThingRegisterFormOpen(!isThingRegisterFormOpen);
            }, 1000);
            setTimeout(() => {
              setThingDTO({
                description: "",
                status: "ACTIVE",
                serviceStatus: "IN_SERVICE",
                thingcode: "",
                account: "",
                thid: "",
                thingClass: "",
                hashtag: "",
              });
              setFiles([]);
              setLocation({ address: "", geoCode: { lat: "", lng: "" } });
              setHashtags([]);
              refresh();
              setLoading(false);
            }, 1200);*/
          })
          .catch((e) => {
            switch (e && e.response && e.response.status) {
              case 406:
                showAlert(
                  t("errorMessages:THING_SAVE_NOT_ACCEPTABLE"),
                  "error"
                );
                break;
              case 402:
                console.log({ e });
                showAlert(
                  t(
                    "errorMessages:" + e.response.data?.message ??
                      "UNEXPECTED_ERROR"
                  ),
                  "error"
                );
                break;
              case 409:
                showAlert(t("errorMessages:THING_SAVE_CONFLICT"), "error");
                break;
              case 423:
                showAlert(t("errorMessages:THING_SAVE_LOCKED"), "error");
                break;
              default:
                showAlert(t("errorMessages:UNEXPECTED_ERROR"), "error");
                break;
            }
            setLoading(false);
          });
      })
      .catch((error) => {
        showAlert(error.message, "error");
      });
  };

  const onFocusDesc = (event) => {
    if (isGenerateThingDesc) event.target.select();
  };

  const generateThingDesc = (thingClassId) => {
    setLoading(false);
    var req = requests.getSimpleGetRequest(THING.generateThingDesc, {
      accountId: accountId,
      thingClassId: thingClassId,
    });
    FaAxios(req)
      .then((response) => {
        if (!loading) {
          setThingDTO((prev) => {
            return { ...prev, ["description"]: response.data[0] };
          });
          //  setThingDTO({
          //    ...thingDTO,
          //    ["description"]: response.data[0],
          //  });
        }
      })
      .catch((error) => {
        showAlert("error", error);
        setLoading(false);
      });
    return () => {
      setLoading(false);
    };
  };

  return (
    <FaDialog
      title={
        product
          ? t("THING_CREATE")
          : equipment
          ? t("EQUIPMENT_CREATE")
          : isTNF(
              t("INTERACTION_POINT_CREATE"),
              t("INTERACTION_POINT_CREATE_T2C")
            )
      }
      isLocationOn
      faLocation={location}
      faSetLocation={setLocation}
      isDocumentUploadOn
      faFiles={files}
      faSetFiles={setFiles}
      faOpen={isThingRegisterFormOpen}
      faOnSubmit={saveThing}
      loading={loading}
      showSaveButton
      faHandleClose={(data) => {
        setIsThingRegisterFormOpen(data);
        setFiles([]);
        setLocation({
          address: "",
          geoCode: { lat: "", lng: "" },
        });
        setThingDTO(createThing());
      }}
      documentUploadText={t("GORSEL_YUKLE")}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <FormControlLabel
            control={
              <Switch
                checked={advanceMode}
                onChange={() => {
                  setAdvanceMode(!advanceMode);
                }}
                color="primary"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={t("Gelişmiş")}
          />
        </Grid>
        {product && (
          <Grid item xs={12}>
            <ThingProductTypeSelect
              required={true}
              value={thingDTO.productType}
              product={true}
              service={true}
              onChange={(data) => {
                setThingDTO({
                  ...thingDTO,
                  ["productType"]: data,
                });
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <ThingClassAutoComplete
            required={true}
            value={thingDTO.thingClass}
            accountId={accountId}
            onChange={(data) => {
              setThingDTO({
                ...thingDTO,
                ["thingClass"]: data,
              });
              if (isGenerateThingDesc && data) generateThingDesc(data.id);
            }}
            data-fa-input="THING_CLASS"
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            onChange={(event) =>
              handleChangeV("description", event.target.value)
            }
            onFocus={onFocusDesc}
            value={thingDTO.description || ""}
            data-fa-input="DESCRIPTION"
          />
        </Grid>
        <Grid item xs={12}>
          <FaInput
            label={t("THING_CODE")}
            onChange={(event) => handleChangeV("thingCode", event.target.value)}
            value={thingDTO.thingCode || ""}
            data-fa-input="THING_CODE"
          />
        </Grid>

        {advanceMode && (
          <Grid item xs={12}>
            <FaInput
              label={t("PUBLIC_IDENTIFIER")}
              onChange={(event) =>
                handleChangeV("publicIdentifier", event.target.value)
              }
              value={thingDTO.publicIdentifier || ""}
              data-fa-input="PUBLIC_IDENTIFIER"
            />
          </Grid>
        )}
        {advanceMode && (
          <Grid item xs={12}>
            <ThingHashtag
              label={t("HASHTAG")}
              accountId={accountId}
              value={thingDTO.hashtag}
              onChange={(data) => {
                setThingDTO({
                  ...thingDTO,
                  hashtag: data,
                });
              }}
              data-fa-input="HASHTAG"
            />
          </Grid>
        )}

        {advanceMode && !!!product && !!!equipment && (
          <Grid item xs={12}>
            <VendorAutoComplete
              value={thingDTO.vendor}
              accountId={accountId}
              onChange={(data) => {
                handleChangeV("vendor", data);
              }}
              data-fa-input="VENDOR"
            />
          </Grid>
        )}
        {advanceMode && (
          <Grid item xs={12}>
            <FaDatePicker
              label={t("SERVICE_START_DATE")}
              faType="date"
              name="serviceStartDate"
              value={thingDTO.serviceStartDate}
              faOnChange={(val) => {
                handleChangeV("serviceStartDate", val);
              }}
              data-fa-input="SERVICE_START_DATE"
            />
          </Grid>
        )}

        {location.address ? (
          <>
            <Grid item xs={12}>
              <FaInput
                label={"location"}
                disabled
                value={location.address || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={"locationGeocode"}
                disabled
                value={
                  location.geoCode
                    ? location.geoCode.lat + " " + location.geoCode.lng
                    : ""
                }
              />
            </Grid>
          </>
        ) : null}

        {
          <Grid item xs={12} style={{ height: 14 }}>
            {" "}
          </Grid>
        }
      </Grid>
    </FaDialog>
  );
});

export default ThingCreate;
