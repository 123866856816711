import Grid from "@mui/material/Grid";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomerMessageChannelSelect from "../../components/select/CustomerMessageChannelSelect";
import i18n from "../../i18n";
import { CUSTOMER_MESSAGE } from "../../services/faApi";

const CustomerMessageEdit = forwardRef((props, ref) => {
  const { onSave, message, type, survey } = props;
  const { t } = useTranslation("customerMessage", { i18n });
  const accountId = localStorage.accountId;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newMessage = () => {
    return {
      account: { id: accountId },
      name: null,
      description: null,
      plannedSendDate: null,
      messageTitle: null,
      messageTemplate: null,
      channel: null,
      status: "DRAFT",
      messageType: type ? type : null,
      survey: survey,
    };
  };
  const [messageDTO, setMessageDTO] = useState(newMessage());

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (message) {
      setMessageDTO(message == null ? newMessage() : { ...message });
    }
  }, [message]);

  const saveMessage = () => {
    setLoading(true);

    let req = requests.getSimplePostRequest(CUSTOMER_MESSAGE.save, {
      ...messageDTO,
    });

    FaAxios(req)
      .then((response) => {
        showAlert(!messageDTO.id ? t("SAVED") : t("EDITED"), "success");
        setIsDialogOpen(false);
        setLoading(false);
        setMessageDTO(message ? message : newMessage());
        onSave(response.data);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const handleMessageChange = (prop, val) => {
    setMessageDTO({ ...messageDTO, [prop]: val });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const handleChangeV = (prop, val) => {
    setMessageDTO({ ...messageDTO, [prop]: val });
  };

  return (
    <FaDialog
      title={t("NEW_MESSAGE")}
      faOpen={isDialogOpen}
      faOnSubmit={saveMessage}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setMessageDTO(message ? message : newMessage());
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
        data-fa-section="CUSTOMER_MESSAGE_DIALOG"
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) =>
              handleMessageChange("name", event.target.value)
            }
            value={messageDTO.name || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={(event) =>
              handleMessageChange("description", event.target.value)
            }
            value={messageDTO.description || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomerMessageChannelSelect
            value={messageDTO.channel}
            onChange={(data) => {
              setMessageDTO({
                ...messageDTO,
                channel: data,
              });
            }}
          />
        </Grid>
        {messageDTO.channel === "EMAIL" && (
          <Grid item xs={12}>
            <FaInput
              label={t("MESSAGE_TITLE")}
              required
              inputProps={{ maxLength: 255 }}
              onChange={(event) =>
                handleMessageChange("messageTitle", event.target.value)
              }
              value={messageDTO.messageTitle || ""}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FaInput
            label={t("MESSAGE_TEMPLATE")}
            required
            faType="description"
            inputProps={{
              maxLength: messageDTO.channel === "SMS" ? 160 : null,
            }}
            onChange={(event) =>
              handleMessageChange("messageTemplate", event.target.value)
            }
            value={messageDTO.messageTemplate || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaDatePicker
            label={t("PLANNED_SEND_DATE")}
            faType="dateTime"
            name="plannedSendDate"
            value={messageDTO.plannedSendDate}
            faOnChange={(val) => {
              handleChangeV("plannedSendDate", val);
            }}
            data-fa-input="PLANNED_SEND_DATE"
            minDate={new Date()}
          />
        </Grid>
      </Grid>
    </FaDialog>
  );
});

export default CustomerMessageEdit;
