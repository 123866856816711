import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function LikeDetailTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialQuickStart", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        //0
        intro: (
          <TutorialElement icon="tutorial">
            <div>
              <p>
                Müşterilerinizin bildirdiği <b>Beğenilere</b> ait detay
                bilgilerin gösterildiği sayfadır.
              </p>
              <p>
                Bu sayfada <b>Beğeni Bilgileri</b>’ni ve <b>Ek Alanları</b>{" "}
                izleyebilirsiniz.
              </p>
            </div>
          </TutorialElement>
        ),
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"like_detail"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
