import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Info } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import PatrolDefTypeSelect from "components/select/PatrolDefTypeSelect";
import { default as React, Suspense, useEffect, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import FaButton from "react-base-fa/dist/fa/faButton";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PatrolDefService } from "services/PatrolDefService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import PatrolDefControlItems from "./PatrolDefControlItems";
import PatrolDefRecurrence from "./PatrolDefRecurrence";
import PatrolDefThings from "./PatrolDefThings";
import PatrolDefUsers from "./PatrolDefUsers";
import { useStyles } from "./style";

export default function PatrolDefDetail() {
  const classes = useStyles();
  const labelClasses = labelStyles();
  const { t } = useTranslation("patrolDef", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId;
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patrolDef, setPatrolDef] = useState({});
  const [updateVal, setUpdateVal] = useState({});
  const [counts, setCounts] = useState({});

  useEffect(() => {
    refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    PatrolDefService.findById(id, findByIdOnSuccess, findByIdOnError);
    PatrolDefService.getCounts(
      id,
      (data) => {
        setCounts(data);
      },
      (error) => {}
    );
  };

  const findByIdOnSuccess = (data) => {
    setPatrolDef({
      ...data,
    });
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const editButtonClicked = () => {
    setUpdateVal({ ...patrolDef });
    setIsModalOpen(true);
  };

  const savePatrolDef = () => {
    setLoading(true);
    PatrolDefService.save(
      {
        ...updateVal,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const saveSuccess = (data) => {
    showAlert(!patrolDef.id ? t("SAVED") : t("EDITED"), "success");
    setIsModalOpen(false);
    setPatrolDef({
      ...data,
    });
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const passivation = () => {
    faDialog({
      description: t("Pasifleştirmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        setLoading(true);
        PatrolDefService.passivation(
          patrolDef.id,
          passivationSuccess,
          passivationError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const passivationSuccess = (data) => {
    showAlert(t("PASSIVATED"), "success");
    setPatrolDef({
      ...data,
    });
    setLoading(false);
  };

  const passivationError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const activation = () => {
    PatrolDefService.findNextDate(patrolDef.id, findNextDateSuccess, (data) => {
      showAlert(data.message, "error");
      setLoading(false);
    });
  };

  const findNextDateSuccess = (data) => {
    let startNow = false;
    faDialog({
      title: t("Aktifleştirmek istediğinize emin misiniz?"),
      content: (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => {
                  startNow = event.target.checked;
                }}
                color="primary"
              />
            }
            label={
              "İlk denetim işlemi " +
              getDateTimeWFormat(data) +
              " tarihinde başlayacaktır. Eğer ilk denetim işlemini hemen başlatmak isterseniz buraya tıklayınız."
            }
          />
        </div>
      ),
      type: "confirm",
    })
      .then((confirmation) => {
        setLoading(true);
        PatrolDefService.activation(
          patrolDef.id,
          startNow,
          activationSuccess,
          activationError
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const activationSuccess = (data) => {
    showAlert(t("ACTIVATED"), "success");
    setPatrolDef({
      ...data,
    });
    setLoading(false);
  };

  const activationError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setUpdateVal({ ...updateVal, [prop]: event.target.value });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onChange = () => {
    PatrolDefService.getCounts(
      id,
      (data) => {
        setCounts(data);
      },
      (error) => {}
    );
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Denetim Bilgileri Güncelle"}
        faOpen={isModalOpen}
        faOnSubmit={savePatrolDef}
        showSaveButton
        faHandleClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <FaInput
              required
              maxLength={100}
              label={t("NAME")}
              onChange={handleChange("name")}
              value={updateVal.name || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              maxLength={100}
              label={t("DESCRIPTION")}
              onChange={handleChange("description")}
              value={updateVal.description || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              label={t("TOTAL_DURATION_MIN")}
              onChange={handleChange("totalDurationMin")}
              value={updateVal.totalDurationMin || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              label={t("CLOSE_DURATION_MIN")}
              onChange={handleChange("closeDurationMin")}
              value={updateVal.closeDurationMin || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <PatrolDefTypeSelect
              required
              value={updateVal.type}
              onChange={(data) => {
                handleChangeGeneric(setUpdateVal, updateVal, "type", data);
              }}
            />
          </Grid>
        </Grid>
      </FaDialog>
    );
  };

  const getTooltipMessage = () => {
    let message = "";
    if (counts.thingCount === 0 && counts.userCount === 0)
      message =
        "Denetim istasyonları ve kullanıcılarının tanımlanması gereklidir";
    else if (counts.thingCount === 0)
      message = "Denetim istasyonlarının tanımlanması gereklidir";
    else if (counts.userCount === 0)
      message = "Denetim kullanıcılarının tanımlanması gereklidir";

    console.log("message", message);
    return message;
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {renderDialog()}

      <Grid container spacing={1}>
        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item lg={12}>
              <Card>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="settings"
                      onClick={editButtonClicked}
                      disabled={patrolDef.status === "ACTIVE"}
                      size="large">
                      <EditIcon />
                    </IconButton>
                  }
                  title={t("PATROL_INFO")}
                  data-fa-section="PATROL_INFO"
                />
                <CardContent>
                  <Grid item container spacing={0}>
                    <Grid item xs={12}>
                      <Grid
                        item
                        container
                        spacing={1}
                        style={{ marginLeft: 10, marginRight: 10 }}
                      >
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("NAME")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {patrolDef.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("DESCRIPTION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {patrolDef.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("TOTAL_DURATION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {patrolDef.totalDurationMin}{" "}
                            {patrolDef.totalDurationMin && t("MINUTES")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("CLOSE_DURATION")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {patrolDef.closeDurationMin}{" "}
                            {patrolDef.closeDurationMin && t("MINUTES")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("NEXT_DATE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {getDateTimeWFormat(patrolDef.nextDate)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={labelClasses.LabelRow}>
                          <Typography className={labelClasses.LabelHeader}>
                            {t("TYPE")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t("" + patrolDef.type)}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={labelClasses.LabelLastRow}
                        >
                          <Typography className={labelClasses.LabelHeader}>
                            {t("STATUS")}
                          </Typography>
                          <Typography className={labelClasses.LabelValue}>
                            {t("" + patrolDef.status)}
                          </Typography>
                        </Grid>

                        <Grid item xs={4}>
                          {patrolDef.status === "PASSIVE" && (
                            <>
                              <FaButton
                                style={{ width: "40%" }}
                                size="small"
                                color="primary"
                                faClick={() => activation()}
                                data-fa-button="ACTIVATE"
                                disabled={
                                  counts.thingCount === 0 ||
                                  counts.userCount === 0
                                }
                              >
                                {t("ACTIVATE")}
                              </FaButton>

                              {(counts.thingCount === 0 ||
                                counts.userCount === 0) && (
                                <Tooltip title={getTooltipMessage()}>
                                  <IconButton aria-label="info" size="large">
                                    <Info />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                          {patrolDef.status === "ACTIVE" && (
                            <FaButton
                              style={{ width: "40%" }}
                              size="small"
                              color="primary"
                              faClick={() => passivation()}
                              data-fa-button="DEACTIVATE"
                            >
                              {t("DEACTIVATE")}
                            </FaButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              {patrolDef && patrolDef.id > 0 && (
                <PatrolDefUsers patrolDef={patrolDef} onChange={onChange} />
              )}
            </Grid>

            <Grid item xs={12}>
              {patrolDef && patrolDef.id > 0 && (
                <PatrolDefRecurrence
                  patrolDef={patrolDef}
                  setPatrolDef={setPatrolDef}
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12}>
          {patrolDef && patrolDef.id > 0 && (
            <PatrolDefControlItems patrolDef={patrolDef} />
          )}
        </Grid>

        <Grid item lg={12}>
          {patrolDef && patrolDef.id > 0 && (
            <PatrolDefThings patrolDef={patrolDef} onChange={onChange} />
          )}
        </Grid>
      </Grid>
    </Suspense>
  </>;
}
