import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { default as React, forwardRef, useState } from "react";
import { FaInput } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { ReportService } from "services/report/reportService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const StepUserInfo = forwardRef((props, ref) => {
  const {
    verificationType,
    report,
    handleChange,
    handleChangeV,
    publicThingAccountId,
    type,
  } = props;
  const { t } = useTranslation("report", { i18n });
  const labelClasses = labelStyles();

  const VERIFICATION_EMAIL = "EMAIL";
  const VERIFICATION_SMS = "SMS";
  const VERIFICATION_CAPTCHA = "CAPTCHA";

  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(null);

  const [isCountDownOn2, setIsCountDownOn2] = useState(false);

  let timer2 = 0;
  let seconds2 = 180;
  const [countDownTime2, setCountDownTime2] = useState();

  function secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  const showLegalText = (type) => {
    ReportService.getLegal(
      type,
      publicThingAccountId,
      showLegalTextOnSuccess,
      (error) => {
        setLegalText(null);
      }
    );
  };
  const showLegalTextOnSuccess = (data) => {
    setLegalText(data);
    setOpenLegalText(true);
  };

  function countDown2() {
    let tempSeconds = seconds2 - 1;
    seconds2 = tempSeconds;
    setCountDownTime2(secondsToTime(tempSeconds));

    if (tempSeconds == 0) {
      clearInterval(timer2);
      setCountDownTime2(null);
      setIsCountDownOn2(false);
    }
  }

  const onFocusPhone = () => {
    if (!report.phone || report.phone.length == 0)
      handleChangeV("phone", "+90 (5");
  };

  const onBlurPhone = () => {
    if (report.phone === "+90 (5**) *** ** **") handleChangeV("phone", "");
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenLegalText(false);
            }}
            color="primary"
          >
            {t("CLOSE")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderConfirmations = () => {
    return <>
      <div>
        <Grid item container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={report.faydalaniciAydinlatmaMetin}
              onChange={handleChange("faydalaniciAydinlatmaMetin")}
              name="faydalaniciAydinlatmaMetin"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText("FAYDALANICI_AYDINLATMA_METNI");
                }}
                className={labelClasses.SimpleLink}
                underline="hover">
                {t("BENEFICIARY_INFORMATION_TEXT")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid item container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item lg={1} xs={1}>
            <Checkbox
              checked={report.faydalaniciAydinlatmaOnay}
              onChange={handleChange("faydalaniciAydinlatmaOnay")}
              name="faydalaniciAydinlatmaOnay"
              color="primary"
            />
          </Grid>
          <Grid item lg={9} xs={11}>
            <Typography variant="body1">
              <Link
                onClick={() => {
                  showLegalText("FAYDALANICI_AYDINLATMA_METNI");
                }}
                className={labelClasses.SimpleLink}
                underline="hover">
                {t("BENEFICIARY_INFORMATION_TEXT_CONSENT")}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>;
  };
  const renderUserInfo = () => {
    if (verificationType === "SMS_EMAIL") {
      return <>
        {type === "USER_CONTENT" ? (
          <>
            <div>
              <FaInput
                label={t("NAME")}
                onChange={handleChange("userName")}
                value={report.userName || ""}
                required
              />
            </div>
            <div style={{ paddingBottom: 10 }}>
              <FaInput
                label={t("SURNAME")}
                onChange={handleChange("userSurname")}
                value={report.userSurname || ""}
                required
              />
            </div>
          </>
        ) : null}
        <div>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">
              {t("İletişim bilgileri seçimi")}
            </FormLabel>
            <RadioGroup
              row
              aria-label="position"
              value={report.verificationType}
              onChange={handleChange("verificationType")}
            >
              <FormControlLabel
                value={VERIFICATION_EMAIL}
                control={<Radio color="primary" />}
                label={t("EMAIL")}
              />
              <FormControlLabel
                value={VERIFICATION_SMS}
                control={<Radio color="primary" />}
                label={t("MOBILE_PHONE")}
              />
            </RadioGroup>
          </FormControl>
        </div>
        {report.verificationType === VERIFICATION_EMAIL && (
          <div>
            <FaInput
              faType="email"
              label={t("EMAIL")}
              autoComplete="email"
              onChange={handleChange("email")}
              value={report.email || ""}
              helperText={t("EMAIL_HELPER_TEXT")}
            />
          </div>
        )}
        {report.verificationType === VERIFICATION_SMS && (
          <div>
            <FaInput
              label={t("PHONE_NUMBER")}
              onChange={(event) =>
                handleChangeV("phone", event.target.allValues.formattedValue)
              }
              faMask="+## (###) ### ## ##"
              faMaskChar="*"
              value={report.phone}
              onFocus={onFocusPhone}
              onBlur={onBlurPhone}
              helperText={t("PHONE_HELPER_TEXT")}
            />
          </div>
        )}
        {renderConfirmations()}
      </>;
    } else if (verificationType === "SMS") {
      return <>
        {type === "USER_CONTENT" ? (
          <>
            <div>
              <FaInput
                label={t("NAME")}
                onChange={handleChange("userName")}
                value={report.userName || ""}
                required
              />
            </div>
            <div style={{ paddingBottom: 10 }}>
              <FaInput
                label={t("SURNAME")}
                onChange={handleChange("userSurname")}
                value={report.userSurname || ""}
                required
              />
            </div>
          </>
        ) : null}
        <div>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">
              {t("CONTACT_INFORMATION")}
            </FormLabel>
          </FormControl>
        </div>
        <div>
          <FaInput
            label={t("PHONE_NUMBER")}
            onChange={(event) =>
              handleChangeV("phone", event.target.allValues.formattedValue)
            }
            faMask="+## (###) ### ## ##"
            faMaskChar="*"
            value={report.phone}
            onFocus={onFocusPhone}
            onBlur={onBlurPhone}
            helperText={t("PHONE_HELPER_TEXT")}
          />
        </div>
        {renderConfirmations()}
      </>;
    } else if (verificationType === "EMAIL") {
      return <>
        {type === "USER_CONTENT" ? (
          <>
            <div>
              <FaInput
                label={t("NAME")}
                onChange={handleChange("userName")}
                value={report.userName || ""}
                required
              />
            </div>
            <div style={{ paddingBottom: 10 }}>
              <FaInput
                label={t("SURNAME")}
                onChange={handleChange("userSurname")}
                value={report.userSurname || ""}
                required
              />
            </div>
          </>
        ) : null}
        <div>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">
              {t("CONTACT_INFORMATION")}
            </FormLabel>
          </FormControl>
        </div>
        <div>
          <FaInput
            faType="email"
            label={t("EMAIL")}
            autoComplete="email"
            onChange={handleChange("email")}
            value={report.email || ""}
            helperText={t("EMAIL_HELPER_TEXT")}
          />
        </div>
        {renderConfirmations()}
      </>;
    } else if (verificationType === "CAPTCHA") {
      return <>
        {type === "USER_CONTENT" ? (
          <>
            <div>
              <FaInput
                label={t("NAME")}
                onChange={handleChange("userName")}
                value={report.userName || ""}
                required
              />
            </div>
            <div style={{ paddingBottom: 10 }}>
              <FaInput
                label={t("SURNAME")}
                onChange={handleChange("userSurname")}
                value={report.userSurname || ""}
                required
              />
            </div>
          </>
        ) : null}
        <div>
          <FormControl variant="standard" component="fieldset">
            <FormLabel component="legend">
              {t("CONTACT_INFORMATION")}
            </FormLabel>
          </FormControl>
        </div>
        <div>
          <FaInput
            faType="email"
            label={t("EMAIL")}
            autoComplete="email"
            onChange={handleChange("email")}
            value={report.email || ""}
            helperText={t("EMAIL_HELPER_TEXT")}
          />
        </div>
        <div>
          <FaInput
            label={t("PHONE_NUMBER")}
            onChange={(event) =>
              handleChangeV("phone", event.target.allValues.formattedValue)
            }
            faMask="+## (###) ### ## ##"
            faMaskChar="*"
            value={report.phone}
            onFocus={onFocusPhone}
            onBlur={onBlurPhone}
            helperText={t("PHONE_HELPER_TEXT")}
          />
        </div>
        {renderConfirmations()}
      </>;
    }
  };

  return (
    <>
      {renderLegalTextDialog()}
      {renderUserInfo()}
    </>
  );
});

export default StepUserInfo;
