import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardHeader, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Edit } from "@mui/icons-material";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CouponService } from "services/CouponService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CouponTargetEdit from "./CouponTargetEdit";
import { useStyles } from "./style";

export default function CouponTarget(props) {
  const { coupon } = props;

  const { t } = useTranslation("coupon", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [targets, setTargets] = useState();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [coupon]);

  const refresh = () => {
    setLoading(true);
    CouponService.findTargets(id, findTargetsOnSuccess, findTargetsOnError);
  };

  const findTargetsOnSuccess = (data) => {
    setTargets(data);
    setLoading(false);
  };

  const findTargetsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };

  const renderTargets = () => {
    if (coupon == null || targets == null || targets.length === 0) {
      return <div>Kayıt bulunamadı.</div>;
    } else
      return (
        <div>
          {targets?.map((target, index) => {
            let icon;
            let label;

            if (target.customer) {
              icon = <FontAwesomeIcon icon={faUser} />;
              label =
                (target.customer.name || "") +
                " " +
                (target.customer.surname || "") +
                " " +
                (target.customer.email || "") +
                " " +
                (target.customer.phone || "");
            } else if (target.customerList) {
              icon = <FontAwesomeIcon icon={faUsers} />;
              label = target.customerList.name;
            }

            return <Chip icon={icon} label={label} className={classes.chip} />;
          })}
        </div>
      );
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      <CouponTargetEdit
        couponId={coupon.id}
        targets={targets}
        onSave={() => {
          refresh();
          setIsEditFormOpen(false);
        }}
        onClose={() => {
          setIsEditFormOpen(false);
        }}
        open={isEditFormOpen}
      />
      <Card style={{ minHeight: 204 }}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              data-fa-button="COUPON_SCOPE_EDIT"
              disabled={coupon.status === "ACTIVE"}
              onClick={() => {
                setIsEditFormOpen(true);
              }}
              size="large">
              <Edit />
            </IconButton>
          }
          title={t("COUPON_SCOPE")}
          data-fa-section="COUPON_SCOPE"
        />
        <CardContent>
          <Grid item container spacing={1}>
            {renderTargets()}
          </Grid>
        </CardContent>
      </Card>
    </Suspense>
  </>;
}
