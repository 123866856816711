import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardHeader, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import {
  createRef,
  default as React,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CampaignService } from "services/CampaignService";
import { GetFieldByLanguage } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import CampaignPageEdit from "./CampaignPageEdit";
import CampaignQuestionEdit from "./CampaignQuestionEdit";
import CampaignSurveyQuestionRender from "./CampaignSurveyQuestionRender";
import { useStyles } from "./style";

export default function CampaignPage(props) {
  const { campaign, currentLang } = props;

  const accountId = localStorage.accountId;
  const { t } = useTranslation("campaign", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  let refArray = [];
  const pageDialogRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [selectedQuestion, setSelectedQuestion] = useState(false);

  const dialogRef = useRef();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    refresh();
  }, [campaign]);

  const refresh = () => {
    setLoading(true);
    CampaignService.findPages(id, findPagesOnSuccess, findPagesOnError);
    CampaignService.findQuestions(
      id,
      findQuestionsOnSuccess,
      findQuestionsOnError
    );
  };

  const findQuestionsOnSuccess = (data) => {
    setQuestions(data);
    setLoading(false);
  };
  const findQuestionsOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const findPagesOnSuccess = (data) => {
    setPages(data);
    setLoading(false);
  };

  const findPagesOnError = (error) => {
    showAlert(error, "error");
    setLoading(false);
  };
  const movePageDown = (page) => {
    let campaignId = page?.campaign?.id;
    let pageId = page?.id;
    CampaignService.questionMoveDown(
      campaignId,
      pageId,
      moveOnSuccess,
      () => {}
    );
  };
  const movePageUp = (page) => {
    let campaignId = page?.campaign?.id;
    let pageId = page?.id;
    CampaignService.questionMoveUp(campaignId, pageId, moveOnSuccess, () => {});
  };
  const moveOnSuccess = () => {
    refresh();
  };
  const renderMoveButton = (page, up) => {
    return (
      <IconButton
        onClick={() => (up === true ? movePageUp : movePageDown(page))}
        disabled={campaign.status !== "DRAFT"}
        aria-label={up === true ? "Yukarı taşı" : "Aşağı taşı"}
        size="large">
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };
  const onChangeAnswer = () => {};

  const moveQuestionDown = (question) => {
    let campaignId = question?.campaignPage?.campaign?.id;
    let questionId = question?.id;
    CampaignService.questionMoveDown(
      campaignId,
      questionId,
      moveQuestionSuccess,
      () => {}
    );
  };
  const moveQuestionUp = (question) => {
    let campaignId = question?.campaignPage?.campaign?.id;
    let questionId = question?.id;
    CampaignService.questionMoveUp(
      campaignId,
      questionId,
      moveQuestionSuccess,
      () => {}
    );
  };
  const moveQuestionSuccess = () => {
    refresh();
  };

  const renderQuestionMoveButton = (question, up) => {
    return (
      <IconButton
        onClick={() =>
          up === true ? moveQuestionUp(question) : moveQuestionDown(question)
        }
        aria-label={up === true ? "Yukarı taşı" : "Aşağı taşı"}
        size="large">
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };

  const questionEditButtonClicked = (question) => {
    setSelectedQuestion(question);
    dialogRef.current.openDialog();
  };

  const renderPages = () => {
    refArray = [];
    return pages.map((page, index) => {
      let pageRef = createRef();
      refArray.push(pageRef);
      let questionDialogref = createRef();
      return (
        <div key={index}>
          <CampaignPageEdit
            ref={pageRef}
            page={page}
            campaign={campaign}
            onSave={() => {
              refresh();
            }}
            currentLang={currentLang}
          />
          <CampaignQuestionEdit
            ref={questionDialogref}
            pageId={page.id}
            campaign={campaign}
            onSave={() => {
              refresh();
            }}
            currentLang={currentLang}
          />
          <Card
            style={{
              minWidth: "100%",
              minHeight: 200,
              marginTop: 10,
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <CardHeader
              action={
                <>
                  {renderMoveButton(page, true)}
                  {renderMoveButton(page, false)}
                  <IconButton
                    aria-label="settings"
                    disabled={campaign.status !== "DRAFT"}
                    onClick={() => {
                      questionDialogref.current.openDialog();
                    }}
                    size="large">
                    <AddIcon style={{ fontSize: 33 }} />
                  </IconButton>
                  <IconButton
                    aria-label="settings"
                    disabled={campaign.status !== "DRAFT"}
                    onClick={() => {
                      pageRef.current.openDialog();
                    }}
                    size="large">
                    <EditIcon />
                  </IconButton>
                </>
              }
              title={GetFieldByLanguage(page.multiLangName, currentLang)}
              data-fa-section="CAMPAIGN_PAGES"
            />
            <CardContent>
              {questions.map(
                (item, index) =>
                  item.campaignPage.id === page.id && (
                    <Grid
                      item
                      container
                      alignItems="center"
                      spacing={1}
                      key={index}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Grid item xs={10}>
                        <CampaignSurveyQuestionRender
                          key={index}
                          question={item}
                          onChangeAnswer={onChangeAnswer}
                          answer={{ surveyQuestion: { id: item.id } }}
                          currency={campaign.currency}
                          editable={true}
                          readOnly={false}
                          accountId={accountId}
                          currentLang={currentLang}
                        />
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: "right" }}>
                        {campaign.status === "DRAFT" && (
                          <>
                            {renderQuestionMoveButton(item, true)}
                            {renderQuestionMoveButton(item, false)}
                            <IconButton
                              aria-label="settings"
                              onClick={() => {
                                questionEditButtonClicked(item);
                              }}
                              size="large">
                              <EditIcon />
                            </IconButton>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )
              )}
            </CardContent>
          </Card>
        </div>
      );
    });
  };
  return <>
    <Suspense fallback={<LoadingBar />}>
      <CampaignPageEdit
        ref={pageDialogRef}
        campaign={campaign}
        onSave={() => {
          refresh();
        }}
        currentLang={currentLang}
      />
      <CampaignQuestionEdit
        ref={dialogRef}
        campaign={campaign}
        question={selectedQuestion}
        onSave={() => {
          refresh();
        }}
        currentLang={currentLang}
      />
      <Card>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              disabled={campaign.status !== "DRAFT"}
              onClick={() => {
                pageDialogRef.current.openDialog();
              }}
              data-fa-button="CAMPAIGN_PAGE_ADD"
              size="large">
              <AddIcon />
            </IconButton>
          }
          title={
            campaign.type === "CAMPAIGN"
              ? t("CAMPAIGN_PAGES")
              : t("EVENT_PAGES")
          }
          data-fa-section="CAMPAIGN_PAGES"
        />
      </Card>
      {renderPages()}
    </Suspense>
  </>;
}
