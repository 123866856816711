import { Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CustomerThingAutoComplete from "components/autocomplete/CustomerThingAutoComplete";
import IssueClassAutoComplete from "components/autocomplete/IssueClassAutoComplete";
import ThingDetail from "components/detail/ThingDetail";
import LoadingBar from "components/loadingBar";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat, IssueDesc } from "services/utils";
import IssueEdit from "./IssueEdit";

export default function OpenIssues() {
  const { t } = useTranslation(["beneficiaryPortal", "report"], { i18n });

  const [loading, setLoading] = useState(false);
  const dialogRef = useRef();
  const tableRef = useRef();
  const accountId = localStorage.beneficiaryAccountId;

  const newSearchForm = () => {
    return {
      notifier: null,
      thing: null,
      thingId: null,
      issueClass: null,
      issueClassId: null,
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };
  const columns = [
    {
      field: "thing",
      label: t("Varlık"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return <ThingDetail value={value} noLink={true} />;
      },
    },
    {
      field: "notificationDate",
      label: t("Bildirim Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "id2",
      label: t("Sorun"),
      align: "center",
      width: "30%",
      valueFormatter: (val, row) => IssueDesc(row),
    },
    {
      field: "status",
      label: t("Bildirim Durumu"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return t("ISSUE_STATUS_" + value, { ns: "report" });
      },
    },
    {
      field: "closeDate",
      label: t("Kapatılma Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
  ];

  const addNewIssueRender = () => {
    return (
      <IssueEdit
        onSave={() => tableRef.current.refreshTable()}
        ref={dialogRef}
      ></IssueEdit>
    );
  };

  return (
    <Suspense fallback={<LoadingBar />}>
      {addNewIssueRender()}
      <ThingsDataTable
        searchUrl={BENEFICIARY_PORTAL.openIssues}
        searchForm={searchForm}
        columns={columns}
        ref={tableRef}
        showHeaderText={false}
        queryParams={{
          page: 0,
          size: 10,
          isAsc: false,
          sort: "notificationDate",
        }}
      />

      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid
          item
          xs={1}
          className={"thingsDataTableButtons"}
          style={{ paddingLeft: 1 }}
        >
          <FaButton
            variant="contained"
            disabled={loading}
            color="primary"
            size="medium"
            faClick={() => {
              dialogRef.current.openDialog();
            }}
            startIcon={<AddIcon />}
            data-fa-button="ADD_ISSUE"
          >
            {t("ADD")}
          </FaButton>
        </Grid>
        <Grid item xs={5}>
          <div>{searchInfo}</div>
        </Grid>
        <Grid item xs={6}>
          <FaSearch
            onSearch={searchSubmit}
            onClear={searchClear}
            onSummarySearch={searchSummary}
            setSearchInfo={setSearchInfo}
            faSummary={searchForm?.summary}
            faClassName="appSearchBar"
          >
            <CustomerThingAutoComplete
              required
              accountId={accountId}
              value={searchForm?.thing || null}
              onChange={(data) => {
                handleChangeGeneric(
                  setSearchForm,
                  searchForm,
                  "thing",
                  data,
                  "thingId",
                  data?.id
                );
              }}
            />
            <IssueClassAutoComplete
              accountId={accountId}
              value={searchForm.issueClass}
              onChange={(data) => {
                handleChangeGeneric(
                  setSearchForm,
                  searchForm,
                  "issueClass",
                  data,
                  "issueClassId",
                  data?.id
                );
              }}
            />
            <FaInput
              label={t("DESCRIPTION")}
              value={searchForm.description}
              onChange={(event) => {
                handleChangeGeneric(
                  setSearchForm,
                  searchForm,
                  "description",
                  event.target.value
                );
              }}
            />
          </FaSearch>
        </Grid>
      </Grid>
    </Suspense>
  );
}
