import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import { default as React, Suspense, useEffect, useRef, useState } from "react";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import ServiceOperationEdit from "./ServiceOperationEdit";
import ServiceOperationPhotos from "./ServiceOperationPhotos";
import ServiceOperationProcedure from "./ServiceOperationProcedure";
import ServiceOperationStock from "./ServiceOperationStock";
import { useStyles } from "./style";

export default function ServiceOperationClassDetail() {
  const fileInputRef = useRef();
  const classes = useStyles();
  const labelClasses = labelStyles();
  const dialogRef = useRef();
  const { t } = useTranslation("serviceOperationClass", { i18n });
  const { id } = useParams();
  const accountId = localStorage.accountId; //useSelector(getAccountId);
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState("");
  const [serviceOperationClass, setServiceOperationClass] = useState();

  useEffect(() => {
    if (id) refresh();
  }, [id]);

  const refresh = () => {
    setLoading(true);
    ServiceOperationClassService.findById(
      id,
      findByIdOnSuccess,
      findByIdOnError
    );
  };

  const findByIdOnSuccess = (data) => {
    setServiceOperationClass(data);
    setLoading(false);
  };

  const findByIdOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const onInfoUpdate = (newData) => {
    setServiceOperationClass(newData);
    showAlert("Bakım görevi bilgileri güncellendi", "success");
  };

  const goParent = () => {
    if (serviceOperationClass?.parent.id) {
      const tmpTo = {
        pathname: `${serviceOperationClass?.parent.id}`,
        breadCrumbKey: `/serviceOperationClassDetail/:id`,
      };
      history.push(tmpTo);
    }
  };

  const renderDialog = () => (
    <ServiceOperationEdit
      serviceOperation={serviceOperationClass}
      onSave={refresh}
      ref={dialogRef}
    ></ServiceOperationEdit>
  );

  return <>
    <Suspense fallback={<LoadingBar />}>
      {renderDialog()}
      <div>
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="settings"
                        onClick={() => {
                          dialogRef.current.openDialog();
                        }}
                        disabled={!!!serviceOperationClass?.account}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    }
                    title="Bakım Görevi Bilgileri"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={12}>
                        <Grid
                          item
                          container
                          spacing={1}
                          style={{ marginLeft: 10, marginRight: 10 }}
                        >
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("GENERIC_CLASSNAME")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.className}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("CODE")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.code}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DOMAIN")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.domain}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DESCRIPTION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.description}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("EQUIPMENT")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.equipment}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("PERSONAL_COUNT")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.personalCount}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("DURATION")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.duration}{" "}
                              {t(serviceOperationClass?.timePeriod)}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("TRAININGS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.trainings}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("WARNINGS")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.warnings}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={labelClasses.LabelLastRow}
                          >
                            <Typography className={labelClasses.LabelHeader}>
                              {t("ACCOUNT")}
                            </Typography>
                            <Typography className={labelClasses.LabelValue}>
                              {serviceOperationClass?.account
                                ? serviceOperationClass?.account.fullname
                                : t("SYSTEM")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                {serviceOperationClass && serviceOperationClass.id > 0 && (
                  <ServiceOperationPhotos
                    serviceOperation={serviceOperationClass}
                  ></ServiceOperationPhotos>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                {serviceOperationClass && serviceOperationClass.id > 0 && (
                  <ServiceOperationStock
                    serviceOperation={serviceOperationClass}
                  ></ServiceOperationStock>
                )}
              </Grid>

              <Grid item xs={12}>
                {serviceOperationClass && serviceOperationClass.id > 0 && (
                  <ServiceOperationProcedure
                    serviceOperation={serviceOperationClass}
                  ></ServiceOperationProcedure>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Suspense>
  </>;
}
