import { IconButton } from "@mui/material";
import ThingsHelpIcon from "components/icons/ThingsHelpIcon";
import ThingsSteps from "features/tutorial/ThingsSteps";
import TutorialElement from "features/tutorial/TutorialElement";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function BeneficiaryNotesHelp(props) {
  const { value, thing, issue } = props;
  const { t } = useTranslation("help", { i18n });

  const classes = useStyles();
  const stepsRef = useRef();
  const [open, setOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Sorun bildiren müşterinize <b>mesaj göndermek</b> için kullanılan
            alandır.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-button='ADD_BENEFICIARY_NOTES']",
        intro: (
          <TutorialElement icon="click">
            Sorun bildiren müşterinize <b>mesaj göndermek</b> için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-section='CHANNEL_INPUT']",
        intro: (
          <TutorialElement icon="click">
            Tıklayın ve Mesaj Kanalı olarak <b>“SMS”, “E-Posta”</b> veya{" "}
            <b>“Mobil Bildirim”</b>den birini seçin.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          $("[data-fa-button='ADD_BENEFICIARY_NOTES']")[0].click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "[data-fa-section='MESSAGE_CONTENT_INPUT']",
        intro: (
          <TutorialElement icon="click">
            Mesajınızı girin ve Mesaj Gönder’e tıklayın.
          </TutorialElement>
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <>
      {open && (
        <ThingsSteps
          code={value}
          steps={steps}
          ref={stepsRef}
          onExit={props.onExit}
          onStepChange={setStepIndex}
          afterComplete={() => {
            setOpen(true);
            var el = $("[aria-label='close']");
            if (el != null && el[0] != null) el[0].click();
          }}
        />
      )}
      <IconButton
        aria-label="help"
        onClick={() => setOpen(true)}
        title={t("HELP")}
        size="large"
      >
        <ThingsHelpIcon />
      </IconButton>
    </>
  );
}

BeneficiaryNotesHelp.propTypes = {
  value: PropTypes.string,
};
