import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function RoleSelect(props) {
  const { value, onChange, required } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100}>
      <InputLabel>{t("ROLE")}</InputLabel>
      <Select
        variant="outlined"
        // classes={{
        //   outlined: classes.padding10,
        // }}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={t("ROLE")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"BENEFICIARY"}>{t("ROLE_BENEFICIARY")}</MenuItem>
        <MenuItem value={"POWER_USER"}>{t("ROLE_POWER_USER")}</MenuItem>
        <MenuItem value={"THINGMANAGER"}>{t("ROLE_THINGMANAGER")}</MenuItem>
        <MenuItem value={"OPERATOR"}>{t("ROLE_OPERATOR")}</MenuItem>
        <MenuItem value={"ACCOUNTOWNER"}>{t("ROLE_ACCOUNTOWNER")}</MenuItem>
      </Select>
    </FormControl>
  );
}
