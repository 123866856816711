import { Grid, Typography } from "@mui/material";
import SurveyAutoComplete from "components/autocomplete/SurveyAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingTableDecorator from "components/decorator/ThingTableDecorator";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { getDateTimeWFormat, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { SURVEY } from "../../services/faApi";
import SurveyAnswerDetail from "./SurveyAnswerDetail";

export default function SurveyAnswerList() {
  const tableRef = useRef();
  const { t } = useTranslation(["survey", "components"], { i18n });
  const dispatch = useDispatch();
  const { id } = useParams();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [totalItems, setTotalItems] = useState(0);

  const columns = [
    {
      field: "answerDate",
      label: t("ANSWER_DATE"),
      align: "center",
      width: "20%",
      valueFormatter: (val) => {
        return getDateTimeWFormat(val);
      },
    },
    {
      field: "survey",
      label: t("Anket"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        let langs = value?.languages;
        let name = "";
        langs &&
          langs.map((lang, index) => {
            if (index > 0) {
              name = name.concat(" / ");
            }
            name = name.concat(value?.multiLangName?.[lang.toLowerCase()]);
          });
        return name;
      },
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "60%",
      valueFormatter: (val) => {
        //return <ThingDetail value={val} noLink={true} />;
        return <ThingTableDecorator value={val} />;
      },
    },
  ];

  const navigateToAnalysis = (id) => {
    const tmpTo = {
      pathname: `survey/analysis/${id}`,
      breadCrumbKey: `/survey/analysis/:id`,
    };
    history.push(tmpTo);
  };

  const newSearchForm = () => {
    return {
      accountId: accountId,
      survey: null,
      surveyId: id,
      answerDateStart: null,
      answerDateEnd: null,
      thing: null,
      thingId: null,
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row, index) => {
    const queryParams = tableRef.current.getQueryParams();
    setCurrentItem(queryParams.page * queryParams.size + index);
    setTotalItems(queryParams.totalItems);

    setSelected(row);
    setIsOpen(true);
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };
  const onPrevious = () => {
    const queryParams = tableRef.current.getQueryParams();
    SurveyService.getPrevious(
      accountId,
      id,
      selected.id,
      queryParams.sort,
      queryParams.isAsc,
      setSelected,
      (error) => {}
    );
    setCurrentItem((prev) => prev - 1);
  };

  const onNext = () => {
    const queryParams = tableRef.current.getQueryParams();
    SurveyService.getNext(
      accountId,
      id,
      selected.id,
      queryParams.sort,
      queryParams.isAsc,
      setSelected,
      (error) => {}
    );
    setCurrentItem((prev) => prev + 1);
  };
  const calculateDate = (data) => {
    var today = new Date();
    let start = null;

    if (data === -7) {
      start = new Date(today);
      start.setDate(start.getDate() - 7);
    } else if (data === -31) {
      start = new Date(today.setMonth(today.getMonth() - 1));
    }

    return start;
  };
  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <SurveyAnswerDetail
          id={selected?.id}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
            setSelected(null);
          }}
          surveyId={selected?.survey?.id}
          onPrevious={onPrevious}
          onNext={onNext}
          currentItem={currentItem}
          totalItems={totalItems}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <SurveyAutoComplete
                accountId={accountId}
                value={searchForm.survey}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "survey",
                    data,
                    "surveyId",
                    data?.id
                  );
                }}
              />

              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />

              <DateRangeSelector
                label={t("ANSWER_DATE")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    answerDateStart: data.startDate,
                    answerDateEnd: data.endDate,
                  });
                }}
                data-fa-search="TARIH"
              />
            </FaSearch>
          </Grid>
        </Grid>
        <Grid item container spacing={0} style={{ marginBottom: 5 }}>
          <Grid item lg={4} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TARIH")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  value={searchForm.notificationDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      notificationDateValue: data,
                      answerDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={SURVEY.answerSearch}
          exportUrl={
            SURVEY.exportAnswers + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          queryParams={{
            page: 0,
            size: 10,
            sort: "answerDate",
            isAsc: false,
          }}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
      </Suspense>
    </>
  );
}
