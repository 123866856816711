import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  adDiv: {
    height: "100%",
    width: "100%",
    margin: "auto",
    padding: 2,
    justifyContent: "center",
  },

  adDivAlt: {
    height: "100%",
    width: "100%",
    margin: "auto",
    padding: 2,
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },

  adImage: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
