import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import LocationAutoComplete from "components/autocomplete/LocationAutoComplete";
import OrganizationAutoComplete from "components/autocomplete/OrganizationAutoComplete";
import ThingClassAutoComplete from "components/autocomplete/ThingClassAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import VendorAutoComplete from "components/autocomplete/VendorAutoComplete";
import DateRangeSelector from "components/dateRangeSelector";
import UserDetail from "components/detail/UserDetail";
import ThingHashtag from "components/hashtag/ThingHashtag";
import YesNoSelect from "components/select/YesNoSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThingDesc, isTNF, smartTimeDiff } from "services/utils";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { THING } from "../../services/faApi";

export default function ThingArchives() {
  const { t } = useTranslation(["things", "errorMessages"], { i18n });
  const tableRef = useRef();
  const dialogRef = useRef();
  let history = useHistory();
  const role = useSelector(getRole);
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;

  const accountId = localStorage.accountId;
  const [selectedRows, setSelectedRows] = useState([]);

  const newSearchForm = () => {
    let result = {
      archive: true,
      accountId: accountId,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `thingDetail/${row.id}`,
      breadCrumbKey: `/thingDetail/:id`,
      customLabel: ThingDesc(row),
    };
    history.push(tmpTo);
  };

  const columns = [
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (value, row) => {
        //if(row.thumbnail != null)
        //FIXME public url haline getirildi
        if (row.thumbnail != null)
          //return <div style={{display: "flex", alignItems: "center"}}><img src={BASE_URL + THING.getImage + "?thingId=0" + "&objectId=" + row.defaultThumbnail } style={{width: "48px", height: "40px", marginRight: "10px"}} /> {value}</div>
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    {
      field: "organization",
      label: t("ORGANIZATION"),
      align: "center",
      width: "20%",
      inner: "name",
      valueFormatter: (value) => {
        return value?.name;
      },
    },
    {
      field: "ownerUser",
      label: t("OWNER"),
      align: "center",
      width: "20%",
      inner: "fullname",
      valueFormatter: (value) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        return smartTimeDiff(value);
      },
    },
    {
      field: "openIssueCount",
      label: t("OPEN_ISSUES"),
      align: "center",
      width: "10%",
      valueFormatter: (value) => {
        if (value && value > 0)
          return (
            <Badge
              badgeContent={value}
              color="secondary"
              style={{ paddingLeft: 12 }}
            ></Badge>
          );
        return <CheckIcon />;
      },
    },
  ];

  useEffect(() => {}, []);

  const clearSelectedRows = () => {
    tableRef.current.clearSelectedRows();
  };

  const onSelectedChange = (rows) => {
    setSelectedRows(rows);
  };

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <ThingClassAutoComplete
                accountId={accountId}
                value={searchForm.thingClassList}
                filterExists={true}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    thingClassList: data,
                    thingClassIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="THING_CLASS"
              />
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    description: event.target.value,
                  });
                }}
                data-fa-search="DESCRIPTION"
              />
              <FaInput
                label={t("THING_CODE")}
                value={searchForm.thingCode}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    thingCode: event.target.value,
                  });
                }}
                data-fa-search="THING_CODE"
              />
              <FaInput
                label={t("Nesne Kimlik Numarası")}
                value={searchForm.thid}
                onChange={(event) => {
                  setSearchForm({
                    ...searchForm,
                    thid: event.target.value,
                  });
                }}
                data-fa-search="Nesne Kimlik Numarası"
              />

              <ThingHashtag
                label={t("HASHTAG")}
                accountId={accountId}
                value={searchForm.hashtag}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    hashtag: data,
                  });
                }}
                data-fa-search="HASHTAG"
              />

              <OrganizationAutoComplete
                accountId={accountId}
                value={searchForm.organizationList}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    organizationList: data,
                    organizationIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="ORGANIZATION"
              />
              <LocationAutoComplete
                accountId={accountId}
                value={searchForm.locationList}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    locationList: data,
                    locationIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="LOCATION"
              />
              <UserAndGroupAutoComplete
                label={t("OWNER")}
                value={{
                  user: searchForm?.ownerUser,
                  userGroup: searchForm?.ownerUserGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    ownerUser: data && data.user ? data.user : null,
                    ownerUserId: data && data.user ? data.user?.id : null,
                    ownerUserGroup:
                      data && data.userGroup ? data.userGroup : null,
                    ownerUserGroupId:
                      data && data.userGroup ? data.userGroup?.id : null,
                  });
                }}
                data-fa-search="OWNER"
              />
              <UserAndGroupAutoComplete
                label={t("MTNC")}
                value={{
                  user: searchForm?.mtncUser,
                  userGroup: searchForm?.mtncUserGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    mtncUser: data && data.user ? data.user : null,
                    mtncUserId: data && data.user ? data.user?.id : null,
                    mtncUserGroup:
                      data && data.userGroup ? data.userGroup : null,
                    mtncUserGroupId:
                      data && data.userGroup ? data.userGroup?.id : null,
                  });
                }}
                data-fa-search="MTNC"
              />
              <UserAndGroupAutoComplete
                label={t("USER")}
                value={{
                  user: searchForm?.user,
                  userGroup: searchForm?.userGroup,
                }}
                accountId={accountId}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    user: data && data.user ? data.user : null,
                    userId: data && data.user ? data.user?.id : null,
                    userGroup: data && data.userGroup ? data.userGroup : null,
                    userGroupId:
                      data && data.userGroup ? data.userGroup?.id : null,
                  });
                }}
                data-fa-search="USER"
              />
              <VendorAutoComplete
                accountId={accountId}
                value={searchForm.vendorList}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    vendorList: data,
                    vendorIdList: data?.map((item) => {
                      return item.id;
                    }),
                  });
                }}
                multiple
                data-fa-search="VENDOR"
              />

              <YesNoSelect
                value={searchForm.openIssues}
                label={t("OPEN_ISSUES")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    openIssues: data,
                  });
                }}
                yesLabel={t("SERVICE_STATUS_YES")}
                noLabel={t("SERVICE_STATUS_NO")}
                data-fa-search="OPEN_ISSUES"
              />

              <DateRangeSelector
                label={t("CREATE_DATE")}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    createDateStart: data.startDate,
                    createDateEnd: data.endDate,
                  });
                }}
                data-fa-search="TARIH"
              />
            </FaSearch>
          </Grid>
        </Grid>

        {
          <ThingsDataTable
            searchUrl={THING.search + "?accountId=" + localStorage.accountId}
            searchForm={searchForm}
            columns={columns}
            ref={tableRef}
            showDeleteButton={false}
            showHeaderText={false}
            headerText={t("VARLIK_LISTESI")}
            faOnDoubleClick={onDoubleClick}
            data-fa-list="List"
            queryParams={{
              page: 0,
              size: 10,
              isAsc: false,
              sort: "createDate",
            }}
          />
        }
      </Suspense>
    </>
  );
}
