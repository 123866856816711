import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import Alert from '@mui/material/Alert';
import React, { Suspense, useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import { FaButton, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { CouponService } from "services/CouponService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function UseCoupon() {
  const tableRef = useRef();
  const { t } = useTranslation("coupon", { i18n });
  const dispatch = useDispatch();
  const classes = useStyles();
  const labelClasses = labelStyles();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const dialogRef = useRef();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);

  const [couponCode, setCouponCode] = useState();
  const [customerCoupon, setCustomerCoupon] = useState();
  const [isCouponAvailable, setIsCouponAvailable] = useState(false);
  const [validationCode, setValidationCode] = useState(false);
  const [validationChannel, setValidationChannel] = useState();
  const [valCodeSent, setValCodeSent] = useState(false);

  const [message, setMessage] = useState();

  const handleChange = (val) => {
    setValidationCode(val);
  };
  const refresh = () => {
    checkCouponCode();
  };

  const checkCouponCode = () => {
    setLoading(true);
    setIsCouponAvailable(false);
    setMessage(null);
    CouponService.checkCouponCode(couponCode, checkOnSuccess, checkOnError);
  };
  const checkOnSuccess = (data) => {
    setCustomerCoupon(data);

    if (Date.parse(data?.coupon.endDate) < Date.now()) {
      setMessage("THE_COUPON_IS_EXPIRED");
    } else if (data?.usingStatus === "YES") {
      setMessage("THE_COUPON_IS_USED");
    } else if (data?.coupon.status !== "ACTIVE") {
      setMessage("THE_COUPON_IS_NOT_ACTIVE");
    } else {
      setIsCouponAvailable(true);
    }
    setLoading(false);
  };
  const checkOnError = (error) => {
    setLoading(false);
    setMessage("COUPON_NOT_FOUND");
    setCustomerCoupon(null);
  };

  const sendValidationCode = () => {
    CouponService.sendValidationCode(
      customerCoupon.id,
      validationChannel,
      sendOnSuccess,
      sendOnError
    );
  };
  const sendOnSuccess = () => {
    setLoading(false);
    setValCodeSent(true);
  };
  const sendOnError = () => {};

  const confirm = () => {
    CouponService.checkValidationCode(
      customerCoupon.id,
      validationCode,
      checkValOnSuccess,
      checkValOnError
    );
  };
  const checkValOnSuccess = (data) => {
    setLoading(false);
    setMessage("THE_COUPON_IS_USED");
    refresh();
  };
  const checkValOnError = () => {
    setMessage("WRONG_VAL_CODE");
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid item container spacing={1}>
          <Grid item lg={12}>
            <Grid item container spacing={1}>
              <Grid item lg={4}>
                <FaInput
                  label={t("COUPON_CODE")}
                  required
                  onChange={(event) => setCouponCode((event.target.value).trim())}
                  value={couponCode || ""}
                />
              </Grid>
              <Grid item lg={2} style={{ marginTop: 8 }}>
                <FaButton
                  variant="contained"
                  disabled={loading}
                  color="primary"
                  size="medium"
                  faClick={checkCouponCode}
                  //   startIcon={<AddIcon />}
                  data-fa-button="CHECK_CODE"
                >
                  {t("CHECK_CODE")}
                </FaButton>
              </Grid>

              {customerCoupon?.id && (
                <>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title={t("COUPON_INFO")}
                        data-fa-section="COUPON_INFO"
                      />
                      <CardContent>
                        <Grid item row container spacing={1}>
                          <Grid item xs={6}>
                            <Grid item container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("NAME")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {customerCoupon?.coupon.name}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("START_DATE")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {getDateTimeWFormat(
                                    customerCoupon?.coupon.startDate
                                  )}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("END_DATE")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {getDateTimeWFormat(
                                    customerCoupon?.coupon.endDate
                                  )}
                                </Typography>
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("USING_STATUS")}
                                </Typography>
                                <Typography
                                  component={"span"}
                                  variant={"body2"}
                                  className={labelClasses.LabelValue}
                                >
                                  {customerCoupon?.usingStatus === "YES"
                                    ? t("USED")
                                    : t("NOT_USED")}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelLastRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("USED_DATE")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {getDateTimeWFormat(customerCoupon?.usedDate)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid item container spacing={1}>
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("CUSTOMER_NAMES")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {customerCoupon?.customer?.name +
                                    " " +
                                    customerCoupon?.customer?.surname}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("CUSTOMER_PHONE")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {customerCoupon?.customer?.phone
                                    ? customerCoupon?.customer?.phone
                                    : "-"}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                className={labelClasses.LabelRow}
                              >
                                <Typography
                                  className={labelClasses.LabelHeader}
                                >
                                  {t("CUSTOMER_EMAIL")}
                                </Typography>
                                <Typography className={labelClasses.LabelValue}>
                                  {customerCoupon?.customer?.email
                                    ? customerCoupon?.customer?.email
                                    : "-"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}

              {(message === "THE_COUPON_IS_EXPIRED" ||
                message === "THE_COUPON_IS_NOT_ACTIVE" ||
                message === "COUPON_NOT_FOUND") && (
                <Alert
                  severity="error"
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  {t(message)}
                </Alert>
              )}

              {message === "THE_COUPON_IS_USED" && (
                <Alert
                  severity="success"
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  {t(message)}
                </Alert>
              )}

              {isCouponAvailable && message !== "THE_COUPON_IS_USED" && (
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={t("VALIDATE_COUPON")}
                      data-fa-section="VALIDATE_COUPON"
                    />
                    <CardContent>
                      <Grid item container>
                        <RadioGroup
                          row
                          aria-label="position"
                          name="position"
                          defaultValue="end"
                        >
                          {customerCoupon?.customer?.email && (
                            <FormControlLabel
                              value="EMAIL"
                              control={<Radio color="primary" />}
                              label={t("EMAIL")}
                              onChange={() => {
                                setValidationChannel("EMAIL");
                              }}
                            />
                          )}
                          {customerCoupon?.customer?.phone && (
                            <FormControlLabel
                              value="SMS"
                              control={<Radio color="primary" />}
                              label={t("SMS")}
                              onChange={() => {
                                setValidationChannel("SMS");
                              }}
                            />
                          )}
                        </RadioGroup>
                        <Grid item xs={4}>
                          <FaButton
                            variant="contained"
                            disabled={
                              loading || !validationChannel || valCodeSent
                            }
                            color="primary"
                            size="medium"
                            faClick={sendValidationCode}
                            //   startIcon={<AddIcon />}
                            data-fa-button="SEND_VALIDATION_CODE"
                          >
                            {t("SEND_VALIDATION_CODE")}
                          </FaButton>
                        </Grid>
                      </Grid>

                      {isCouponAvailable && valCodeSent && (
                        <Grid item row container alignItems="flex-start">
                          <Grid item xs={10}>
                            <AuthCode
                              onChange={handleChange}
                              containerClassName={classes.container}
                              inputClassName={classes.input}
                              allowedCharacters="numeric"
                            />
                          </Grid>

                          <Grid item xs={2} style={{ paddingTop: 30 }}>
                            <FaButton
                              variant="contained"
                              disabled={loading}
                              color="primary"
                              size="medium"
                              faClick={confirm}
                              //   startIcon={<AddIcon />}
                              data-fa-button="CONFIRM"
                            >
                              {t("CONFIRM")}
                            </FaButton>
                          </Grid>
                        </Grid>
                      )}
                      {message === "WRONG_VAL_CODE" && (
                        <Alert
                          severity="error"
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          {t(message)}
                        </Alert>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
