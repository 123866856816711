import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function StorageStatTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();

  const { t } = useTranslation("tutorialStorageStat", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Hesabınıza ait <b>Depolama Alan Durumu</b> ve{" "}
            <b>Kullanılan Depolama Alan Dağılımı</b> bilgilerinin yer aldığı
            alandır.
          </TutorialElement>
        ),
        comp: "StorageStat",
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"storage_stat"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
