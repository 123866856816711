import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DynamicFieldsEdit from "components/dynamicFieldsEdit";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { selectThemePrefer } from "react-base-fa/dist/AppSlice";
import { FaButton, FaFileUpload, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
// import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FingerPrintService } from "services/FingerPrintService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { GpsLocationService } from "../../services/location/GpsLocationService";
import { ReportService } from "../../services/report/reportService";
import LandingHeader from "./landing/header/LandingHeader";
import { useStyles } from "./ReportIssueStyle";
import StepCaptchaVerification from "./StepCaptchaVerification";
import StepEmailVerification from "./StepEmailVerification";
import StepPhoneVerification from "./StepPhoneVerification";
import StepUserInfo from "./StepUserInfo";

export default function ReportSuggestion(props) {
  const { loginPath, appLogo, miniAppLogo } = props;
  const { tid } = useParams();
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const emailVerRef = useRef();
  const smsVerRef = useRef();

  const [legalText, setLegalText] = useState(null);
  const [openLegalText, setOpenLegalText] = useState(null);
  const labelClasses = labelStyles();

  const [loading, setLoading] = useState(true);
  const [gpsError, setGpsError] = useState(false);
  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);

  const [isShowNextButton, setIsShowNextButton] = useState(false);
  const [saveOK, setSaveOK] = useState(false);
  const [visitorId, setVisitorId] = useState(null);
  const [blockReport, setBlockReport] = useState(false);
  const [classListExist, setClassListExist] = useState(false);

  const STEP_SUGG_CLASS_SELECT = 0;
  const STEP_DESC = 1;
  const STEP_PHOTO = 2;
  const STEP_USER_INFO = 3;
  const STEP_USER_VERIFY = 4;
  const STEP_SENDING = 5;
  const STEP_COMPLETED = 6;

  const VERIFICATION_EMAIL = "EMAIL";
  const VERIFICATION_SMS = "SMS";
  const VERIFICATION_CAPTCHA = "CAPTCHA";

  const [errorMessages, setErrorMessages] = useState({
    description: "",
    phone: "",
    email: "",
    suggestionClass: "",
  });

  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    description: true,
    phone: true,
    email: true,
    suggestionClass: true,
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    phone: true,
    email: true,
  });

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      GpsLocationService.getLocation(getLocationOnSuccess, getLocationOnError);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    FingerPrintService.getVisitorId(visitorIdOnSuccess, (error) => {
      visitorIdOnSuccess(null);
    });
  }, []);

  const visitorIdOnSuccess = (dataVisitorId) => {
    setVisitorId(dataVisitorId);
    setReport((prev) => {
      return { ...report, visitorId: dataVisitorId };
    });
    ReportService.checkVisitor(
      publicThing.accountId,
      dataVisitorId,
      "SUGGESTION",
      publicThing.id,
      (data) => {
        if (data === false) {
          setBlockReport(true);
        } else {
          if (publicThing.suggestionClassList.length === 0) {
            setActiveStep(1); // öneri listesi boş, öneri tipi seçme aşamasını atla
          } else {
            setActiveStep(0);
            setClassListExist(true); // öneri listesi dolu
          }
        }
      },
      (error) => {}
    );
  };

  const [files, setFiles] = useState([]);

  const onFileChange = (data) => {
    if (data && data.length > 0) {
      setFiles([...files, ...data]);
    } else setFiles(data);
  };

  const getLocationOnSuccess = (data) => {
    setReport((prev) => {
      return { ...prev, gpsLocation: JSON.stringify(data) };
    });
  };

  const getLocationOnError = (data) => {
    setGpsError(data);
  };

  const getInitialVerificationType = () => {
    if (publicThing.publicReportSuggestionVerificationType === "SMS")
      return VERIFICATION_SMS;
    else if (publicThing.publicReportSuggestionVerificationType === "EMAIL")
      return VERIFICATION_EMAIL;
    else if (publicThing.publicReportSuggestionVerificationType === "SMS_EMAIL")
      return VERIFICATION_EMAIL;
    else if (publicThing.publicReportSuggestionVerificationType === "CAPTCHA")
      return VERIFICATION_CAPTCHA;
  };

  const [report, setReport] = useState({
    thing: { id: location.state.publicThing.id },
    description: "",
    email: "",
    phone: "",
    gpsLocation: null,
    verificationType: getInitialVerificationType(),
    suggestionClass: null,
    membership: false,
    acikRiza: false,
    kvkk: false,
  });
  const [reported, setReported] = useState(false);
  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);

  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  const theme = useSelector(selectThemePrefer);
  const dispatch = useDispatch();

  const submitReport = () => {
    setLoading(true);
    const postData = { ...report, dynamicValues: dynamicValues };
    ReportService.reportSuggestion(
      postData,
      files,
      reportSuggestionOnSuccess,
      reportSuggestionOnError
    );
  };

  const reportSuggestionOnSuccess = (data) => {
    setLoading(false);
    setReported(true);
    setActiveStep((prevActiveStep) => STEP_COMPLETED);
  };

  const reportSuggestionOnError = (data) => {
    setLoading(false);
    showAlert(t("SUBMIT_ERROR_MSG"), "error");
    handleNextCallback(false);
  };

  const handleChange = (prop) => (event) => {
    if (
      prop === "membership" ||
      prop === "acikRiza" ||
      prop === "kvkk" ||
      prop === "kvkkOnay" ||
      prop === "kullaniciSozlesme" ||
      prop === "faydalaniciAydinlatmaMetin" ||
      prop === "faydalaniciAydinlatmaOnay"
    ) {
      setReport({ ...report, [prop]: event.target.checked });
    } else {
      setReport({ ...report, [prop]: event.target.value });
    }
    setIsFieldsEmpty({
      ...isFieldsEmpty,
      [prop]: event.target.value ? false : true,
    });

    if (prop === "email") {
      setIsFieldsValid({
        ...isFieldsValid,
        email: event.target.validity.valid,
      });

      if (report.verificationType === VERIFICATION_EMAIL) {
        setIsShowNextButton(
          event.target.value !== "" && event.target.validity.valid
        );
      } else setIsShowNextButton(event.target.validity.valid);
    } else if (prop === "phone") {
      setIsFieldsValid({
        ...isFieldsValid,
        phone: event.target.validity.valid,
      });
    } else if (prop === "description") {
      setIsShowNextButton(event.target.value !== "");
    }
  };

  const handleChangeV = (prop, value) => {
    setReport({ ...report, [prop]: value });
    setIsFieldsEmpty({ ...isFieldsEmpty, [prop]: value ? false : true });

    if (prop === "phone" && report.verificationType === VERIFICATION_SMS) {
      setIsShowNextButton(value !== "");
    } else if (prop === "suggestionClass") {
      setIsShowNextButton(value !== "");
    }
  };

  const [activeStep, setActiveStep] = useState(null);
  const steps = [
    t("CHOICE"),
    t("DESCRIPTION"),
    t("UPLOAD_PHOTO"),
    t("CONTACT_INFORMATION"),
    t("VERIFY"),
  ];

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const saveCustomer = () => {
    if (report.kvkkOnay && report.kullaniciSozlesme) {
      ReportService.saveCustomer(
        report.email,
        report.phone,
        report.gpsLocation,
        publicThing.id,
        publicThing.accountId,
        (data) => {
          setSaveOK(true);
        },
        (error) => {}
      );
    } else {
      showAlert(t("PLEASE_FILL_REQUIRED_FIELDS"), "error");
    }
  };

  const renderMembership = () => {
    return <>
      <div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xs={3}></Grid>
          <Grid item xs={1}>
            <Checkbox
              checked={report.membership}
              onChange={handleChange("membership")}
              name="membership"
              color="primary"
            />
          </Grid>
          <Grid item>{t("SUBSCRIBE")}</Grid>
        </Grid>
      </div>
      {report.membership && (
        <div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
              <Checkbox
                checked={report.acikRiza}
                onChange={handleChange("acikRiza")}
                name="acikRiza"
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Link
                  onClick={() => {
                    showLegalText("TICARI_ELEKTRONIK_ILETI_BILGILENDIRME");
                  }}
                  className={labelClasses.SimpleLink}
                  underline="hover">
                  {t(
                    "Elektronik Ticari İletilere dair bilgilendirme metninde"
                  )}
                </Link>
                {" belirtilen ilkeler nezdinde "}
                <Link
                  onClick={() => {
                    showLegalText("TICARI_ELEKTRONIK_ILETI_RIZA");
                  }}
                  className={labelClasses.SimpleLink}
                  underline="hover">
                  {t("Elektronik Ticari İleti")}
                </Link>
                {" almak istiyorum."}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {report.membership && (
        <div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
              <Checkbox
                checked={report.kvkk}
                onChange={handleChange("kvkk")}
                name="kvkk"
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Link
                  onClick={() => {
                    showLegalText("KVKK");
                  }}
                  className={labelClasses.SimpleLink}
                  underline="hover">
                  {t("KVKK aydınlatma metnini")}
                </Link>
                {" okudum."}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {report.membership && (
        <div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
              <Checkbox
                checked={report.kvkkOnay}
                onChange={handleChange("kvkkOnay")}
                name="kvkkOnay"
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Link
                  onClick={() => {
                    showLegalText("KVKK");
                  }}
                  className={labelClasses.SimpleLink}
                  underline="hover">
                  {t("KVKK aydınlatma metnini")}
                </Link>
                {" uyarınca kişisel verilerin işlenmesine onay veriyorum."}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {report.membership && (
        <div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={1}>
              <Checkbox
                checked={report.kullaniciSozlesme}
                onChange={handleChange("kullaniciSozlesme")}
                name="kullaniciSozlesme"
                color="primary"
              />
            </Grid>
            <Grid item>
              <Typography variant="body1">
                <Link
                  onClick={() => {
                    showLegalText("KULLANICI_SOZLESMESI");
                  }}
                  className={labelClasses.SimpleLink}
                  underline="hover">
                  {t("Kullanıcı sözleşmesini")}
                </Link>
                {" okudum kabul ediyorum."}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {report.membership && (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <FaButton
              faType="button"
              size="small"
              color="primary"
              label={t("Tamam")}
              onClick={saveCustomer}
            ></FaButton>
          </Grid>
        </Grid>
      )}
    </>;
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case STEP_SUGG_CLASS_SELECT:
        return (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" align="center"></TableCell>
                  <TableCell width="95%" align="center"></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>{t("Öneri seçiniz.")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {publicThing?.suggestionClassList.map(
                  (suggestionClass, index) => (
                    <TableRow
                      key={index}
                      onClick={() => {
                        handleChangeV("suggestionClass", {
                          id: suggestionClass.id,
                        });
                      }}
                      selected={
                        suggestionClass?.id === report.suggestionClass?.id
                      }
                    >
                      <TableCell align="center">
                        <Radio
                          color="primary"
                          checked={
                            suggestionClass?.id === report.suggestionClass?.id
                          }
                        />
                      </TableCell>
                      <TableCell>{suggestionClass.className}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        );
      case STEP_DESC:
        return (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            {dynamicFormList && dynamicFormList.length > 0 && (
              <Grid item xs={12}>
                <DynamicFieldsEdit
                  selectedDetails={dynamicFormList}
                  details={dynamicFormList}
                  data={dynamicValues}
                  onValuesChange={onDynamicValuesChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FaInput
                faType="description"
                helperText={t("SUGGESTION_HELPER_TEXT")}
                required
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={report.description || ""}
              />
            </Grid>
          </Grid>
        );
      case STEP_PHOTO:
        return (
          <>
            <FaFileUpload
              maxFileSize={20000000}
              dropzoneText={() => {
                return t("PHOTO_HELPER_TEXT");
              }}
              onChange={onFileChange}
              initialFiles={files}
              capture="environment"
              filesLimit={5}
              showPreviewInDropZone={false}
              dropZoneBoxProps={{ style: { minHeight: "50px" } }}
            />
          </>
        );
      case STEP_USER_INFO:
        return (
          <StepUserInfo
            report={report}
            verificationType={
              publicThing.publicReportSuggestionVerificationType
            }
            handleChange={handleChange}
            handleChangeV={handleChangeV}
            publicThingAccountId={publicThing.accountId}
          ></StepUserInfo>
        );
      case STEP_USER_VERIFY:
        return (
          <>
            {report.verificationType === VERIFICATION_EMAIL ? (
              <StepEmailVerification
                ref={emailVerRef}
                reportEmail={report.email}
                setIsShowNextButton={setIsShowNextButton}
                submit={submitReport}
              ></StepEmailVerification>
            ) : null}
            {report.verificationType === VERIFICATION_SMS ? (
              <StepPhoneVerification
                ref={smsVerRef}
                reportPhone={report.phone}
                setIsShowNextButton={setIsShowNextButton}
                submit={submitReport}
              ></StepPhoneVerification>
            ) : null}
            {report.verificationType === VERIFICATION_CAPTCHA ? (
              <StepCaptchaVerification
                handleBack={handleBack}
                handleNextCallback={handleNextCallback}
                submit={submitReport}
              ></StepCaptchaVerification>
            ) : null}
          </>
        );
      case STEP_SENDING:
        return (
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "20px" }}>
              {t("SUGGESTION_SENDING_HELPER_TEXT")}
            </Typography>
            <LinearProgress />
          </div>
        );
      case STEP_COMPLETED:
        return (
          <>
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ fontSize: "64px", color: green[500] }}
              />
              <Typography style={{ fontSize: "20px" }}>
                {t("SUGGESTION_SUCCESS")}
              </Typography>
              {!saveOK ? renderMembership() : renderSaveSuccess()}
            </div>
            <div className={classes.footer}>
              <div className={classes.actionButtons2Div}>
                <div style={{ flex: 1 }}>
                  <Button
                    className={classes.backButton}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {t("Back")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "20px" }}>Loading...</Typography>
          </div>
        );
    }
  };
  const renderSaveSuccess = () => {
    return (
      <Typography style={{ fontSize: "20px" }}>{t("RECORD_SAVED")}</Typography>
    );
  };
  const handleNext = (event) => {
    checkNextButtonStatus(activeStep + 1);
    if (activeStep === STEP_SUGG_CLASS_SELECT && report.suggestionClass?.id) {
      ReportService.findFormByName(
        "SUGGESTION_CLASS",
        publicThing.accountId,
        report.suggestionClass.id,
        setDynamicFormList,
        (error) => {
          setDynamicFormList(null);
        }
      );

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === STEP_USER_VERIFY) {
      if (report.verificationType === VERIFICATION_EMAIL) {
        emailVerRef.current.sendEmailToken(handleNextCallback);
      } else if (report.verificationType === VERIFICATION_SMS) {
        smsVerRef.current.sendSmsToken(handleNextCallback);
      } else if (report.verificationType === VERIFICATION_CAPTCHA) {
        handleNextCallback(true);
      }
    } else if (activeStep === STEP_SENDING) {
      //
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleNextCallback = (result) => {
    if (result === true) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep == STEP_SUGG_CLASS_SELECT) {
      history.goBack();
    }
    if (!classListExist && activeStep === STEP_DESC) {
      history.goBack();
    } else {
      checkNextButtonStatus(activeStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const checkNextButtonStatus = (stepNum) => {
    let showNext = false;
    if (stepNum === STEP_DESC) {
      if (report.description !== "") showNext = true;
    } else if (stepNum === STEP_PHOTO) {
      showNext = true;
    } else if (stepNum === STEP_USER_INFO) {
      if (
        report.verificationType === VERIFICATION_EMAIL &&
        report.email !== "" &&
        report.email
      )
        showNext = true;
      else if (
        report.verificationType === VERIFICATION_SMS &&
        report.phone !== "" &&
        report.phone
      )
        showNext = true;
      else if (report.verificationType === VERIFICATION_CAPTCHA)
        showNext = true;

      if (!isFieldsValid.email || !isFieldsValid.phone) showNext = false;
    } else if (stepNum === STEP_USER_VERIFY) {
    } else if (stepNum === STEP_SUGG_CLASS_SELECT) {
      if (report.suggestionClass !== null) showNext = true;
    }

    setIsShowNextButton(showNext);
  };

  const renderReported = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                width="250"
                height="92"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                style={{ fontSize: "64px", color: green[500] }}
              />
              <Typography style={{ fontSize: "20px" }}>
                {t("ALREADY_REPORTED")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <FaButton
              faType="button"
              size="large"
              label={t("Back")}
              onClick={() => {
                history.goBack();
              }}
              style={{ height: "60px" }}
            ></FaButton>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const showLegalText = (type) => {
    ReportService.getLegal(
      type,
      publicThing.accountId,
      showLegalTextOnSuccess,
      (error) => {
        setLegalText(null);
      }
    );
  };

  const showLegalTextOnSuccess = (data) => {
    setLegalText(data);
    setOpenLegalText(true);
  };

  const renderLegalTextDialog = () => {
    return (
      <Dialog
        open={openLegalText}
        onClose={() => {
          setOpenLegalText(false);
        }}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{legalText?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{ __html: legalText?.content }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenLegalText(false);
            }}
            color="primary"
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      {renderLegalTextDialog()}
      {blockReport && renderReported()}
      {!blockReport && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          component={Paper}
          elevation={6}
          style={{
            height: "100vh",
            width: "100vw",
            margin: "10px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <div>
            <Grid
              item
              container
              spacing={3}
              lg={8}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                lg={10}
                xs={12}
                style={{ textAlign: "center", marginBottom: 92 }}
              >
                <LandingHeader
                  publicThing={publicThing}
                  miniAppLogo={miniAppLogo}
                />
              </Grid>
            </Grid>
            {activeStep < steps.length && (
              <div>
                {classListExist ? (
                  <Typography variant="h5">
                    {activeStep + 1}/{steps.length} {steps[activeStep]}
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    {activeStep}/{steps.length - 1} {steps[activeStep]}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {getStepContent(activeStep)}
            </Grid>

            {activeStep < steps.length &&
              !(
                activeStep === STEP_USER_VERIFY &&
                publicThing.publicReportSuggestionVerificationType ===
                  VERIFICATION_CAPTCHA
              ) && (
                <>
                  <div className={classes.footer}>
                    <div className={classes.actionButtons2Div}>
                      <div style={{ flex: 1 }}>
                        <Button
                          className={classes.backButton}
                          onClick={handleBack}
                        >
                          {t("Geri")}
                        </Button>
                      </div>

                      <div style={{ flex: 1 }}>
                        <Button
                          className={classes.nextButton}
                          onClick={handleNext}
                          disabled={!isShowNextButton}
                        >
                          {activeStep === steps.length - 1
                            ? t("Gönder")
                            : t("İleri")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

ReportSuggestion.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
