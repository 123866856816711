import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ServiceOperationHashtagButtonGroup from "components/buttonGroup/ServiceOperationHashtagButtonGroup";
import SystemAccountButtonGroup from "components/buttonGroup/SystemAccountButtonGroup";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { SERVICE_OPERATION_CLASS } from "../../services/faApi";
import ServiceOperationEdit from "./ServiceOperationEdit";

export default function ServiceOperationClass() {
  const tableRef = useRef();
  const dialogRef = useRef();
  const { t } = useTranslation("serviceOperationClass", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();

  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();
  const accountName = localStorage.accountName;
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "className",
      label: t("GENERIC_CLASSNAME"),
      align: "center",
      width: "25%",
      valueFormatter: (value, row) => {
        if (row.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={`data:image/png;base64, ${row.thumbnail}`}
                style={{ width: "48px", marginRight: "10px" }}
              />{" "}
              {value}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{ width: "48px", marginRight: "10px" }}
              >
                {" "}
                {value.charAt(0)}
              </Avatar>{" "}
              {value}
            </div>
          );
      },
    },
    { field: "code", label: t("CODE"), align: "center", width: "10%" },
    { field: "domain", label: t("DOMAIN"), align: "center", width: "15%" },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
    },
    {
      field: "id",
      label: t("ACCOUNT"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return row.account ? row.account.fullname : t("SYSTEM");
      },
    },
  ];

  const newSearchForm = () => {
    let result = {
      genericClassName: null,
      className: null,
      domain: null,
      code: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      genericClassName: null,
      className: null,
      code: "",
      domain: null,
      accountId: accountId,
    });
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `serviceOperationClassDetail/${row.id}`,
      breadCrumbKey: `/serviceOperationClassDetail/:id`,
    };
    history.push(tmpTo);
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const newRegisterRender = () => (
    <ServiceOperationEdit
      onSave={refresh}
      ref={dialogRef}
    ></ServiceOperationEdit>
  );

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newRegisterRender()}
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={4} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("ACCOUNT")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SystemAccountButtonGroup
                label={accountName}
                value={searchForm.systemType}
                onChange={(data) => {
                  const temp = { ...searchForm, systemType: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
              />
            </Grid>
          </Grid>
          <Grid item lg={5} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("Hashtag")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ServiceOperationHashtagButtonGroup
                  value={searchForm.domain}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      domain: data,
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  // style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={
            SERVICE_OPERATION_CLASS.search +
            "?accountId=" +
            localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            sort: "className",
            isAsc: true,
          }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                dialogRef.current.openDialog();
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("GENERIC_CLASSNAME")}
                value={searchForm.className}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "className",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("CODE")}
                value={searchForm.code}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "code",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DOMAIN")}
                value={searchForm.domain}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "domain",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
