import { Button, Card, CardHeader, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import StockAutoComplete from "components/autocomplete/StockAutoComplete";
import ThingsDataTable from "components/thingsDataTable";
import { default as React, Suspense, useRef, useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SERVICE_OPERATION_CLASS } from "services/faApi";
import { ServiceOperationClassService } from "services/ServiceOperationClassService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function ServiceOperationStock(props) {
  const { serviceOperation } = props;

  const accountId = localStorage.accountId;

  const { t } = useTranslation("serviceOperationClass", { i18n });
  const { id } = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const faDialog = useDialog();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const dialogRef = useRef();
  const tableRef = useRef();
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const newServiceOperationStock = () => {
    return {
      serviceOperationClass: { id: serviceOperation.id },
      stock: null,
      amount: null,
    };
  };

  const [serviceOperationStock, setServiceOperationStock] = useState(
    newServiceOperationStock()
  );

  const newSearchForm = () => {
    return {
      serviceOperationClassId: serviceOperation.id,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "stock",
      label: t("NAME"),
      align: "center",
      width: "50%",
      inner: "name",
      valueFormatter: (value) => {
        return value.name;
      },
    },
    {
      field: "amount",
      label: t("AMOUNT"),
      align: "center",
      width: "50%",
      valueFormatter: (val, row) => {
        if (val) return val + " " + row.stock.unit.name;
        else return;
      },
    },
  ];

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  const save = () => {
    ServiceOperationClassService.saveStock(
      serviceOperationStock,
      (data) => {
        setIsEditFormOpen(false);
        refresh();
        setServiceOperationStock(newServiceOperationStock());
      },
      (error) => console.log(error)
    );
  };

  const onDoubleClick = (row) => {
    setServiceOperationStock(row);
    setIsEditFormOpen(true);
  };

  const onDelete = (data) => {
    faDialog({
      description: t("Malzemeyi silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        ServiceOperationClassService.deleteStock(
          serviceOperationStock,
          (data) => {
            showAlert(t("Malzeme silindi"), "success");
            setIsEditFormOpen(false);
            refresh();
            setServiceOperationStock(newServiceOperationStock());
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderDialog = () => {
    return (
      <FaDialog
        title={"Yedek parça / Sarf malzemeleri Tanımla"}
        faOpen={isEditFormOpen}
        faOnSubmit={save}
        loading={loading}
        showSaveButton
        faHandleClose={() => {
          setIsEditFormOpen(false);
          setServiceOperationStock(newServiceOperationStock());
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <StockAutoComplete
              required
              accountId={accountId}
              value={serviceOperationStock?.stock}
              onChange={(data) =>
                setServiceOperationStock({
                  ...serviceOperationStock,
                  stock: data,
                })
              }
            ></StockAutoComplete>
          </Grid>
          <Grid item xs={12}>
            <FaInput
              faType="number"
              label={t("AMOUNT")}
              value={serviceOperationStock?.amount}
              onChange={(event) =>
                setServiceOperationStock({
                  ...serviceOperationStock,
                  amount: event.target.value,
                })
              }
            />
          </Grid>
          {serviceOperationStock.id && (
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button onClick={onDelete} color="secondary">
                {t("Sil")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  return <>
    <Suspense fallback={<LoadingBar />}>
      {renderDialog()}
      <Card>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              onClick={() => {
                setIsEditFormOpen(true);
              }}
              size="large">
              <EditIcon />
            </IconButton>
          }
          title={t("STOCK")}
        ></CardHeader>
        <ThingsDataTable
          searchUrl={
            SERVICE_OPERATION_CLASS.searchStock + "?accountId=" + accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{
            page: 0,
            size: 10,
            sort: "id",
            isAsc: false,
          }}
        />
      </Card>
    </Suspense>
  </>;
}
