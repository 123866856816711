import {
  faChevronDown,
  faChevronUp,
  faEdit,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Add } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import ThingsDataTable from "components/thingsDataTable";
import ControlItemEdit from "features/controlItem/ControlItemEdit";
import ThingClassControlItemHelp from "features/help/ThingClassControlItemHelp";
import FeatureNotAllowed from "features/subscriptions/FeatureNotAllowed";
import React, { useEffect, useRef, useState } from "react";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ROLES } from "services/utils/Roles";
import { checkRole } from "services/utils/SecurityUtils";
import i18n from "../../i18n";
import { ControlItemService } from "../../services/controlItem/controlItemService";
import { useStyles } from "./style";

export default function ThingClassControlItemEdit(props) {
  const { thingClass } = props;

  const classes = useStyles();

  const { t } = useTranslation("thingClass", { i18n });
  const tableRef = useRef();
  const faDialog = useDialog();

  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);
  const [openEnableDialog, setOpenEnableDialog] = useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);
  const [sectionIssueClass, setSectionIssueClass] = useState(false);
  const [sectionServiceOperationClass, setSectionServiceOperationClass] =
    useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedControlItem, setSelectedControlItem] = useState(null);
  const [controlItemList, setControlItemList] = useState([]);

  const [subscriptionError, setSubscriptionError] = useState(false);
  const role = useSelector(getRole);
  const isRoleExists = checkRole([ROLES.SERVICE_OPERATION], role);

  useEffect(() => {
    if (thingClass && accountId && thingClass.id && thingClass.id !== 0) {
      ControlItemService.findByThingClass(
        thingClass.id,
        accountId,
        findByThingClassOnSuccess,
        findByThingClassOnError
      );
    }
  }, [thingClass, accountId]);

  const findByThingClassOnSuccess = (data) => {
    setControlItemList(data.list);
  };

  const findByThingClassOnError = (data) => {};

  const refresh = () => {
    ControlItemService.findByThingClass(
      thingClass.id,
      accountId,
      findByThingClassOnSuccess,
      findByThingClassOnError
    );
    setOpenEnableDialog(false);
    setOpenDisableDialog(false);
  };

  const deleteOnError = (data) => {};

  const disableControlItem = () => {
    ControlItemService.disable(
      selectedControlItem.id,
      thingClass.id,
      refresh,
      deleteOnError
    );
  };

  const enableControlItem = () => {
    ControlItemService.enable(
      selectedControlItem.controlItemOverride.id,
      refresh,
      deleteOnError
    );
  };

  const onEdit = (row) => {
    if (row.thingClass.id !== thingClass.id) return;
    var tmp = { ...row };
    tmp.controlItemTargets = tmp.controlItemTargets
      ? tmp.controlItemTargets.map((data) => {
          if (data.issueClass) {
            data.label = data.issueClass.className;
            data.key = "ic_" + data.issueClass.id;
          } else if (data.serviceOperationClass) {
            data.label = data.serviceOperationClass.className;
            data.key = "soc_" + data.serviceOperationClass.id;
          }

          return data;
        })
      : [];

    setSelectedControlItem(tmp);
    setIsDialogOpen(true);
  };

  const moveUp = (row) => {
    ControlItemService.moveUp(
      accountId,
      thingClass.id,
      row.id,
      refresh,
      deleteOnError
    );
  };

  const moveDown = (row) => {
    ControlItemService.moveDown(
      accountId,
      thingClass.id,
      row.id,
      refresh,
      deleteOnError
    );
  };

  const renderMoveButton = (row, up) => {
    return (
      <IconButton
        onClick={() => (up === true ? moveUp(row) : moveDown(row))}
        size="small"
        color="primary"
        aria-label={up === true ? t("MOVE_UP") : t("MOVE_DOWN")}
      >
        <FontAwesomeIcon icon={up === true ? faChevronUp : faChevronDown} />
      </IconButton>
    );
  };

  const columns = [
    {
      field: "orderNo",
      label: "#",
      align: "center",
      width: "5%",
    },
    {
      field: "question",
      label: t("QUESTION"),
      align: "center",
      width: "45%",
      valueFormatter: (value, row) => {
        if (row.thingClass.id === thingClass.id) return value;
        else
          return (
            <>
              {value} <i>({row.thingClass.className})</i>
            </>
          );
      },
    },
    {
      field: "itemType",
      label: t("ITEM_TYPE"),
      align: "center",
      width: "15%",
      valueFormatter: (value) => {
        return t("" + value);
      },
    },
    {
      field: "documentAttachmentType",
      label: t("DOCUMENT_ATTACHMENT_TYPE"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return t("" + value);
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "15%",
      valueFormatter: (value, row) => {
        if (row.thingClass.id === thingClass.id)
          return (
            <>
              {renderMoveButton(row, true)}
              {renderMoveButton(row, false)}

              <IconButton
                onClick={() => {
                  onEdit(row);
                }}
                size="small"
                color="primary"
                title={t("Düzenle")}
              >
                <FontAwesomeIcon icon={faEdit} />
              </IconButton>
            </>
          );
        else if (
          row.controlItemOverride == null ||
          row.controlItemOverride.disable == null ||
          row.controlItemOverride.disable === false
        ) {
          return (
            <>
              {renderMoveButton(row, true)}
              {renderMoveButton(row, false)}
              <IconButton
                onClick={() => {
                  setSelectedControlItem(row);
                  setOpenDisableDialog(true);
                }}
                size="small"
                color="primary"
                aria-label={t("GECERLI")}
                title={t("GECERLI")}
              >
                <FontAwesomeIcon icon={faVolumeUp} />
              </IconButton>
            </>
          );
        } else if (
          row.controlItemOverride != null &&
          row.controlItemOverride.disable === true
        ) {
          return (
            <IconButton
              onClick={() => {
                setSelectedControlItem(row);
                setOpenEnableDialog(true);
              }}
              size="small"
              color="primary"
              aria-label={t("GECERSIZ")}
              title={t("GECERSIZ")}
            >
              <FontAwesomeIcon icon={faVolumeMute} />
            </IconButton>
          );
        }
        return null;
      },
    },
  ];

  const controlItemAddButtonClicked = () => {
    if (!isRoleExists) {
      setSubscriptionError(true);
    } else {
      setSelectedControlItem(null);
      setIsDialogOpen(true);
    }
  };

  const renderDisableDialog = () => {
    return (
      <Dialog
        open={openDisableDialog}
        onClose={() => {
          setOpenDisableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçersiz Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz kontrol sorusu, mevcut etkileşim noktası tipi ve
            alt etkileşim noktası tiplerinde gösterilmeyecektir. Devam etmek
            istiyor musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDisableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              disableControlItem();
            }}
            color="primary"
            autoFocus
          >
            Geçersiz Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderEnableDialog = () => {
    return (
      <Dialog
        open={openEnableDialog}
        onClose={() => {
          setOpenEnableDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Geçerli Yap?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Seçmiş olduğunuz kontrol sorusu, mevcut etkileşim noktası tipi ve
            alt etkileşim noktası tiplerinde gösterilmeye başlanacaktır. Devam
            etmek istiyor musunuz?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEnableDialog(false);
            }}
            color="primary"
          >
            İptal
          </Button>
          <Button
            onClick={() => {
              enableControlItem();
            }}
            color="primary"
            autoFocus
          >
            Geçerli Yap
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const controlItemOnChange = () => {
    ControlItemService.findByThingClass(
      thingClass.id,
      accountId,
      findByThingClassOnSuccess,
      findByThingClassOnError
    );
    setIsDialogOpen(false);
  };

  return (
    <>
      <ControlItemEdit
        onChange={controlItemOnChange}
        open={isDialogOpen}
        thingClass={thingClass}
        value={selectedControlItem}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      {renderDisableDialog()}
      {renderEnableDialog()}
      {subscriptionError && (
        <FeatureNotAllowed
          open={subscriptionError}
          onClose={() => {
            setSubscriptionError(false);
          }}
        />
      )}
      <Card>
        <CardHeader
          action={
            <>
              <ThingClassControlItemHelp value="thingClass.controlItem" />
              <IconButton
                aria-label="settings"
                onClick={controlItemAddButtonClicked}
                size="large"
              >
                <Add />
              </IconButton>
            </>
          }
          title={t("CONTROL_QUESTIONS")}
          data-fa-section="CONTROL_QUESTIONS"
        />
      </Card>
      <ThingsDataTable
        data={controlItemList}
        columns={columns}
        ref={tableRef}
        showDeleteButton={false}
        showHeaderText={false}
        headerText={t("CONTROL_QUESTIONS")}
        showPaging={false}
        showHeader={false}
      />
    </>
  );
}
