import {
  AddCircle,
  CloudDownload,
  Notifications,
  RemoveCircle,
} from "@mui/icons-material";
import LinkIcon from "@mui/icons-material/Link";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Grid, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import { getDateWFormat } from "services/utils";
import i18n from "../../../i18n";
import { useStyles } from "./LandingTemplate10Style";
import PublicDocumentDownload from "./PublicDocumentDownload";
import PublicPhotoBrowser from "./PublicPhotoBrowser";
import LandingHeader from "./header/LandingHeader";
export default function LandingTemplate10(props) {
  const { publicThing, onLinkClick, appLogo, miniAppLogo } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [documentParam, setDocumentParam] = useState(null);
  const [expandedItems, setExpandedItems] = useState("");

  const isMain = () => {
    if (!publicThing) return false;

    if (
      publicThing.interactionPageSettings.ipCampaign &&
      publicThing.campaignList &&
      publicThing.campaignList.length > 0
    )
      return true;

    if (
      publicThing.interactionPageSettings.ipSurvey &&
      publicThing.surveyList &&
      publicThing.surveyList.length > 0
    )
      return true;

    if (
      publicThing.interactionPageSettings.ipAnnouncement &&
      publicThing.announcementList &&
      publicThing.announcementList.length > 0
    )
      return true;

    if (publicThing.interactionPageSettings.ipUserContent) return true;

    return false;
  };

  const isDetails = () => {
    if (!publicThing) return false;

    if (
      publicThing.interactionPageSettings.ipDocument &&
      publicThing.documentList &&
      publicThing.documentList.length > 0
    )
      return true;

    if (
      publicThing.interactionPageSettings.ipLink &&
      publicThing.thingLinkList &&
      publicThing.thingLinkList.length > 0
    )
      return true;

    if (publicThing.gpsLocation && publicThing.gpsLocation.length > 5)
      return true;

    return false;
  };

  const getDefaultTabValue = () => {
    if (isMain()) return "highlights";
    else if (isDetails()) return "details";
  };

  const [tabValue, setTabValue] = useState(getDefaultTabValue());

  const navigateReportIssue = () => {
    let tmpTo = {
      pathname: `/report/issue/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportLike = () => {
    let tmpTo = {
      pathname: `/report/like/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportSuggestion = () => {
    let tmpTo = {
      pathname: `/report/suggestion/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateReportUserContent = () => {
    let tmpTo = {
      pathname: `/report/userContent/${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCustomer = () => {
    let tmpTo = {
      pathname: `/report/customer/tid=${publicThing.tid}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateSurvey = (survey) => {
    let tmpTo = {
      pathname: `/report/survey/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const navigateCampaign = (survey) => {
    let tmpTo = {
      pathname: `/report/registerCampaign/${survey.id}/${publicThing.id}/${publicThing.accountId}`,
      state: {
        publicThing: {
          ...publicThing,
        },
      },
    };
    history.push(tmpTo);
  };

  const openExpand = (item) => {
    if (!expandedItems.indexOf(item) > -1) {
      setExpandedItems((prev) => prev + "," + item);
    }
  };

  const closeExpand = (item) => {
    if (expandedItems.indexOf(item) > -1) {
      setExpandedItems((prev) => prev.replace(item, ""));
    }
  };

  const renderHighlightItem = (type, id, title, detailComp, onClick) => {
    const compId = type + "_" + id;

    return (
      <div className={classes.highlightItem}>
        <div style={{ display: "flex" }}>
          <div className={classes.highlightItemTitle}>{title}</div>
          <div className={classes.highlightItemExpand}>
            {expandedItems.indexOf(compId) === -1 && (
              <IconButton
                className={classes.expandDetailButton}
                onClick={() => {
                  openExpand(compId);
                }}
                title="Detay"
                size="large"
              >
                <AddCircle />
              </IconButton>
            )}
            {expandedItems.indexOf(compId) !== -1 && (
              <IconButton
                className={classes.closeDetailButton}
                onClick={() => {
                  closeExpand(compId);
                }}
                title="Kapat"
                size="large"
              >
                <RemoveCircle />
              </IconButton>
            )}
          </div>
        </div>
        {expandedItems.indexOf(compId) !== -1 && (
          <div className={classes.highlightItemDetail}>
            {detailComp}
            {onClick && (
              <Button
                variant="contained"
                className={classes.highlightItemButton}
                onClick={onClick}
              >
                {t("Subscribe")}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className={classes.mainPanel}>
        {publicThing &&
          publicThing.interactionPageSettings.ipCampaign &&
          publicThing.campaignList &&
          publicThing.campaignList.length > 0 && (
            <>
              {publicThing.campaignList.map((item, index) => {
                return renderHighlightItem(
                  "campaign",
                  item.id,
                  t("CAMPAIGN")` - ${item.multiLangName?.["tr"]}`,
                  <div>{t("APPLICATION_DATES")` ${getDateWFormat(
                    item.registerStartDate
                  )} - ${getDateWFormat(item.registerEndDate)}`}</div>,
                  () => {
                    navigateCampaign(item);
                  }
                );
              })}
            </>
          )}

        {publicThing &&
          publicThing.interactionPageSettings.ipSurvey &&
          publicThing.surveyList &&
          publicThing.surveyList.length > 0 &&
          publicThing.surveyList.map((item, index) => {
            return renderHighlightItem(
              "survey",
              item.id,
              t("SURVEY") + ` - ${surveyName(item)}`,
              <div>
                {t("PARTICIPATION_DATES")}{" "}
                {`Katılım tarihleri ${getDateWFormat(
                  item.registerStartDate
                )} - ${getDateWFormat(item.registerEndDate)}`}
              </div>,
              () => {
                navigateSurvey(item);
              }
            );
          })}

        {publicThing &&
          publicThing.interactionPageSettings.ipAnnouncement &&
          publicThing.announcementList &&
          publicThing.announcementList.length > 0 &&
          publicThing.announcementList.map((item, index) => {
            return renderHighlightItem(
              "announcement",
              item.id,
              t("ANNOUNCEMENT")` - ${item.subject}`,
              <>
                <div>
                  <div>
                    {t("DATE")} {`: ${getDateWFormat(item.startDate)}`}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </>,
              null
            );
          })}

        {publicThing.interactionPageSettings.ipUserContent &&
          renderHighlightItem(
            "userContent",
            1,
            t("ADD_CONTENT"),
            <div></div>,
            () => {
              navigateReportUserContent();
            }
          )}
      </div>
    );
  };

  const renderDetail = () => {
    return (
      <div className={classes.mainPanel}>
        {publicThing &&
          publicThing.interactionPageSettings.ipLocation &&
          publicThing.gpsLocation &&
          publicThing.gpsLocation.length > 5 && (
            <div className={classes.highlightItem}>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <Button
                    className={classes.downloadButton}
                    onClick={() => {
                      window
                        .open(
                          ReportService.getLocationLink(publicThing),
                          "_blank"
                        )
                        .focus();
                    }}
                  >
                    <LocationOnIcon className={classes.actionButtonIcon} />
                  </Button>
                </div>
                <div style={{ flex: 10, paddingLeft: 10 }}>
                  <div className={classes.detailItemType}>{t("LOCATION")}</div>
                  <div className={classes.detailItemTitle}></div>
                </div>
              </div>
            </div>
          )}
        {publicThing &&
          publicThing.interactionPageSettings.ipDocument &&
          publicThing.documentList &&
          publicThing.documentList.length > 0 &&
          publicThing.documentList.map((item, index) => {
            return (
              <div className={classes.highlightItem}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Button
                      className={classes.downloadButton}
                      onClick={() => {
                        setDocumentParam(item);
                      }}
                    >
                      <CloudDownload className={classes.actionButtonIcon} />
                    </Button>
                  </div>
                  <div style={{ flex: 10, paddingLeft: 10 }}>
                    <div className={classes.detailItemType}>
                      {t("Document")}
                    </div>
                    <div className={classes.detailItemTitle}>
                      {item.name ?? item.fileName}
                    </div>{" "}
                  </div>
                </div>
              </div>
            );
          })}
        {publicThing &&
          publicThing.interactionPageSettings.ipDocument &&
          publicThing.documentList &&
          publicThing.documentList.length > 0 && (
            <div>
              <PublicDocumentDownload
                value={documentParam}
                publicThing={publicThing}
                onClose={() => {
                  setDocumentParam(null);
                }}
              />
            </div>
          )}

        {publicThing &&
          publicThing.interactionPageSettings.ipLink &&
          publicThing.thingLinkList &&
          publicThing.thingLinkList.length > 0 &&
          publicThing.thingLinkList.map((item, index) => {
            return (
              <div className={classes.highlightItem}>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1 }}>
                    <Button
                      className={classes.downloadButton}
                      onClick={() => {
                        window.open(item.url, "_blank").focus();
                        onLinkClick(item);
                      }}
                    >
                      <LinkIcon className={classes.actionButtonIcon} />
                    </Button>
                  </div>
                  <div style={{ flex: 10, paddingLeft: 10 }}>
                    <div className={classes.detailItemType}>{t("Link")}</div>
                    <div className={classes.detailItemTitle}>
                      {item.description ?? item.url}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const surveyName = (item) => {
    let langs = item?.languages;
    let name = "";
    langs.map((lang, index) => {
      if (index > 0) {
        name = name.concat(" / ");
      }
      name = name.concat(item?.multiLangName?.[lang.toLowerCase()]);
    });
    if (name && name.length > 0) return name;
    else return item.name;
  };

  const renderHeader = () => {
    return (
      <div className={classes.banner}>
        <div className={classes.bannerLeft}>
          {publicThing.logo && (
            <img
              src={"data:image/jpeg;base64," + publicThing.logo}
              alt="logo"
              className={classes.appLogo}
            />
          )}

          {!publicThing.logo && (
            <img src={miniAppLogo} alt="logo" className={classes.appLogo} />
          )}
        </div>
        <div className={classes.bannerTitle}>{publicThing.accountName}</div>
        <div className={classes.bannerRight}>
          <img
            src={process.env.PUBLIC_URL + "/assets/things_no_logo.png"}
            alt="logo"
            className={classes.headerLogo}
          />
        </div>
      </div>
    );
  };

  const renderPhoto = () => {
    return (
      publicThing.interactionPageSettings.ipImage && (
        <div className={classes.photosDivNoMargin}>
          <PublicPhotoBrowser publicThing={publicThing} />
        </div>
      )
    );
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className={classes.root}>
      <LandingHeader publicThing={publicThing} miniAppLogo={miniAppLogo} />

      <div className={classes.pageContent}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {renderPhoto()}
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className={classes.thingTitle} style={{ flex: 10 }}>
                {publicThing.thingDesc}
              </div>
              {publicThing.interactionPageSettings.ipCustomer && (
                <div style={{ flex: 1 }}>
                  <IconButton
                    className={classes.customerButton}
                    onClick={() => {
                      navigateCustomer();
                    }}
                    style={{ paddingTop: 0 }}
                    title={t("SUBSCRIBE")}
                    size="large"
                  >
                    <Notifications className={classes.actionButtonIcon} />
                  </IconButton>
                </div>
              )}
            </div>

            {publicThing.interactionPageSettings.ipThingPublicDescription &&
              publicThing.publicDescription && (
                <div className={classes.thingDescription}>
                  {publicThing.publicDescription}
                </div>
              )}
          </Grid>
        </Grid>

        <div className={classes.tabs}>
          {isMain() && (
            <Button
              variant="contained"
              className={
                tabValue === "highlights"
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              style={{ width: isDetails() ? "50%" : "100%" }}
              onClick={() => {
                handleTabChange("highlights");
              }}
            >
              {t("HIGHLIGHTS")}
            </Button>
          )}
          {isDetails() && (
            <Button
              variant="contained"
              className={
                tabValue === "details"
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              style={{ width: isMain() ? "50%" : "100%" }}
              onClick={() => {
                handleTabChange("details");
              }}
            >
              {t("DETAILS")}
            </Button>
          )}
        </div>
      </div>

      <div style={{ zIndex: 1, marginTop: 10 }}>
        {isMain() && tabValue === "highlights" && renderMain()}

        {isDetails() && tabValue === "details" && renderDetail()}
      </div>

      <div style={{ height: 100 }}></div>

      <div className={classes.footer}>
        <div className={classes.actionButtons2Div}>
          {publicThing.interactionPageSettings.ipIssue && (
            <div style={{ flex: 1 }}>
              <Button
                className={classes.issueButton}
                onClick={navigateReportIssue}
              >
                {t("ISSUE")}
              </Button>
            </div>
          )}
          {publicThing.interactionPageSettings.ipSuggestion && (
            <div style={{ flex: 1, paddingLeft: 20 }}>
              <Button
                className={classes.suggestionButton}
                onClick={navigateReportSuggestion}
              >
                {t("SUGGESTION")}
              </Button>
            </div>
          )}
          {publicThing.interactionPageSettings.ipLike && (
            <div style={{ flex: 1, paddingLeft: 20 }}>
              <Button
                className={classes.likeButton}
                onClick={navigateReportLike}
              >
                {t("LIKE")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
}

LandingTemplate10.propTypes = {
  loginPath: PropTypes.string,
  onLinkClick: PropTypes.any,
  appLogo: PropTypes.any,
  miniAppLogo: PropTypes.any,
};
