import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReportService } from "services/report/reportService";
import i18n from "../../../i18n";
import PublicPhotosCarousel from "./PublicPhotosCarousel";
import { useStyles } from "./style";

export default function PublicPhotos(props) {
  const { publicThing } = props;
  const classes = useStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const location = useLocation();
  const [images, setImages] = useState([]);

  const [selectedHdImageIndex, setSelectedHdImageIndex] = useState(0);
  const [isCarouselOpen, setCarouselOpen] = useState(false);

  const openCarousel = (index) => {
    setSelectedHdImageIndex(index);
    setCarouselOpen(true);
  };

  useEffect(() => {
    if (publicThing && publicThing.id) {
      refreshThingImages();
    }
  }, [publicThing]);

  const refreshThingImages = () => {
    ReportService.downloadThumbnails(
      publicThing.id,
      (data) => {
        setImages(data);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const closeCarousel = () => {
    setCarouselOpen(false);
  };

  const createCarousel = () => {
    return (
      <Dialog
        open={isCarouselOpen}
        aria-labelledby="form-dialog-title"
        onClose={closeCarousel}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeCarousel}
            size="large">
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <PublicPhotosCarousel
            thingId={publicThing.id}
            setImages={setImages}
            images={images}
            selectedItem={selectedHdImageIndex}
          />
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <Grid
      item
      container
      spacing={1}
      alignItems="center"
      justifyContent="center"
    >
      <div
        style={{
          display: "flex",
          paddingTop: 10,
        }}
      >
        {createCarousel()}
        {images?.map((item, index) => (
          <div key={index} className={classes.thingImageContainer}>
            <img
              src={`data:image/png;base64, ${item.content}`}
              className={classes.thingImage}
              alt={item.object}
              onClick={() => {
                openCarousel(index);
              }}
            />
          </div>
        ))}
      </div>
    </Grid>
  );
}

PublicPhotos.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
