import { Button, Grid } from "@mui/material";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { PeriodicReportService } from "services/PeriodicReportService";
import UserAutoComplete from "../../components/autocomplete/UserAutoComplete";
import i18n from "../../i18n";

const PeriodicReportEdit = forwardRef((props, ref) => {
  const { onSave, periodicReport } = props;
  const { t } = useTranslation("periodicReport", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const newPeriodicReport = () => {
    return {
      user: { id: null },
      account: { id: accountId },
      name: null,
      description: null,
      status: "PASSIVE", // ????

      frequency: "hour",
      hourlyInterval: 8,

      dailyMod: "INTERVAL", //WEEKDAY
      dailyInterval: 1,

      weeklyInterval: 1,
      weeklyDays: [false, false, false, false, false, false, false],

      monthlyDay: 1,
      monthlyInterval: 1,

      yearlyInterval: 1,
      yearlyDay: 1,
      yearlyMonth: 0,

      startTime: new Date(2021, 1, 1, 12, 0, 0),
      startDate: new Date(),
      endDate: null,
    };
  };
  const [periodicReportDTO, setPeriodicReportDTO] = useState(
    newPeriodicReport()
  );

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (periodicReport) {
      setPeriodicReportDTO(
        periodicReport == null ? newPeriodicReport() : { ...periodicReport }
      );
    }
  }, [periodicReport]);

  const savePeriodicReport = () => {
    setLoading(true);

    if (
      periodicReportDTO.schedulePlan &&
      periodicReportDTO.schedulePlan.id === 0
    ) {
      PeriodicReportService.save(
        { ...periodicReportDTO, schedulePlan: null },
        saveOnSuccess,
        saveOnError
      );
    } else
      PeriodicReportService.save(periodicReportDTO, saveOnSuccess, saveOnError);
  };

  const saveOnSuccess = (data) => {
    showAlert(!periodicReportDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setLoading(false);
    setPeriodicReportDTO(periodicReport ? periodicReport : newPeriodicReport());
    onSave(data);
  };

  const saveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setPeriodicReportDTO({ ...periodicReportDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setPeriodicReportDTO({ ...periodicReportDTO, [prop]: val });
  };
  const onDelete = (data) => {
    faDialog({
      description: t("Kuralı silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        PeriodicReportService.delete(
          periodicReportDTO,
          (data) => {
            showAlert(t("Kural Silindi"), "success");
            const tmpTo = {
              pathname: `/periodicReport`,
              breadCrumbKey: `/periodicReport`,
              search: "?page=0&size=10&isAsc=false&sort=name",
            };
            history.push(tmpTo);
          },
          (error) => {}
        );
      })
      .catch((err) => console.log("error:", err));
  };
  return (
    <FaDialog
      title={t("EDIT_PERIODIC_REPORT")}
      faOpen={isDialogOpen}
      faOnSubmit={savePeriodicReport}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setPeriodicReportDTO(
          periodicReport ? periodicReport : newPeriodicReport()
        );
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={handleChange("name")}
            value={periodicReportDTO.name || ""}
          />
        </Grid>

        <Grid item xs={12}>
          <FaInput
            label={t("DESCRIPTION")}
            faType="description"
            inputProps={{ maxLength: 255 }}
            onChange={handleChange("description")}
            value={periodicReportDTO.description || ""}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <UserAutoComplete
          value={periodicReportDTO.user}
          accountId={accountId}
          onChange={(data) => {
            handleChangeV("user", data);
          }}
          label={t("USER")}
          required={true}
        />
      </Grid>
      {periodicReportDTO.id && (
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button onClick={onDelete} color="secondary">
            {t("Sil")}
          </Button>
        </Grid>
      )}
    </FaDialog>
  );
});

export default PeriodicReportEdit;
