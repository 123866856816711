import { Grid } from "@mui/material";
import { Settings } from "@mui/icons-material";
import React, { Suspense, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { SyncService } from "services/SyncService";
import LoadingBar from "../../components/loadingBar";

export default function LdapSync() {
  const [loading, setLoading] = useState(false);

  const startUserSync = () => {
    setLoading(true);
    SyncService.ldapUserSync(
      (data) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const startOrgSync = () => {
    setLoading(true);
    SyncService.ldapOrganizationSync(
      (data) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const startLocationDetailThumbnails = () => {
    setLoading(true);
    SyncService.startLocationDetailThumbnails(
      (data) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const startThingThumbnails = () => {
    setLoading(true);
    SyncService.startThingThumbnails(
      (data) => {
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                startUserSync();
              }}
              startIcon={<Settings />}
            >
              {"Start User Sync"}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                startOrgSync();
              }}
              startIcon={<Settings />}
            >
              {"Start Org Sync"}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                startLocationDetailThumbnails();
              }}
              startIcon={<Settings />}
            >
              {"LocationDetail Thumbnails"}
            </FaButton>
          </Grid>
          <Grid item xs={2}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                startThingThumbnails();
              }}
              startIcon={<Settings />}
            >
              {"Thing Thumbnails"}
            </FaButton>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Suspense>
    </>
  );
}
