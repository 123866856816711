import { FormControl, FormHelperText, FormLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import React from "react";
import { FaInput } from "react-base-fa/dist/fa";
import { GetFieldByLanguage } from "services/utils";
export default function SurveyQuestionText(props) {
  const { question, answer, onChange, readOnly, currentLang } = props;
  return (
    <Grid item xs={12}>
      <FormControl
        variant="standard"
        component="fieldset"
        style={{ width: "100%" }}
        required={question.notEmpty}>
        <Grid container item row xs={12}>
          <Grid item xs={12}>
            <FormLabel component="legend">
              {question.orderNo}.{" "}
              {GetFieldByLanguage(question.multiLangName, currentLang)}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormHelperText>
              {GetFieldByLanguage(question.multiLangHint, currentLang)}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FaInput
            required={question.notEmpty}
            multiline={question.questionType === "COMMENT"}
            minRows={question.questionType === "COMMENT" && 4}
            inputProps={{
              minLength: question?.min,
              maxLength: question?.max,
            }}
            value={answer?.dataString}
            onChange={(event) => {
              onChange(question, event.target.value, "dataString");
            }}
            disabled={readOnly}
          />
        </Grid>
      </FormControl>
    </Grid>
  );
}

SurveyQuestionText.propTypes = {
  question: PropTypes.any,
  answer: PropTypes.any,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
