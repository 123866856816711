import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { CampaignService } from "services/CampaignService";
import { GetFieldByLanguage } from "services/utils";
import CampaignQuestionTypeSelect from "../../components/select/CampaignQuestionTypeSelect";
import i18n from "../../i18n";
import { useStyles } from "./style";

const CampaignQuestionEdit = forwardRef((props, ref) => {
  const { campaign, pageId, onSave, question, onClose, currentLang } = props;
  const { t } = useTranslation("campaign", { i18n });
  const accountId = localStorage.accountId;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const faDialog = useDialog();

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const newCampaignQuestion = () => {
    return {
      campaign: campaign,
      campaignPage: { id: pageId },
      name: null,
      questionType: null,
      notEmpty: null,
      orderNo: null,
      min: null,
      max: null,
      past: null,
      future: null,
      maxFileSize: null,
      fileTypes: null,
      showComment: null,
      commentLabel: null,
      options: [],
      hint: null,
      maxCount: null,
    };
  };

  const [questionDTO, setQuestionDTO] = useState(newCampaignQuestion());
  const newOption = () => {
    return {
      campaignQuestion: null,
      name: null,
      orderNo: null,
      multiLangName: {
        tr: null,
        en: null,
        de: null,
      },
    };
  };
  const [options, setOptions] = useState([newOption()]);
  const [readyToSave, setReadyToSave] = useState(false);

  useEffect(() => {
    if (question?.id) {
      if (!!question.options && question.options.length > 0) {
        setOptions(question.options);
      }
      setQuestionDTO({ ...question });
    } else {
      setQuestionDTO(newCampaignQuestion());
      setOptions([newOption()]);
    }
  }, [question, isDialogOpen]);

  useEffect(() => {
    if (readyToSave) {
      save();
    }
  }, [readyToSave]);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));
  const saveQuestion = () => {
    if (
      questionDTO.questionType === "MULTIPLE_CHOICE" ||
      questionDTO.questionType === "CHECKBOX" ||
      questionDTO.questionType === "DROPDOWN"
    ) {
      setQuestionDTO({ ...questionDTO, options: options });
      setReadyToSave(true);
    } else if (questionDTO.questionType === "IMAGE") {
      setQuestionDTO({ ...questionDTO, fileTypes: "jpeg,jpg,png" });
      setReadyToSave(true);
    } else {
      save();
    }
  };
  const save = () => {
    setLoading(true);
    CampaignService.saveQuestion(
      { ...questionDTO },
      (data) => {
        onSave();
        setIsDialogOpen(false);
        setLoading(false);
        setReadyToSave(false);
        if (onClose) onClose();
      },
      errorCallback
    );
  };

  const errorCallback = (error) => {
    console.log(error);
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    setQuestionDTO({ ...questionDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setQuestionDTO({ ...questionDTO, [prop]: val });
  };
  const addItem = () => {
    let tempOptions = [...options];
    tempOptions.push(newOption());
    setOptions(tempOptions);
  };
  const deleteItem = (index) => {
    let temp = [...options];
    temp.splice(index, 1);

    for (let i = index; i < temp.length; i++) {
      temp[i].orderNo = index + 1;
    }

    setOptions(temp);
  };
  const handleChangeOptionV = (index, val) => {
    let temp = options[index];
    temp.multiLangName = { ...temp.multiLangName, [currentLang]: val };
    temp.orderNo = index + 1;
    let tempItems = [...options];
    tempItems[index] = temp;
    setOptions(tempItems);
  };

  const handleChangeItemV = (index, val, list, setList) => {
    let temp = list[index];
    temp.multiLangName = { ...temp.multiLangName, [currentLang]: val };
    temp.orderNo = index + 1;
    let tempItems = [...list];
    tempItems[index] = temp;
    setList(tempItems);
  };

  const renderEnterOptions = () => {
    return (
      <>
        {options.length !== 0 &&
          options.map((item, index) => (
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FaInput
                  label={t("OPTION") + " " + (index + 1)}
                  required
                  onChange={(event) => {
                    // handleChangeOptionV(index, event.target.value);
                    handleChangeItemV(
                      index,
                      event.target.value,
                      options,
                      setOptions
                    );
                  }}
                  value={
                    item.multiLangName?.[currentLang]
                      ? item.multiLangName?.[currentLang]
                      : ""
                  }
                  helperText={
                    item.multiLangName?.[currentLang] === undefined
                      ? GetFieldByLanguage(item.multiLangName, currentLang)
                      : ""
                  }
                />
                <IconButton
                  onClick={() => deleteItem(index)}
                  size="small"
                  color="primary"
                  aria-label={t("Sil")}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </div>
            </Grid>
          ))}
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              addItem();
            }}
          >
            {t("ADD_OPTION")}
          </Button>
        </Grid>
      </>
    );
  };

  const onQuestionDelete = (data) => {
    faDialog({
      description: t("Soruyu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        CampaignService.deleteQuestion(
          questionDTO,
          (data) => {
            showAlert(t("Soru Silindi"), "success");
            setIsDialogOpen(false);
            onSave();
          },
          (error) => {
            showAlert(t("Bağlı Kayıtlar Mevcut"), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  return (
    <FaDialog
      title={t("EDIT_QUESTION")}
      faOpen={isDialogOpen}
      faOnSubmit={saveQuestion}
      loading={loading}
      showSaveButton
      faHandleClose={(data) => {
        setIsDialogOpen(false);
        if (onClose) onClose();
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) => {
              setQuestionDTO({
                ...questionDTO,
                multiLangName: {
                  ...questionDTO.multiLangName,
                  [currentLang]: event.target.value,
                },
              });
            }}
            value={questionDTO.multiLangName?.[currentLang] || ""}
            helperText={
              questionDTO.multiLangName?.[currentLang] === undefined
                ? GetFieldByLanguage(questionDTO.multiLangName, currentLang)
                : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <CampaignQuestionTypeSelect
            value={questionDTO?.questionType}
            required
            onChange={(data) => {
              handleChangeV("questionType", data);
            }}
            label={t("QUESTION_TYPE")}
          />
        </Grid>

        {(questionDTO.questionType === "MULTIPLE_CHOICE" ||
          questionDTO.questionType === "CHECKBOX" ||
          questionDTO.questionType === "DROPDOWN") &&
          renderEnterOptions()}

        {(questionDTO.questionType === "STAR_RATING" ||
          questionDTO.questionType === "SLIDER") && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("SCALE")}
                required
                onChange={handleChange("max")}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}

        {(questionDTO.questionType === "TEXT" ||
          questionDTO.questionType === "COMMENT") && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("MIN_LENGTH")}
                onChange={handleChange("min")}
                value={questionDTO.min ? questionDTO.min : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("MAX_LENGTH")}
                onChange={handleChange("max")}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}

        {questionDTO.questionType === "INTEGER" && (
          <>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("MIN_VALUE")}
                onChange={handleChange("min")}
                value={questionDTO.min ? questionDTO.min : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("MAX_VALUE")}
                onChange={handleChange("max")}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}

        {(questionDTO.questionType === "DECIMAL" ||
          questionDTO.questionType === "SHOPPING_AMOUNT") && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("MIN_VALUE")}
                onChange={(event) => {
                  let str = event.target.value;
                  if (!isNaN(str) && !isNaN(parseFloat(str))) {
                    handleChangeV("min", event.target.value);
                  }
                }}
                value={questionDTO.min ? questionDTO.min : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("MAX_VALUE")}
                onChange={(event) => {
                  let str = event.target.value;
                  if (!isNaN(str) && !isNaN(parseFloat(str))) {
                    handleChangeV("max", event.target.value);
                  }
                }}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}
        {(questionDTO.questionType === "DATE" ||
          questionDTO.questionType === "DATE_TIME") && (
          <>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questionDTO.past}
                      onChange={(event) => {
                        handleChangeV("past", event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("PAST")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questionDTO.future}
                      onChange={(event) => {
                        handleChangeV("future", event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("FUTURE")}
                />
              </FormControl>
            </Grid>
          </>
        )}

        {questionDTO.questionType === "FILE" && (
          <>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("MAX_FILE_SIZE") + " (MB)"}
                onChange={handleChange("maxFileSize")}
                value={questionDTO.maxFileSize ? questionDTO.maxFileSize : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("FILE_TYPES")}
                onChange={handleChange("fileTypes")}
                value={questionDTO.fileTypes ? questionDTO.fileTypes : ""}
              />
            </Grid>
          </>
        )}
        {questionDTO.questionType === "SHOPPING_DOCUMENTS" && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("MAX_COUNT")}
                onChange={(event) => {
                  let str = event.target.value;
                  if (!isNaN(str) && !isNaN(parseFloat(str))) {
                    handleChangeV("maxCount", event.target.value);
                  }
                }}
                value={questionDTO.maxCount ? questionDTO.maxCount : ""}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <FaInput
            label={t("HINT")}
            onChange={(event) => {
              setQuestionDTO({
                ...questionDTO,
                multiLangHint: {
                  ...questionDTO.multiLangHint,
                  [currentLang]: event.target.value,
                },
              });
            }}
            value={questionDTO.multiLangHint?.[currentLang] || ""}
            helperText={
              questionDTO.multiLangHint?.[currentLang] === undefined
                ? GetFieldByLanguage(questionDTO.multiLangHint, currentLang)
                : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl variant="outlined" size="small">
            <FormControlLabel
              control={
                <Checkbox
                  checked={questionDTO.notEmpty}
                  onChange={(event) => {
                    handleChangeV("notEmpty", event.target.checked);
                  }}
                  color="primary"
                />
              }
              label={t("NOT_EMPTY")}
            />
          </FormControl>
        </Grid>

        {questionDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={onQuestionDelete} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default CampaignQuestionEdit;
