import AddIcon from "@mui/icons-material/Add";
import { Avatar, Grid, Typography } from "@mui/material";
import Adsense from "components/adsense/Adsense";
import ActivePassiveButtonGroup from "components/buttonGroup/ActivePassiveButtonGroup";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { ANNOUNCEMENT } from "../../services/faApi";
import AnnouncementEdit from "./AnnouncementEdit";

export default function Announcement() {
  const tableRef = useRef();
  const { t } = useTranslation(["announcement", "announcement"], { i18n });

  let history = useHistory();
  const labelClasses = labelStyles();

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const accountId = localStorage.accountId;

  const columns = [
    {
      field: "subject",
      label: t("SUBJECT"),
      align: "center",
      width: "30%",
    },
    {
      field: "startDate",
      label: t("START_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "endDate",
      label: t("END_DATE"),
      align: "center",
      width: "20%",
      type: "dateTime",
    },
    {
      field: "identifier",
      label: t("THING"),
      align: "center",
      width: "30%",
      valueFormatter: (value, row) => {
        if (!row?.thing) return null;
        else if (row.thing.thumbnail != null)
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="thing"
                src={`data:image/png;base64, ${row.thing.thumbnail}`}
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              />{" "}
              {row.thing.identifier}
            </div>
          );
        else
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                variant="square"
                style={{
                  width: "48px",
                  height: "48px",
                  marginRight: "10px",
                  borderRadius: "15%",
                }}
              >
                {" "}
                {row.thing.identifier.charAt(0)}
              </Avatar>{" "}
              {row.thing.identifier}
            </div>
          );
      },
    },
  ];

  const newSearchForm = () => {
    return {
      thingId: null,
      subject: null,
      content: null,
      accountId: accountId,
      status: "ACTIVE",
    };
  };

  const [searchForm, setSearchForm] = useState(newSearchForm());
  const [announcement, setAnnouncement] = useState();

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setAnnouncement(row);
    setIsEditFormOpen(true);
  };

  const announcementOnChange = (data) => {
    setIsEditFormOpen(false);
    tableRef.current.refreshTable();
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <AnnouncementEdit
          onChange={announcementOnChange}
          open={isEditFormOpen}
          onClose={setIsEditFormOpen}
          value={announcement}
        />
        <Grid
          item
          container
          spacing={1}
          style={{ marginBottom: 5, overflowY: "auto" }}
        >
          <Grid item lg={4}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("STATUS")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <ActivePassiveButtonGroup
                  data-fa-section="ACTIVE_PASSIVE_BUTTON_GROUP"
                  value={searchForm.status}
                  onChange={(data) => {
                    const temp = { ...searchForm, status: data };
                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={ANNOUNCEMENT.search}
          searchForm={searchForm}
          columns={columns}
          queryParams={{ page: 0, size: 10, isAsc: true, sort: "startDate" }}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setAnnouncement(null);
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("SUBJECT")}
                value={searchForm.subject}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "subject",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("CONTENT")}
                value={searchForm.content}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "content",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>

          <Adsense value="RklmAlt" />
        </Grid>
      </Suspense>
    </>
  );
}
