import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function CustomerInteractionsAll(props) {
  const stepsRef = useRef();

  const { t } = useTranslation("tutorialCustomerInteractionsAll", { i18n });
  const classes = useStyles();
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            Her türlü <b>Müşteri Etkileşimi</b>’nin toplu liste olarak
            gösterildiği ekran/menü’dür.
            <p>
              Bu menüde Müşteri Etkileşimlerini;
              <ul>
                <li>
                  <b>Tarih</b>'e göre listeleyebilirsiniz.
                </li>
                <li>
                  <b>İşlem</b>'e göre görebilirsiniz.
                </li>
                <li>
                  <b>Etkileşim Noktası</b>'na göre filtreleyebilirsiniz.
                </li>
                <li>
                  <b>Excel</b> dosyası olarak dışarı aktarabilirsiniz.
                </li>
                <li>
                  Etkileşim <b>detay bilgilerine</b> erişebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
      },
      {
        element: "#dateSpeedFilter",
        intro: (
          <TutorialElement icon="click">
            Etkileşimleri Tarih’e göre listelemek için <b>“Bugün”</b>,{" "}
            <b>“Son 7 Gün”</b> ve <b>“Son 1 Ay”</b> seçeneklerinden birine
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='date']",
        intro: (
          <TutorialElement icon="click">
            Etkileşimlerin tümünü <b>ilk</b> veya <b>son</b> etkileşim tarihine
            göre listelemek için tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#operationSpeedFilter",
        intro: (
          <TutorialElement icon="click">
            Etkileşimleri <b>İşlem</b>’e göre listelemek için seçeneklerden
            birine tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "[data-fa-column='operation']",
        intro: (
          <TutorialElement icon="click">
            Etkileşimlerin tümünü <b>İşlem Türü</b>’ne göre listelemek için
            tıklayın.
          </TutorialElement>
        ),
      },
      {
        element: "#navbarAdvancedSearchButton",
        intro: (
          <TutorialElement icon="click">
            Etkileşimleri <b>Etkileşim Noktası</b>’na göre filtrelemek için
            tıklayın
          </TutorialElement>
        ),
      },
      {
        element: null,
        intro: (
          <TutorialElement icon="click">
            Açılan pencerede filtreleri görebilirsiniz.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("#navbarAdvancedSearchButton").click();
        },
      },
      {
        element: "[data-fa-input='FILTER_THING']",
        intro: (
          <TutorialElement icon="click">
            <b>Etkileşim Noktası</b> seçmek için tıklayın.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/filtre_thing_secimi.png"
                }
                width="605"
                height="346"
              ></img>
            </div>
            <br />
            <b>Etkileşim Noktaları</b>’ndan birini seçin ve <b>Ara</b> butonuna
            basın.
            <br /> <br />
            <b>ÖNEMLİ NOT :</b>
            <br />
            Başlangıç paketi ve diğer üst paketler için geçerlidir.
          </TutorialElement>
        ),
        tooltipClass: classes.filtreThingSecimi,
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/filtre_islem_ve_tarih.png"
                }
                width="534"
                height="242"
              ></img>
            </div>
            <br />
            Seçtiğiniz <b>Etkileşim Noktası</b> ile ilgili müşteri
            etkileşimlerini <b>İşlem</b>’e ve/veya <b>Tarih</b>’e göre
            listelemek için seçim yapın ve <b>Ara</b> butonuna basın.
          </TutorialElement>
        ),
        tooltipClass: classes.filtreIslemVeTarih,
      },
      {
        element: "div.MuiTablePagination-selectRoot", // sayfa basina kayit sayisi
        intro: (
          <TutorialElement icon="click">
            Sayfa başına Etkileşim Sayısını ayarlamak için tıklayın ve 10, 25,
            50 ve 100 seçeneklerinden birini seçin.
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          $("#navbarAdvancedSearchButton").click();
        },
        position: "top",
      },
      {
        element: ".MuiTablePagination-actions",
        intro: (
          <TutorialElement icon="click">
            Sayfalar arasında geçiş yapmak için {"“<”"} ve {"“>”"} seçeneklerine
            tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        element: "[title='Excel']",
        intro: (
          <TutorialElement icon="click">
            Listeyi <b>Dışarı Aktarmak</b> için <b>Excel</b> ikonuna tıklayın.
          </TutorialElement>
        ),
        position: "top",
      },
      {
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/tumEtkilesimlerElement.png"
                }
                width="819"
                height="344"
              ></img>
            </div>
            <br />
            Detay bilgilerine erişmek istediğiniz <b>Etkileşim</b>’in üzerine
            liste üzerinden <b>çift</b> tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.tumEtkilesimlerElement,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"customer_interactions_all"} // veri tabanındaki kodla aynı olması önemli
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        onExit={props.onExit}
        // otherOptions={{ scrollToElement: false }}
      />
    </div>
  );
}
