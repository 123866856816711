import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import AddIcon from "@mui/icons-material/Add";
import ThingsDataTable from "components/thingsDataTable";
import i18n from "i18n";
import { default as React, useRef, useState } from "react";
import { FaButton } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { BENEFICIARY_PORTAL } from "services/faApi";
import { getDateTimeWFormat, IssueDesc } from "services/utils";
import IssueEdit from "../issues/IssueEdit";
import { useStyles } from "./style";

export default function MyIssues(props) {
  const { campaign } = props;
  const accountId = localStorage.accountId;
  const { t } = useTranslation(["beneficiaryPortal", "report"], { i18n });

  const dispatch = useDispatch();
  const classes = useStyles();
  const tableRef = useRef();
  const dialogRef = useRef();
  const faDialog = useDialog();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCheckList, setSelectedCheckList] = useState(false);
  const [checkLists, setCheckLists] = useState();

  const newSearchForm = () => {
    return {
      notifier: null,
      issueClass: null,
      issueClassId: null,
      user: null,
      userId: null,
      description: null,
      accountId: localStorage.beneficiaryAccountId,
    };
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const columns = [
    {
      field: "notificationDate",
      label: t("Bildirim Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
    {
      field: "id2",
      label: t("Sorun"),
      align: "center",
      width: "40%",
      valueFormatter: (val, row) => IssueDesc(row),
    },
    {
      field: "status",
      label: t("Bildirim Durumu"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return t("ISSUE_STATUS_" + value, { ns: "report" });
      },
    },
    {
      field: "closeDate",
      label: t("Kapatılma Tarihi"),
      align: "center",
      width: "20%",
      valueFormatter: (value) => {
        return getDateTimeWFormat(value);
      },
    },
  ];

  return (
    <>
      <IssueEdit
        onSave={() => tableRef.current.refreshTable()}
        ref={dialogRef}
      ></IssueEdit>

      <Card>
        <CardHeader title={t("OPEN_ISSUES")} data-fa-section="OPEN_ISSUES" />
        <ThingsDataTable
          searchUrl={BENEFICIARY_PORTAL.openIssues}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "notificationDate",
          }}
        />
      </Card>
      <Grid
        item
        xs={2}
        className={"thingsDataTableButtons"}
        style={{ marginLeft: 10 }}
      >
        <FaButton
          variant="contained"
          disabled={loading}
          color="primary"
          size="medium"
          faClick={() => {
            dialogRef.current.openDialog();
          }}
          startIcon={<AddIcon />}
          data-fa-button="ADD_ISSUE"
        >
          {t("ADD")}
        </FaButton>
      </Grid>
    </>
  );
}
