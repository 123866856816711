import { Button, Grid } from "@mui/material";
import LegalTextSelect from "components/select/LegalTextSelect";
import React, { forwardRef, useEffect, useState } from "react";
import { FaDatePicker, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LegalTextService } from "services/LegalTextService";
import i18n from "../../i18n";

const LegalTextEdit = forwardRef((props, ref) => {
  const { onChange, onDelete, onClose, value, open } = props;
  const { t } = useTranslation("legalText", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const accountId = localStorage.accountId;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setLegalText(value == null ? newLegalText() : { ...value });
    }
  }, [open]);

  const newLegalText = () => {
    return {
      accountId: accountId,
      id: "",
      name: null,
      description: null,
      startDate: null,
      endDate: null,
    };
  };
  const newStartDate = () => {
    return new Date();
  };
  const newEndDate = () => {
    return new Date();
  };
  const [legalText, setLegalText] = useState(newLegalText());

  const handleChange = (prop) => (event) => {
    setLegalText({
      ...legalText,
      [prop]: event.target.value,
    });
  };
  const handleChangeV = (prop, value) => {
    setLegalText({ ...legalText, [prop]: value });
  };
  const handleDateChangeV = (prop, value) => {
    setLegalText({ ...legalText, [prop]: value });
  };
  const saveSuccess = (data) => {
    showAlert(!legalText.id ? t("Kaydedildi") : t("Güncellendi"), "success");
    setLegalText(newLegalText());
    setLoading(false);
    onChange(data);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveLegalText = () => {
    setLoading(true);
    LegalTextService.save(legalText, saveSuccess, saveError);
  };

  const legalTextDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        LegalTextService.delete(
          legalText,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            onDelete();
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={t("EDIT_LEGAL_TEXT")}
          faOpen={open}
          faOnSubmit={saveLegalText}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            onClose();
          }}
          faMaxWidth="lg"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                faType="name"
                label={t("NAME")}
                value={legalText.name || ""}
                inputProps={{ maxLength: 100 }}
                onChange={handleChange("name")}
              />
              <FaInput
                faType="description"
                label={t("DESCRIPTION")}
                value={legalText.description || ""}
                inputProps={{ maxLength: 300 }}
                onChange={handleChange("description")}
              />
              <Grid item xs={12}>
                <FaDatePicker
                  required
                  label={t("START_DATE")}
                  faType="date"
                  name="startDate"
                  value={legalText.startDate}
                  faOnChange={(data) => handleDateChangeV("startDate", data)}
                />
                <FaDatePicker
                  label={t("END_DATE")}
                  faType="date"
                  name="endDate"
                  value={legalText.endDate}
                  faOnChange={(data) => handleDateChangeV("endDate", data)}
                />
              </Grid>

              <Grid item xs={12} style={{ marginTop: 5 }}>
                <LegalTextSelect
                  required
                  value={legalText.type}
                  onChange={(data) => handleChangeV("type", data)}
                />
              </Grid>
              <FaInput
                required
                faType="description"
                label={t("CONTENT")}
                value={legalText.content || ""}
                inputProps={{ maxLength: 30000 }}
                onChange={handleChange("content")}
              />
            </Grid>
            {legalText.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={legalTextDelete} color="secondary">
                  Sil
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return newFormRender();
});

export default LegalTextEdit;
