import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { OpenInBrowser } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import RelationTypeAutoComplete from "components/autocomplete/RelationTypeAutoComplete";
import ThingDetail from "components/detail/ThingDetail";
import ThingSelector from "components/selector/ThingSelector";
import ThingsRelationsHelp from "features/help/ThingsRelationsHelp";
import React, { useEffect, useState } from "react";
import { FaDialog } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { RelationTypeService } from "services/RelationTypeService";
import { ThingRelationService } from "services/ThingRelationService";
import { AccPreferencesService } from "services/accPreferences/accPreferencesService";
import { ThingDesc } from "services/utils";
import i18n from "../../i18n";
import ThingRelationsOverviewFlow from "./ThingRelationsOverviewFlow";
import { useStyles } from "./style";

export default function ThingRelationsChart(props) {
  const faDialog = useDialog();
  const { thing, isEditable } = props;
  const classes = useStyles();

  const { t } = useTranslation("thingDetail", { i18n });

  let history = useHistory();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [thingRelations, setThingRelations] = useState([]);
  const [thingRelation, setThingRelation] = useState({});
  const [things, setThings] = useState([]);
  const [defaultRelationType, setDefaultRelationType] = useState(null);
  const [isCheckOK, setIsCheckOK] = useState(false);
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (thing && thing.account)
      if (!!!thing.productType)
        AccPreferencesService.findByAccount(thing.account.id, (data) => {
          setDefaultRelationType(data?.relationType);
        });
      else
        RelationTypeService.findInteractionPoint(
          thing.account.id,
          (data) => {
            setDefaultRelationType(data);
          },
          () => {}
        );
  }, [thing]);

  const newThingRelation = () => {
    return {
      sourceThing: { id: thing.id },
      targetThing: null,
      relationType: defaultRelationType,
      account: { id: thing?.account?.id },
    };
  };

  useEffect(() => {
    if (thing && thing.id) {
      ThingRelationService.findThingRelations(
        thing.id,
        thing.account.id,
        findThingRelationsOnSuccess,
        findThingRelationsOnError
      );
      setThingRelation(newThingRelation());
    }
  }, [thing]);

  useEffect(() => {
    if (things && thingRelation && thingRelation.relationType) checkRelation();
  }, [things, thingRelation]);

  const reloadThingRelations = () => {
    ThingRelationService.findThingRelations(
      thing.id,
      thing.account.id,
      findThingRelationsOnSuccess,
      findThingRelationsOnError
    );
  };

  const findThingRelationsOnSuccess = (data) => {
    setThingRelations(data);
  };

  const findThingRelationsOnError = () => {};

  const saveRelation = () => {
    let tmp = null;
    if (thingRelation.relationType.source === false) {
      tmp = things.map((item) => {
        return {
          ...thingRelation,
          targetThing: { id: thing.id },
          sourceThing: item,
          account: { id: thing?.account?.id },
        };
      });
    } else {
      tmp = {
        ...thingRelation,
      };
      tmp = things.map((item) => {
        return {
          ...thingRelation,
          targetThing: item,
          sourceThing: { id: thing.id },
          account: { id: thing?.account?.id },
        };
      });
    }

    ThingRelationService.saveAll(
      tmp,
      saveRelationOnSuccess,
      saveRelationOnError
    );
  };

  const checkRelation = () => {
    setIsCheckOK(true);
    let tmp = null;
    let checkSource = true;
    if (thingRelation.relationType.source === false) {
      checkSource = true;
      tmp = things.map((item) => {
        return {
          ...thingRelation,
          targetThing: { id: thing.id },
          sourceThing: { ...item },
          account: { id: thing?.account?.id },
        };
      });
    } else {
      tmp = {
        ...thingRelation,
      };
      tmp = things.map((item) => {
        checkSource = false;
        return {
          ...thingRelation,
          targetThing: { ...item },
          sourceThing: { id: thing.id },
          account: { id: thing?.account?.id },
        };
      });
    }

    console.log("tmp:", tmp);

    tmp.map((item) => {
      ThingRelationService.check(
        item,
        (checkData) => {
          if (!checkData) {
            setIsCheckOK(false);
            showAlert(
              "Bu etkileşim noktası ilişkisi yapılamaz, lütfen kontrol edin (" +
                (checkSource
                  ? ThingDesc(item.sourceThing)
                  : ThingDesc(item.targetThing)) +
                ")",
              "error"
            );
          }
        },
        (checkError) => {
          setIsCheckOK(false);
          showAlert(
            "Bu etkileşim noktası ilişkisi yapılamaz, lütfen kontrol edin (" +
              (checkSource
                ? ThingDesc(item.sourceThing)
                : ThingDesc(item.targetThing)) +
              ")",
            "error"
          );
        }
      );
    });
  };

  const saveRelationOnSuccess = () => {
    reloadThingRelations();
    setIsEditDialogOpen(false);
  };

  const saveRelationOnError = (data) => {
    showAlert(
      "Bu etkileşim noktası ilişkisi yapıldı, lütfen kontrol edin",
      "error"
    );
  };

  const handleChangeV = (prop, val) => {
    setThingRelation({ ...thingRelation, [prop]: val });
  };

  const deleteRelation = (item) => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    }).then((confirmation) => {
      ThingRelationService.delete(
        item,
        deleteRelationOnSuccess,
        deleteRelationOnError
      );
    });
  };

  const deleteRelationOnSuccess = (data) => {
    reloadThingRelations();
  };

  const deleteRelationOnError = (data) => {};

  const renderEditDialog = () => {
    return (
      <FaDialog
        title={t("ADD_RELATIONSHIP")}
        faOpen={isEditDialogOpen}
        faOnSubmit={saveRelation}
        showSaveButton
        faHandleClose={() => {
          setIsEditDialogOpen(false);
        }}
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={0}
          data-fa-section="RELATION_DIALOG"
        >
          <Grid item xs={12}>
            <ThingSelector
              required={true}
              multiple={true}
              value={things}
              accountId={thing?.account?.id}
              onChange={(data) => {
                setThings(data);
              }}
            />
          </Grid>
          {(!!!thing.productType || !defaultRelationType) && (
            <Grid item xs={12}>
              <RelationTypeAutoComplete
                value={thingRelation.relationType}
                accountId={thing?.account?.id}
                onChange={(data) => handleChangeV("relationType", data)}
              />
            </Grid>
          )}
        </Grid>
      </FaDialog>
    );
  };

  const onClickThingRealtionExplorer = () => {
    const tmpTo = {
      pathname: `/thingRelationExplorer/${thing.id}`,
      breadCrumbKey: `/thingRelationExplorer/:id`,
    };
    history.push(tmpTo);
  };

  return <>
    {renderEditDialog()}
    <Card
      style={{ minHeight: 100, overflowY: "auto" }}
      className="fai-thingLinks"
    >
      <CardHeader
        action={
          <>
            <ThingsRelationsHelp value="thingDetail.thingsRelations" />

            <IconButton
              data-fa-button="RELATION_BROWSER"
              aria-label="settings"
              onClick={() => {
                onClickThingRealtionExplorer();
              }}
              title="İlişki Tarayıcısı"
              size="large">
              <OpenInBrowser />
            </IconButton>

            {isEditable && (
              <IconButton
                data-fa-button="RELATION_EDIT"
                aria-label="settings"
                onClick={() => {
                  setThingRelation(newThingRelation());
                  setThings([]);
                  setIsEditDialogOpen(true);
                }}
                size="large">
                <AddIcon />
              </IconButton>
            )}
          </>
        }
        title={
          !!!thing.productType
            ? t("RELATED_THINGS")
            : t("Etkileşim Noktaları")
        }
        data-fa-section="RELATED_THINGS"
      />
      <CardContent>
        <Grid item container spacing={1}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableBody>
                {thingRelations.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <ThingDetail
                        value={
                          row.sourceThing.id === thing.id
                            ? row.targetThing
                            : row.sourceThing
                        }
                      />
                    </TableCell>
                    {!!!thing.productType && (
                      <TableCell>
                        {row.sourceThing.id === thing.id
                          ? row.relationType.nameSource
                          : row.relationType.nameTarget}
                      </TableCell>
                    )}
                    <TableCell>
                      {isEditable && (
                        <IconButton
                          onClick={() => deleteRelation(row)}
                          size="small"
                          color="primary"
                          aria-label={t("Sil")}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              {thingRelations && thingRelations.length > 0 && (
                <TableFooter>
                  <TableCell
                    colSpan={3}
                    style={{ textAlign: "right", borderStyle: "none" }}
                  >
                    {"#" + thingRelations.length + " Kayıt"}
                  </TableCell>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Grid>

        <ThingRelationsOverviewFlow
          thing={thing}
          thingRelations={thingRelations}
        />
      </CardContent>
    </Card>
  </>;
}
