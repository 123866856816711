import { CardContent, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import i18n from "i18n";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BeneficiaryPortalService } from "services/BeneficiaryPortalService";
import { PUBLIC } from "services/faApi";
import { BASE_URL } from "serviceWorker";
import { useStyles } from "./style";

export default function Welcome() {
  const accountId = localStorage.beneficiaryAccountId;
  const { t } = useTranslation(["beneficiaryPortal", "report"], { i18n });

  const classes = useStyles();

  const [customer, setCustomer] = useState({});

  useEffect(() => {
    BeneficiaryPortalService.getCustomerInfo(
      accountId,
      setCustomer,
      (error) => {}
    );
  }, []);

  return (
    <Card>
      <CardContent>
        <div style={{ display: "inline-block" }}>
          <img
            onc
            alt="belge"
            src={BASE_URL + PUBLIC.accountAvatar + "/" + accountId}
            style={{
              width: "48px",
              height: "48px",
              marginRight: "10px",
            }}
          />
          <Typography
            variant="caption"
            style={{ top: -15, position: "relative" }}
          >
            {`Hoşgeldiniz ${customer?.name ?? ""} ${customer?.surname ?? ""}`}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
