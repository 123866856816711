import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  image: {
    position: "relative",
    width: "100%",
  },
  imageDeleteButtom: {
    position: "absolute",
    left: 0,
    bottom: 0,
    zIndex: 1,
    marginLeft: "10px",
    marginBottom: "10px",
  },
  buttonPanel: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  chipRoot: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  cancelButton: {
    marginBottom: "20px",
    marginLeft: "5px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  LabelValue: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#747474",
  },
  ActionButton: {
    fontSize: "1rem",
    padding: "0px",
  },
  ActionButtonPlaceHolder: {
    width: "18px",
    height: "20px",
  },
}));
