import AddIcon from "@mui/icons-material/Add";
import { Grid, Typography } from "@mui/material";
import StockCategoryAutoComplete from "components/autocomplete/StockCategoryAutoComplete";
import StockQuantityAutoComplete from "components/autocomplete/StockQuantityAutoComplete";
import StockUnitAutoComplete from "components/autocomplete/StockUnitAutoComplete";
import SystemAccountButtonGroup from "components/buttonGroup/SystemAccountButtonGroup";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { STOCK } from "../../services/faApi";
import { StockService } from "../../services/stock/stockService";

export default function Stock() {
  const tableRef = useRef();
  const { t } = useTranslation("stock", { i18n });
  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const labelClasses = labelStyles();
  const accountName = localStorage.accountName;
  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const columns = [
    { field: "name", label: t("NAME"), align: "center", width: "40%" },
    { field: "code", label: t("CODE"), align: "center", width: "20%" },
    {
      field: "category",
      label: t("CATEGORY"),
      align: "center",
      width: "20%",
      valueFormatter: (category) => {
        return category?.name;
      },
    },
    {
      field: "unit",
      label: t("UNIT"),
      align: "center",
      width: "10%",
      valueFormatter: (unit, row) => {
        return (row.quantity ? row.quantity.name + "/" : "") + unit?.name;
      },
    },
    {
      field: "id",
      label: t("ACCOUNT"),
      align: "center",
      width: "10%",
      valueFormatter: (value, row) => {
        return row.account ? row.account.fullname : t("SYSTEM");
      },
    },
  ];
  const newStock = () => {
    return {
      id: "",
      name: "",
      code: "",
      category: null,
      quantity: null,
      unit: null,
    };
  };
  const [stock, setStock] = useState(newStock());

  const handleChange = (prop) => (event) => {
    setStock({ ...stock, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!stock.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setStock(newStock());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveStock = () => {
    setLoading(true);
    StockService.save(
      {
        ...stock,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      code: null,
      category: null,
      categoryId: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    if (row.account) {
      setStock(row);
      setIsEditFormOpen(true);
    }
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Stok Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveStock}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setStock(newStock());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <StockCategoryAutoComplete
                accountId={accountId}
                value={stock.category}
                required
                onChange={(data) => {
                  handleChangeGeneric(setStock, stock, "category", data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={stock.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("CODE")}
                onChange={handleChange("code")}
                value={stock.code || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <StockQuantityAutoComplete
                required
                accountId={accountId}
                value={stock.quantity}
                onChange={(data) => {
                  handleChangeGeneric(setStock, stock, "quantity", data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StockUnitAutoComplete
                required
                accountId={accountId}
                quantityId={stock.quantity?.id}
                value={stock.unit}
                onChange={(data) => {
                  handleChangeGeneric(setStock, stock, "unit", data);
                }}
              />
            </Grid>
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newFormRender()}
        <Grid item lg={4} xs={6}>
          <Grid item xs={12} className={labelClasses.LabelLastRow}>
            <Typography className={labelClasses.LabelHeader}>
              {t("ACCOUNT")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SystemAccountButtonGroup
              label={accountName}
              value={searchForm.systemType}
              onChange={(data) => {
                const temp = { ...searchForm, systemType: data };
                setSearchForm(temp);
                tableRef.current.search(temp);
              }}
            />
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={STOCK.search + "?accountId=" + localStorage.accountId}
          exportUrl={STOCK.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{ page: 0, size: 10, sort: "name", isAsc: true }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("CODE")}
                value={searchForm.code}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "code",
                    event.target.value
                  );
                }}
              />

              <StockCategoryAutoComplete
                accountId={accountId}
                value={searchForm.category}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    category: data,
                    categoryId: data?.id,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
