import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  w100: {
    width: "100%",
    minHeight: 50,
    marginTop: 4,
  },
  padding10: {
    padding: "14.5px 14px",
  },
}));
