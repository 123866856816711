import { Link } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import AccountSocialMediaHelp from "features/help/AccounSocialMediaHelp";
import React, { useState } from "react";
import { FaDialog, FaInput } from "react-base-fa/dist/fa";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { AccountService } from "services/AccountService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./AccountDetailsCardStyle";

export default function AccountSocialMedia(props) {
  const { accountDetails, onSave } = props;
  const { t } = useTranslation(["accountDetails", "components"], { i18n });
  const classes = useStyles();

  const labelClasses = labelStyles();
  const [loading, setLoading] = useState(false);
  const [updateValues, setUpdateValues] = useState({ ...accountDetails });
  let history = useHistory();

  const dispatch = useDispatch();

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  const [isAccountDetailsEditOpen, setIsAccountDetailsEditOpen] =
    useState(false);

  const editButtonClicked = () => {
    setUpdateValues({ ...accountDetails });
    setIsAccountDetailsEditOpen(true);
  };

  const saveAccountDetails = () => {
    setLoading(true);

    AccountService.updateSocialMedia(
      updateValues,
      accountDetails.id,
      (data) => {
        showAlert("Sosyal medya bilgileri güncellendi", "success");
        setIsAccountDetailsEditOpen(false);
        if (onSave) onSave();
      },
      (error) => {
        showAlert(t("errorMessages:UNEXPECTED_ERROR"), "error");
      }
    );
  };

  const handleChange = (prop) => (event) => {
    setUpdateValues({ ...updateValues, [prop]: event.target.value });
  };

  const handleChangeV = (prop, value) => {
    setUpdateValues({ ...updateValues, [prop]: value });
  };

  return <>
    <FaDialog
      title={"Sosyal Medya Bilgileri Güncelle"}
      faOpen={isAccountDetailsEditOpen}
      faOnSubmit={saveAccountDetails}
      showSaveButton
      faHandleClose={(data) => {
        setIsAccountDetailsEditOpen(false);
      }}
    >
      <Grid container spacing={1} data-fa-section="SOCIAL_MEDIA_EDIT">
        <Grid item lg={12} xs={12}>
          <FaInput
            label={t("Facebook")}
            value={updateValues.sm_facebook}
            onChange={handleChange("sm_facebook")}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <FaInput
            label={t("Twitter/X")}
            value={updateValues.sm_twitter}
            onChange={handleChange("sm_twitter")}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <FaInput
            label={t("Instagram")}
            value={updateValues.sm_instagram}
            onChange={handleChange("sm_instagram")}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <FaInput
            label={t("Linkedin")}
            value={updateValues.sm_linkedin}
            onChange={handleChange("sm_linkedin")}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <FaInput
            label={t("Tripadvisor")}
            value={updateValues.sm_tripadvisor}
            onChange={handleChange("sm_tripadvisor")}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <FaInput
            label={t("Google")}
            value={updateValues.sm_google}
            onChange={handleChange("sm_google")}
            inputProps={{
              maxLength: 255,
            }}
          />
        </Grid>
      </Grid>
    </FaDialog>
    <Grid container spacing={1}>
      <Grid item lg={12}>
        <Card
          data-fa-section="SOCIAL_MEDIA_CARD"
          style={{ marginTop: "16px", overflowY: "auto" }}
        >
          <CardHeader
            action={
              <>
                <AccountSocialMediaHelp value="accountDetail.socialMedia" />
                <IconButton
                  data-fa-button="SOCIAL_MEDIA_ADD"
                  aria-label="settings"
                  onClick={editButtonClicked}
                  size="large">
                  <EditIcon />
                </IconButton>
              </>
            }
            data-fa-section="SOCIAL_MEDIA"
            title={t("SOCIAL_MEDIA")}
          />
          <CardContent>
            <Grid
              item
              container
              spacing={1}
              style={{ marginLeft: 10, marginRight: 10 }}
              data-fa-section="SOCIAL_MEDIA_LIST"
            >
              <Grid item xs={1}>
                <SocialIcon
                  network="facebook"
                  style={{ height: 24, width: 24 }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={labelClasses.LabelValue}>
                  <Link
                    onClick={() => {
                      window.open(`${accountDetails.sm_facebook}`, "_blank");
                    }}
                    underline="hover">
                    {accountDetails.sm_facebook}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SocialIcon
                  network="twitter"
                  style={{ height: 24, width: 24 }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={labelClasses.LabelValue}>
                  <Link
                    onClick={() => {
                      window.open(`${accountDetails.sm_twitter}`, "_blank");
                    }}
                    underline="hover">
                    {accountDetails.sm_twitter}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SocialIcon
                  network="instagram"
                  style={{ height: 24, width: 24 }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={labelClasses.LabelValue}>
                  <Link
                    onClick={() => {
                      window.open(`${accountDetails.sm_instagram}`, "_blank");
                    }}
                    underline="hover">
                    {accountDetails.sm_instagram}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SocialIcon
                  network="linkedin"
                  style={{ height: 24, width: 24 }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={labelClasses.LabelValue}>
                  <Link
                    onClick={() => {
                      window.open(`${accountDetails.sm_linkedin}`, "_blank");
                    }}
                    underline="hover">
                    {accountDetails.sm_linkedin}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1333.31 1333.31"
                  shape-rendering="geometricPrecision"
                  text-rendering="geometricPrecision"
                  image-rendering="optimizeQuality"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  style={{ height: 24, width: 24 }}
                >
                  <g fill-rule="nonzero">
                    <circle
                      cx="666.66"
                      cy="666.66"
                      r="666.66"
                      fill="#34e0a1"
                    />
                    <path d="M1078.42 536.6l80.45-87.52h-178.4c-89.31-61.01-197.17-96.54-313.81-96.54-116.5 0-224.06 35.61-313.22 96.54H174.6l80.44 87.52c-49.31 44.99-80.22 109.8-80.22 181.75 0 135.79 110.09 245.88 245.88 245.88 64.51 0 123.27-24.88 167.14-65.55l78.81 85.81 78.81-85.73c43.87 40.67 102.57 65.47 167.07 65.47 135.79 0 246.03-110.09 246.03-245.88.07-72.03-30.84-136.83-80.15-181.75zM420.77 884.75c-91.92 0-166.4-74.48-166.4-166.4s74.49-166.4 166.4-166.4c91.92 0 166.4 74.49 166.4 166.4 0 91.91-74.49 166.4-166.4 166.4zm245.96-171.24c0-109.5-79.63-203.5-184.73-243.65 56.84-23.76 119.18-36.94 184.66-36.94 65.47 0 127.89 13.18 184.73 36.94-105.02 40.23-184.65 134.15-184.65 243.65zm245.88 171.24c-91.92 0-166.4-74.48-166.4-166.4s74.49-166.4 166.4-166.4c91.92 0 166.4 74.49 166.4 166.4 0 91.91-74.49 166.4-166.4 166.4zm0-253.7c-48.2 0-87.23 39.03-87.23 87.23 0 48.19 39.03 87.22 87.23 87.22 48.19 0 87.22-39.03 87.22-87.22 0-48.12-39.03-87.23-87.22-87.23zM508 718.35c0 48.19-39.03 87.22-87.23 87.22-48.19 0-87.22-39.03-87.22-87.22 0-48.2 39.03-87.23 87.22-87.23 48.19-.07 87.23 39.03 87.23 87.23z" />
                  </g>
                </svg>
                {/* <SocialIcon
                  network="tripadvisor"
                  style={{ height: 24, width: 24 }}
                /> */}
              </Grid>
              <Grid item xs={5}>
                <Typography className={labelClasses.LabelValue}>
                  <Link
                    onClick={() => {
                      window.open(
                        `${accountDetails.sm_tripadvisor}`,
                        "_blank"
                      );
                    }}
                    underline="hover">
                    {accountDetails.sm_tripadvisor}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SocialIcon
                  network="google"
                  style={{ height: 24, width: 24 }}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className={labelClasses.LabelValue}>
                  <Link
                    onClick={() => {
                      window.open(`${accountDetails.sm_google}`, "_blank");
                    }}
                    underline="hover">
                    {accountDetails.sm_google}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>;
}
