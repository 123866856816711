import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useStyles } from "./style";

export default function CustomerIneractionOperationSelect(props) {
  const { value, onChange, required, label } = props;
  const classes = useStyles();
  const { t } = useTranslation("components", { i18n });

  return (
    <FormControl className={classes.w100}>
      <InputLabel>
        {label ?? t("CUSTOMER_INTERACTION_OPERATION.NAME")}
      </InputLabel>
      <Select
        variant="outlined"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        required={required}
        label={label ?? t("CUSTOMER_INTERACTION_OPERATION")}
      >
        <MenuItem value={null}>...</MenuItem>
        <MenuItem value={"ISSUE_REPORT"}>
          {t("CUSTOMER_INTERACTION_OPERATION.ISSUE_REPORT")}
        </MenuItem>
        <MenuItem value={"LIKE"}>
          {t("CUSTOMER_INTERACTION_OPERATION.LIKE")}
        </MenuItem>
        <MenuItem value={"SUGGESTION"}>
          {t("CUSTOMER_INTERACTION_OPERATION.SUGGESTION")}
        </MenuItem>
        <MenuItem value={"SURVEY"}>
          {t("CUSTOMER_INTERACTION_OPERATION.SURVEY")}
        </MenuItem>
        <MenuItem value={"CAMPAIGN"}>
          {t("CUSTOMER_INTERACTION_OPERATION.CAMPAIGN")}
        </MenuItem>
        <MenuItem value={"CUSTOMER"}>
          {t("CUSTOMER_INTERACTION_OPERATION.CUSTOMER")}
        </MenuItem>
        <MenuItem value={"SCAN_QR"}>
          {t("CUSTOMER_INTERACTION_OPERATION.SCAN_QR")}
        </MenuItem>
        <MenuItem value={"PUBLIC_INFO"}>
          {t("CUSTOMER_INTERACTION_OPERATION.PUBLIC_INFO")}
        </MenuItem>
        <MenuItem value={"DOWNLOAD_DOCUMENT"}>
          {t("CUSTOMER_INTERACTION_OPERATION.DOWNLOAD_DOCUMENT")}
        </MenuItem>
        <MenuItem value={"DOWNLOAD_IMAGE"}>
          {t("CUSTOMER_INTERACTION_OPERATION.DOWNLOAD_IMAGE")}
        </MenuItem>
        <MenuItem value={"THING_LINK"}>
          {t("CUSTOMER_INTERACTION_OPERATION.THING_LINK")}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
