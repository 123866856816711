import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  base: {
    padding: 10,
    overflowY: "scroll",
    maxHeight: "300px",
  },
  messageBox: {
    padding: 10,
  },
  date: {
    padding: 10,
  },
  dateSize: {
    fontSize: 10,
  },
  back: {
    background: "#FDF5FF",
  },
}));
