import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SurveyQuestionCategoryAutoComplete from "components/autocomplete/SurveyQuestionCategoryAutoComplete";
import {
  default as React,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaButton, FaDialog, FaInput } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SurveyService } from "services/SurveyService";
import { GetFieldByLanguage } from "services/utils";
import SurveyQuestionTypeSelect from "../../components/select/SurveyQuestionTypeSelect";
import i18n from "../../i18n";
import { useStyles } from "./style";

const SurveyQuestionEdit = forwardRef((props, ref) => {
  const {
    onSave,
    pageId,
    question,
    library,
    openChooseFromLibrary,
    onClose,
    currentLang,
  } = props;
  const { t } = useTranslation("survey", { i18n });
  const accountId = localStorage.accountId;
  let history = useHistory();
  const faDialog = useDialog();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [readyToSave, setReadyToSave] = useState(false);
  const [addToLibrary, setAddToLibrary] = useState(false);

  const newQuestion = () => {
    return {
      surveyPage: pageId ? { id: pageId } : null,
      surveyQuestionCategory: null,
      name: null,
      questionType: null,
      notEmpty: false,
      min: null,
      max: null,
      past: false,
      maxFileSize: null,
      fileTypes: null,
      future: false,
      showComment: false,
      commentLabel: null,
      options: [],
      yesLabel: null,
      nullLabel: null,
      account: { id: accountId },
      refQuestionId: null,
    };
  };

  const [questionDTO, setQuestionDTO] = useState(newQuestion());

  const newOption = () => {
    return {
      surveyQuestion: null,
      optionType: null,
      orderNo: null,
      multiLangName: {
        tr: null,
        en: null,
        de: null,
      },
    };
  };

  const [rows, setRows] = useState([newOption()]);
  const [columns, setColumns] = useState([newOption()]);
  const [menuItems, setMenuItems] = useState([newOption()]);

  const [options, setOptions] = useState([newOption()]);

  useImperativeHandle(ref, () => ({
    openDialog() {
      setIsDialogOpen(true);
    },
  }));

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };

  useEffect(() => {
    if (question && question.id) {
      setQuestionDTO({ ...question });
      let tempAll = [...question.options];
      let tempOptions = [];
      let tempRows = [];
      let tempColumns = [];
      let tempMenuItems = [];

      tempAll.map((option, index) => {
        if (option.optionType === "ROW") {
          tempRows.push(option);
        } else if (option.optionType === "COLUMN") {
          tempColumns.push(option);
        } else if (option.optionType === "MENU") {
          tempMenuItems.push(option);
        } else {
          tempOptions.push(option);
        }
      });
      setOptions(tempOptions);
      setRows(tempRows);
      setColumns(tempColumns);
      setMenuItems(tempMenuItems);
    }
  }, [question]);

  useEffect(() => {
    if (library) {
      setAddToLibrary(true);
    }
  }, [library]);

  useEffect(() => {
    if (readyToSave && !addToLibrary) {
      save();
    } else if (readyToSave && addToLibrary && questionDTO.refQuestionId) {
      save();
    }
  }, [readyToSave, questionDTO?.refQuestionId]);

  const save = () => {
    setLoading(true);
    SurveyService.saveQuestion(questionDTO, saveOnSuccess, saveOnError);
  };

  const saveAsLibraryQuestion = () => {
    SurveyService.addQuestionToLibrary(
      questionDTO,
      (data) => {
        if (library) {
          // Soru kütüphaneye ekleniyorsa
          saveOnSuccess();
        } else {
          // Soru ankete ekleniyorsa referansını setler
          setQuestionDTO({ ...questionDTO, refQuestionId: data.id });
          setReadyToSave(true);
        }
      },
      () => {}
    );
  };

  const saveQuestion = () => {
    if (questionDTO.questionType === "MENU_MATRIX") {
      let all = rows.concat(columns, menuItems);
      setQuestionDTO({ ...questionDTO, options: all });
      if (addToLibrary) {
        saveAsLibraryQuestion();
      } else {
        setReadyToSave(true);
      }
    } else if (questionDTO.questionType === "MATRIX") {
      let all = rows.concat(columns);
      setQuestionDTO({ ...questionDTO, options: all });
      if (addToLibrary) {
        saveAsLibraryQuestion();
      } else {
        setReadyToSave(true);
      }
    } else if (
      questionDTO.questionType === "MULTIPLE_CHOICE" ||
      questionDTO.questionType === "CHECKBOX" ||
      questionDTO.questionType === "DROPDOWN" ||
      questionDTO.questionType === "RANKING"
    ) {
      setQuestionDTO({ ...questionDTO, options: options });
      if (addToLibrary) {
        saveAsLibraryQuestion();
      } else {
        setReadyToSave(true);
      }
    } else {
      if (addToLibrary) {
        saveAsLibraryQuestion();
      } else {
        setReadyToSave(true);
      }
    }
  };

  const saveOnSuccess = (data) => {
    showAlert(!questionDTO.id ? t("SAVED") : t("EDITED"), "success");
    setIsDialogOpen(false);
    setQuestionDTO(newQuestion());
    setOptions([newOption()]);
    setRows([newOption()]);
    setColumns([newOption()]);
    setMenuItems([newOption()]);
    setLoading(false);
    onSave(data);
    setReadyToSave(false);
    setAddToLibrary(false);
  };

  const saveOnError = (error) => {
    showAlert(error.message, "error");
    setLoading(false);
  };

  const addItem = (list, setList) => {
    let temp = [...list];
    temp.push(newOption());
    setList(temp);
  };

  const deleteItem = (index, list, setList) => {
    let temp = [...list];
    temp.splice(index, 1);

    for (let i = index; i < temp.length; i++) {
      temp[i].orderNo = index + 1;
    }

    setList(temp);
  };

  const handleChange = (prop) => (event) => {
    setQuestionDTO({ ...questionDTO, [prop]: event.target.value });
  };

  const handleChangeV = (prop, val) => {
    setQuestionDTO({ ...questionDTO, [prop]: val });
  };

  const handleChangeItemV = (index, val, list, setList, optionType) => {
    let temp = list[index];
    temp.multiLangName = { ...temp.multiLangName, [currentLang]: val };
    temp.orderNo = index + 1;
    temp.optionType = optionType;
    let tempItems = [...list];
    tempItems[index] = temp;
    setList(tempItems);
  };

  const onQuestionDelete = (data) => {
    faDialog({
      description: t("Soruyu silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        SurveyService.deleteQuestion(
          questionDTO,
          (data) => {
            showAlert(t("Soru Silindi"), "success");
            setIsDialogOpen(false);
            onSave();
            setQuestionDTO(newQuestion());
            setOptions([newOption()]);
            setRows([newOption()]);
            setColumns([newOption()]);
            setMenuItems([newOption()]);
          },
          (error) => {
            showAlert(error.message, "Bağlı Kayıtlar Mevcut");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const moveUpItem = (index, list, setList) => {
    if (index === 0) return;

    let tempArray = [...list];

    let tempItem = tempArray[index - 1];
    tempItem.orderNo = tempItem.orderNo + 1;
    tempArray[index].orderNo = tempArray[index].orderNo - 1;
    tempArray[index - 1] = tempArray[index];
    tempArray[index] = tempItem;

    setList(tempArray);
  };

  const moveDownItem = (index, list, setList) => {
    if (index === list.length - 1) return;

    let tempArray = [...list];

    let tempItem = tempArray[index + 1];
    tempItem.orderNo = tempItem.orderNo - 1;
    tempArray[index].orderNo = tempArray[index].orderNo + 1;
    tempArray[index + 1] = tempArray[index];
    tempArray[index] = tempItem;

    setList(tempArray);
  };

  const renderEnterOptions = () => {
    return (
      <>
        {options.map((item, index) => (
          <Grid item xs={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaInput
                label={t("OPTION") + " " + (index + 1)}
                required
                onChange={(event) => {
                  handleChangeItemV(
                    index,
                    event.target.value,
                    options,
                    setOptions,
                    null
                  );
                }}
                value={
                  item.multiLangName?.[currentLang]
                    ? item.multiLangName?.[currentLang]
                    : ""
                }
                helperText={
                  item.multiLangName?.[currentLang] === undefined
                    ? GetFieldByLanguage(item.multiLangName, currentLang)
                    : ""
                }
              />
              <IconButton
                onClick={() => moveUpItem(index, options, setOptions)}
                size="small"
                color="primary"
                aria-label={t("Sil")}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton
                onClick={() => moveDownItem(index, options, setOptions)}
                size="small"
                color="primary"
                aria-label={t("Sil")}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
              <IconButton
                onClick={() => deleteItem(index, options, setOptions)}
                size="small"
                color="primary"
                aria-label={t("Sil")}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </div>
          </Grid>
        ))}
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button
            onClick={() => {
              addItem(options, setOptions);
            }}
          >
            {t("ADD_OPTION")}
          </Button>
        </Grid>
      </>
    );
  };

  return (
    <FaDialog
      title={t("EDIT_SURVEY_QUESTION")}
      faOpen={isDialogOpen}
      faOnSubmit={saveQuestion}
      loading={loading}
      showSaveButton
      faHandleClose={() => {
        setIsDialogOpen(false);
        setQuestionDTO(newQuestion());
        setOptions([newOption()]);
        setRows([newOption()]);
        setColumns([newOption()]);
        setMenuItems([newOption()]);
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <FaInput
            label={t("NAME")}
            required
            onChange={(event) => {
              setQuestionDTO({
                ...questionDTO,
                multiLangName: {
                  ...questionDTO.multiLangName,
                  [currentLang]: event.target.value,
                },
              });
            }}
            value={questionDTO?.multiLangName?.[currentLang] || ""}
            helperText={
              questionDTO.multiLangName?.[currentLang] === undefined
                ? GetFieldByLanguage(questionDTO.multiLangName, currentLang)
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <SurveyQuestionTypeSelect
            required
            value={questionDTO.questionType || ""}
            onChange={(data) => {
              handleChangeV("questionType", data);
            }}
          ></SurveyQuestionTypeSelect>
        </Grid>
        {(questionDTO.questionType === "MULTIPLE_CHOICE" ||
          questionDTO.questionType === "CHECKBOX" ||
          questionDTO.questionType === "DROPDOWN" ||
          questionDTO.questionType === "RANKING") &&
          renderEnterOptions()}
        {(questionDTO.questionType === "STAR_RATING" ||
          questionDTO.questionType === "SLIDER") && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("SCALE")}
                required
                onChange={handleChange("max")}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}
        {(questionDTO.questionType === "TEXT" ||
          questionDTO.questionType === "COMMENT") && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("MIN_LENGTH")}
                onChange={handleChange("min")}
                value={questionDTO.min ? questionDTO.min : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("MAX_LENGTH")}
                onChange={handleChange("max")}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}
        {(questionDTO.questionType === "INTEGER" ||
          questionDTO.questionType === "PERCENTAGE") && (
          <>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("MIN_VALUE")}
                onChange={handleChange("min")}
                value={questionDTO.min ? questionDTO.min : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                faType="number"
                label={t("MAX_VALUE")}
                onChange={handleChange("max")}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}
        {questionDTO.questionType === "DECIMAL" && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("MIN_VALUE")}
                onChange={(event) => {
                  let str = event.target.value;
                  if (!isNaN(str) && !isNaN(parseFloat(str))) {
                    handleChangeV("min", event.target.value);
                  }
                }}
                value={questionDTO.min ? questionDTO.min : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("MAX_VALUE")}
                onChange={(event) => {
                  let str = event.target.value;
                  if (!isNaN(str) && !isNaN(parseFloat(str))) {
                    handleChangeV("max", event.target.value);
                  }
                }}
                value={questionDTO.max ? questionDTO.max : ""}
              />
            </Grid>
          </>
        )}
        {(questionDTO.questionType === "DATE" ||
          questionDTO.questionType === "DATE_TIME") && (
          <>
            <Grid item xs={12}>
              <FormControl variant="outlined" size="small">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questionDTO.past}
                      onChange={(event) => {
                        handleChangeV("past", event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("PAST")}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={questionDTO.future}
                      onChange={(event) => {
                        handleChangeV("future", event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("FUTURE")}
                />
              </FormControl>
            </Grid>
          </>
        )}
        {questionDTO.questionType === "YES_NO" && (
          <>
            <Grid item xs={12}>
              <FaInput
                label={t("YES_LABEL")}
                onChange={(event) => {
                  setQuestionDTO({
                    ...questionDTO,
                    multiLangYesLabel: {
                      ...questionDTO.multiLangYesLabel,
                      [currentLang]: event.target.value,
                    },
                  });
                }}
                value={questionDTO.multiLangYesLabel?.[currentLang] || ""}
                helperText={t("YES_LABEL_DEFAULT_VALUE")}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                label={t("NO_LABEL")}
                onChange={(event) => {
                  setQuestionDTO({
                    ...questionDTO,
                    multiLangNoLabel: {
                      ...questionDTO.multiLangNoLabel,
                      [currentLang]: event.target.value,
                    },
                  });
                }}
                value={questionDTO.multiLangNoLabel?.[currentLang] || ""}
                helperText={t("NO_LABEL_DEFAULT_VALUE")}
              />
            </Grid>
          </>
        )}
        {questionDTO.questionType === "MATRIX" && (
          <>
            {rows.map((row, index) => (
              <Grid item xs={12} key={index}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaInput
                    label={t("ROW") + " " + (index + 1)}
                    required
                    onChange={(event) => {
                      handleChangeItemV(
                        index,
                        event.target.value,
                        rows,
                        setRows,
                        "ROW"
                      );
                    }}
                    value={
                      row.multiLangName?.[currentLang]
                        ? row.multiLangName?.[currentLang]
                        : ""
                    }
                    helperText={
                      row.multiLangName?.[currentLang] === undefined
                        ? GetFieldByLanguage(row.multiLangName, currentLang)
                        : ""
                    }
                  />
                  <IconButton
                    onClick={() => deleteItem(index, rows, setRows)}
                    size="small"
                    color="primary"
                    aria-label={t("Sil")}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  addItem(rows, setRows);
                }}
              >
                {t("ADD_ROW")}
              </Button>
            </Grid>

            {columns.map((column, index) => (
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaInput
                    label={t("COLUMN") + " " + (index + 1)}
                    required
                    onChange={(event) => {
                      handleChangeItemV(
                        index,
                        event.target.value,
                        columns,
                        setColumns,
                        "COLUMN"
                      );
                    }}
                    value={
                      column.multiLangName?.[currentLang]
                        ? column.multiLangName?.[currentLang]
                        : ""
                    }
                    helperText={
                      column.multiLangName?.[currentLang] === undefined
                        ? GetFieldByLanguage(column.multiLangName, currentLang)
                        : ""
                    }
                  />
                  <IconButton
                    onClick={() => deleteItem(index, columns, setColumns)}
                    size="small"
                    color="primary"
                    aria-label={t("Sil")}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  addItem(columns, setColumns);
                }}
              >
                {t("ADD_COLUMN")}
              </Button>
            </Grid>
          </>
        )}
        {questionDTO.questionType === "MENU_MATRIX" && (
          <>
            {rows.map((row, index) => (
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaInput
                    label={t("ROW") + " " + (index + 1)}
                    required
                    onChange={(event) => {
                      handleChangeItemV(
                        index,
                        event.target.value,
                        rows,
                        setRows,
                        "ROW"
                      );
                    }}
                    value={
                      row.multiLangName?.[currentLang]
                        ? row.multiLangName?.[currentLang]
                        : ""
                    }
                    helperText={
                      row.multiLangName?.[currentLang] === undefined
                        ? GetFieldByLanguage(row.multiLangName, currentLang)
                        : ""
                    }
                  />
                  <IconButton
                    onClick={() => deleteItem(index, rows, setRows)}
                    size="small"
                    color="primary"
                    aria-label={t("Sil")}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  addItem(rows, setRows);
                }}
              >
                {t("ADD_ROW")}
              </Button>
            </Grid>

            {columns.map((column, index) => (
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaInput
                    label={t("COLUMN") + " " + (index + 1)}
                    required
                    onChange={(event) => {
                      handleChangeItemV(
                        index,
                        event.target.value,
                        columns,
                        setColumns,
                        "COLUMN"
                      );
                    }}
                    value={
                      column.multiLangName?.[currentLang]
                        ? column.multiLangName?.[currentLang]
                        : ""
                    }
                    helperText={
                      column.multiLangName?.[currentLang] === undefined
                        ? GetFieldByLanguage(column.multiLangName, currentLang)
                        : ""
                    }
                  />
                  <IconButton
                    onClick={() => deleteItem(index, columns, setColumns)}
                    size="small"
                    color="primary"
                    aria-label={t("Sil")}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  addItem(columns, setColumns);
                }}
              >
                {t("ADD_COLUMN")}
              </Button>
            </Grid>

            {menuItems.map((item, index) => (
              <Grid item xs={12}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaInput
                    label={t("MENU_ITEM") + " " + (index + 1)}
                    required
                    onChange={(event) => {
                      handleChangeItemV(
                        index,
                        event.target.value,
                        menuItems,
                        setMenuItems,
                        "MENU"
                      );
                    }}
                    value={
                      item.multiLangName?.[currentLang]
                        ? item.multiLangName?.[currentLang]
                        : ""
                    }
                    helperText={
                      item.multiLangName?.[currentLang] === undefined
                        ? GetFieldByLanguage(item.multiLangName, currentLang)
                        : ""
                    }
                  />
                  <IconButton
                    onClick={() => deleteItem(index, menuItems, setMenuItems)}
                    size="small"
                    color="primary"
                    aria-label={t("Sil")}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </IconButton>
                </div>
              </Grid>
            ))}

            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => {
                  addItem(menuItems, setMenuItems);
                }}
              >
                {t("ADD_MENU_ITEM")}
              </Button>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <SurveyQuestionCategoryAutoComplete
            required={false}
            accountId={accountId}
            value={questionDTO.surveyQuestionCategory}
            onChange={(data) => {
              handleChangeV("surveyQuestionCategory", data);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" size="small">
            <FormControlLabel
              control={
                <Checkbox
                  checked={questionDTO.notEmpty}
                  onChange={(event) => {
                    handleChangeV("notEmpty", event.target.checked);
                  }}
                  color="primary"
                />
              }
              label={t("NOT_EMPTY")}
            />
          </FormControl>
        </Grid>
        {!question?.id && !library && (
          <>
            <Grid item xs={6}>
              <FormControl variant="outlined" size="small">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={addToLibrary}
                      onChange={(event) => {
                        setAddToLibrary(event.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={t("ADD_TO_LIBRARY")}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <FaButton
                variant="contained"
                color="secondary"
                size="medium"
                faClick={() => {
                  setIsDialogOpen(false);
                  openChooseFromLibrary();
                }}
                // startIcon={}
              >
                {t("Kütüphaneden Seç")}
              </FaButton>
            </Grid>
          </>
        )}
        {questionDTO.id && (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button onClick={onQuestionDelete} color="secondary">
              {t("Sil")}
            </Button>
          </Grid>
        )}
      </Grid>
    </FaDialog>
  );
});

export default SurveyQuestionEdit;
