import { Chip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

export default function CampaignStatusDecorator(props) {
  const { value } = props;

  const { t } = useTranslation("campaign", { i18n });

  const render = () => {
    if (value === "DRAFT") return <Chip size="small" label={t(value)} />;
    else if (value === "STARTED")
      return (
        <Chip
          style={{ backgroundColor: "green", color: "white" }}
          size="small"
          label={t(value)}
        />
      );
    else if (value === "REGISTER_CLOSED")
      return (
        <Chip
          style={{ backgroundColor: "blue", color: "white" }}
          size="small"
          label={t(value)}
        />
      );
    else if (value === "FINISHED")
      return (
        <Chip
          style={{ backgroundColor: "black", color: "white" }}
          size="small"
          label={t(value)}
        />
      );
    else if (value === "CANCELED")
      return (
        <Chip
          style={{ backgroundColor: "red", color: "white" }}
          size="small"
          label={t(value)}
        />
      );
    else {
      return <div>{value}</div>;
    }
  };

  return render();
}
