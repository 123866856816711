import { Button, Grid } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { getRoutesList } from "react-base-fa/dist/services/MenuService";
import { getRole } from "react-base-fa/dist/services/sessionSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { contextRoutes } from "routes";
import { UserTutorialService } from "services/UserTutorialService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { TUTORIALS } from "./TutorialData";

export default function Tutorials() {
  const tableRef = useRef();
  const { t } = useTranslation("tutorial", { i18n });

  let history = useHistory();

  const [userTutorialList, setUserTutorialList] = useState(null);
  const role = useSelector(getRole);

  useEffect(() => {
    UserTutorialService.findTutorialList(setUserTutorialList, (error) => {});
  }, []);

  const getDefinedTutorials = () => {
    //FIXME pakete ve role göre filtrelenecek

    const routes = getRoutesList(contextRoutes, role);

    const list = TUTORIALS.filter((x) => {
      let isGranted = routes.some(
        (r) => (x.paths != null && x.paths.indexOf(r.path) > -1) || !!!x.paths
      );
      if (!isGranted) {
        routes.forEach((r) => {
          if (r.childRoutes) {
            if (
              r.childRoutes.some(
                (rc) => x.paths != null && x.paths.indexOf(rc.path) > -1
              )
            ) {
              isGranted = true;
            }
          }
        });
      }

      return isGranted;
    });

    return list;
  };

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "25%",
      valueFormatter: (val) => {
        return t(val);
      },
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "35%",
      valueFormatter: (val) => {
        return t(val);
      },
    },
    {
      field: "status",
      label: t("COMPLETED"),
      align: "center",
      width: "20%",
      valueFormatter: (val, row) => {
        let exists = userTutorialList
          ? userTutorialList.find((x) => x.tutorialCode === row.code)
          : null;

        if (exists && exists.completeDate) {
          return <CheckCircle style={{ color: "green" }} />;
        }
      },
    },
    {
      field: "id",
      label: t("OPERATION"),
      align: "center",
      width: "20%",
      valueFormatter: (val, row) => {
        let exists = userTutorialList
          ? userTutorialList.find((x) => x.tutorialCode === row.code)
          : null;

        return (
          <div style={{ display: "flex" }}>
            <Button
              onClick={() => {
                startTutorial(row);
              }}
              size="small"
              color="primary"
              variant={"contained"}
            >
              {exists && exists.completeDate ? t("START_AGAIN") : t("START")}
            </Button>
          </div>
        );
      },
    },
  ];

  const startTutorial = (tutorial) => {
    const tmpTo = {
      pathname: `tutorial/${tutorial.code}`,
      breadCrumbKey: `/tutorial/:code`,
      customLabel: t(tutorial.name),
    };
    history.push(tmpTo);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <ThingsDataTable
          data={getDefinedTutorials()}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          showPaging={false}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}></Grid>
          <Grid item xs={5}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Suspense>
    </>
  );
}
