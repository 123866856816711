import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DynamicFieldsEdit from "components/dynamicFieldsEdit";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// import useStyles from "react-base-fa/dist/fa/faUnAuthPages/style";
import { useTranslation } from "react-i18next";
import "react-puzzle-confirm/react-puzzle-confirm.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AccessLogService } from "services/AccessLogService";
import { AccountService } from "services/AccountService";
import { FingerPrintService } from "services/FingerPrintService";
import i18n from "../../i18n";
import { ReportService } from "../../services/report/reportService";
import LandingHeader from "./landing/header/LandingHeader";
import { useStyles as reportLikeStyles } from "./ReporLikeStyle";
import { useStyles } from "./ReportIssueStyle";
import StepCaptchaVerification from "./StepCaptchaVerification";
import StepEmailVerification from "./StepEmailVerification";
import StepPhoneVerification from "./StepPhoneVerification";
import StepUserInfo from "./StepUserInfo";

export default function ReportLike(props) {
  const { loginPath, appLogo, miniAppLogo } = props;
  const { tid } = useParams();
  const classes = useStyles();
  const reportLikeClasses = reportLikeStyles();
  const { t } = useTranslation("report", { i18n });
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const emailVerRef = useRef();
  const smsVerRef = useRef();

  const VERIFICATION_EMAIL = "EMAIL";
  const VERIFICATION_SMS = "SMS";
  const VERIFICATION_CAPTCHA = "CAPTCHA";

  const STEP_LIKE_CLASS_SELECT = 0;
  const STEP_USER_INFO = 1;
  const STEP_USER_VERIFY = 2;
  const STEP_COMPLETED = 3;

  const [activeStep, setActiveStep] = useState(null);
  const steps = [t("CHOICE"), t("CONTACT_INFORMATION"), t("VERIFY")];

  const location = useLocation();
  const [publicThing, setPublicThing] = useState(location?.state?.publicThing);
  const [publicReportPageTemplate, setPublicReportPageTemplate] = useState(
    location?.state?.publicReportPageTemplate
  );
  const [likeSent, setLikeSent] = useState(false);

  const [visitorId, setVisitorId] = useState(null);
  const [blockReport, setBlockReport] = useState(false);
  const [likeId, setLikeId] = useState(null);
  const [classListExist, setClassListExist] = useState(null);
  const [isShowNextButton, setIsShowNextButton] = useState(false);

  const [isFieldsValid, setIsFieldsValid] = useState({
    phone: true,
    email: true,
  });

  const getInitialVerificationType = () => {
    if (publicThing.publicReportLikeVerificationType === "SMS")
      return VERIFICATION_SMS;
    else if (publicThing.publicReportLikeVerificationType === "EMAIL")
      return VERIFICATION_EMAIL;
    else if (publicThing.publicReportLikeVerificationType === "SMS_EMAIL")
      return VERIFICATION_EMAIL;
    else if (publicThing.publicReportLikeVerificationType === "CAPTCHA")
      return VERIFICATION_CAPTCHA;
  };

  const [report, setReport] = useState({
    thing: { id: location.state.publicThing.id },
    likeClass: null,
    visitorId: null,
    verificationType: getInitialVerificationType(),
  });
  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);
  const [accountDetails, setAccountDetails] = useState();
  const onDynamicValuesChange = (values) => {
    setDynamicValues(values);
  };

  useEffect(() => {
    if (location.state && location.state.publicThing) {
      FingerPrintService.getVisitorId(visitorIdOnSuccess, (error) => {
        visitorIdOnSuccess(null);
      });
    }
  }, []);

  const handleNextCallback = (result) => {
    if (result === true) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const checkNextButtonStatus = (stepNum) => {
    let showNext = false;
    if (stepNum === STEP_USER_INFO) {
      if (
        report.verificationType === VERIFICATION_EMAIL &&
        report.email !== "" &&
        report.email
      )
        showNext = true;
      else if (
        report.verificationType === VERIFICATION_SMS &&
        report.phone !== "" &&
        report.phone
      )
        showNext = true;
      else if (report.verificationType === VERIFICATION_CAPTCHA)
        showNext = true;
    } else if (stepNum === STEP_USER_VERIFY) {
    } else if (stepNum === STEP_LIKE_CLASS_SELECT) {
      if (report.likeClass !== null) showNext = true;
    }

    if (!isFieldsValid.email || !isFieldsValid.phone) showNext = false;

    setIsShowNextButton(showNext);
  };

  const handleNext = (event) => {
    checkNextButtonStatus(activeStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);

    if (activeStep === STEP_USER_VERIFY) {
      if (report.verificationType === VERIFICATION_EMAIL) {
        emailVerRef.current.sendEmailToken(handleNextCallback);
      } else if (report.verificationType === VERIFICATION_SMS) {
        smsVerRef.current.sendSmsToken(handleNextCallback);
      } else if (report.verificationType === VERIFICATION_CAPTCHA) {
        handleNextCallback(true);
      }
    }
  };
  const handleBack = () => {
    if (activeStep == STEP_LIKE_CLASS_SELECT) {
      history.goBack();
    } else {
      checkNextButtonStatus(activeStep - 1);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const visitorIdOnSuccess = (dataVisitorId) => {
    setVisitorId(dataVisitorId);
    setReport((prev) => {
      return { ...prev, visitorId: dataVisitorId };
    });

    ReportService.checkVisitor(
      publicThing.accountId,
      dataVisitorId,
      "LIKE",
      publicThing.id,
      (data) => {
        if (data === false) {
          setBlockReport(true);
        } else {
          if (location.state.publicThing?.likeClassList.length > 0) {
            setClassListExist(true);
            setActiveStep(0);
          } else {
            setActiveStep(1);
            setClassListExist(false);
          }
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (publicThing) {
      AccountService.findById(
        publicThing.accountId,
        (data) => {
          setAccountDetails(data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [publicThing]);

  const handleSend = (event) => {
    setLoading(true);
    const postData = { ...report, dynamicValues: dynamicValues };
    ReportService.reportLike(postData, reportLikeOnSuccess, reportLikeOnError);
  };

  const reportLikeOnSuccess = (data) => {
    setLoading(false);
    setLikeSent(true);
    setLikeId(data);
    setActiveStep((prevActiveStep) => STEP_COMPLETED);
  };

  const reportLikeOnError = (data) => {
    setLoading(false);
  };

  const handleChange = (prop) => (event) => {
    if (
      prop === "membership" ||
      prop === "acikRiza" ||
      prop === "kvkk" ||
      prop === "kvkkOnay" ||
      prop === "kullaniciSozlesme" ||
      prop === "faydalaniciAydinlatmaMetin" ||
      prop === "faydalaniciAydinlatmaOnay"
    ) {
      setReport({ ...report, [prop]: event.target.checked });
    } else {
      setReport({ ...report, [prop]: event.target.value });
    }

    if (prop === "email") {
      setIsFieldsValid({
        ...isFieldsValid,
        email: event.target.validity.valid,
      });

      if (report.verificationType === VERIFICATION_EMAIL) {
        setIsShowNextButton(
          event.target.value !== "" && event.target.validity.valid
        );
      } else setIsShowNextButton(event.target.validity.valid);
    } else if (prop === "phone") {
      setIsFieldsValid({
        ...isFieldsValid,
        phone: event.target.validity.valid,
      });
    }
  };

  const handleChangeV = (prop, value) => {
    setReport({ ...report, [prop]: value });

    if (prop === "phone" && report.verificationType === VERIFICATION_SMS) {
      setIsShowNextButton(value !== "");
    } else if (prop === "likeClass") {
      setIsShowNextButton(value !== "");
    }
  };

  const createAccessLog = (socialMediaType) => {
    let accessLog = {
      account: { id: publicThing.accountId },
      likeId: likeId,
      user: null,
      thing: publicThing,
      logType: "SOCIAL_MEDIA",
      gpsLocation: null,
      socialMediaType: socialMediaType,
    };
    AccessLogService.save(
      accessLog,
      () => {},
      () => {}
    );
  };

  const renderSocialLike = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <CheckCircleOutlineIcon
          style={{ fontSize: "64px", color: green[500] }}
        />
        <Typography style={{ fontSize: "20px" }}>
          {t("LIKE_SUCCESS", { likeId: likeId })}
        </Typography>
        {(accountDetails?.sm_twitter ||
          accountDetails?.sm_facebook ||
          accountDetails?.sm_instagram ||
          accountDetails?.sm_google ||
          accountDetails?.sm_tripadvisor ||
          accountDetails?.sm_linkedin) && (
          <>
            <Typography style={{ fontSize: "20px" }}>
              {t("social_media_prompt")}
            </Typography>

            <Grid
              container
              row
              justifyContent="center"
              alignItems="center"
              marginRight={10}
              style={{ marginTop: 10 }}
              spacing={1}
            >
              {accountDetails?.sm_facebook && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    className={reportLikeClasses.smButton}
                    startIcon={<Icon icon="logos:facebook" />}
                    onClick={() => {
                      createAccessLog("FACEBOOK");
                      window.open(`${accountDetails.sm_facebook}`, "_blank");
                    }}
                  >
                    {t("Facebook")}
                  </Button>
                </Grid>
              )}
              {accountDetails?.sm_twitter && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon="simple-icons:x" />}
                    className={reportLikeClasses.smButton}
                    onClick={() => {
                      createAccessLog("TWITTER");
                      window.open(`${accountDetails.sm_twitter}`, "_blank");
                    }}
                  >
                    {t("X")}
                  </Button>
                </Grid>
              )}
              {accountDetails?.sm_instagram && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon="logos:instagram" />}
                    className={reportLikeClasses.smButton}
                    onClick={() => {
                      createAccessLog("INSTAGRAM");
                      window.open(`${accountDetails.sm_instagram}`, "_blank");
                    }}
                  >
                    {t("Instagram")}
                  </Button>
                </Grid>
              )}
              {accountDetails?.sm_linkedin && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon="skill-icons:linkedin" />}
                    className={reportLikeClasses.smButton}
                    onClick={() => {
                      createAccessLog("LINKEDIN");
                      window.open(`${accountDetails.sm_linkedin}`, "_blank");
                    }}
                  >
                    {t("Linkedin")}
                  </Button>
                </Grid>
              )}
              {accountDetails?.sm_tripadvisor && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon="fontisto:tripadvisor" />}
                    className={reportLikeClasses.smButton}
                    onClick={() => {
                      createAccessLog("TRIPADVISOR");
                      window.open(`${accountDetails.sm_tripadvisor}`, "_blank");
                    }}
                  >
                    {t("Tripadvisor")}
                  </Button>
                </Grid>
              )}
              {accountDetails?.sm_google && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<Icon icon="logos:google-icon" />}
                    className={reportLikeClasses.smButton}
                    onClick={() => {
                      createAccessLog("GOOGLE");
                      window.open(`${accountDetails.sm_google}`, "_blank");
                    }}
                  >
                    {t("Google")}
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </div>
    );
  };
  const renderLikeClasses = () => {
    return (
      <>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="5%" align="center"></TableCell>
                <TableCell width="95%" align="center"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>{t("what_did_you_like")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {publicThing?.likeClassList.map((likeClass, index) => (
                <TableRow
                  key={index}
                  onClick={() => {
                    handleChangeV("likeClass", { id: likeClass.id });

                    ReportService.findFormByName(
                      "LIKE_CLASS",
                      publicThing.accountId,
                      likeClass.id,
                      setDynamicFormList,
                      (error) => {
                        setDynamicFormList(null);
                      }
                    );
                  }}
                  selected={likeClass?.id === report.likeClass?.id}
                >
                  <TableCell align="center">
                    <Radio
                      color="primary"
                      checked={likeClass?.id === report.likeClass?.id}
                    />
                  </TableCell>
                  <TableCell>{likeClass.className}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const renderWithLikeClassList = () => {
    return (
      <>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}>
            {renderLikeClasses()}
          </Grid>
          {likeSent
            ? null
            : dynamicFormList &&
              dynamicFormList.length > 0 && (
                <Grid item xs={12}>
                  <DynamicFieldsEdit
                    selectedDetails={dynamicFormList}
                    details={dynamicFormList}
                    data={dynamicValues}
                    onValuesChange={onDynamicValuesChange}
                  />
                </Grid>
              )}

          <Grid item xs={12} style={{ height: 20 }}></Grid>
          {/* !(
          activeStep === STEP_USER_VERIFY &&
          publicThing.publicReportLikeVerificationType ===
            VERIFICATION_CAPTCHA
        ) && ( */}
        </Grid>
      </>
    );
  };

  const renderReported = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        component={Paper}
        elevation={6}
        style={{
          height: "100vh",
          width: "100vw",
          margin: "10px",
          paddingLeft: "5px",
          paddingRight: "5px",
        }}
      >
        <div>
          <Grid
            item
            container
            spacing={3}
            lg={8}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item lg={10} xs={12} style={{ textAlign: "center" }}>
              <img
                src={appLogo}
                width="250"
                height="92"
                alt="logo"
                className={classes.appLogo}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Typography style={{ fontSize: "20px" }}>
                {t("ALREADY_REPORTED")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.footer}>
              <div className={classes.actionButtons2Div}>
                <div style={{ flex: 1 }}>
                  <Button
                    className={classes.backButton}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {t("Back")}
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case STEP_LIKE_CLASS_SELECT:
        return renderWithLikeClassList();
      case STEP_USER_INFO:
        return (
          <StepUserInfo
            report={report}
            verificationType={publicThing.publicReportLikeVerificationType}
            handleChange={handleChange}
            handleChangeV={handleChangeV}
            publicThingAccountId={publicThing.accountId}
          ></StepUserInfo>
        );
      case STEP_USER_VERIFY:
        return (
          <>
            {report.verificationType === VERIFICATION_EMAIL ? (
              <StepEmailVerification
                ref={emailVerRef}
                reportEmail={report.email}
                setIsShowNextButton={setIsShowNextButton}
                submit={handleSend}
              ></StepEmailVerification>
            ) : null}
            {report.verificationType === VERIFICATION_SMS ? (
              <StepPhoneVerification
                ref={smsVerRef}
                reportPhone={report.phone}
                setIsShowNextButton={setIsShowNextButton}
                submit={handleSend}
              ></StepPhoneVerification>
            ) : null}
            {report.verificationType === VERIFICATION_CAPTCHA ? (
              <StepCaptchaVerification
                handleBack={handleBack}
                handleNextCallback={handleNextCallback}
                submit={handleSend}
              ></StepCaptchaVerification>
            ) : null}
          </>
        );
      case STEP_COMPLETED:
        return (
          <>
            {renderSocialLike()}
            <div className={classes.footer}>
              <div className={classes.actionButtons2Div}>
                <div style={{ flex: 1 }}>
                  <Button
                    className={classes.backButton}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    {t("Back")}
                  </Button>
                </div>
              </div>
            </div>
          </>
        );
      default:
        return (
          <div style={{ textAlign: "center" }}>
            <Typography style={{ fontSize: "20px" }}>Loading...</Typography>
          </div>
        );
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ height: "100vh", width: "100vw" }}
    >
      {blockReport && renderReported()}
      {!blockReport && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          component={Paper}
          elevation={6}
          style={{
            height: "100vh",
            width: "100vw",
            margin: "10px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          <div>
            <Grid
              item
              container
              spacing={3}
              lg={8}
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                lg={10}
                xs={12}
                style={{ textAlign: "center", marginBottom: 92 }}
              >
                <LandingHeader
                  publicThing={publicThing}
                  miniAppLogo={miniAppLogo}
                />
              </Grid>
            </Grid>
            {activeStep < steps.length && (
              <div>
                {classListExist ? (
                  <Typography variant="h5">
                    {activeStep + 1}/{steps.length} {steps[activeStep]}
                  </Typography>
                ) : (
                  <Typography variant="h5">
                    {activeStep}/{steps.length - 1} {steps[activeStep]}
                  </Typography>
                )}
              </div>
            )}
          </div>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {getStepContent(activeStep)}
            </Grid>

            {activeStep < steps.length &&
              !(
                activeStep === STEP_USER_VERIFY &&
                publicThing.publicReportLikeVerificationType ===
                  VERIFICATION_CAPTCHA
              ) && (
                <>
                  <div className={classes.footer}>
                    <div className={classes.actionButtons2Div}>
                      <div style={{ flex: 1 }}>
                        <Button
                          className={classes.backButton}
                          onClick={handleBack}
                        >
                          {t("Back")}
                        </Button>
                      </div>

                      <div style={{ flex: 1 }}>
                        <Button
                          className={classes.nextButton}
                          onClick={handleNext}
                          disabled={!isShowNextButton}
                        >
                          {activeStep === steps.length - 1
                            ? t("SUBMIT")
                            : t("NEXT")}
                        </Button>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}

ReportLike.propTypes = {
  loginPath: PropTypes.string,
  appLogo: PropTypes.any,
};
