import { Grid, Typography } from "@mui/material";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import TaskTypeButtonGroup from "components/buttonGroup/TaskTypeButtonGroup";
import DateRangeSelector from "components/dateRangeSelector";
import ThingDetail from "components/detail/ThingDetail";
import TaskTypeSelect from "components/select/TaskTypeSelect";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useRef, useState } from "react";
import { FaInput, FaSearch } from "react-base-fa/dist/fa";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { calculateDate, isTNF } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { TASK } from "../../services/faApi";

export default function Todo() {
  const tableRef = useRef();
  const { t } = useTranslation("todo", { i18n });

  let history = useHistory();
  const accountId = localStorage.accountId;
  const labelClasses = labelStyles();

  const columns = [
    {
      field: "taskDate",
      label: t("TASK_DATE"),
      align: "center",
      width: "10%",
      type: "dateTime",
    },
    {
      field: "thing",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      width: "40%",
      valueFormatter: (val, row) => {
        return val && val.id && <ThingDetail value={val} noLink={true} />;
      },
    },
    {
      field: "taskType",
      label: t("TASK_TYPE"),
      align: "center",
      width: "20%",
      valueFormatter: (status) => {
        return t("TASK_TYPE_" + status);
      },
    },
    {
      field: "description",
      label: t("Açıklama"),
      align: "center",
      width: "30%",
    },
  ];

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
      user: null,
      userId: null,
      thing: null,
      thingId: null,
      status: null,
      taskDateValue: null,
      taskDateEnd: null,
      taskDateStart: null,
      taskType: null,
    };

    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    if (row?.taskType === "TASK") {
      const tmpTo = {
        pathname: `/todo/detail/${row?.id}`,
        breadCrumbKey: `/todo/detail/:id`,
      };
      history.push(tmpTo);
    }
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid item container spacing={1} style={{ marginBottom: 5 }}>
          <Grid item lg={6} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TASK_TYPE")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TaskTypeButtonGroup
                value={searchForm.taskType}
                onChange={(data) => {
                  const temp = { ...searchForm, taskType: data };
                  setSearchForm(temp);
                  tableRef.current.search(temp);
                }}
                // style={{ height: 60 }}
              />
            </Grid>
          </Grid>

          <Grid item lg={4} xs={6}>
            <Grid item xs={12} className={labelClasses.LabelLastRow}>
              <Typography className={labelClasses.LabelHeader}>
                {t("TASK_DATE")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <DateButtonGroup
                  value={searchForm.taskDateValue}
                  onChange={(data) => {
                    const temp = {
                      ...searchForm,
                      taskDateValue: data,
                      taskDateStart: calculateDate(data),
                    };

                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                  // style={{ height: 60 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />

              <ThingAutoComplete
                accountId={accountId}
                value={searchForm.thing}
                onChange={(data) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "thing",
                    data,
                    "thingId",
                    data?.id
                  );
                }}
              />
              <TaskTypeSelect
                value={searchForm.taskType}
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    taskType: data,
                  });
                }}
              />
              <DateRangeSelector
                onChange={(data) => {
                  setSearchForm({
                    ...searchForm,
                    taskDateValue: "OTHER",
                    taskDateStart: data.startDate,
                    taskDateEnd: data.endDate,
                  });
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
        <ThingsDataTable
          searchUrl={TASK.todoSearch + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          columns={columns}
          queryParams={{
            page: 0,
            size: 10,
            sort: "taskDate",
            isAsc: false,
          }}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
        />
      </Suspense>
    </>
  );
}
