import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./style";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function ChangePasswordTutorial(props) {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialAssignTask", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(1)",
        intro: (
          <TutorialElement icon="click">
            <b>Profil</b> ikonuna tıklayın.
          </TutorialElement>
        ),
        onAfterChange: (index) => {
          $(
            "#root > div > header > div > div > div:nth-child(3) > button:nth-child(1)"
          ).click();
        },
      },
      {
        element:
          "#primary-search-account-menu > div.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded > ul > li:nth-child(3)",
        intro: (
          <TutorialElement icon="click" text={"Parola değiştir'e tıklayın."} />
        ),
        onBeforeChange: (index) => {},
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_mail_gonder.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            <b>E-posta Gönder</b>’e tıklayın.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
        onBeforeChange: (index) => {
          $(
            "#root > div > header > div > div > div:nth-child(3) > button:nth-child(1)"
          ).click();
        },
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_aktivasyon_kodu.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            E-postanıza gelen <b>Aktivasyon Kodu</b>’nu girin.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_mevcut_parola.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            <b>Mevcut Parola</b>’nızı girin.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
      {
        element: null,
        intro: (
          <TutorialElement>
            <div style={{ textAlign: "center" }}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/tutorial/parola_degistir_yeni_parola.png"
                }
                width="489"
                height="373"
              ></img>
            </div>
            <br />
            <b>Yeni Parolanızı</b> girin ve <b>Kaydet</b>’e basın.
          </TutorialElement>
        ),
        tooltipClass: classes.parolaDegistirClass,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => window.history.back()}
      />
    </div>
  );
}
