import { Help } from "@mui/icons-material";
import Issues from "features/issues";
import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../i18n";
import { useStyles } from "./QuickStartTutorialStyle";
import ThingsSteps from "./ThingsSteps";
import TutorialElement from "./TutorialElement";

export default function FollowIssuesTutorial(props) {
  const { code } = useParams();
  const classes = useStyles();

  const stepsRef = useRef();
  let history = useHistory();

  const { t } = useTranslation("tutorialFollowIssues", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        element: "#menuCustomerInteraction", // parent menu
        intro: (
          <TutorialElement
            icon="click"
            text={"Bildirilen sorunları izlemek için tıklayın."}
          />
        ),
        position: "right",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element: "#menuIssues", // child menu
        intro: (
          <TutorialElement icon="click">
            {t(
              "Açık ve kapalı tüm sorunları liste halinde izlemek için tıklayın."
            )}
          </TutorialElement>
        ),
        onBeforeChange: (index) => {
          var element = document.querySelector("#menuCustomerInteraction");
          if (element.childElementCount > 1 == false)
            $("#menuCustomerInteraction")[0].firstElementChild.click();
          stepsRef.current.updateStepElement(index);
        },
      },
      {
        element:
          "#root > div > header > div > div > div:nth-child(3) > button:nth-child(5)",
        intro: (
          <TutorialElement icon="info">
            {
              "Açık ve Kapalı tüm sorunları Durum ve Tarihlere göre sıralamak, sorun detaylarını görmek için "
            }{" "}
            <b>Sorunlar</b> sayfasındaki {"yardıma "}
            <Help /> {" tıklayın ve yönergeleri takip edin."}
          </TutorialElement>
        ),
        comp: "Issues",
        onBeforeChange: (index) => {
          stepsRef.current.updateStepElement(index);
        },
      },
    ]);
  }, []);

  return (
    <div>
      {steps &&
        steps.length > stepIndex &&
        steps[stepIndex].comp === "Issues" && <Issues />}

      <ThingsSteps
        code={code}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
        afterComplete={() => {
          window.history.back();
          $("#menuCustomerInteraction")[0].firstElementChild.click();
        }}
      />
    </div>
  );
}
