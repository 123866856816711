import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import i18n from "../../../i18n";
import { useStyles } from "../style";
import ThingsSteps from "../ThingsSteps";
import TutorialElement from "../TutorialElement";

export default function AccountDetailTutorial(props) {
  const { code } = useParams();

  const stepsRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation("tutorialAccountDetails", { i18n });

  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setSteps([
      {
        intro: (
          <TutorialElement icon="tutorial">
            {t("Hesabınız ile ilgili bilgilerin gösterildiği Menü/Sayfadır. ")}
            <p>
              Bu menüde;
              <ul>
                <li>
                  <b>Hesap Bilgilerinizi</b> güncelleyebilirsiniz.
                </li>
                <li>
                  <b>Logo</b> Ekleyebilirsiniz.
                </li>
                <li>
                  <b>Sosyal Medya</b> hesaplarınızı ekleyebilirsiniz.
                </li>
                <li>
                  <b>Hesap Seçenekleri</b>’ni güncelleyebilirsiniz.
                </li>
                <li>
                  <b>Varlık Kayıt Seçenekleri</b>'ni yönetebilirsiniz.
                </li>
                <li>
                  <b>Periyodik Görev Seçenekleri</b>'ni yönetebilirsiniz.
                </li>
                <li>
                  <b>Hesap Anasayfa Seçenekleri</b>'ni yönetebilirsiniz.
                </li>
              </ul>
            </p>
          </TutorialElement>
        ),
        comp: "AccountDetails",
        tooltipClass: classes.accountDetail,
      },
    ]);
  }, []);

  return (
    <div>
      <ThingsSteps
        code={"account_detail"}
        steps={steps}
        ref={stepsRef}
        onExit={props.onExit}
        onStepChange={setStepIndex}
      />
    </div>
  );
}
