import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import { default as React, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RecurrenceService } from "services/RecurrenceService";
import { getDateTimeWFormat } from "services/utils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";

export default function RecurrenceDetailHistory(props) {
  const { patrolDef } = props;
  const labelClasses = labelStyles();
  const { t } = useTranslation("recurrence", { i18n });

  const [previousList, setPreviousList] = useState([]);
  const [nextList, setNextList] = useState([]);

  useEffect(() => {
    if (patrolDef.id > 0) {
      RecurrenceService.findPreviousOccuranceList(
        patrolDef.id,
        (data) => {
          setPreviousList(data);
        },
        (error) => {}
      );
      RecurrenceService.findNextOccuranceList(
        patrolDef,
        (data) => {
          setNextList(data);
        },
        (error) => {}
      );
    }
  }, [patrolDef]);

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item lg={12}>
                <Card>
                  <CardHeader
                    title={t("EXECUTION_HISTORY")}
                    data-fa-section="EXECUTION_HISTORY"
                  />
                  <CardContent>
                    <Grid item container spacing={0}>
                      <Grid item xs={6}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell className={labelClasses.LabelHeader}>
                                  Gerçekleşmiş Kayıtlar
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {previousList.map((previous, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    className={labelClasses.LabelValue}
                                  >
                                    {getDateTimeWFormat(previous)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={6}>
                        <TableContainer>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell className={labelClasses.LabelHeader}>
                                  Gelecek Kayıtlar
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {nextList.map((next, index) => (
                                <TableRow key={index}>
                                  <TableCell
                                    className={labelClasses.LabelValue}
                                  >
                                    {getDateTimeWFormat(next)}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
