import FullCalendar from "@fullcalendar/react"; // must go before plugins
// prettier-ignore
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// prettier-ignore
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
// prettier-ignore
import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import React, { useEffect } from "react";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../../i18n";
import { JOURNAL, TASK } from "../../services/faApi";

export default function Calendar() {
  const { t } = useTranslation(["calendar", "errorMessages"], { i18n });

  let history = useHistory();
  const handleEventClick = (arg) => {
    const task = arg.event.extendedProps.task;
    const journal = arg.event.extendedProps.journal;

    if (task && task.recurrence) {
      const tmpTo = {
        pathname: `/recurrence/detail/${task.recurrence.id}`,
        breadCrumbKey: `/recurrence/detail/:id`,
      };
      history.push(tmpTo);
    } else if (task && task.calendar) {
    } else if (task) {
      const tmpTo = {
        pathname: `/todo`,
        breadCrumbKey: `/todo`,
      };
      history.push(tmpTo);
    } else if (journal && journal.thing) {
      const tmpTo = {
        pathname: `/thingDetail/${journal.thing.id}`,
        breadCrumbKey: `/thingDetail/:id`,
      };
      history.push(tmpTo);
    }
  };

  const accountId = localStorage.accountId;

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  const calendarEvents2 = (arg, successCallback, failureCallback) => {
    // bind with an arrow function

    var list = [];

    var req = requests.getSimpleGetRequest(TASK.findByAccountAndDateRange, {
      accountId: accountId,
      startDate: arg.start.toJSON().slice(0, 10),
      endDate: arg.end.toJSON().slice(0, 10),
    });

    var req2 = requests.getSimpleGetRequest(JOURNAL.findByAccountAndDateRange, {
      accountId: accountId,
      startDate: arg.start.toJSON().slice(0, 10),
      endDate: arg.end.toJSON().slice(0, 10),
    });

    FaAxios(req)
      .then((response) => {
        if (response.data != null) {
          response.data.map((element) => {
            list.push({
              title:
                "Görev" +
                (element.description ? ":" + element.description : ""),
              date: element.taskDate,
              extendedProps: {
                task: element,
              },
              backgroundColor: element.calendar ? "#38B64A" : null,
            });
          });
        }
        FaAxios(req2)
          .then((response) => {
            if (response.data != null) {
              response.data.map((element) => {
                list.push({
                  title:
                    "Günce" +
                    (element.description ? ": " + element.description : ""),
                  date: element.journalDate,
                  extendedProps: {
                    journal: element,
                  },
                  backgroundColor: "#FAC604",
                });
              });
            }
            successCallback(list);
          })
          .catch((error) => {});

        //setLoading(false);
      })
      .catch((error) => {
        //showAlert(error.message, "error");
        //setLoading(false);
      });

    return () => {
      //setLoading(false);
    };
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {};

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      eventClick={handleEventClick}
      eventContent={renderEventContent}
      events={calendarEvents2}
      height="auto"
      dayMaxEvents={5}
      locale={i18n.language}
      eventDisplay="block"
    />
  );
}
