import { Close, Settings } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import ThingsDataTable from "components/thingsDataTable";
import AdditionalFieldsEdit from "features/additionalFields/additionalFieldsEdit";
import React, { Suspense, useRef, useState } from "react";
import { FaButton, FaDialog, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { JournalTypeService } from "services/JournalTypeService";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { JOURNAL_TYPE } from "../../services/faApi";
import { useStyles } from "./style";

export default function JournalType() {
  const tableRef = useRef();
  const { t } = useTranslation("journalType", { i18n });
  const dispatch = useDispatch();
  const faDialog = useDialog();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  const classes = useStyles();

  const accountId = localStorage.accountId;

  const [loading, setLoading] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAdditionalFieldsDialogOpen, setIsAdditionalFieldsDialogOpen] =
    useState(false);
  const [journalClass, setJournalClass] = useState();

  const columns = [
    {
      field: "name",
      label: t("NAME"),
      align: "center",
      width: "30%",
    },
    {
      field: "description",
      label: t("DESCRIPTION"),
      align: "center",
      width: "30%",
    },

    {
      field: "createDate",
      label: t("CREATE_DATE"),
      align: "center",
      width: "20%",
      type: "smartDate",
    },
    {
      field: "id",
      label: t("DYNAMIC_FIELDS"),
      align: "center",
      width: "20%",
      valueFormatter: (value, row) => {
        return (
          <>
            <IconButton
              onClick={() => {
                setJournalClass({ ...row });
                setIsAdditionalFieldsDialogOpen(true);
              }}
              size="small"
              color="primary"
              title={t("Ayarlar")}
              style={{ marginLeft: 10 }}
            >
              <Settings />
            </IconButton>
          </>
        );
      },
    },
    // {
    //   field: "lastChangeDate",
    //   label: t("LAST_CHANGE_DATE"),
    //   align: "center",
    //   width: "20%",
    //   type: "smartDate",
    // },
  ];

  const newJournalType = () => {
    return {
      id: "",
      name: "",
    };
  };
  const [journalType, setJournalType] = useState(newJournalType());

  const handleChange = (prop) => (event) => {
    setJournalType({ ...journalType, [prop]: event.target.value });
  };

  const saveSuccess = (data) => {
    showAlert(!journalType.id ? t("SAVED") : t("EDITED"), "success");
    setIsEditFormOpen(false);
    setJournalType(newJournalType());
    tableRef.current.refreshTable();
    setLoading(false);
  };

  const saveError = (data) => {
    showAlert(data.message, "error");
    setLoading(false);
  };

  const saveJournalType = () => {
    setLoading(true);
    JournalTypeService.save(
      {
        ...journalType,
        account: { id: accountId },
      },
      saveSuccess,
      saveError
    );
  };

  const newSearchForm = () => {
    let result = {
      name: null,
      description: null,
      accountId: accountId,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };
  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm(newSearchForm());
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const onDoubleClick = (row) => {
    setJournalType(row);
    setIsEditFormOpen(true);
  };

  const onDelete = () => {
    faDialog({
      description: t("Silmek istediğinize emin misiniz?"),
      type: "confirm",
    })
      .then((confirmation) => {
        JournalTypeService.delete(
          journalType,
          (data) => {
            showAlert(t("Başarıyla silindi"), "success");
            tableRef.current.refreshTable();
            setIsEditFormOpen(false);
          },
          (error) => {
            showAlert(t("Kayıt silinemedi, bağlı kayıtlar mevcut."), "error");
          }
        );
      })
      .catch((err) => console.log("error:", err));
  };

  const renderAdditionalFieldsDialog = () => {
    return (
      isAdditionalFieldsDialogOpen &&
      journalClass &&
      journalClass.id && (
        <Dialog
          open={isAdditionalFieldsDialogOpen}
          onClose={() => {
            setIsAdditionalFieldsDialogOpen(false);
          }}
          maxWidth="lg"
          fullWidth={true}
        >
          <DialogTitle id="form-dialog-title">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                setIsAdditionalFieldsDialogOpen(false);
              }}
              size="large"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid item container spacing={1}>
              <Grid item xs={12}>
                <AdditionalFieldsEdit
                  dynamicName="JOURNAL_CLASS"
                  dynamicId={journalClass.id}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )
    );
  };

  const newFormRender = () => {
    return (
      <>
        <FaDialog
          title={"Günce Türü Tanımlama"}
          faOpen={isEditFormOpen}
          faOnSubmit={saveJournalType}
          loading={loading}
          showSaveButton
          faHandleClose={(data) => {
            setIsEditFormOpen(data);
            setJournalType(newJournalType());
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <FaInput
                required
                maxLength={100}
                label={t("NAME")}
                onChange={handleChange("name")}
                value={journalType.name || ""}
              />
            </Grid>
            <Grid item xs={12}>
              <FaInput
                maxLength={100}
                label={t("DESCRIPTION")}
                onChange={handleChange("description")}
                value={journalType.description || ""}
              />
            </Grid>
            {journalType && journalType.id > 0 && (
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={onDelete} color="secondary">
                  Sil
                </Button>
              </Grid>
            )}
          </Grid>
        </FaDialog>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {newFormRender()}
        {renderAdditionalFieldsDialog()}
        <ThingsDataTable
          searchUrl={
            JOURNAL_TYPE.search + "?accountId=" + localStorage.accountId
          }
          searchForm={searchForm}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}
          queryParams={{ page: 0, size: 10, sort: "name", isAsc: true }}
        />
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          spacing={1}
        >
          <Grid item xs={1} className={"thingsDataTableButtons"}>
            <FaButton
              variant="contained"
              disabled={loading}
              color="primary"
              size="medium"
              faClick={() => {
                setJournalType(newJournalType());
                setIsEditFormOpen(true);
              }}
              startIcon={<AddIcon />}
              data-fa-button="ADD"
            >
              {t("ADD")}
            </FaButton>
          </Grid>
          <Grid item xs={5}>
            <div>{searchInfo}</div>
          </Grid>
          <Grid item xs={6}>
            <FaSearch
              onSearch={searchSubmit}
              onClear={searchClear}
              onSummarySearch={searchSummary}
              setSearchInfo={setSearchInfo}
              faSummary={searchForm?.summary}
              faClassName="appSearchBar"
            >
              <FaInput
                label={t("NAME")}
                value={searchForm.name}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "name",
                    event.target.value
                  );
                }}
              />

              <FaInput
                label={t("DESCRIPTION")}
                value={searchForm.description}
                onChange={(event) => {
                  handleChangeGeneric(
                    setSearchForm,
                    searchForm,
                    "description",
                    event.target.value
                  );
                }}
              />
            </FaSearch>
          </Grid>
        </Grid>
      </Suspense>
    </>
  );
}
